import React from "react";

function SvgComponent({size, color, ...props}: {size?: number; color?: string}) {
    return (
        <svg
            width={size || "1em"}
            height={size || "1em"}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.025 3.543a3.508 3.508 0 110 7.016 3.508 3.508 0 010-7.016zm0-1.5a5.008 5.008 0 10.002 10.016 5.008 5.008 0 00-.002-10.016zM14.025 15.5a3.5 3.5 0 013.5 3.5v1.076a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5V19a3.5 3.5 0 013.5-3.5h4zm0-1.5h-4a5 5 0 00-5 5v1.076a2 2 0 002 2h10a2 2 0 002-2V19a5 5 0 00-5-5z"
                fill="currentColor"
            />
        </svg>
    );
}

const MemoSvgComponent = React.memo(SvgComponent);
export default MemoSvgComponent;
