import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {useLocalStorage} from "@unibuddy/patron";
import {AnalyticsProvider as UnibuddyAnalyticsProvider} from "@unibuddy/tracking";
import mixpanelAdaptor from "@unibuddy/tracking/dist/adaptors/mixpanel";
import {Adaptor} from "@unibuddy/tracking/dist/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {CookiesManager} from "./Profile/CookiesManager/CookiesManager";

const loggingAdaptor: Adaptor = {
    trackEvent: (name, props) => {
        console.log(`trackEvent: ${name}`, props);
    },
    setUser: (user) => {
        console.log("setUser", user);
    },
    aliasVisitor: (user) => {
        console.log("aliasVisitor", user);
    },
    reset: () => {
        console.log("reset");
    },
};

export const CommunityAnalyticsProvider: FC<PropsWithChildren> = ({children}) => {
    const [preferencesCookies, setPreferencesCookies] = useLocalStorage("analytics-cookies.v2");
    const hasSelection = !!preferencesCookies;
    const analytics = preferencesCookies?.preferences?.analytics;
    const handleSelection = ({analytics}: {analytics: boolean}, uniqueId: string) => {
        setPreferencesCookies({
            uniqueId,
            preferences: {
                analytics,
            },
        });
    };
    const [adaptors, setAdaptors] = useState<Adaptor[]>([]);

    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            setAdaptors((adaptors) => [...adaptors, loggingAdaptor]);
        }
    }, []);

    useEffect(() => {
        if (process.env.MIXPANEL_API_TOKEN && hasSelection && analytics) {
            mixpanelAdaptor.init(process.env.MIXPANEL_API_TOKEN, {
                api_host: "https://tukey.unibuddy.co",
            });
            setAdaptors((adaptors) => [...adaptors, mixpanelAdaptor]);
        } else {
            // TODO: figure out how to detect if mixpanel initialized already bc this crashes
            // mixpanel.opt_out_tracking();
        }
    }, [analytics, hasSelection]);
    const {user} = useAuth();

    return (
        <UnibuddyAnalyticsProvider key={adaptors.length} adaptors={adaptors} user={user}>
            <CookiesManager hasSelection={hasSelection} onSelection={handleSelection} />
            {children}
        </UnibuddyAnalyticsProvider>
    );
};
