import React, {createContext, useContext, useMemo, useCallback} from "react";
import {Platform, StatusBar, Appearance} from "react-native";
import {contentUITheme} from "@unibuddy/content-ui";
import {DesignSystemProvider} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {ChatThemeProvider} from "@unibuddy/chat-ui";
import merge from "lodash/merge";

import {useAsyncLocalStorage} from "ubcommunity-shared/src/Auth/AuthProvider/useAsyncLocalStorage";
import theme, {darkTheme} from "ubcommunity-shared/src/theme";
import {chatTheme} from "ubcommunity-shared/src/chatTheme";

type ToggleMode = {
    setCommunityTheme: (theme: "light" | "dark") => void;
    darkModeEnabled: boolean;
};

const CommunityThemeContext = createContext({} as ToggleMode);

export const useCommunityTheme = () => {
    return useContext(CommunityThemeContext);
};

export function CommunityThemeProvider({children}: {children: any}) {
    const colorScheme = Appearance.getColorScheme();
    const {trackEvent} = useAnalytics();
    const {value, setValue, loading} = useAsyncLocalStorage(
        "communityTheme",
        Platform.select({native: colorScheme, web: "light"}),
    );

    const setTheme = useCallback(
        (theme: "light" | "dark") => {
            setValue(theme);
            trackEvent("Dark mode", {turnedOn: theme === "dark" ? true : false});
        },
        [setValue, trackEvent],
    );

    const darkModeEnabled = useMemo(() => value !== "light", [value]);

    const mobileChatTheme = useMemo(() => {
        if (!darkModeEnabled) {
            return {
                ...chatTheme,
                chat: {
                    ...chatTheme.chat,
                    input: {
                        ...chatTheme.chat?.input,
                        paddingTop: Platform.select({
                            ios: 11,
                            android: 0,
                        }),
                    },
                },
            };
        }

        return {
            ...chatTheme,
            chat: {
                ...chatTheme.chat,
                contextMenu: {
                    backgroundColor: "#2B2D3F",
                    underlayColor: "#424157",
                    reactionBackgroundColor: "#424157",
                    reactionBorderColor: "#CFC8FF",
                },
                input: {
                    color: "#fff",
                    backgroundColor: "#2B2D3F",
                    placeholderColor: "#D0D9D9",
                    paddingTop: Platform.select({
                        ios: 11,
                        android: 0,
                    }),
                },
                footer: {
                    backgroundColor: "#131727",
                },
                message: {
                    backgroundColor: "#6852FB",
                    altBackgroundColor: "#2B2D3F",
                    linkColor: "#fff",
                    altLinkColor: "#fff",
                    textColor: "#fff",
                    altTextColor: "#fff",
                },
                quote: {
                    backgroundColor: "#5342C9",
                    altBackgroundColor: "#424157",
                    authorColor: "#fff",
                    altAuthorColor: "#fff",
                    highlightColor: "#6852FB",
                    altBorderColor: "#fff",
                    bodyColor: "#fff",
                    altBodyColor: "#fff",
                },
                scroll: {
                    backgroundColor: "#131727",
                    gradientBgColorTop: "transparent",
                    gradientBgColorBottom: "transparent",
                },
                reaction: {
                    backgroundColor: "#424157",
                    pinnedBackgroundColor: "#424157",
                    border: "1px solid #424157",
                    reactedBackgroundColor: "#424157",
                    reactedBorderColor: "#CFC8FF",
                },
            },
        };
    }, [darkModeEnabled]);

    const customTheme: any = useMemo(() => {
        if (darkModeEnabled) {
            return merge(darkTheme, contentUITheme);
        }
        return merge(theme, contentUITheme);
    }, [darkModeEnabled]);

    return (
        <CommunityThemeContext.Provider value={{setCommunityTheme: setTheme, darkModeEnabled}}>
            <DesignSystemProvider theme={customTheme}>
                <StatusBar
                    barStyle={darkModeEnabled ? "light-content" : "dark-content"}
                    backgroundColor={darkModeEnabled ? "#131727" : "#fff"}
                />
                <ChatThemeProvider theme={mobileChatTheme}>
                    {loading ? null : children}
                </ChatThemeProvider>
            </DesignSystemProvider>
        </CommunityThemeContext.Provider>
    );
}
