import React from "react";
import {Text, Button} from "@unibuddy/patron";

import {SIGNUP_PROMPT_STRINGS} from "../strings";

export const CreateAccountButton = ({
    handleSubmit,
    isSubmitDisabled,
}: {
    handleSubmit?: () => void;
    isSubmitDisabled?: boolean;
}) => {
    return (
        <Button onClick={handleSubmit} size="lg" block color="primary" disabled={isSubmitDisabled}>
            <Text size="large" weight="bold" color="white">
                {!isSubmitDisabled
                    ? SIGNUP_PROMPT_STRINGS.BUTTON_TEXT_CREATE_ACCOUNT
                    : SIGNUP_PROMPT_STRINGS.BUTTON_TEXT_INVALID_LINK}
            </Text>
        </Button>
    );
};
