type AccessTokenType = {
    scheme: string;
    token: string;
};

/**
 * Destructure `accessToken` and transform to a string
 */
export function toAuthorizationSchemeFromObject({scheme, token}: AccessTokenType): string {
    return `${scheme} ${token}`;
}

/**
 * Transform to a string
 */
export function toAuthorizationSchemeFromString(scheme: string, token: string): string {
    return `${scheme} ${token}`;
}

/**
 * Using the `accessToken` field, generate the value for a request
 * Authorization header
 */
export function getAuthorizationHeaderFor(
    tokenScheme: string = "",
    accessToken: string | AccessTokenType = "",
): string {
    if (!tokenScheme && !accessToken) {
        throw new Error("Scheme and token are invalid");
    }

    if ((!tokenScheme || tokenScheme === "JWT") && typeof accessToken === "string") {
        return `JWT ${accessToken}`;
    }

    if (!tokenScheme && typeof accessToken === "object") {
        return toAuthorizationSchemeFromObject(accessToken);
    }

    if (tokenScheme.includes("Bearer") && typeof accessToken === "string") {
        return toAuthorizationSchemeFromString(tokenScheme, accessToken);
    }
}

/**
 * Using the `accessToken` field, generate a boolean indicating whether it is of the
 * expected structure
 */
export function hasAuthorization(
    tokenScheme: string = "",
    accessToken: string | AccessTokenType = "",
): boolean {
    if (!tokenScheme && !accessToken) return false;

    if (typeof accessToken === "string") {
        // A string means non-SSO (JWT) or Native (Bearer)
        return true;
    }

    // An object with `scheme` and `token` means SSO (Bearer)
    return !!(accessToken.scheme && accessToken.token);
}
