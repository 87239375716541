import React, {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import {ApolloQueryResult, NetworkStatus, ApolloError, LazyQueryExecFunction} from "@apollo/client";
import {
    Exact,
    FilterUsersDto,
    GetCommunityUserDirectoryQuery,
    HousingInput,
    OffsetPagination,
    useGetCommunityUserDirectoryLazyQuery,
    UserField,
} from "../types";
import {useCommunity} from "../Community/CommunityProvider/CommunityProvider";

export type {UserField};

export enum FilterGroups {
    AREA_OF_STUDY = "areaOfStudy",
    HOUSING = "housingTypes",
    INTERESTS = "interests",
}

export type Filter = {
    [FilterGroups.AREA_OF_STUDY]?: string[];
    [FilterGroups.INTERESTS]?: string[];
    [FilterGroups.HOUSING]?: HousingInput[];
};

type IPeopleFiltersContext = {
    loading: boolean;
    error?: ApolloError;
    results: any[];
    totalCount: number;
    filters: Filter;
    setFilters: React.Dispatch<React.SetStateAction<Filter>>;
    fetch: LazyQueryExecFunction<
        GetCommunityUserDirectoryQuery,
        Exact<{
            pagination: OffsetPagination;
            filterUsers: FilterUsersDto;
        }>
    >;
    refetch: (
        variables?: Partial<
            Exact<{
                pagination: OffsetPagination;
                filterUsers: FilterUsersDto;
            }>
        >,
    ) => Promise<Partial<ApolloQueryResult<GetCommunityUserDirectoryQuery>>>;
    fetchNext: () => void;
    fetchNextLoading: boolean;
    clearAll: () => void;
};

const PeopleFiltersContext = createContext<IPeopleFiltersContext>({
    loading: false,
    error: undefined,
    results: [],
    totalCount: 0,
    filters: {},
    setFilters: () => {},
    // @ts-ignore
    fetch: () => {},
    refetch: async () => ({
        data: undefined,
        loading: false,
        networkStatus: NetworkStatus.ready,
    }),
    fetchNext: () => {},
    fetchNextLoading: false,
    clearAll: () => {},
});

export const usePeopleFilters = () => {
    return useContext(PeopleFiltersContext);
};

export const PEOPLE_LIST_LIMIT = 15;
export const PeopleFiltersProvider = ({children}: PropsWithChildren) => {
    const {selectedCommunity} = useCommunity();
    const [filters, setFilters] = useState<Filter>({});

    const [getUserDirectory, {networkStatus, data, refetch, fetchMore, error}] =
        useGetCommunityUserDirectoryLazyQuery({
            variables: {
                pagination: {
                    offset: 0,
                    limit: PEOPLE_LIST_LIMIT,
                },
                filterUsers: {
                    communityId: selectedCommunity?.id,
                },
            },
            notifyOnNetworkStatusChange: true,
        });

    const clearAll = useCallback(() => {
        setFilters({});
        refetch({
            pagination: {
                offset: 0,
                limit: PEOPLE_LIST_LIMIT,
            },
            filterUsers: {
                communityId: selectedCommunity?.id,
            },
        });
    }, [refetch, selectedCommunity?.id]);

    const fetchNext = useCallback(() => {
        fetchMore({
            variables: {
                pagination: {
                    offset: data?.communityUserSearch?.result?.length ?? 0,
                    limit: PEOPLE_LIST_LIMIT,
                },
            },
        });
    }, [fetchMore, data?.communityUserSearch?.result?.length]);

    const loading =
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch ||
        networkStatus === NetworkStatus.setVariables;

    const value = useMemo(
        () => ({
            loading,
            error,
            results: data?.communityUserSearch?.result || [],
            totalCount: data?.communityUserSearch?.totalUsers || 0,
            filters,
            setFilters,
            refetch,
            fetch: getUserDirectory,
            fetchNext,
            fetchNextLoading: networkStatus === NetworkStatus.fetchMore,
            clearAll,
        }),
        [
            filters,
            loading,
            data?.communityUserSearch,
            refetch,
            fetchNext,
            networkStatus,
            getUserDirectory,
            error,
            clearAll,
        ],
    );

    return <PeopleFiltersContext.Provider value={value}>{children}</PeopleFiltersContext.Provider>;
};
