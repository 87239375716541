import {useEffect, useState} from "react";

export function useIsDesktop() {
    const [media] = useState(() => window.matchMedia("(min-width: 768px)"));
    const [isDesktop, setIsDesktop] = useState(media.matches);
    useEffect(() => {
        const listener = () => {
            setIsDesktop(media.matches);
        };
        media.addListener(listener);
        return () => {
            media.removeListener(listener);
        };
    }, [media]);

    return {isDesktop};
}
