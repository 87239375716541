import {colors} from "@unibuddy/patron";
import {Platform} from "react-native";
import {DefaultTheme} from "styled-components";

const nunitoMetrics = {
    capHeight: 705,
    ascent: 1011,
    descent: -353,
    lineGap: 0,
    unitsPerEm: 1000,
};

const fontFamily = Platform.select({android: "Nunito Regular", default: "Nunito"});
const theme: DefaultTheme = {
    colors: {
        primaryColor: "#5140C9",
        accentColor: "#01D4CF",
        secondaryColor: "#CFC8FF",
        secondaryText: "#A1AEB7",
        white: "#fff",
        grey: "#cdcdcd",
        darkGrey: "#404446",
        lightGrey: "#949D9F",
        black: "#000000",
        darkPink: "#606467",
        calypso: "#131727",
        gray88: "#E0E0E0",
        blueLilac: "#B5AAFF",

        // Theming based colors
        body: "#fff",
        navbarBorderColor: "#eee",
        navbarColor: "#fff",
        chatBody: "#f2f5f7",
        textLinkColor: "#5140C9",
        textLinkColorInactive: "#757575",
        swipeableItem: "#f1f1f1",
        textColor: "#424b5a",
        groupBgColor: "#F3F5F5",
        textColorWhiteBackground: "#424b5a",
        error: "#C94343",
        errorBackground: "#FDF9F9",
        altTextColor: "#606467",
        placeholderColor: "#bfc8cf",
        pillColor: "#AEE1E0",
    },
    shadows: {
        medium: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        large: "0px 2px 12px rgba(0, 0, 0, 0.08)",
    },
    Drawer: {
        sizes: {
            full: {
                mobile: "100%",
                tablet: "100%",
                desktop: "100%",
                largeDesktop: "100%",
            },
            small: {
                mobile: "100%",
                tablet: "320px",
                desktop: "320px",
                largeDesktop: "320px",
            },
        },
    },
    Divider: {
        bgColor: "#F0F2F2",
    },
    TextBlock: {
        fontMetrics: nunitoMetrics,
        fontFamily,
        color: "#424b5a",
        sizes: {
            xxsmall: {
                fontSize: 12,
                lineGap: 8,
            },
            xsmall: {
                fontSize: 13,
                lineGap: 9,
            },
            small: {
                fontSize: 14,
                lineGap: 10,
            },
            standard: {
                fontSize: 16,
                lineGap: 13,
            },
            medium: {
                fontSize: 16,
                lineGap: 13,
            },
            large: {
                fontSize: 18,
                lineGap: 16,
            },
            xlarge: {
                fontSize: 22,
                lineGap: 22,
            },
            xxlarge: {
                fontSize: 26,
                lineGap: 24,
            },
        },
    },
    Heading: {
        fontMetrics: nunitoMetrics,
        fontFamily,
        color: "#424b5a",
        sizes: {
            xxsmall: {
                fontSize: 18,
                lineGap: 20,
            },
            xsmall: {
                fontSize: 20,
                lineGap: 21,
            },
            small: {
                fontSize: 24,
                lineGap: 25,
            },
            standard: {
                fontSize: 26,
                lineGap: 24,
            },
            medium: {
                fontSize: 26,
                lineGap: 24,
            },
            large: {
                fontSize: 32,
                lineGap: 28,
            },
            xlarge: {
                fontSize: 48,
                lineGap: 36,
            },
            xxlarge: {
                fontSize: 64,
                lineGap: 52,
            },
        },
    },
    Button: {
        fontSizes: {
            lg: "20px",
            default: "18px",
            md: "18px",
            sm: "16px",
            xs: "14px",
            xxsmall: "12px",
        },
        heights: {
            lg: "50px",
            default: "44px",
            md: "44px",
            sm: "38px",
            xs: "34px",
            xxsmall: "28px",
        },
        iconOnly: {
            heights: {
                xs: "38px",
                xxsmall: "28px",
            },
        },
        colors: {
            activeChat: {
                borderColor: "transparent",
                hoverBgColor: "#ffd5a6",
            },
            default: {
                color: "#5140C9",
                bgColor: "#CFC8FF",
                borderColor: "#CFC8FF",
                hoverBgColor: "#bcb4f7",
                outlineColor: "#5140C9",
                clearColor: "#212121",
                clearHoverBgColor: "#f1f1f1",
            },
            white: {
                color: "#fff",
                bgColor: "#fff",
                borderColor: "#CFC8FF",
                hoverBgColor: "#bcb4f7",
                outlineColor: "#5140C9",
                clearColor: "#212121",
                clearHoverBgColor: "#f1f1f1",
            },
            primary: {
                color: "#fff",
                bgColor: "#5140c9",
                borderColor: "#5140c9",
                hoverBgColor: "#573ff5",
                outlineColor: "#5140c9",
                clearColor: "#5140c9",
                clearHoverBgColor: "#CFC8FF",
            },
            success: {
                color: colors.teal900,
                bgColor: colors.teal100,
                borderColor: colors.teal800,
                hoverBgColor: colors.teal200,
                outlineColor: colors.teal800,
                clearColor: colors.teal800,
                clearHoverBgColor: colors.teal150,
            },
            light: {
                color: "#3E3E48",
                bgColor: "#F2F5F7",
                borderColor: "#F2F5F7",
                hoverBgColor: "#F2F5F7",
                outlineColor: "#3E3E48",
                clearColor: "#3E3E48",
                clearHoverBgColor: "#F2F5F7",
            },
            gifButton: {
                color: "#3E3E48",
                bgColor: "#F2F5F7",
                borderColor: "#F2F5F7",
                hoverBgColor: "#F2F5F7",
                outlineColor: "#3E3E48",
                clearColor: "#3E3E48",
                clearHoverBgColor: "#F2F5F7",
            },
            darkGrey: {
                color: "#404446",
                bgColor: "transparent",
                borderColor: "404446",
                hoverBgColor: "#404446",
                outlineColor: "#404446",
                clearColor: "#404446",
                clearHoverBgColor: "#efefef",
            },
            // themed buttons after this are using the colors from patron
            // and must be cross confirmed with the designer
            // please check if a different pallette is required for dark mode
            grey: {
                color: "black",
                bgColor: colors.grey300,
                borderColor: colors.grey300,
                hoverBgColor: colors.grey200,
                outlineColor: colors.grey500,
                clearColor: colors.grey300,
                clearHoverBgColor: colors.grey100,
            },
        },
    },
    memberPill: {
        backgroundColor: "grey50",
    },
    activityIndicator: {
        default: "#AEA1FF",
    },
    contextMenu: {
        backgroundColor: "#fff",
    },
};

export default theme;

export const darkTheme: DefaultTheme = {
    ...theme,
    colors: {
        primaryColor: "#5140C9",
        accentColor: "#01D4CF",
        secondaryColor: "#424157",
        secondaryText: "#B5B3C0",
        white: "#fff",
        grey: "#cdcdcd",
        darkGrey: "#404446",
        lightGrey: "#949D9F",
        black: "#000000",
        darkPink: "#606467",
        calypso: "#131727",
        gray88: "#E0E0E0",
        blueLilac: "#B5AAFF",

        // Theming based colors
        body: "#131727",
        navbarColor: "#2B2D3F",
        navbarBorderColor: "#404446",
        chatBody: "#2B2D3F",
        textLinkColor: "#CFC8FF",
        textLinkColorInactive: "#cdcdcd",
        swipeableItem: "#2B2D3F",
        textColor: "#fff",
        groupBgColor: "#2B2D3F",
        textColorWhiteBackground: "#424b5a",
        error: "#C94343",
        errorBackground: "#FDF9F9",
        altTextColor: "#fff",
        placeholderColor: "#D0D9D9",
        pillColor: "#AEE1E0",
    },
    Divider: {
        bgColor: "darkGrey",
    },
    Button: {
        ...theme.Button,
        colors: {
            ...theme.Button.colors,
            gifButton: {
                color: "#fff",
                bgColor: "#131727",
                borderColor: "#131727",
                hoverBgColor: "#131727",
                outlineColor: "#131727",
                clearColor: "#3E3E48",
                clearHoverBgColor: "#F2F5F7",
            },
            grey: {
                color: "white",
                ...theme.Button.colors.grey,
            },
        },
    },
    TextBlock: {
        fontMetrics: nunitoMetrics,
        fontFamily,
        color: "#fff",
    },
    Heading: {
        fontMetrics: nunitoMetrics,
        fontFamily,
        color: "#fff",
    },
    FormFieldError: {
        color: "#000",
        backgroundColor: "#DB808C",
    },
    memberPill: {
        color: "#fff",
        backgroundColor: "#2B2D3F",
    },
    TextInput: {
        height: "50px",
        borderRadius: "4px",
        fontSize: "16px",
        color: "#fff",
        backgroundColor: "#424157",
        placeholderColor: "#fff",
        variants: {
            disabled: {
                backgroundColor: "#c2d1d9",
            },
            focus: {
                borderColor: "#AEA1FF",
            },
            error: {
                borderColor: "#DB808C",
            },
        },
    },
    contextMenu: {
        backgroundColor: "#2B2D3F",
    },
};
