import React from "react";
import styled from "styled-components/native";
import {Image, Platform} from "react-native";
import {Stack, Text, Box, Inline, InlineItem, VisuallyHidden} from "@unibuddy/patron";
import {Community} from "ubcommunity-shared/src/types";
import {MemberCount} from "ubcommunity-shared/src/Community/MemberCount/MemberCount";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

import {shadowStyle} from "ubcommunity-shared/src/Styles/shadowStyles";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";

const Row = styled.TouchableOpacity`
    padding: 20px;
    flex-direction: row;
    min-width: 0;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
`;

type DisplayCommunityProps = {
    community: Community;
    onPress: () => void;
    loading?: boolean;
};

export const DisplayCommunity = ({community, onPress, loading = false}: DisplayCommunityProps) => {
    const university = community.institutions[0];
    const {darkModeEnabled} = useCommunityTheme();
    return (
        <Box
            w="100%"
            shadow="medium"
            borderRadius="xxsmall"
            backgroundColor={darkModeEnabled ? "secondaryColor" : "white"}
            style={{...shadowStyle}}
        >
            <Row disabled={loading} accessibilityRole="button" onPress={onPress}>
                <Inline space="small" verticalAlign="center" wrap="nowrap">
                    <InlineItem flexShrink={0}>
                        <Image
                            style={{width: 80, height: 80, borderRadius: 6}}
                            source={{uri: university.squareLogo}}
                            width={80}
                            height={80}
                        />
                    </InlineItem>
                    <InlineItem flexShrink={1}>
                        <Stack space="small">
                            <Text size="medium" lineHeight="22px">
                                {community.title || `${university.name} Community`}
                            </Text>
                            <Text size="small">{university.name}</Text>
                            <Box display="flex">
                                {Platform.OS === "web" ? (
                                    <VisuallyHidden>
                                        <Text size="medium">Members in this community: </Text>
                                    </VisuallyHidden>
                                ) : null}
                                <Inline space="medium" verticalAlign="center">
                                    <MemberCount
                                        memberCount={community?.communityMemberCount}
                                        color="primary"
                                    />
                                    {loading ? (
                                        <ActivityIndicator accessibilityLabel="Joining community" />
                                    ) : null}
                                </Inline>
                            </Box>
                        </Stack>
                    </InlineItem>
                </Inline>
            </Row>
        </Box>
    );
};
