import React, {PropsWithChildren, createContext, useContext, useMemo} from "react";
import {useLocation} from "react-router";
import {useLocalStorage} from "@unibuddy/patron";
import {useGetEmailAddressFromInviteIdQuery} from "ubcommunity-shared/src/types";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";

export interface IEmailInviteContext {
    invitedEmail: string;
    resetEmailInvite: () => void;
}

export const EmailInviteContext = createContext<IEmailInviteContext>({
    invitedEmail: "",
    resetEmailInvite: () => {},
});

export function useEmailInvite() {
    return useContext(EmailInviteContext);
}

export function EmailInviteProvider({children}: PropsWithChildren) {
    const {search} = useLocation();
    const inviteId = new URLSearchParams(search).get("inviteId");
    const [emailInvite, setEmailInvite] = useLocalStorage(LocalStorageNames.EMAIL_INVITE, "");
    useGetEmailAddressFromInviteIdQuery({
        skip: !inviteId,
        variables: {
            inviteId,
        },
        onCompleted: (data) => {
            const {invitedEmail} = data.getInviteFromId;
            setEmailInvite(invitedEmail);
        },
    });

    const value = useMemo(
        () => ({
            invitedEmail: emailInvite,
            resetEmailInvite: () => setEmailInvite(""),
        }),
        [emailInvite, setEmailInvite],
    );

    return <EmailInviteContext.Provider value={value}>{children}</EmailInviteContext.Provider>;
}
