import React, {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import {createGlobalStyle} from "styled-components";
import {Dialog} from "@unibuddy/patron";
import {ContextMenu} from "@unibuddy/chat-ui";

const ModalStyle = createGlobalStyle`
    [data-reach-dialog-overlay] {
        z-index: 999 !important;
    }
`;

export enum ModalTypes {
    DIALOG = "dialog",
    BOTTOM_SHEET = "bottom-sheet",
}

export const ModalContext = createContext<{
    openModal(options: ModalOptions): void;
    dismissModal(): void;
}>({
    openModal: () => {},
    dismissModal: () => {},
});

export function useModal() {
    return useContext(ModalContext);
}

export interface ModalOptions {
    component: React.ReactNode;
    size?:
        | "small"
        | "xxsmall"
        | "xsmall"
        | "medium"
        | "large"
        | "xlarge"
        | "xxlarge"
        | "full"
        | "fill";
    type?: ModalTypes;
}

export const ModalProvider = ({children}: PropsWithChildren) => {
    const [modal, setModal] = useState<null | ModalOptions>(null);
    const openModal = useCallback((options: ModalOptions) => {
        setModal({
            ...options,
            type: options.type || ModalTypes.DIALOG,
        });
    }, []);
    const dismissModal = useCallback(() => {
        setModal(null);
    }, []);
    const value = useMemo(() => ({openModal, dismissModal}), [dismissModal, openModal]);

    if (modal?.type === ModalTypes.BOTTOM_SHEET) {
        return (
            <ModalContext.Provider value={value}>
                {modal ? (
                    <ContextMenu
                        onDismiss={() => {
                            dismissModal();
                        }}
                        isOpen={!!modal}
                    >
                        {modal?.component}
                    </ContextMenu>
                ) : null}
                {children}
            </ModalContext.Provider>
        );
    }

    return (
        <ModalContext.Provider value={value}>
            {modal ? (
                <>
                    <ModalStyle />
                    <Dialog
                        isOpen={!!modal}
                        size={modal.size}
                        onDismiss={() => {
                            dismissModal();
                        }}
                    >
                        {modal?.component}
                    </Dialog>
                </>
            ) : null}
            {children}
        </ModalContext.Provider>
    );
};
