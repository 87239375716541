import {Platform} from "react-native";

export const shadowStyle = Platform.select({
    android: {
        elevation: 3,
        shadowColor: "#00000",
    },
    ios: {
        // aligns with "medium" token
        shadowColor: "#00000",
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 0.1,
        shadowRadius: 2,
    },
});

export const shadowStyleCards = Platform.select({
    android: {
        elevation: 3,
        shadowColor: "#00000",
    },
    ios: {
        shadowColor: "#00000",
        shadowOffset: {width: 0, height: 3},
        shadowOpacity: 0.15,
        shadowRadius: 5,
    },
    web: {
        boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.15)",
    },
});
