import React from "react";
import styled from "styled-components";
import {Form as DSForm} from "@unibuddy/patron";

const FullForm = styled(DSForm)`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    height: 100%;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    --webkit-overflow-scrolling: touch;
    min-height: 0;
`;

const Footer = styled.div`
    padding: ${({theme}) => theme?.space?.medium};

    @media (min-width: 768px) {
        padding: ${({theme}) => `${theme?.space?.medium} ${theme?.space?.large}`};
    }
`;

export default function Form({children, footer, ...props}) {
    return (
        <FullForm {...props}>
            <Body>{children}</Body>
            {footer ? <Footer>{footer}</Footer> : null}
        </FullForm>
    );
}
