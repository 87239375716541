import traverse from "traverse";

const reduceRootQuery = (rootQuery) => {
    return Object.keys(rootQuery).reduce(
        (obj: any, key: string) => {
            if (key === "__typename") return obj;

            if (/@persist$/.test(key)) {
                obj[key] = rootQuery[key];
            }
            return obj;
        },
        {__typename: "Query"},
    );
};

const createMapFromEntities = (persistEntities, cachedData, mapped) => {
    return persistEntities.reduce((obj, key) => {
        obj[key] = cachedData[key];
        return obj;
    }, mapped);
};

// creates a map of queries marked with the @persist directive
export const persistenceMapper = async (data: any) => {
    const parsed = JSON.parse(data);

    let mapped: any = {};
    const persistEntities: any[] = [];
    const rootQuery = parsed["ROOT_QUERY"];

    if (!rootQuery) {
        return JSON.stringify(mapped);
    }

    mapped["ROOT_QUERY"] = reduceRootQuery(rootQuery);

    traverse(parsed).forEach((x) => {
        if (x && x.__ref) {
            persistEntities.push(x.__ref);
        }
    });

    mapped = createMapFromEntities(persistEntities, parsed, mapped);

    return JSON.stringify(mapped);
};
