import React, {lazy, Suspense, useEffect} from "react";
import {Route, useParams, Switch} from "react-router-dom";
import {AnalyticsValue, useAnalytics} from "@unibuddy/tracking";
import {createFeatureFlaggingProvider, User} from "ub-feature-flagging-react";
import RelativeRouter from "ubcommunity-shared/src/Navigation/RelativeRouter/RelativeRouter";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {TrackEvents} from "ubcommunity-shared/src/constants";
import {EmailInviteProvider} from "ubcommunity-shared/src/Auth/EmailInviteProvider/EmailInviteProvider";
import {ModalProvider} from "ubcommunity-shared/src/General/ModalProvider/ModalProvider";
import {BlogDesktop, BlogNative} from "./Blog/Blog";
import UniversityRoutes from "./UniversityRoutes/UniversityRoutes";
import {InvitePage} from "./Community/InvitePage/InvitePage";

const AuthRoutes = lazy(() => import("./Auth/AuthRoutes/AuthRoutes"));

const UniversitySlugAnalyticsValue = () => {
    const {universitySlug} = useParams<{universitySlug: string}>();
    return <AnalyticsValue name="universitySlug" value={universitySlug} />;
};

const ProductOpenedAnalyticsEvent = () => {
    const {trackEvent} = useAnalytics();
    useEffect(() => {
        trackEvent(TrackEvents.PRODUCT_OPENED);
    }, [trackEvent]);
    return null;
};

const FeatureFlaggingProvider = createFeatureFlaggingProvider(process.env.LAUNCH_DARKLY_CLIENT_ID);

export default function AppRoutes() {
    const {isLoggedIn, user} = useAuth();
    const {university} = useCommunity();

    function getLaunchDarklyUser() {
        if (isLoggedIn()) {
            return new User(user.id, {
                email: user.email,
                accountRole: user.accountRole,
                university: university ? university : user.university,
            });
        }

        return new User("anonymousId", {anonymous: true});
    }

    return (
        <FeatureFlaggingProvider user={getLaunchDarklyUser()}>
            <ModalProvider>
                <Switch>
                    <Route path="/u/:universitySlug/native/blog">
                        <BlogNative />
                    </Route>
                    <Route path="/u/:universitySlug/blog">
                        <BlogDesktop />
                    </Route>
                    <Route path="/u/:universitySlug">
                        <UniversitySlugAnalyticsValue />
                        <ProductOpenedAnalyticsEvent />
                        <UniversityRoutes />
                    </Route>
                    <Route path="/auth">
                        <ProductOpenedAnalyticsEvent />
                        <RelativeRouter path="/" url="/">
                            <Suspense fallback={null}>
                                <EmailInviteProvider>
                                        <AuthRoutes />
                                </EmailInviteProvider>
                            </Suspense>
                        </RelativeRouter>
                    </Route>
                    <Route path="/invite/:inviteId">
                        <InvitePage />
                    </Route>
                </Switch>
            </ModalProvider>
        </FeatureFlaggingProvider>
    );
}
