import React from "react";
import {View, Text, Stack} from "@unibuddy/patron";
import {ErrorSvg} from "ubcommunity-shared/src/General/Errors/ErrorSvg";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

export function SimpleFallback({
    children,
    hideIllustration = false,
    errorMessage = "Oh no! Something went wrong.",
}: {
    children?: React.ReactNode;
    hideIllustration?: boolean;
    errorMessage?: string;
}) {
    return (
        <View
            flex={1}
            justifyContent="center"
            style={{
                padding: 18,
            }}
        >
            <Stack space="medium" align="center">
                {hideIllustration ? null : <ErrorSvg />}
                <Text style={{...boldFontStyles}} align="center">
                    {errorMessage}
                </Text>
                {children}
            </Stack>
        </View>
    );
}
