import {useApolloClient} from "@apollo/client";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {useApiClient} from "ubcommunity-shared/src/ApiProvider/useApiClient";
import {LocalStorageNames} from "ubcommunity-shared/src/constants";
import {useAsyncLocalStorage} from "../AuthProvider/useAsyncLocalStorage";
import useAuth from "./useAuth";
import {logoutSSOUser} from "../SSO/ssoLogout";

export const useClearLocalState = () => {
    const {setAuth, authState} = useAuth();
    const {select} = useCommunity();
    const client = useApolloClient();
    const {clearCache} = useApiClient();

    const {setValue: setSsoFlowValue} = useAsyncLocalStorage(LocalStorageNames.SSO_FLOW, undefined);
    const {setValue: setInviteId} = useAsyncLocalStorage(LocalStorageNames.INVITE_ID, undefined);

    const clear = async () => {
        await client.clearStore();
        clearCache();
        select();
        setAuth({});

        setInviteId(undefined);

        if (authState?.tokenScheme && authState.tokenScheme.includes("Bearer")) {
            logoutSSOUser(authState?.accessToken);
            setSsoFlowValue(undefined);
        }
    };
    return {clear};
};
