import React from "react";
import {ApolloProvider} from "@apollo/client";

import {useApiClient} from "./useApiClient";

export default function ApiProvider(props) {
    const {client} = useApiClient();

    if (!client) return null;

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}
