import React from "react";
import {Box, Heading, Stack, Text, View} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {CommunityLobbyQuery} from "ubcommunity-shared/src/types";
import {DisplayCommunity} from "./DisplayCommunity";

type Invite = CommunityLobbyQuery["getInvitesForEmail"][0];

type Props = {
    invites: Invite[] | undefined;
    preSelect: (invite: Invite) => void;
};

export enum LobbyPendingInvitesStrings {
    heading = "Your invitations",
    subheading = "Your peers are waiting",
    description = "Click below to enter your community",
}

export const LobbyPendingInvites = ({invites, preSelect}: Props) => {
    const {trackEvent} = useAnalytics();

    return (
        <View flex="2" padding="medium">
            <Stack space="small">
                <Box padding="medium" paddingTop="large">
                    <Heading level="1" size="xsmall">
                        {LobbyPendingInvitesStrings.heading}
                    </Heading>
                </Box>
                <Box padding="medium">
                    <Stack space="medium">
                        <Text size="medium" weight="bold" color="primaryColor">
                            {LobbyPendingInvitesStrings.subheading}
                        </Text>
                        <Text size="medium">{LobbyPendingInvitesStrings.description}</Text>
                    </Stack>
                </Box>
                {invites?.map((invite: Invite) => {
                    return (
                        <DisplayCommunity
                            key={invite.id}
                            onPress={() => {
                                trackEvent("Community Invite Selected", {
                                    communityId: invite.community.id,
                                });
                                preSelect(invite);
                            }}
                            community={invite.community}
                        />
                    );
                })}
            </Stack>
        </View>
    );
};
