import React, {forwardRef, useContext} from "react";
import {Link as RLink} from "react-router-dom";
import styled from "styled-components";

import {RelativeRouterContext} from "../RelativeRouter/RelativeRouter";
import getToProp from "./getToProp";

const Link = styled(RLink)`
    color: ${({theme}) => theme.colors?.primaryColor};

    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`;

/**
 * RelativeLink appends the `to` prop to the pah from the RelativeRouterContext.
 * It can be used outside of the RelativeRouter, in that case it will behave like the
 * normal Link component.
 *
 * @export
 * @param {*} {to, children, ...props}
 * @returns
 */
function RelativeLink({to, children, callback, ...props}, ref) {
    const {url} = useContext(RelativeRouterContext);

    const handleClick = () => {
        if (callback) {
            callback();
        }
    };
    return (
        <Link ref={ref} to={getToProp(to, url)} {...props} onClickCapture={handleClick}>
            {children}
        </Link>
    );
}

export default forwardRef(RelativeLink);
