import React from "react";
import Hotjar from "@hotjar/browser";
import {ENV_NAME} from "ubcommunity-shared/src/constants";

export const HotJar = (): null => {
    const hotjarId = ENV_NAME === "production" ? 3707579 : 3707583;

    React.useEffect(() => {
        const hotjarVersion = 6;
        Hotjar.init(hotjarId, hotjarVersion);
    }, []);

    return null;
};
