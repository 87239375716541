export enum LocalStorageNames {
    EMAIL_INVITE = "email-invite",
    HAS_TRIGGERED_MATCH_ME = "has-triggered-match-me.v1",
    HAS_SHOWN_PROFILE_PICTURE_ANNOUNCEMENT_POPUP = "has-shown-profile-picture-announcement-popup.v1",
    HAS_SHOWN_PROMOTE_ENHANCED_PROFILE = "has-shown-promote-enhanced-profiles.v1",
    HAS_SHOWN_POPUP_TODAY = "has-shown-popup-today.v1",
    HAS_SHOWN_STUDENT_ENROLLMENT_STAGE = "has-shown-student-enrollment-stage.v1",
    HAS_SHOW_FEEL_PREPARED_QUESTIONS = "has-shown-feel-prepared-questions.v1",
    HAS_REDIRECTED_TO_EXPLORE = "has-redirected-to-explore-page",
    HAS_SHOWN_STUDENT_RECRUIT_CTA = "has-shown-student-recruit-cta",
    HAS_SHOWN_STUDENT_CONFIDENCE_CTA = "has-shown-student-confidence-cta.v1",
    HAS_SHOWN_HAS_ENROLLED_CTA = "has-shown-has-enrolled-cta.v1",
    HAS_SHOWN_ADD_INTERESTS_CONFIDENCE_CTA = "has-shown-add-interests-confidence-cta",
    HAS_SHOWN_BANK_CTA = "has-shown-bank-cta.v1",
    INVITE_ID = "community-invite-id.v1",
    MOBILE_DOWNLOAD_BANNER = "mobile-download-banner.v2",
    SIGNUP_STEP_1 = "signup-step-1",
    SSO_FLOW = "sso_flow",
    SSO_UNI_SLUG = "sso_uni_slug",
    TODO_CHAT_LIST = "todo-chat-list",
    TODO_LIST = "todo-list",
}
