import React, {FC, PropsWithChildren, useEffect, useCallback, useMemo, createContext} from "react";
import get from "lodash/get";
import noop from "lodash/noop";
import {AnyUserField} from "ubcommunity-shared/src/types";
import {useAsyncLocalStorage} from "./useAsyncLocalStorage";

export const LOCAL_STORAGE_KEYS = {
    AUTH_STATE: "auth.state.v4",
    AUTH_STATE_META: "auth.state.meta",
};

interface IAuthContext {
    authState?: any;
    user?: AnyUserField;
    loading?: boolean;
    meta: {lastLogin?: number};
    setAuth(auth: any): void;
    isVerified(): boolean;
    isLoggedIn(): boolean;
}

const initialMeta = {};

export const AuthContext = createContext<IAuthContext>({
    setAuth: noop,
    loading: false,
    meta: initialMeta,
    isVerified: () => false,
    isLoggedIn: () => false,
});

/**
 * AuthProvider holds the authentication state internally
 * and passes it to its children through the React context api.
 *
 * @param {Object} props
 */
const AuthProvider: FC<
    PropsWithChildren<{
        initialState?: {
            accessToken?: string;
            me?: {
                anyUser: AnyUserField;
            };
        };
    }>
> = ({children, initialState}) => {
    const {
        value: authState,
        setValue: setAuthState,
        loading,
    } = useAsyncLocalStorage(LOCAL_STORAGE_KEYS.AUTH_STATE, initialState);
    const {
        value: meta,
        setValue: setMeta,
        loading: loadingMeta,
    } = useAsyncLocalStorage(LOCAL_STORAGE_KEYS.AUTH_STATE_META, {});
    const setAuth = useCallback(
        (auth) => {
            setAuthState(auth);
            setMeta({lastLogin: Date.now()});
        },
        [setMeta, setAuthState],
    );
    const user = get(authState, "me.anyUser");

    // For backwards compatibility and the users who are logged in prior to the introduction of the meta data
    // we initialize the lastLogin if there is none.
    useEffect(() => {
        if (!loadingMeta && !meta.lastLogin) {
            setMeta({lastLogin: Date.now()});
        }
    }, [loadingMeta, meta.lastLogin, setMeta]);

    const isVerified = useCallback(() => {
        return !!(
            authState &&
            authState.accessToken &&
            authState.me &&
            (authState.me.anyUser.verified || authState.me.anyUser.accountRole !== "applicant")
        );
    }, [authState]);

    const isLoggedIn = useCallback(() => {
        if (authState?.tokenScheme && authState.tokenScheme.includes("Bearer")) {
            return !!user;
        }

        return (
            !!(authState?.accessToken || authState?.token) &&
            !!(user?.id && user.id !== "anonymousId")
        );
    }, [authState, user]);

    const value = useMemo(
        () => ({user, isVerified, isLoggedIn, authState, setAuth, loading, meta, loadingMeta}),
        [user, isVerified, isLoggedIn, authState, setAuth, loading, meta, loadingMeta],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
