import React from "react";
import {Image} from "react-native";
import {useAnalytics} from "@unibuddy/tracking";
import {LoadingButton, Stack, Text, View, Inline, usePatronTheme} from "@unibuddy/patron";

import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {InviteLinkInfoDto} from "ubcommunity-shared/src/types";
import RelativeLink from "ubcommunity-shared/src/Navigation/RelativeLink/RelativeLink";
import RelativeRouter from "ubcommunity-shared/src/Navigation/RelativeRouter/RelativeRouter";

import {truncateString} from "ubcommunity-shared/src/Utils";
import {TrackEvents, SignUpTrackingPageSources} from "ubcommunity-shared/src/constants";

import {CreateAccountButton} from "./components";
import {SIGNUP_PROMPT_STRINGS} from "./strings";

interface SignUpPromptProps {
    getUser?: () => void;
    linkInfo: Partial<InviteLinkInfoDto>;
    userDataLoading: boolean;
    communityDescription?: string;
    handleSubmit?: () => void;
    loginPath: string;
    isSubmitDisabled?: boolean;
    pageSource: SignUpTrackingPageSources;
}

export const SignUpPrompt = ({
    getUser,
    linkInfo,
    userDataLoading,
    communityDescription,
    handleSubmit,
    loginPath,
    isSubmitDisabled = false,
    pageSource,
}: SignUpPromptProps) => {
    const theme = usePatronTheme;
    const {isLoggedIn} = useAuth();
    const {trackEvent} = useAnalytics();

    return (
        <RelativeRouter
            path={`/u/${linkInfo.universitySlug}`}
            url={`/u/${linkInfo.universitySlug}`}
        >
            <View padding="medium" maxW={360} overflow="hidden">
                <Stack space="large">
                    <View display="flex" alignItems="center">
                        <Image
                            style={{width: 100, height: 100, borderRadius: 6}}
                            source={{
                                uri: linkInfo.squareLogo,
                            }}
                            width={100}
                            height={100}
                        />
                    </View>
                    <View>
                        <Text size="large" align="center">
                            {SIGNUP_PROMPT_STRINGS.WELCOME_TO}
                        </Text>
                    </View>
                    <View>
                        <Text align="center" size="xlarge" weight="bold">
                            {linkInfo.communityTitle}
                        </Text>
                    </View>
                    <View>
                        <Stack space="large">
                            <Text align="center">
                                {truncateString(communityDescription, 250) ||
                                    SIGNUP_PROMPT_STRINGS.JOIN_THE_COMMUNITY}
                            </Text>
                            {!isLoggedIn() && !communityDescription ? (
                                <Text align="center">{SIGNUP_PROMPT_STRINGS.NOT_LOGGED_IN}</Text>
                            ) : null}
                        </Stack>
                    </View>
                    {isLoggedIn() ? (
                        <LoadingButton
                            onClick={() => getUser()}
                            disabled={!linkInfo.valid}
                            loading={userDataLoading}
                            color="primary"
                            block
                            size="lg"
                        >
                            <Text size="large" weight="bold" color="white">
                                {linkInfo.valid
                                    ? SIGNUP_PROMPT_STRINGS.BUTTON_TEXT_LETS_GO
                                    : SIGNUP_PROMPT_STRINGS.BUTTON_TEXT_INVALID_LINK}
                            </Text>
                        </LoadingButton>
                    ) : (
                        <Stack space="medium">
                            <CreateAccountButton
                                handleSubmit={handleSubmit}
                                isSubmitDisabled={isSubmitDisabled}
                            />
                            <View width="100%" alignItems="center">
                                <Inline>
                                    <Text size="large">
                                        {SIGNUP_PROMPT_STRINGS.GOT_ACCOUNT_ALREADY} &nbsp;
                                    </Text>
                                    <Text size="large">
                                        <RelativeLink
                                            to={loginPath}
                                            size="large"
                                            style={{
                                                ...boldFontStyles,
                                                textDecorationLine: "underline",
                                                textDecorationColor: theme?.colors?.textLinkColor,
                                                color: theme?.colors?.textLinkColor,
                                            }}
                                            onClick={() => {
                                                trackEvent(TrackEvents.LOGIN_CLICKED, {
                                                    source: pageSource,
                                                });
                                            }}
                                        >
                                            {SIGNUP_PROMPT_STRINGS.BUTTON_TEXT_LOGIN}
                                        </RelativeLink>
                                    </Text>
                                </Inline>
                            </View>
                        </Stack>
                    )}
                </Stack>
            </View>
        </RelativeRouter>
    );
};
