import React, {useEffect, useState} from "react";
import {View} from "@unibuddy/patron";
import ActivityIndicator from "ubcommunity-shared/src/General/ActivityIndicator/ActivityIndicator";

/**
 * Covers the entirety of the closes relative parent and displays the loading indicator.
 */
export function LoadingCover({
    loading,
    label = "Loading",
    size,
    backgroundColor = "white",
}: {
    loading: boolean;
    label?: string;
    size?: number;
    backgroundColor?: string;
}) {
    const [internalLoading, setInternalLoading] = useState<boolean>();

    useEffect(() => {
        setInternalLoading(loading);
    }, [loading]);

    return (
        <View
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            justifyContent="center"
            alignItems="center"
            backgroundColor={backgroundColor}
            zIndex={1}
            opacity={internalLoading ? 0.8 : 0}
            pointerEvents={internalLoading ? "auto" : "none"}
            transition=".3s ease opacity"
        >
            <ActivityIndicator accessible={loading} accessibilityLabel={label} size={size} />
        </View>
    );
}
