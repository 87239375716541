import {usePatronTheme} from "@unibuddy/patron";
import React from "react";
import {ActivityIndicatorProps, ActivityIndicator as BasicActivityIndicator} from "react-native";

const ActivityIndicator = (props: ActivityIndicatorProps) => {
    const theme = usePatronTheme();

    return <BasicActivityIndicator color={theme?.activityIndicator?.default} {...props} />;
};

export default ActivityIndicator;
