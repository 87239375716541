import {useLocationQuery} from "ubcommunity-shared/src/Hooks/useLocationQuery";

export function useQueryParams() {
    const query = useLocationQuery();

    const getParams = (queryParam: string) => {
        const source = query.get(queryParam);
        return source;
    };

    return {getParams};
}
