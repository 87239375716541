export enum TrackEvents {
    ABOUT_TAB_SELECTED = "About tab selected",
    ACCOUNT_INFORMATION_VIEWED = "Account information viewed",
    ADD_INTERESTS_CTA_DISMISSED = "Add interests CTA dismissed",
    APP_INSTALL_BANNER_CLICKED = "App install banner clicked",
    APP_INSTALL_BANNER_DESKTOP_MODAL_OPEN = "App install banner desktop modal open",
    APP_INSTALL_BANNER_DESKTOP_IOS_CLICKED = "App install banner desktop ios clicked",
    APP_INSTALL_BANNER_DESKTOP_ANDROID_CLICKED = "App install banner desktop android clicked",
    APP_INSTALL_BANNER_DISMISSED = "App install banner dismissed",
    AREA_OF_STUDY_CHANGED = "Area of study changed",
    AREA_OF_STUDY_SELECTED = "Area of study selected",
    AREA_OF_STUDY_SELECT_OPEN = "Area of study select open",
    ATTACHMENT_ICON_CLICKED = "Attachment icon clicked",
    ATTACHMENTS_SELECTED = "Attachments selected",
    ATTACHMENTS_DROPPED = "Attachments dropped (web)",
    BANK_CTA_CLICKED = "Bank CTA clicked",
    BANK_CTA_VIEWED = "Bank CTA viewed",
    BANK_CTA_DISMISSED = "Bank CTA dismissed",
    BANK_LINK_CLICKED = "Bank link clicked",
    BIO_PROVIDED = "Bio provided",
    BIO_SKIPPED = "Bio skipped",
    BLOG_VIEWED = "Blog Viewed",
    CHAT_MENU_CLICKED = "Chat menu clicked",
    CLICK_VIEW_USER_PROFILE = "Click view user profile",
    CLICK_SEND_DIRECT_MESSAGE = "Click send direct message",
    CLICK_SEND_MATCH_MESSAGE = "Click send match message",
    COMMUNITY_HEADER_PROFILE_PICTURE_CLICKED = "Community header profile picture clicked",
    CONTENT_FILTER_AREA_OF_STUDY = "Filtered Content by Area of Study",
    CONTENT_FILTER_SEARCH = "Search Content by Title",
    CONTENT_FILTER_SORT = "Sorting Content",
    CONTENT_FILTER_TOPIC = "Filtered Content by Topic",
    CONTENT_VIEW_POST_CLICKED = "Content view post clicked",
    CONTENT_VIEW_ALL_CLICKED = "Content view all clicked",
    CREATE_YOUR_ACCOUNT_CLICKED = "Create your account clicked",
    COMPLETE_PROFILE_CTA_CLICKED = "Complete profile CTA clicked",
    ENHANCE_PROFILE_POPUP_CLICKED = "Enhance profile pop-up clicked",
    ENHANCE_PROFILE_POPUP_VIEWED = "Enhance profile pop-up viewed",
    FEEL_PREPARED_QUESTIONS_VIEWED = "Feel prepared question viewed",
    FEEL_PREPARED_QUESTIONS_DISMISSED = "Feel prepared question dismissed",
    FORM_SUBMISSION_ERROR = "Form submission error",
    FORM_VALIDATION_ERROR = "Form validation error",
    GROUP_JOINED = "Group joined",
    GROUPS_FILTER_CLICKED = "Groups filter clicked",
    GROUP_MUTE_TOGGLED = "Group mute toggled",
    HOUSING_UPDATED = "Housing Updated",
    IMAGES_CAMERA_OPENED = "Images: Camera opened",
    IMAGES_GALLERY_OPENED = "Images: Gallery opened",
    IMAGES_SELECTED = "Images: Selected",
    INTERESTS_UPDATED = "Interests updated",
    IN_APP_REVIEW_TRIGGERED = "In App Review Triggered",
    LOGIN_CLICKED = "Login clicked",
    MATCH_ME_CTA_VIEWED = "Match me cta viewed",
    MATCH_ME_CTA_CLICKED = "Match me cta clicked",
    MATCH_ME_FORM_CLOSE = "Match me form close",
    MATCH_ME_CLICKED = "Match me clicked",
    MATCHES_LIST_OPENED = "Matches list opened",
    MEMBERS_TAB_SELECTED = "Members tab selected",
    MESSAGE_COPIED = "Message Copied",
    MESSAGE_REACTION_ADDED = "Message Reaction Added",
    MESSAGE_REACTION_REMOVED = "Message Reaction Removed",
    MESSAGE_SENT = "Message Sent",
    MESSAGE_LIKED = "Message Liked",
    MESSAGE_UNLIKED = "Message Unliked",
    MEMBER_LIST_BUTTON_CLICKED = "Member list button clicked",
    NEW_GROUP_BUTTON_CLICKED = "New group button clicked",
    NOTIFICATION_MATCH_FEATURE = "Match feature notification clicked",
    NOTIFICATION_BUDDY_MATCH = "Buddy match notification clicked",
    NOTIFICATION_CHAT_MESSAGE = "Chat message notification clicked",
    NOTIFICATION_UPDATES_MESSAGE = "Updates message notification clicked",
    NOTIFICATION_PROFILE_UPDATE = "Profile update notification opened",
    NOTIFICATION_TODO_TAB_REDIRECTION = "To-Do tab redirection notification clicked",
    NOTIFICATION_ROGUE = "Rogue notification clicked",
    OTHER_INTERESTS_ADDED = "Other interests added",
    PEOPLE_AREA_OF_STUDY_FILTER_SELECTED = "People - area of study filter selected",
    PEOPLE_AREA_OF_STUDY_FILTER_DESELECTED = "People - area of study filter de-selected",
    PEOPLE_PRIVATE_HOUSING_FILTER_SELECTED = "People - private housing filter selected",
    PEOPLE_PRIVATE_HOUSING_FILTER_DESELECTED = "People - private housing filter de-selected",
    PEOPLE_UNIVERSITY_HOUSING_FILTER_SELECTED = "People - university housing filter selected",
    PEOPLE_UNIVERSITY_HOUSING_FILTER_DESELECTED = "People - university housing filter de-selected",
    PEOPLE_INTEREST_FILTER_SELECTED = "People - interest filter selected",
    PEOPLE_INTEREST_FILTER_DESELECTED = "People - interest filter de-selected",
    PEOPLE_SHOW_RESULTS_CLICKED = "People show results clicked",
    PINNED_MSG_TAB_SELECTED = "Pinned messages tab selected",
    POLL_ANSWER_CLICKED = "Poll answer clicked",
    POLL_CREATED = "Poll created",
    POPUP_PROFILE_PICTURE_VIEWED = "Popup profile picture viewed",
    POPUP_PROFILE_PICTURE_BUTTON_CLICKED = "Popup profile picture button clicked",
    POPUP_PROFILE_PICTURE_DISMISSED = "Popup profile picture dismissed",
    PRIVATE_CARD_BUTTON_CLICKED = "Private card button cliked",
    PRIVATE_GROUP_CREATED = "Private Group Created",
    PRODUCT_OPENED = "Product Opened",
    PROFILE_SAVED = "Profile saved",
    PRONOUNS_UPDATED = "Pronouns Updated",
    PROSPECT_LOGGED_IN = "Prospect Logged In",
    PROSPECT_ACCOUNT_CREATED = "Prospect Account Created",
    PROSPECT_ACCOUNT_VERIFIED = "Prospect Account Verified",
    PUBLIC_CARD_BUTTON_CLICKED = "Public card button cliked",
    PUBLIC_GROUP_REQUESTED = "Public Group Requested",
    QUERY_ERROR_CAPTURED = "Query Error Captured",
    GROUP_SEARCH_TRIGGERED = "Group search triggered",
    SOCIAL_SIGN_ON_CLICKED = "Social sign on button clicked",
    STUDENT_HAS_SUBMITTED_ENROLLMENT_STAGE = "Student has submitted enrollment stage",
    STUDENT_RECRUIT_CTA_CLICKED = "Student Recruit CTA clicked",
    STUDENT_RECRUIT_CTA_DISMISSED = "Student Recruit CTA dismissed",
    STARRED_PROFILE_VIEWED = "Starred profile viewed",
    SELECT_NATIONALITY_OPEN = "Select nationality open",
    SELECT_NATIONALITY_DISMISSED = "Select nationality dismissed",
    TAB_PRESSED = "Tab Clicked",
    TODO_TAB_SELECTED = "Todo tab selected",
    TODO_ADD_BUTTON_CLICKED = "Todo add button clicked",
    TODO_REMOVE_BUTTON_CLICKED = "Todo remove button clicked",
    TODO_LINK_CLICKED = "Todo link clicked",
    TODO_ITEM_SET_COMPLETED = "Todo item set completed",
    TODO_ITEM_SET_NOT_COMPLETED = "Todo item set not completed",
    MUTATION_ERROR_CAPTURED = "Mutation Error Captured",
    UPDATE_APP_SUCCESS = "Update app prompt successful",
    UPDATE_APP_FAIL = "Update app prompt ignored",
    UPDATE_PROFILE_CTA_CLICKED = "Update profile CTA clicked",
    UPDATE_PROFILE_CTA_DISMISSED = "Update profile CTA dismissed",
    USER_INITIATED_BLOCKING_FLOW = "User initiated blocking flow",
    USER_COMPLETED_BLOCKING_FLOW = "User completed blocking flow",
    USER_BLOCK = "User Blocked",
    USER_UNBLOCK = "User Unblocked",
    USER_PROFILE_ACTION_BLOCK = "User Profile: Block user pressed",
    USER_PROFILE_ACTION_DIRECT_MESSAGE = "User Profile: Send message pressed",
    USER_PROFILE_ACTION_PRIVATE_GROUP = "User Profile: Add to private group pressed",
    USER_PROFILE_ACTION_REPORT = "User Profile: Report user pressed",
    USER_PROFILE_ACTION_UNBLOCK = "User Profile: Unblock user pressed",
    USER_PROFILE_OPENED = "User Profile Opened",
    USER_PROFILE_ENRICHED_OPENED = "User Profile Enriched Opened",
    VIEW_PUBLIC_GROUPS_CLICKED = "View public groups clicked",
    VIEW_MORE_CARD_CLICKED = "View More Card Clicked",
    WELCOME_PAGE_OPENED = "Welcome page opened",
    SETTINGS_SECURITY_PRIVACY_CLICKED = "Settings - Security privacy clicked",
    SETTINGS_SWITCH_COMMUNITY_CLICKED = "Settings - Switch community clicked",
    SETTINGS_YOUR_PROFILES_CLICKED = "Settings - Your profiles clicked",
}

// These are events that we no longer use DO_NOT_USE_OR_YOU_WILL_BE_FIRED
// I used it somewhere 😈
// eslint-disable-next-line
enum deprecatedEvents {
    GALLERY_ICON_CLICKED = "Images: Gallery icon tapped",
    INTERESTS_ADDED = "Interests added",
    JOIN_COMMUNITY_CLICKED = "Join community clicked",
    NATIONALITY_CHANGED = "Nationality changed",
    SIGNUP_STEP_1 = "Community Signup Step 1",
    SIGNUP_STEP_2 = "Community Signup Step 2",
    SIGNUP_STEP_3 = "Community Signup Step 3",
    SIGNUP_STEP_4 = "Community Signup Step 4",
    UPDATE_PROFILE_CTA_VIEWED = "Update profile CTA viewed",
    VIEW_ALL_MATCHES_CLICKED = "View all matches clicked",
    SIGNUP_STEP_1_v2 = "Community Signup Step 1 - v2",
}

export enum TrackSources {
    ACCOUNT_INFO = "Account Information",
    SIGNUP_FLOW = "Signup flow",
    VIEW_PROFILE = "View profile",
    CHAT = "Chat",
    EXPLORE_PAGE = "Explore page",
    MEMBERS_LIST = "Members List",
}

export enum PageViews {
    SIGNUP_STEP_1_A = "Community Signup Step 1 - variant A",
    SIGNUP_STEP_1_B = "Community Signup Step 1 - variant B",
}
