export enum AccountRoles {
    STUDENT = "Student",
    AMBASSADOR = "Ambassador",
    UNIVERSITY_ADMIN = "University Admin",
    UNIBUDDY_ADMIN = "Unibuddy Admin",
}

// users account roles in backend
// Todo: Use this enum for magic strings, add more values to this enum as we keep removing the magic strings
export const enum UserAccontRoles  {
    APPLICANT = "applicant"
}
