import React, {useEffect, useState} from "react";
import {Image, RefreshControl, ScrollView} from "react-native";
import {SafeAreaView} from "react-native-safe-area-context";
import {NetworkStatus} from "@apollo/client";
import {boolean} from "yup";
import {useAnalytics} from "@unibuddy/tracking";
import {
    Stack,
    Text,
    View,
    Box,
    CheckBox,
    TextLine,
    usePatronTheme,
    Divider,
    Heading,
} from "@unibuddy/patron";
import {
    Community,
    useCommunityLobbyQuery,
    useJoinCommunityMutation,
    useUpdateApplicantSignupSourceMutation,
} from "ubcommunity-shared/src/types";
import {useCommunity} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {LoadingCover} from "ubcommunity-shared/src/General/LoadingCover/LoadingCover";
import {Link} from "ubcommunity-shared/src/General/Link/Link";
import Form from "ubcommunity-shared/src/Forms/Form/Form";
import SubmitButton from "ubcommunity-shared/src/Forms/SubmitButton/SubmitButton";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {useQueryErrorHandler} from "ubcommunity-shared/src/General/Errors/QueryErrorHandler";
import {MutationErrorHandler} from "ubcommunity-shared/src/General/Errors/MutationErrorHandler";
import {usePageTitle} from "ubcommunity-shared/src/Hooks/usePageTitle";
import {PageTitles} from "ubcommunity-shared/src/constants";

import {DisplayCommunity} from "./DisplayCommunity";
import {LobbyEmptyState} from "./LobbyEmptyState";
import {LobbyPendingInvites} from "./LobbyPendingInvites";

interface Invite {
    community: Community;
}

export const Confirm = ({community, onConfirm}: {community: Community; onConfirm: () => void}) => {
    const {isDesktop} = useIsDesktop();
    const theme = usePatronTheme();
    return (
        <Form
            footer={
                <View flexDirection="row" justifyContent="center">
                    <SubmitButton color="primary" block={!isDesktop}>
                        <Box paddingX="large">
                            <Text style={{...boldFontStyles}} size="large" color="white">
                                Join now
                            </Text>
                        </Box>
                    </SubmitButton>
                </View>
            }
            onSubmit={onConfirm}
            initialValues={{confirmPrivacy: false}}
            validationSchema={{
                confirmPrivacy: boolean()
                    .required()
                    .oneOf([true], "Privacy policy must be checked"),
            }}
        >
            <View flex="1">
                <View padding="large" flex="1">
                    <Stack space="xlarge" align="center">
                        <Image
                            source={{
                                width: 100,
                                height: 100,
                                uri: community.institutions[0].squareLogo,
                            }}
                            style={{borderRadius: 6}}
                        />
                        <Stack space="medium">
                            <Heading level="1" size="xxsmall" weight="700" align="center">
                                {community.institutions[0].name}
                            </Heading>
                            <Text size="medium" align="center">
                                Welcome to {community.title}
                            </Text>
                        </Stack>
                    </Stack>
                </View>
                <View padding="medium">
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom="medium"
                    >
                        <CheckBox name="confirmPrivacy" required>
                            I agree to the institution's{" "}
                            <Link
                                external
                                href={community.institutions[0].privacyPolicyUrl}
                                style={{
                                    textDecorationLine: "underline",
                                    textDecorationColor: theme?.colors?.textLinkColor,
                                }}
                            >
                                <TextLine color="textLinkColor">Privacy Policy</TextLine>
                            </Link>
                        </CheckBox>
                    </Box>
                </View>
            </View>
        </Form>
    );
};

export enum GeneralLobbyStrings {
    heading = "Your Communities",
}

export function GeneralLobby({onSelect}: {onSelect?: () => void}) {
    const {user} = useAuth();
    const theme = usePatronTheme();
    const {trackEvent} = useAnalytics();
    const [preSelectedInvite, setPreSelectedInvite] = useState<Invite>();
    const preSelect = (invite) => {
        setPreSelectedInvite(invite);
    };

    usePageTitle(PageTitles.LOBBY);

    const handleSelectInvite = async (_, {setSubmitting}) => {
        try {
            const result = await joinCommunity({
                variables: {communityId: preSelectedInvite.community.id},
            });
            const {data} = result;

            if (user.accountRole === "applicant") {
                await updateApplicantSignupSourceMutation({
                    variables: {communityId: preSelectedInvite.community.id},
                });
            }

            setSubmitting(false);
            if (data?.joinCommunity?.joined) {
                trackEvent("Community Joined", {communityId: preSelectedInvite.community.id});
                select(data.joinCommunity.community);
                setPreSelectedInvite(null);
            } else {
                // TODO: handle failure in some better way?
                // addToast({
                //     title: "Error",
                //     tone: "danger",
                //     ttl: 3000,
                //     text: "Could not join this community",
                // });
            }
            // TODO: add error reporting
        } catch (error) {
            console.log(error);
            // addToast({
            //     title: "Error",
            //     tone: "danger",
            //     ttl: 3000,
            //     text: `Could not join this community. Reason: ${error.message}`,
            // });
        }
    };
    // const {addToast} = useToast();
    const {select} = useCommunity();
    const {data, error, updateQuery, networkStatus, refetch} = useCommunityLobbyQuery({
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        variables: {
            email: user.email,
            userId: user.id,
        },
    });
    const [joinCommunity, {loading: joining, error: joinCommunityMutationError}] =
        useJoinCommunityMutation({
            update: (_, {data}) => {
                if (data?.joinCommunity?.joined) {
                    updateQuery((cacheData) => {
                        if (!cacheData?.getInvitesForEmail) return cacheData;
                        const updatedInvites = cacheData.getInvitesForEmail.filter(
                            (invite) => invite.community.id !== data.joinCommunity.community.id,
                        );
                        return {
                            ...cacheData,
                            getInvitesForEmail: updatedInvites,
                        };
                    });
                }
            },
        });

    const [updateApplicantSignupSourceMutation, {loading: sourceUpdating}] =
        useUpdateApplicantSignupSourceMutation();

    // TEMPORARY
    const {reportErrorAndTrackEvent} = useQueryErrorHandler();
    useEffect(() => {
        if (!data && error) {
            reportErrorAndTrackEvent(error, {
                component: "Lobby",
                query: "useCommunityLobbyQuery",
            });
        }
    }, [data, error, reportErrorAndTrackEvent]);
    // TEMPORARY

    const refreshControl = (
        <RefreshControl
            onRefresh={() => refetch()}
            refreshing={networkStatus === NetworkStatus.refetch}
        />
    );

    const filteredJoinedCommunityIds = new Set();

    const filteredJoinedCommunities = data?.getUser.communities.filter((community) => {
        const isFeatureEnabled = community.institutions[0].isFeatureEnabled;
        if (isFeatureEnabled) {
            filteredJoinedCommunityIds.add(community.id);
        }
        return isFeatureEnabled;
    });

    const filteredPendingInvites = data?.getInvitesForEmail?.filter(
        (invite) =>
            invite.community.institutions[0].isFeatureEnabled &&
            !filteredJoinedCommunityIds.has(invite.community.id),
    );

    const hasPendingInvites = filteredPendingInvites?.length > 0;
    const hasJoinedCommunity = filteredJoinedCommunities?.length > 0;
    const hasEmptyState = !hasJoinedCommunity && !hasPendingInvites;

    const loadingJoiningOrSourceUpdating = joining || sourceUpdating;

    useEffect(() => {
        if (hasEmptyState && data?.getInvitesForEmail?.length === 0) {
            trackEvent("Community signup: User has no community invites");
        }
    }, [trackEvent, hasEmptyState, data?.getInvitesForEmail?.length]);

    if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.refetch)
        return (
            <Box padding="xlarge" position="relative" flex="1">
                <LoadingCover loading backgroundColor="body" />
            </Box>
        );

    if (preSelectedInvite) {
        return (
            <SafeAreaView style={{flex: 1, alignItems: "center"}}>
                <Confirm onConfirm={handleSelectInvite} community={preSelectedInvite.community} />
                {joinCommunityMutationError ? (
                    <View maxW={420} padding="small">
                        <MutationErrorHandler
                            error={joinCommunityMutationError}
                            meta={{component: "Lobby", mutation: "useJoinCommunityMutation"}}
                        />
                    </View>
                ) : null}
            </SafeAreaView>
        );
    }

    if (!hasJoinedCommunity && filteredPendingInvites?.length === 1) {
        preSelect(filteredPendingInvites[0]);
    }

    return (
        <SafeAreaView style={{flex: 1, backgroundColor: theme?.colors?.body}}>
            {loadingJoiningOrSourceUpdating ? (
                <LoadingCover loading backgroundColor="body" />
            ) : null}

            <ScrollView refreshControl={refreshControl}>
                {hasEmptyState ? <LobbyEmptyState /> : null}
                {hasPendingInvites ? (
                    <LobbyPendingInvites invites={filteredPendingInvites} preSelect={preSelect} />
                ) : null}

                {hasJoinedCommunity ? (
                    <View flex="1" padding="medium">
                        {hasPendingInvites ? <Divider /> : ""}
                        <Stack space="small">
                            <Box padding="medium">
                                <Heading level="1" size="xsmall">
                                    {GeneralLobbyStrings.heading}
                                </Heading>
                            </Box>
                            {filteredJoinedCommunities.map((community) => {
                                const handleSelectCommunity = () => {
                                    trackEvent("Community Selected", {
                                        communityId: community.id,
                                    });
                                    select(community);
                                    if (onSelect) {
                                        onSelect(community);
                                    }
                                };
                                return (
                                    <DisplayCommunity
                                        key={community.id}
                                        onPress={handleSelectCommunity}
                                        community={community}
                                    />
                                );
                            })}
                        </Stack>
                    </View>
                ) : null}
            </ScrollView>
        </SafeAreaView>
    );
}
