import React, {useContext} from "react";
import {ButtonLink} from "@unibuddy/patron";
import {RelativeRouterContext} from "../RelativeRouter/RelativeRouter";
import getToProp from "../RelativeLink/getToProp";

/**
 * RelativeLink functionalty except it is using the ButtonLink component from Experimental.
 *
 * @export
 * @param {*} {to, children, ...props}
 * @returns
 */
export default function RelativeButtonLink({to, children, ...props}) {
    const {url} = useContext(RelativeRouterContext);
    return (
        <ButtonLink to={getToProp(to, url)} {...props}>
            {children}
        </ButtonLink>
    );
}
