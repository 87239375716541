import {ENV_NAME} from "./constants";

export const getEnvName = () => {
    try {
        const envName = ENV_NAME;
        return envName ? envName.toUpperCase() : "DEV";
    } catch (e) {
        return "DEV";
    }
};
