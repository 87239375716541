import React, {PropsWithChildren} from "react";
import {useHistory, useRouteMatch} from "react-router";
import {RemoteComponent} from "@unibuddy/mfe-tools";
import {Box, View, Spinner} from "@unibuddy/patron";
import {useAnalytics} from "@unibuddy/tracking";
import {
    API_URL,
    ASSETS_URL,
    GATEWAY_URL,
    CONTENT_FEDERATED_MODULES_APP_URL,
    TrackEvents,
} from "ubcommunity-shared/src/constants";
import RelativeRouter from "ubcommunity-shared/src/Navigation/RelativeRouter/RelativeRouter";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {SideDrawerProvider} from "ubcommunity-shared/src/General/SideDrawerProvider/SideDrawerProvider";
import {dependencies} from "./deps";
import {BackButton} from "ubcommunity-shared/src/Navigation/BackButton/BackButton";

const Remote = (props: any) => {
    const {trackEvent} = useAnalytics();
    const remoteScope = "blogRemoteEntry.js";

    const MockAnalytics = {
        events: {
            pageViewed: () => {},
            blogViewed: (args) => trackEvent(TrackEvents.BLOG_VIEWED, args),
            blogShared: () => {},
            backClickedFromContent: () => {},
            buddyViewed: () => {},
            chatNowClickedFromContent: () => {},
            contentSearchTitle: (args) => trackEvent(TrackEvents.CONTENT_FILTER_SEARCH, args),
            contentFilterAreaOfStudy: (args) =>
                trackEvent(TrackEvents.CONTENT_FILTER_AREA_OF_STUDY, args),
            contentFilterTopic: (args) => trackEvent(TrackEvents.CONTENT_FILTER_TOPIC, args),
            contentSort: (args) => trackEvent(TrackEvents.CONTENT_FILTER_SORT, args),
            contentMultipleCoverImage: () => {},
            ambassadorShareContent: () => {},
            promptToggleEnabled: () => {},
        },
        pageNames: {},
    };

    return (
        <RemoteComponent
            url={`${CONTENT_FEDERATED_MODULES_APP_URL}/${remoteScope}`}
            remoteScope="blog_page_federation"
            remoteModule="./CommunityContent"
            dependencies={dependencies}
            nestedProps={{
                ...props,
                analytics: MockAnalytics,
                env: {
                    API_URL,
                    GATEWAY_URL,
                    ASSETS_URL,
                },
            }}
            fallback={
                <View padding="xlarge">
                    <Spinner />
                </View>
            }
        />
    );
};

const Blog = (props: any) => {
    return (
        <Box overflow="auto">
            <Remote {...props} />
        </Box>
    );
};

const BlogWithBackButton = (props: any) => {
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };
    return (
        <>
            <BackButton callback={handleBack} paddingBottom="xsmall" />
            <Blog />
        </>
    );
};

const BlogWrapper = ({children}: PropsWithChildren) => {
    const {params} = useRouteMatch<{
        universitySlug: string;
    }>();

    const {isDesktop} = useIsDesktop();

    return (
        <RelativeRouter path={`/u/${params.universitySlug}`} url={`/u/${params.universitySlug}`}>
            {isDesktop ? children : <SideDrawerProvider>{children}</SideDrawerProvider>}
        </RelativeRouter>
    );
};

const BlogDesktop = (props: any) => {
    return (
        <BlogWrapper>
            <BlogWithBackButton props={props} />
        </BlogWrapper>
    );
};

const BlogNative = (props: any) => {
    return (
        <BlogWrapper>
            <Blog />
        </BlogWrapper>
    );
};

export {BlogDesktop, BlogNative};
