import React, {useEffect} from "react";

import {Text, View, usePatronTheme} from "@unibuddy/patron";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";

import {TrackEvents} from "ubcommunity-shared/src/constants";
import {SUPPORT_EMAIL} from "ubcommunity-shared/src/constants/constants";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";

import {Link} from "../Link/Link";

type MutationErrorHandlerProps = {
    error: any;
    meta?: {
        component?: string;
        mutation?: string;
    };
    message?: string;
};

export const useMutationErrorHandler = () => {
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    const reportMutationError = (
        error: MutationErrorHandlerProps["error"],
        meta: MutationErrorHandlerProps["meta"],
    ) => {
        trackEvent(TrackEvents.MUTATION_ERROR_CAPTURED, {
            component: meta.component,
            mutation: meta.mutation,
        });
        if (error) {
            reportError(error, {
                tags: {
                    component: meta.component,
                    mutation: meta.mutation,
                },
                level: "error",
            });
            console.error(error);
        }
    };

    return {
        reportMutationError,
    };
};

const DefaultMessage = () => {
    const theme = usePatronTheme();

    return (
        <Text size="medium" color={theme?.FormFieldError?.color}>
            Oh no! Something went wrong. If you continue to experience this issue, please contact{" "}
            <Link
                style={{...boldFontStyles}}
                color={theme?.FormFieldError?.color}
                href="mailto:support@unibuddy.com"
            >
                {SUPPORT_EMAIL}
            </Link>{" "}
        </Text>
    );
};

const ErrorMessage = ({
    error,
    message,
}: {
    error: MutationErrorHandlerProps["error"];
    message: MutationErrorHandlerProps["message"];
}) => {
    const theme = usePatronTheme();

    if (message && message.length > 0) {
        return (
            <Text size="medium" color={theme?.FormFieldError?.color}>
                {message}
            </Text>
        );
    }

    if (error?.message && error?.message.length > 0) {
        return (
            <Text size="medium" color={theme?.FormFieldError?.color}>
                {error.message}
            </Text>
        );
    }

    return <DefaultMessage />;
};

export const MutationErrorHandler = ({
    error = null,
    message = "",
    meta = {
        component: "NotSpecified",
        mutation: "notSpecified",
    },
}: MutationErrorHandlerProps) => {
    const {reportMutationError} = useMutationErrorHandler();
    const theme = usePatronTheme();

    useEffect(() => {
        reportMutationError(error, meta);
    }, [error, meta, reportMutationError]);

    return (
        <View
            w="100%"
            color="red"
            paddingX="xsmall"
            paddingY="small"
            borderRadius="xxsmall"
            borderColor={theme?.FormFieldError?.color}
            bgColor={theme?.FormFieldError?.backgroundColor}
            // @ts-ignore - "accessible" is valid in Native
            accessible
            accessibilityRole="alert"
        >
            <View paddingLeft="xsmall" role="alert">
                <ErrorMessage error={error} message={message} />
            </View>
        </View>
    );
};
