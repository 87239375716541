import React from "react";
import {View} from "@unibuddy/patron";
import {InviteContainer} from "ubcommunity-shared/src/Community/Invite/InviteContainer";
import {ChildrenProps} from "./InvitePage";
import background from "../../Layout/DesktopAuthLayout/image/star_background.png";

export const DesktopInvitePage = ({inviteId}: ChildrenProps) => {
    return (
        <View
            flex="1"
            maxH="100%"
            alignItems="center"
            justifyContent="center"
            minH={0}
            style={{backgroundImage: `url(${background})`}}
        >
            <InviteContainer inviteId={inviteId} />
        </View>
    );
};
