import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router";

import {Drawer, View} from "@unibuddy/patron";

interface Options {
    component: React.ReactNode;
    header: React.ReactNode;
}

export const SideDrawerContext = createContext<{
    openSideDrawer(options: Options): void;
    closeSideDrawer(): void;
}>({
    openSideDrawer: () => {},
    closeSideDrawer: () => {},
});

export function useSideDrawer() {
    return useContext(SideDrawerContext);
}

export function SideDrawerProvider({children}) {
    const [dialog, setDialog] = useState<Options | null>(null);
    const {listen} = useHistory();

    const openSideDrawer = useCallback((options: Options) => {
        setDialog(options);
    }, []);
    const closeSideDrawer = useCallback(() => {
        setDialog(null);
    }, []);

    useEffect(() => {
        const unlisten = listen(() => closeSideDrawer());

        return () => {
            if (unlisten) {
                unlisten();
            }
        };
    }, [listen, closeSideDrawer]);

    const value = useMemo(
        () => ({openSideDrawer, closeSideDrawer}),
        [closeSideDrawer, openSideDrawer],
    );

    return (
        <SideDrawerContext.Provider value={value}>
            <View flex="1" minH={0} maxH="100%" position="relative" overflow="hidden">
                <Drawer
                    id="side-drawer"
                    isOpen={!!dialog}
                    size="small"
                    onDismiss={closeSideDrawer}
                    closeButton={null}
                    showBackdrop={false}
                >
                    {dialog?.header}
                    {dialog?.component}
                </Drawer>
                {children}
            </View>
        </SideDrawerContext.Provider>
    );
}
