import removeTrailingSlash from "ubcommunity-shared/src/Utils/remove-trailing-slash";
import {API_URL} from "ubcommunity-shared/src/constants";

const ENDPOINT_URL = removeTrailingSlash(API_URL);

export const logoutSSOUser = async (token: string) => {
    await fetch(`${ENDPOINT_URL}/acvl/logout`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({bearerToken: token}),
    });
};
