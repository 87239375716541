import React, {createContext, useContext, useMemo} from "react";
import {useLocalStorage} from "@unibuddy/patron";
import isEqual from "lodash/isEqual";
import {Community, UniversityField, useGetCommunitiesQuery} from "ubcommunity-shared/src/types";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";

export interface ICommunityContext {
    selectedCommunity: Community;
    university: UniversityField;
    select(c?: Community): void;
    loading: boolean;
}

export const CommunityContext = createContext<ICommunityContext>({
    selectedCommunity: null,
    university: null,
    loading: false,
    select: () => {},
});

export function useCommunity() {
    return useContext(CommunityContext);
}

export function CommunityProvider({children}) {
    const {user, authState} = useAuth();
    const [selectedCommunity, select] = useLocalStorage(
        `selected-community-${user?.id}`,
        undefined,
    );
    useGetCommunitiesQuery({
        skip: !user || !authState.accessToken,
        variables: {
            userId: user?.id,
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            if (data && selectedCommunity) {
                const updatedCommunity = data.getUser.communities.find(
                    (c) => c.id === selectedCommunity.id,
                );
                // We check if the local and remote communities are equal, if not we update the selected community.
                // This is so we prevent an unnecessary rerender when nothing on the community has changed.
                if (
                    updatedCommunity &&
                    (!isEqual(updatedCommunity.settings, selectedCommunity.settings) ||
                        !isEqual(updatedCommunity.newsFeedGroups, selectedCommunity.newsFeedGroups))
                ) {
                    select(updatedCommunity);
                }
            }
        },
    });

    const value = useMemo(
        () => ({
            selectedCommunity,
            university: selectedCommunity?.institutions?.[0],
            select: (community) => select(community),
            loading: false,
        }),
        [selectedCommunity, select],
    );

    return <CommunityContext.Provider value={value}>{children}</CommunityContext.Provider>;
}
