import {Platform} from "react-native";
import {createHttpLink, ApolloLink} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {fetch as NativeFetch} from "react-native-ssl-pinning";
import {API_URL} from "../constants";

const customFetch = (uri, options) => {
    let customFetch: any = fetch;

    if (Platform.OS === "ios" || Platform.OS === "android") {
        // SSL Pinning
        customFetch = NativeFetch;

        options["headers"] = {
            ...options.headers,
            accept: "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            e_platform: "mobile",
        };
        options["sslPinning"] = {
            certs: ["cert"],
        };

        // use this boolean to disable ssl pinning validation
        options["disableAllSecurity"] = false;
    }

    if (options.body instanceof FormData) return customFetch(uri, options);

    const body = JSON.parse(options.body);
    const {operationName} = body;

    // if (Platform.OS === "ios" || Platform.OS === "android") {
    //     options.headers.client = "Community Native";
    // }

    const paramsArray = [`opname=${encodeURIComponent(operationName)}`];
    const filteredVariablesString = paramsArray.join("&");

    return customFetch(`${uri}?${filteredVariablesString}`, options);
};

export const httpLink = createHttpLink({
    uri: API_URL,
    credentials: "same-origin",
    fetch: customFetch,
});

export const errorMiddleware = (setAuthState) =>
    onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({message, locations, path}) => {
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                );
            });

        if (typeof networkError !== "object") {
            console.log("[Unknown network error]: ", networkError);
            return;
        }

        // web receives network.statusCode
        if (networkError && "statusCode" in networkError && networkError.statusCode === 401) {
            console.log(`[Unauthorized]: The user token is invalid. (web)`);
            setAuthState(undefined);
        }

        // native receives network.status
        if (networkError && "status" in networkError && networkError.status === 401) {
            console.log(`[Unauthorized]: The user token is invalid. (native)`);
            setAuthState(undefined);
        }

        if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            // temporary measure to identity native crashes
            // breadcrumbs indicate that this block is always encounter before an exception
            // check sentry for more details
            try {
                console.log(`[Stringified network error]: ${JSON.stringify(networkError)}`);
            } catch (error) {
                console.log("An error occurred when try to stringify error object");
            }
        }
    });

export const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation).map((data) => {
        return data;
    });
});
