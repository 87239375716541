import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  GenericScalar: any;
  IsoDateTime: any;
  JSONString: any;
  LimitedInt: any;
  /** A field whose value conforms with the standard mongodb object ID as described here: https://docs.mongodb.com/manual/reference/method/ObjectId/#ObjectId. Example: 5e5677d71bdc2ae76344968c */
  ObjectID: any;
  /** ObjectId custom scalar type */
  ObjectId: any;
  Upload: any;
  _FieldSet: any;
};





export type AcceptRequestedPublicGroupInput = {
  communityId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String'];
};

export enum AccountRoles {
  Applicant = 'APPLICANT',
  Mentor = 'MENTOR',
  University = 'UNIVERSITY'
}

export type ActivateApplicantsMutation = {
  __typename?: 'ActivateApplicantsMutation';
  updateCount?: Maybe<Scalars['Int']>;
};

export type ActivateMentorsMutation = {
  __typename?: 'ActivateMentorsMutation';
  updateCount?: Maybe<Scalars['Int']>;
};

export type ActivityField = {
  __typename?: 'ActivityField';
  activitySource?: Maybe<ActivitySourceField>;
  app?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  user?: Maybe<AnyUserField>;
};

export type ActivitySourceField = {
  __typename?: 'ActivitySourceField';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  widgetUrl?: Maybe<Scalars['String']>;
};

export type AddAttributeOptionMutation = {
  __typename?: 'AddAttributeOptionMutation';
  university?: Maybe<UniversityField>;
};

export type AddBotToChatGroupMutation = {
  __typename?: 'AddBotToChatGroupMutation';
  chatGroup?: Maybe<ChatGroupField>;
};

export type AddCustomAttributeMutation = {
  __typename?: 'AddCustomAttributeMutation';
  university?: Maybe<UniversityField>;
};

export type AddDegreeLevelsMutation = {
  __typename?: 'AddDegreeLevelsMutation';
  degreeLevels?: Maybe<Array<Maybe<DegreeLevelField>>>;
};

export type AddDegreesMutation = {
  __typename?: 'AddDegreesMutation';
  degrees?: Maybe<Array<Maybe<DegreeField>>>;
};

export type AddHobsonsConnectIntegration = {
  __typename?: 'AddHobsonsConnectIntegration';
  integration?: Maybe<CampusManagementConnectIntegrationField>;
};

export type AddLocalesToApp = {
  __typename?: 'AddLocalesToApp';
  appLocales?: Maybe<Array<Maybe<AppLocaleField>>>;
};

export type AddResearchTimeMutation = {
  __typename?: 'AddResearchTimeMutation';
  result?: Maybe<Scalars['Boolean']>;
};

export type AddYearOfStudyMutation = {
  __typename?: 'AddYearOfStudyMutation';
  yearOfStudy?: Maybe<Array<Maybe<YearOfStudyField>>>;
};

export type AdminDeleteApplicantMutation = {
  __typename?: 'AdminDeleteApplicantMutation';
  applicant?: Maybe<ApplicantField>;
};

export type AdminRecommendation = {
  __typename?: 'AdminRecommendation';
  active: Scalars['Boolean'];
  cohort?: Maybe<Cohort>;
  description: Scalars['String'];
  forDecisionStage: DecisionStageEnum;
  id: Scalars['ObjectId'];
  resourceType: RecommendationResourceType;
  title: Scalars['String'];
  universityId: Scalars['ObjectId'];
  url: Scalars['String'];
};

export type AdminRecommendationCountPerDecisionStage = {
  __typename?: 'AdminRecommendationCountPerDecisionStage';
  forDecisionStage: DecisionStageEnum;
  recommendationCount: Scalars['Int'];
};

export type AdminRecommendationInput = {
  cohort?: Maybe<CohortInput>;
  description: Scalars['String'];
  forDecisionStage: DecisionStageEnum;
  resourceType: RecommendationResourceTypeEnum;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type AdminUpdateMentorMutation = {
  __typename?: 'AdminUpdateMentorMutation';
  mentor?: Maybe<MentorField>;
};

export type AdminUserField = {
  __typename?: 'AdminUserField';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AgeVerificationInfoField = {
  __typename?: 'AgeVerificationInfoField';
  age?: Maybe<Scalars['Int']>;
  consentAgeForCountry?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  isMinor?: Maybe<Scalars['Boolean']>;
};

export type AgeVerificationInfoInputField = {
  age?: Maybe<Scalars['Int']>;
  consentAgeForCountry?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['Date']>;
  isMinor?: Maybe<Scalars['Boolean']>;
};

export type AlertField = {
  __typename?: 'AlertField';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
};

export type AllPostsField = {
  __typename?: 'AllPostsField';
  blogPosts?: Maybe<Array<Maybe<BlogPostField>>>;
  blogPostsAuthors?: Maybe<Array<Maybe<Scalars['String']>>>;
  blogPostsCount?: Maybe<Scalars['Int']>;
  blogPostsDegrees?: Maybe<Array<Maybe<DegreeField>>>;
  blogPostsTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AmbassadorField = {
  __typename?: 'AmbassadorField';
  accountRole?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reply?: Maybe<Scalars['String']>;
};

export type AmbassadorProfileInformation = {
  __typename?: 'AmbassadorProfileInformation';
  accountRole: Buddies;
  bio: Scalars['String'];
  countryName: Scalars['String'];
  degreeName: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  profilePhoto: Scalars['String'];
  profileUrl: Scalars['String'];
  universityId?: Maybe<Scalars['String']>;
};

export type AmbassadorRecommendation = {
  __typename?: 'AmbassadorRecommendation';
  active: Scalars['Boolean'];
  ambassadorId: Scalars['ObjectId'];
  forDecisionStage?: Maybe<DecisionStageEnum>;
  id: Scalars['ObjectId'];
  resourceType: AmbassadorRecommendationResourceType;
  resourceURL: Scalars['String'];
  sharedWithUser: Scalars['ObjectId'];
  universityId: Scalars['ObjectId'];
};

export type AmbassadorRecommendationInput = {
  resourceType: AmbassadorRecommendationResourceTypeEnum;
  resourceURL: Scalars['String'];
  sharedWithUser: Scalars['String'];
  universityId: Scalars['String'];
};

export type AmbassadorRecommendationResourceType = {
  __typename?: 'AmbassadorRecommendationResourceType';
  displayName: Scalars['String'];
  name: AmbassadorRecommendationResourceTypeEnum;
};

export enum AmbassadorRecommendationResourceTypeEnum {
  Ambassador = 'AMBASSADOR',
  Blog = 'BLOG'
}

export type Analytics = {
  __typename?: 'Analytics';
  attendees: Attendees;
  id: Scalars['ObjectId'];
  messagesSent: Scalars['Float'];
  signups: Scalars['Float'];
  virtualEventId: Scalars['ObjectId'];
};

export type AnalyticsBuddyField = {
  __typename?: 'AnalyticsBuddyField';
  buddyId?: Maybe<Scalars['String']>;
  data?: Maybe<GraphField>;
  name?: Maybe<Scalars['String']>;
};

export type AnalyticsDashboardAuthField = {
  __typename?: 'AnalyticsDashboardAuthField';
  key?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type AnalyticsDashboardMetadata = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AnalyticsMentorField = {
  __typename?: 'AnalyticsMentorField';
  data?: Maybe<GraphField>;
  degree?: Maybe<Scalars['String']>;
  mentorId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AnyChatUserField = {
  __typename?: 'AnyChatUserField';
  accountRole?: Maybe<Scalars['String']>;
  chatIntroMessage?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<MentorDegreeField>>>;
  firstName?: Maybe<Scalars['String']>;
  headlineInfo?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inboxInfoOne?: Maybe<Scalars['String']>;
  inboxInfoTwo?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isEnterprise?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  profilePhoto?: Maybe<Scalars['String']>;
};


export type AnyChatUserFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type AnyUserField = {
  __typename?: 'AnyUserField';
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  chatToken?: Maybe<Scalars['String']>;
  country?: Maybe<CountryField>;
  created?: Maybe<Scalars['String']>;
  degree?: Maybe<DegreeField>;
  degreeLevel?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<MentorDegreeField>>>;
  domesticRegion?: Maybe<DomesticRegionField>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  staffFields?: Maybe<StaffUserField>;
  university?: Maybe<UniversityField>;
  verified?: Maybe<Scalars['Boolean']>;
  year?: Maybe<Scalars['String']>;
};

export type AppLocaleField = {
  __typename?: 'AppLocaleField';
  locales?: Maybe<Array<Maybe<Locale>>>;
  name?: Maybe<Scalars['String']>;
  universityLocalePreferenceApplies?: Maybe<Scalars['Boolean']>;
};

export type AppLocalesFieldInput = {
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};

export type ApplicantBroadcastMessageMutation = {
  __typename?: 'ApplicantBroadcastMessageMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ApplicantCollectionField = {
  __typename?: 'ApplicantCollectionField';
  fieldName?: Maybe<Scalars['String']>;
  isCustomField?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type ApplicantConversationField = {
  __typename?: 'ApplicantConversationField';
  count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type ApplicantCountField = {
  __typename?: 'ApplicantCountField';
  applicantCount?: Maybe<Scalars['Int']>;
};

export type ApplicantField = {
  __typename?: 'ApplicantField';
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  ageVerificationInfo?: Maybe<AgeVerificationInfoField>;
  applicantUniversities?: Maybe<Array<Maybe<ApplicantUniversityLinkField>>>;
  archived?: Maybe<Scalars['Boolean']>;
  chatGroupCount?: Maybe<Scalars['Int']>;
  country?: Maybe<CountryField>;
  created?: Maybe<Scalars['DateTime']>;
  degreeLevel?: Maybe<DegreeLevelField>;
  degreesInterested?: Maybe<Array<Maybe<DegreeField>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasAcceptedMarketing?: Maybe<Scalars['Boolean']>;
  hasAppliedToCurrentUniversity?: Maybe<Scalars['Boolean']>;
  hasReadChatRules?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linkWithUniversity?: Maybe<ApplicantUniversityLinkField>;
  localePreference?: Maybe<UserLocalePreference>;
  marketplaces?: Maybe<Array<Maybe<ApplicantMarketplaceObjectField>>>;
  messageCount?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferences?: Maybe<ApplicantPreferencesField>;
  primaryApp?: Maybe<Scalars['String']>;
  schoolHistory?: Maybe<ApplicantSchoolHistory>;
  signUpLocation?: Maybe<Scalars['String']>;
  unibuddyServicesConsentAccepted?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated favoring applicant_universities */
  universitiesApplied?: Maybe<Array<Maybe<ApplicantUniversityLinkField>>>;
  universitiesInterested?: Maybe<Array<Maybe<UniversityField>>>;
};


export type ApplicantFieldLinkWithUniversityArgs = {
  slug?: Maybe<Scalars['String']>;
};

export type ApplicantInboxField = {
  __typename?: 'ApplicantInboxField';
  botChatGroup?: Maybe<ChatGroupField>;
  chatGroups?: Maybe<Array<Maybe<ChatGroupField>>>;
  universityClearingChatGroup?: Maybe<ChatGroupField>;
};

export type ApplicantInvite = {
  __typename?: 'ApplicantInvite';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ApplicantLabelField = {
  __typename?: 'ApplicantLabelField';
  count?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ApplicantMarketplaceInput = {
  id?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  userData?: Maybe<MarketplaceApplicantData>;
};

export type ApplicantMarketplaceObjectField = {
  __typename?: 'ApplicantMarketplaceObjectField';
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  userData?: Maybe<MarketplaceApplicantUserData>;
};

export type ApplicantPreferencesField = {
  __typename?: 'ApplicantPreferencesField';
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApplicantSchoolHistory = {
  __typename?: 'ApplicantSchoolHistory';
  highSchool?: Maybe<HighSchoolField>;
};

export type ApplicantSignupStats = {
  __typename?: 'ApplicantSignupStats';
  xAxis?: Maybe<Array<Maybe<Scalars['String']>>>;
  yAxis?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ApplicantUniversityLinkField = {
  __typename?: 'ApplicantUniversityLinkField';
  applied?: Maybe<Scalars['Boolean']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueField>>>;
  dateOfEntry?: Maybe<Scalars['Date']>;
  degreeLevel?: Maybe<DegreeLevelField>;
  degreesInterested?: Maybe<Array<Maybe<DegreeField>>>;
  delinked?: Maybe<Scalars['Boolean']>;
  hasAcceptedMarketing?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  setupComplete?: Maybe<Scalars['Boolean']>;
  university?: Maybe<UniversityField>;
};


export type ApplicantUniversityLinkFieldCustomAttributesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};

export type ApplicantUserField = ChatUserField & {
  __typename?: 'ApplicantUserField';
  firstName: Scalars['String'];
  id: Scalars['String'];
};

export type ApplicantsAppliedStats = {
  __typename?: 'ApplicantsAppliedStats';
  applied?: Maybe<Scalars['Int']>;
  notApplied?: Maybe<Scalars['Int']>;
  notReplied?: Maybe<Scalars['Int']>;
};

export type ApplicantsMessagedCount = {
  __typename?: 'ApplicantsMessagedCount';
  messagedCount?: Maybe<Scalars['Int']>;
};

export type ApplicationField = {
  __typename?: 'ApplicationField';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ApplicationInsightModel = {
  __typename?: 'ApplicationInsightModel';
  /** Date in UTC timezone */
  entryDate: Scalars['DateTime'];
  id: Scalars['ObjectId'];
  label?: Maybe<Scalars['String']>;
  totalApplied: Scalars['Int'];
  university: UniversityField;
};

export type AppliedUsersCountInput = {
  /** Date in UTC timezone */
  entryDate: Scalars['DateTime'];
  universityId: Scalars['ObjectId'];
};

export type AppliedUsersCountModel = {
  __typename?: 'AppliedUsersCountModel';
  appliedUsersCount: Scalars['Int'];
};

export type AssistantConversationFeedback = {
  __typename?: 'AssistantConversationFeedback';
  /** When the feedback was created */
  createdAt: Scalars['DateTime'];
  /** Rating from 1-5 on the quality of the conversation */
  rating: Scalars['Float'];
  /** Optional free text for the conversation feedback */
  text?: Maybe<Scalars['String']>;
  /** When the feedback was updated */
  updatedAt: Scalars['DateTime'];
};

export type AssistantConversationHistory = {
  __typename?: 'AssistantConversationHistory';
  feedback?: Maybe<AssistantConversationFeedback>;
  id: Scalars['String'];
  leadGenerated?: Maybe<Scalars['Boolean']>;
  messages: Array<AssistantConversationMessage>;
  universityId: Scalars['String'];
};

export type AssistantConversationLead = {
  __typename?: 'AssistantConversationLead';
  conversationId: Scalars['String'];
  email: Scalars['String'];
  marketingConsent?: Maybe<Scalars['Boolean']>;
  ubConsent: Scalars['Boolean'];
  universityId: Scalars['String'];
};

export type AssistantConversationMessage = {
  __typename?: 'AssistantConversationMessage';
  createdAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<AssistantMessageFeedback>;
  id: Scalars['String'];
  message: Scalars['String'];
  metadata?: Maybe<MessageMetadata>;
  sender: SenderRole;
  universityId: Scalars['String'];
};

export type AssistantMessageFeedback = {
  __typename?: 'AssistantMessageFeedback';
  freeText?: Maybe<Scalars['String']>;
  type: AssistantMessageFeedbackType;
};

export type AssistantMessageFeedbackInput = {
  freeText?: Maybe<Scalars['String']>;
  messageId: Scalars['String'];
  type: AssistantMessageFeedbackType;
};

export enum AssistantMessageFeedbackType {
  Dislike = 'DISLIKE',
  Like = 'LIKE'
}

export type Attachment = {
  __typename?: 'Attachment';
  fileName?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  type: AttachmentType;
};

export type AttachmentDto = {
  fileName?: Maybe<Scalars['String']>;
  link: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  type: AttachmentType;
};

export type AttachmentField = {
  __typename?: 'AttachmentField';
  payload?: Maybe<PayloadField>;
  type?: Maybe<Scalars['String']>;
};

export enum AttachmentType {
  Pdf = 'PDF'
}

export type AttendeeAnalytics = {
  __typename?: 'AttendeeAnalytics';
  accountRole: Scalars['String'];
  active: Scalars['Boolean'];
  id: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
  virtualEventId: Scalars['ObjectId'];
};

export type AttendeeData = {
  accountRole: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ObjectId'];
  virtualEventId: Scalars['ObjectId'];
};

export type Attendees = {
  __typename?: 'Attendees';
  activeApplicants: Scalars['Float'];
  applicants: Scalars['Float'];
};

export type AuthField = {
  __typename?: 'AuthField';
  accessToken?: Maybe<Scalars['String']>;
  device?: Maybe<DeviceField>;
  me?: Maybe<MeField>;
};

export type AuthorSnippetField = {
  __typename?: 'AuthorSnippetField';
  author?: Maybe<MentorField>;
  snippet?: Maybe<Scalars['String']>;
};

export type BaseCustomAttributeField = CustomAttributeField & {
  __typename?: 'BaseCustomAttributeField';
  active?: Maybe<Scalars['Boolean']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSyncedWithCrm?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<CustomAttributeOptionField>>>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type BlockUserMutation = {
  __typename?: 'BlockUserMutation';
  objectsBlockedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BlockUserMutationV2 = {
  __typename?: 'BlockUserMutationV2';
  objectsBlockedIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BlockerField = {
  __typename?: 'BlockerField';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type BlogCommentField = {
  __typename?: 'BlogCommentField';
  author?: Maybe<AnyUserField>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type BlogPostField = {
  __typename?: 'BlogPostField';
  approved?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  author?: Maybe<MentorField>;
  body?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<BlogCommentField>>>;
  coverImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  created?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  flaggedByUser?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lastUpdatedBy?: Maybe<AnyUserField>;
  modified?: Maybe<Scalars['String']>;
  numberOfViews?: Maybe<Scalars['Int']>;
  ogShareUrl?: Maybe<Scalars['String']>;
  readingTime?: Maybe<Scalars['Int']>;
  shareUrl?: Maybe<Scalars['String']>;
  status?: Maybe<BlogStatus>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<BlogTopicField>;
  university?: Maybe<UniversityField>;
  videoContent?: Maybe<VideoContent>;
  wordCount?: Maybe<Scalars['Int']>;
  youtubeVideo?: Maybe<Scalars['String']>;
};


export type BlogPostFieldCoverImagesArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};


export type BlogPostFieldImageArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export enum BlogStatus {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  ReadyToPublish = 'READY_TO_PUBLISH',
  Unpublished = 'UNPUBLISHED'
}

export type BlogTopicField = {
  __typename?: 'BlogTopicField';
  active?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BlogcardSnippetField = {
  __typename?: 'BlogcardSnippetField';
  authorSnippets?: Maybe<Array<Maybe<AuthorSnippetField>>>;
  description?: Maybe<DescriptionField>;
  topicSnippets?: Maybe<Array<Maybe<TopicSnippetField>>>;
};

export type BotUserField = {
  __typename?: 'BotUserField';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export enum Buddies {
  Ambassador = 'AMBASSADOR',
  Staff = 'STAFF'
}

export type BuddiesField = {
  __typename?: 'BuddiesField';
  count?: Maybe<Scalars['Int']>;
  randomizeListSeed?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<BuddyField>>>;
};

export type BuddiesFilterOptionsField = {
  __typename?: 'BuddiesFilterOptionsField';
  customAttributesFilters?: Maybe<Array<Maybe<CustomAttributesFilterField>>>;
  departments?: Maybe<Array<Maybe<Scalars['String']>>>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BuddiesListFilterField = {
  __typename?: 'BuddiesListFilterField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BuddiesListFilterInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BuddyApplicantChatGroupField = {
  __typename?: 'BuddyApplicantChatGroupField';
  created?: Maybe<Scalars['String']>;
  creationSource?: Maybe<CreationSource>;
  id?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};

export type BuddyApplicantChatGroupFieldV2 = {
  __typename?: 'BuddyApplicantChatGroupFieldV2';
  applicant?: Maybe<ApplicantField>;
  created?: Maybe<Scalars['DateTime']>;
  creationSource?: Maybe<CreationSource>;
  id?: Maybe<Scalars['String']>;
  lastMessageSentAt?: Maybe<Scalars['DateTime']>;
  lastMessageText?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  staff?: Maybe<StaffField>;
};

export type BuddyApplicantChatInterventionBuddyField = {
  __typename?: 'BuddyApplicantChatInterventionBuddyField';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  unansweredCount?: Maybe<Scalars['Int']>;
};


export type BuddyApplicantChatInterventionBuddyFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type BuddyField = {
  __typename?: 'BuddyField';
  active?: Maybe<Scalars['Boolean']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueField>>>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupField>>>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  shareUrl?: Maybe<Scalars['String']>;
  staffFields?: Maybe<StaffUserFieldsField>;
  university?: Maybe<UniversityField>;
};


export type BuddyFieldCustomAttributesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type BuddyFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type BuddyInviteField = {
  __typename?: 'BuddyInviteField';
  buddyInvites?: Maybe<Array<Maybe<BuddyInviteListField>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BuddyInviteListField = {
  __typename?: 'BuddyInviteListField';
  accepted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  notFound?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type BulkApplicantField = {
  __typename?: 'BulkApplicantField';
  acceptMarketing?: Maybe<Scalars['Boolean']>;
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  applied?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  blockedByUniversity?: Maybe<Scalars['Boolean']>;
  chatGroupCount?: Maybe<Scalars['Int']>;
  country?: Maybe<CountryField>;
  created?: Maybe<Scalars['DateTime']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributesField>>>;
  dateOfEntry?: Maybe<Scalars['Date']>;
  degreeLevel?: Maybe<DegreeLevelField>;
  degrees?: Maybe<Array<Maybe<DegreeField>>>;
  degreesInterested?: Maybe<Array<Maybe<DegreeField>>>;
  domesticRegion?: Maybe<DomesticRegionField>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<ApplicantLabelField>>>;
  lastName?: Maybe<Scalars['String']>;
  liveEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageCount?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryApp?: Maybe<Scalars['String']>;
  schoolHistory?: Maybe<ApplicantSchoolHistory>;
  signUpLocation?: Maybe<Scalars['String']>;
  signupSource?: Maybe<Scalars['String']>;
  sourceTrackingParams?: Maybe<SourceTrackingParamsField>;
  universitiesInterested?: Maybe<Array<Maybe<UniversityField>>>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type BulkOptOutMarketplaceUniversityMarketingMutation = {
  __typename?: 'BulkOptOutMarketplaceUniversityMarketingMutation';
  me?: Maybe<MeField>;
};

export type ButtonField = {
  __typename?: 'ButtonField';
  inline?: Maybe<Scalars['Boolean']>;
  payload?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CrmApplicantField = {
  __typename?: 'CRMApplicantField';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type CrmField = {
  __typename?: 'CRMField';
  active?: Maybe<Scalars['Boolean']>;
  additionalInfo?: Maybe<Scalars['String']>;
  crmMetaData?: Maybe<CrmMetaDataField>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CrmIntegrationField = {
  __typename?: 'CRMIntegrationField';
  active?: Maybe<Scalars['Boolean']>;
  connection?: Maybe<CrmConnectionField>;
  crm?: Maybe<CrmField>;
  crmApplicantRecords?: Maybe<Array<Maybe<CrmApplicantRecordField>>>;
  crmIdentifier?: Maybe<Scalars['String']>;
  fieldMapping?: Maybe<Array<Maybe<FieldMappingField>>>;
  id?: Maybe<Scalars['String']>;
  jobs?: Maybe<Array<Maybe<CrmSyncJobField>>>;
  lastSyncJob?: Maybe<CrmSyncJobField>;
  syncErrorCount?: Maybe<Scalars['Int']>;
  syncSuccessCount?: Maybe<Scalars['Int']>;
  university?: Maybe<Scalars['String']>;
};


export type CrmIntegrationFieldCrmApplicantRecordsArgs = {
  status?: Maybe<Scalars['String']>;
};

export type CrmMetaDataField = {
  __typename?: 'CRMMetaDataField';
  additionalInfoLink?: Maybe<Scalars['String']>;
  additionalInfoText?: Maybe<Scalars['String']>;
  logoData?: Maybe<Array<Maybe<LogoDataField>>>;
};

export type CsvUploadAmbassadorFieldsMutation = {
  __typename?: 'CSVUploadAmbassadorFieldsMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CsvUploadApplicantsMutation = {
  __typename?: 'CSVUploadApplicantsMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type CampusManagementConnectIntegrationField = {
  __typename?: 'CampusManagementConnectIntegrationField';
  active?: Maybe<Scalars['Boolean']>;
  clientName?: Maybe<Scalars['String']>;
  crm?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  passKey?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
  webServiceUrl?: Maybe<Scalars['String']>;
};

export type CanonicalQuestionField = {
  __typename?: 'CanonicalQuestionField';
  answers?: Maybe<Array<Maybe<FaqAnswerField>>>;
  id: Scalars['String'];
  subTopic?: Maybe<SubTopicField>;
  text?: Maybe<Scalars['String']>;
};

export type CarouselSnippetField = {
  __typename?: 'CarouselSnippetField';
  defaultSnippet?: Maybe<Scalars['String']>;
  description?: Maybe<DescriptionField>;
};

export type ChatConversation = {
  __typename?: 'ChatConversation';
  communityChatDescription?: Maybe<Scalars['String']>;
  communityChatDynamicLink?: Maybe<Scalars['String']>;
  communityChatMembers?: Maybe<Array<UserField>>;
  communityChatTitle?: Maybe<Scalars['String']>;
  communityId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserField>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDirect?: Maybe<Scalars['Boolean']>;
  isNewsFeed: Scalars['Boolean'];
  isPrivate?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Labels>>;
  lastMessage?: Maybe<ChatMessage>;
  members: Array<UserField>;
  mutedBy: Array<Scalars['ID']>;
  pinnedMessages: Array<ChatMessage>;
  pinnedMessagesCount: Scalars['Int'];
  tags?: Maybe<Array<ConversationTag>>;
  totalMemberCount?: Maybe<Scalars['Float']>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
};


export type ChatConversationCommunityChatMembersArgs = {
  offsetPagination: OffsetPagination;
};

export type ChatConversationInboxInputDto = {
  product?: Maybe<Product>;
  universityId?: Maybe<Scalars['String']>;
};

export type ChatGroupAndMessages = {
  __typename?: 'ChatGroupAndMessages';
  chatGroup?: Maybe<ChatGroupField>;
  error?: Maybe<Scalars['String']>;
  hasMore?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<Array<Maybe<MessageField>>>;
  offsetId?: Maybe<Scalars['String']>;
};

export type ChatGroupAuxiliaryField = {
  __typename?: 'ChatGroupAuxiliaryField';
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<ChatGroupLabelField>>>;
  willReply?: Maybe<WillReplyField>;
};

export type ChatGroupField = {
  __typename?: 'ChatGroupField';
  applicant?: Maybe<AnyChatUserField>;
  aux?: Maybe<ChatGroupAuxiliaryField>;
  created?: Maybe<Scalars['String']>;
  creationSource?: Maybe<CreationSource>;
  delinked?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<LastMessageField>;
  members?: Maybe<Array<Maybe<AnyUserField>>>;
  messageCount?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<MessageField>>>;
  messagesForModeration?: Maybe<Array<Maybe<MessageField>>>;
  opponent?: Maybe<AnyChatUserField>;
  pinnedMessages?: Maybe<Array<Maybe<PinnedMessageField>>>;
  resolved?: Maybe<Scalars['Boolean']>;
  sendbirdChannelUrl?: Maybe<Scalars['String']>;
  unansweredByMentor?: Maybe<Scalars['Boolean']>;
  university?: Maybe<UniversityField>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
};


export type ChatGroupFieldMessagesArgs = {
  lastFetched?: Maybe<Scalars['String']>;
  messagesToFetch?: Maybe<Scalars['Int']>;
};


export type ChatGroupFieldMessagesForModerationArgs = {
  lastFetched?: Maybe<Scalars['String']>;
  messagesToFetch?: Maybe<Scalars['Int']>;
};

export type ChatGroupLabelField = {
  __typename?: 'ChatGroupLabelField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChatInboxField = InboxItemInterface & {
  __typename?: 'ChatInboxField';
  id: Scalars['String'];
  lastMessage?: Maybe<InboxItemLastMessageField>;
  members?: Maybe<Array<Maybe<ChatUserField>>>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  created: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  isSenderBlocked?: Maybe<Scalars['Boolean']>;
  likes: Array<Scalars['ObjectId']>;
  likesCount: Scalars['Int'];
  reactions?: Maybe<Array<Reaction>>;
  resolved: Scalars['Boolean'];
  richContent?: Maybe<RichMessageContent>;
  sender: UserField;
  text: Scalars['String'];
};

export type ChatPermission = {
  __typename?: 'ChatPermission';
  id: Scalars['ID'];
  isMember: Scalars['Boolean'];
};

export type ChatReport = {
  __typename?: 'ChatReport';
  actionTaken?: Maybe<Scalars['String']>;
  community: Community;
  conversation: ChatConversation;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  message: ChatMessage;
  moderationTime?: Maybe<Scalars['DateTime']>;
  moderator?: Maybe<UserField>;
  moderatorComment?: Maybe<Scalars['String']>;
  modificationTime?: Maybe<Scalars['DateTime']>;
  product: Product;
  reportee: UserField;
  reporter: UserField;
  reporterComment: Scalars['String'];
  reporterReason: Scalars['String'];
  resolved: Scalars['Boolean'];
  university: UniversityField;
};

export type ChatReportModeratorInputDto = {
  actionTaken?: Maybe<Scalars['String']>;
  chatReportId: Scalars['ObjectID'];
  moderatorComment: Scalars['String'];
  resolved: Scalars['Boolean'];
};

export type ChatReportUserInputDto = {
  conversationId: Scalars['ObjectID'];
  messageId: Scalars['ObjectID'];
  product: Product;
  reporteeId: Scalars['ObjectID'];
  reporterComment: Scalars['String'];
  reporterReason: Scalars['String'];
  universityId: Scalars['ObjectID'];
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  chatRoomAnalytics: ChatRoomAnalytics;
  conversation: ChatConversation;
  description: Scalars['String'];
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type ChatRoomAnalytics = {
  __typename?: 'ChatRoomAnalytics';
  chatRoomAttendees: ChatRoomAttendees;
  chatRoomId: Scalars['ObjectId'];
  chatRoomMessagesSent: Scalars['Float'];
  id: Scalars['ObjectId'];
  virtualEventId: Scalars['ObjectId'];
};

export type ChatRoomAttendeeAnalytics = {
  __typename?: 'ChatRoomAttendeeAnalytics';
  accountRole: Scalars['String'];
  active: Scalars['Boolean'];
  chatRoomId: Scalars['ObjectId'];
  id: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type ChatRoomAttendeeData = {
  accountRole: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  chatRoomId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type ChatRoomAttendees = {
  __typename?: 'ChatRoomAttendees';
  activeApplicants: Scalars['Float'];
  applicants: Scalars['Float'];
};

export type ChatSummaryField = {
  __typename?: 'ChatSummaryField';
  chatGroupCount?: Maybe<Scalars['Int']>;
  chatSummary?: Maybe<Scalars['String']>;
  generatedOn?: Maybe<Scalars['DateTime']>;
  prospectId?: Maybe<Scalars['String']>;
  totalMessages?: Maybe<Scalars['Int']>;
};

export type ChatUserField = {
  firstName: Scalars['String'];
  id: Scalars['String'];
};

export type CheckEmailMutation = {
  __typename?: 'CheckEmailMutation';
  emailExists?: Maybe<Scalars['Boolean']>;
  passwordSetEmailSent?: Maybe<Scalars['Boolean']>;
};

export type ChildOrganisationField = {
  __typename?: 'ChildOrganisationField';
  hasOrganisationAccess: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ClassificationField = {
  __typename?: 'ClassificationField';
  id?: Maybe<Scalars['String']>;
  sentence?: Maybe<Scalars['String']>;
  topSubTopic?: Maybe<TopSubTopicField>;
};

export type CloneLiveEventAndChannelsMutation = {
  __typename?: 'CloneLiveEventAndChannelsMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEvent?: Maybe<LiveEventField>;
};

export type Cohort = {
  __typename?: 'Cohort';
  active?: Maybe<Scalars['Boolean']>;
  areaOfStudies?: Maybe<Array<DegreeField>>;
  areaOfStudyIds?: Maybe<Array<Scalars['String']>>;
  countries?: Maybe<Array<CountryField>>;
  countryIds?: Maybe<Array<Scalars['String']>>;
  created?: Maybe<Scalars['DateTime']>;
  degreeLevelIds?: Maybe<Array<Scalars['String']>>;
  degreeLevels?: Maybe<Array<DegreeLevelField>>;
  domesticRegionIds?: Maybe<Array<Scalars['String']>>;
  domesticRegions?: Maybe<Array<DomesticRegionField>>;
  expectedEntryFrom?: Maybe<Scalars['DateTime']>;
  expectedEntryTo?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type CohortInput = {
  active?: Maybe<Scalars['Boolean']>;
  areaOfStudyIds?: Maybe<Array<Scalars['String']>>;
  countryIds?: Maybe<Array<Scalars['String']>>;
  degreeLevelIds?: Maybe<Array<Scalars['String']>>;
  domesticRegionIds?: Maybe<Array<Scalars['String']>>;
  expectedEntryFrom?: Maybe<Scalars['DateTime']>;
  expectedEntryTo?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};

export type CommentMutation = {
  __typename?: 'CommentMutation';
  comment?: Maybe<BlogCommentField>;
};

export type Community = {
  __typename?: 'Community';
  archived: Scalars['Boolean'];
  blockedMembers?: Maybe<Array<UserField>>;
  communityChatGroups: Array<CommunityChatGroup>;
  communityMemberCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  institutions?: Maybe<Array<UniversityField>>;
  inviteLinks: Array<InviteLinkDto>;
  members?: Maybe<Array<UserField>>;
  newsFeedGroups?: Maybe<Array<NewsFeedGroup>>;
  requestedPublicGroups: Scalars['Int'];
  settings?: Maybe<SettingsObjectType>;
  title?: Maybe<Scalars['String']>;
  welcomeText?: Maybe<Scalars['String']>;
};

export type CommunityChatGroup = {
  __typename?: 'CommunityChatGroup';
  communityId: Scalars['ID'];
  conversation: ChatConversation;
  createdBy?: Maybe<UserField>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  dynamicLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDirect: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  labels?: Maybe<Array<Labels>>;
  members?: Maybe<Array<UserField>>;
  mutedBy: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type CommunityField = {
  __typename?: 'CommunityField';
  id?: Maybe<Scalars['String']>;
};

export type CommunityInviteResponse = {
  __typename?: 'CommunityInviteResponse';
  accepted: Scalars['Boolean'];
  communityId: Scalars['String'];
};

export type CommunityInvites = {
  __typename?: 'CommunityInvites';
  accepted: Scalars['Boolean'];
  accountRole?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  invitedEmail: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  usedInviteLink: Scalars['Boolean'];
  userId?: Maybe<Scalars['String']>;
};

export type CommunityJoinResponse = {
  __typename?: 'CommunityJoinResponse';
  alreadyJoined?: Maybe<Scalars['Boolean']>;
  community: Community;
  joined: Scalars['Boolean'];
};

export type CompletedEvent = {
  __typename?: 'CompletedEvent';
  activeAttendees?: Maybe<Scalars['Int']>;
  attendees?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  messagesSent?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  newSignUps?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CompletedEventsOverview = {
  __typename?: 'CompletedEventsOverview';
  events?: Maybe<Array<Maybe<CompletedEvent>>>;
  eventsCount?: Maybe<Scalars['Int']>;
  hasMore?: Maybe<Scalars['Boolean']>;
};

export type ContentBriefField = {
  __typename?: 'ContentBriefField';
  brief?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isValuable?: Maybe<Scalars['Boolean']>;
  maxDuration?: Maybe<Scalars['Int']>;
  minDuration?: Maybe<Scalars['Int']>;
  sourceMetrics?: Maybe<Array<Maybe<MetricDataPoint>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
};

export type Context = {
  __typename?: 'Context';
  id: Scalars['String'];
  type: ContextType;
};

export enum ContextType {
  IsDirectConversation = 'isDirectConversation',
  IsNewsFeedConversation = 'isNewsFeedConversation',
  University = 'university'
}

export type ConversationContextChatGroupField = {
  __typename?: 'ConversationContextChatGroupField';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ConversationContextField = {
  __typename?: 'ConversationContextField';
  ambassador?: Maybe<AmbassadorField>;
  applicantMsg?: Maybe<Scalars['String']>;
  chatGroup?: Maybe<ConversationContextChatGroupField>;
  id?: Maybe<Scalars['String']>;
};

export type ConversationInbox = {
  __typename?: 'ConversationInbox';
  /** The contexts the conversations on this entity belong to. We should not try to resolve this from the FE as this should be set explicitly on the specific inbox resolvers. */
  contexts: Array<Context>;
  conversations: Array<ChatConversation>;
};

export type ConversationTag = {
  __typename?: 'ConversationTag';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type CookieConsentInputDto = {
  preferences: PreferencesInputType;
  uniqueId: Scalars['String'];
};

export type CookieConsentOutputDto = {
  __typename?: 'CookieConsentOutputDTO';
  preferences: PreferencesOutputType;
  uniqueId: Scalars['String'];
};

export type CountPerEpoch = {
  __typename?: 'CountPerEpoch';
  count?: Maybe<Scalars['Int']>;
  epoch?: Maybe<Scalars['String']>;
};

export type CountryAnalyticsValue = {
  __typename?: 'CountryAnalyticsValue';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type CountryField = {
  __typename?: 'CountryField';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  subRegion?: Maybe<Scalars['String']>;
};

export type CountrySnippetField = {
  __typename?: 'CountrySnippetField';
  country?: Maybe<CountryField>;
  snippet?: Maybe<Scalars['String']>;
};

export type CountryStatField = {
  __typename?: 'CountryStatField';
  count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
};

export type CourseSnippetField = {
  __typename?: 'CourseSnippetField';
  degreeSnippets?: Maybe<Array<Maybe<DegreeSnippetField>>>;
  description?: Maybe<DescriptionField>;
};

export type CreateAlertMutation = {
  __typename?: 'CreateAlertMutation';
  alert?: Maybe<AlertField>;
};

export type CreateApplicantInsightInput = {
  /** Date in UTC timezone */
  entryDate: Scalars['DateTime'];
  label?: Maybe<Scalars['String']>;
  totalApplied: Scalars['Int'];
  universityId: Scalars['ObjectId'];
};

export type CreateApplicantMutation = {
  __typename?: 'CreateApplicantMutation';
  applicant?: Maybe<ApplicantField>;
};

export type CreateApplicantMutationV2 = {
  __typename?: 'CreateApplicantMutationV2';
  applicant?: Maybe<ApplicantField>;
  error?: Maybe<Scalars['String']>;
};

export type CreateAssistantConversationInput = {
  universityId: Scalars['String'];
};

export type CreateBlogTopicMutation = {
  __typename?: 'CreateBlogTopicMutation';
  blogTopic?: Maybe<BlogTopicField>;
};

export type CreateBuddyMutation = {
  __typename?: 'CreateBuddyMutation';
  error?: Maybe<Scalars['String']>;
  existingUser?: Maybe<Scalars['Boolean']>;
  noInvite?: Maybe<Scalars['Boolean']>;
};

export type CreateCanonicalQuestion = {
  __typename?: 'CreateCanonicalQuestion';
  question?: Maybe<CanonicalQuestionField>;
};

export type CreateCommunityApplicantMutation = {
  __typename?: 'CreateCommunityApplicantMutation';
  applicant?: Maybe<ApplicantField>;
};

export type CreateCommunityApplicantMutationV2 = {
  __typename?: 'CreateCommunityApplicantMutationV2';
  applicant?: Maybe<ApplicantField>;
  error?: Maybe<Scalars['String']>;
};

export type CreateCommunityChatGroupInput = {
  communityId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  dynamicLink?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String'];
};

export type CreateCommunityConversationDto = {
  communityId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String'];
};

export type CreateCommunityDto = {
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  universityId: Scalars['ID'];
};

export type CreateCrmServiceUniversityConfigMutation = {
  __typename?: 'CreateCrmServiceUniversityConfigMutation';
  error?: Maybe<Scalars['String']>;
  universityConfiguration?: Maybe<CrmServiceUniversityConfig>;
};

export type CreateExternalEventMutation = {
  __typename?: 'CreateExternalEventMutation';
  externalEvent?: Maybe<ExternalEventField>;
};

export type CreateFaqAnswer = {
  __typename?: 'CreateFaqAnswer';
  faqAnswer?: Maybe<FaqAnswerField>;
};

export type CreateFresherMutation = {
  __typename?: 'CreateFresherMutation';
  error?: Maybe<Scalars['String']>;
  fresher?: Maybe<ApplicantField>;
};

export type CreateLiveEventDetailsMutation = {
  __typename?: 'CreateLiveEventDetailsMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEvent?: Maybe<LiveEventField>;
};

export type CreateLiveEventFeedMutation = {
  __typename?: 'CreateLiveEventFeedMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEventFeed?: Maybe<LiveEventChatGroupField>;
};

export type CreateLiveEventFeedSessionsMutation = {
  __typename?: 'CreateLiveEventFeedSessionsMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEventFeed?: Maybe<LiveEventChatGroupField>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateLiveEventMutation = {
  __typename?: 'CreateLiveEventMutation';
  errors?: Maybe<Array<Maybe<FormFieldValidationError>>>;
  liveEvent?: Maybe<LiveEventField>;
};

export type CreateMarketplaceApplicantMutation = {
  __typename?: 'CreateMarketplaceApplicantMutation';
  authenticatedApplicant?: Maybe<AuthField>;
  error?: Maybe<Scalars['String']>;
};

export type CreateMarketplaceMutation = {
  __typename?: 'CreateMarketplaceMutation';
  marketplace?: Maybe<MarketplaceField>;
};

export type CreateMentorMutation = {
  __typename?: 'CreateMentorMutation';
  existingUser?: Maybe<Scalars['Boolean']>;
  mentor?: Maybe<MentorField>;
  noInvite?: Maybe<Scalars['Boolean']>;
};

export type CreateOpenDayMutation = {
  __typename?: 'CreateOpenDayMutation';
  openDay?: Maybe<OpenDayField>;
};

export type CreateOrganisationBuddyLinkMutation = {
  __typename?: 'CreateOrganisationBuddyLinkMutation';
  organisationBuddyLink?: Maybe<OrganisationBuddyLinkField>;
};

export type CreateOrganisationMutation = {
  __typename?: 'CreateOrganisationMutation';
  organisation?: Maybe<OrganisationField>;
};

export type CreatePostMutation = {
  __typename?: 'CreatePostMutation';
  error?: Maybe<Scalars['String']>;
  post?: Maybe<BlogPostField>;
};

export type CreateRequestedPublicGroupInput = {
  communityId: Scalars['ID'];
  description: Scalars['String'];
  title: Scalars['String'];
};

export type CreateSubTopicMutation = {
  __typename?: 'CreateSubTopicMutation';
  subTopic?: Maybe<SubTopicField>;
};

export type CreateTagMutation = {
  __typename?: 'CreateTagMutation';
  tag?: Maybe<TagField>;
};

export type CreateTopicMutation = {
  __typename?: 'CreateTopicMutation';
  topic?: Maybe<TopicField>;
};

export type CreateUniversityBlockReportMutation = {
  __typename?: 'CreateUniversityBlockReportMutation';
  error?: Maybe<Scalars['String']>;
  universityBlockReport?: Maybe<UniversityBlockReportField>;
};

export type CreateUniversityMutation = {
  __typename?: 'CreateUniversityMutation';
  university?: Maybe<UniversityField>;
};

export type CreateUniversityUserMutation = {
  __typename?: 'CreateUniversityUserMutation';
  universityUser?: Maybe<UniversityUserField>;
};

export type CreateUserMutation = {
  __typename?: 'CreateUserMutation';
  user?: Maybe<UserField>;
};

export type CreateVirtualEventInput = {
  description: Scalars['String'];
  end: Scalars['DateTime'];
  imageUrl: Scalars['String'];
  start: Scalars['DateTime'];
  /** the name of the event */
  title: Scalars['String'];
};

export type CreationSource = {
  __typename?: 'CreationSource';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CrmApplicantRecordField = {
  __typename?: 'CrmApplicantRecordField';
  applicant?: Maybe<CrmApplicantField>;
  crmApplicantId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastSync?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CrmAttributeField = {
  __typename?: 'CrmAttributeField';
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CrmConnectionField = {
  __typename?: 'CrmConnectionField';
  connectionError?: Maybe<Scalars['String']>;
  isConnected?: Maybe<Scalars['Boolean']>;
};

export type CrmDataField = {
  __typename?: 'CrmDataField';
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  fieldName?: Maybe<Scalars['String']>;
  isCustomField?: Maybe<Scalars['Boolean']>;
  sampleValue?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CrmFieldInputType = {
  fieldName?: Maybe<Scalars['String']>;
  isCustomField?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type CrmFieldSyncStatus = {
  __typename?: 'CrmFieldSyncStatus';
  fieldName?: Maybe<Scalars['String']>;
  isSyncActive?: Maybe<Scalars['Boolean']>;
};

export type CrmServiceUniversityConfig = {
  __typename?: 'CrmServiceUniversityConfig';
  allFields?: Maybe<Array<Maybe<CrmDataField>>>;
  connectorEndpoint?: Maybe<Scalars['String']>;
  connectorName?: Maybe<Scalars['String']>;
  dataFormatConfigs?: Maybe<Array<Maybe<DataFormatConfig>>>;
  error?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<CrmDataField>>>;
  id?: Maybe<Scalars['String']>;
  isSyncActive?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  zapierWebhook?: Maybe<Scalars['String']>;
};

export type CrmSyncJobField = {
  __typename?: 'CrmSyncJobField';
  endDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  synced?: Maybe<Scalars['Int']>;
  toSync?: Maybe<Scalars['Int']>;
};

export type CurrentDecisionStageOutput = {
  __typename?: 'CurrentDecisionStageOutput';
  decisionStage?: Maybe<DecisionStageEnum>;
  decisionStageLocation?: Maybe<DecisionStageLocationEnum>;
  notInterestedReason?: Maybe<NotInterestedReasonEnum>;
  universityId: Scalars['ObjectId'];
  updated?: Maybe<Scalars['DateTime']>;
  userId: Scalars['ObjectId'];
};

export type CurrentUserDecisionStageQueryInput = {
  universityId: Scalars['ObjectId'];
};

export type CustomAttributeField = {
  active?: Maybe<Scalars['Boolean']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSyncedWithCrm?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<CustomAttributeOptionField>>>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type CustomAttributeInput = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};

export type CustomAttributeOptionField = {
  __typename?: 'CustomAttributeOptionField';
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomAttributeValueField = {
  attribute?: Maybe<CustomAttributeField>;
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomAttributeValueInput = {
  attribute?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomAttributesField = {
  __typename?: 'CustomAttributesField';
  attribute?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  option?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type CustomAttributesFilterField = {
  __typename?: 'CustomAttributesFilterField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<CustomAttributesFilterOptionField>>>;
};

export type CustomAttributesFilterInputField = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomAttributesFilterOptionField = {
  __typename?: 'CustomAttributesFilterOptionField';
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DataFormatConfig = {
  __typename?: 'DataFormatConfig';
  dataFormatConfigName?: Maybe<Scalars['String']>;
  dataFormatConfigValue?: Maybe<Scalars['String']>;
};

export type DataFormatConfigInputType = {
  dataFormatConfigName?: Maybe<Scalars['String']>;
  dataFormatConfigValue?: Maybe<Scalars['String']>;
};



export type DateValueField = CustomAttributeValueField & {
  __typename?: 'DateValueField';
  attribute?: Maybe<CustomAttributeField>;
  date?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DeactivateApplicantsMutation = {
  __typename?: 'DeactivateApplicantsMutation';
  updateCount?: Maybe<Scalars['Int']>;
};

export type DeactivateMentorsMutation = {
  __typename?: 'DeactivateMentorsMutation';
  updateCount?: Maybe<Scalars['Int']>;
};

export enum DecisionStageEnum {
  Admitted = 'ADMITTED',
  Applied = 'APPLIED',
  Comparing = 'COMPARING',
  Enrolled = 'ENROLLED',
  GatheringInfo = 'GATHERING_INFO',
  Narrowed = 'NARROWED',
  NoneOfTheAbove = 'NONE_OF_THE_ABOVE',
  NotInterested = 'NOT_INTERESTED',
  Preparing = 'PREPARING'
}

export enum DecisionStageLocationEnum {
  ApplicantInsightsPage = 'APPLICANT_INSIGHTS_PAGE',
  CommunityPopUp = 'COMMUNITY_POP_UP',
  EnrolledInsightsPage = 'ENROLLED_INSIGHTS_PAGE',
  WidgetChat = 'WIDGET_CHAT',
  WidgetDecisionPage = 'WIDGET_DECISION_PAGE',
  WidgetForYou = 'WIDGET_FOR_YOU',
  WidgetInbox = 'WIDGET_INBOX',
  WidgetNotInterestedPage = 'WIDGET_NOT_INTERESTED_PAGE',
  WidgetSignup = 'WIDGET_SIGNUP'
}

export type DefaultQuestionsField = {
  __typename?: 'DefaultQuestionsField';
  id?: Maybe<Scalars['String']>;
  subTopic?: Maybe<SubTopicField>;
  text?: Maybe<Scalars['String']>;
};

export type DegreeCategoriesAnalyticsValue = {
  __typename?: 'DegreeCategoriesAnalyticsValue';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type DegreeCategoryField = {
  __typename?: 'DegreeCategoryField';
  categoryType?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hierarchyRank?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parents?: Maybe<Array<Maybe<DegreeCategoryParentField>>>;
  relatedKeywords?: Maybe<Scalars['String']>;
};

export type DegreeCategoryParentField = {
  __typename?: 'DegreeCategoryParentField';
  categoryType?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  hierarchyRank?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DegreeChatUserField = {
  __typename?: 'DegreeChatUserField';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type DegreeField = {
  __typename?: 'DegreeField';
  active?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<DegreeCategoryField>>>;
  degreeLevel?: Maybe<DegreeLevelField>;
  degreeType?: Maybe<Array<Maybe<DegreeType>>>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type DegreeLevelField = {
  __typename?: 'DegreeLevelField';
  archived?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
  yearOfStudy?: Maybe<Array<Maybe<YearOfStudyField>>>;
};


export type DegreeLevelFieldYearOfStudyArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
};

export type DegreeSnippetField = {
  __typename?: 'DegreeSnippetField';
  degree?: Maybe<DegreeField>;
  snippet?: Maybe<Scalars['String']>;
};

export type DegreeStatField = {
  __typename?: 'DegreeStatField';
  count?: Maybe<Scalars['Int']>;
  degree?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
};

export enum DegreeType {
  Bachelor = 'BACHELOR',
  Certification = 'CERTIFICATION',
  Concentration = 'CONCENTRATION',
  Faculty = 'FACULTY',
  Honors = 'HONORS',
  JointHonors = 'JOINT_HONORS',
  Major = 'MAJOR',
  Master = 'MASTER',
  Minor = 'MINOR',
  Phd = 'PHD',
  Program = 'PROGRAM',
  Specialisation = 'SPECIALISATION',
  SpecialProgramme = 'SPECIAL_PROGRAMME'
}

export type DegreeTypeOrderField = {
  __typename?: 'DegreeTypeOrderField';
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<DegreeType>;
};

export type DegreeTypeOrderInput = {
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<DegreeType>;
};

export type DeleteApplicantMutation = {
  __typename?: 'DeleteApplicantMutation';
  applicant?: Maybe<ApplicantField>;
};

export type DeleteAttributeOptionsMutation = {
  __typename?: 'DeleteAttributeOptionsMutation';
  university?: Maybe<UniversityField>;
};

export type DeleteCanonicalQuestion = {
  __typename?: 'DeleteCanonicalQuestion';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteCommunityConversationDto = {
  communityId: Scalars['ID'];
  conversationId: Scalars['ID'];
};

export type DeleteCustomAttributesMutation = {
  __typename?: 'DeleteCustomAttributesMutation';
  university?: Maybe<UniversityField>;
};

export type DeleteDegreeLevelMutation = {
  __typename?: 'DeleteDegreeLevelMutation';
  error?: Maybe<Scalars['String']>;
};

export type DeleteFaqAnswer = {
  __typename?: 'DeleteFaqAnswer';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteKnowledgeBaseEntriesByPatternMatchInput = {
  dryRun: Scalars['Boolean'];
  pagination: GraphQlOffsetPaginationInput;
  pattern: Scalars['String'];
  universityId: Scalars['String'];
};

export type DeleteKnowledgeBaseEntriesByPatternMismatchInput = {
  dryRun: Scalars['Boolean'];
  pagination: GraphQlOffsetPaginationInput;
  pattern: Scalars['String'];
  universityId: Scalars['String'];
};

export type DeleteKnowledgeBaseEntriesByUrlListInput = {
  dryRun: Scalars['Boolean'];
  pagination: GraphQlOffsetPaginationInput;
  universityId: Scalars['String'];
  urlsToDelete: Array<Scalars['String']>;
};

export type DeleteKnowledgeBaseEntriesOutput = {
  __typename?: 'DeleteKnowledgeBaseEntriesOutput';
  totalCount: Scalars['Float'];
  universityId: Scalars['String'];
  urls: Array<Scalars['String']>;
};

export type DeleteLiveEventFeedMutation = {
  __typename?: 'DeleteLiveEventFeedMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEventFeed?: Maybe<LiveEventChatGroupField>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteLiveEventMessageMutation = {
  __typename?: 'DeleteLiveEventMessageMutation';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteLiveEventMutation = {
  __typename?: 'DeleteLiveEventMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteMessageDto = {
  conversationId: Scalars['ObjectId'];
  messageId: Scalars['ObjectId'];
};

export type DeletePostMutation = {
  __typename?: 'DeletePostMutation';
  deleted?: Maybe<Scalars['Boolean']>;
};

export type DeleteRecordingResponse = {
  __typename?: 'DeleteRecordingResponse';
  message: Scalars['String'];
  recordingId: Scalars['ObjectId'];
};

export type DeleteResearchTimeMutation = {
  __typename?: 'DeleteResearchTimeMutation';
  result?: Maybe<Scalars['Boolean']>;
};

export type DeleteSubTopicMutation = {
  __typename?: 'DeleteSubTopicMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteTopicMutation = {
  __typename?: 'DeleteTopicMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteUniversityUserMutation = {
  __typename?: 'DeleteUniversityUserMutation';
  universityUser?: Maybe<UniversityUserField>;
};

export type DeleteUserMutation = {
  __typename?: 'DeleteUserMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteYearOfStudyMutation = {
  __typename?: 'DeleteYearOfStudyMutation';
  error?: Maybe<Scalars['String']>;
};

export type DepartmentSnippetField = {
  __typename?: 'DepartmentSnippetField';
  department?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
};

export type DescriptionField = {
  __typename?: 'DescriptionField';
  descriptionTag?: Maybe<Scalars['String']>;
  instructionTag?: Maybe<Scalars['String']>;
};

export type DeviceField = {
  __typename?: 'DeviceField';
  id?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type DeviceMutation = {
  __typename?: 'DeviceMutation';
  device?: Maybe<DeviceField>;
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  active: Scalars['Boolean'];
  deviceToken: Scalars['String'];
  system: Scalars['String'];
  userId: Scalars['String'];
};

export type DirectConversationDto = {
  communityId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  userToConverseWith: Scalars['String'];
};

export type DirectMessagingApplicant = {
  __typename?: 'DirectMessagingApplicant';
  applicant?: Maybe<ApplicantField>;
  chatGroup?: Maybe<ChatGroupField>;
};

export type DirectMessagingEnquiryTeam = {
  __typename?: 'DirectMessagingEnquiryTeam';
  chatGroup?: Maybe<ChatGroupField>;
  university?: Maybe<UniversityField>;
};

export type DirectMessagingMentor = {
  __typename?: 'DirectMessagingMentor';
  chatGroup?: Maybe<ChatGroupField>;
  mentor?: Maybe<PublicMentorField>;
};

export type DirectMessagingStaffMember = {
  __typename?: 'DirectMessagingStaffMember';
  chatGroup?: Maybe<ChatGroupField>;
  staffMember?: Maybe<BuddyField>;
};

export type Display = {
  __typename?: 'Display';
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type DomesticRegionField = {
  __typename?: 'DomesticRegionField';
  country?: Maybe<CountryField>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EditAlertMutation = {
  __typename?: 'EditAlertMutation';
  alert?: Maybe<AlertField>;
};

export type EditAttributeOptionMutation = {
  __typename?: 'EditAttributeOptionMutation';
  university?: Maybe<UniversityField>;
};

export type EditBlogTopicMutation = {
  __typename?: 'EditBlogTopicMutation';
  blogTopic?: Maybe<BlogTopicField>;
};

export type EditCommunityConversationDto = {
  communityId: Scalars['ID'];
  conversationId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Scalars['String']>>;
  tags?: Maybe<Array<Tag>>;
  title: Scalars['String'];
};

export type EditCommunityDto = {
  archived?: Maybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  settings?: Maybe<SettingsDto>;
  title?: Maybe<Scalars['String']>;
};

export type EditCustomAttributeMutation = {
  __typename?: 'EditCustomAttributeMutation';
  customAttribute?: Maybe<CustomAttributeField>;
};

export type EditCustomAttributesMutation = {
  __typename?: 'EditCustomAttributesMutation';
  customAttributes?: Maybe<Array<Maybe<CustomAttributeField>>>;
  error?: Maybe<Scalars['String']>;
};

export type EditCustomWelcomeMessageMutation = {
  __typename?: 'EditCustomWelcomeMessageMutation';
  error?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export type EditDegreeLevelMutation = {
  __typename?: 'EditDegreeLevelMutation';
  degreeLevel?: Maybe<DegreeLevelField>;
  error?: Maybe<Scalars['String']>;
};

export type EditDegreeNameMutation = {
  __typename?: 'EditDegreeNameMutation';
  degree?: Maybe<DegreeField>;
};

export type EditDegreesMutation = {
  __typename?: 'EditDegreesMutation';
  degrees?: Maybe<Array<Maybe<DegreeField>>>;
};

export type EditExternalEventMutation = {
  __typename?: 'EditExternalEventMutation';
  externalEvent?: Maybe<ExternalEventField>;
};

export type EditLiveEventDetailsMutation = {
  __typename?: 'EditLiveEventDetailsMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEvent?: Maybe<LiveEventField>;
};

export type EditLiveEventFeedMutation = {
  __typename?: 'EditLiveEventFeedMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEventFeed?: Maybe<LiveEventChatGroupField>;
};

export type EditLiveEventMutation = {
  __typename?: 'EditLiveEventMutation';
  errors?: Maybe<Array<Maybe<FormFieldValidationError>>>;
  liveEvent?: Maybe<LiveEventField>;
};

export type EditLiveEventsLandingPageMutation = {
  __typename?: 'EditLiveEventsLandingPageMutation';
  liveEventLandingPage?: Maybe<LiveEventLandingPageField>;
};

export type EditOpenDayMutation = {
  __typename?: 'EditOpenDayMutation';
  openDay?: Maybe<OpenDayField>;
};

export type EditOrganisationMutation = {
  __typename?: 'EditOrganisationMutation';
  organisation?: Maybe<OrganisationField>;
};

export type EditPostMutation = {
  __typename?: 'EditPostMutation';
  error?: Maybe<Scalars['String']>;
  post?: Maybe<BlogPostField>;
};

export type EditUniversityMutation = {
  __typename?: 'EditUniversityMutation';
  university?: Maybe<UniversityField>;
};

export type EditYearOfStudyMutation = {
  __typename?: 'EditYearOfStudyMutation';
  error?: Maybe<Scalars['String']>;
  yearOfStudy?: Maybe<YearOfStudyField>;
};

export type EmbeddedEventField = {
  __typename?: 'EmbeddedEventField';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type EmbeddedEventSnippetField = {
  __typename?: 'EmbeddedEventSnippetField';
  description?: Maybe<DescriptionField>;
  embeddedEvents?: Maybe<Array<Maybe<EmbeddedEventField>>>;
  snippet?: Maybe<Scalars['String']>;
};

export type EnableCustomWelcomeMessageMutation = {
  __typename?: 'EnableCustomWelcomeMessageMutation';
  university?: Maybe<UniversityField>;
};

export type EnableCustomWelcomeMessageStaffMutation = {
  __typename?: 'EnableCustomWelcomeMessageStaffMutation';
  university?: Maybe<UniversityField>;
};

export type EnableFeaturesMutation = {
  __typename?: 'EnableFeaturesMutation';
  university?: Maybe<UniversityField>;
};

export type EnrolledInsightResponse = {
  __typename?: 'EnrolledInsightResponse';
  community: CommunityField;
  id: Scalars['ObjectId'];
  label?: Maybe<Scalars['String']>;
  totalApplicants: Scalars['Float'];
  totalEnrolled: Scalars['Float'];
  university: UniversityField;
};

export type EnrolledInsightsInput = {
  communityId: Scalars['ObjectId'];
  universityId: Scalars['ObjectId'];
};

export type EnterpriseAddOns = {
  __typename?: 'EnterpriseAddOns';
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type EnterpriseMentorField = {
  __typename?: 'EnterpriseMentorField';
  active?: Maybe<Scalars['Boolean']>;
  blogPostCount?: Maybe<Scalars['Int']>;
  chatGroupCount?: Maybe<Scalars['Int']>;
  degree?: Maybe<DegreeField>;
  degreeLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  linkedToOrganisations?: Maybe<Array<Maybe<OrganisationField>>>;
  messageCount?: Maybe<Scalars['Int']>;
  profilePhoto?: Maybe<Scalars['String']>;
  sourceOrganisation?: Maybe<OrganisationField>;
  year?: Maybe<Scalars['String']>;
};

export type EntriesField = {
  __typename?: 'EntriesField';
  active?: Maybe<Scalars['Boolean']>;
  entryMonths?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type EntriesInput = {
  active?: Maybe<Scalars['Boolean']>;
  entryMonths?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type EventPage = {
  __typename?: 'EventPage';
  description: Scalars['String'];
  id: Scalars['ObjectId'];
  /** the name of the page */
  name: Scalars['String'];
  pageImageUrl: Scalars['String'];
};

export type EventPageData = {
  description: Scalars['String'];
  /** the name of the page */
  name: Scalars['String'];
  pageImageUrl: Scalars['String'];
};

export type EventPagesDisplayedOn = {
  __typename?: 'EventPagesDisplayedOn';
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type ExportAnalyticsCsvMutation = {
  __typename?: 'ExportAnalyticsCsvMutation';
  error?: Maybe<Scalars['String']>;
};

export type ExportTimeSheetDataField = {
  __typename?: 'ExportTimeSheetDataField';
  usersTimesheetData?: Maybe<Array<Maybe<UserTimeSheetExportField>>>;
};

export type ExportedReportField = {
  __typename?: 'ExportedReportField';
  created?: Maybe<Scalars['String']>;
  fileLink?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ExternalContent = {
  __typename?: 'ExternalContent';
  description: Scalars['String'];
  eventPagesDisplayedOn: Array<EventPagesDisplayedOn>;
  eventPagesIds: Array<Scalars['ObjectId']>;
  externalContentImageUrl: Scalars['String'];
  externalContentUrl: Scalars['String'];
  id: Scalars['ObjectId'];
  /** the name of the external content */
  name: Scalars['String'];
  showOnHomepage: Scalars['Boolean'];
};

export type ExternalContentData = {
  description: Scalars['String'];
  eventPagesIds?: Maybe<Array<Scalars['ObjectId']>>;
  externalContentImageUrl: Scalars['String'];
  externalContentUrl: Scalars['String'];
  /** the name of the external content */
  name: Scalars['String'];
  showOnHomepage?: Maybe<Scalars['Boolean']>;
};

export type ExternalEventField = {
  __typename?: 'ExternalEventField';
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FaqAnswerField = {
  __typename?: 'FaqAnswerField';
  id: Scalars['String'];
  mentor: MentorField;
  text: Scalars['String'];
};

export type FeatureField = {
  __typename?: 'FeatureField';
  description?: Maybe<Scalars['String']>;
  descriptionClickablePart?: Maybe<Scalars['String']>;
  descriptionLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FeatureInputField = {
  enabledByUnibuddy: Scalars['Boolean'];
  name: Scalars['String'];
  universityCanToggleFeature: Scalars['Boolean'];
};

export type FeatureNotificationRequestDto = {
  applicableRoles: Array<Scalars['String']>;
  body: Scalars['String'];
  featureFlag: Scalars['String'];
  title: Scalars['String'];
  universityIds: Array<Scalars['String']>;
};

export type FeatureRulesDto = {
  __typename?: 'FeatureRulesDTO';
  roles?: Maybe<Array<Scalars['String']>>;
  universities?: Maybe<Array<UniversityOutput>>;
};

export type FeatureToggleInputField = {
  enabledByUniversity?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type FieldMappingField = {
  __typename?: 'FieldMappingField';
  applicationField?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  crmName?: Maybe<Scalars['String']>;
};

export type FieldMappingInput = {
  applicationField?: Maybe<Scalars['String']>;
  crmId?: Maybe<Scalars['String']>;
  crmName?: Maybe<Scalars['String']>;
};

export type FilterUsersDto = {
  areaOfStudy?: Maybe<Array<Scalars['String']>>;
  communityId: Scalars['String'];
  country?: Maybe<Array<Scalars['String']>>;
  hideSelf?: Maybe<Scalars['Boolean']>;
  housingTypes?: Maybe<Array<HousingInput>>;
  interests?: Maybe<Array<Scalars['String']>>;
};

export type FlagField = {
  __typename?: 'FlagField';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type FormFieldValidationError = {
  __typename?: 'FormFieldValidationError';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ForwardApplicantToUniversity = {
  __typename?: 'ForwardApplicantToUniversity';
  chatGroup?: Maybe<ChatGroupField>;
  messages?: Maybe<Array<Maybe<MessageField>>>;
};

export type GenerateAnalyticsDashboardTokenMutation = {
  __typename?: 'GenerateAnalyticsDashboardTokenMutation';
  analyticsAuth?: Maybe<AnalyticsDashboardAuthField>;
};

export type GenerateLiveEventReport = {
  __typename?: 'GenerateLiveEventReport';
  liveEvent?: Maybe<LiveEventField>;
};

export type GenerateRecommendationsInput = {
  areaOfStudyIds?: Maybe<Array<Scalars['ObjectId']>>;
  batchSize: Scalars['Int'];
  countryId?: Maybe<Scalars['ObjectId']>;
  degreeLevelId?: Maybe<Scalars['ObjectId']>;
  domesticRegionId?: Maybe<Scalars['ObjectId']>;
  expectedEntry?: Maybe<Scalars['DateTime']>;
  universityId: Scalars['ObjectId'];
};


export type GetAssistantConversationInput = {
  id: Scalars['String'];
  universityId: Scalars['String'];
};

export type GetChatReportsDto = {
  product: Product;
  resolved?: Maybe<Scalars['Boolean']>;
  universityId: Scalars['ObjectID'];
};

export type GetDownloadableUrlMutation = {
  __typename?: 'GetDownloadableURLMutation';
  url?: Maybe<Scalars['String']>;
};

export type GetInvitesForEmailDto = {
  __typename?: 'GetInvitesForEmailDTO';
  community: Community;
  id: Scalars['ID'];
};

export type GetMarketplaceApplicantMutation = {
  __typename?: 'GetMarketplaceApplicantMutation';
  authenticatedApplicant?: Maybe<AuthField>;
};

export type GetMessageDto = {
  conversationId: Scalars['ObjectId'];
  limit?: Maybe<Scalars['Int']>;
  offsetId?: Maybe<Scalars['ObjectId']>;
};

export type GetRandomAmbassadorsInput = {
  count: Scalars['Float'];
  universityId: Scalars['String'];
};

export type GetRecordingFailureIdsArgs = {
  ids?: Maybe<Array<Scalars['ObjectId']>>;
};

export type GetRecordingFailureIdsResponse = {
  __typename?: 'GetRecordingFailureIdsResponse';
  failedIds: Array<RecordingFailureId>;
};

export type GetSignedS3PostFieldsMutation = {
  __typename?: 'GetSignedS3PostFieldsMutation';
  postFieldsObject?: Maybe<SignedS3PostFields>;
};

export type GetSignedS3UrlMutation = {
  __typename?: 'GetSignedS3UrlMutation';
  signedObject?: Maybe<SignedS3UrlField>;
};

export type GetUserReportsDto = {
  product: Product;
  resolved?: Maybe<Scalars['Boolean']>;
  universityId: Scalars['ObjectID'];
};

export type GifDto = {
  aspectRatio: Scalars['Float'];
  height: Scalars['Int'];
  id: Scalars['String'];
  type: GifType;
  width: Scalars['Int'];
};

export enum GifType {
  Gif = 'Gif',
  Sticker = 'Sticker'
}

export type Giphy = {
  __typename?: 'Giphy';
  aspectRatio: Scalars['Float'];
  height: Scalars['Int'];
  id: Scalars['String'];
  type: GifType;
  width: Scalars['Int'];
};

export type GraphField = {
  __typename?: 'GraphField';
  xAxis?: Maybe<Array<Maybe<Scalars['String']>>>;
  yAxis?: Maybe<Array<Maybe<Scalars['Float']>>>;
  yWeights?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GraphQlOffsetPaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type GroupField = {
  __typename?: 'GroupField';
  type?: Maybe<Scalars['String']>;
};

export type HideMentorInMarketplaceMutation = {
  __typename?: 'HideMentorInMarketplaceMutation';
  mentor?: Maybe<MentorField>;
};

export type HighSchoolField = {
  __typename?: 'HighSchoolField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type HighSchoolTypeField = {
  __typename?: 'HighSchoolTypeField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum HousingCategory {
  Na = 'NA',
  Private = 'PRIVATE',
  University = 'UNIVERSITY'
}

export type HousingInput = {
  category: HousingCategory;
  name?: Maybe<Scalars['String']>;
};

export type HousingObject = {
  __typename?: 'HousingObject';
  category: HousingCategory;
  name?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
};

export type ImageDto = {
  url: Scalars['String'];
};

export type InboxItemInterface = {
  id: Scalars['String'];
  lastMessage?: Maybe<InboxItemLastMessageField>;
  members?: Maybe<Array<Maybe<ChatUserField>>>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type InboxItemLastMessageField = {
  __typename?: 'InboxItemLastMessageField';
  created?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isFromUser?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
};

export type InboxItemUnion = ChatInboxField | UniversityChatInboxField;

export type InboxPromptAnswer = {
  __typename?: 'InboxPromptAnswer';
  answeredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type InboxPromptAnswerInput = {
  answers: Array<InboxPromptAnswerInputItem>;
  questionId: Scalars['ObjectId'];
  universityId: Scalars['ObjectId'];
};

export type InboxPromptAnswerInputItem = {
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type InboxPromptInput = {
  tags?: Maybe<Array<Scalars['String']>>;
  universityId: Scalars['ObjectId'];
};

export type InboxPromptOption = {
  __typename?: 'InboxPromptOption';
  active: Scalars['Boolean'];
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type InboxPromptQuestion = {
  __typename?: 'InboxPromptQuestion';
  active: Scalars['Boolean'];
  answers: Array<InboxPromptAnswer>;
  id: Scalars['ObjectId'];
  options: Array<InboxPromptOption>;
  tags: Array<Scalars['String']>;
  text: Scalars['String'];
};


export type InboxPromptQuestionAnswersArgs = {
  inboxPromptInput: InboxPromptInput;
};

export type InboxUniversityField = {
  __typename?: 'InboxUniversityField';
  id: Scalars['String'];
  name: Scalars['String'];
  squareLogo?: Maybe<Scalars['String']>;
};

export type InitialiseRecordingArgs = {
  channelId: Scalars['String'];
};

export type InitialiseRecordingResponse = {
  __typename?: 'InitialiseRecordingResponse';
  channelId: Scalars['String'];
  deleted: Scalars['Boolean'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  layoutMode: Scalars['Float'];
  layoutStatus: Scalars['String'];
  recordingStatus: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type InputField = {
  __typename?: 'InputField';
  action?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeholder?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Insights = {
  __typename?: 'Insights';
  communityId: Scalars['ID'];
  totalApplicantsInCommunity: Scalars['Float'];
  totalEnrolled: Scalars['Float'];
};

export type InsightsField = {
  __typename?: 'InsightsField';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<TopicStatisticField>>>;
};

export type Interest = {
  __typename?: 'Interest';
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type InterestInput = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type InterestObject = {
  __typename?: 'InterestObject';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type InterventionMessageField = {
  __typename?: 'InterventionMessageField';
  message?: Maybe<MessageField>;
  title?: Maybe<Scalars['String']>;
};

export type Invite = {
  __typename?: 'Invite';
  communityId: Scalars['String'];
  emailId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitedEmail: Scalars['String'];
  reminderStatus?: Maybe<Scalars['String']>;
  sendUnibuddyEmail?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type InviteApplicantsMutation = {
  __typename?: 'InviteApplicantsMutation';
  alreadyExistingInvites?: Maybe<Array<Maybe<Scalars['String']>>>;
  failedInvites?: Maybe<Array<Maybe<Scalars['String']>>>;
  successfulInvites?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InviteBuddiesMutation = {
  __typename?: 'InviteBuddiesMutation';
  failedInvites?: Maybe<Array<Maybe<Scalars['String']>>>;
  successfulInvites?: Maybe<Array<Maybe<BuddyInviteListField>>>;
};

export type InviteFilter = {
  accountRole?: Maybe<Array<AccountRoles>>;
  inviteMethod?: Maybe<Array<InviteMethods>>;
  status?: Maybe<Array<InviteStatus>>;
};

export type InviteInformation = {
  __typename?: 'InviteInformation';
  community: Community;
  id: Scalars['ID'];
  invite?: Maybe<Invite>;
  /** Whether the user is a member of the community. Requires authentication. Returns "null" if user is not authenticated, "false" if user is authenticated but not a member, and "true" if user is authenticated and a member. */
  isPartOfCommunity?: Maybe<Scalars['Boolean']>;
  university: UniversityField;
};

export type InviteInformationInput = {
  inviteId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type InviteLinkDto = {
  __typename?: 'InviteLinkDto';
  active: Scalars['Boolean'];
  communityId: Scalars['String'];
  expiringAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  labels: Array<Scalars['String']>;
};

export type InviteLinkInfoDto = {
  __typename?: 'InviteLinkInfoDto';
  communityId: Scalars['String'];
  communityTitle: Scalars['String'];
  id: Scalars['String'];
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  squareLogo?: Maybe<Scalars['String']>;
  universityName: Scalars['String'];
  universitySlug: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type InviteLinkInput = {
  active: Scalars['Boolean'];
  communityId: Scalars['ID'];
  labels: Array<Scalars['String']>;
};

export type InviteMentorsMutation = {
  __typename?: 'InviteMentorsMutation';
  successfulInvites?: Maybe<Array<Maybe<MentorInviteField>>>;
};

export enum InviteMethods {
  Email = 'EMAIL',
  Invitelink = 'INVITELINK'
}

export enum InviteStatus {
  Invited = 'INVITED',
  Joined = 'JOINED'
}



export type JoinChatGroupMutation = {
  __typename?: 'JoinChatGroupMutation';
  chatGroup?: Maybe<ChatGroupField>;
};

export type KnowledgeBaseDeleteEntryInput = {
  id: Scalars['String'];
};

export type KnowledgeBaseDeleteEntryOutput = {
  __typename?: 'KnowledgeBaseDeleteEntryOutput';
  ok: Scalars['Boolean'];
};

export type KnowledgeBaseEmbedding = {
  __typename?: 'KnowledgeBaseEmbedding';
  createdAt: Scalars['DateTime'];
  ids: Array<Scalars['String']>;
  url: Scalars['String'];
};

export type KnowledgeBaseSourceQueryInput = {
  limit: Scalars['Float'];
  offset: Scalars['Float'];
  universityId: Scalars['String'];
};

export type KnowledgeBaseSourcesByUrl = {
  __typename?: 'KnowledgeBaseSourcesByUrl';
  ids: Array<Scalars['String']>;
  url: Scalars['String'];
};

export type KnowledgeBaseSourcesListInput = {
  universityId: Scalars['String'];
  urlSearchPattern: Scalars['String'];
};

export type LabelSentenceMutation = {
  __typename?: 'LabelSentenceMutation';
  labelSentence: LabellingField;
};

export type LabellingField = {
  __typename?: 'LabellingField';
  chatGroupId?: Maybe<Scalars['String']>;
  classifiedSentenceId?: Maybe<Scalars['String']>;
  embeddedSubTopic?: Maybe<TopSubTopicField>;
  id?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  subTopicExternalId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  topicExternalId?: Maybe<Scalars['String']>;
};

export type LabellingSentenceCandidate = {
  __typename?: 'LabellingSentenceCandidate';
  classifiedSentenceId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  topSubTopics?: Maybe<Array<Maybe<TopSubTopicField>>>;
};

export type Labels = {
  __typename?: 'Labels';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LanguageField = {
  __typename?: 'LanguageField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LastMessageField = {
  __typename?: 'LastMessageField';
  created?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  sender?: Maybe<AnyUserField>;
  text?: Maybe<Scalars['String']>;
};

export type LastRead = {
  __typename?: 'LastRead';
  conversationId: Scalars['String'];
  messageId: Scalars['ObjectId'];
  userId: Scalars['String'];
};

export type LeaveChatGroupMutation = {
  __typename?: 'LeaveChatGroupMutation';
  chatGroup?: Maybe<ChatGroupField>;
};

export type LevelSnippetField = {
  __typename?: 'LevelSnippetField';
  level?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
};

export type LikeMessageDto = {
  conversationId: Scalars['ObjectId'];
  messageId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type LikeMessageMutation = {
  __typename?: 'LikeMessageMutation';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};


export type LinkOrganisationsMutation = {
  __typename?: 'LinkOrganisationsMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ListAdminRecommendationInput = {
  forDecisionStage: DecisionStageEnum;
};

export type ListBuddyApplicantConversationsField = {
  __typename?: 'ListBuddyApplicantConversationsField';
  conversations?: Maybe<Array<Maybe<BuddyApplicantChatGroupFieldV2>>>;
  totalConversations?: Maybe<Scalars['Int']>;
};

export type ListMentorApplicantConversationsField = {
  __typename?: 'ListMentorApplicantConversationsField';
  conversations?: Maybe<Array<Maybe<MentorApplicantChatGroupField>>>;
  totalConversations?: Maybe<Scalars['Int']>;
};

export type LiveEventChatGroupField = {
  __typename?: 'LiveEventChatGroupField';
  applicantCount?: Maybe<Scalars['Int']>;
  applicants?: Maybe<Array<Maybe<ApplicantField>>>;
  chatGroup?: Maybe<ChatGroupField>;
  description?: Maybe<Scalars['String']>;
  hasEnquiryTeam?: Maybe<Scalars['Boolean']>;
  hostIdsVideoSessions?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  mentors?: Maybe<Array<Maybe<MentorField>>>;
  name?: Maybe<Scalars['String']>;
  recordingEnabled?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  staff?: Maybe<Array<Maybe<UserField>>>;
  university?: Maybe<UniversityField>;
  universityUsers?: Maybe<Array<Maybe<UniversityUserField>>>;
  videoEnabled?: Maybe<Scalars['Boolean']>;
  videoSessions?: Maybe<Array<Maybe<VideoSessionField>>>;
};

export type LiveEventChatGroupsInputField = {
  description?: Maybe<Scalars['String']>;
  hostIdsVideoSessions?: Maybe<Array<Maybe<Scalars['String']>>>;
  includeUniversity?: Maybe<Scalars['Boolean']>;
  mentorIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  staffIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  videoSessions?: Maybe<Array<Maybe<VideoSession>>>;
};

export type LiveEventDetailedReport = {
  __typename?: 'LiveEventDetailedReport';
  applicantCountries?: Maybe<Array<Maybe<LiveEventDetailedReportCountryStats>>>;
  liveEventId?: Maybe<Scalars['String']>;
  messagesPerChannel?: Maybe<Array<Maybe<LiveEventDetailedReportChannelStats>>>;
  prospectInLiveEvent?: Maybe<Scalars['Int']>;
  prospectLoggedIn?: Maybe<Scalars['Int']>;
  prospectSignUps?: Maybe<Scalars['Int']>;
  prospectsInLiveEventChatGroups?: Maybe<Scalars['Int']>;
  prospectsSendDirectMessage?: Maybe<Scalars['Int']>;
  prospectsSendMessage?: Maybe<Scalars['Int']>;
};

export type LiveEventDetailedReportChannelStats = {
  __typename?: 'LiveEventDetailedReportChannelStats';
  applicants?: Maybe<Array<Maybe<Scalars['String']>>>;
  len?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type LiveEventDetailedReportCountryStats = {
  __typename?: 'LiveEventDetailedReportCountryStats';
  count?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
};

export type LiveEventDetailsField = {
  __typename?: 'LiveEventDetailsField';
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isUserRegistered?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
};

export type LiveEventDirectMessaging = {
  __typename?: 'LiveEventDirectMessaging';
  applicants?: Maybe<Array<Maybe<DirectMessagingApplicant>>>;
  enquiryTeam?: Maybe<DirectMessagingEnquiryTeam>;
  mentors?: Maybe<Array<Maybe<DirectMessagingMentor>>>;
  staff?: Maybe<Array<Maybe<DirectMessagingStaffMember>>>;
};

export type LiveEventFeedbackFormMutation = {
  __typename?: 'LiveEventFeedbackFormMutation';
  feedBackForm?: Maybe<LiveEventProspectFeedbackFormField>;
};

export type LiveEventField = {
  __typename?: 'LiveEventField';
  allMentors?: Maybe<Array<Maybe<MentorField>>>;
  allStaff?: Maybe<Array<Maybe<BuddyField>>>;
  applicants?: Maybe<Array<Maybe<Scalars['String']>>>;
  created?: Maybe<Scalars['String']>;
  createdByUniversity?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  detailedReport?: Maybe<LiveEventDetailedReport>;
  directMessagingMentors?: Maybe<Array<Maybe<MentorField>>>;
  directMessagingStaff?: Maybe<Array<Maybe<BuddyField>>>;
  directMessagingUniversity?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  end?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  inbox?: Maybe<LiveEventInboxField>;
  liveFeeds?: Maybe<Array<Maybe<LiveEventChatGroupField>>>;
  name?: Maybe<Scalars['String']>;
  report?: Maybe<Scalars['String']>;
  setupCompleted?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};


export type LiveEventFieldInboxArgs = {
  exclude?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit: Scalars['Int'];
  offset?: Maybe<Scalars['Int']>;
};

export type LiveEventInboxField = {
  __typename?: 'LiveEventInboxField';
  hasMore?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<InboxItemUnion>>>;
};

export type LiveEventLandingPageField = {
  __typename?: 'LiveEventLandingPageField';
  description?: Maybe<Scalars['String']>;
  externalEvents?: Maybe<Array<Maybe<ExternalEventField>>>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  liveEvents?: Maybe<Array<Maybe<LiveEventDetailsField>>>;
  title?: Maybe<Scalars['String']>;
};

export type LiveEventProspectFeedbackFormField = {
  __typename?: 'LiveEventProspectFeedbackFormField';
  accountRole?: Maybe<Scalars['String']>;
  eventExperience?: Maybe<Scalars['Int']>;
  eventFeedback?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  liveEvent?: Maybe<LiveEventField>;
  university?: Maybe<UniversityField>;
  user?: Maybe<UserField>;
};

export type LiveEventsFormFieldValidationError = {
  __typename?: 'LiveEventsFormFieldValidationError';
  code?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LiveSession = {
  __typename?: 'LiveSession';
  conversation: ChatConversation;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  eventPagesDisplayedOn: Array<EventPagesDisplayedOn>;
  eventPagesIds: Array<Scalars['ObjectId']>;
  id: Scalars['ObjectId'];
  isSavedByMe: Scalars['Boolean'];
  /** the name of the event */
  name: Scalars['String'];
  presenters: Array<UserField>;
  recordingEnabled: Scalars['Boolean'];
  sessionAnalytics: SessionAnalytics;
  sessionImageUrl: Scalars['String'];
  showOnHomepage: Scalars['Boolean'];
  start: Scalars['DateTime'];
};

export type Locale = {
  __typename?: 'Locale';
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LocaleField = {
  __typename?: 'LocaleField';
  androidCode?: Maybe<Scalars['String']>;
  dialectOf?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  osxCode?: Maybe<Scalars['String']>;
  osxLocale?: Maybe<Scalars['String']>;
  textDirection?: Maybe<Scalars['String']>;
  threeLettersCode?: Maybe<Scalars['String']>;
  twoLettersCode?: Maybe<Scalars['String']>;
};

export type LogoDataField = {
  __typename?: 'LogoDataField';
  logoText?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type LogoutMutation = {
  __typename?: 'LogoutMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManageOrganisationAccessMutation = {
  __typename?: 'ManageOrganisationAccessMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ManuallyClassifyMutation = {
  __typename?: 'ManuallyClassifyMutation';
  classification: ClassificationField;
};

export type MarkAssistantConversationAsViewedInput = {
  conversationId: Scalars['String'];
  universityId: Scalars['String'];
};

export type MarkReadMessageDto = {
  conversationId: Scalars['ID'];
  messageId: Scalars['ObjectId'];
};

export type MarketplaceApplicantCountries = {
  __typename?: 'MarketplaceApplicantCountries';
  countries?: Maybe<Array<Maybe<CountryAnalyticsValue>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MarketplaceApplicantData = {
  custom?: Maybe<Scalars['JSONString']>;
  degreeCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  marketingConsentAccepted?: Maybe<Scalars['Boolean']>;
  personalDataCollectionAccepted?: Maybe<Scalars['Boolean']>;
  yearOfEntry?: Maybe<Scalars['String']>;
};

export type MarketplaceApplicantDegreeCategories = {
  __typename?: 'MarketplaceApplicantDegreeCategories';
  categories?: Maybe<Array<Maybe<DegreeCategoriesAnalyticsValue>>>;
  total?: Maybe<Scalars['Int']>;
};

export type MarketplaceApplicantUserData = {
  __typename?: 'MarketplaceApplicantUserData';
  custom?: Maybe<Scalars['GenericScalar']>;
  degreeCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  marketingConsentAccepted?: Maybe<Scalars['Boolean']>;
  personalDataCollectionAccepted?: Maybe<Scalars['Boolean']>;
  yearOfEntry?: Maybe<Scalars['String']>;
};

export type MarketplaceField = {
  __typename?: 'MarketplaceField';
  active?: Maybe<Scalars['Boolean']>;
  allowUniversityProspectDataAccess?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  customBuddyWord?: Maybe<Scalars['String']>;
  customFilters?: Maybe<Array<Maybe<MarketplaceFilters>>>;
  disableExternalNotification?: Maybe<Scalars['Boolean']>;
  faAppId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSsoEnabled?: Maybe<Scalars['Boolean']>;
  marketplaceMarketingConsentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  marketplaceMarketingConsentMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personalDataApiEnabled?: Maybe<Scalars['Boolean']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  unibuddyMarketingConsentEnabled?: Maybe<Scalars['Boolean']>;
  universityMarketingConsentEnabled?: Maybe<Scalars['Boolean']>;
  webhookUrl?: Maybe<Scalars['String']>;
  widgetDescription?: Maybe<Scalars['String']>;
  widgetTitle?: Maybe<Scalars['String']>;
  widgetUrl?: Maybe<Scalars['String']>;
};

export type MarketplaceFilterInput = {
  filterSlug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MarketplaceFilters = {
  __typename?: 'MarketplaceFilters';
  filterSlug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MarketplaceInboxField = {
  __typename?: 'MarketplaceInboxField';
  chatGroups?: Maybe<Array<Maybe<ChatGroupField>>>;
};

export type MarketplaceMentorList = {
  __typename?: 'MarketplaceMentorList';
  mentorSeed?: Maybe<Scalars['Int']>;
  mentors?: Maybe<Array<Maybe<PublicMentorField>>>;
  totalMentors?: Maybe<Scalars['Int']>;
  universitySeed?: Maybe<Scalars['Int']>;
};

export type MarketplaceSnippetField = {
  __typename?: 'MarketplaceSnippetField';
  error?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
};

export type MatchedUser = {
  __typename?: 'MatchedUser';
  matchingCriteria: Array<Scalars['String']>;
  matchingPercentage: Scalars['Float'];
  user: UserField;
};

/** Types of matching criteria */
export enum MatchingCriteriaEnum {
  AreaOfStudy = 'AreaOfStudy',
  Nationality = 'Nationality'
}

export type MeField = {
  __typename?: 'MeField';
  admin?: Maybe<AdminUserField>;
  anyUser?: Maybe<AnyUserField>;
  applicant?: Maybe<ApplicantField>;
  mentor?: Maybe<MentorField>;
  staff?: Maybe<UserField>;
  universityUser?: Maybe<UniversityUserField>;
};

export type MentorApplicantChatGroupField = {
  __typename?: 'MentorApplicantChatGroupField';
  applicant?: Maybe<ApplicantField>;
  created?: Maybe<Scalars['String']>;
  creationSource?: Maybe<CreationSource>;
  id?: Maybe<Scalars['String']>;
  lastMessageSentAt?: Maybe<Scalars['DateTime']>;
  lastMessageText?: Maybe<Scalars['String']>;
  mentor?: Maybe<MentorField>;
  resolved?: Maybe<Scalars['Boolean']>;
  unansweredByMentor?: Maybe<Scalars['Boolean']>;
};

export type MentorBroadcastMessageMutation = {
  __typename?: 'MentorBroadcastMessageMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type MentorChatUserField = ChatUserField & {
  __typename?: 'MentorChatUserField';
  degree?: Maybe<DegreeChatUserField>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
};


export type MentorChatUserFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type MentorDegreeField = {
  __typename?: 'MentorDegreeField';
  degree?: Maybe<DegreeField>;
  degreeType?: Maybe<DegreeType>;
};

export type MentorDegreeFieldInput = {
  degree: Scalars['String'];
  degreeType: DegreeType;
};

export type MentorDegreeHistory = {
  __typename?: 'MentorDegreeHistory';
  degree?: Maybe<DegreeField>;
  university?: Maybe<UniversityField>;
};

export type MentorField = {
  __typename?: 'MentorField';
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  blogPostCount?: Maybe<Scalars['Int']>;
  cardComplete?: Maybe<Scalars['Boolean']>;
  chatGroupCount?: Maybe<Scalars['Int']>;
  chatIntro?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  country?: Maybe<CountryField>;
  created?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueField>>>;
  degree?: Maybe<DegreeField>;
  degreeHistory?: Maybe<MentorDegreeHistory>;
  degreeLevel?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<MentorDegreeField>>>;
  email?: Maybe<Scalars['String']>;
  favouriteModules?: Maybe<Array<Maybe<MentorModuleField>>>;
  firstName?: Maybe<Scalars['String']>;
  hasUnansweredConversation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<MentorInterestField>>>;
  isOnline?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<LanguageField>>>;
  lastName?: Maybe<Scalars['String']>;
  lastSeen?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['String']>;
  mentorFields?: Maybe<MentorFields>;
  messageCount?: Maybe<Scalars['Int']>;
  pastQualification?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  preferences?: Maybe<MentorPreferencesField>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  resolvedUnansweredConversations?: Maybe<Scalars['Boolean']>;
  schoolHistory?: Maybe<MentorSchoolHistoryField>;
  shareUrl?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  showIn?: Maybe<Array<Maybe<Display>>>;
  societies?: Maybe<Array<Maybe<MentorSocietyField>>>;
  stageOneComplete?: Maybe<Scalars['Boolean']>;
  stageTwoComplete?: Maybe<Scalars['Boolean']>;
  unansweredCount?: Maybe<Scalars['Int']>;
  university?: Maybe<UniversityField>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};


export type MentorFieldCustomAttributesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type MentorFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type MentorFieldOrderField = {
  __typename?: 'MentorFieldOrderField';
  field?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type MentorFieldOrderInput = {
  field?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type MentorFields = {
  __typename?: 'MentorFields';
  primaryFlag?: Maybe<FlagField>;
  secondaryFlag?: Maybe<FlagField>;
};

export type MentorFilterField = {
  __typename?: 'MentorFilterField';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<MentorFilterOptionField>>>;
};

export type MentorFilterInputField = {
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MentorFilterOptionField = {
  __typename?: 'MentorFilterOptionField';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type MentorInboxField = {
  __typename?: 'MentorInboxField';
  chatGroups?: Maybe<Array<Maybe<ChatGroupField>>>;
  chatGroupsCount?: Maybe<Scalars['Int']>;
};

export type MentorInterestField = {
  __typename?: 'MentorInterestField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MentorInterventionQueryField = {
  __typename?: 'MentorInterventionQueryField';
  mentors?: Maybe<Array<Maybe<MentorField>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MentorIntroMessageField = {
  __typename?: 'MentorIntroMessageField';
  introMessage?: Maybe<MessageField>;
};

export type MentorInviteCountField = {
  __typename?: 'MentorInviteCountField';
  mentorInviteCount?: Maybe<Scalars['Int']>;
};

export type MentorInviteField = {
  __typename?: 'MentorInviteField';
  accepted?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  notFound?: Maybe<Scalars['Boolean']>;
};

export type MentorListField = {
  __typename?: 'MentorListField';
  countries?: Maybe<Array<Maybe<CountryField>>>;
  degrees?: Maybe<Array<Maybe<DegreeField>>>;
  levels?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentors?: Maybe<Array<Maybe<PublicMentorField>>>;
  validCombination?: Maybe<Scalars['Boolean']>;
};

export type MentorListFilterChoice = {
  __typename?: 'MentorListFilterChoice';
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type MentorListFilterChoiceInput = {
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MentorModuleField = {
  __typename?: 'MentorModuleField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MentorPreferencesField = {
  __typename?: 'MentorPreferencesField';
  id?: Maybe<Scalars['String']>;
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MentorSchoolHistoryField = {
  __typename?: 'MentorSchoolHistoryField';
  highSchool?: Maybe<HighSchoolField>;
  highSchoolType?: Maybe<HighSchoolTypeField>;
};

export type MentorSnippetField = {
  __typename?: 'MentorSnippetField';
  mentor?: Maybe<MentorField>;
  snippet?: Maybe<Scalars['String']>;
};

export type MentorSocietyField = {
  __typename?: 'MentorSocietyField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MentorcardSnippetField = {
  __typename?: 'MentorcardSnippetField';
  countrySnippets?: Maybe<Array<Maybe<CountrySnippetField>>>;
  degreeSnippets?: Maybe<Array<Maybe<DegreeSnippetField>>>;
  description?: Maybe<DescriptionField>;
  mentorSnippets?: Maybe<Array<Maybe<MentorSnippetField>>>;
};

export type MessageDto = {
  conversationId: Scalars['ObjectId'];
  richContent?: Maybe<RichContentDto>;
  text: Scalars['String'];
};

export type MessageField = {
  __typename?: 'MessageField';
  attachment?: Maybe<AttachmentField>;
  chatGroupId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  input?: Maybe<InputField>;
  instantAction?: Maybe<Scalars['String']>;
  isSenderBlocked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Array<Maybe<Scalars['String']>>>;
  ownMessage?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  richContent?: Maybe<RichContentOutputField>;
  sender?: Maybe<AnyUserField>;
  text?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['String']>;
};

export type MessageMetadata = {
  __typename?: 'MessageMetadata';
  ambassadorProfile?: Maybe<AmbassadorProfileInformation>;
  staffProfile?: Maybe<StaffProfileInformation>;
};

export type MessagePublicBotMutation = {
  __typename?: 'MessagePublicBotMutation';
  botMessages?: Maybe<Array<Maybe<MessageField>>>;
};

export type Metadata = {
  qssMetadata?: Maybe<QssMetadata>;
};

export type MetricDataPoint = {
  __typename?: 'MetricDataPoint';
  endDate?: Maybe<Scalars['DateTime']>;
  metricName?: Maybe<Scalars['String']>;
  metricType?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptRequestedPublicGroup: RequestedPublicGroup;
  activateApplicants?: Maybe<ActivateApplicantsMutation>;
  activateMentors?: Maybe<ActivateMentorsMutation>;
  addAttendees?: Maybe<AttendeeAnalytics>;
  addBotToChatGroup?: Maybe<AddBotToChatGroupMutation>;
  addChatRoomAttendees?: Maybe<ChatRoomAttendeeAnalytics>;
  addCustomAttribute?: Maybe<AddCustomAttributeMutation>;
  addCustomAttributeOption?: Maybe<AddAttributeOptionMutation>;
  addDegreeLevels?: Maybe<AddDegreeLevelsMutation>;
  addDegrees?: Maybe<AddDegreesMutation>;
  addDevice?: Maybe<DeviceMutation>;
  addHobsonsConnectIntegration?: Maybe<AddHobsonsConnectIntegration>;
  addLocalesToApp?: Maybe<AddLocalesToApp>;
  addMatchingCriteria: UpdatedResults;
  addReactionToMessage: ChatMessage;
  addResearchTime?: Maybe<AddResearchTimeMutation>;
  addSessionAttendees?: Maybe<SessionAttendeeAnalytics>;
  addToFeatureExperiment: Scalars['Boolean'];
  addUserToCommunityConversation: ChatConversation;
  addVote: Poll;
  addYearOfStudy?: Maybe<AddYearOfStudyMutation>;
  adminDeleteApplicant?: Maybe<AdminDeleteApplicantMutation>;
  adminDeleteBuddy?: Maybe<DeleteUserMutation>;
  adminUpdateMentor?: Maybe<AdminUpdateMentorMutation>;
  applicantBroadcastMessage?: Maybe<ApplicantBroadcastMessageMutation>;
  bulkOptOutMarketplaceUniversityMarketing?: Maybe<BulkOptOutMarketplaceUniversityMarketingMutation>;
  checkEmail?: Maybe<CheckEmailMutation>;
  cloneCommunity: Community;
  cloneLiveEventAndChannels?: Maybe<CloneLiveEventAndChannelsMutation>;
  createAlert?: Maybe<CreateAlertMutation>;
  createApplicant?: Maybe<CreateApplicantMutation>;
  createApplicantV2?: Maybe<CreateApplicantMutationV2>;
  createApplicationInsight: ApplicationInsightModel;
  createAssistantConversation: AssistantConversationHistory;
  createAssistantMessageFeedback: Scalars['Boolean'];
  createBlogTopic?: Maybe<CreateBlogTopicMutation>;
  createBuddy?: Maybe<CreateBuddyMutation>;
  createCanonicalQuestion?: Maybe<CreateCanonicalQuestion>;
  /** Create a chat report. This mutation is available to all user types. */
  createChatReport: ChatReport;
  createChatRoom: ChatRoom;
  createComment?: Maybe<CommentMutation>;
  createCommunity: Community;
  createCommunityApplicant?: Maybe<CreateCommunityApplicantMutation>;
  createCommunityApplicantV2?: Maybe<CreateCommunityApplicantMutationV2>;
  /** @deprecated Deprecated, please use createCommunityConversation */
  createCommunityChatGroup: CommunityChatGroup;
  createCommunityConversation: ChatConversation;
  createCommunityFromTemplate: Community;
  createCrmServiceUniversityConfig?: Maybe<CreateCrmServiceUniversityConfigMutation>;
  createDirectConversation: ChatConversation;
  createEventPage: EventPage;
  createExternalContent: ExternalContent;
  createExternalEvent?: Maybe<CreateExternalEventMutation>;
  createFaqAnswer?: Maybe<CreateFaqAnswer>;
  createFresher?: Maybe<CreateFresherMutation>;
  createInviteLink: InviteLinkDto;
  createLiveEvent?: Maybe<CreateLiveEventMutation>;
  createLiveEventDetails?: Maybe<CreateLiveEventDetailsMutation>;
  createLiveEventFeed?: Maybe<CreateLiveEventFeedMutation>;
  createLiveEventFeedSessions?: Maybe<CreateLiveEventFeedSessionsMutation>;
  createLiveSession: LiveSession;
  createMarketplace?: Maybe<CreateMarketplaceMutation>;
  createMarketplaceApplicant?: Maybe<CreateMarketplaceApplicantMutation>;
  createMentor?: Maybe<CreateMentorMutation>;
  createOpenDay?: Maybe<CreateOpenDayMutation>;
  createOrUpdateUniversityAssistantPromptContext: UniversityAssistantConfigurationPromptContextOutput;
  createOrganisation?: Maybe<CreateOrganisationMutation>;
  createOrganisationBuddyLink?: Maybe<CreateOrganisationBuddyLinkMutation>;
  createPost?: Maybe<CreatePostMutation>;
  createStaffContact: StaffContactOutput;
  createSubTopic?: Maybe<CreateSubTopicMutation>;
  createTag?: Maybe<CreateTagMutation>;
  createTopic?: Maybe<CreateTopicMutation>;
  createUniversity?: Maybe<CreateUniversityMutation>;
  createUniversityAssistantEventLink: UniversityAssistantConfigurationEventOutput;
  createUniversityBlockReport?: Maybe<CreateUniversityBlockReportMutation>;
  createUniversityScrape: UniversityScrapeOutput;
  createUniversityScrapeBatch: UniversityScrapeOutput;
  createUniversityScrapeOne: UniversityScrapeOutput;
  createUniversityUser?: Maybe<CreateUniversityUserMutation>;
  createUserMutation?: Maybe<CreateUserMutation>;
  /** Create a user report. This mutation is available to all user types. */
  createUserReport: UserReport;
  createVideoUploadLink: VideoUploadLink;
  createVirtualEvent: VirtualEvent;
  createVirtualEventProspectReport?: Maybe<ReportAnalytics>;
  csvUploadAmbassadorFields?: Maybe<CsvUploadAmbassadorFieldsMutation>;
  csvUploadApplicants?: Maybe<CsvUploadApplicantsMutation>;
  deactivateApplicants?: Maybe<DeactivateApplicantsMutation>;
  deactivateMentors?: Maybe<DeactivateMentorsMutation>;
  deleteAdminRecommendation: AdminRecommendation;
  deleteApplicant?: Maybe<DeleteApplicantMutation>;
  deleteBlogPost?: Maybe<DeletePostMutation>;
  deleteCanonicalQuestion?: Maybe<DeleteCanonicalQuestion>;
  deleteChatRoom: Scalars['Boolean'];
  deleteCommunityConversation: ChatConversation;
  deleteConversationMessage: ChatMessage;
  deleteCustomAttributeOptions?: Maybe<DeleteAttributeOptionsMutation>;
  deleteCustomAttributes?: Maybe<DeleteCustomAttributesMutation>;
  deleteDegreeLevel?: Maybe<DeleteDegreeLevelMutation>;
  deleteEventPage: Scalars['Boolean'];
  deleteExternalContent: Scalars['Boolean'];
  deleteFaqAnswer?: Maybe<DeleteFaqAnswer>;
  deleteLiveEvent?: Maybe<DeleteLiveEventMutation>;
  deleteLiveEventFeed?: Maybe<DeleteLiveEventFeedMutation>;
  deleteLiveEventMessageMutation?: Maybe<DeleteLiveEventMessageMutation>;
  deleteLiveSession: Scalars['Boolean'];
  deleteRecording: DeleteRecordingResponse;
  deleteResearchTime?: Maybe<DeleteResearchTimeMutation>;
  deleteSubTopic?: Maybe<DeleteSubTopicMutation>;
  deleteTopic?: Maybe<DeleteTopicMutation>;
  deleteUniversityKnowledgeBaseSourceById: KnowledgeBaseDeleteEntryOutput;
  deleteUniversityKnowledgeBaseSourceByPatternMatch: DeleteKnowledgeBaseEntriesOutput;
  deleteUniversityKnowledgeBaseSourceByPatternMismatch: DeleteKnowledgeBaseEntriesOutput;
  deleteUniversityKnowledgeBaseSourceByUrlList: DeleteKnowledgeBaseEntriesOutput;
  deleteUniversityUser?: Maybe<DeleteUniversityUserMutation>;
  deleteVirtualEvent: Scalars['Boolean'];
  deleteYearOfStudy?: Maybe<DeleteYearOfStudyMutation>;
  editAdminRecommendation: AdminRecommendation;
  editAlert?: Maybe<EditAlertMutation>;
  editBlogPost?: Maybe<EditPostMutation>;
  editBlogTopic?: Maybe<EditBlogTopicMutation>;
  editCommunity: Community;
  editCommunityConversation: ChatConversation;
  editCookieConsentPreferences: CookieConsentOutputDto;
  editCustomAttribute?: Maybe<EditCustomAttributeMutation>;
  editCustomAttributeOption?: Maybe<EditAttributeOptionMutation>;
  editCustomAttributes?: Maybe<EditCustomAttributesMutation>;
  editCustomWelcomeMessage?: Maybe<EditCustomWelcomeMessageMutation>;
  editDegreeLevel?: Maybe<EditDegreeLevelMutation>;
  editDegreeName?: Maybe<EditDegreeNameMutation>;
  editDegrees?: Maybe<EditDegreesMutation>;
  editExternalEvent?: Maybe<EditExternalEventMutation>;
  editLiveEvent?: Maybe<EditLiveEventMutation>;
  editLiveEventDetails?: Maybe<EditLiveEventDetailsMutation>;
  editLiveEventFeed?: Maybe<EditLiveEventFeedMutation>;
  editLiveEventsLandingPage?: Maybe<EditLiveEventsLandingPageMutation>;
  editOpenDay?: Maybe<EditOpenDayMutation>;
  editOrganisation?: Maybe<EditOrganisationMutation>;
  editUniversity?: Maybe<EditUniversityMutation>;
  editYearOfStudy?: Maybe<EditYearOfStudyMutation>;
  emailInvitesDataForCommunity: Scalars['Boolean'];
  enableCustomWelcomeMessage?: Maybe<EnableCustomWelcomeMessageMutation>;
  enableCustomWelcomeMessageStaff?: Maybe<EnableCustomWelcomeMessageStaffMutation>;
  enableFeatures?: Maybe<EnableFeaturesMutation>;
  exportAnalyticsCsv?: Maybe<ExportAnalyticsCsvMutation>;
  forwardApplicantToUniversity?: Maybe<ForwardApplicantToUniversity>;
  generateAnalyticsDashboardToken?: Maybe<GenerateAnalyticsDashboardTokenMutation>;
  generateDownloadUrl?: Maybe<GetDownloadableUrlMutation>;
  generateLiveEventReport?: Maybe<GenerateLiveEventReport>;
  generateRecommendations: Array<Recommendation>;
  getMarketplaceApplicant?: Maybe<GetMarketplaceApplicantMutation>;
  getOrCreateWidgetConversation: ChatConversation;
  getSignedS3PostFields?: Maybe<GetSignedS3PostFieldsMutation>;
  getSignedS3Url?: Maybe<GetSignedS3UrlMutation>;
  hideMentorInMarketplace?: Maybe<HideMentorInMarketplaceMutation>;
  initialiseRecording: InitialiseRecordingResponse;
  initiateUserDeletion: Scalars['Boolean'];
  inviteApplicants?: Maybe<InviteApplicantsMutation>;
  inviteBuddies?: Maybe<InviteBuddiesMutation>;
  inviteEmailsToCommunity: CommunityInviteResponse;
  inviteMentors?: Maybe<InviteMentorsMutation>;
  joinChatGroup?: Maybe<JoinChatGroupMutation>;
  joinCommunity: CommunityJoinResponse;
  joinCommunityByInviteLink: CommunityJoinResponse;
  joinCommunityConversation: ChatConversation;
  joinCommunityForAdmins: CommunityJoinResponse;
  labelSentence?: Maybe<LabelSentenceMutation>;
  leaveChatGroup?: Maybe<LeaveChatGroupMutation>;
  leaveCommunityChatGroup: ChatConversation;
  likeConversationMessage: ChatMessage;
  likeMessage?: Maybe<LikeMessageMutation>;
  linkOrganisations?: Maybe<LinkOrganisationsMutation>;
  liveEventFeedbackForm?: Maybe<LiveEventFeedbackFormMutation>;
  logout?: Maybe<LogoutMutation>;
  manageOrganisationAccess?: Maybe<ManageOrganisationAccessMutation>;
  manuallyClassify?: Maybe<ManuallyClassifyMutation>;
  markAssistantConversationAsViewed: AssistantConversationHistory;
  mentorBroadcastMessage?: Maybe<MentorBroadcastMessageMutation>;
  messagePublicBot?: Maybe<MessagePublicBotMutation>;
  /** Moderate a chat report. This mutation is available only to university users and admins. */
  moderateChatReport: ChatReport;
  /** Moderate a user report. This mutation is available only to university users and admins. */
  moderateUserReport: UserReport;
  pinMessage?: Maybe<PinMessageMutation>;
  pinMessageInConversation: ChatConversation;
  readChat?: Maybe<ReadChatMutation>;
  recordConsent: RecordingConsent;
  recordFailure: RecordingFailureResponse;
  recordLastMessageReadByUser: LastRead;
  recordUserActivity?: Maybe<RecordUserActivityMutation>;
  registerToLiveEvent?: Maybe<RegisterToLiveEvent>;
  rejectRequestedPublicGroup: RequestedPublicGroup;
  removeApplicationInsight: Scalars['Boolean'];
  removeBuddyInvites?: Maybe<RemoveBuddyInvitesMutation>;
  removeDevice?: Maybe<RemoveDeviceMutation>;
  removeMembersFromCommunity: Scalars['Boolean'];
  removeMentorInvites?: Maybe<RemoveMentorInvitesMutation>;
  removeReactionFromMessage: ChatMessage;
  removeUserFromCommunityChatGroup: Scalars['Boolean'];
  removeVote: Poll;
  requestAnalyticsExport?: Maybe<RequestAnalyticsExportMutation>;
  requestPasswordReset?: Maybe<RequestPasswordResetMutation>;
  requestPasswordSet?: Maybe<RequestPasswordSetMutation>;
  requestPublicGroup: RequestedPublicGroup;
  requestResetPassword?: Maybe<ResetPasswordMobileMutation>;
  requestResetPasswordMobile?: Maybe<PasswordResetMobileMutation>;
  requestResetPasswordMobileV2?: Maybe<PasswordResetMobileMutationV2>;
  resetPassword?: Maybe<ResetPasswordMutation>;
  resetPasswordEmail?: Maybe<SendResetPasswordEmail>;
  resetPasswordV2?: Maybe<ResetPasswordMutationV2>;
  resolveChats?: Maybe<ResolveUniversityChatsMutation>;
  resolveConversationMessage: ChatMessage;
  runManageCommand?: Maybe<RunManageCommandMutation>;
  saveAdminRecommendation: AdminRecommendation;
  saveAmbassadorRecommendation: AmbassadorRecommendation;
  saveAppliedUsers: SaveAppliedUsersModel;
  saveAssistantConversationEmailLead: AssistantConversationLead;
  saveAssistantConversationFeedback: AssistantConversationFeedback;
  saveCookieConsentPreferences: CookieConsentOutputDto;
  saveInboxPromptAnswer: Array<InboxPromptAnswer>;
  saveInsightsByCommunity: Insights;
  saveNotInterestedStage: UserDecisionStageOutput;
  saveSatisfactionSurvey: SatisfactionSurveyEntity;
  saveUniversityQuestionAnswer: Array<UniversityQuestionAnswer>;
  saveUserDecisionStage: UserDecisionStageOutput;
  saveVisitedRecommendation: Recommendation;
  sendAssistantMessage: AssistantConversationMessage;
  sendCommunityVerificationCode?: Maybe<SendCommunityVerificationCode>;
  sendConversationMessage: ChatMessage;
  /** sends feature push notification to all users with applicable roles in given university list. */
  sendFeatureNotification: Scalars['Boolean'];
  sendFeedMessage?: Maybe<SendFeedMessageMutation>;
  sendLiveEventMessage?: Maybe<SendLiveEventMessageMutation>;
  sendMessage?: Maybe<SendMessageMutation>;
  sendOpenDayProspectReport?: Maybe<SendOpenDayProspectReport>;
  sendVerificationCode?: Maybe<SendVerificationCode>;
  setActiveBlogTopics?: Maybe<SetActiveBlogTopicsMutation>;
  setAllNotificationsRead?: Maybe<SetAllNotificationsReadMutation>;
  setArchivedContentBrief?: Maybe<SetArchivedContentBriefMutation>;
  setArchivedDegreeLevel?: Maybe<SetArchivedDegreeLevelMutation>;
  setArchivedYearOfStudy?: Maybe<SetArchivedYearOfStudyMutation>;
  setBlockUser?: Maybe<BlockUserMutation>;
  setBlockUserV2?: Maybe<BlockUserMutationV2>;
  setBlogPostStatus?: Maybe<UpdatePostStatusMutation>;
  setBuddiesListFilters?: Maybe<SetBuddiesListFilters>;
  setChatGroupLabels?: Maybe<SetChatGroupLabelsMutation>;
  setDeviceToken: DeviceToken;
  setInterestsForUser: Array<UserInterest>;
  setMentorFieldOrder?: Maybe<SetMentorFieldOrderMutation>;
  setMentorListFilterSelection?: Maybe<SetMentorListFilterSelection>;
  setOnline?: Maybe<SetOnlineMutation>;
  setResolveChatGroup?: Maybe<SetResolveChatGroupMutation>;
  setUniversityUnblockUsers?: Maybe<UniversityBlockUsersMutation>;
  setUserProfile: UserProfile;
  setWillReply?: Maybe<SetWillReplyMutation>;
  showMentorInMarketplace?: Maybe<ShowMentorInMarketplaceMutation>;
  startRecordingVideo?: Maybe<RecordingResponse>;
  stopRecordingVideo?: Maybe<RecordingResponse>;
  stopUniversityCrawl?: Maybe<UniversityScrapeStopOutput>;
  toggleCommunityChatGroupMute: ChatConversation;
  toggleCrmIntegrationSync?: Maybe<ToggleCrmIntegrationSync>;
  toggleCustomAttributeCrmSync?: Maybe<ToggleCustomAttributeCrmSyncMutation>;
  toggleDegreesActive?: Maybe<ToggleDegreesActiveMutation>;
  toggleFeatureAvailability?: Maybe<ToggleFeatureAvailabilityMutation>;
  trackLiveEvent?: Maybe<TrackLiveEventMutation>;
  trackOpenDay?: Maybe<TrackOpenDayMutation>;
  unlikeConversationMessage: ChatMessage;
  unpinMessage?: Maybe<UnpinMessageMutation>;
  unpinMessageInConversation: ChatConversation;
  unresolveConversationMessage: ChatMessage;
  updateApplicant?: Maybe<UpdateApplicantMutation>;
  updateApplicantPreferences?: Maybe<UpdateApplicantPreferencesMutation>;
  updateCanonicalQuestion?: Maybe<UpdateCanonicalQuestion>;
  updateChatRoom: ChatRoom;
  updateCommunityApplicantSignupsource?: Maybe<UpdateCommunityApplicantSignupSourceMutation>;
  updateCrmServiceUniversityConfig?: Maybe<UpdateUniversityConfigMutation>;
  updateDirectMessagingMembersForLiveEvent?: Maybe<UpdateDirectMessagingMembersForLiveEventMutation>;
  updateEventPage: EventPage;
  updateExternalContent: ExternalContent;
  updateFaqAnswer?: Maybe<UpdateFaqAnswer>;
  updateHobsonsConnectIntegration?: Maybe<UpdateHobsonsConnectIntegration>;
  updateIntegrationFieldMapping?: Maybe<UpdateIntegrationFieldMapping>;
  updateLiveEventFeedSessionData?: Maybe<UpdateLiveEventFeedSessionDataMutation>;
  updateLiveSession: LiveSession;
  updateLiveSessionAnyUser: LiveSession;
  updateManyUserMutation?: Maybe<UpdateManyUserMutation>;
  updateMarketplace?: Maybe<UpdateMarketplaceMutation>;
  updateMarketplaceApplicant?: Maybe<UpdateMarketplaceApplicantMutation>;
  updateMarketplaceApplicantUniversityLink?: Maybe<UpdateMarketplaceApplicantUniversityLinkMutation>;
  updateMentor?: Maybe<UpdateMentorMutation>;
  updateMentorPreferences?: Maybe<UpdateMentorPreferences>;
  updateMyUniversityLink?: Maybe<UpdateMyUniversityLinkMutation>;
  updateRecordingLayout?: Maybe<RecordingResponse>;
  updateResearchTime?: Maybe<UpdateResearchTimeMutation>;
  updateStaff?: Maybe<UpdateStaffMutation>;
  updateStaffContact: StaffContactOutput;
  updateSubTopic?: Maybe<UpdateSubTopicMutation>;
  updateTag?: Maybe<UpdateTagMutation>;
  updateTopic?: Maybe<UpdateTopicMutation>;
  updateUniversityBlockReport?: Maybe<UpdateUniversityBlockReportMutation>;
  updateUniversityUser?: Maybe<UpdateUniversityUserMutation>;
  updateUniversityUserPreferences?: Maybe<UpdateUniversityUserPreferencesMutation>;
  updateUserMutation?: Maybe<UpdateUserMutation>;
  updateUserPreferencesMutation?: Maybe<UpdateUserPreferences>;
  updateVirtualEvent: VirtualEvent;
  verifyAccount?: Maybe<VerifyAccount>;
  viewBlog?: Maybe<ViewBlogMutation>;
};


export type MutationAcceptRequestedPublicGroupArgs = {
  acceptRequestedPublicGroupInput: AcceptRequestedPublicGroupInput;
};


export type MutationActivateApplicantsArgs = {
  applicantIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationActivateMentorsArgs = {
  mentorIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationAddAttendeesArgs = {
  attendeeData: AttendeeData;
};


export type MutationAddBotToChatGroupArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationAddChatRoomAttendeesArgs = {
  chatRoomAttendeeData: ChatRoomAttendeeData;
};


export type MutationAddCustomAttributeArgs = {
  attributeValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  destination?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};


export type MutationAddCustomAttributeOptionArgs = {
  attributeId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type MutationAddDegreeLevelsArgs = {
  degreeLevelNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationAddDegreesArgs = {
  degreeLevelName?: Maybe<Scalars['String']>;
  degreeNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  degreeTypes?: Maybe<Array<Maybe<DegreeType>>>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationAddDeviceArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationAddHobsonsConnectIntegrationArgs = {
  clientName?: Maybe<Scalars['String']>;
  passKey?: Maybe<Scalars['String']>;
  webServiceUrl?: Maybe<Scalars['String']>;
};


export type MutationAddLocalesToAppArgs = {
  appLocales: Array<Maybe<AppLocalesFieldInput>>;
};


export type MutationAddReactionToMessageArgs = {
  reactionDto: ReactionDto;
};


export type MutationAddResearchTimeArgs = {
  researchTimeData: ResearchTimeInput;
};


export type MutationAddSessionAttendeesArgs = {
  sessionAttendeeData: SessionAttendeeData;
};


export type MutationAddToFeatureExperimentArgs = {
  experimentName: Scalars['String'];
  metadata: Scalars['String'];
};


export type MutationAddUserToCommunityConversationArgs = {
  conversationId: Scalars['String'];
  memberId: Scalars['String'];
};


export type MutationAddVoteArgs = {
  chatMessageId: Scalars['ObjectId'];
  option: Scalars['String'];
};


export type MutationAddYearOfStudyArgs = {
  degreeLevelName: Scalars['String'];
  universitySlug: Scalars['String'];
  yearOfStudyNames: Array<Maybe<Scalars['String']>>;
};


export type MutationAdminDeleteApplicantArgs = {
  applicantId?: Maybe<Scalars['String']>;
};


export type MutationAdminDeleteBuddyArgs = {
  userId: Scalars['String'];
};


export type MutationAdminUpdateMentorArgs = {
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};


export type MutationApplicantBroadcastMessageArgs = {
  receiverIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  text?: Maybe<Scalars['String']>;
};


export type MutationBulkOptOutMarketplaceUniversityMarketingArgs = {
  marketplaceSlug: Scalars['String'];
  universitySlugs: Array<Maybe<Scalars['String']>>;
};


export type MutationCheckEmailArgs = {
  checkForAmbassador?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCloneCommunityArgs = {
  sourceCommmunityId: Scalars['String'];
};


export type MutationCloneLiveEventAndChannelsArgs = {
  id?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationCreateAlertArgs = {
  active?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationCreateApplicantArgs = {
  ageVerificationInfo?: Maybe<AgeVerificationInfoInputField>;
  checkForAmbassador?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  primaryApp?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateApplicantV2Args = {
  ageVerificationInfo?: Maybe<AgeVerificationInfoInputField>;
  checkForAmbassador?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  primaryApp?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateApplicationInsightArgs = {
  createApplicantInsightInput: CreateApplicantInsightInput;
};


export type MutationCreateAssistantConversationArgs = {
  createAssistantConversationInput: CreateAssistantConversationInput;
};


export type MutationCreateAssistantMessageFeedbackArgs = {
  assistantMessageFeedbackInput: AssistantMessageFeedbackInput;
};


export type MutationCreateBlogTopicArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateBuddyArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  inviteId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateCanonicalQuestionArgs = {
  defaultQuestion?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  subTopic: Scalars['String'];
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateChatReportArgs = {
  chatReportUserInputDTO: ChatReportUserInputDto;
};


export type MutationCreateChatRoomArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
  virtualEventId: Scalars['ObjectId'];
};


export type MutationCreateCommentArgs = {
  postId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};


export type MutationCreateCommunityArgs = {
  CreateCommunityDTO: CreateCommunityDto;
};


export type MutationCreateCommunityApplicantArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationCreateCommunityApplicantV2Args = {
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationCreateCommunityChatGroupArgs = {
  createCommunityChatGroupInput: CreateCommunityChatGroupInput;
};


export type MutationCreateCommunityConversationArgs = {
  CreateCommunityConversationDTO: CreateCommunityConversationDto;
};


export type MutationCreateCommunityFromTemplateArgs = {
  CreateCommunityDTO: CreateCommunityDto;
  communityUseCase: Scalars['String'];
};


export type MutationCreateCrmServiceUniversityConfigArgs = {
  authToken?: Maybe<Scalars['String']>;
  connectorEndpoint?: Maybe<Scalars['String']>;
  connectorName?: Maybe<Scalars['String']>;
  dataFormatConfigs?: Maybe<Array<Maybe<DataFormatConfigInputType>>>;
  fields?: Maybe<Array<Maybe<CrmFieldInputType>>>;
  isSyncActive?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  zapierWebhook?: Maybe<Scalars['String']>;
};


export type MutationCreateDirectConversationArgs = {
  DirectConversationDTO: DirectConversationDto;
};


export type MutationCreateEventPageArgs = {
  eventPageData: EventPageData;
  virtualEventId: Scalars['ObjectId'];
};


export type MutationCreateExternalContentArgs = {
  externalContentData: ExternalContentData;
  virtualEventId: Scalars['ObjectId'];
};


export type MutationCreateExternalEventArgs = {
  description: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type MutationCreateFaqAnswerArgs = {
  answer: Scalars['String'];
  canonicalQuestion: Scalars['String'];
};


export type MutationCreateFresherArgs = {
  acceptMarketing?: Maybe<Scalars['Boolean']>;
  countryId?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateInviteLinkArgs = {
  inviteLinkInput: InviteLinkInput;
};


export type MutationCreateLiveEventArgs = {
  chatGroups?: Maybe<Array<Maybe<LiveEventChatGroupsInputField>>>;
  description?: Maybe<Scalars['String']>;
  directMessagingMentors?: Maybe<Array<Maybe<Scalars['String']>>>;
  directMessagingStaff?: Maybe<Array<Maybe<Scalars['String']>>>;
  directMessagingUniversity?: Maybe<Scalars['Boolean']>;
  end?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationCreateLiveEventDetailsArgs = {
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationCreateLiveEventFeedArgs = {
  description?: Maybe<Scalars['String']>;
  hostIdsVideoSessions?: Maybe<Array<Maybe<Scalars['String']>>>;
  liveEventId?: Maybe<Scalars['String']>;
  mentors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  recordingEnabled?: Maybe<Scalars['Boolean']>;
  staff?: Maybe<Array<Maybe<Scalars['String']>>>;
  universityId?: Maybe<Scalars['String']>;
  videoEnabled?: Maybe<Scalars['Boolean']>;
  videoSessions?: Maybe<Array<Maybe<VideoSession>>>;
};


export type MutationCreateLiveEventFeedSessionsArgs = {
  liveEventFeedId?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  videoSessions?: Maybe<Array<Maybe<VideoSession>>>;
};


export type MutationCreateLiveSessionArgs = {
  sessionData: SessionData;
  virtualEventId: Scalars['ObjectId'];
};


export type MutationCreateMarketplaceArgs = {
  active?: Maybe<Scalars['Boolean']>;
  allowUniversityProspectDataAccess?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<Scalars['String']>;
  customBuddyWord?: Maybe<Scalars['String']>;
  customFilters?: Maybe<Array<Maybe<MarketplaceFilterInput>>>;
  disableExternalNotification?: Maybe<Scalars['Boolean']>;
  marketplaceMarketingConsentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  marketplaceMarketingConsentMessage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  personalDataApiEnabled?: Maybe<Scalars['Boolean']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  unibuddyMarketingConsentEnabled?: Maybe<Scalars['Boolean']>;
  universityMarketingConsentEnabled?: Maybe<Scalars['Boolean']>;
  webhookUrl?: Maybe<Scalars['String']>;
  widgetDescription?: Maybe<Scalars['String']>;
  widgetTitle?: Maybe<Scalars['String']>;
  widgetUrl: Scalars['String'];
};


export type MutationCreateMarketplaceApplicantArgs = {
  country: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  marketplace: ApplicantMarketplaceInput;
  password: Scalars['String'];
  unibuddyServicesConsentAccepted?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateMentorArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateOpenDayArgs = {
  description: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  externalEventIds: Array<Maybe<Scalars['String']>>;
  image?: Maybe<Scalars['String']>;
  isStaffCarouselEnabled?: Maybe<Scalars['Boolean']>;
  isStudentsCarouselEnabled?: Maybe<Scalars['Boolean']>;
  liveEventIds: Array<Maybe<Scalars['String']>>;
  navigateTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
};


export type MutationCreateOrUpdateUniversityAssistantPromptContextArgs = {
  universityAssistantConfigurationPromptContextInput: UniversityAssistantConfigurationPromptContextInput;
};


export type MutationCreateOrganisationArgs = {
  active?: Maybe<Scalars['Boolean']>;
  childOrganisationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedInstanceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisationType: OrganisationType;
  parentOrganisationId?: Maybe<Scalars['String']>;
};


export type MutationCreateOrganisationBuddyLinkArgs = {
  organisation: Scalars['String'];
  status: Scalars['Boolean'];
  user: Scalars['String'];
};


export type MutationCreatePostArgs = {
  body?: Maybe<Scalars['String']>;
  coverImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  youtubeVideo?: Maybe<Scalars['String']>;
};


export type MutationCreateStaffContactArgs = {
  staffContactInput: StaffContactInput;
};


export type MutationCreateSubTopicArgs = {
  description?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  name: Scalars['String'];
  topic: Scalars['String'];
};


export type MutationCreateTagArgs = {
  name: Scalars['String'];
};


export type MutationCreateTopicArgs = {
  description?: Maybe<Scalars['String']>;
  externalId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateUniversityArgs = {
  countryId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationCreateUniversityAssistantEventLinkArgs = {
  universityAssistantConfigurationEventInput: UniversityAssistantConfigurationEventInput;
};


export type MutationCreateUniversityBlockReportArgs = {
  blocked?: Maybe<Scalars['String']>;
  chatGroup?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  conversation?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  triggeredFrom?: Maybe<Scalars['String']>;
};


export type MutationCreateUniversityScrapeArgs = {
  universityScrapeInput: UniversityScrapeInput;
};


export type MutationCreateUniversityScrapeBatchArgs = {
  universityScrapeManualInput: UniversityScrapeManualInput;
};


export type MutationCreateUniversityScrapeOneArgs = {
  universityScrapeInput: UniversityScrapeInput;
};


export type MutationCreateUniversityUserArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationCreateUserMutationArgs = {
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffFields?: Maybe<Scalars['JSONString']>;
  university?: Maybe<Scalars['String']>;
};


export type MutationCreateUserReportArgs = {
  userReportUserInputDTO: UserReportUserInputDto;
};


export type MutationCreateVideoUploadLinkArgs = {
  videoUploadLinkInput: VideoUploadLinkInput;
};


export type MutationCreateVirtualEventArgs = {
  createVirtualEventInput: CreateVirtualEventInput;
  universityId: Scalars['ObjectId'];
};


export type MutationCreateVirtualEventProspectReportArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type MutationCsvUploadAmbassadorFieldsArgs = {
  file: Scalars['Upload'];
  uniSlug: Scalars['String'];
};


export type MutationCsvUploadApplicantsArgs = {
  file: Scalars['Upload'];
  password: Scalars['String'];
  uniSlug: Scalars['String'];
};


export type MutationDeactivateApplicantsArgs = {
  applicantIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeactivateMentorsArgs = {
  mentorIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteAdminRecommendationArgs = {
  recommendationId: Scalars['String'];
};


export type MutationDeleteBlogPostArgs = {
  blogPostId?: Maybe<Scalars['String']>;
};


export type MutationDeleteCanonicalQuestionArgs = {
  question: Scalars['String'];
};


export type MutationDeleteChatRoomArgs = {
  chatRoomId: Scalars['ObjectId'];
};


export type MutationDeleteCommunityConversationArgs = {
  DeleteCommunityConversationDTO: DeleteCommunityConversationDto;
};


export type MutationDeleteConversationMessageArgs = {
  deleteMessageDto: DeleteMessageDto;
};


export type MutationDeleteCustomAttributeOptionsArgs = {
  optionIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteCustomAttributesArgs = {
  attributeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteDegreeLevelArgs = {
  degreeLevelId: Scalars['String'];
  universitySlug: Scalars['String'];
};


export type MutationDeleteEventPageArgs = {
  eventPageId: Scalars['ObjectId'];
};


export type MutationDeleteExternalContentArgs = {
  externalContentId: Scalars['ObjectId'];
};


export type MutationDeleteFaqAnswerArgs = {
  answer: Scalars['String'];
};


export type MutationDeleteLiveEventArgs = {
  liveEventId?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationDeleteLiveEventFeedArgs = {
  liveEventFeedId?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationDeleteLiveEventMessageMutationArgs = {
  messageId?: Maybe<Scalars['String']>;
};


export type MutationDeleteLiveSessionArgs = {
  liveSessionId: Scalars['ObjectId'];
};


export type MutationDeleteRecordingArgs = {
  eventId?: Maybe<Scalars['String']>;
  sid: Scalars['String'];
  universityId: Scalars['String'];
};


export type MutationDeleteResearchTimeArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSubTopicArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTopicArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUniversityKnowledgeBaseSourceByIdArgs = {
  knowledgeBaseDeleteEntryInput: KnowledgeBaseDeleteEntryInput;
};


export type MutationDeleteUniversityKnowledgeBaseSourceByPatternMatchArgs = {
  deleteKnowledgeBaseEntriesByPatternMatchInput: DeleteKnowledgeBaseEntriesByPatternMatchInput;
};


export type MutationDeleteUniversityKnowledgeBaseSourceByPatternMismatchArgs = {
  deleteKnowledgeBaseEntriesByPatternMismatchInput: DeleteKnowledgeBaseEntriesByPatternMismatchInput;
};


export type MutationDeleteUniversityKnowledgeBaseSourceByUrlListArgs = {
  deleteKnowledgeBaseEntriesByUrlListInput: DeleteKnowledgeBaseEntriesByUrlListInput;
};


export type MutationDeleteUniversityUserArgs = {
  universitySlug?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
};


export type MutationDeleteVirtualEventArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type MutationDeleteYearOfStudyArgs = {
  yearOfStudyId: Scalars['String'];
};


export type MutationEditAdminRecommendationArgs = {
  adminRecommendationInput: AdminRecommendationInput;
  recommendationId: Scalars['String'];
};


export type MutationEditAlertArgs = {
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationEditBlogPostArgs = {
  body?: Maybe<Scalars['String']>;
  coverImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  topicId?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
  youtubeVideo?: Maybe<Scalars['String']>;
};


export type MutationEditBlogTopicArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationEditCommunityArgs = {
  EditCommunityDTO: EditCommunityDto;
};


export type MutationEditCommunityConversationArgs = {
  EditCommunityConversationDTO: EditCommunityConversationDto;
};


export type MutationEditCookieConsentPreferencesArgs = {
  CookieConsentInputDTO: CookieConsentInputDto;
};


export type MutationEditCustomAttributeArgs = {
  active?: Maybe<Scalars['Boolean']>;
  attributeId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
};


export type MutationEditCustomAttributeOptionArgs = {
  newValue?: Maybe<Scalars['String']>;
  optionId?: Maybe<Scalars['String']>;
};


export type MutationEditCustomAttributesArgs = {
  attributes?: Maybe<Array<Maybe<CustomAttributeInput>>>;
};


export type MutationEditCustomWelcomeMessageArgs = {
  defaultProspectWelcomeMessage?: Maybe<Scalars['String']>;
  groupType?: Maybe<Scalars['String']>;
};


export type MutationEditDegreeLevelArgs = {
  degreeLevelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationEditDegreeNameArgs = {
  degreeId?: Maybe<Scalars['String']>;
  degreeLevelName?: Maybe<Scalars['String']>;
  newDegreeName?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationEditDegreesArgs = {
  degreeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  degreeLevelName?: Maybe<Scalars['String']>;
  degreeTypes?: Maybe<Array<Maybe<DegreeType>>>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationEditExternalEventArgs = {
  description: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  externalEventId?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};


export type MutationEditLiveEventArgs = {
  chatGroups?: Maybe<Array<Maybe<LiveEventChatGroupsInputField>>>;
  description?: Maybe<Scalars['String']>;
  directMessagingMentors?: Maybe<Array<Maybe<Scalars['String']>>>;
  directMessagingStaff?: Maybe<Array<Maybe<Scalars['String']>>>;
  directMessagingUniversity?: Maybe<Scalars['Boolean']>;
  end?: Maybe<Scalars['String']>;
  liveEventId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationEditLiveEventDetailsArgs = {
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationEditLiveEventFeedArgs = {
  description?: Maybe<Scalars['String']>;
  hostIdsVideoSessions?: Maybe<Array<Maybe<Scalars['String']>>>;
  liveEventFeedId?: Maybe<Scalars['String']>;
  mentors?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  recordingEnabled?: Maybe<Scalars['Boolean']>;
  staff?: Maybe<Array<Maybe<Scalars['String']>>>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationEditLiveEventsLandingPageArgs = {
  description: Scalars['String'];
  externalEventIds: Array<Maybe<Scalars['String']>>;
  image?: Maybe<Scalars['String']>;
  liveEventIds: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationEditOpenDayArgs = {
  description: Scalars['String'];
  end?: Maybe<Scalars['String']>;
  externalEventIds: Array<Maybe<Scalars['String']>>;
  image?: Maybe<Scalars['String']>;
  isStaffCarouselEnabled?: Maybe<Scalars['Boolean']>;
  isStudentsCarouselEnabled?: Maybe<Scalars['Boolean']>;
  liveEventIds: Array<Maybe<Scalars['String']>>;
  navigateTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  openDayId: Scalars['String'];
  start?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
};


export type MutationEditOrganisationArgs = {
  active?: Maybe<Scalars['Boolean']>;
  childOrganisationIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  organisationId: Scalars['String'];
};


export type MutationEditUniversityArgs = {
  churned?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
  customChatBubbleTextInLocales?: Maybe<Array<Maybe<TextInLocaleInput>>>;
  customMarketingConsentText?: Maybe<Scalars['String']>;
  customMarketingConsentTextInLocales?: Maybe<Array<Maybe<TextInLocaleInput>>>;
  customPreSignupText?: Maybe<Scalars['String']>;
  customPreSignupTextInLocales?: Maybe<Array<Maybe<TextInLocaleInput>>>;
  degreeTypeOrder?: Maybe<Array<Maybe<DegreeTypeOrderInput>>>;
  domesticRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  entries?: Maybe<EntriesInput>;
  launchDate?: Maybe<Scalars['String']>;
  localePreference?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  marketplaces?: Maybe<Array<Maybe<UniversityMarketplaceInput>>>;
  name?: Maybe<Scalars['String']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  squareLogo?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  whiteLogo?: Maybe<Scalars['String']>;
  widgetUrl?: Maybe<Scalars['String']>;
  widgetUrls?: Maybe<Array<Maybe<WidgetUrlsInput>>>;
};


export type MutationEditYearOfStudyArgs = {
  degreeLevelName: Scalars['String'];
  name: Scalars['String'];
  universitySlug: Scalars['String'];
  yearOfStudyId: Scalars['String'];
};


export type MutationEmailInvitesDataForCommunityArgs = {
  communityId: Scalars['String'];
  filters: InviteFilter;
  retrieveArchived?: Maybe<Scalars['Boolean']>;
};


export type MutationEnableCustomWelcomeMessageArgs = {
  allowWelcomeMessageCustomization?: Maybe<Scalars['Boolean']>;
};


export type MutationEnableCustomWelcomeMessageStaffArgs = {
  allowWelcomeMessageCustomizationStaff?: Maybe<Scalars['Boolean']>;
};


export type MutationEnableFeaturesArgs = {
  features?: Maybe<Array<Maybe<FeatureInputField>>>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationExportAnalyticsCsvArgs = {
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
};


export type MutationForwardApplicantToUniversityArgs = {
  applicantId?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};


export type MutationGenerateAnalyticsDashboardTokenArgs = {
  integrationId: Scalars['String'];
  metadata?: Maybe<Array<Maybe<AnalyticsDashboardMetadata>>>;
};


export type MutationGenerateDownloadUrlArgs = {
  objectName?: Maybe<Scalars['String']>;
};


export type MutationGenerateLiveEventReportArgs = {
  liveEventId?: Maybe<Scalars['String']>;
};


export type MutationGenerateRecommendationsArgs = {
  generateRecommendationsInput: GenerateRecommendationsInput;
};


export type MutationGetMarketplaceApplicantArgs = {
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  marketplaceUser?: Maybe<Scalars['JSONString']>;
  newPassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationGetOrCreateWidgetConversationArgs = {
  widgetConversationDto: WidgetConversationDto;
};


export type MutationGetSignedS3PostFieldsArgs = {
  objectName?: Maybe<Scalars['String']>;
};


export type MutationGetSignedS3UrlArgs = {
  objectName?: Maybe<Scalars['String']>;
};


export type MutationHideMentorInMarketplaceArgs = {
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  mentorId: Scalars['String'];
};


export type MutationInitialiseRecordingArgs = {
  data: InitialiseRecordingArgs;
};


export type MutationInviteApplicantsArgs = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationInviteBuddiesArgs = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationInviteEmailsToCommunityArgs = {
  communityId: Scalars['String'];
  emails: Array<Scalars['String']>;
  sendInvites?: Maybe<Scalars['Boolean']>;
};


export type MutationInviteMentorsArgs = {
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationJoinChatGroupArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
};


export type MutationJoinCommunityArgs = {
  communityId: Scalars['String'];
};


export type MutationJoinCommunityByInviteLinkArgs = {
  communityId: Scalars['String'];
};


export type MutationJoinCommunityConversationArgs = {
  conversationId: Scalars['String'];
};


export type MutationJoinCommunityForAdminsArgs = {
  communityId: Scalars['String'];
};


export type MutationLabelSentenceArgs = {
  chatGroupId: Scalars['String'];
  classifiedSentenceId: Scalars['String'];
  messageId: Scalars['String'];
  subTopicExternalId: Scalars['String'];
  text: Scalars['String'];
  topicExternalId: Scalars['String'];
};


export type MutationLeaveChatGroupArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
};


export type MutationLeaveCommunityChatGroupArgs = {
  conversationId: Scalars['String'];
};


export type MutationLikeConversationMessageArgs = {
  likeMessageDto: LikeMessageDto;
};


export type MutationLikeMessageArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  liked?: Maybe<Scalars['Boolean']>;
  messageId?: Maybe<Scalars['String']>;
};


export type MutationLinkOrganisationsArgs = {
  childOrganisationId: Scalars['String'];
  parentOrganisationId: Scalars['String'];
};


export type MutationLiveEventFeedbackFormArgs = {
  accountRole?: Maybe<Scalars['String']>;
  eventExperience?: Maybe<Scalars['Int']>;
  eventFeedback?: Maybe<Scalars['String']>;
  liveEventSlug?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationManageOrganisationAccessArgs = {
  access: Scalars['Boolean'];
  organisationId: Scalars['String'];
};


export type MutationManuallyClassifyArgs = {
  classificationId: Scalars['String'];
  subTopicExternalId?: Maybe<Scalars['String']>;
  subTopicId?: Maybe<Scalars['String']>;
};


export type MutationMarkAssistantConversationAsViewedArgs = {
  markAssistantConversationAsViewedInput: MarkAssistantConversationAsViewedInput;
};


export type MutationMentorBroadcastMessageArgs = {
  receiverIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};


export type MutationMessagePublicBotArgs = {
  botAction?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationModerateChatReportArgs = {
  chatReportModeratorInputDTO: ChatReportModeratorInputDto;
};


export type MutationModerateUserReportArgs = {
  userReportModeratorInputDTO: UserReportModeratorInputDto;
};


export type MutationPinMessageArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};


export type MutationPinMessageInConversationArgs = {
  pinMessageDTO: PinMessageDto;
};


export type MutationReadChatArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
};


export type MutationRecordConsentArgs = {
  channelId: Scalars['ObjectId'];
  time: Scalars['DateTime'];
  universityId: Scalars['ObjectId'];
};


export type MutationRecordFailureArgs = {
  data: RecordingFailureArgs;
};


export type MutationRecordLastMessageReadByUserArgs = {
  markReadMessageDto: MarkReadMessageDto;
};


export type MutationRecordUserActivityArgs = {
  activity?: Maybe<Scalars['String']>;
  activitySource?: Maybe<Scalars['JSONString']>;
  app?: Maybe<Scalars['String']>;
};


export type MutationRegisterToLiveEventArgs = {
  liveEventSlug: Scalars['String'];
  universitySlug: Scalars['String'];
};


export type MutationRejectRequestedPublicGroupArgs = {
  rejectRequestedPublicGroupInput: RejectRequestedPublicGroupInput;
};


export type MutationRemoveApplicationInsightArgs = {
  removeApplicantInsightInput: RemoveApplicantInsightInput;
};


export type MutationRemoveBuddyInvitesArgs = {
  inviteIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
};


export type MutationRemoveDeviceArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRemoveMembersFromCommunityArgs = {
  removeMembersDTO: RemoveMembersFromCommunityInputDto;
};


export type MutationRemoveMentorInvitesArgs = {
  inviteIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationRemoveReactionFromMessageArgs = {
  reactionDto: ReactionDto;
};


export type MutationRemoveUserFromCommunityChatGroupArgs = {
  conversationId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveVoteArgs = {
  chatMessageId: Scalars['ObjectId'];
  option: Scalars['String'];
};


export type MutationRequestAnalyticsExportArgs = {
  email?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationRequestPasswordResetArgs = {
  email?: Maybe<Scalars['String']>;
  localePreference?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  rootUrl?: Maybe<RootUrlEnum>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationRequestPasswordSetArgs = {
  email?: Maybe<Scalars['String']>;
  rootUrl?: Maybe<RootUrlEnum>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationRequestPublicGroupArgs = {
  requestPublicGroupInput: CreateRequestedPublicGroupInput;
};


export type MutationRequestResetPasswordArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationRequestResetPasswordMobileArgs = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationRequestResetPasswordMobileV2Args = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  password?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordV2Args = {
  password?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationResolveChatsArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationResolveConversationMessageArgs = {
  resolveMessageDto: ResolveMessageDto;
};


export type MutationRunManageCommandArgs = {
  command?: Maybe<Scalars['String']>;
};


export type MutationSaveAdminRecommendationArgs = {
  adminRecommendationInput: AdminRecommendationInput;
};


export type MutationSaveAmbassadorRecommendationArgs = {
  ambassadorRecommendationInput: AmbassadorRecommendationInput;
};


export type MutationSaveAppliedUsersArgs = {
  saveAppliedUsersInput: SaveAppliedUsersInput;
};


export type MutationSaveAssistantConversationEmailLeadArgs = {
  saveAssistantConversationEmailLeadInput: SaveAssistantConversationEmailLeadInput;
};


export type MutationSaveAssistantConversationFeedbackArgs = {
  saveAssistantConversationFeedbackInput: SaveAssistantConversationFeedbackInput;
};


export type MutationSaveCookieConsentPreferencesArgs = {
  CookieConsentInputDTO: CookieConsentInputDto;
};


export type MutationSaveInboxPromptAnswerArgs = {
  inboxPromptAnswerInput: InboxPromptAnswerInput;
};


export type MutationSaveInsightsByCommunityArgs = {
  communityId: Scalars['String'];
  emails: Array<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};


export type MutationSaveNotInterestedStageArgs = {
  notInterestedStageInput: UserNotInterestedStageInput;
};


export type MutationSaveSatisfactionSurveyArgs = {
  SatisfactionSurveyDTO: SatisfactionSurveyDto;
};


export type MutationSaveUniversityQuestionAnswerArgs = {
  saveUniversityQuestionAnswerInput: SaveUniversityQuestionAnswerInput;
};


export type MutationSaveUserDecisionStageArgs = {
  decisionStageInput: UserDecisionStageInput;
};


export type MutationSaveVisitedRecommendationArgs = {
  saveVisitedRecommendationInput: SaveVisitedRecommendationInput;
};


export type MutationSendAssistantMessageArgs = {
  sendAssistantMessageInput: SendAssistantMessageInput;
};


export type MutationSendConversationMessageArgs = {
  messageDto: MessageDto;
};


export type MutationSendFeatureNotificationArgs = {
  FeatureNotificationRequestDTO: FeatureNotificationRequestDto;
};


export type MutationSendFeedMessageArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Metadata>;
  richContent?: Maybe<RichContentInputField>;
  text?: Maybe<Scalars['String']>;
};


export type MutationSendLiveEventMessageArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Metadata>;
  richContent?: Maybe<RichContentInputField>;
  text?: Maybe<Scalars['String']>;
};


export type MutationSendMessageArgs = {
  botAction?: Maybe<Scalars['String']>;
  chatGroupId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Metadata>;
  origin?: Maybe<OriginField>;
  richContent?: Maybe<RichContentInputField>;
  skipNotifications?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  timeSpent?: Maybe<Scalars['Int']>;
};


export type MutationSendOpenDayProspectReportArgs = {
  openDayId: Scalars['String'];
};


export type MutationSendVerificationCodeArgs = {
  source: SourceInputField;
};


export type MutationSetActiveBlogTopicsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  blogTopicIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationSetArchivedContentBriefArgs = {
  archived: Scalars['Boolean'];
  contentBriefId: Scalars['String'];
  universityId: Scalars['String'];
  valuable?: Maybe<Scalars['Boolean']>;
};


export type MutationSetArchivedDegreeLevelArgs = {
  archived: Scalars['Boolean'];
  degreeLevelId: Scalars['String'];
  universitySlug: Scalars['String'];
};


export type MutationSetArchivedYearOfStudyArgs = {
  archived: Scalars['Boolean'];
  universitySlug: Scalars['String'];
  yearOfStudyId: Scalars['String'];
};


export type MutationSetBlockUserArgs = {
  setBlocked?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationSetBlockUserV2Args = {
  setBlocked?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
};


export type MutationSetBlogPostStatusArgs = {
  blogPostId?: Maybe<Scalars['String']>;
  status?: Maybe<BlogStatus>;
};


export type MutationSetBuddiesListFiltersArgs = {
  filters?: Maybe<Array<Maybe<BuddiesListFilterInput>>>;
};


export type MutationSetChatGroupLabelsArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  labelIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationSetDeviceTokenArgs = {
  SetDeviceTokenDTO: SetDeviceTokenDto;
};


export type MutationSetInterestsForUserArgs = {
  setUserInterestsInput: SetUserInterestsInput;
};


export type MutationSetMentorFieldOrderArgs = {
  order?: Maybe<Array<Maybe<MentorFieldOrderInput>>>;
};


export type MutationSetMentorListFilterSelectionArgs = {
  choices?: Maybe<Array<Maybe<MentorListFilterChoiceInput>>>;
};


export type MutationSetOnlineArgs = {
  online?: Maybe<Scalars['Boolean']>;
};


export type MutationSetResolveChatGroupArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
};


export type MutationSetUniversityUnblockUsersArgs = {
  setUnblocked?: Maybe<Scalars['Boolean']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationSetUserProfileArgs = {
  data: UpdateUserProfile;
};


export type MutationSetWillReplyArgs = {
  applicant?: Maybe<Scalars['String']>;
  chatGroupId?: Maybe<Scalars['String']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  mobileAdded?: Maybe<Scalars['Boolean']>;
};


export type MutationShowMentorInMarketplaceArgs = {
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  mentorId: Scalars['String'];
};


export type MutationStartRecordingVideoArgs = {
  data: StartRecordingArgs;
};


export type MutationStopRecordingVideoArgs = {
  data: StopRecordingArgs;
};


export type MutationStopUniversityCrawlArgs = {
  universityStopCrawlInput: UniversityStopCrawlInput;
};


export type MutationToggleCommunityChatGroupMuteArgs = {
  conversationId: Scalars['String'];
  status: Scalars['Boolean'];
};


export type MutationToggleCrmIntegrationSyncArgs = {
  id?: Maybe<Scalars['String']>;
};


export type MutationToggleCustomAttributeCrmSyncArgs = {
  fieldName?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationToggleDegreesActiveArgs = {
  degreeIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  degreeLevels?: Maybe<Array<Maybe<Scalars['String']>>>;
  setActive?: Maybe<Scalars['Boolean']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationToggleFeatureAvailabilityArgs = {
  features?: Maybe<Array<Maybe<FeatureToggleInputField>>>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationTrackLiveEventArgs = {
  liveEventSlug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationTrackOpenDayArgs = {
  name: Scalars['String'];
  openDayId: Scalars['String'];
  product: Scalars['String'];
  universitySlug: Scalars['String'];
};


export type MutationUnlikeConversationMessageArgs = {
  likeMessageDto: LikeMessageDto;
};


export type MutationUnpinMessageArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};


export type MutationUnpinMessageInConversationArgs = {
  unpinMessageDTO: UnpinMessageDto;
};


export type MutationUnresolveConversationMessageArgs = {
  resolveMessageDto: ResolveMessageDto;
};


export type MutationUpdateApplicantArgs = {
  acceptMarketing?: Maybe<Scalars['Boolean']>;
  ageVerificationInfo?: Maybe<AgeVerificationInfoInputField>;
  country?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueInput>>>;
  degreeLevel?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<Scalars['String']>>>;
  domesticRegion?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasReadChatRules?: Maybe<Scalars['Boolean']>;
  highSchool?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  localePreference?: Maybe<UserLocalePreferenceInput>;
  pastUniversity?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationUpdateApplicantPreferencesArgs = {
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateCanonicalQuestionArgs = {
  question?: Maybe<Scalars['String']>;
  questionId: Scalars['String'];
  subTopic?: Maybe<Scalars['String']>;
};


export type MutationUpdateChatRoomArgs = {
  chatRoomId: Scalars['ObjectId'];
  updateChatRoomInput: UpdateChatRoomInput;
};


export type MutationUpdateCommunityApplicantSignupsourceArgs = {
  communityId?: Maybe<Scalars['String']>;
};


export type MutationUpdateCrmServiceUniversityConfigArgs = {
  authToken?: Maybe<Scalars['String']>;
  connectorEndpoint?: Maybe<Scalars['String']>;
  connectorName?: Maybe<Scalars['String']>;
  dataFormatConfigs?: Maybe<Array<Maybe<DataFormatConfigInputType>>>;
  fields?: Maybe<Array<Maybe<CrmFieldInputType>>>;
  id?: Maybe<Scalars['String']>;
  isSyncActive?: Maybe<Scalars['Boolean']>;
  sendSampleData?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['String']>;
  zapierWebhook?: Maybe<Scalars['String']>;
};


export type MutationUpdateDirectMessagingMembersForLiveEventArgs = {
  directMessagingMentors?: Maybe<Array<Maybe<Scalars['String']>>>;
  directMessagingStaff?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type MutationUpdateEventPageArgs = {
  eventPageId: Scalars['ObjectId'];
  updateEventPageInput: UpdateEventPageInput;
};


export type MutationUpdateExternalContentArgs = {
  externalContentId: Scalars['ObjectId'];
  updateExternalContentInput: UpdateExternalContentInput;
};


export type MutationUpdateFaqAnswerArgs = {
  answer?: Maybe<Scalars['String']>;
  answerId: Scalars['String'];
  canonicalQuestion?: Maybe<Scalars['String']>;
};


export type MutationUpdateHobsonsConnectIntegrationArgs = {
  clientName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  passKey?: Maybe<Scalars['String']>;
  webServiceUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateIntegrationFieldMappingArgs = {
  fieldMapping?: Maybe<Array<Maybe<FieldMappingInput>>>;
  integrationId?: Maybe<Scalars['String']>;
};


export type MutationUpdateLiveEventFeedSessionDataArgs = {
  liveEventFeedId?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  videoSessions?: Maybe<Array<Maybe<VideoSessionToUpdateField>>>;
};


export type MutationUpdateLiveSessionArgs = {
  liveSessionId: Scalars['ObjectId'];
  updateLiveSessionInput: UpdateLiveSessionInput;
};


export type MutationUpdateLiveSessionAnyUserArgs = {
  liveSessionId: Scalars['ObjectId'];
  updateLiveSessionInput: UpdateLiveSessionAnyUserInput;
};


export type MutationUpdateManyUserMutationArgs = {
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  locked?: Maybe<Scalars['Boolean']>;
  smudged?: Maybe<Scalars['Boolean']>;
  university?: Maybe<Scalars['String']>;
};


export type MutationUpdateMarketplaceArgs = {
  active?: Maybe<Scalars['Boolean']>;
  allowUniversityProspectDataAccess?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<Scalars['String']>;
  customBuddyWord?: Maybe<Scalars['String']>;
  customFilters?: Maybe<Array<Maybe<MarketplaceFilterInput>>>;
  disableExternalNotification?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  marketplaceMarketingConsentCollectionEnabled?: Maybe<Scalars['Boolean']>;
  marketplaceMarketingConsentMessage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  personalDataApiEnabled?: Maybe<Scalars['Boolean']>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  unibuddyMarketingConsentEnabled?: Maybe<Scalars['Boolean']>;
  universityMarketingConsentEnabled?: Maybe<Scalars['Boolean']>;
  webhookUrl?: Maybe<Scalars['String']>;
  widgetDescription?: Maybe<Scalars['String']>;
  widgetTitle?: Maybe<Scalars['String']>;
  widgetUrl?: Maybe<Scalars['String']>;
};


export type MutationUpdateMarketplaceApplicantArgs = {
  marketplace: ApplicantMarketplaceInput;
};


export type MutationUpdateMarketplaceApplicantUniversityLinkArgs = {
  acceptMarketing?: Maybe<Scalars['Boolean']>;
  marketplaceSlug: Scalars['String'];
  universitySlug: Scalars['String'];
};


export type MutationUpdateMentorArgs = {
  bio?: Maybe<Scalars['String']>;
  chatIntro?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueInput>>>;
  degree?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<MentorDegreeFieldInput>>>;
  favouriteModules?: Maybe<Array<Maybe<Scalars['String']>>>;
  firstName?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<Scalars['String']>>>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  mentorId?: Maybe<Scalars['String']>;
  pastQualification?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  primaryFlag?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  secondaryFlag?: Maybe<Scalars['String']>;
  societies?: Maybe<Array<Maybe<Scalars['String']>>>;
  year?: Maybe<Scalars['String']>;
};


export type MutationUpdateMentorPreferencesArgs = {
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateMyUniversityLinkArgs = {
  acceptMarketing?: Maybe<Scalars['Boolean']>;
  applied?: Maybe<Scalars['Boolean']>;
  countryId?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueInput>>>;
  dateOfEntry?: Maybe<Scalars['Date']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreesInterested?: Maybe<Array<Maybe<Scalars['String']>>>;
  domesticRegion?: Maybe<Scalars['String']>;
  localePreference?: Maybe<UserLocalePreferenceInput>;
  sourceMetadata?: Maybe<SourceMetadataField>;
  sourceTrackingParams?: Maybe<Scalars['JSONString']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type MutationUpdateRecordingLayoutArgs = {
  data: UpdateRecordingLayoutArgs;
};


export type MutationUpdateResearchTimeArgs = {
  researchTimeData: ResearchTimeInput;
};


export type MutationUpdateStaffArgs = {
  bio?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueInput>>>;
  department?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type MutationUpdateStaffContactArgs = {
  updateStaffContactInput: UpdateStaffContactInput;
};


export type MutationUpdateSubTopicArgs = {
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};


export type MutationUpdateTagArgs = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateTopicArgs = {
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateUniversityBlockReportArgs = {
  blockedUser?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  universityBlockReportId?: Maybe<Scalars['String']>;
};


export type MutationUpdateUniversityUserArgs = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationUpdateUniversityUserPreferencesArgs = {
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateUserMutationArgs = {
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffFields?: Maybe<Scalars['JSONString']>;
  university?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserPreferencesMutationArgs = {
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateVirtualEventArgs = {
  updateVirtualEventInput: UpdateVirtualEventInput;
  virtualEventId: Scalars['ObjectId'];
};


export type MutationVerifyAccountArgs = {
  code: Scalars['String'];
};


export type MutationViewBlogArgs = {
  blogId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type NewsFeedGroup = {
  __typename?: 'NewsFeedGroup';
  conversationId: Scalars['String'];
};

export enum NotInterestedReasonEnum {
  NotInterestedInApplyingToUni = 'NOT_INTERESTED_IN_APPLYING_TO_UNI',
  NotInterestedInUpdatesFromUni = 'NOT_INTERESTED_IN_UPDATES_FROM_UNI',
  NotInterestedOther = 'NOT_INTERESTED_OTHER'
}

export type NotificationField = {
  __typename?: 'NotificationField';
  id?: Maybe<Scalars['String']>;
};



export type OffsetPagination = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type OpenDayField = {
  __typename?: 'OpenDayField';
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  externalEvents?: Maybe<Array<Maybe<ExternalEventField>>>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isStaffCarouselEnabled?: Maybe<Scalars['Boolean']>;
  isStudentsCarouselEnabled?: Maybe<Scalars['Boolean']>;
  liveEvents?: Maybe<Array<Maybe<LiveEventDetailsField>>>;
  navigateOpenDays?: Maybe<Array<Maybe<OpenDayField>>>;
  navigateTags?: Maybe<Array<Maybe<TagField>>>;
  prospectLogins?: Maybe<OpenDaysLoginField>;
  prospectSignups?: Maybe<OpenDaysSignupField>;
  prospectsAttending?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<TagField>>>;
  title?: Maybe<Scalars['String']>;
};

export type OpenDaysLoginField = {
  __typename?: 'OpenDaysLoginField';
  id?: Maybe<Scalars['String']>;
  liveEvent?: Maybe<Scalars['Int']>;
  openDay?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  widget?: Maybe<Scalars['Int']>;
};

export type OpenDaysSignupField = {
  __typename?: 'OpenDaysSignupField';
  id?: Maybe<Scalars['String']>;
  liveEvent?: Maybe<Scalars['Int']>;
  openDay?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  widget?: Maybe<Scalars['Int']>;
};

export type OpenTextFieldValueField = CustomAttributeValueField & {
  __typename?: 'OpenTextFieldValueField';
  attribute?: Maybe<CustomAttributeField>;
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type OrderByLiveSessionsInput = {
  end?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
};

export type OrganisationBuddyLinkField = {
  __typename?: 'OrganisationBuddyLinkField';
  organisation?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  user?: Maybe<Scalars['String']>;
};

export type OrganisationField = {
  __typename?: 'OrganisationField';
  active?: Maybe<Scalars['Boolean']>;
  childOrganisations?: Maybe<Array<Maybe<ChildOrganisationField>>>;
  created?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  linkedInstance?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisationType: Scalars['String'];
  parentOrganisation?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export enum OrganisationType {
  Enterprise = 'ENTERPRISE',
  University = 'UNIVERSITY'
}

export type OriginField = {
  slug?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type OutboxChatGroupField = {
  __typename?: 'OutboxChatGroupField';
  aux?: Maybe<ChatGroupAuxiliaryField>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<LastMessageField>;
  members?: Maybe<Array<Maybe<AnyUserField>>>;
  opponent?: Maybe<AnyChatUserField>;
  resolved?: Maybe<Scalars['Boolean']>;
  sendbirdChannelUrl?: Maybe<Scalars['String']>;
  unansweredByMentor?: Maybe<Scalars['Boolean']>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
};

export type OutputField = {
  __typename?: 'OutputField';
  returncode?: Maybe<Scalars['Int']>;
  stderr?: Maybe<Scalars['String']>;
  stdout?: Maybe<Scalars['String']>;
};

export type PageMentorField = {
  __typename?: 'PageMentorField';
  mentors?: Maybe<Array<Maybe<MentorField>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedAdminRecommendation = {
  __typename?: 'PaginatedAdminRecommendation';
  hasMore: Scalars['Boolean'];
  items: Array<AdminRecommendation>;
};

export type PaginatedApplicantInsightInput = {
  universityId: Scalars['ObjectId'];
};

export type PaginatedApplicantInvite = {
  __typename?: 'PaginatedApplicantInvite';
  data?: Maybe<Array<Maybe<ApplicantInvite>>>;
  totalNum?: Maybe<Scalars['Int']>;
};

export type PaginatedApplicationInsightModel = {
  __typename?: 'PaginatedApplicationInsightModel';
  hasMore: Scalars['Boolean'];
  items: Array<ApplicationInsightModel>;
};

export type PaginatedChatMessages = {
  __typename?: 'PaginatedChatMessages';
  hasMore: Scalars['Boolean'];
  messages: Array<ChatMessage>;
};

export type PaginatedChatRooms = {
  __typename?: 'PaginatedChatRooms';
  hasMore: Scalars['Boolean'];
  items: Array<ChatRoom>;
};

export type PaginatedEventPages = {
  __typename?: 'PaginatedEventPages';
  hasMore: Scalars['Boolean'];
  items: Array<EventPage>;
};

export type PaginatedExternalContents = {
  __typename?: 'PaginatedExternalContents';
  hasMore: Scalars['Boolean'];
  items: Array<ExternalContent>;
};

export type PaginatedLiveSessions = {
  __typename?: 'PaginatedLiveSessions';
  hasMore: Scalars['Boolean'];
  items: Array<LiveSession>;
};

export type PaginatedVirtualEvents = {
  __typename?: 'PaginatedVirtualEvents';
  hasMore: Scalars['Boolean'];
  items: Array<VirtualEvent>;
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type PasswordResetMobileMutation = {
  __typename?: 'PasswordResetMobileMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type PasswordResetMobileMutationV2 = {
  __typename?: 'PasswordResetMobileMutationV2';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PayloadField = {
  __typename?: 'PayloadField';
  buttons?: Maybe<Array<Maybe<ButtonField>>>;
  relativePath?: Maybe<Scalars['String']>;
  templateType?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type PinMessageMutation = {
  __typename?: 'PinMessageMutation';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type PinnedMessageField = {
  __typename?: 'PinnedMessageField';
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sender?: Maybe<PinnedMessageSenderField>;
  text?: Maybe<Scalars['String']>;
};

export type PinnedMessageSenderField = {
  __typename?: 'PinnedMessageSenderField';
  accountRole?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
};

export type Poll = {
  __typename?: 'Poll';
  allowMultipleAnswers: Scalars['Boolean'];
  options: Array<PollOption>;
  question: Scalars['String'];
};

export type PollDto = {
  allowMultipleAnswers: Scalars['Boolean'];
  options: Array<PollOptionDto>;
  question: Scalars['String'];
};

export type PollOption = {
  __typename?: 'PollOption';
  option: Scalars['String'];
  votes: Array<Scalars['ObjectId']>;
};

export type PollOptionDto = {
  option: Scalars['String'];
  votes?: Maybe<Array<Scalars['ObjectId']>>;
};

export type PopCardDegreeSnippetField = {
  __typename?: 'PopCardDegreeSnippetField';
  description?: Maybe<Scalars['String']>;
  getSnippet?: Maybe<Array<Maybe<DegreeSnippetField>>>;
};

export type PopCardLevelSnippetField = {
  __typename?: 'PopCardLevelSnippetField';
  description?: Maybe<Scalars['String']>;
  getSnippet?: Maybe<Array<Maybe<LevelSnippetField>>>;
};

export type PopCardWidgetField = {
  __typename?: 'PopCardWidgetField';
  description?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
};

export type PopcardSnippetField = {
  __typename?: 'PopcardSnippetField';
  defaultSnippet?: Maybe<Array<Maybe<PopCardWidgetField>>>;
  degreeSnippets?: Maybe<Array<Maybe<PopCardDegreeSnippetField>>>;
  description?: Maybe<DescriptionField>;
  internationalSnippet?: Maybe<Array<Maybe<PopCardWidgetField>>>;
  levelSnippets?: Maybe<Array<Maybe<PopCardLevelSnippetField>>>;
  nationalSnippet?: Maybe<Array<Maybe<PopCardWidgetField>>>;
};

export type PostFields = {
  __typename?: 'PostFields';
  acl?: Maybe<Scalars['String']>;
  awsAccessKeyId?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  xAmzSecurityToken?: Maybe<Scalars['String']>;
};

export type PreferencesInputType = {
  analyticsCookies: Scalars['Boolean'];
  strictCookies?: Maybe<Scalars['Boolean']>;
};

export type PreferencesObjectType = {
  __typename?: 'PreferencesObjectType';
  analyticsCookies: Scalars['Boolean'];
  strictCookies: Scalars['Boolean'];
};

export type PreferencesOutputType = {
  __typename?: 'PreferencesOutputType';
  analyticsCookies: Scalars['Boolean'];
  strictCookies: Scalars['Boolean'];
};

export enum Product {
  Community = 'community',
  VirtualEvent = 'virtualEvent',
  Widget = 'widget'
}

export type ProspectStageStatField = {
  __typename?: 'ProspectStageStatField';
  changeComparedToLastPeriod?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  stage?: Maybe<Scalars['String']>;
};

export type ProspectStageSummaryField = {
  __typename?: 'ProspectStageSummaryField';
  summary?: Maybe<Array<Maybe<ProspectStageStatField>>>;
};

export enum PublicGroupStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

export type PublicMentorField = {
  __typename?: 'PublicMentorField';
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  chatIntro?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  country?: Maybe<CountryField>;
  created?: Maybe<Scalars['String']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueField>>>;
  degree?: Maybe<DegreeField>;
  degreeHistory?: Maybe<MentorDegreeHistory>;
  degreeLevel?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<MentorDegreeField>>>;
  favouriteModules?: Maybe<Array<Maybe<MentorModuleField>>>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Maybe<MentorInterestField>>>;
  isOnline?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<LanguageField>>>;
  lastName?: Maybe<Scalars['String']>;
  lastSeen?: Maybe<Scalars['String']>;
  mentorFields?: Maybe<MentorFields>;
  pastQualification?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  schoolHistory?: Maybe<MentorSchoolHistoryField>;
  shareUrl?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  slowSocieties?: Maybe<Array<Maybe<MentorSocietyField>>>;
  societies?: Maybe<Array<Maybe<MentorSocietyField>>>;
  university?: Maybe<UniversityField>;
  year?: Maybe<Scalars['String']>;
};


export type PublicMentorFieldCustomAttributesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type PublicMentorFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};


export type PublicMentorFieldShareUrlArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
};

export type PublicUserField = {
  __typename?: 'PublicUserField';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
};

export type QssMetadata = {
  allQuestions?: Maybe<Array<Maybe<QssQuestion>>>;
  originalQuestionText?: Maybe<Scalars['String']>;
  questionModified?: Maybe<Scalars['Boolean']>;
  rank?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  userChoseQss?: Maybe<Scalars['Boolean']>;
};

export type QssQuestion = {
  questionId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeUniversities?: Maybe<Array<Maybe<UniversityField>>>;
  adminMentor?: Maybe<MentorField>;
  adminRecommendationCountPerDecisionStage: Array<AdminRecommendationCountPerDecisionStage>;
  adminSearchMentors?: Maybe<Array<Maybe<MentorField>>>;
  agenda: PaginatedLiveSessions;
  alert?: Maybe<AlertField>;
  allAdminApplicants?: Maybe<Array<Maybe<BulkApplicantField>>>;
  allAppLocales?: Maybe<Array<Maybe<AppLocaleField>>>;
  allApplicantConversations?: Maybe<Array<Maybe<ApplicantConversationField>>>;
  allApplicantCount?: Maybe<ApplicantCountField>;
  allApplicantSignUps?: Maybe<Array<Maybe<CountPerEpoch>>>;
  allApplicants?: Maybe<Array<Maybe<BulkApplicantField>>>;
  allBuddyInvites?: Maybe<BuddyInviteField>;
  allChatGroupCount?: Maybe<Array<Maybe<CountPerEpoch>>>;
  allChatGroupsByDay?: Maybe<Array<Maybe<ChatGroupField>>>;
  allContentBriefs?: Maybe<Array<Maybe<ContentBriefField>>>;
  allCountries?: Maybe<Array<Maybe<CountryField>>>;
  allCrmIntegrations?: Maybe<Array<Maybe<CrmIntegrationField>>>;
  allCrms?: Maybe<Array<Maybe<CrmField>>>;
  allDegreeLevels?: Maybe<Array<Maybe<DegreeLevelField>>>;
  allDegreeTypes?: Maybe<Array<Maybe<DegreeType>>>;
  allDegrees?: Maybe<Array<Maybe<DegreeField>>>;
  allHighSchoolTypes?: Maybe<Array<Maybe<HighSchoolTypeField>>>;
  allHighSchools?: Maybe<Array<Maybe<HighSchoolField>>>;
  allLanguages?: Maybe<Array<Maybe<LanguageField>>>;
  allLocales?: Maybe<Array<Maybe<LocaleField>>>;
  allMentorInvites?: Maybe<Array<Maybe<MentorInviteField>>>;
  allMentors?: Maybe<Array<Maybe<MentorField>>>;
  allMentorsOfEnterprise?: Maybe<Array<Maybe<EnterpriseMentorField>>>;
  allMentorsTimeSpent?: Maybe<UsersTimeSpentData>;
  allPosts?: Maybe<Array<Maybe<BlogPostField>>>;
  allPostsInfo?: Maybe<AllPostsField>;
  allStaff?: Maybe<StaffUserListField>;
  allSubCountries?: Maybe<Array<Maybe<TopCountryField>>>;
  allSubRegions?: Maybe<Array<Maybe<TopRegionField>>>;
  allTopCountries?: Maybe<Array<Maybe<TopCountryField>>>;
  allTopDegrees?: Maybe<Array<Maybe<TopDegreeField>>>;
  allTopRegions?: Maybe<Array<Maybe<TopRegionField>>>;
  allUniversities?: Maybe<Array<Maybe<UniversityField>>>;
  allUserIds?: Maybe<UserIdsListField>;
  allUsers?: Maybe<UserListField>;
  allYearOfStudy?: Maybe<Array<Maybe<YearOfStudyField>>>;
  analytics: Analytics;
  appLocales?: Maybe<AppLocaleField>;
  applicant?: Maybe<ApplicantField>;
  applicantCollectionFields?: Maybe<Array<Maybe<ApplicantCollectionField>>>;
  applicantCount?: Maybe<ApplicantCountField>;
  applicantCountryCount?: Maybe<Scalars['Int']>;
  applicantDegreeCount?: Maybe<Scalars['Int']>;
  applicantDomesticRegionCount?: Maybe<Scalars['Int']>;
  applicantInbox?: Maybe<ApplicantInboxField>;
  applicantInvites?: Maybe<Array<Maybe<ApplicantInvite>>>;
  applicantSignUps?: Maybe<Array<Maybe<CountPerEpoch>>>;
  applicantsApplied?: Maybe<ApplicantsAppliedStats>;
  applicantsMessaged?: Maybe<ApplicantsMessagedCount>;
  applicationInsights: PaginatedApplicationInsightModel;
  appliedUsersCount: AppliedUsersCountModel;
  askQuestion?: Maybe<Array<Maybe<CanonicalQuestionField>>>;
  availableDegreeTypes?: Maybe<Array<Maybe<DegreeType>>>;
  blockedUsersByMe?: Maybe<Array<Maybe<Scalars['String']>>>;
  blogTopics?: Maybe<Array<Maybe<BlogTopicField>>>;
  botUser?: Maybe<BotUserField>;
  buddies?: Maybe<BuddiesField>;
  buddiesFilterOptions?: Maybe<BuddiesFilterOptionsField>;
  buddy?: Maybe<BuddyField>;
  buddyApplicantChatGroupCount?: Maybe<Scalars['Int']>;
  buddyApplicantChatGroups?: Maybe<Array<Maybe<BuddyApplicantChatGroupField>>>;
  buddyApplicantChatIntervention?: Maybe<Array<Maybe<BuddyApplicantChatInterventionBuddyField>>>;
  buddyApplicantChatInterventionTotalCount?: Maybe<Scalars['Int']>;
  buddyInvite?: Maybe<BuddyInviteListField>;
  buddyResponseTime?: Maybe<Array<Maybe<AnalyticsBuddyField>>>;
  canonicalQuestions?: Maybe<Array<Maybe<CanonicalQuestionField>>>;
  chatGroup?: Maybe<ChatGroupField>;
  chatGroupAux?: Maybe<ChatGroupAuxiliaryField>;
  chatGroupById?: Maybe<ChatGroupField>;
  chatGroupCount?: Maybe<Array<Maybe<CountPerEpoch>>>;
  chatGroupLabellingCandidates?: Maybe<Array<Maybe<LabellingSentenceCandidate>>>;
  chatGroupLabels?: Maybe<Array<Maybe<ChatGroupLabelField>>>;
  chatGroupSuggestions?: Maybe<Array<Maybe<SuggestionField>>>;
  chatGroupsByDay?: Maybe<Array<Maybe<ChatGroupField>>>;
  chatInterventionMentors?: Maybe<MentorInterventionQueryField>;
  chatRoom?: Maybe<ChatRoom>;
  chatRoomAnalytics?: Maybe<ChatRoomAnalytics>;
  chatRooms: PaginatedChatRooms;
  /** @deprecated Deprecated, please use communityUserSearch which has totalUsers count */
  communityUserDirectory: Array<UserField>;
  communityUserSearch: UserDirectoryResult;
  completedEvents?: Maybe<CompletedEventsOverview>;
  conversationContext?: Maybe<Array<Maybe<ConversationContextField>>>;
  /** @deprecated Do not use query. See implementation for more details. */
  conversationInboxDoNotUse: Array<ConversationInbox>;
  conversationStats?: Maybe<Array<Maybe<TrendingTopicsField>>>;
  country?: Maybe<CountryField>;
  crmApplicationFields?: Maybe<Array<Maybe<ApplicationField>>>;
  crmAttributes?: Maybe<Array<Maybe<CrmAttributeField>>>;
  crmDateFormats?: Maybe<Array<Maybe<Scalars['String']>>>;
  crmIntegration?: Maybe<CrmIntegrationField>;
  crmServiceUniversityConfig?: Maybe<CrmServiceUniversityConfig>;
  crmSyncJob?: Maybe<CrmSyncJobField>;
  currentUserDecisionStage: CurrentDecisionStageOutput;
  degreeCategories?: Maybe<Array<Maybe<DegreeCategoryField>>>;
  degreeCategoriesCount?: Maybe<Scalars['Int']>;
  degreeCategory?: Maybe<DegreeCategoryField>;
  domesticRegions?: Maybe<Array<Maybe<DomesticRegionField>>>;
  enrolledInsightsByCommunity: EnrolledInsightResponse;
  enterpriseAddOns?: Maybe<Array<Maybe<EnterpriseAddOns>>>;
  enterpriseDegrees?: Maybe<Array<Maybe<DegreeField>>>;
  enterpriseMentorCount?: Maybe<Scalars['Int']>;
  eventAnalytics: Analytics;
  eventPage?: Maybe<EventPage>;
  eventPages: PaginatedEventPages;
  exampleGraphQLQuery: Scalars['Boolean'];
  exportTimesheetReport?: Maybe<ExportTimeSheetDataField>;
  externalContent?: Maybe<ExternalContent>;
  externalContents: PaginatedExternalContents;
  externalEvent?: Maybe<ExternalEventField>;
  externalEvents?: Maybe<Array<Maybe<ExternalEventField>>>;
  filterUsersEnterprise?: Maybe<Array<Maybe<UserCustomAttributesFilterField>>>;
  getAssistantConversation?: Maybe<AssistantConversationHistory>;
  getChatConversation: ChatConversation;
  getChatConversationFromLegacyId?: Maybe<ChatConversation>;
  getChatConversationInbox: Array<ChatConversation>;
  getChatConversationMessages: PaginatedChatMessages;
  /** @deprecated Deprecated, please use getCommunityConversations */
  getChatGroupsForCommunity: Array<CommunityChatGroup>;
  /** Get chat reports for a university based on product (currently supports community and virtualEvent). The query is available to only university users and admins. */
  getChatReports: Array<ChatReport>;
  /** Paginated query to get community chat members */
  getCommunityChatMembers: Array<UserField>;
  /** This differs from the getChatConversation resolver in that it checks for the ReadCommunity permissions instead of ReadConversation permissions. */
  getCommunityConversation: ChatConversation;
  getCommunityConversations: Array<ChatConversation>;
  getCommunityForUniOrAdmin: Community;
  getCommunityForUser: Community;
  getCommunityMembers: Array<UserField>;
  getCommunityTemplates: Array<Array<Scalars['String']>>;
  getConversationPermission: ChatPermission;
  getExperimentalTodos: Scalars['String'];
  getIdpToken?: Maybe<AuthField>;
  getInterests: Array<Interest>;
  getInterestsForUser: Array<UserInterest>;
  getInviteFromId: Invite;
  getInviteInformation: InviteInformation;
  getInviteLinkInfo: InviteLinkInfoDto;
  getInvitesForCommunity: Array<CommunityInvites>;
  getInvitesForEmail: Array<GetInvitesForEmailDto>;
  getLabels: Array<Labels>;
  getLiveEventsById?: Maybe<Array<Maybe<LiveEventField>>>;
  /** @deprecated This query has now been deprecated, please use getChatConversationMessages */
  getMessagesForChatConversation: Array<ChatMessage>;
  getOrCreateBotChatGroup?: Maybe<ChatGroupField>;
  getOrCreateChatGroup?: Maybe<ChatGroupField>;
  getOrCreateCommunitiesForInstitution: Array<Community>;
  getOrCreateUniversityChatGroup?: Maybe<ChatGroupAndMessages>;
  getRandomAmbassadors?: Maybe<Array<AmbassadorProfileInformation>>;
  getRecommendedConversations: Array<ChatConversation>;
  getRecommendedUsers: UserRecommendation;
  getRecording?: Maybe<RecordingDownloadResponse>;
  getRecordingFailuresByChannelId: GetRecordingFailureIdsResponse;
  getRecordingFailuresByEventId: GetRecordingFailureIdsResponse;
  getRecordingList?: Maybe<RecordingListResponse>;
  getRecordingUploadingCount: RecordingUploadingResponse;
  getToken?: Maybe<AuthField>;
  getTokenForEnterpriseView?: Maybe<AuthField>;
  /** Gets all universities which pass the feature flag check via launch darkly based on universitySlug. */
  getUniversitiesByFeatureFlag: FeatureRulesDto;
  getUniversityAssistantConfig?: Maybe<UniversityAssistantConfiguration>;
  getUser?: Maybe<UserField>;
  getUserByEmail?: Maybe<UserField>;
  getUserByIds?: Maybe<Array<Maybe<UserField>>>;
  getUserProfile: UserProfile;
  /** Get user reports for a university based on product (currently supports community and virtualEvent). The query is available to only university users and admins. */
  getUserReports: Array<UserReport>;
  getUsersCommunityConversations: Array<ChatConversation>;
  getUsersCommunityPrivateConversations: Array<ChatConversation>;
  getVideoContent: VideoContent;
  globalAlerts?: Maybe<Array<Maybe<AlertField>>>;
  hasContent: UniversityContent;
  hasMore?: Maybe<Scalars['Boolean']>;
  helloCommunityService: Scalars['String'];
  hobsonsConnectIntegration?: Maybe<CampusManagementConnectIntegrationField>;
  inboxPromptQuestionByQuestionId: InboxPromptQuestion;
  inboxPromptQuestions: Array<InboxPromptQuestion>;
  interventionMessageList?: Maybe<Array<Maybe<InterventionMessageField>>>;
  /** Health check resolver for moderation-service */
  isModerationHealthy: Scalars['Boolean'];
  isProspectGuidanceHealthy: Scalars['Boolean'];
  isUserInDirectMessages?: Maybe<Scalars['Boolean']>;
  isUserProfilingHealthy: Scalars['Boolean'];
  isVideoContentHealthy: Scalars['Boolean'];
  latestExportedReport?: Maybe<ExportedReportField>;
  listAdminRecommendationResourceTypes: Array<RecommendationResourceType>;
  listAdminRecommendations: PaginatedAdminRecommendation;
  listArchivedCommunitiesForAnInstitution: Array<Community>;
  listBuddyApplicantConversations?: Maybe<ListBuddyApplicantConversationsField>;
  listCommunitiesForAnInstitution: Array<Community>;
  listMentorApplicantConversations?: Maybe<ListMentorApplicantConversationsField>;
  listUniversityKnowledgeBaseSources: Array<KnowledgeBaseEmbedding>;
  listUniversityKnowledgeBaseSourcesByUrlPattern: Array<KnowledgeBaseSourcesByUrl>;
  liveEvent?: Maybe<LiveEventField>;
  liveEventLandingPage?: Maybe<LiveEventLandingPageField>;
  liveEvents?: Maybe<Array<Maybe<LiveEventField>>>;
  liveFeed?: Maybe<LiveEventChatGroupField>;
  liveSession?: Maybe<LiveSession>;
  liveSessions: PaginatedLiveSessions;
  marketplace?: Maybe<MarketplaceField>;
  marketplaceApplicantChatGroupsCount?: Maybe<Scalars['Int']>;
  marketplaceApplicantCountries?: Maybe<MarketplaceApplicantCountries>;
  marketplaceApplicantDegreeCategories?: Maybe<MarketplaceApplicantDegreeCategories>;
  marketplaceApplicantsCount?: Maybe<Scalars['Int']>;
  marketplaceInbox?: Maybe<MarketplaceInboxField>;
  marketplaceMentorCountries?: Maybe<Array<Maybe<CountryField>>>;
  marketplaceMentorDegreeCategories?: Maybe<Array<Maybe<UniversityField>>>;
  marketplaceMentorList?: Maybe<MarketplaceMentorList>;
  marketplaceMentorRegions?: Maybe<Array<Maybe<DomesticRegionField>>>;
  marketplaceMentorUniversities?: Maybe<Array<Maybe<UniversityField>>>;
  marketplaceSnippets?: Maybe<MarketplaceSnippetField>;
  marketplaceUniversitiesSlugs?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketplaceUniversityCountries?: Maybe<Array<Maybe<CountryField>>>;
  marketplaceUnreadMessageCount?: Maybe<Scalars['Int']>;
  marketplaces?: Maybe<Array<Maybe<MarketplaceField>>>;
  marketplacesCount?: Maybe<Scalars['Int']>;
  me?: Maybe<MeField>;
  memberOrganisations?: Maybe<Array<Maybe<OrganisationField>>>;
  mentorApplicantChatGroupCount?: Maybe<Scalars['Int']>;
  mentorApplicantChatGroups?: Maybe<Array<Maybe<MentorApplicantChatGroupField>>>;
  mentorCount?: Maybe<Scalars['Int']>;
  mentorInbox?: Maybe<MentorInboxField>;
  mentorInterests?: Maybe<Array<Maybe<MentorInterestField>>>;
  mentorIntroMessage?: Maybe<MentorIntroMessageField>;
  mentorInvite?: Maybe<MentorInviteField>;
  mentorInviteCount?: Maybe<MentorInviteCountField>;
  mentorList?: Maybe<MentorListField>;
  mentorModules?: Maybe<Array<Maybe<MentorModuleField>>>;
  mentorPosts?: Maybe<Array<Maybe<BlogPostField>>>;
  mentorResponseTime?: Maybe<Array<Maybe<AnalyticsMentorField>>>;
  mentorSocieties?: Maybe<Array<Maybe<MentorSocietyField>>>;
  mentorTimeSpent?: Maybe<Array<Maybe<AnalyticsMentorField>>>;
  messages?: Maybe<ChatGroupAndMessages>;
  myAgenda: PaginatedLiveSessions;
  myBlogTopics?: Maybe<Array<Maybe<BlogTopicField>>>;
  myLiveEventDirectMessaging?: Maybe<LiveEventDirectMessaging>;
  myLiveEventFeeds?: Maybe<Array<Maybe<LiveEventChatGroupField>>>;
  myRegisteredEvents?: Maybe<Array<Maybe<LiveEventDetailsField>>>;
  myUniversityDegrees?: Maybe<Array<Maybe<DegreeField>>>;
  myUniversityLink?: Maybe<ApplicantUniversityLinkField>;
  offsetId?: Maybe<Scalars['String']>;
  openDay?: Maybe<OpenDayField>;
  openDays?: Maybe<Array<Maybe<OpenDayField>>>;
  openDaysWithViewFilter?: Maybe<Array<Maybe<OpenDayField>>>;
  organisation?: Maybe<OrganisationField>;
  organisations?: Maybe<Array<Maybe<OrganisationField>>>;
  outbox?: Maybe<Array<Maybe<OutboxChatGroupField>>>;
  outboxCount?: Maybe<Scalars['Int']>;
  pageMentors?: Maybe<PageMentorField>;
  paginatedApplicantInvites?: Maybe<PaginatedApplicantInvite>;
  pingUserDirectory: Scalars['String'];
  popularSession?: Maybe<SessionAnalytics>;
  post?: Maybe<BlogPostField>;
  prospectChatSummary?: Maybe<ChatSummaryField>;
  prospectChatSummaryV2?: Maybe<ChatSummaryField>;
  prospectStageStats?: Maybe<ProspectStageSummaryField>;
  publicLiveEvents?: Maybe<Array<Maybe<LiveEventField>>>;
  publicMentor?: Maybe<PublicMentorField>;
  publicMentorIntroMessage?: Maybe<MentorIntroMessageField>;
  publicStaffCount?: Maybe<Scalars['Int']>;
  publicUser?: Maybe<PublicUserField>;
  qssQuestions?: Maybe<Array<Maybe<QuestionField>>>;
  queryRecording: QueryRecordingResponse;
  queryUniversityIndexing?: Maybe<UniversityScrapeIndexingOutput>;
  randomBuddy?: Maybe<BuddyField>;
  randomDefaultQuestions?: Maybe<Array<Maybe<DefaultQuestionsField>>>;
  randomMentor?: Maybe<PublicMentorField>;
  randomMentorsList?: Maybe<RandomMentorsListField>;
  requestedPublicGroups: Array<RequestedPublicGroup>;
  researchTime?: Maybe<ResearchTimeResult>;
  researchTimeLog?: Maybe<Array<Maybe<ResearchTimeLogResult>>>;
  searchUniversityKnowledgeBase: Array<SearchResult>;
  sequenceFilterMentorList?: Maybe<SequenceFilterMentorListField>;
  serverTime?: Maybe<Scalars['String']>;
  signupsOverTime?: Maybe<ApplicantSignupStats>;
  singleInstanceOrganisations?: Maybe<Array<Maybe<OrganisationField>>>;
  staffContactByUniversityId?: Maybe<StaffContactOutput>;
  subTopic?: Maybe<SubTopicField>;
  subTopics?: Maybe<Array<Maybe<SubTopicField>>>;
  switchUniversities?: Maybe<SwitchUniversitiesField>;
  tag?: Maybe<TagField>;
  tags?: Maybe<Array<Maybe<TagField>>>;
  timeTaken?: Maybe<TimeTakenField>;
  topCountries?: Maybe<Array<Maybe<TopCountryField>>>;
  topDegrees?: Maybe<Array<Maybe<TopDegreeField>>>;
  topDomesticRegions?: Maybe<Array<Maybe<TopDomesticRegionField>>>;
  topSignupsByCountry?: Maybe<Array<Maybe<CountryStatField>>>;
  topSignupsByDegree?: Maybe<Array<Maybe<DegreeStatField>>>;
  topSignupsByState?: Maybe<Array<Maybe<StateStatField>>>;
  topic?: Maybe<TopicField>;
  topicStatistics?: Maybe<Array<Maybe<InsightsField>>>;
  topics?: Maybe<Array<Maybe<TopicField>>>;
  totalSignups?: Maybe<SignUpsCount>;
  triggerDegreeExport?: Maybe<Scalars['String']>;
  triggerMentorExport?: Maybe<Scalars['String']>;
  triggerProspectExportEmail?: Maybe<Scalars['String']>;
  triggerReportExport?: Maybe<Scalars['String']>;
  triggerTopicStatisticsExport?: Maybe<Scalars['String']>;
  uniApplicantSignUps?: Maybe<GraphField>;
  uniApplicantSignUpsBenchmark?: Maybe<GraphField>;
  uniChatGroups?: Maybe<GraphField>;
  uniChatGroupsBenchmark?: Maybe<GraphField>;
  uniConversionRate?: Maybe<GraphField>;
  uniConversionRateBenchmark?: Maybe<GraphField>;
  uniMessages?: Maybe<ChatGroupAndMessages>;
  uniMessagesPerChatGroup?: Maybe<GraphField>;
  uniResponseTime?: Maybe<GraphField>;
  uniResponseTimeBenchmark?: Maybe<GraphField>;
  uniSentences?: Maybe<GraphField>;
  uniSentencesBenchmark?: Maybe<GraphField>;
  uniSentencesPerChatGroup?: Maybe<GraphField>;
  uniSentencesPerChatGroupBenchmark?: Maybe<GraphField>;
  uniTimeSpent?: Maybe<GraphField>;
  uniTimeSpentBenchmark?: Maybe<GraphField>;
  uniUniqueVisits?: Maybe<GraphField>;
  uniUniqueVisitsBenchmark?: Maybe<GraphField>;
  university?: Maybe<UniversityField>;
  universityAdmins?: Maybe<Array<Maybe<UniversityUserField>>>;
  universityAlerts?: Maybe<Array<Maybe<AlertField>>>;
  universityBlockReports?: Maybe<Array<Maybe<UniversityBlockReportField>>>;
  universityBlockReportsCount?: Maybe<Scalars['Int']>;
  universityBlogPosts?: Maybe<UniversityBlogPostsField>;
  universityBlogPostsCount?: Maybe<Scalars['Int']>;
  universityBlogTopics?: Maybe<Array<Maybe<BlogTopicField>>>;
  universityInbox?: Maybe<UniversityInboxField>;
  universityMentor?: Maybe<MentorField>;
  universityQuestionById: UniversityQuestion;
  universityQuestions: Array<UniversityQuestion>;
  universitySnippets?: Maybe<UniversitySnippetField>;
  universityUsers?: Maybe<Array<Maybe<UniversityUserField>>>;
  unreadCount?: Maybe<UnreadCountField>;
  upcomingExternalEvents?: Maybe<Array<Maybe<ExternalEventField>>>;
  upcomingLiveEvents?: Maybe<Array<Maybe<LiveEventField>>>;
  userChatGroups?: Maybe<Array<Maybe<ChatGroupField>>>;
  userPartialSearch?: Maybe<Array<Maybe<UserCustomAttributesFilterField>>>;
  userPartialSearchMultipleFields?: Maybe<Array<Maybe<UserPartialSearchFields>>>;
  userPermissions?: Maybe<UserPermissionsListField>;
  userTimeSpentBreakdown?: Maybe<UserTimeSpentBreakdown>;
  userUnreadMessageCount?: Maybe<UnreadMessageCountField>;
  verifyCode?: Maybe<VerifyCodeField>;
  virtualEvent?: Maybe<VirtualEvent>;
  virtualEventAttendees: Array<UserField>;
  virtualEventProspectReport?: Maybe<ReportAnalytics>;
  virtualEvents: PaginatedVirtualEvents;
  virtualEventsByUniversityIds: PaginatedVirtualEvents;
};


export type QueryAdminMentorArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAdminRecommendationCountPerDecisionStageArgs = {
  universityId: Scalars['ObjectId'];
};


export type QueryAdminSearchMentorsArgs = {
  propName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type QueryAgendaArgs = {
  eventPagesIds?: Maybe<Array<Scalars['ObjectId']>>;
  pagination: PaginationInput;
  rangeDate?: Maybe<RangeDateInput>;
  virtualEventId: Scalars['ObjectId'];
};


export type QueryAlertArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryAllAdminApplicantsArgs = {
  degreeLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllApplicantConversationsArgs = {
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllApplicantCountArgs = {
  degreeLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllApplicantSignUpsArgs = {
  per?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllApplicantsArgs = {
  includeSmudged?: Maybe<Scalars['Boolean']>;
  itemsPerPage?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  updatedFrom?: Maybe<Scalars['DateTime']>;
  updatedTo?: Maybe<Scalars['DateTime']>;
};


export type QueryAllBuddyInvitesArgs = {
  accepted?: Maybe<Scalars['String']>;
  itemsPerPage?: Maybe<Scalars['LimitedInt']>;
  order?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  roleSelected?: Maybe<Scalars['String']>;
};


export type QueryAllChatGroupCountArgs = {
  per?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllChatGroupsByDayArgs = {
  day?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllContentBriefsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryAllDegreeLevelsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  nameContains?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryAllDegreesArgs = {
  degreeLevel?: Maybe<Scalars['String']>;
  degreeType?: Maybe<Array<Maybe<DegreeType>>>;
  limit?: Maybe<Scalars['Int']>;
  nameContains?: Maybe<Scalars['String']>;
  show?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryAllHighSchoolsArgs = {
  nameContains?: Maybe<Scalars['String']>;
};


export type QueryAllMentorInvitesArgs = {
  accepted?: Maybe<Scalars['String']>;
  itemsPerPage?: Maybe<Scalars['LimitedInt']>;
  order?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
};


export type QueryAllMentorsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlyMentorsWithUnreads?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  uniId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryAllMentorsOfEnterpriseArgs = {
  active?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['LimitedInt']>;
  offset?: Maybe<Scalars['Int']>;
  onlyMentorsWithUnreads?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryAllMentorsTimeSpentArgs = {
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fromTime?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryAllPostsArgs = {
  authorId?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  title?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryAllPostsInfoArgs = {
  authorId?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  randomise?: Maybe<Scalars['Boolean']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  title?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryAllStaffArgs = {
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffRole?: Maybe<Scalars['String']>;
};


export type QueryAllSubCountriesArgs = {
  count?: Maybe<Scalars['Int']>;
  inputRegion?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllSubRegionsArgs = {
  count?: Maybe<Scalars['Int']>;
  inputRegion?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllTopCountriesArgs = {
  count?: Maybe<Scalars['Int']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllTopDegreesArgs = {
  count?: Maybe<Scalars['Int']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllTopRegionsArgs = {
  count?: Maybe<Scalars['Int']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllUniversitiesArgs = {
  nameContains?: Maybe<Scalars['String']>;
};


export type QueryAllUserIdsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlyUsersWithUnreads?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffRole?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllUsersArgs = {
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlyUsersWithUnreads?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffRole?: Maybe<Scalars['String']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryAllYearOfStudyArgs = {
  degreeLevelId?: Maybe<Scalars['String']>;
  degreeLevelName?: Maybe<Scalars['String']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryAnalyticsArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type QueryAppLocalesArgs = {
  appName?: Maybe<Scalars['String']>;
};


export type QueryApplicantArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryApplicantCollectionFieldsArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryApplicantCountArgs = {
  includeSmudged?: Maybe<Scalars['Boolean']>;
};


export type QueryApplicantInboxArgs = {
  allowDelinked?: Maybe<Scalars['Boolean']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryApplicantInvitesArgs = {
  universityId: Scalars['String'];
};


export type QueryApplicantSignUpsArgs = {
  per?: Maybe<Scalars['String']>;
};


export type QueryApplicantsAppliedArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryApplicantsMessagedArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryApplicationInsightsArgs = {
  paginatedApplicantInsightInput: PaginatedApplicantInsightInput;
  pagination: GraphQlOffsetPaginationInput;
};


export type QueryAppliedUsersCountArgs = {
  appliedUsersCountInput: AppliedUsersCountInput;
};


export type QueryAskQuestionArgs = {
  question: Scalars['String'];
  universitySlug: Scalars['String'];
};


export type QueryAvailableDegreeTypesArgs = {
  universitySlug: Scalars['String'];
};


export type QueryBlogTopicsArgs = {
  hideEmpty?: Maybe<Scalars['Boolean']>;
  idIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  showInactive?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryBotUserArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryBuddiesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  customAttributesFilters?: Maybe<Array<Maybe<CustomAttributesFilterInputField>>>;
  idIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  randomizeListSeed?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  staffFields?: Maybe<Scalars['JSONString']>;
  universitySlug?: Maybe<Scalars['String']>;
  useRandomizeStaffFeature?: Maybe<Scalars['Boolean']>;
};


export type QueryBuddiesFilterOptionsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  all?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<Array<Maybe<BuddiesListFilterInput>>>;
  groupType?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryBuddyArgs = {
  id: Scalars['String'];
};


export type QueryBuddyApplicantChatGroupCountArgs = {
  buddyId?: Maybe<Scalars['String']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};


export type QueryBuddyApplicantChatGroupsArgs = {
  buddyId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};


export type QueryBuddyApplicantChatInterventionArgs = {
  buddyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupType?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};


export type QueryBuddyApplicantChatInterventionTotalCountArgs = {
  buddyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupType?: Maybe<Scalars['String']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};


export type QueryBuddyInviteArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryBuddyResponseTimeArgs = {
  groupType?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryCanonicalQuestionsArgs = {
  subTopics?: Maybe<Array<Maybe<Scalars['String']>>>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryChatGroupArgs = {
  allowDelinked?: Maybe<Scalars['Boolean']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  opponentId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryChatGroupAuxArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
};


export type QueryChatGroupByIdArgs = {
  allowDelinked?: Maybe<Scalars['Boolean']>;
  chatGroupId?: Maybe<Scalars['String']>;
};


export type QueryChatGroupCountArgs = {
  per?: Maybe<Scalars['String']>;
};


export type QueryChatGroupLabellingCandidatesArgs = {
  chatGroupId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  topicLimit?: Maybe<Scalars['Int']>;
};


export type QueryChatGroupSuggestionsArgs = {
  chatGroupId: Scalars['String'];
};


export type QueryChatGroupsByDayArgs = {
  day?: Maybe<Scalars['String']>;
};


export type QueryChatInterventionMentorsArgs = {
  limit?: Maybe<Scalars['Int']>;
  mentorIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  onlyMentorsWithUnreads?: Maybe<Scalars['Boolean']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  uniId?: Maybe<Scalars['String']>;
};


export type QueryChatRoomArgs = {
  id: Scalars['ObjectId'];
};


export type QueryChatRoomAnalyticsArgs = {
  chatRoomId: Scalars['ObjectId'];
};


export type QueryChatRoomsArgs = {
  pagination: PaginationInput;
  virtualEventId: Scalars['ObjectId'];
};


export type QueryCommunityUserDirectoryArgs = {
  filterUsersDTO: FilterUsersDto;
  pagination: OffsetPagination;
};


export type QueryCommunityUserSearchArgs = {
  filterUsersDTO: FilterUsersDto;
  pagination: OffsetPagination;
};


export type QueryCompletedEventsArgs = {
  endDate: Scalars['DateTime'];
  filterByName?: Maybe<Scalars['String']>;
  filterByType?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryConversationContextArgs = {
  endDate: Scalars['String'];
  groupBy?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  subGroupById?: Maybe<Scalars['String']>;
  subTopic: Scalars['String'];
  topic: Scalars['String'];
};


export type QueryConversationStatsArgs = {
  endDate: Scalars['DateTime'];
  groupBy?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCountryArgs = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryCrmAttributesArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCrmIntegrationArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCrmServiceUniversityConfigArgs = {
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryCrmSyncJobArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCurrentUserDecisionStageArgs = {
  currentUserDecisionStageQueryInput: CurrentUserDecisionStageQueryInput;
};


export type QueryDegreeCategoriesArgs = {
  categoryType?: Maybe<Scalars['String']>;
  hierarchyRank?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryDegreeCategoriesCountArgs = {
  categoryType?: Maybe<Scalars['String']>;
  hierarchyRank?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryDegreeCategoryArgs = {
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


export type QueryDomesticRegionsArgs = {
  countryCode?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['String']>;
};


export type QueryEnrolledInsightsByCommunityArgs = {
  enrolledInsightsInput: EnrolledInsightsInput;
};


export type QueryEnterpriseDegreesArgs = {
  nameContains?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
};


export type QueryEnterpriseMentorCountArgs = {
  active?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryEventAnalyticsArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type QueryEventPageArgs = {
  id: Scalars['ObjectId'];
};


export type QueryEventPagesArgs = {
  pagination: PaginationInput;
  virtualEventId: Scalars['ObjectId'];
};


export type QueryExportTimesheetReportArgs = {
  active?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fromTime?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryExternalContentArgs = {
  id: Scalars['ObjectId'];
};


export type QueryExternalContentsArgs = {
  eventPageId?: Maybe<Scalars['ObjectId']>;
  pagination: PaginationInput;
  showOnHomepage?: Maybe<Scalars['Boolean']>;
  virtualEventId: Scalars['ObjectId'];
};


export type QueryExternalEventArgs = {
  externalEventId?: Maybe<Scalars['String']>;
};


export type QueryExternalEventsArgs = {
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryFilterUsersEnterpriseArgs = {
  accountRole?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type QueryGetAssistantConversationArgs = {
  getAssistantConversationInput: GetAssistantConversationInput;
};


export type QueryGetChatConversationArgs = {
  id: Scalars['String'];
};


export type QueryGetChatConversationFromLegacyIdArgs = {
  id: Scalars['String'];
};


export type QueryGetChatConversationInboxArgs = {
  ChatConversationInboxInputDto: ChatConversationInboxInputDto;
};


export type QueryGetChatConversationMessagesArgs = {
  getMessageDto: GetMessageDto;
};


export type QueryGetChatGroupsForCommunityArgs = {
  communityId: Scalars['String'];
};


export type QueryGetChatReportsArgs = {
  getChatReportsDTO: GetChatReportsDto;
};


export type QueryGetCommunityChatMembersArgs = {
  conversationId: Scalars['String'];
  offsetPagination: OffsetPagination;
};


export type QueryGetCommunityConversationArgs = {
  id: Scalars['String'];
};


export type QueryGetCommunityConversationsArgs = {
  communityId: Scalars['String'];
  retrieveArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCommunityForUniOrAdminArgs = {
  communityId: Scalars['String'];
  retrieveArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCommunityForUserArgs = {
  communityId: Scalars['String'];
};


export type QueryGetCommunityMembersArgs = {
  communityId: Scalars['String'];
};


export type QueryGetConversationPermissionArgs = {
  id: Scalars['String'];
};


export type QueryGetIdpTokenArgs = {
  channel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  idpToken?: Maybe<Scalars['String']>;
};


export type QueryGetInviteFromIdArgs = {
  inviteId: Scalars['String'];
};


export type QueryGetInviteInformationArgs = {
  InviteInformationInput: InviteInformationInput;
};


export type QueryGetInviteLinkInfoArgs = {
  inviteLinkId: Scalars['String'];
};


export type QueryGetInvitesForCommunityArgs = {
  communityId: Scalars['String'];
  filters: InviteFilter;
  retrieveArchived?: Maybe<Scalars['Boolean']>;
};


export type QueryGetInvitesForEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetLiveEventsByIdArgs = {
  liveEventIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryGetMessagesForChatConversationArgs = {
  getMessageDto: GetMessageDto;
};


export type QueryGetOrCreateBotChatGroupArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryGetOrCreateChatGroupArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
  opponentId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryGetOrCreateCommunitiesForInstitutionArgs = {
  universityId: Scalars['String'];
};


export type QueryGetOrCreateUniversityChatGroupArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryGetRandomAmbassadorsArgs = {
  getRandomAmbassadorsInput: GetRandomAmbassadorsInput;
};


export type QueryGetRecommendedConversationsArgs = {
  communityId: Scalars['String'];
};


export type QueryGetRecommendedUsersArgs = {
  communityId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  matchingCriterion?: Maybe<MatchingCriteriaEnum>;
};


export type QueryGetRecordingArgs = {
  path: Scalars['String'];
};


export type QueryGetRecordingFailuresByChannelIdArgs = {
  data: GetRecordingFailureIdsArgs;
};


export type QueryGetRecordingFailuresByEventIdArgs = {
  data: GetRecordingFailureIdsArgs;
};


export type QueryGetRecordingListArgs = {
  channelId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  universityId: Scalars['String'];
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryGetRecordingUploadingCountArgs = {
  channelId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  universityId: Scalars['String'];
};


export type QueryGetTokenArgs = {
  email?: Maybe<Scalars['String']>;
  notificationToken?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  rootUrl?: Maybe<Scalars['String']>;
};


export type QueryGetTokenForEnterpriseViewArgs = {
  organisationId?: Maybe<Scalars['String']>;
};


export type QueryGetUniversitiesByFeatureFlagArgs = {
  featureFlag: Scalars['String'];
};


export type QueryGetUniversityAssistantConfigArgs = {
  universityId: Scalars['String'];
};


export type QueryGetUserArgs = {
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffFields?: Maybe<Scalars['JSONString']>;
  university?: Maybe<Scalars['String']>;
};


export type QueryGetUserByEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryGetUserByIdsArgs = {
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  idIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffFields?: Maybe<Scalars['JSONString']>;
  university?: Maybe<Scalars['String']>;
};


export type QueryGetUserReportsArgs = {
  getUserReportsDTO: GetUserReportsDto;
};


export type QueryGetUsersCommunityConversationsArgs = {
  communityId: Scalars['String'];
  offsetPagination?: Maybe<OffsetPagination>;
};


export type QueryGetUsersCommunityPrivateConversationsArgs = {
  communityId: Scalars['String'];
};


export type QueryGlobalAlertsArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type QueryHasContentArgs = {
  universityContentInput: UniversityContentInput;
};


export type QueryHelloCommunityServiceArgs = {
  throwError?: Maybe<Scalars['Boolean']>;
};


export type QueryHobsonsConnectIntegrationArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryInboxPromptQuestionByQuestionIdArgs = {
  questionId: Scalars['ObjectId'];
};


export type QueryInboxPromptQuestionsArgs = {
  inboxPromptInput: InboxPromptInput;
};


export type QueryInterventionMessageListArgs = {
  mentorId?: Maybe<Scalars['String']>;
};


export type QueryIsUserInDirectMessagesArgs = {
  liveEventSlug: Scalars['String'];
  universityId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryLatestExportedReportArgs = {
  liveEventId?: Maybe<Scalars['String']>;
  openDayId?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryListAdminRecommendationsArgs = {
  listAdminRecommendationInput: ListAdminRecommendationInput;
  pagination: GraphQlOffsetPaginationInput;
};


export type QueryListArchivedCommunitiesForAnInstitutionArgs = {
  institutionId: Scalars['String'];
};


export type QueryListBuddyApplicantConversationsArgs = {
  activeBuddies?: Maybe<Scalars['Boolean']>;
  buddyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['LimitedInt']>;
  offset?: Maybe<Scalars['Int']>;
  resolved?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryListCommunitiesForAnInstitutionArgs = {
  institutionId: Scalars['String'];
};


export type QueryListMentorApplicantConversationsArgs = {
  activeMentors?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['LimitedInt']>;
  mentorIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  resolved?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryListUniversityKnowledgeBaseSourcesArgs = {
  knowledgeBaseSourceQueryInput: KnowledgeBaseSourceQueryInput;
};


export type QueryListUniversityKnowledgeBaseSourcesByUrlPatternArgs = {
  knowledgeBaseSourcesListInput: KnowledgeBaseSourcesListInput;
};


export type QueryLiveEventArgs = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryLiveEventLandingPageArgs = {
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryLiveEventsArgs = {
  viewFilter?: Maybe<Scalars['String']>;
};


export type QueryLiveFeedArgs = {
  liveFeedId?: Maybe<Scalars['String']>;
};


export type QueryLiveSessionArgs = {
  id: Scalars['ObjectId'];
};


export type QueryLiveSessionsArgs = {
  endAfter?: Maybe<Scalars['DateTime']>;
  endBefore?: Maybe<Scalars['DateTime']>;
  eventPageId?: Maybe<Scalars['ObjectId']>;
  orderBy?: Maybe<OrderByLiveSessionsInput>;
  pagination: PaginationInput;
  recordingEnabled?: Maybe<Scalars['Boolean']>;
  showOnHomepage?: Maybe<Scalars['Boolean']>;
  startAfter?: Maybe<Scalars['DateTime']>;
  startBefore?: Maybe<Scalars['DateTime']>;
  virtualEventId: Scalars['ObjectId'];
};


export type QueryMarketplaceArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceApplicantChatGroupsCountArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceApplicantCountriesArgs = {
  createdAfter?: Maybe<Scalars['IsoDateTime']>;
  createdBefore?: Maybe<Scalars['IsoDateTime']>;
  degreeCategoryFilter?: Maybe<Scalars['String']>;
  marketplaceSlug: Scalars['String'];
};


export type QueryMarketplaceApplicantDegreeCategoriesArgs = {
  countryFilter?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['IsoDateTime']>;
  createdBefore?: Maybe<Scalars['IsoDateTime']>;
  marketplaceSlug: Scalars['String'];
};


export type QueryMarketplaceApplicantsCountArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceInboxArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceMentorCountriesArgs = {
  degreeCategoryId?: Maybe<Scalars['String']>;
  degreeCategoryType?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  domesticRegion?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  universityCountry?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceMentorDegreeCategoriesArgs = {
  countryId?: Maybe<Scalars['String']>;
  degreeCategoryRank?: Maybe<Scalars['Int']>;
  degreeCategoryType?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  domesticRegion?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  parentDegreeCategory?: Maybe<Scalars['String']>;
  universityCountry?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceMentorListArgs = {
  countryId?: Maybe<Scalars['String']>;
  degreeCategoryId?: Maybe<Scalars['String']>;
  degreeCategoryType?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  domesticRegion?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  mentorSeed?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  skip?: Maybe<Scalars['Int']>;
  universityCountry?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  universitySeed?: Maybe<Scalars['Int']>;
  universitySlug?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceMentorRegionsArgs = {
  countryId?: Maybe<Scalars['String']>;
  degreeCategoryId?: Maybe<Scalars['String']>;
  degreeCategoryType?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  universityCountry?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceMentorUniversitiesArgs = {
  countryId?: Maybe<Scalars['String']>;
  degreeCategoryId?: Maybe<Scalars['String']>;
  degreeCategoryType?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  domesticRegion?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  universityCountry?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceSnippetsArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceUniversitiesSlugsArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceUniversityCountriesArgs = {
  countryId?: Maybe<Scalars['String']>;
  degreeCategoryId?: Maybe<Scalars['String']>;
  degreeCategoryType?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['String']>;
  marketplaceSlug?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceUnreadMessageCountArgs = {
  marketplaceSlug?: Maybe<Scalars['String']>;
};


export type QueryMarketplacesArgs = {
  limit?: Maybe<Scalars['LimitedInt']>;
  name?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketplacesCountArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryMentorApplicantChatGroupCountArgs = {
  mentorId?: Maybe<Scalars['String']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};


export type QueryMentorApplicantChatGroupsArgs = {
  limit?: Maybe<Scalars['Int']>;
  mentorId?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  unanswered?: Maybe<Scalars['Boolean']>;
};


export type QueryMentorCountArgs = {
  active?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  onlyMentorsWithUnreads?: Maybe<Scalars['Boolean']>;
  uniId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryMentorInboxArgs = {
  itemsPerPage?: Maybe<Scalars['Int']>;
  pageNum?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
};


export type QueryMentorInterestsArgs = {
  nameContains?: Maybe<Scalars['String']>;
};


export type QueryMentorIntroMessageArgs = {
  mentorId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryMentorInviteArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryMentorInviteCountArgs = {
  accepted?: Maybe<Scalars['String']>;
};


export type QueryMentorListArgs = {
  countryId?: Maybe<Scalars['String']>;
  degreeId?: Maybe<Scalars['String']>;
  filterIncompleteProfiles?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  mentorId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryMentorModulesArgs = {
  nameContains?: Maybe<Scalars['String']>;
};


export type QueryMentorPostsArgs = {
  mentorId?: Maybe<Scalars['String']>;
};


export type QueryMentorResponseTimeArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryMentorSocietiesArgs = {
  nameContains?: Maybe<Scalars['String']>;
};


export type QueryMentorTimeSpentArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryMessagesArgs = {
  allowDelinked?: Maybe<Scalars['Boolean']>;
  chatGroupId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offsetId?: Maybe<Scalars['String']>;
};


export type QueryMyAgendaArgs = {
  eventPagesIds?: Maybe<Array<Scalars['ObjectId']>>;
  filterBySavedSessions?: Maybe<Scalars['Boolean']>;
  pagination: PaginationInput;
  rangeDate?: Maybe<RangeDateInput>;
  virtualEventId: Scalars['ObjectId'];
};


export type QueryMyLiveEventDirectMessagingArgs = {
  liveEventId?: Maybe<Scalars['String']>;
  liveEventSlug?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryMyLiveEventFeedsArgs = {
  liveEventId?: Maybe<Scalars['String']>;
  liveEventSlug?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryMyUniversityDegreesArgs = {
  limit?: Maybe<Scalars['Int']>;
  nameContains?: Maybe<Scalars['String']>;
};


export type QueryMyUniversityLinkArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryOpenDayArgs = {
  openDayId?: Maybe<Scalars['String']>;
};


export type QueryOpenDaysArgs = {
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryOpenDaysWithViewFilterArgs = {
  viewFilter: Scalars['String'];
};


export type QueryOrganisationArgs = {
  organisationId?: Maybe<Scalars['String']>;
};


export type QueryOrganisationsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  organisationType?: Maybe<Scalars['String']>;
};


export type QueryOutboxArgs = {
  itemsPerPage?: Maybe<Scalars['Int']>;
  pageNum?: Maybe<Scalars['Int']>;
};


export type QueryPageMentorsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  onlyMentorsWithUnreads?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  uniId?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryPaginatedApplicantInvitesArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  universityId: Scalars['String'];
};


export type QueryPopularSessionArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type QueryPostArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryProspectChatSummaryArgs = {
  prospectId?: Maybe<Scalars['String']>;
};


export type QueryProspectChatSummaryV2Args = {
  prospectId: Scalars['String'];
};


export type QueryProspectStageStatsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryPublicLiveEventsArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryPublicMentorArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryPublicMentorIntroMessageArgs = {
  mentorId?: Maybe<Scalars['String']>;
};


export type QueryPublicStaffCountArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryPublicUserArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryQueryRecordingArgs = {
  data: QueryRecordingArgs;
};


export type QueryQueryUniversityIndexingArgs = {
  UniversityScrapeIndexingQueryInput: UniversityScrapeIndexingQueryInput;
};


export type QueryRandomBuddyArgs = {
  groupType?: Maybe<Scalars['String']>;
  staffFields?: Maybe<Scalars['JSONString']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryRandomDefaultQuestionsArgs = {
  limit: Scalars['LimitedInt'];
  mentor?: Maybe<Scalars['String']>;
};


export type QueryRandomMentorArgs = {
  degreeId?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  sameCountryAsUniversity?: Maybe<Scalars['Boolean']>;
  universitySlug: Scalars['String'];
};


export type QueryRandomMentorsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  universitySlug: Scalars['String'];
};


export type QueryRequestedPublicGroupsArgs = {
  communityId: Scalars['String'];
};


export type QueryResearchTimeArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryResearchTimeLogArgs = {
  fromTime?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['String']>;
};


export type QuerySearchUniversityKnowledgeBaseArgs = {
  limit: Scalars['Float'];
  searchTerm: Scalars['String'];
  universityId: Scalars['String'];
};


export type QuerySequenceFilterMentorListArgs = {
  ambassadorSearch?: Maybe<Scalars['String']>;
  applyDegreeFilter?: Maybe<Scalars['Boolean']>;
  degreeTypesEnabled?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  mentorSeed?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  sequenceFilter?: Maybe<Array<Maybe<MentorFilterInputField>>>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QuerySignupsOverTimeArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QuerySingleInstanceOrganisationsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  namePrefix?: Maybe<Scalars['String']>;
};


export type QueryStaffContactByUniversityIdArgs = {
  staffContactByUniversityIdInput: StaffContactByUniversityIdInput;
};


export type QuerySubTopicsArgs = {
  topic?: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['String'];
};


export type QueryTagsArgs = {
  idIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
};


export type QueryTimeTakenArgs = {
  fromDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
};


export type QueryTopCountriesArgs = {
  count?: Maybe<Scalars['Int']>;
};


export type QueryTopDegreesArgs = {
  count?: Maybe<Scalars['Int']>;
};


export type QueryTopDomesticRegionsArgs = {
  count?: Maybe<Scalars['Int']>;
};


export type QueryTopSignupsByCountryArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  topX?: Maybe<Scalars['Int']>;
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTopSignupsByDegreeArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  topX?: Maybe<Scalars['Int']>;
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTopSignupsByStateArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  topX?: Maybe<Scalars['Int']>;
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTopicArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryTopicStatisticsArgs = {
  dateLimit?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  groupBy?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryTotalSignupsArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  universityIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTriggerDegreeExportArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryTriggerMentorExportArgs = {
  active?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  onlyMentorsWithUnreads?: Maybe<Scalars['Boolean']>;
  reportType?: Maybe<Scalars['String']>;
  staffRole?: Maybe<Scalars['String']>;
  timeSpentFromDate?: Maybe<Scalars['String']>;
  timeSpentToDate?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};


export type QueryTriggerProspectExportEmailArgs = {
  includeSmudged?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['String']>;
  updatedFrom?: Maybe<Scalars['DateTime']>;
  updatedTo?: Maybe<Scalars['DateTime']>;
};


export type QueryTriggerReportExportArgs = {
  liveEventId?: Maybe<Scalars['String']>;
  openDayId?: Maybe<Scalars['String']>;
};


export type QueryTriggerTopicStatisticsExportArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryUniApplicantSignUpsArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniApplicantSignUpsBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniChatGroupsArgs = {
  groupType?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniChatGroupsBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniConversionRateArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniConversionRateBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniMessagesArgs = {
  chatGroupId?: Maybe<Scalars['String']>;
  groupType?: Maybe<Scalars['String']>;
};


export type QueryUniMessagesPerChatGroupArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniResponseTimeArgs = {
  groupType?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniResponseTimeBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniSentencesArgs = {
  groupType?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniSentencesBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniSentencesPerChatGroupArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniSentencesPerChatGroupBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniTimeSpentArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniTimeSpentBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniUniqueVisitsArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniUniqueVisitsBenchmarkArgs = {
  interval?: Maybe<Scalars['String']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniversityArgs = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryUniversityAlertsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  universityId?: Maybe<Scalars['String']>;
};


export type QueryUniversityBlockReportsArgs = {
  itemsPerPage?: Maybe<Scalars['LimitedInt']>;
  order?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
};


export type QueryUniversityBlogPostsArgs = {
  itemsPerPage?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  pageNum?: Maybe<Scalars['Int']>;
  safeLimit?: Maybe<Scalars['LimitedInt']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryUniversityBlogPostsCountArgs = {
  status?: Maybe<BlogStatus>;
};


export type QueryUniversityInboxArgs = {
  limit?: Maybe<Scalars['LimitedInt']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryUniversityMentorArgs = {
  id: Scalars['String'];
};


export type QueryUniversityQuestionByIdArgs = {
  universityQuestionByIdInput: UniversityQuestionByIdInput;
};


export type QueryUniversityQuestionsArgs = {
  universityQuestionInput: UniversityQuestionInput;
};


export type QueryUniversitySnippetsArgs = {
  popCardAlign?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryUniversityUsersArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryUpcomingExternalEventsArgs = {
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryUpcomingLiveEventsArgs = {
  universitySlug?: Maybe<Scalars['String']>;
};


export type QueryUserChatGroupsArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QueryUserPartialSearchArgs = {
  accountRole?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


export type QueryUserPartialSearchMultipleFieldsArgs = {
  accountRole?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  value?: Maybe<Scalars['String']>;
};


export type QueryUserTimeSpentBreakdownArgs = {
  fromTime?: Maybe<Scalars['String']>;
  toTime?: Maybe<Scalars['String']>;
};


export type QueryVerifyCodeArgs = {
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type QueryVirtualEventArgs = {
  id: Scalars['ObjectId'];
};


export type QueryVirtualEventAttendeesArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type QueryVirtualEventProspectReportArgs = {
  virtualEventId: Scalars['ObjectId'];
};


export type QueryVirtualEventsArgs = {
  endAfter?: Maybe<Scalars['DateTime']>;
  endBefore?: Maybe<Scalars['DateTime']>;
  pagination: PaginationInput;
  startAfter?: Maybe<Scalars['DateTime']>;
  startBefore?: Maybe<Scalars['DateTime']>;
  universityId: Scalars['ObjectId'];
};


export type QueryVirtualEventsByUniversityIdsArgs = {
  endAfter?: Maybe<Scalars['DateTime']>;
  endBefore?: Maybe<Scalars['DateTime']>;
  pagination: PaginationInput;
  startAfter?: Maybe<Scalars['DateTime']>;
  startBefore?: Maybe<Scalars['DateTime']>;
  universityIds: Array<Scalars['ObjectId']>;
};

export type QueryRecordingArgs = {
  channelId: Scalars['String'];
};

export type QueryRecordingResponse = {
  __typename?: 'QueryRecordingResponse';
  recording?: Maybe<RecordingModel>;
};

export type QuestionField = {
  __typename?: 'QuestionField';
  questionId?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
};

export type RandomMentorsListField = {
  __typename?: 'RandomMentorsListField';
  mentors?: Maybe<Array<Maybe<PublicMentorField>>>;
};

export type RangeDateInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type Reaction = {
  __typename?: 'Reaction';
  reaction: Scalars['String'];
  reactionUnicode: Scalars['String'];
  userIds: Array<Scalars['String']>;
};

export type ReactionDto = {
  conversationId: Scalars['ObjectId'];
  messageId: Scalars['ObjectId'];
  reaction: Scalars['String'];
  reactionUnicode: Scalars['String'];
};

export type ReadChatMutation = {
  __typename?: 'ReadChatMutation';
  chatGroup?: Maybe<ChatGroupField>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  batchId: Scalars['ObjectId'];
  contentId: Scalars['ObjectId'];
  description: Scalars['String'];
  id: Scalars['ObjectId'];
  source: RecommendationSource;
  title: Scalars['String'];
  type: RecommendationResourceTypeEnum;
  url: Scalars['String'];
};

export type RecommendationResourceType = {
  __typename?: 'RecommendationResourceType';
  displayName: Scalars['String'];
  name: RecommendationResourceTypeEnum;
};

export enum RecommendationResourceTypeEnum {
  Ambassador = 'AMBASSADOR',
  Article = 'ARTICLE',
  Blog = 'BLOG',
  Community = 'COMMUNITY',
  Event = 'EVENT',
  InPersonEvent = 'IN_PERSON_EVENT',
  Newsletter = 'NEWSLETTER',
  Other = 'OTHER',
  UniversityWebpage = 'UNIVERSITY_WEBPAGE',
  Video = 'VIDEO'
}

export enum RecommendationSource {
  Admin = 'ADMIN',
  ApiRandom = 'API_RANDOM'
}

export type RecordUserActivityMutation = {
  __typename?: 'RecordUserActivityMutation';
  newActivity?: Maybe<ActivityField>;
};

export type RecordingConsent = {
  __typename?: 'RecordingConsent';
  channelId: Scalars['ObjectId'];
  id: Scalars['ObjectId'];
  time: Scalars['DateTime'];
  universityId: Scalars['ObjectId'];
};

export type RecordingDownloadResponse = {
  __typename?: 'RecordingDownloadResponse';
  url: Scalars['String'];
};

export type RecordingFailureArgs = {
  channelId: Scalars['ObjectId'];
  eventId: Scalars['ObjectId'];
};

export type RecordingFailureId = {
  __typename?: 'RecordingFailureId';
  id: Scalars['ObjectId'];
};

export type RecordingFailureResponse = {
  __typename?: 'RecordingFailureResponse';
  success: Scalars['Boolean'];
};

export type RecordingListResponse = {
  __typename?: 'RecordingListResponse';
  files: Array<VideoFile>;
};

export type RecordingModel = {
  __typename?: 'RecordingModel';
  channelId: Scalars['String'];
  deleted: Scalars['Boolean'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ObjectId'];
  layoutMode: Scalars['Float'];
  layoutStatus: Scalars['String'];
  recordingStatus: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  sid?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type RecordingResponse = {
  __typename?: 'RecordingResponse';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RecordingUploadingResponse = {
  __typename?: 'RecordingUploadingResponse';
  count: Scalars['Float'];
};

export type RegisterToLiveEvent = {
  __typename?: 'RegisterToLiveEvent';
  success?: Maybe<Scalars['Boolean']>;
};

export type RejectRequestedPublicGroupInput = {
  id: Scalars['String'];
};

export type RemoveApplicantInsightInput = {
  /** Date in UTC timezone */
  entryDate: Scalars['DateTime'];
  universityId: Scalars['ObjectId'];
};

export type RemoveBuddyInvitesMutation = {
  __typename?: 'RemoveBuddyInvitesMutation';
  successfulInvites?: Maybe<Array<Maybe<BuddyInviteListField>>>;
};

export type RemoveDeviceMutation = {
  __typename?: 'RemoveDeviceMutation';
  result?: Maybe<Scalars['String']>;
};

export type RemoveMembersFromCommunityInputDto = {
  communityId: Scalars['String'];
  emails?: Maybe<Array<Scalars['String']>>;
  userIds: Array<Scalars['String']>;
};

export type RemoveMentorInvitesMutation = {
  __typename?: 'RemoveMentorInvitesMutation';
  mentorInvites?: Maybe<Array<Maybe<MentorInviteField>>>;
};

export type ReplyInputField = {
  messageCreated?: Maybe<Scalars['DateTime']>;
  messageId?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
  senderAccountRole?: Maybe<Scalars['String']>;
  senderFirstName?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
};

export type ReplyMessage = {
  __typename?: 'ReplyMessage';
  id: Scalars['ObjectId'];
};

export type ReplyMessageDto = {
  id: Scalars['ObjectId'];
};

export type ReplyOutputField = {
  __typename?: 'ReplyOutputField';
  isSenderBlocked?: Maybe<Scalars['Boolean']>;
  messageCreated?: Maybe<Scalars['DateTime']>;
  messageId?: Maybe<Scalars['String']>;
  messageText?: Maybe<Scalars['String']>;
  originalMessageText?: Maybe<Scalars['String']>;
  senderAccountRole?: Maybe<Scalars['String']>;
  senderFirstName?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
};

export type ReportAnalytics = {
  __typename?: 'ReportAnalytics';
  id: Scalars['ObjectId'];
  reportLink?: Maybe<Scalars['String']>;
  reportStatus: Scalars['String'];
  virtualEventId: Scalars['ObjectId'];
};

export type RequestAnalyticsExportMutation = {
  __typename?: 'RequestAnalyticsExportMutation';
  error?: Maybe<Scalars['String']>;
};

export type RequestPasswordResetMutation = {
  __typename?: 'RequestPasswordResetMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestPasswordSetMutation = {
  __typename?: 'RequestPasswordSetMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestedPublicGroup = {
  __typename?: 'RequestedPublicGroup';
  community: Community;
  conversation: ChatConversation;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  requestedBy: UserField;
  resolvedBy: UserField;
  status: PublicGroupStatus;
  title: Scalars['String'];
};

export type ResearchTimeInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  logDate?: Maybe<Scalars['String']>;
  timeSpentSeconds?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
};

export type ResearchTimeLogResult = {
  __typename?: 'ResearchTimeLogResult';
  id?: Maybe<Scalars['Int']>;
  timeSpentSeconds?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type ResearchTimeResult = {
  __typename?: 'ResearchTimeResult';
  description?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['String']>;
  timeSpentSeconds?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type ResetPasswordMobileMutation = {
  __typename?: 'ResetPasswordMobileMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordMutation = {
  __typename?: 'ResetPasswordMutation';
  redirectUrl?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ResetPasswordMutationV2 = {
  __typename?: 'ResetPasswordMutationV2';
  error?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ResolveMessageDto = {
  conversationId: Scalars['ObjectId'];
  messageId: Scalars['ObjectId'];
};

export type ResolveUniversityChatsMutation = {
  __typename?: 'ResolveUniversityChatsMutation';
  count?: Maybe<Scalars['Int']>;
  university?: Maybe<UniversityField>;
};

export type RichContentDto = {
  attachments?: Maybe<Array<AttachmentDto>>;
  giphy?: Maybe<GifDto>;
  images?: Maybe<Array<ImageDto>>;
  poll?: Maybe<PollDto>;
  reply?: Maybe<ReplyMessageDto>;
};

export type RichContentInputField = {
  reply?: Maybe<ReplyInputField>;
};

export type RichContentOutputField = {
  __typename?: 'RichContentOutputField';
  reply?: Maybe<ReplyOutputField>;
};

export type RichMessageContent = {
  __typename?: 'RichMessageContent';
  attachments?: Maybe<Array<Attachment>>;
  giphy?: Maybe<Giphy>;
  images?: Maybe<Array<Image>>;
  poll?: Maybe<Poll>;
  reply?: Maybe<ChatMessage>;
};

export enum RootUrlEnum {
  AbraUrl = 'ABRA_URL',
  AmbassadorDashboardUrl = 'AMBASSADOR_DASHBOARD_URL',
  CommunityUrl = 'COMMUNITY_URL',
  HorseaUrl = 'HORSEA_URL',
  OnixUrl = 'ONIX_URL',
  RapidashUrl = 'RAPIDASH_URL'
}

export type RunManageCommandMutation = {
  __typename?: 'RunManageCommandMutation';
  output?: Maybe<OutputField>;
};

export type SatisfactionSurveyDto = {
  communityId: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  rating: Scalars['Float'];
  universitySlug: Scalars['String'];
};

export type SatisfactionSurveyEntity = {
  __typename?: 'SatisfactionSurveyEntity';
  communityId: Scalars['String'];
  universitySlug: Scalars['String'];
  userId: Scalars['String'];
};

export type SaveAppliedUsersInput = {
  /** Date in UTC timezone */
  entryDate: Scalars['DateTime'];
  universityId: Scalars['ObjectId'];
  userIds: Array<Scalars['ObjectId']>;
};

export type SaveAppliedUsersModel = {
  __typename?: 'SaveAppliedUsersModel';
  saved: Scalars['Boolean'];
};

export type SaveAssistantConversationEmailLeadInput = {
  conversationId: Scalars['String'];
  email: Scalars['String'];
  marketingConsent?: Maybe<Scalars['Boolean']>;
  ubConsent: Scalars['Boolean'];
  universityId: Scalars['String'];
};

export type SaveAssistantConversationFeedbackInput = {
  /** The conversation to rate */
  conversationId: Scalars['String'];
  /** Rating from 1-5 on the quality of the conversation */
  rating: Scalars['Float'];
  /** Optional free text for the conversation feedback */
  text?: Maybe<Scalars['String']>;
  universityId: Scalars['String'];
};

export type SaveUniversityQuestionAnswerInput = {
  answers: Array<UniversityQuestionAnswerInputItem>;
  context: UniversityQuestionContextInput;
  questionId: Scalars['ObjectId'];
  universityId: Scalars['ObjectId'];
  universityName?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};

export type SaveVisitedRecommendationInput = {
  batchId: Scalars['ObjectId'];
  contentId: Scalars['ObjectId'];
};

export enum ScraperJobStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Paused = 'PAUSED',
  Started = 'STARTED',
  Stopped = 'STOPPED'
}

export enum ScraperTaskStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Queued = 'QUEUED',
  Skipped = 'SKIPPED',
  Started = 'STARTED'
}

export type SearchResult = {
  __typename?: 'SearchResult';
  content: Scalars['String'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type SendAssistantMessageInput = {
  conversationId: Scalars['String'];
  input: Scalars['String'];
  universityId: Scalars['String'];
};

export type SendCommunityVerificationCode = {
  __typename?: 'SendCommunityVerificationCode';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendFeedMessageMutation = {
  __typename?: 'SendFeedMessageMutation';
  message?: Maybe<MessageField>;
};

export type SendLiveEventMessageMutation = {
  __typename?: 'SendLiveEventMessageMutation';
  botMessages?: Maybe<Array<Maybe<MessageField>>>;
  chatGroup?: Maybe<ChatGroupField>;
  message?: Maybe<MessageField>;
};

export type SendMessageMutation = {
  __typename?: 'SendMessageMutation';
  botMessages?: Maybe<Array<Maybe<MessageField>>>;
  chatGroup?: Maybe<ChatGroupField>;
  message?: Maybe<MessageField>;
};

export type SendOpenDayProspectReport = {
  __typename?: 'SendOpenDayProspectReport';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendResetPasswordEmail = {
  __typename?: 'SendResetPasswordEmail';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendVerificationCode = {
  __typename?: 'SendVerificationCode';
  success?: Maybe<Scalars['Boolean']>;
};

export enum SenderRole {
  Assistant = 'ASSISTANT',
  Function = 'FUNCTION',
  System = 'SYSTEM',
  Tool = 'TOOL',
  User = 'USER'
}

export type SequenceFilterMentorListField = {
  __typename?: 'SequenceFilterMentorListField';
  countries?: Maybe<Array<Maybe<CountryField>>>;
  degrees?: Maybe<Array<Maybe<DegreeField>>>;
  filters?: Maybe<Array<Maybe<MentorFilterField>>>;
  levels?: Maybe<Array<Maybe<Scalars['String']>>>;
  mentorSeed?: Maybe<Scalars['Int']>;
  mentors?: Maybe<Array<Maybe<PublicMentorField>>>;
  validCombination?: Maybe<Scalars['Boolean']>;
};

export type SessionAnalytics = {
  __typename?: 'SessionAnalytics';
  id: Scalars['ObjectId'];
  liveSessionId: Scalars['ObjectId'];
  session?: Maybe<LiveSession>;
  sessionAttendees: SessionAttendees;
  sessionMessagesSent: Scalars['Float'];
  virtualEventId: Scalars['ObjectId'];
};

export type SessionAttendeeAnalytics = {
  __typename?: 'SessionAttendeeAnalytics';
  accountRole: Scalars['String'];
  id: Scalars['ObjectId'];
  liveSessionId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type SessionAttendeeData = {
  accountRole: Scalars['String'];
  liveSessionId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type SessionAttendees = {
  __typename?: 'SessionAttendees';
  activeApplicants: Scalars['Float'];
  applicants: Scalars['Float'];
};

export type SessionData = {
  description: Scalars['String'];
  end: Scalars['DateTime'];
  eventPagesIds?: Maybe<Array<Scalars['ObjectId']>>;
  /** the name of the event */
  name: Scalars['String'];
  presenterIds?: Maybe<Array<Scalars['String']>>;
  recordingEnabled?: Maybe<Scalars['Boolean']>;
  savedByUserIds?: Maybe<Array<Scalars['ObjectId']>>;
  sessionImageUrl: Scalars['String'];
  showOnHomepage?: Maybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
};

export type SetActiveBlogTopicsMutation = {
  __typename?: 'SetActiveBlogTopicsMutation';
  blogTopics?: Maybe<Array<Maybe<BlogTopicField>>>;
};

export type SetAllNotificationsReadMutation = {
  __typename?: 'SetAllNotificationsReadMutation';
  notifications?: Maybe<Array<Maybe<NotificationField>>>;
};

export type SetArchivedContentBriefMutation = {
  __typename?: 'SetArchivedContentBriefMutation';
  contentBrief?: Maybe<ContentBriefField>;
  error?: Maybe<Scalars['String']>;
};

export type SetArchivedDegreeLevelMutation = {
  __typename?: 'SetArchivedDegreeLevelMutation';
  degreeLevel?: Maybe<DegreeLevelField>;
  error?: Maybe<Scalars['String']>;
};

export type SetArchivedYearOfStudyMutation = {
  __typename?: 'SetArchivedYearOfStudyMutation';
  error?: Maybe<Scalars['String']>;
  yearOfStudy?: Maybe<YearOfStudyField>;
};

export type SetBuddiesListFilters = {
  __typename?: 'SetBuddiesListFilters';
  error?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export type SetChatGroupLabelsMutation = {
  __typename?: 'SetChatGroupLabelsMutation';
  chatGroup?: Maybe<ChatGroupField>;
};

export type SetDeviceTokenDto = {
  active?: Maybe<Scalars['Boolean']>;
  deviceToken: Scalars['String'];
  system: Scalars['String'];
  userId: Scalars['String'];
};

export type SetMentorFieldOrderMutation = {
  __typename?: 'SetMentorFieldOrderMutation';
  error?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export type SetMentorListFilterSelection = {
  __typename?: 'SetMentorListFilterSelection';
  error?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export type SetOnlineMutation = {
  __typename?: 'SetOnlineMutation';
  online?: Maybe<Scalars['Boolean']>;
};

export type SetResolveChatGroupMutation = {
  __typename?: 'SetResolveChatGroupMutation';
  chatGroup?: Maybe<ChatGroupField>;
};

export type SetUserInterestsInput = {
  interestIds: Array<Scalars['String']>;
};

export type SetWillReplyMutation = {
  __typename?: 'SetWillReplyMutation';
  chatGroupAux?: Maybe<ChatGroupAuxiliaryField>;
};

export type SettingsDto = {
  allowDirectChat?: Maybe<Scalars['Boolean']>;
  allowGifs?: Maybe<Scalars['Boolean']>;
  allowImages?: Maybe<Scalars['Boolean']>;
  allowNewsFeed?: Maybe<Scalars['Boolean']>;
  allowPrivateGroups?: Maybe<Scalars['Boolean']>;
};

export type SettingsObjectType = {
  __typename?: 'SettingsObjectType';
  allowDirectChat: Scalars['Boolean'];
  allowGifs?: Maybe<Scalars['Boolean']>;
  allowImages: Scalars['Boolean'];
  allowNewsFeed: Scalars['Boolean'];
  allowPrivateGroups: Scalars['Boolean'];
};

export type ShowMentorInMarketplaceMutation = {
  __typename?: 'ShowMentorInMarketplaceMutation';
  mentor?: Maybe<MentorField>;
};

export type SignUpsCount = {
  __typename?: 'SignUpsCount';
  totalSignups?: Maybe<Scalars['Int']>;
};

export type SignedS3PostFields = {
  __typename?: 'SignedS3POSTFields';
  fields?: Maybe<PostFields>;
  fileName?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SignedS3UrlField = {
  __typename?: 'SignedS3URLField';
  mediaUrl?: Maybe<Scalars['String']>;
  objectName?: Maybe<Scalars['String']>;
  signedUrl?: Maybe<Scalars['String']>;
};

export type SingleChoiceAttributeField = CustomAttributeField & {
  __typename?: 'SingleChoiceAttributeField';
  active?: Maybe<Scalars['Boolean']>;
  destination?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSyncedWithCrm?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<CustomAttributeOptionField>>>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type SingleChoiceValueField = CustomAttributeValueField & {
  __typename?: 'SingleChoiceValueField';
  attribute?: Maybe<CustomAttributeField>;
  id?: Maybe<Scalars['String']>;
  option?: Maybe<CustomAttributeOptionField>;
  value?: Maybe<Scalars['String']>;
};

export type SourceInputField = {
  slug: Scalars['String'];
  type: Scalars['String'];
};

export type SourceMetadataField = {
  data?: Maybe<Scalars['JSONString']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SourceTrackingParamsField = {
  __typename?: 'SourceTrackingParamsField';
  ubCampaign?: Maybe<Scalars['String']>;
  ubContent?: Maybe<Scalars['String']>;
  ubMedium?: Maybe<Scalars['String']>;
  ubSource?: Maybe<Scalars['String']>;
};

export type StaffBuddycardSnippetField = {
  __typename?: 'StaffBuddycardSnippetField';
  departmentSnippet?: Maybe<Array<Maybe<DepartmentSnippetField>>>;
  description?: Maybe<DescriptionField>;
};

export type StaffChatUserField = ChatUserField & {
  __typename?: 'StaffChatUserField';
  firstName: Scalars['String'];
  id: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  staffFields?: Maybe<StaffFieldsChatUserField>;
};


export type StaffChatUserFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type StaffContactByUniversityIdInput = {
  universityId: Scalars['String'];
};

export type StaffContactInput = {
  contact: Scalars['String'];
  description: Scalars['String'];
  universityId: Scalars['String'];
};

export type StaffContactOutput = {
  __typename?: 'StaffContactOutput';
  contact: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['String'];
  universityId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StaffField = {
  __typename?: 'StaffField';
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  chatGroupCount?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  messageCount?: Maybe<Scalars['Int']>;
  profilePhoto?: Maybe<Scalars['String']>;
  staffFields?: Maybe<StaffUserFieldsField>;
};


export type StaffFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type StaffFieldsChatUserField = {
  __typename?: 'StaffFieldsChatUserField';
  department?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type StaffPopcardSnippetField = {
  __typename?: 'StaffPopcardSnippetField';
  departmentSnippet?: Maybe<Array<Maybe<DepartmentSnippetField>>>;
  description?: Maybe<DescriptionField>;
};

export type StaffProfileInformation = {
  __typename?: 'StaffProfileInformation';
  accountRole: Buddies;
  bio: Scalars['String'];
  department: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  profilePhoto: Scalars['String'];
  profileUrl: Scalars['String'];
  role: Scalars['String'];
  universityId?: Maybe<Scalars['String']>;
};

export type StaffUserField = {
  __typename?: 'StaffUserField';
  bio?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type StaffUserFieldsField = {
  __typename?: 'StaffUserFieldsField';
  bio?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type StaffUserListField = {
  __typename?: 'StaffUserListField';
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<StaffField>>>;
};

export type StartRecordingArgs = {
  channelId: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  universityId: Scalars['String'];
  universitySlug?: Maybe<Scalars['String']>;
};

export type StateStatField = {
  __typename?: 'StateStatField';
  count?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

export type StopRecordingArgs = {
  channelId: Scalars['String'];
};

export type SubTopicField = {
  __typename?: 'SubTopicField';
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  topic?: Maybe<TopicField>;
};

export type SubTopicStatField = {
  __typename?: 'SubTopicStatField';
  messageCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type SubTopicStatisticField = {
  __typename?: 'SubTopicStatisticField';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SuggestionField = {
  __typename?: 'SuggestionField';
  canonicalQuestions?: Maybe<Array<Maybe<CanonicalQuestionField>>>;
  id?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
  sentence?: Maybe<Scalars['String']>;
  topSubTopic?: Maybe<TopSubTopicField>;
  topSubTopics?: Maybe<Array<Maybe<TopSubTopicField>>>;
};

export type SwitchUniversitiesField = {
  __typename?: 'SwitchUniversitiesField';
  universities?: Maybe<Array<Maybe<SwitchUniversityListField>>>;
};

export type SwitchUniversityField = {
  __typename?: 'SwitchUniversityField';
  id?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  squareLogo?: Maybe<Scalars['String']>;
  whiteLogo?: Maybe<Scalars['String']>;
};

export type SwitchUniversityListField = {
  __typename?: 'SwitchUniversityListField';
  university?: Maybe<SwitchUniversityField>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type Tag = {
  id: Scalars['String'];
  type: Scalars['String'];
};

export type TagField = {
  __typename?: 'TagField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TextInLocale = {
  __typename?: 'TextInLocale';
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type TextInLocaleInput = {
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type TimeTakenField = {
  __typename?: 'TimeTakenField';
  totalTime?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['String']>;
};

export type ToggleCrmIntegrationSync = {
  __typename?: 'ToggleCrmIntegrationSync';
  integration?: Maybe<CrmIntegrationField>;
};

export type ToggleCustomAttributeCrmSyncMutation = {
  __typename?: 'ToggleCustomAttributeCrmSyncMutation';
  customAttributeNewSyncStatus?: Maybe<CrmFieldSyncStatus>;
  error?: Maybe<Scalars['String']>;
};

export type ToggleDegreesActiveMutation = {
  __typename?: 'ToggleDegreesActiveMutation';
  degrees?: Maybe<Array<Maybe<DegreeField>>>;
};

export type ToggleFeatureAvailabilityMutation = {
  __typename?: 'ToggleFeatureAvailabilityMutation';
  university?: Maybe<UniversityField>;
};

export type TopCountryField = {
  __typename?: 'TopCountryField';
  count?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
};

export type TopDegreeField = {
  __typename?: 'TopDegreeField';
  count?: Maybe<Scalars['Int']>;
  degree?: Maybe<Scalars['String']>;
};

export type TopDomesticRegionField = {
  __typename?: 'TopDomesticRegionField';
  count?: Maybe<Scalars['Int']>;
  domesticRegion?: Maybe<Scalars['String']>;
};

export type TopRegionField = {
  __typename?: 'TopRegionField';
  count?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
};

export type TopSubTopicField = {
  __typename?: 'TopSubTopicField';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  probability?: Maybe<Scalars['Float']>;
  topic?: Maybe<TopTopicField>;
};

export type TopTopicField = {
  __typename?: 'TopTopicField';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  probability?: Maybe<Scalars['Float']>;
};

export type TopicField = {
  __typename?: 'TopicField';
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subTopics?: Maybe<Array<Maybe<SubTopicField>>>;
};

export type TopicSnippetField = {
  __typename?: 'TopicSnippetField';
  snippet?: Maybe<Scalars['String']>;
  topic?: Maybe<BlogTopicField>;
};

export type TopicStatisticField = {
  __typename?: 'TopicStatisticField';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subTopics?: Maybe<Array<Maybe<SubTopicStatisticField>>>;
};

export type TopicsStatField = {
  __typename?: 'TopicsStatField';
  messageCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  subTopics?: Maybe<Array<Maybe<SubTopicStatField>>>;
};

export type TrackLiveEventMutation = {
  __typename?: 'TrackLiveEventMutation';
  liveEvent?: Maybe<LiveEventField>;
};

export type TrackOpenDayMutation = {
  __typename?: 'TrackOpenDayMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type TrendingTopicsField = {
  __typename?: 'TrendingTopicsField';
  aggregatedCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<TopicsStatField>>>;
};

export type UniversityAssistantConfiguration = {
  __typename?: 'UniversityAssistantConfiguration';
  /** university event url */
  eventUrls?: Maybe<Array<UniversityAssistantConfigurationEventUrls>>;
  /** Extra context to be added to prompt */
  promptContext?: Maybe<Scalars['String']>;
  /** A universityId */
  universityId: Scalars['String'];
};

export type UniversityAssistantConfigurationEventInput = {
  /** A description of the url */
  description?: Maybe<Scalars['String']>;
  /** A universityId */
  universityId: Scalars['String'];
  /** A url to a universities event page */
  url: Scalars['String'];
};

export type UniversityAssistantConfigurationEventOutput = {
  __typename?: 'UniversityAssistantConfigurationEventOutput';
  /** A description of the url */
  description?: Maybe<Scalars['String']>;
  /** A universityId */
  universityId: Scalars['String'];
  /** A url to a universities event page */
  url: Scalars['String'];
};

export type UniversityAssistantConfigurationEventUrls = {
  __typename?: 'UniversityAssistantConfigurationEventUrls';
  /** A description of the url */
  description?: Maybe<Scalars['String']>;
  /** A url to a universities event page */
  url: Scalars['String'];
};

export type UniversityAssistantConfigurationPromptContextInput = {
  /** Extra context to be added to prompt */
  promptContext: Scalars['String'];
  /** A universityId */
  universityId: Scalars['String'];
};

export type UniversityAssistantConfigurationPromptContextOutput = {
  __typename?: 'UniversityAssistantConfigurationPromptContextOutput';
  /** Extra context to be added to prompt */
  promptContext: Scalars['String'];
  /** A universityId */
  universityId: Scalars['String'];
};

export type UniversityBlockReportField = {
  __typename?: 'UniversityBlockReportField';
  blocked?: Maybe<ApplicantField>;
  blocker?: Maybe<AnyUserField>;
  chatGroup?: Maybe<ChatGroupField>;
  comment?: Maybe<Scalars['String']>;
  conversation?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  resolved?: Maybe<Scalars['Boolean']>;
  triggeredFrom?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export type UniversityBlockUsersMutation = {
  __typename?: 'UniversityBlockUsersMutation';
  count?: Maybe<Scalars['String']>;
};

export type UniversityBlogPostsField = {
  __typename?: 'UniversityBlogPostsField';
  blogPostCount?: Maybe<Scalars['Int']>;
  blogPosts?: Maybe<Array<Maybe<BlogPostField>>>;
};

export type UniversityChatInboxField = InboxItemInterface & {
  __typename?: 'UniversityChatInboxField';
  id: Scalars['String'];
  lastMessage?: Maybe<InboxItemLastMessageField>;
  members?: Maybe<Array<Maybe<ChatUserField>>>;
  university?: Maybe<InboxUniversityField>;
  unreadCount?: Maybe<Scalars['Int']>;
};

export type UniversityConfigField = {
  __typename?: 'UniversityConfigField';
  features?: Maybe<Array<Maybe<UniversityFeatureField>>>;
  featuresWithUniversityControl?: Maybe<Array<Maybe<UniversityFeatureField>>>;
};

export type UniversityContent = {
  __typename?: 'UniversityContent';
  hasContent: Scalars['Boolean'];
};

export type UniversityContentInput = {
  universityId: Scalars['ObjectId'];
};

export type UniversityFeatureField = {
  __typename?: 'UniversityFeatureField';
  enabled?: Maybe<Scalars['Boolean']>;
  enabledByUnibuddy?: Maybe<Scalars['Boolean']>;
  enabledByUniversity?: Maybe<Scalars['Boolean']>;
  feature?: Maybe<FeatureField>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  universityCanToggleFeature?: Maybe<Scalars['Boolean']>;
};

export type UniversityField = {
  __typename?: 'UniversityField';
  active?: Maybe<Scalars['Boolean']>;
  allowWelcomeMessageCustomization?: Maybe<Scalars['Boolean']>;
  allowWelcomeMessageCustomizationStaff?: Maybe<Scalars['Boolean']>;
  buddiesListFilters?: Maybe<Array<Maybe<BuddiesListFilterField>>>;
  chatboxUrl?: Maybe<Scalars['String']>;
  churned?: Maybe<Scalars['Boolean']>;
  colour?: Maybe<Scalars['String']>;
  config?: Maybe<UniversityConfigField>;
  country?: Maybe<CountryField>;
  customAttribute?: Maybe<CustomAttributeField>;
  customAttributeOptions?: Maybe<Array<Maybe<CustomAttributeOptionField>>>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeField>>>;
  customChatBubbleTextInLocales?: Maybe<Array<Maybe<TextInLocale>>>;
  customMarketingConsentText?: Maybe<Scalars['String']>;
  customMarketingConsentTextInLocales?: Maybe<Array<Maybe<TextInLocale>>>;
  customPreSignupText?: Maybe<Scalars['String']>;
  customPreSignupTextInLocales?: Maybe<Array<Maybe<TextInLocale>>>;
  defaultProspectWelcomeMessage?: Maybe<Scalars['String']>;
  defaultProspectWelcomeMessageStaff?: Maybe<Scalars['String']>;
  degreeTypeOrder?: Maybe<Array<Maybe<DegreeTypeOrderField>>>;
  domesticRegions?: Maybe<Array<Maybe<DomesticRegionField>>>;
  entries?: Maybe<EntriesField>;
  id?: Maybe<Scalars['String']>;
  isEnterprise?: Maybe<Scalars['Boolean']>;
  isFeatureEnabled?: Maybe<Scalars['Boolean']>;
  isFeatureReadOnly?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  isReadyToLaunch?: Maybe<Scalars['Boolean']>;
  /** @deprecated Removed because CRM sync toggle is on CRM graph */
  isSyncedWithCrm?: Maybe<Scalars['Boolean']>;
  launchDate?: Maybe<Scalars['String']>;
  localePreference?: Maybe<Locale>;
  logo?: Maybe<Scalars['String']>;
  marketplaces?: Maybe<Array<Maybe<UniversityMarketplaceField>>>;
  mentorFieldOrder?: Maybe<Array<Maybe<MentorFieldOrderField>>>;
  mentorListFilters?: Maybe<Array<Maybe<MentorListFilterChoice>>>;
  mentorMessagesCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  /** @deprecated Removed because unoptimized */
  onlineMentorAvatars?: Maybe<Array<Maybe<Scalars['String']>>>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  squareLogo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  whiteLogo?: Maybe<Scalars['String']>;
  widgetUrl?: Maybe<Scalars['String']>;
  widgetUrls?: Maybe<Array<Maybe<WidgetUrlField>>>;
};


export type UniversityFieldCustomAttributeArgs = {
  attributeId?: Maybe<Scalars['String']>;
};


export type UniversityFieldCustomAttributeOptionsArgs = {
  attributeId?: Maybe<Scalars['String']>;
};


export type UniversityFieldCustomAttributesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  destination?: Maybe<Scalars['String']>;
};


export type UniversityFieldIsFeatureEnabledArgs = {
  feature?: Maybe<Scalars['String']>;
};


export type UniversityFieldIsFeatureReadOnlyArgs = {
  feature?: Maybe<Scalars['String']>;
};


export type UniversityFieldWidgetUrlsArgs = {
  selectedLocale?: Maybe<Scalars['String']>;
};

export type UniversityInboxField = {
  __typename?: 'UniversityInboxField';
  chatGroupCount?: Maybe<Scalars['Int']>;
  chatGroups?: Maybe<Array<Maybe<ChatGroupField>>>;
};

export type UniversityMarketplaceField = {
  __typename?: 'UniversityMarketplaceField';
  allowProspectDataAccess?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  marketingOptInWording?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  tncWording?: Maybe<Scalars['String']>;
  universityMarketplaceId?: Maybe<Scalars['String']>;
};

export type UniversityMarketplaceInput = {
  allowProspectDataAccess?: Maybe<Scalars['Boolean']>;
  marketingOptInWording?: Maybe<Scalars['String']>;
  marketplaceId?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  tncWording?: Maybe<Scalars['String']>;
  universityMarketplaceId?: Maybe<Scalars['String']>;
};

export type UniversityOutput = {
  __typename?: 'UniversityOutput';
  active?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  isFeatureEnabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type UniversityQuestion = {
  __typename?: 'UniversityQuestion';
  active: Scalars['Boolean'];
  answers: Array<UniversityQuestionAnswer>;
  contexts: Array<UniversityQuestionContext>;
  id: Scalars['ObjectId'];
  isMultipleAnswersAllowed: Scalars['Boolean'];
  options?: Maybe<Array<UniversityQuestionOption>>;
  text: Scalars['String'];
  type: UniversityQuestionType;
};


export type UniversityQuestionAnswersArgs = {
  universityQuestionAnswerInput: UniversityQuestionAnswerInput;
};

export type UniversityQuestionAnswer = {
  __typename?: 'UniversityQuestionAnswer';
  answeredAt?: Maybe<Scalars['DateTime']>;
  context?: Maybe<UniversityQuestionContext>;
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export type UniversityQuestionAnswerInput = {
  context: UniversityQuestionContextInput;
  universityId: Scalars['ObjectId'];
};

export type UniversityQuestionAnswerInputItem = {
  id?: Maybe<Scalars['ObjectId']>;
  text: Scalars['String'];
};

export type UniversityQuestionByIdInput = {
  context: UniversityQuestionContextInput;
  questionId: Scalars['ObjectId'];
  universityId: Scalars['ObjectId'];
  universityName?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};

export type UniversityQuestionContext = {
  __typename?: 'UniversityQuestionContext';
  id?: Maybe<Scalars['ObjectId']>;
  type: Scalars['String'];
};

export type UniversityQuestionContextInput = {
  id?: Maybe<Scalars['ObjectId']>;
  type: Scalars['String'];
};

export type UniversityQuestionInput = {
  context: UniversityQuestionContextInput;
  universityId: Scalars['ObjectId'];
  universityName?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
};

export type UniversityQuestionOption = {
  __typename?: 'UniversityQuestionOption';
  active: Scalars['Boolean'];
  id: Scalars['ObjectId'];
  text: Scalars['String'];
};

export enum UniversityQuestionType {
  MultipleChoice = 'MULTIPLE_CHOICE',
  SingleChoice = 'SINGLE_CHOICE',
  Text = 'TEXT'
}

export type UniversityScrapeIndexingOutput = {
  __typename?: 'UniversityScrapeIndexingOutput';
  createdAt: Scalars['DateTime'];
  jobStatus: ScraperJobStatus;
  taskCompletedCount: Scalars['Float'];
  taskInFlightCount: Scalars['Float'];
  taskQueuedCount: Scalars['Float'];
  tasks: Array<UniversityScrapeTask>;
  universityId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UniversityScrapeIndexingQueryInput = {
  httpStatus?: Maybe<Scalars['Float']>;
  jobId: Scalars['String'];
  requestDurationEquality?: Maybe<Scalars['String']>;
  requestDurationMs?: Maybe<Scalars['Float']>;
  sortBy?: Maybe<Scalars['String']>;
  sortByDir?: Maybe<Scalars['String']>;
  taskStatus?: Maybe<ScraperTaskStatus>;
  universityId: Scalars['String'];
};

export type UniversityScrapeInput = {
  universityId: Scalars['String'];
  url: Scalars['String'];
};

/** For scraping a list of urls, will only visit the urls defined in the 'urls' field */
export type UniversityScrapeManualInput = {
  /** The universities home page */
  rootUrl: Scalars['String'];
  /** id of the university */
  universityId: Scalars['String'];
  /** List of urls to scrape */
  urls: Array<Scalars['String']>;
};

export type UniversityScrapeOutput = {
  __typename?: 'UniversityScrapeOutput';
  created: Scalars['DateTime'];
  jobId: Scalars['String'];
  jobStatus: ScraperJobStatus;
  rootUrl: Scalars['String'];
  started: Scalars['Boolean'];
  universityId: Scalars['String'];
};

export type UniversityScrapeStopOutput = {
  __typename?: 'UniversityScrapeStopOutput';
  created: Scalars['DateTime'];
  jobId: Scalars['String'];
  jobStatus: ScraperJobStatus;
  rootUrl: Scalars['String'];
  universityId: Scalars['String'];
};

export type UniversityScrapeTask = {
  __typename?: 'UniversityScrapeTask';
  created: Scalars['DateTime'];
  depth: Scalars['Float'];
  error: Scalars['String'];
  httpStatus: Scalars['Float'];
  jobId: Scalars['String'];
  requestDurationMs: Scalars['Float'];
  rootUrl: Scalars['String'];
  taskStatus: ScraperTaskStatus;
  universityId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type UniversitySnippetField = {
  __typename?: 'UniversitySnippetField';
  blogcardSnippets?: Maybe<BlogcardSnippetField>;
  carouselWidgetSnippets?: Maybe<CarouselSnippetField>;
  courseWidgetSnippets?: Maybe<CourseSnippetField>;
  embeddedEventSnippet?: Maybe<EmbeddedEventSnippetField>;
  error?: Maybe<Scalars['String']>;
  mentorcardExternalSnippets?: Maybe<MentorcardSnippetField>;
  mentorcardInternalSnippets?: Maybe<MentorcardSnippetField>;
  popcardSnippets?: Maybe<PopcardSnippetField>;
  staffBuddycardExternalSnippet?: Maybe<StaffBuddycardSnippetField>;
  staffBuddycardInternalSnippet?: Maybe<StaffBuddycardSnippetField>;
  staffPopcardSnippet?: Maybe<StaffPopcardSnippetField>;
  university?: Maybe<UniversityField>;
  widgetSnippet?: Maybe<WidgetSnippetField>;
};

export type UniversityStopCrawlInput = {
  jobId: Scalars['String'];
  universityId: Scalars['String'];
};

export type UniversityUserField = {
  __typename?: 'UniversityUserField';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupField>>>;
  id?: Maybe<Scalars['String']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferences?: Maybe<UniversityUserPreferencesField>;
  profilePhoto?: Maybe<Scalars['String']>;
  university?: Maybe<UniversityField>;
};

export type UniversityUserPreferencesField = {
  __typename?: 'UniversityUserPreferencesField';
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UnpinMessageMutation = {
  __typename?: 'UnpinMessageMutation';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UnreadCountField = {
  __typename?: 'UnreadCountField';
  count?: Maybe<Scalars['Int']>;
};

export type UnreadMessageCountField = {
  __typename?: 'UnreadMessageCountField';
  unreadCount?: Maybe<Scalars['Int']>;
};

export type UpdateApplicantMutation = {
  __typename?: 'UpdateApplicantMutation';
  error?: Maybe<Scalars['String']>;
  me?: Maybe<MeField>;
};

export type UpdateApplicantPreferencesMutation = {
  __typename?: 'UpdateApplicantPreferencesMutation';
  me?: Maybe<MeField>;
};

export type UpdateCanonicalQuestion = {
  __typename?: 'UpdateCanonicalQuestion';
  canonicalQuestion?: Maybe<CanonicalQuestionField>;
};

export type UpdateChatRoomInput = {
  description: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateCommunityApplicantSignupSourceMutation = {
  __typename?: 'UpdateCommunityApplicantSignupSourceMutation';
  me?: Maybe<MeField>;
};

export type UpdateDirectMessagingMembersForLiveEventMutation = {
  __typename?: 'UpdateDirectMessagingMembersForLiveEventMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEvent?: Maybe<LiveEventField>;
};

export type UpdateEventPageInput = {
  description: Scalars['String'];
  /** the name of the page */
  name: Scalars['String'];
  pageImageUrl: Scalars['String'];
};

export type UpdateExternalContentInput = {
  description: Scalars['String'];
  eventPagesIds?: Maybe<Array<Scalars['ObjectId']>>;
  externalContentImageUrl: Scalars['String'];
  externalContentUrl: Scalars['String'];
  /** the name of the external content */
  name: Scalars['String'];
  showOnHomepage?: Maybe<Scalars['Boolean']>;
};

export type UpdateFaqAnswer = {
  __typename?: 'UpdateFaqAnswer';
  faqAnswer?: Maybe<FaqAnswerField>;
};

export type UpdateHobsonsConnectIntegration = {
  __typename?: 'UpdateHobsonsConnectIntegration';
  integration?: Maybe<CampusManagementConnectIntegrationField>;
};

export type UpdateIntegrationFieldMapping = {
  __typename?: 'UpdateIntegrationFieldMapping';
  integration?: Maybe<CrmIntegrationField>;
};

export type UpdateLiveEventFeedSessionDataMutation = {
  __typename?: 'UpdateLiveEventFeedSessionDataMutation';
  errors?: Maybe<Array<Maybe<LiveEventsFormFieldValidationError>>>;
  liveEventFeed?: Maybe<LiveEventChatGroupField>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateLiveSessionAnyUserInput = {
  addSavedByUserId?: Maybe<Scalars['ObjectId']>;
  removeSavedByUserId?: Maybe<Scalars['ObjectId']>;
};

export type UpdateLiveSessionInput = {
  description: Scalars['String'];
  end: Scalars['DateTime'];
  eventPagesIds?: Maybe<Array<Scalars['ObjectId']>>;
  /** the name of the event */
  name: Scalars['String'];
  presenterIds?: Maybe<Array<Scalars['String']>>;
  recordingEnabled?: Maybe<Scalars['Boolean']>;
  sessionImageUrl: Scalars['String'];
  showOnHomepage?: Maybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
};

export type UpdateManyUserMutation = {
  __typename?: 'UpdateManyUserMutation';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateMarketplaceApplicantMutation = {
  __typename?: 'UpdateMarketplaceApplicantMutation';
  me?: Maybe<MeField>;
};

export type UpdateMarketplaceApplicantUniversityLinkMutation = {
  __typename?: 'UpdateMarketplaceApplicantUniversityLinkMutation';
  me?: Maybe<MeField>;
};

export type UpdateMarketplaceMutation = {
  __typename?: 'UpdateMarketplaceMutation';
  marketplace?: Maybe<MarketplaceField>;
};

export type UpdateMentorMutation = {
  __typename?: 'UpdateMentorMutation';
  me?: Maybe<MeField>;
};

export type UpdateMentorPreferences = {
  __typename?: 'UpdateMentorPreferences';
  preferences?: Maybe<MentorPreferencesField>;
};

export type UpdateMyUniversityLinkMutation = {
  __typename?: 'UpdateMyUniversityLinkMutation';
  me?: Maybe<MeField>;
};

export type UpdatePostStatusMutation = {
  __typename?: 'UpdatePostStatusMutation';
  blogPost?: Maybe<BlogPostField>;
  error?: Maybe<Scalars['String']>;
};

export type UpdateRecordingLayoutArgs = {
  channelId: Scalars['String'];
  mode: Scalars['Int'];
  screenShareUid?: Maybe<Scalars['String']>;
};

export type UpdateResearchTimeMutation = {
  __typename?: 'UpdateResearchTimeMutation';
  result?: Maybe<Scalars['Boolean']>;
};

export type UpdateStaffContactInput = {
  contact: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  universityId: Scalars['String'];
};

export type UpdateStaffMutation = {
  __typename?: 'UpdateStaffMutation';
  me?: Maybe<MeField>;
};

export type UpdateSubTopicMutation = {
  __typename?: 'UpdateSubTopicMutation';
  subTopic?: Maybe<SubTopicField>;
};

export type UpdateTagMutation = {
  __typename?: 'UpdateTagMutation';
  tag?: Maybe<TagField>;
};

export type UpdateTopicMutation = {
  __typename?: 'UpdateTopicMutation';
  topic?: Maybe<TopicField>;
};

export type UpdateUniversityBlockReportMutation = {
  __typename?: 'UpdateUniversityBlockReportMutation';
  universityBlockReport?: Maybe<UniversityBlockReportField>;
};

export type UpdateUniversityConfigMutation = {
  __typename?: 'UpdateUniversityConfigMutation';
  error?: Maybe<Scalars['String']>;
  universityConfiguration?: Maybe<CrmServiceUniversityConfig>;
};

export type UpdateUniversityUserMutation = {
  __typename?: 'UpdateUniversityUserMutation';
  error?: Maybe<Scalars['String']>;
  me?: Maybe<MeField>;
};

export type UpdateUniversityUserPreferencesMutation = {
  __typename?: 'UpdateUniversityUserPreferencesMutation';
  me?: Maybe<MeField>;
};

export type UpdateUserMutation = {
  __typename?: 'UpdateUserMutation';
  user?: Maybe<UserField>;
};

export type UpdateUserPreferences = {
  __typename?: 'UpdateUserPreferences';
  preferences?: Maybe<UserPreferencesField>;
};

export type UpdateUserProfile = {
  areaOfStudy?: Maybe<Array<Scalars['String']>>;
  bio?: Maybe<Scalars['String']>;
  communities?: Maybe<Array<Scalars['String']>>;
  housing?: Maybe<HousingInput>;
  interests?: Maybe<Array<InterestInput>>;
  profilePhoto?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
};

export type UpdateVirtualEventInput = {
  description: Scalars['String'];
  end: Scalars['DateTime'];
  imageUrl: Scalars['String'];
  start: Scalars['DateTime'];
  /** the name of the event */
  title: Scalars['String'];
};

export type UpdatedResults = {
  __typename?: 'UpdatedResults';
  acknowledged: Scalars['Boolean'];
  matchedCount: Scalars['Float'];
  modifiedCount: Scalars['Float'];
  upsertedCount: Scalars['Float'];
  upsertedId: Scalars['String'];
};


export type UserCustomAttributesFilterField = {
  __typename?: 'UserCustomAttributesFilterField';
  name?: Maybe<Scalars['String']>;
};

export type UserDecisionStageInput = {
  decisionStage: DecisionStageEnum;
  decisionStageLocation: DecisionStageLocationEnum;
  universityId: Scalars['ObjectId'];
};

export type UserDecisionStageOutput = {
  __typename?: 'UserDecisionStageOutput';
  decisionStage: DecisionStageEnum;
  decisionStageLocation: DecisionStageLocationEnum;
  notInterestedReason?: Maybe<NotInterestedReasonEnum>;
  userId: Scalars['ObjectId'];
};

export type UserDegreeField = {
  __typename?: 'UserDegreeField';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserDirectoryResult = {
  __typename?: 'UserDirectoryResult';
  result: Array<UserField>;
  totalUsers: Scalars['Float'];
};

export type UserField = {
  __typename?: 'UserField';
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  bio?: Maybe<Scalars['String']>;
  blockedByMentors?: Maybe<Array<Maybe<BlockerField>>>;
  blockedByUniversities?: Maybe<Array<Maybe<UniversityField>>>;
  chatGroupCount?: Maybe<Scalars['Int']>;
  chatIntro?: Maybe<Scalars['String']>;
  chatToken?: Maybe<Scalars['String']>;
  communities?: Maybe<Array<Community>>;
  communityProfile?: Maybe<UserProfile>;
  country?: Maybe<CountryField>;
  created?: Maybe<Scalars['DateTime']>;
  customAttributes?: Maybe<Array<Maybe<CustomAttributeValueField>>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<GroupField>>>;
  hasUnansweredConversation?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  interests: Array<Interest>;
  isOnline?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<LanguageField>>>;
  lastName?: Maybe<Scalars['String']>;
  lastSeen?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  messageCount?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
  placeName?: Maybe<Scalars['String']>;
  preferences?: Maybe<UserPreferencesField>;
  profilePhoto?: Maybe<Scalars['String']>;
  prospectWelcomeMessage?: Maybe<Scalars['String']>;
  resolvedUnansweredConversations?: Maybe<Scalars['Boolean']>;
  shareUrl?: Maybe<Scalars['String']>;
  smudged?: Maybe<Scalars['Boolean']>;
  staffFields?: Maybe<StaffUserFieldsField>;
  stageOneComplete?: Maybe<Scalars['Boolean']>;
  stageTwoComplete?: Maybe<Scalars['Boolean']>;
  unansweredCount?: Maybe<Scalars['Int']>;
  university?: Maybe<UniversityField>;
  unreadMessageCount?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};


export type UserFieldCustomAttributesArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type UserFieldProfilePhotoArgs = {
  raw?: Maybe<Scalars['Boolean']>;
};

export type UserIdsListField = {
  __typename?: 'UserIdsListField';
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserInterest = {
  __typename?: 'UserInterest';
  id: Scalars['ObjectId'];
  name: Scalars['String'];
};

export type UserListField = {
  __typename?: 'UserListField';
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<Maybe<UserField>>>;
};

export type UserLocalePreference = {
  __typename?: 'UserLocalePreference';
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserLocalePreferenceInput = {
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserNotInterestedStageInput = {
  decisionStage: DecisionStageEnum;
  decisionStageLocation: DecisionStageLocationEnum;
  notInterestedReason: NotInterestedReasonEnum;
  universityId: Scalars['ObjectId'];
};

export type UserPartialSearchFields = {
  __typename?: 'UserPartialSearchFields';
  accountRole?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['String']>;
  concatenatedFields?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  profilePhoto?: Maybe<Scalars['String']>;
};

export type UserPermissionsListField = {
  __typename?: 'UserPermissionsListField';
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserPreferencesField = {
  __typename?: 'UserPreferencesField';
  id?: Maybe<Scalars['String']>;
  newMessageNotifications?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  areaOfStudy?: Maybe<Array<DegreeField>>;
  bio?: Maybe<Scalars['String']>;
  communities?: Maybe<Array<Scalars['String']>>;
  housing?: Maybe<HousingObject>;
  id: Scalars['ID'];
  interests?: Maybe<Array<InterestObject>>;
  profilePhoto?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type UserRecommendation = {
  __typename?: 'UserRecommendation';
  currentUser: UserField;
  matches: Array<MatchedUser>;
  totalMatches: Scalars['Int'];
  type: Scalars['String'];
};

export type UserReport = {
  __typename?: 'UserReport';
  actionTaken?: Maybe<Scalars['String']>;
  community?: Maybe<Community>;
  creationTime: Scalars['DateTime'];
  id: Scalars['ID'];
  moderationTime?: Maybe<Scalars['DateTime']>;
  moderator?: Maybe<UserField>;
  moderatorComment?: Maybe<Scalars['String']>;
  modificationTime?: Maybe<Scalars['DateTime']>;
  product: Product;
  reportee: UserField;
  reporter: UserField;
  reporterComment: Scalars['String'];
  reporterReason: Scalars['String'];
  resolved: Scalars['Boolean'];
  university: UniversityField;
};

export type UserReportModeratorInputDto = {
  actionTaken?: Maybe<Scalars['String']>;
  moderatorComment: Scalars['String'];
  resolved: Scalars['Boolean'];
  userReportId: Scalars['ObjectID'];
};

export type UserReportUserInputDto = {
  communityId?: Maybe<Scalars['ObjectID']>;
  product: Product;
  reporteeId: Scalars['ObjectID'];
  reporterComment: Scalars['String'];
  reporterReason: Scalars['String'];
  universityId: Scalars['ObjectID'];
};

export type UserTimeSheetExportField = {
  __typename?: 'UserTimeSheetExportField';
  blogs?: Maybe<Scalars['Int']>;
  conversations?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  degreeLevel?: Maybe<Scalars['String']>;
  degreeName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  liveEvents?: Maybe<Scalars['Int']>;
  research?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  totalTimeSpent?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type UserTimeSpentBreakdown = {
  __typename?: 'UserTimeSpentBreakdown';
  blogs?: Maybe<Scalars['Int']>;
  conversations?: Maybe<Scalars['Int']>;
  liveEvents?: Maybe<Scalars['Int']>;
  research?: Maybe<Scalars['Int']>;
  totalTimeSpent?: Maybe<Scalars['Int']>;
};

export type UserTimeSpentStatsField = {
  __typename?: 'UserTimeSpentStatsField';
  active?: Maybe<Scalars['Boolean']>;
  blogs?: Maybe<Scalars['Int']>;
  conversations?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  degree?: Maybe<UserDegreeField>;
  degreeLevel?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fromTime?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  liveEvents?: Maybe<Scalars['Int']>;
  profilePhotoUrl?: Maybe<Scalars['String']>;
  research?: Maybe<Scalars['Int']>;
  toTime?: Maybe<Scalars['String']>;
  totalTimeSpent?: Maybe<Scalars['Int']>;
  university?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type UsersTimeSpentData = {
  __typename?: 'UsersTimeSpentData';
  userCount?: Maybe<Scalars['Int']>;
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  userTimeStats?: Maybe<Array<Maybe<UserTimeSpentStatsField>>>;
};

export type VerifyAccount = {
  __typename?: 'VerifyAccount';
  error?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type VerifyCodeField = {
  __typename?: 'VerifyCodeField';
  success?: Maybe<Scalars['Boolean']>;
};

export type VideoContent = {
  __typename?: 'VideoContent';
  id: Scalars['ID'];
  status: VideoStatus;
  type: VideoTypeEnum;
  url: VideoUrl;
};

export type VideoFile = {
  __typename?: 'VideoFile';
  end?: Maybe<Scalars['DateTime']>;
  fileIndex: Scalars['Float'];
  lastModified?: Maybe<Scalars['DateTime']>;
  path: Scalars['String'];
  recordingNumber: Scalars['Float'];
  sid: Scalars['String'];
  size: Scalars['Float'];
  splitNumber?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type VideoSession = {
  coverS3Url: Scalars['String'];
  description: Scalars['String'];
  endTime: Scalars['String'];
  name: Scalars['String'];
  startTime: Scalars['String'];
};

export type VideoSessionField = {
  __typename?: 'VideoSessionField';
  coverS3Url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export type VideoSessionToUpdateField = {
  coverS3Url: Scalars['String'];
  description: Scalars['String'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  startTime: Scalars['String'];
};

export enum VideoStatus {
  Error = 'ERROR',
  PresignedUrlGenerated = 'PRESIGNED_URL_GENERATED',
  Uploaded = 'UPLOADED',
  Uploading = 'UPLOADING'
}

export enum VideoTypeEnum {
  Short = 'SHORT'
}

export type VideoUploadLink = {
  __typename?: 'VideoUploadLink';
  fields: Array<VideoUploadPresignedFields>;
  id: Scalars['ID'];
  presignedUrl: Scalars['String'];
};

export type VideoUploadLinkInput = {
  type: VideoTypeEnum;
  universityId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
};

export type VideoUploadPresignedFields = {
  __typename?: 'VideoUploadPresignedFields';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type VideoUrl = {
  __typename?: 'VideoUrl';
  url?: Maybe<Scalars['String']>;
};

export type ViewBlogMutation = {
  __typename?: 'ViewBlogMutation';
  post?: Maybe<BlogPostField>;
};

export type VirtualEvent = {
  __typename?: 'VirtualEvent';
  description: Scalars['String'];
  end: Scalars['DateTime'];
  id: Scalars['ObjectId'];
  imageUrl: Scalars['String'];
  start: Scalars['DateTime'];
  /** the name of the event */
  title: Scalars['String'];
  university: UniversityField;
};

export type WidgetConversationDto = {
  ambassadorId: Scalars['String'];
  universityId: Scalars['String'];
};

export type WidgetSnippetField = {
  __typename?: 'WidgetSnippetField';
  description?: Maybe<DescriptionField>;
  embeddedEventSnippet?: Maybe<EmbeddedEventSnippetField>;
  snippet?: Maybe<Scalars['String']>;
};

export type WidgetUrlField = {
  __typename?: 'WidgetUrlField';
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type WidgetUrlsInput = {
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type WillReplyField = {
  __typename?: 'WillReplyField';
  created?: Maybe<Scalars['String']>;
  dismissed?: Maybe<Scalars['Boolean']>;
  mobileAdded?: Maybe<Scalars['Boolean']>;
};

export type YearOfStudyField = {
  __typename?: 'YearOfStudyField';
  archived?: Maybe<Scalars['Boolean']>;
  degreeLevel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  university?: Maybe<Scalars['String']>;
};

export type PinMessageDto = {
  conversationId: Scalars['String'];
  messageId: Scalars['ObjectId'];
};

export type UnpinMessageDto = {
  conversationId: Scalars['String'];
  messageId: Scalars['ObjectId'];
};




export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AcceptRequestedPublicGroupInput: AcceptRequestedPublicGroupInput;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AccountRoles: AccountRoles;
  ActivateApplicantsMutation: ResolverTypeWrapper<ActivateApplicantsMutation>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  ActivateMentorsMutation: ResolverTypeWrapper<ActivateMentorsMutation>;
  ActivityField: ResolverTypeWrapper<ActivityField>;
  ActivitySourceField: ResolverTypeWrapper<ActivitySourceField>;
  AddAttributeOptionMutation: ResolverTypeWrapper<AddAttributeOptionMutation>;
  AddBotToChatGroupMutation: ResolverTypeWrapper<AddBotToChatGroupMutation>;
  AddCustomAttributeMutation: ResolverTypeWrapper<AddCustomAttributeMutation>;
  AddDegreeLevelsMutation: ResolverTypeWrapper<AddDegreeLevelsMutation>;
  AddDegreesMutation: ResolverTypeWrapper<AddDegreesMutation>;
  AddHobsonsConnectIntegration: ResolverTypeWrapper<AddHobsonsConnectIntegration>;
  AddLocalesToApp: ResolverTypeWrapper<AddLocalesToApp>;
  AddResearchTimeMutation: ResolverTypeWrapper<AddResearchTimeMutation>;
  AddYearOfStudyMutation: ResolverTypeWrapper<AddYearOfStudyMutation>;
  AdminDeleteApplicantMutation: ResolverTypeWrapper<AdminDeleteApplicantMutation>;
  AdminRecommendation: ResolverTypeWrapper<AdminRecommendation>;
  AdminRecommendationCountPerDecisionStage: ResolverTypeWrapper<AdminRecommendationCountPerDecisionStage>;
  AdminRecommendationInput: AdminRecommendationInput;
  AdminUpdateMentorMutation: ResolverTypeWrapper<AdminUpdateMentorMutation>;
  AdminUserField: ResolverTypeWrapper<AdminUserField>;
  AgeVerificationInfoField: ResolverTypeWrapper<AgeVerificationInfoField>;
  AgeVerificationInfoInputField: AgeVerificationInfoInputField;
  AlertField: ResolverTypeWrapper<AlertField>;
  AllPostsField: ResolverTypeWrapper<AllPostsField>;
  AmbassadorField: ResolverTypeWrapper<AmbassadorField>;
  AmbassadorProfileInformation: ResolverTypeWrapper<AmbassadorProfileInformation>;
  AmbassadorRecommendation: ResolverTypeWrapper<AmbassadorRecommendation>;
  AmbassadorRecommendationInput: AmbassadorRecommendationInput;
  AmbassadorRecommendationResourceType: ResolverTypeWrapper<AmbassadorRecommendationResourceType>;
  AmbassadorRecommendationResourceTypeEnum: AmbassadorRecommendationResourceTypeEnum;
  Analytics: ResolverTypeWrapper<Analytics>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AnalyticsBuddyField: ResolverTypeWrapper<AnalyticsBuddyField>;
  AnalyticsDashboardAuthField: ResolverTypeWrapper<AnalyticsDashboardAuthField>;
  AnalyticsDashboardMetadata: AnalyticsDashboardMetadata;
  AnalyticsMentorField: ResolverTypeWrapper<AnalyticsMentorField>;
  AnyChatUserField: ResolverTypeWrapper<AnyChatUserField>;
  AnyUserField: ResolverTypeWrapper<AnyUserField>;
  AppLocaleField: ResolverTypeWrapper<AppLocaleField>;
  AppLocalesFieldInput: AppLocalesFieldInput;
  ApplicantBroadcastMessageMutation: ResolverTypeWrapper<ApplicantBroadcastMessageMutation>;
  ApplicantCollectionField: ResolverTypeWrapper<ApplicantCollectionField>;
  ApplicantConversationField: ResolverTypeWrapper<ApplicantConversationField>;
  ApplicantCountField: ResolverTypeWrapper<ApplicantCountField>;
  ApplicantField: ResolverTypeWrapper<ApplicantField>;
  ApplicantInboxField: ResolverTypeWrapper<ApplicantInboxField>;
  ApplicantInvite: ResolverTypeWrapper<ApplicantInvite>;
  ApplicantLabelField: ResolverTypeWrapper<ApplicantLabelField>;
  ApplicantMarketplaceInput: ApplicantMarketplaceInput;
  ApplicantMarketplaceObjectField: ResolverTypeWrapper<ApplicantMarketplaceObjectField>;
  ApplicantPreferencesField: ResolverTypeWrapper<ApplicantPreferencesField>;
  ApplicantSchoolHistory: ResolverTypeWrapper<ApplicantSchoolHistory>;
  ApplicantSignupStats: ResolverTypeWrapper<ApplicantSignupStats>;
  ApplicantUniversityLinkField: ResolverTypeWrapper<ApplicantUniversityLinkField>;
  ApplicantUserField: ResolverTypeWrapper<ApplicantUserField>;
  ApplicantsAppliedStats: ResolverTypeWrapper<ApplicantsAppliedStats>;
  ApplicantsMessagedCount: ResolverTypeWrapper<ApplicantsMessagedCount>;
  ApplicationField: ResolverTypeWrapper<ApplicationField>;
  ApplicationInsightModel: ResolverTypeWrapper<ApplicationInsightModel>;
  AppliedUsersCountInput: AppliedUsersCountInput;
  AppliedUsersCountModel: ResolverTypeWrapper<AppliedUsersCountModel>;
  AssistantConversationFeedback: ResolverTypeWrapper<AssistantConversationFeedback>;
  AssistantConversationHistory: ResolverTypeWrapper<AssistantConversationHistory>;
  AssistantConversationLead: ResolverTypeWrapper<AssistantConversationLead>;
  AssistantConversationMessage: ResolverTypeWrapper<AssistantConversationMessage>;
  AssistantMessageFeedback: ResolverTypeWrapper<AssistantMessageFeedback>;
  AssistantMessageFeedbackInput: AssistantMessageFeedbackInput;
  AssistantMessageFeedbackType: AssistantMessageFeedbackType;
  Attachment: ResolverTypeWrapper<Attachment>;
  AttachmentDto: AttachmentDto;
  AttachmentField: ResolverTypeWrapper<AttachmentField>;
  AttachmentType: AttachmentType;
  AttendeeAnalytics: ResolverTypeWrapper<AttendeeAnalytics>;
  AttendeeData: AttendeeData;
  Attendees: ResolverTypeWrapper<Attendees>;
  AuthField: ResolverTypeWrapper<AuthField>;
  AuthorSnippetField: ResolverTypeWrapper<AuthorSnippetField>;
  BaseCustomAttributeField: ResolverTypeWrapper<BaseCustomAttributeField>;
  BlockUserMutation: ResolverTypeWrapper<BlockUserMutation>;
  BlockUserMutationV2: ResolverTypeWrapper<BlockUserMutationV2>;
  BlockerField: ResolverTypeWrapper<BlockerField>;
  BlogCommentField: ResolverTypeWrapper<BlogCommentField>;
  BlogPostField: ResolverTypeWrapper<BlogPostField>;
  BlogStatus: BlogStatus;
  BlogTopicField: ResolverTypeWrapper<BlogTopicField>;
  BlogcardSnippetField: ResolverTypeWrapper<BlogcardSnippetField>;
  BotUserField: ResolverTypeWrapper<BotUserField>;
  Buddies: Buddies;
  BuddiesField: ResolverTypeWrapper<BuddiesField>;
  BuddiesFilterOptionsField: ResolverTypeWrapper<BuddiesFilterOptionsField>;
  BuddiesListFilterField: ResolverTypeWrapper<BuddiesListFilterField>;
  BuddiesListFilterInput: BuddiesListFilterInput;
  BuddyApplicantChatGroupField: ResolverTypeWrapper<BuddyApplicantChatGroupField>;
  BuddyApplicantChatGroupFieldV2: ResolverTypeWrapper<BuddyApplicantChatGroupFieldV2>;
  BuddyApplicantChatInterventionBuddyField: ResolverTypeWrapper<BuddyApplicantChatInterventionBuddyField>;
  BuddyField: ResolverTypeWrapper<BuddyField>;
  BuddyInviteField: ResolverTypeWrapper<BuddyInviteField>;
  BuddyInviteListField: ResolverTypeWrapper<BuddyInviteListField>;
  BulkApplicantField: ResolverTypeWrapper<BulkApplicantField>;
  BulkOptOutMarketplaceUniversityMarketingMutation: ResolverTypeWrapper<BulkOptOutMarketplaceUniversityMarketingMutation>;
  ButtonField: ResolverTypeWrapper<ButtonField>;
  CRMApplicantField: ResolverTypeWrapper<CrmApplicantField>;
  CRMField: ResolverTypeWrapper<CrmField>;
  CRMIntegrationField: ResolverTypeWrapper<CrmIntegrationField>;
  CRMMetaDataField: ResolverTypeWrapper<CrmMetaDataField>;
  CSVUploadAmbassadorFieldsMutation: ResolverTypeWrapper<CsvUploadAmbassadorFieldsMutation>;
  CSVUploadApplicantsMutation: ResolverTypeWrapper<CsvUploadApplicantsMutation>;
  CampusManagementConnectIntegrationField: ResolverTypeWrapper<CampusManagementConnectIntegrationField>;
  CanonicalQuestionField: ResolverTypeWrapper<CanonicalQuestionField>;
  CarouselSnippetField: ResolverTypeWrapper<CarouselSnippetField>;
  ChatConversation: ResolverTypeWrapper<ChatConversation>;
  ChatConversationInboxInputDto: ChatConversationInboxInputDto;
  ChatGroupAndMessages: ResolverTypeWrapper<ChatGroupAndMessages>;
  ChatGroupAuxiliaryField: ResolverTypeWrapper<ChatGroupAuxiliaryField>;
  ChatGroupField: ResolverTypeWrapper<ChatGroupField>;
  ChatGroupLabelField: ResolverTypeWrapper<ChatGroupLabelField>;
  ChatInboxField: ResolverTypeWrapper<ChatInboxField>;
  ChatMessage: ResolverTypeWrapper<ChatMessage>;
  ChatPermission: ResolverTypeWrapper<ChatPermission>;
  ChatReport: ResolverTypeWrapper<ChatReport>;
  ChatReportModeratorInputDTO: ChatReportModeratorInputDto;
  ChatReportUserInputDTO: ChatReportUserInputDto;
  ChatRoom: ResolverTypeWrapper<ChatRoom>;
  ChatRoomAnalytics: ResolverTypeWrapper<ChatRoomAnalytics>;
  ChatRoomAttendeeAnalytics: ResolverTypeWrapper<ChatRoomAttendeeAnalytics>;
  ChatRoomAttendeeData: ChatRoomAttendeeData;
  ChatRoomAttendees: ResolverTypeWrapper<ChatRoomAttendees>;
  ChatSummaryField: ResolverTypeWrapper<ChatSummaryField>;
  ChatUserField: ResolversTypes['ApplicantUserField'] | ResolversTypes['MentorChatUserField'] | ResolversTypes['StaffChatUserField'];
  CheckEmailMutation: ResolverTypeWrapper<CheckEmailMutation>;
  ChildOrganisationField: ResolverTypeWrapper<ChildOrganisationField>;
  ClassificationField: ResolverTypeWrapper<ClassificationField>;
  CloneLiveEventAndChannelsMutation: ResolverTypeWrapper<CloneLiveEventAndChannelsMutation>;
  Cohort: ResolverTypeWrapper<Cohort>;
  CohortInput: CohortInput;
  CommentMutation: ResolverTypeWrapper<CommentMutation>;
  Community: ResolverTypeWrapper<Community>;
  CommunityChatGroup: ResolverTypeWrapper<CommunityChatGroup>;
  CommunityField: ResolverTypeWrapper<CommunityField>;
  CommunityInviteResponse: ResolverTypeWrapper<CommunityInviteResponse>;
  CommunityInvites: ResolverTypeWrapper<CommunityInvites>;
  CommunityJoinResponse: ResolverTypeWrapper<CommunityJoinResponse>;
  CompletedEvent: ResolverTypeWrapper<CompletedEvent>;
  CompletedEventsOverview: ResolverTypeWrapper<CompletedEventsOverview>;
  ContentBriefField: ResolverTypeWrapper<ContentBriefField>;
  Context: ResolverTypeWrapper<Context>;
  ContextType: ContextType;
  ConversationContextChatGroupField: ResolverTypeWrapper<ConversationContextChatGroupField>;
  ConversationContextField: ResolverTypeWrapper<ConversationContextField>;
  ConversationInbox: ResolverTypeWrapper<ConversationInbox>;
  ConversationTag: ResolverTypeWrapper<ConversationTag>;
  CookieConsentInputDTO: CookieConsentInputDto;
  CookieConsentOutputDTO: ResolverTypeWrapper<CookieConsentOutputDto>;
  CountPerEpoch: ResolverTypeWrapper<CountPerEpoch>;
  CountryAnalyticsValue: ResolverTypeWrapper<CountryAnalyticsValue>;
  CountryField: ResolverTypeWrapper<CountryField>;
  CountrySnippetField: ResolverTypeWrapper<CountrySnippetField>;
  CountryStatField: ResolverTypeWrapper<CountryStatField>;
  CourseSnippetField: ResolverTypeWrapper<CourseSnippetField>;
  CreateAlertMutation: ResolverTypeWrapper<CreateAlertMutation>;
  CreateApplicantInsightInput: CreateApplicantInsightInput;
  CreateApplicantMutation: ResolverTypeWrapper<CreateApplicantMutation>;
  CreateApplicantMutationV2: ResolverTypeWrapper<CreateApplicantMutationV2>;
  CreateAssistantConversationInput: CreateAssistantConversationInput;
  CreateBlogTopicMutation: ResolverTypeWrapper<CreateBlogTopicMutation>;
  CreateBuddyMutation: ResolverTypeWrapper<CreateBuddyMutation>;
  CreateCanonicalQuestion: ResolverTypeWrapper<CreateCanonicalQuestion>;
  CreateCommunityApplicantMutation: ResolverTypeWrapper<CreateCommunityApplicantMutation>;
  CreateCommunityApplicantMutationV2: ResolverTypeWrapper<CreateCommunityApplicantMutationV2>;
  CreateCommunityChatGroupInput: CreateCommunityChatGroupInput;
  CreateCommunityConversationDTO: CreateCommunityConversationDto;
  CreateCommunityDTO: CreateCommunityDto;
  CreateCrmServiceUniversityConfigMutation: ResolverTypeWrapper<CreateCrmServiceUniversityConfigMutation>;
  CreateExternalEventMutation: ResolverTypeWrapper<CreateExternalEventMutation>;
  CreateFaqAnswer: ResolverTypeWrapper<CreateFaqAnswer>;
  CreateFresherMutation: ResolverTypeWrapper<CreateFresherMutation>;
  CreateLiveEventDetailsMutation: ResolverTypeWrapper<CreateLiveEventDetailsMutation>;
  CreateLiveEventFeedMutation: ResolverTypeWrapper<CreateLiveEventFeedMutation>;
  CreateLiveEventFeedSessionsMutation: ResolverTypeWrapper<CreateLiveEventFeedSessionsMutation>;
  CreateLiveEventMutation: ResolverTypeWrapper<CreateLiveEventMutation>;
  CreateMarketplaceApplicantMutation: ResolverTypeWrapper<CreateMarketplaceApplicantMutation>;
  CreateMarketplaceMutation: ResolverTypeWrapper<CreateMarketplaceMutation>;
  CreateMentorMutation: ResolverTypeWrapper<CreateMentorMutation>;
  CreateOpenDayMutation: ResolverTypeWrapper<CreateOpenDayMutation>;
  CreateOrganisationBuddyLinkMutation: ResolverTypeWrapper<CreateOrganisationBuddyLinkMutation>;
  CreateOrganisationMutation: ResolverTypeWrapper<CreateOrganisationMutation>;
  CreatePostMutation: ResolverTypeWrapper<CreatePostMutation>;
  CreateRequestedPublicGroupInput: CreateRequestedPublicGroupInput;
  CreateSubTopicMutation: ResolverTypeWrapper<CreateSubTopicMutation>;
  CreateTagMutation: ResolverTypeWrapper<CreateTagMutation>;
  CreateTopicMutation: ResolverTypeWrapper<CreateTopicMutation>;
  CreateUniversityBlockReportMutation: ResolverTypeWrapper<CreateUniversityBlockReportMutation>;
  CreateUniversityMutation: ResolverTypeWrapper<CreateUniversityMutation>;
  CreateUniversityUserMutation: ResolverTypeWrapper<CreateUniversityUserMutation>;
  CreateUserMutation: ResolverTypeWrapper<CreateUserMutation>;
  CreateVirtualEventInput: CreateVirtualEventInput;
  CreationSource: ResolverTypeWrapper<CreationSource>;
  CrmApplicantRecordField: ResolverTypeWrapper<CrmApplicantRecordField>;
  CrmAttributeField: ResolverTypeWrapper<CrmAttributeField>;
  CrmConnectionField: ResolverTypeWrapper<CrmConnectionField>;
  CrmDataField: ResolverTypeWrapper<CrmDataField>;
  CrmFieldInputType: CrmFieldInputType;
  CrmFieldSyncStatus: ResolverTypeWrapper<CrmFieldSyncStatus>;
  CrmServiceUniversityConfig: ResolverTypeWrapper<CrmServiceUniversityConfig>;
  CrmSyncJobField: ResolverTypeWrapper<CrmSyncJobField>;
  CurrentDecisionStageOutput: ResolverTypeWrapper<CurrentDecisionStageOutput>;
  CurrentUserDecisionStageQueryInput: CurrentUserDecisionStageQueryInput;
  CustomAttributeField: ResolversTypes['BaseCustomAttributeField'] | ResolversTypes['SingleChoiceAttributeField'];
  CustomAttributeInput: CustomAttributeInput;
  CustomAttributeOptionField: ResolverTypeWrapper<CustomAttributeOptionField>;
  CustomAttributeValueField: ResolversTypes['DateValueField'] | ResolversTypes['OpenTextFieldValueField'] | ResolversTypes['SingleChoiceValueField'];
  CustomAttributeValueInput: CustomAttributeValueInput;
  CustomAttributesField: ResolverTypeWrapper<CustomAttributesField>;
  CustomAttributesFilterField: ResolverTypeWrapper<CustomAttributesFilterField>;
  CustomAttributesFilterInputField: CustomAttributesFilterInputField;
  CustomAttributesFilterOptionField: ResolverTypeWrapper<CustomAttributesFilterOptionField>;
  DataFormatConfig: ResolverTypeWrapper<DataFormatConfig>;
  DataFormatConfigInputType: DataFormatConfigInputType;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DateValueField: ResolverTypeWrapper<DateValueField>;
  DeactivateApplicantsMutation: ResolverTypeWrapper<DeactivateApplicantsMutation>;
  DeactivateMentorsMutation: ResolverTypeWrapper<DeactivateMentorsMutation>;
  DecisionStageEnum: DecisionStageEnum;
  DecisionStageLocationEnum: DecisionStageLocationEnum;
  DefaultQuestionsField: ResolverTypeWrapper<DefaultQuestionsField>;
  DegreeCategoriesAnalyticsValue: ResolverTypeWrapper<DegreeCategoriesAnalyticsValue>;
  DegreeCategoryField: ResolverTypeWrapper<DegreeCategoryField>;
  DegreeCategoryParentField: ResolverTypeWrapper<DegreeCategoryParentField>;
  DegreeChatUserField: ResolverTypeWrapper<DegreeChatUserField>;
  DegreeField: ResolverTypeWrapper<DegreeField>;
  DegreeLevelField: ResolverTypeWrapper<DegreeLevelField>;
  DegreeSnippetField: ResolverTypeWrapper<DegreeSnippetField>;
  DegreeStatField: ResolverTypeWrapper<DegreeStatField>;
  DegreeType: DegreeType;
  DegreeTypeOrderField: ResolverTypeWrapper<DegreeTypeOrderField>;
  DegreeTypeOrderInput: DegreeTypeOrderInput;
  DeleteApplicantMutation: ResolverTypeWrapper<DeleteApplicantMutation>;
  DeleteAttributeOptionsMutation: ResolverTypeWrapper<DeleteAttributeOptionsMutation>;
  DeleteCanonicalQuestion: ResolverTypeWrapper<DeleteCanonicalQuestion>;
  DeleteCommunityConversationDTO: DeleteCommunityConversationDto;
  DeleteCustomAttributesMutation: ResolverTypeWrapper<DeleteCustomAttributesMutation>;
  DeleteDegreeLevelMutation: ResolverTypeWrapper<DeleteDegreeLevelMutation>;
  DeleteFaqAnswer: ResolverTypeWrapper<DeleteFaqAnswer>;
  DeleteKnowledgeBaseEntriesByPatternMatchInput: DeleteKnowledgeBaseEntriesByPatternMatchInput;
  DeleteKnowledgeBaseEntriesByPatternMismatchInput: DeleteKnowledgeBaseEntriesByPatternMismatchInput;
  DeleteKnowledgeBaseEntriesByUrlListInput: DeleteKnowledgeBaseEntriesByUrlListInput;
  DeleteKnowledgeBaseEntriesOutput: ResolverTypeWrapper<DeleteKnowledgeBaseEntriesOutput>;
  DeleteLiveEventFeedMutation: ResolverTypeWrapper<DeleteLiveEventFeedMutation>;
  DeleteLiveEventMessageMutation: ResolverTypeWrapper<DeleteLiveEventMessageMutation>;
  DeleteLiveEventMutation: ResolverTypeWrapper<DeleteLiveEventMutation>;
  DeleteMessageDto: DeleteMessageDto;
  DeletePostMutation: ResolverTypeWrapper<DeletePostMutation>;
  DeleteRecordingResponse: ResolverTypeWrapper<DeleteRecordingResponse>;
  DeleteResearchTimeMutation: ResolverTypeWrapper<DeleteResearchTimeMutation>;
  DeleteSubTopicMutation: ResolverTypeWrapper<DeleteSubTopicMutation>;
  DeleteTopicMutation: ResolverTypeWrapper<DeleteTopicMutation>;
  DeleteUniversityUserMutation: ResolverTypeWrapper<DeleteUniversityUserMutation>;
  DeleteUserMutation: ResolverTypeWrapper<DeleteUserMutation>;
  DeleteYearOfStudyMutation: ResolverTypeWrapper<DeleteYearOfStudyMutation>;
  DepartmentSnippetField: ResolverTypeWrapper<DepartmentSnippetField>;
  DescriptionField: ResolverTypeWrapper<DescriptionField>;
  DeviceField: ResolverTypeWrapper<DeviceField>;
  DeviceMutation: ResolverTypeWrapper<DeviceMutation>;
  DeviceToken: ResolverTypeWrapper<DeviceToken>;
  DirectConversationDTO: DirectConversationDto;
  DirectMessagingApplicant: ResolverTypeWrapper<DirectMessagingApplicant>;
  DirectMessagingEnquiryTeam: ResolverTypeWrapper<DirectMessagingEnquiryTeam>;
  DirectMessagingMentor: ResolverTypeWrapper<DirectMessagingMentor>;
  DirectMessagingStaffMember: ResolverTypeWrapper<DirectMessagingStaffMember>;
  Display: ResolverTypeWrapper<Display>;
  DomesticRegionField: ResolverTypeWrapper<DomesticRegionField>;
  EditAlertMutation: ResolverTypeWrapper<EditAlertMutation>;
  EditAttributeOptionMutation: ResolverTypeWrapper<EditAttributeOptionMutation>;
  EditBlogTopicMutation: ResolverTypeWrapper<EditBlogTopicMutation>;
  EditCommunityConversationDTO: EditCommunityConversationDto;
  EditCommunityDTO: EditCommunityDto;
  EditCustomAttributeMutation: ResolverTypeWrapper<EditCustomAttributeMutation>;
  EditCustomAttributesMutation: ResolverTypeWrapper<EditCustomAttributesMutation>;
  EditCustomWelcomeMessageMutation: ResolverTypeWrapper<EditCustomWelcomeMessageMutation>;
  EditDegreeLevelMutation: ResolverTypeWrapper<EditDegreeLevelMutation>;
  EditDegreeNameMutation: ResolverTypeWrapper<EditDegreeNameMutation>;
  EditDegreesMutation: ResolverTypeWrapper<EditDegreesMutation>;
  EditExternalEventMutation: ResolverTypeWrapper<EditExternalEventMutation>;
  EditLiveEventDetailsMutation: ResolverTypeWrapper<EditLiveEventDetailsMutation>;
  EditLiveEventFeedMutation: ResolverTypeWrapper<EditLiveEventFeedMutation>;
  EditLiveEventMutation: ResolverTypeWrapper<EditLiveEventMutation>;
  EditLiveEventsLandingPageMutation: ResolverTypeWrapper<EditLiveEventsLandingPageMutation>;
  EditOpenDayMutation: ResolverTypeWrapper<EditOpenDayMutation>;
  EditOrganisationMutation: ResolverTypeWrapper<EditOrganisationMutation>;
  EditPostMutation: ResolverTypeWrapper<EditPostMutation>;
  EditUniversityMutation: ResolverTypeWrapper<EditUniversityMutation>;
  EditYearOfStudyMutation: ResolverTypeWrapper<EditYearOfStudyMutation>;
  EmbeddedEventField: ResolverTypeWrapper<EmbeddedEventField>;
  EmbeddedEventSnippetField: ResolverTypeWrapper<EmbeddedEventSnippetField>;
  EnableCustomWelcomeMessageMutation: ResolverTypeWrapper<EnableCustomWelcomeMessageMutation>;
  EnableCustomWelcomeMessageStaffMutation: ResolverTypeWrapper<EnableCustomWelcomeMessageStaffMutation>;
  EnableFeaturesMutation: ResolverTypeWrapper<EnableFeaturesMutation>;
  EnrolledInsightResponse: ResolverTypeWrapper<EnrolledInsightResponse>;
  EnrolledInsightsInput: EnrolledInsightsInput;
  EnterpriseAddOns: ResolverTypeWrapper<EnterpriseAddOns>;
  EnterpriseMentorField: ResolverTypeWrapper<EnterpriseMentorField>;
  EntriesField: ResolverTypeWrapper<EntriesField>;
  EntriesInput: EntriesInput;
  EventPage: ResolverTypeWrapper<EventPage>;
  EventPageData: EventPageData;
  EventPagesDisplayedOn: ResolverTypeWrapper<EventPagesDisplayedOn>;
  ExportAnalyticsCsvMutation: ResolverTypeWrapper<ExportAnalyticsCsvMutation>;
  ExportTimeSheetDataField: ResolverTypeWrapper<ExportTimeSheetDataField>;
  ExportedReportField: ResolverTypeWrapper<ExportedReportField>;
  ExternalContent: ResolverTypeWrapper<ExternalContent>;
  ExternalContentData: ExternalContentData;
  ExternalEventField: ResolverTypeWrapper<ExternalEventField>;
  FaqAnswerField: ResolverTypeWrapper<FaqAnswerField>;
  FeatureField: ResolverTypeWrapper<FeatureField>;
  FeatureInputField: FeatureInputField;
  FeatureNotificationRequestDTO: FeatureNotificationRequestDto;
  FeatureRulesDTO: ResolverTypeWrapper<FeatureRulesDto>;
  FeatureToggleInputField: FeatureToggleInputField;
  FieldMappingField: ResolverTypeWrapper<FieldMappingField>;
  FieldMappingInput: FieldMappingInput;
  FilterUsersDTO: FilterUsersDto;
  FlagField: ResolverTypeWrapper<FlagField>;
  FormFieldValidationError: ResolverTypeWrapper<FormFieldValidationError>;
  ForwardApplicantToUniversity: ResolverTypeWrapper<ForwardApplicantToUniversity>;
  GenerateAnalyticsDashboardTokenMutation: ResolverTypeWrapper<GenerateAnalyticsDashboardTokenMutation>;
  GenerateLiveEventReport: ResolverTypeWrapper<GenerateLiveEventReport>;
  GenerateRecommendationsInput: GenerateRecommendationsInput;
  GenericScalar: ResolverTypeWrapper<Scalars['GenericScalar']>;
  GetAssistantConversationInput: GetAssistantConversationInput;
  GetChatReportsDTO: GetChatReportsDto;
  GetDownloadableURLMutation: ResolverTypeWrapper<GetDownloadableUrlMutation>;
  GetInvitesForEmailDTO: ResolverTypeWrapper<GetInvitesForEmailDto>;
  GetMarketplaceApplicantMutation: ResolverTypeWrapper<GetMarketplaceApplicantMutation>;
  GetMessageDto: GetMessageDto;
  GetRandomAmbassadorsInput: GetRandomAmbassadorsInput;
  GetRecordingFailureIdsArgs: GetRecordingFailureIdsArgs;
  GetRecordingFailureIdsResponse: ResolverTypeWrapper<GetRecordingFailureIdsResponse>;
  GetSignedS3PostFieldsMutation: ResolverTypeWrapper<GetSignedS3PostFieldsMutation>;
  GetSignedS3UrlMutation: ResolverTypeWrapper<GetSignedS3UrlMutation>;
  GetUserReportsDTO: GetUserReportsDto;
  GifDto: GifDto;
  GifType: GifType;
  Giphy: ResolverTypeWrapper<Giphy>;
  GraphField: ResolverTypeWrapper<GraphField>;
  GraphQLOffsetPaginationInput: GraphQlOffsetPaginationInput;
  GroupField: ResolverTypeWrapper<GroupField>;
  HideMentorInMarketplaceMutation: ResolverTypeWrapper<HideMentorInMarketplaceMutation>;
  HighSchoolField: ResolverTypeWrapper<HighSchoolField>;
  HighSchoolTypeField: ResolverTypeWrapper<HighSchoolTypeField>;
  HousingCategory: HousingCategory;
  HousingInput: HousingInput;
  HousingObject: ResolverTypeWrapper<HousingObject>;
  Image: ResolverTypeWrapper<Image>;
  ImageDto: ImageDto;
  InboxItemInterface: ResolversTypes['ChatInboxField'] | ResolversTypes['UniversityChatInboxField'];
  InboxItemLastMessageField: ResolverTypeWrapper<InboxItemLastMessageField>;
  InboxItemUnion: ResolversTypes['ChatInboxField'] | ResolversTypes['UniversityChatInboxField'];
  InboxPromptAnswer: ResolverTypeWrapper<InboxPromptAnswer>;
  InboxPromptAnswerInput: InboxPromptAnswerInput;
  InboxPromptAnswerInputItem: InboxPromptAnswerInputItem;
  InboxPromptInput: InboxPromptInput;
  InboxPromptOption: ResolverTypeWrapper<InboxPromptOption>;
  InboxPromptQuestion: ResolverTypeWrapper<InboxPromptQuestion>;
  InboxUniversityField: ResolverTypeWrapper<InboxUniversityField>;
  InitialiseRecordingArgs: InitialiseRecordingArgs;
  InitialiseRecordingResponse: ResolverTypeWrapper<InitialiseRecordingResponse>;
  InputField: ResolverTypeWrapper<InputField>;
  Insights: ResolverTypeWrapper<Insights>;
  InsightsField: ResolverTypeWrapper<InsightsField>;
  Interest: ResolverTypeWrapper<Interest>;
  InterestInput: InterestInput;
  InterestObject: ResolverTypeWrapper<InterestObject>;
  InterventionMessageField: ResolverTypeWrapper<InterventionMessageField>;
  Invite: ResolverTypeWrapper<Invite>;
  InviteApplicantsMutation: ResolverTypeWrapper<InviteApplicantsMutation>;
  InviteBuddiesMutation: ResolverTypeWrapper<InviteBuddiesMutation>;
  InviteFilter: InviteFilter;
  InviteInformation: ResolverTypeWrapper<InviteInformation>;
  InviteInformationInput: InviteInformationInput;
  InviteLinkDto: ResolverTypeWrapper<InviteLinkDto>;
  InviteLinkInfoDto: ResolverTypeWrapper<InviteLinkInfoDto>;
  InviteLinkInput: InviteLinkInput;
  InviteMentorsMutation: ResolverTypeWrapper<InviteMentorsMutation>;
  InviteMethods: InviteMethods;
  InviteStatus: InviteStatus;
  IsoDateTime: ResolverTypeWrapper<Scalars['IsoDateTime']>;
  JSONString: ResolverTypeWrapper<Scalars['JSONString']>;
  JoinChatGroupMutation: ResolverTypeWrapper<JoinChatGroupMutation>;
  KnowledgeBaseDeleteEntryInput: KnowledgeBaseDeleteEntryInput;
  KnowledgeBaseDeleteEntryOutput: ResolverTypeWrapper<KnowledgeBaseDeleteEntryOutput>;
  KnowledgeBaseEmbedding: ResolverTypeWrapper<KnowledgeBaseEmbedding>;
  KnowledgeBaseSourceQueryInput: KnowledgeBaseSourceQueryInput;
  KnowledgeBaseSourcesByUrl: ResolverTypeWrapper<KnowledgeBaseSourcesByUrl>;
  KnowledgeBaseSourcesListInput: KnowledgeBaseSourcesListInput;
  LabelSentenceMutation: ResolverTypeWrapper<LabelSentenceMutation>;
  LabellingField: ResolverTypeWrapper<LabellingField>;
  LabellingSentenceCandidate: ResolverTypeWrapper<LabellingSentenceCandidate>;
  Labels: ResolverTypeWrapper<Labels>;
  LanguageField: ResolverTypeWrapper<LanguageField>;
  LastMessageField: ResolverTypeWrapper<LastMessageField>;
  LastRead: ResolverTypeWrapper<LastRead>;
  LeaveChatGroupMutation: ResolverTypeWrapper<LeaveChatGroupMutation>;
  LevelSnippetField: ResolverTypeWrapper<LevelSnippetField>;
  LikeMessageDto: LikeMessageDto;
  LikeMessageMutation: ResolverTypeWrapper<LikeMessageMutation>;
  LimitedInt: ResolverTypeWrapper<Scalars['LimitedInt']>;
  LinkOrganisationsMutation: ResolverTypeWrapper<LinkOrganisationsMutation>;
  ListAdminRecommendationInput: ListAdminRecommendationInput;
  ListBuddyApplicantConversationsField: ResolverTypeWrapper<ListBuddyApplicantConversationsField>;
  ListMentorApplicantConversationsField: ResolverTypeWrapper<ListMentorApplicantConversationsField>;
  LiveEventChatGroupField: ResolverTypeWrapper<LiveEventChatGroupField>;
  LiveEventChatGroupsInputField: LiveEventChatGroupsInputField;
  LiveEventDetailedReport: ResolverTypeWrapper<LiveEventDetailedReport>;
  LiveEventDetailedReportChannelStats: ResolverTypeWrapper<LiveEventDetailedReportChannelStats>;
  LiveEventDetailedReportCountryStats: ResolverTypeWrapper<LiveEventDetailedReportCountryStats>;
  LiveEventDetailsField: ResolverTypeWrapper<LiveEventDetailsField>;
  LiveEventDirectMessaging: ResolverTypeWrapper<LiveEventDirectMessaging>;
  LiveEventFeedbackFormMutation: ResolverTypeWrapper<LiveEventFeedbackFormMutation>;
  LiveEventField: ResolverTypeWrapper<LiveEventField>;
  LiveEventInboxField: ResolverTypeWrapper<Omit<LiveEventInboxField, 'items'> & { items?: Maybe<Array<Maybe<ResolversTypes['InboxItemUnion']>>> }>;
  LiveEventLandingPageField: ResolverTypeWrapper<LiveEventLandingPageField>;
  LiveEventProspectFeedbackFormField: ResolverTypeWrapper<LiveEventProspectFeedbackFormField>;
  LiveEventsFormFieldValidationError: ResolverTypeWrapper<LiveEventsFormFieldValidationError>;
  LiveSession: ResolverTypeWrapper<LiveSession>;
  Locale: ResolverTypeWrapper<Locale>;
  LocaleField: ResolverTypeWrapper<LocaleField>;
  LogoDataField: ResolverTypeWrapper<LogoDataField>;
  LogoutMutation: ResolverTypeWrapper<LogoutMutation>;
  ManageOrganisationAccessMutation: ResolverTypeWrapper<ManageOrganisationAccessMutation>;
  ManuallyClassifyMutation: ResolverTypeWrapper<ManuallyClassifyMutation>;
  MarkAssistantConversationAsViewedInput: MarkAssistantConversationAsViewedInput;
  MarkReadMessageDTO: MarkReadMessageDto;
  MarketplaceApplicantCountries: ResolverTypeWrapper<MarketplaceApplicantCountries>;
  MarketplaceApplicantData: MarketplaceApplicantData;
  MarketplaceApplicantDegreeCategories: ResolverTypeWrapper<MarketplaceApplicantDegreeCategories>;
  MarketplaceApplicantUserData: ResolverTypeWrapper<MarketplaceApplicantUserData>;
  MarketplaceField: ResolverTypeWrapper<MarketplaceField>;
  MarketplaceFilterInput: MarketplaceFilterInput;
  MarketplaceFilters: ResolverTypeWrapper<MarketplaceFilters>;
  MarketplaceInboxField: ResolverTypeWrapper<MarketplaceInboxField>;
  MarketplaceMentorList: ResolverTypeWrapper<MarketplaceMentorList>;
  MarketplaceSnippetField: ResolverTypeWrapper<MarketplaceSnippetField>;
  MatchedUser: ResolverTypeWrapper<MatchedUser>;
  MatchingCriteriaEnum: MatchingCriteriaEnum;
  MeField: ResolverTypeWrapper<MeField>;
  MentorApplicantChatGroupField: ResolverTypeWrapper<MentorApplicantChatGroupField>;
  MentorBroadcastMessageMutation: ResolverTypeWrapper<MentorBroadcastMessageMutation>;
  MentorChatUserField: ResolverTypeWrapper<MentorChatUserField>;
  MentorDegreeField: ResolverTypeWrapper<MentorDegreeField>;
  MentorDegreeFieldInput: MentorDegreeFieldInput;
  MentorDegreeHistory: ResolverTypeWrapper<MentorDegreeHistory>;
  MentorField: ResolverTypeWrapper<MentorField>;
  MentorFieldOrderField: ResolverTypeWrapper<MentorFieldOrderField>;
  MentorFieldOrderInput: MentorFieldOrderInput;
  MentorFields: ResolverTypeWrapper<MentorFields>;
  MentorFilterField: ResolverTypeWrapper<MentorFilterField>;
  MentorFilterInputField: MentorFilterInputField;
  MentorFilterOptionField: ResolverTypeWrapper<MentorFilterOptionField>;
  MentorInboxField: ResolverTypeWrapper<MentorInboxField>;
  MentorInterestField: ResolverTypeWrapper<MentorInterestField>;
  MentorInterventionQueryField: ResolverTypeWrapper<MentorInterventionQueryField>;
  MentorIntroMessageField: ResolverTypeWrapper<MentorIntroMessageField>;
  MentorInviteCountField: ResolverTypeWrapper<MentorInviteCountField>;
  MentorInviteField: ResolverTypeWrapper<MentorInviteField>;
  MentorListField: ResolverTypeWrapper<MentorListField>;
  MentorListFilterChoice: ResolverTypeWrapper<MentorListFilterChoice>;
  MentorListFilterChoiceInput: MentorListFilterChoiceInput;
  MentorModuleField: ResolverTypeWrapper<MentorModuleField>;
  MentorPreferencesField: ResolverTypeWrapper<MentorPreferencesField>;
  MentorSchoolHistoryField: ResolverTypeWrapper<MentorSchoolHistoryField>;
  MentorSnippetField: ResolverTypeWrapper<MentorSnippetField>;
  MentorSocietyField: ResolverTypeWrapper<MentorSocietyField>;
  MentorcardSnippetField: ResolverTypeWrapper<MentorcardSnippetField>;
  MessageDto: MessageDto;
  MessageField: ResolverTypeWrapper<MessageField>;
  MessageMetadata: ResolverTypeWrapper<MessageMetadata>;
  MessagePublicBotMutation: ResolverTypeWrapper<MessagePublicBotMutation>;
  Metadata: Metadata;
  MetricDataPoint: ResolverTypeWrapper<MetricDataPoint>;
  Mutation: ResolverTypeWrapper<{}>;
  NewsFeedGroup: ResolverTypeWrapper<NewsFeedGroup>;
  NotInterestedReasonEnum: NotInterestedReasonEnum;
  NotificationField: ResolverTypeWrapper<NotificationField>;
  ObjectID: ResolverTypeWrapper<Scalars['ObjectID']>;
  ObjectId: ResolverTypeWrapper<Scalars['ObjectId']>;
  OffsetPagination: OffsetPagination;
  OpenDayField: ResolverTypeWrapper<OpenDayField>;
  OpenDaysLoginField: ResolverTypeWrapper<OpenDaysLoginField>;
  OpenDaysSignupField: ResolverTypeWrapper<OpenDaysSignupField>;
  OpenTextFieldValueField: ResolverTypeWrapper<OpenTextFieldValueField>;
  OrderByLiveSessionsInput: OrderByLiveSessionsInput;
  OrganisationBuddyLinkField: ResolverTypeWrapper<OrganisationBuddyLinkField>;
  OrganisationField: ResolverTypeWrapper<OrganisationField>;
  OrganisationType: OrganisationType;
  OriginField: OriginField;
  OutboxChatGroupField: ResolverTypeWrapper<OutboxChatGroupField>;
  OutputField: ResolverTypeWrapper<OutputField>;
  PageMentorField: ResolverTypeWrapper<PageMentorField>;
  PaginatedAdminRecommendation: ResolverTypeWrapper<PaginatedAdminRecommendation>;
  PaginatedApplicantInsightInput: PaginatedApplicantInsightInput;
  PaginatedApplicantInvite: ResolverTypeWrapper<PaginatedApplicantInvite>;
  PaginatedApplicationInsightModel: ResolverTypeWrapper<PaginatedApplicationInsightModel>;
  PaginatedChatMessages: ResolverTypeWrapper<PaginatedChatMessages>;
  PaginatedChatRooms: ResolverTypeWrapper<PaginatedChatRooms>;
  PaginatedEventPages: ResolverTypeWrapper<PaginatedEventPages>;
  PaginatedExternalContents: ResolverTypeWrapper<PaginatedExternalContents>;
  PaginatedLiveSessions: ResolverTypeWrapper<PaginatedLiveSessions>;
  PaginatedVirtualEvents: ResolverTypeWrapper<PaginatedVirtualEvents>;
  PaginationInput: PaginationInput;
  PasswordResetMobileMutation: ResolverTypeWrapper<PasswordResetMobileMutation>;
  PasswordResetMobileMutationV2: ResolverTypeWrapper<PasswordResetMobileMutationV2>;
  PayloadField: ResolverTypeWrapper<PayloadField>;
  PinMessageMutation: ResolverTypeWrapper<PinMessageMutation>;
  PinnedMessageField: ResolverTypeWrapper<PinnedMessageField>;
  PinnedMessageSenderField: ResolverTypeWrapper<PinnedMessageSenderField>;
  Poll: ResolverTypeWrapper<Poll>;
  PollDto: PollDto;
  PollOption: ResolverTypeWrapper<PollOption>;
  PollOptionDto: PollOptionDto;
  PopCardDegreeSnippetField: ResolverTypeWrapper<PopCardDegreeSnippetField>;
  PopCardLevelSnippetField: ResolverTypeWrapper<PopCardLevelSnippetField>;
  PopCardWidgetField: ResolverTypeWrapper<PopCardWidgetField>;
  PopcardSnippetField: ResolverTypeWrapper<PopcardSnippetField>;
  PostFields: ResolverTypeWrapper<PostFields>;
  PreferencesInputType: PreferencesInputType;
  PreferencesObjectType: ResolverTypeWrapper<PreferencesObjectType>;
  PreferencesOutputType: ResolverTypeWrapper<PreferencesOutputType>;
  Product: Product;
  ProspectStageStatField: ResolverTypeWrapper<ProspectStageStatField>;
  ProspectStageSummaryField: ResolverTypeWrapper<ProspectStageSummaryField>;
  PublicGroupStatus: PublicGroupStatus;
  PublicMentorField: ResolverTypeWrapper<PublicMentorField>;
  PublicUserField: ResolverTypeWrapper<PublicUserField>;
  QssMetadata: QssMetadata;
  QssQuestion: QssQuestion;
  Query: ResolverTypeWrapper<{}>;
  QueryRecordingArgs: QueryRecordingArgs;
  QueryRecordingResponse: ResolverTypeWrapper<QueryRecordingResponse>;
  QuestionField: ResolverTypeWrapper<QuestionField>;
  RandomMentorsListField: ResolverTypeWrapper<RandomMentorsListField>;
  RangeDateInput: RangeDateInput;
  Reaction: ResolverTypeWrapper<Reaction>;
  ReactionDto: ReactionDto;
  ReadChatMutation: ResolverTypeWrapper<ReadChatMutation>;
  Recommendation: ResolverTypeWrapper<Recommendation>;
  RecommendationResourceType: ResolverTypeWrapper<RecommendationResourceType>;
  RecommendationResourceTypeEnum: RecommendationResourceTypeEnum;
  RecommendationSource: RecommendationSource;
  RecordUserActivityMutation: ResolverTypeWrapper<RecordUserActivityMutation>;
  RecordingConsent: ResolverTypeWrapper<RecordingConsent>;
  RecordingDownloadResponse: ResolverTypeWrapper<RecordingDownloadResponse>;
  RecordingFailureArgs: RecordingFailureArgs;
  RecordingFailureId: ResolverTypeWrapper<RecordingFailureId>;
  RecordingFailureResponse: ResolverTypeWrapper<RecordingFailureResponse>;
  RecordingListResponse: ResolverTypeWrapper<RecordingListResponse>;
  RecordingModel: ResolverTypeWrapper<RecordingModel>;
  RecordingResponse: ResolverTypeWrapper<RecordingResponse>;
  RecordingUploadingResponse: ResolverTypeWrapper<RecordingUploadingResponse>;
  RegisterToLiveEvent: ResolverTypeWrapper<RegisterToLiveEvent>;
  RejectRequestedPublicGroupInput: RejectRequestedPublicGroupInput;
  RemoveApplicantInsightInput: RemoveApplicantInsightInput;
  RemoveBuddyInvitesMutation: ResolverTypeWrapper<RemoveBuddyInvitesMutation>;
  RemoveDeviceMutation: ResolverTypeWrapper<RemoveDeviceMutation>;
  RemoveMembersFromCommunityInputDTO: RemoveMembersFromCommunityInputDto;
  RemoveMentorInvitesMutation: ResolverTypeWrapper<RemoveMentorInvitesMutation>;
  ReplyInputField: ReplyInputField;
  ReplyMessage: ResolverTypeWrapper<ReplyMessage>;
  ReplyMessageDto: ReplyMessageDto;
  ReplyOutputField: ResolverTypeWrapper<ReplyOutputField>;
  ReportAnalytics: ResolverTypeWrapper<ReportAnalytics>;
  RequestAnalyticsExportMutation: ResolverTypeWrapper<RequestAnalyticsExportMutation>;
  RequestPasswordResetMutation: ResolverTypeWrapper<RequestPasswordResetMutation>;
  RequestPasswordSetMutation: ResolverTypeWrapper<RequestPasswordSetMutation>;
  RequestedPublicGroup: ResolverTypeWrapper<RequestedPublicGroup>;
  ResearchTimeInput: ResearchTimeInput;
  ResearchTimeLogResult: ResolverTypeWrapper<ResearchTimeLogResult>;
  ResearchTimeResult: ResolverTypeWrapper<ResearchTimeResult>;
  ResetPasswordMobileMutation: ResolverTypeWrapper<ResetPasswordMobileMutation>;
  ResetPasswordMutation: ResolverTypeWrapper<ResetPasswordMutation>;
  ResetPasswordMutationV2: ResolverTypeWrapper<ResetPasswordMutationV2>;
  ResolveMessageDto: ResolveMessageDto;
  ResolveUniversityChatsMutation: ResolverTypeWrapper<ResolveUniversityChatsMutation>;
  RichContentDto: RichContentDto;
  RichContentInputField: RichContentInputField;
  RichContentOutputField: ResolverTypeWrapper<RichContentOutputField>;
  RichMessageContent: ResolverTypeWrapper<RichMessageContent>;
  RootURLEnum: RootUrlEnum;
  RunManageCommandMutation: ResolverTypeWrapper<RunManageCommandMutation>;
  SatisfactionSurveyDTO: SatisfactionSurveyDto;
  SatisfactionSurveyEntity: ResolverTypeWrapper<SatisfactionSurveyEntity>;
  SaveAppliedUsersInput: SaveAppliedUsersInput;
  SaveAppliedUsersModel: ResolverTypeWrapper<SaveAppliedUsersModel>;
  SaveAssistantConversationEmailLeadInput: SaveAssistantConversationEmailLeadInput;
  SaveAssistantConversationFeedbackInput: SaveAssistantConversationFeedbackInput;
  SaveUniversityQuestionAnswerInput: SaveUniversityQuestionAnswerInput;
  SaveVisitedRecommendationInput: SaveVisitedRecommendationInput;
  ScraperJobStatus: ScraperJobStatus;
  ScraperTaskStatus: ScraperTaskStatus;
  SearchResult: ResolverTypeWrapper<SearchResult>;
  SendAssistantMessageInput: SendAssistantMessageInput;
  SendCommunityVerificationCode: ResolverTypeWrapper<SendCommunityVerificationCode>;
  SendFeedMessageMutation: ResolverTypeWrapper<SendFeedMessageMutation>;
  SendLiveEventMessageMutation: ResolverTypeWrapper<SendLiveEventMessageMutation>;
  SendMessageMutation: ResolverTypeWrapper<SendMessageMutation>;
  SendOpenDayProspectReport: ResolverTypeWrapper<SendOpenDayProspectReport>;
  SendResetPasswordEmail: ResolverTypeWrapper<SendResetPasswordEmail>;
  SendVerificationCode: ResolverTypeWrapper<SendVerificationCode>;
  SenderRole: SenderRole;
  SequenceFilterMentorListField: ResolverTypeWrapper<SequenceFilterMentorListField>;
  SessionAnalytics: ResolverTypeWrapper<SessionAnalytics>;
  SessionAttendeeAnalytics: ResolverTypeWrapper<SessionAttendeeAnalytics>;
  SessionAttendeeData: SessionAttendeeData;
  SessionAttendees: ResolverTypeWrapper<SessionAttendees>;
  SessionData: SessionData;
  SetActiveBlogTopicsMutation: ResolverTypeWrapper<SetActiveBlogTopicsMutation>;
  SetAllNotificationsReadMutation: ResolverTypeWrapper<SetAllNotificationsReadMutation>;
  SetArchivedContentBriefMutation: ResolverTypeWrapper<SetArchivedContentBriefMutation>;
  SetArchivedDegreeLevelMutation: ResolverTypeWrapper<SetArchivedDegreeLevelMutation>;
  SetArchivedYearOfStudyMutation: ResolverTypeWrapper<SetArchivedYearOfStudyMutation>;
  SetBuddiesListFilters: ResolverTypeWrapper<SetBuddiesListFilters>;
  SetChatGroupLabelsMutation: ResolverTypeWrapper<SetChatGroupLabelsMutation>;
  SetDeviceTokenDTO: SetDeviceTokenDto;
  SetMentorFieldOrderMutation: ResolverTypeWrapper<SetMentorFieldOrderMutation>;
  SetMentorListFilterSelection: ResolverTypeWrapper<SetMentorListFilterSelection>;
  SetOnlineMutation: ResolverTypeWrapper<SetOnlineMutation>;
  SetResolveChatGroupMutation: ResolverTypeWrapper<SetResolveChatGroupMutation>;
  SetUserInterestsInput: SetUserInterestsInput;
  SetWillReplyMutation: ResolverTypeWrapper<SetWillReplyMutation>;
  SettingsDTO: SettingsDto;
  SettingsObjectType: ResolverTypeWrapper<SettingsObjectType>;
  ShowMentorInMarketplaceMutation: ResolverTypeWrapper<ShowMentorInMarketplaceMutation>;
  SignUpsCount: ResolverTypeWrapper<SignUpsCount>;
  SignedS3POSTFields: ResolverTypeWrapper<SignedS3PostFields>;
  SignedS3URLField: ResolverTypeWrapper<SignedS3UrlField>;
  SingleChoiceAttributeField: ResolverTypeWrapper<SingleChoiceAttributeField>;
  SingleChoiceValueField: ResolverTypeWrapper<SingleChoiceValueField>;
  SourceInputField: SourceInputField;
  SourceMetadataField: SourceMetadataField;
  SourceTrackingParamsField: ResolverTypeWrapper<SourceTrackingParamsField>;
  StaffBuddycardSnippetField: ResolverTypeWrapper<StaffBuddycardSnippetField>;
  StaffChatUserField: ResolverTypeWrapper<StaffChatUserField>;
  StaffContactByUniversityIdInput: StaffContactByUniversityIdInput;
  StaffContactInput: StaffContactInput;
  StaffContactOutput: ResolverTypeWrapper<StaffContactOutput>;
  StaffField: ResolverTypeWrapper<StaffField>;
  StaffFieldsChatUserField: ResolverTypeWrapper<StaffFieldsChatUserField>;
  StaffPopcardSnippetField: ResolverTypeWrapper<StaffPopcardSnippetField>;
  StaffProfileInformation: ResolverTypeWrapper<StaffProfileInformation>;
  StaffUserField: ResolverTypeWrapper<StaffUserField>;
  StaffUserFieldsField: ResolverTypeWrapper<StaffUserFieldsField>;
  StaffUserListField: ResolverTypeWrapper<StaffUserListField>;
  StartRecordingArgs: StartRecordingArgs;
  StateStatField: ResolverTypeWrapper<StateStatField>;
  StopRecordingArgs: StopRecordingArgs;
  SubTopicField: ResolverTypeWrapper<SubTopicField>;
  SubTopicStatField: ResolverTypeWrapper<SubTopicStatField>;
  SubTopicStatisticField: ResolverTypeWrapper<SubTopicStatisticField>;
  SuggestionField: ResolverTypeWrapper<SuggestionField>;
  SwitchUniversitiesField: ResolverTypeWrapper<SwitchUniversitiesField>;
  SwitchUniversityField: ResolverTypeWrapper<SwitchUniversityField>;
  SwitchUniversityListField: ResolverTypeWrapper<SwitchUniversityListField>;
  Tag: Tag;
  TagField: ResolverTypeWrapper<TagField>;
  TextInLocale: ResolverTypeWrapper<TextInLocale>;
  TextInLocaleInput: TextInLocaleInput;
  TimeTakenField: ResolverTypeWrapper<TimeTakenField>;
  ToggleCrmIntegrationSync: ResolverTypeWrapper<ToggleCrmIntegrationSync>;
  ToggleCustomAttributeCrmSyncMutation: ResolverTypeWrapper<ToggleCustomAttributeCrmSyncMutation>;
  ToggleDegreesActiveMutation: ResolverTypeWrapper<ToggleDegreesActiveMutation>;
  ToggleFeatureAvailabilityMutation: ResolverTypeWrapper<ToggleFeatureAvailabilityMutation>;
  TopCountryField: ResolverTypeWrapper<TopCountryField>;
  TopDegreeField: ResolverTypeWrapper<TopDegreeField>;
  TopDomesticRegionField: ResolverTypeWrapper<TopDomesticRegionField>;
  TopRegionField: ResolverTypeWrapper<TopRegionField>;
  TopSubTopicField: ResolverTypeWrapper<TopSubTopicField>;
  TopTopicField: ResolverTypeWrapper<TopTopicField>;
  TopicField: ResolverTypeWrapper<TopicField>;
  TopicSnippetField: ResolverTypeWrapper<TopicSnippetField>;
  TopicStatisticField: ResolverTypeWrapper<TopicStatisticField>;
  TopicsStatField: ResolverTypeWrapper<TopicsStatField>;
  TrackLiveEventMutation: ResolverTypeWrapper<TrackLiveEventMutation>;
  TrackOpenDayMutation: ResolverTypeWrapper<TrackOpenDayMutation>;
  TrendingTopicsField: ResolverTypeWrapper<TrendingTopicsField>;
  UniversityAssistantConfiguration: ResolverTypeWrapper<UniversityAssistantConfiguration>;
  UniversityAssistantConfigurationEventInput: UniversityAssistantConfigurationEventInput;
  UniversityAssistantConfigurationEventOutput: ResolverTypeWrapper<UniversityAssistantConfigurationEventOutput>;
  UniversityAssistantConfigurationEventUrls: ResolverTypeWrapper<UniversityAssistantConfigurationEventUrls>;
  UniversityAssistantConfigurationPromptContextInput: UniversityAssistantConfigurationPromptContextInput;
  UniversityAssistantConfigurationPromptContextOutput: ResolverTypeWrapper<UniversityAssistantConfigurationPromptContextOutput>;
  UniversityBlockReportField: ResolverTypeWrapper<UniversityBlockReportField>;
  UniversityBlockUsersMutation: ResolverTypeWrapper<UniversityBlockUsersMutation>;
  UniversityBlogPostsField: ResolverTypeWrapper<UniversityBlogPostsField>;
  UniversityChatInboxField: ResolverTypeWrapper<UniversityChatInboxField>;
  UniversityConfigField: ResolverTypeWrapper<UniversityConfigField>;
  UniversityContent: ResolverTypeWrapper<UniversityContent>;
  UniversityContentInput: UniversityContentInput;
  UniversityFeatureField: ResolverTypeWrapper<UniversityFeatureField>;
  UniversityField: ResolverTypeWrapper<UniversityField>;
  UniversityInboxField: ResolverTypeWrapper<UniversityInboxField>;
  UniversityMarketplaceField: ResolverTypeWrapper<UniversityMarketplaceField>;
  UniversityMarketplaceInput: UniversityMarketplaceInput;
  UniversityOutput: ResolverTypeWrapper<UniversityOutput>;
  UniversityQuestion: ResolverTypeWrapper<UniversityQuestion>;
  UniversityQuestionAnswer: ResolverTypeWrapper<UniversityQuestionAnswer>;
  UniversityQuestionAnswerInput: UniversityQuestionAnswerInput;
  UniversityQuestionAnswerInputItem: UniversityQuestionAnswerInputItem;
  UniversityQuestionByIdInput: UniversityQuestionByIdInput;
  UniversityQuestionContext: ResolverTypeWrapper<UniversityQuestionContext>;
  UniversityQuestionContextInput: UniversityQuestionContextInput;
  UniversityQuestionInput: UniversityQuestionInput;
  UniversityQuestionOption: ResolverTypeWrapper<UniversityQuestionOption>;
  UniversityQuestionType: UniversityQuestionType;
  UniversityScrapeIndexingOutput: ResolverTypeWrapper<UniversityScrapeIndexingOutput>;
  UniversityScrapeIndexingQueryInput: UniversityScrapeIndexingQueryInput;
  UniversityScrapeInput: UniversityScrapeInput;
  UniversityScrapeManualInput: UniversityScrapeManualInput;
  UniversityScrapeOutput: ResolverTypeWrapper<UniversityScrapeOutput>;
  UniversityScrapeStopOutput: ResolverTypeWrapper<UniversityScrapeStopOutput>;
  UniversityScrapeTask: ResolverTypeWrapper<UniversityScrapeTask>;
  UniversitySnippetField: ResolverTypeWrapper<UniversitySnippetField>;
  UniversityStopCrawlInput: UniversityStopCrawlInput;
  UniversityUserField: ResolverTypeWrapper<UniversityUserField>;
  UniversityUserPreferencesField: ResolverTypeWrapper<UniversityUserPreferencesField>;
  UnpinMessageMutation: ResolverTypeWrapper<UnpinMessageMutation>;
  UnreadCountField: ResolverTypeWrapper<UnreadCountField>;
  UnreadMessageCountField: ResolverTypeWrapper<UnreadMessageCountField>;
  UpdateApplicantMutation: ResolverTypeWrapper<UpdateApplicantMutation>;
  UpdateApplicantPreferencesMutation: ResolverTypeWrapper<UpdateApplicantPreferencesMutation>;
  UpdateCanonicalQuestion: ResolverTypeWrapper<UpdateCanonicalQuestion>;
  UpdateChatRoomInput: UpdateChatRoomInput;
  UpdateCommunityApplicantSignupSourceMutation: ResolverTypeWrapper<UpdateCommunityApplicantSignupSourceMutation>;
  UpdateDirectMessagingMembersForLiveEventMutation: ResolverTypeWrapper<UpdateDirectMessagingMembersForLiveEventMutation>;
  UpdateEventPageInput: UpdateEventPageInput;
  UpdateExternalContentInput: UpdateExternalContentInput;
  UpdateFaqAnswer: ResolverTypeWrapper<UpdateFaqAnswer>;
  UpdateHobsonsConnectIntegration: ResolverTypeWrapper<UpdateHobsonsConnectIntegration>;
  UpdateIntegrationFieldMapping: ResolverTypeWrapper<UpdateIntegrationFieldMapping>;
  UpdateLiveEventFeedSessionDataMutation: ResolverTypeWrapper<UpdateLiveEventFeedSessionDataMutation>;
  UpdateLiveSessionAnyUserInput: UpdateLiveSessionAnyUserInput;
  UpdateLiveSessionInput: UpdateLiveSessionInput;
  UpdateManyUserMutation: ResolverTypeWrapper<UpdateManyUserMutation>;
  UpdateMarketplaceApplicantMutation: ResolverTypeWrapper<UpdateMarketplaceApplicantMutation>;
  UpdateMarketplaceApplicantUniversityLinkMutation: ResolverTypeWrapper<UpdateMarketplaceApplicantUniversityLinkMutation>;
  UpdateMarketplaceMutation: ResolverTypeWrapper<UpdateMarketplaceMutation>;
  UpdateMentorMutation: ResolverTypeWrapper<UpdateMentorMutation>;
  UpdateMentorPreferences: ResolverTypeWrapper<UpdateMentorPreferences>;
  UpdateMyUniversityLinkMutation: ResolverTypeWrapper<UpdateMyUniversityLinkMutation>;
  UpdatePostStatusMutation: ResolverTypeWrapper<UpdatePostStatusMutation>;
  UpdateRecordingLayoutArgs: UpdateRecordingLayoutArgs;
  UpdateResearchTimeMutation: ResolverTypeWrapper<UpdateResearchTimeMutation>;
  UpdateStaffContactInput: UpdateStaffContactInput;
  UpdateStaffMutation: ResolverTypeWrapper<UpdateStaffMutation>;
  UpdateSubTopicMutation: ResolverTypeWrapper<UpdateSubTopicMutation>;
  UpdateTagMutation: ResolverTypeWrapper<UpdateTagMutation>;
  UpdateTopicMutation: ResolverTypeWrapper<UpdateTopicMutation>;
  UpdateUniversityBlockReportMutation: ResolverTypeWrapper<UpdateUniversityBlockReportMutation>;
  UpdateUniversityConfigMutation: ResolverTypeWrapper<UpdateUniversityConfigMutation>;
  UpdateUniversityUserMutation: ResolverTypeWrapper<UpdateUniversityUserMutation>;
  UpdateUniversityUserPreferencesMutation: ResolverTypeWrapper<UpdateUniversityUserPreferencesMutation>;
  UpdateUserMutation: ResolverTypeWrapper<UpdateUserMutation>;
  UpdateUserPreferences: ResolverTypeWrapper<UpdateUserPreferences>;
  UpdateUserProfile: UpdateUserProfile;
  UpdateVirtualEventInput: UpdateVirtualEventInput;
  UpdatedResults: ResolverTypeWrapper<UpdatedResults>;
  Upload: ResolverTypeWrapper<Scalars['Upload']>;
  UserCustomAttributesFilterField: ResolverTypeWrapper<UserCustomAttributesFilterField>;
  UserDecisionStageInput: UserDecisionStageInput;
  UserDecisionStageOutput: ResolverTypeWrapper<UserDecisionStageOutput>;
  UserDegreeField: ResolverTypeWrapper<UserDegreeField>;
  UserDirectoryResult: ResolverTypeWrapper<UserDirectoryResult>;
  UserField: ResolverTypeWrapper<UserField>;
  UserIdsListField: ResolverTypeWrapper<UserIdsListField>;
  UserInterest: ResolverTypeWrapper<UserInterest>;
  UserListField: ResolverTypeWrapper<UserListField>;
  UserLocalePreference: ResolverTypeWrapper<UserLocalePreference>;
  UserLocalePreferenceInput: UserLocalePreferenceInput;
  UserNotInterestedStageInput: UserNotInterestedStageInput;
  UserPartialSearchFields: ResolverTypeWrapper<UserPartialSearchFields>;
  UserPermissionsListField: ResolverTypeWrapper<UserPermissionsListField>;
  UserPreferencesField: ResolverTypeWrapper<UserPreferencesField>;
  UserProfile: ResolverTypeWrapper<UserProfile>;
  UserRecommendation: ResolverTypeWrapper<UserRecommendation>;
  UserReport: ResolverTypeWrapper<UserReport>;
  UserReportModeratorInputDTO: UserReportModeratorInputDto;
  UserReportUserInputDTO: UserReportUserInputDto;
  UserTimeSheetExportField: ResolverTypeWrapper<UserTimeSheetExportField>;
  UserTimeSpentBreakdown: ResolverTypeWrapper<UserTimeSpentBreakdown>;
  UserTimeSpentStatsField: ResolverTypeWrapper<UserTimeSpentStatsField>;
  UsersTimeSpentData: ResolverTypeWrapper<UsersTimeSpentData>;
  VerifyAccount: ResolverTypeWrapper<VerifyAccount>;
  VerifyCodeField: ResolverTypeWrapper<VerifyCodeField>;
  VideoContent: ResolverTypeWrapper<VideoContent>;
  VideoFile: ResolverTypeWrapper<VideoFile>;
  VideoSession: VideoSession;
  VideoSessionField: ResolverTypeWrapper<VideoSessionField>;
  VideoSessionToUpdateField: VideoSessionToUpdateField;
  VideoStatus: VideoStatus;
  VideoTypeEnum: VideoTypeEnum;
  VideoUploadLink: ResolverTypeWrapper<VideoUploadLink>;
  VideoUploadLinkInput: VideoUploadLinkInput;
  VideoUploadPresignedFields: ResolverTypeWrapper<VideoUploadPresignedFields>;
  VideoUrl: ResolverTypeWrapper<VideoUrl>;
  ViewBlogMutation: ResolverTypeWrapper<ViewBlogMutation>;
  VirtualEvent: ResolverTypeWrapper<VirtualEvent>;
  WidgetConversationDto: WidgetConversationDto;
  WidgetSnippetField: ResolverTypeWrapper<WidgetSnippetField>;
  WidgetUrlField: ResolverTypeWrapper<WidgetUrlField>;
  WidgetUrlsInput: WidgetUrlsInput;
  WillReplyField: ResolverTypeWrapper<WillReplyField>;
  YearOfStudyField: ResolverTypeWrapper<YearOfStudyField>;
  pinMessageDTO: PinMessageDto;
  unpinMessageDTO: UnpinMessageDto;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AcceptRequestedPublicGroupInput: AcceptRequestedPublicGroupInput;
  ID: Scalars['ID'];
  String: Scalars['String'];
  Boolean: Scalars['Boolean'];
  ActivateApplicantsMutation: ActivateApplicantsMutation;
  Int: Scalars['Int'];
  ActivateMentorsMutation: ActivateMentorsMutation;
  ActivityField: ActivityField;
  ActivitySourceField: ActivitySourceField;
  AddAttributeOptionMutation: AddAttributeOptionMutation;
  AddBotToChatGroupMutation: AddBotToChatGroupMutation;
  AddCustomAttributeMutation: AddCustomAttributeMutation;
  AddDegreeLevelsMutation: AddDegreeLevelsMutation;
  AddDegreesMutation: AddDegreesMutation;
  AddHobsonsConnectIntegration: AddHobsonsConnectIntegration;
  AddLocalesToApp: AddLocalesToApp;
  AddResearchTimeMutation: AddResearchTimeMutation;
  AddYearOfStudyMutation: AddYearOfStudyMutation;
  AdminDeleteApplicantMutation: AdminDeleteApplicantMutation;
  AdminRecommendation: AdminRecommendation;
  AdminRecommendationCountPerDecisionStage: AdminRecommendationCountPerDecisionStage;
  AdminRecommendationInput: AdminRecommendationInput;
  AdminUpdateMentorMutation: AdminUpdateMentorMutation;
  AdminUserField: AdminUserField;
  AgeVerificationInfoField: AgeVerificationInfoField;
  AgeVerificationInfoInputField: AgeVerificationInfoInputField;
  AlertField: AlertField;
  AllPostsField: AllPostsField;
  AmbassadorField: AmbassadorField;
  AmbassadorProfileInformation: AmbassadorProfileInformation;
  AmbassadorRecommendation: AmbassadorRecommendation;
  AmbassadorRecommendationInput: AmbassadorRecommendationInput;
  AmbassadorRecommendationResourceType: AmbassadorRecommendationResourceType;
  Analytics: Analytics;
  Float: Scalars['Float'];
  AnalyticsBuddyField: AnalyticsBuddyField;
  AnalyticsDashboardAuthField: AnalyticsDashboardAuthField;
  AnalyticsDashboardMetadata: AnalyticsDashboardMetadata;
  AnalyticsMentorField: AnalyticsMentorField;
  AnyChatUserField: AnyChatUserField;
  AnyUserField: AnyUserField;
  AppLocaleField: AppLocaleField;
  AppLocalesFieldInput: AppLocalesFieldInput;
  ApplicantBroadcastMessageMutation: ApplicantBroadcastMessageMutation;
  ApplicantCollectionField: ApplicantCollectionField;
  ApplicantConversationField: ApplicantConversationField;
  ApplicantCountField: ApplicantCountField;
  ApplicantField: ApplicantField;
  ApplicantInboxField: ApplicantInboxField;
  ApplicantInvite: ApplicantInvite;
  ApplicantLabelField: ApplicantLabelField;
  ApplicantMarketplaceInput: ApplicantMarketplaceInput;
  ApplicantMarketplaceObjectField: ApplicantMarketplaceObjectField;
  ApplicantPreferencesField: ApplicantPreferencesField;
  ApplicantSchoolHistory: ApplicantSchoolHistory;
  ApplicantSignupStats: ApplicantSignupStats;
  ApplicantUniversityLinkField: ApplicantUniversityLinkField;
  ApplicantUserField: ApplicantUserField;
  ApplicantsAppliedStats: ApplicantsAppliedStats;
  ApplicantsMessagedCount: ApplicantsMessagedCount;
  ApplicationField: ApplicationField;
  ApplicationInsightModel: ApplicationInsightModel;
  AppliedUsersCountInput: AppliedUsersCountInput;
  AppliedUsersCountModel: AppliedUsersCountModel;
  AssistantConversationFeedback: AssistantConversationFeedback;
  AssistantConversationHistory: AssistantConversationHistory;
  AssistantConversationLead: AssistantConversationLead;
  AssistantConversationMessage: AssistantConversationMessage;
  AssistantMessageFeedback: AssistantMessageFeedback;
  AssistantMessageFeedbackInput: AssistantMessageFeedbackInput;
  Attachment: Attachment;
  AttachmentDto: AttachmentDto;
  AttachmentField: AttachmentField;
  AttendeeAnalytics: AttendeeAnalytics;
  AttendeeData: AttendeeData;
  Attendees: Attendees;
  AuthField: AuthField;
  AuthorSnippetField: AuthorSnippetField;
  BaseCustomAttributeField: BaseCustomAttributeField;
  BlockUserMutation: BlockUserMutation;
  BlockUserMutationV2: BlockUserMutationV2;
  BlockerField: BlockerField;
  BlogCommentField: BlogCommentField;
  BlogPostField: BlogPostField;
  BlogTopicField: BlogTopicField;
  BlogcardSnippetField: BlogcardSnippetField;
  BotUserField: BotUserField;
  BuddiesField: BuddiesField;
  BuddiesFilterOptionsField: BuddiesFilterOptionsField;
  BuddiesListFilterField: BuddiesListFilterField;
  BuddiesListFilterInput: BuddiesListFilterInput;
  BuddyApplicantChatGroupField: BuddyApplicantChatGroupField;
  BuddyApplicantChatGroupFieldV2: BuddyApplicantChatGroupFieldV2;
  BuddyApplicantChatInterventionBuddyField: BuddyApplicantChatInterventionBuddyField;
  BuddyField: BuddyField;
  BuddyInviteField: BuddyInviteField;
  BuddyInviteListField: BuddyInviteListField;
  BulkApplicantField: BulkApplicantField;
  BulkOptOutMarketplaceUniversityMarketingMutation: BulkOptOutMarketplaceUniversityMarketingMutation;
  ButtonField: ButtonField;
  CRMApplicantField: CrmApplicantField;
  CRMField: CrmField;
  CRMIntegrationField: CrmIntegrationField;
  CRMMetaDataField: CrmMetaDataField;
  CSVUploadAmbassadorFieldsMutation: CsvUploadAmbassadorFieldsMutation;
  CSVUploadApplicantsMutation: CsvUploadApplicantsMutation;
  CampusManagementConnectIntegrationField: CampusManagementConnectIntegrationField;
  CanonicalQuestionField: CanonicalQuestionField;
  CarouselSnippetField: CarouselSnippetField;
  ChatConversation: ChatConversation;
  ChatConversationInboxInputDto: ChatConversationInboxInputDto;
  ChatGroupAndMessages: ChatGroupAndMessages;
  ChatGroupAuxiliaryField: ChatGroupAuxiliaryField;
  ChatGroupField: ChatGroupField;
  ChatGroupLabelField: ChatGroupLabelField;
  ChatInboxField: ChatInboxField;
  ChatMessage: ChatMessage;
  ChatPermission: ChatPermission;
  ChatReport: ChatReport;
  ChatReportModeratorInputDTO: ChatReportModeratorInputDto;
  ChatReportUserInputDTO: ChatReportUserInputDto;
  ChatRoom: ChatRoom;
  ChatRoomAnalytics: ChatRoomAnalytics;
  ChatRoomAttendeeAnalytics: ChatRoomAttendeeAnalytics;
  ChatRoomAttendeeData: ChatRoomAttendeeData;
  ChatRoomAttendees: ChatRoomAttendees;
  ChatSummaryField: ChatSummaryField;
  ChatUserField: ResolversParentTypes['ApplicantUserField'] | ResolversParentTypes['MentorChatUserField'] | ResolversParentTypes['StaffChatUserField'];
  CheckEmailMutation: CheckEmailMutation;
  ChildOrganisationField: ChildOrganisationField;
  ClassificationField: ClassificationField;
  CloneLiveEventAndChannelsMutation: CloneLiveEventAndChannelsMutation;
  Cohort: Cohort;
  CohortInput: CohortInput;
  CommentMutation: CommentMutation;
  Community: Community;
  CommunityChatGroup: CommunityChatGroup;
  CommunityField: CommunityField;
  CommunityInviteResponse: CommunityInviteResponse;
  CommunityInvites: CommunityInvites;
  CommunityJoinResponse: CommunityJoinResponse;
  CompletedEvent: CompletedEvent;
  CompletedEventsOverview: CompletedEventsOverview;
  ContentBriefField: ContentBriefField;
  Context: Context;
  ConversationContextChatGroupField: ConversationContextChatGroupField;
  ConversationContextField: ConversationContextField;
  ConversationInbox: ConversationInbox;
  ConversationTag: ConversationTag;
  CookieConsentInputDTO: CookieConsentInputDto;
  CookieConsentOutputDTO: CookieConsentOutputDto;
  CountPerEpoch: CountPerEpoch;
  CountryAnalyticsValue: CountryAnalyticsValue;
  CountryField: CountryField;
  CountrySnippetField: CountrySnippetField;
  CountryStatField: CountryStatField;
  CourseSnippetField: CourseSnippetField;
  CreateAlertMutation: CreateAlertMutation;
  CreateApplicantInsightInput: CreateApplicantInsightInput;
  CreateApplicantMutation: CreateApplicantMutation;
  CreateApplicantMutationV2: CreateApplicantMutationV2;
  CreateAssistantConversationInput: CreateAssistantConversationInput;
  CreateBlogTopicMutation: CreateBlogTopicMutation;
  CreateBuddyMutation: CreateBuddyMutation;
  CreateCanonicalQuestion: CreateCanonicalQuestion;
  CreateCommunityApplicantMutation: CreateCommunityApplicantMutation;
  CreateCommunityApplicantMutationV2: CreateCommunityApplicantMutationV2;
  CreateCommunityChatGroupInput: CreateCommunityChatGroupInput;
  CreateCommunityConversationDTO: CreateCommunityConversationDto;
  CreateCommunityDTO: CreateCommunityDto;
  CreateCrmServiceUniversityConfigMutation: CreateCrmServiceUniversityConfigMutation;
  CreateExternalEventMutation: CreateExternalEventMutation;
  CreateFaqAnswer: CreateFaqAnswer;
  CreateFresherMutation: CreateFresherMutation;
  CreateLiveEventDetailsMutation: CreateLiveEventDetailsMutation;
  CreateLiveEventFeedMutation: CreateLiveEventFeedMutation;
  CreateLiveEventFeedSessionsMutation: CreateLiveEventFeedSessionsMutation;
  CreateLiveEventMutation: CreateLiveEventMutation;
  CreateMarketplaceApplicantMutation: CreateMarketplaceApplicantMutation;
  CreateMarketplaceMutation: CreateMarketplaceMutation;
  CreateMentorMutation: CreateMentorMutation;
  CreateOpenDayMutation: CreateOpenDayMutation;
  CreateOrganisationBuddyLinkMutation: CreateOrganisationBuddyLinkMutation;
  CreateOrganisationMutation: CreateOrganisationMutation;
  CreatePostMutation: CreatePostMutation;
  CreateRequestedPublicGroupInput: CreateRequestedPublicGroupInput;
  CreateSubTopicMutation: CreateSubTopicMutation;
  CreateTagMutation: CreateTagMutation;
  CreateTopicMutation: CreateTopicMutation;
  CreateUniversityBlockReportMutation: CreateUniversityBlockReportMutation;
  CreateUniversityMutation: CreateUniversityMutation;
  CreateUniversityUserMutation: CreateUniversityUserMutation;
  CreateUserMutation: CreateUserMutation;
  CreateVirtualEventInput: CreateVirtualEventInput;
  CreationSource: CreationSource;
  CrmApplicantRecordField: CrmApplicantRecordField;
  CrmAttributeField: CrmAttributeField;
  CrmConnectionField: CrmConnectionField;
  CrmDataField: CrmDataField;
  CrmFieldInputType: CrmFieldInputType;
  CrmFieldSyncStatus: CrmFieldSyncStatus;
  CrmServiceUniversityConfig: CrmServiceUniversityConfig;
  CrmSyncJobField: CrmSyncJobField;
  CurrentDecisionStageOutput: CurrentDecisionStageOutput;
  CurrentUserDecisionStageQueryInput: CurrentUserDecisionStageQueryInput;
  CustomAttributeField: ResolversParentTypes['BaseCustomAttributeField'] | ResolversParentTypes['SingleChoiceAttributeField'];
  CustomAttributeInput: CustomAttributeInput;
  CustomAttributeOptionField: CustomAttributeOptionField;
  CustomAttributeValueField: ResolversParentTypes['DateValueField'] | ResolversParentTypes['OpenTextFieldValueField'] | ResolversParentTypes['SingleChoiceValueField'];
  CustomAttributeValueInput: CustomAttributeValueInput;
  CustomAttributesField: CustomAttributesField;
  CustomAttributesFilterField: CustomAttributesFilterField;
  CustomAttributesFilterInputField: CustomAttributesFilterInputField;
  CustomAttributesFilterOptionField: CustomAttributesFilterOptionField;
  DataFormatConfig: DataFormatConfig;
  DataFormatConfigInputType: DataFormatConfigInputType;
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  DateValueField: DateValueField;
  DeactivateApplicantsMutation: DeactivateApplicantsMutation;
  DeactivateMentorsMutation: DeactivateMentorsMutation;
  DefaultQuestionsField: DefaultQuestionsField;
  DegreeCategoriesAnalyticsValue: DegreeCategoriesAnalyticsValue;
  DegreeCategoryField: DegreeCategoryField;
  DegreeCategoryParentField: DegreeCategoryParentField;
  DegreeChatUserField: DegreeChatUserField;
  DegreeField: DegreeField;
  DegreeLevelField: DegreeLevelField;
  DegreeSnippetField: DegreeSnippetField;
  DegreeStatField: DegreeStatField;
  DegreeTypeOrderField: DegreeTypeOrderField;
  DegreeTypeOrderInput: DegreeTypeOrderInput;
  DeleteApplicantMutation: DeleteApplicantMutation;
  DeleteAttributeOptionsMutation: DeleteAttributeOptionsMutation;
  DeleteCanonicalQuestion: DeleteCanonicalQuestion;
  DeleteCommunityConversationDTO: DeleteCommunityConversationDto;
  DeleteCustomAttributesMutation: DeleteCustomAttributesMutation;
  DeleteDegreeLevelMutation: DeleteDegreeLevelMutation;
  DeleteFaqAnswer: DeleteFaqAnswer;
  DeleteKnowledgeBaseEntriesByPatternMatchInput: DeleteKnowledgeBaseEntriesByPatternMatchInput;
  DeleteKnowledgeBaseEntriesByPatternMismatchInput: DeleteKnowledgeBaseEntriesByPatternMismatchInput;
  DeleteKnowledgeBaseEntriesByUrlListInput: DeleteKnowledgeBaseEntriesByUrlListInput;
  DeleteKnowledgeBaseEntriesOutput: DeleteKnowledgeBaseEntriesOutput;
  DeleteLiveEventFeedMutation: DeleteLiveEventFeedMutation;
  DeleteLiveEventMessageMutation: DeleteLiveEventMessageMutation;
  DeleteLiveEventMutation: DeleteLiveEventMutation;
  DeleteMessageDto: DeleteMessageDto;
  DeletePostMutation: DeletePostMutation;
  DeleteRecordingResponse: DeleteRecordingResponse;
  DeleteResearchTimeMutation: DeleteResearchTimeMutation;
  DeleteSubTopicMutation: DeleteSubTopicMutation;
  DeleteTopicMutation: DeleteTopicMutation;
  DeleteUniversityUserMutation: DeleteUniversityUserMutation;
  DeleteUserMutation: DeleteUserMutation;
  DeleteYearOfStudyMutation: DeleteYearOfStudyMutation;
  DepartmentSnippetField: DepartmentSnippetField;
  DescriptionField: DescriptionField;
  DeviceField: DeviceField;
  DeviceMutation: DeviceMutation;
  DeviceToken: DeviceToken;
  DirectConversationDTO: DirectConversationDto;
  DirectMessagingApplicant: DirectMessagingApplicant;
  DirectMessagingEnquiryTeam: DirectMessagingEnquiryTeam;
  DirectMessagingMentor: DirectMessagingMentor;
  DirectMessagingStaffMember: DirectMessagingStaffMember;
  Display: Display;
  DomesticRegionField: DomesticRegionField;
  EditAlertMutation: EditAlertMutation;
  EditAttributeOptionMutation: EditAttributeOptionMutation;
  EditBlogTopicMutation: EditBlogTopicMutation;
  EditCommunityConversationDTO: EditCommunityConversationDto;
  EditCommunityDTO: EditCommunityDto;
  EditCustomAttributeMutation: EditCustomAttributeMutation;
  EditCustomAttributesMutation: EditCustomAttributesMutation;
  EditCustomWelcomeMessageMutation: EditCustomWelcomeMessageMutation;
  EditDegreeLevelMutation: EditDegreeLevelMutation;
  EditDegreeNameMutation: EditDegreeNameMutation;
  EditDegreesMutation: EditDegreesMutation;
  EditExternalEventMutation: EditExternalEventMutation;
  EditLiveEventDetailsMutation: EditLiveEventDetailsMutation;
  EditLiveEventFeedMutation: EditLiveEventFeedMutation;
  EditLiveEventMutation: EditLiveEventMutation;
  EditLiveEventsLandingPageMutation: EditLiveEventsLandingPageMutation;
  EditOpenDayMutation: EditOpenDayMutation;
  EditOrganisationMutation: EditOrganisationMutation;
  EditPostMutation: EditPostMutation;
  EditUniversityMutation: EditUniversityMutation;
  EditYearOfStudyMutation: EditYearOfStudyMutation;
  EmbeddedEventField: EmbeddedEventField;
  EmbeddedEventSnippetField: EmbeddedEventSnippetField;
  EnableCustomWelcomeMessageMutation: EnableCustomWelcomeMessageMutation;
  EnableCustomWelcomeMessageStaffMutation: EnableCustomWelcomeMessageStaffMutation;
  EnableFeaturesMutation: EnableFeaturesMutation;
  EnrolledInsightResponse: EnrolledInsightResponse;
  EnrolledInsightsInput: EnrolledInsightsInput;
  EnterpriseAddOns: EnterpriseAddOns;
  EnterpriseMentorField: EnterpriseMentorField;
  EntriesField: EntriesField;
  EntriesInput: EntriesInput;
  EventPage: EventPage;
  EventPageData: EventPageData;
  EventPagesDisplayedOn: EventPagesDisplayedOn;
  ExportAnalyticsCsvMutation: ExportAnalyticsCsvMutation;
  ExportTimeSheetDataField: ExportTimeSheetDataField;
  ExportedReportField: ExportedReportField;
  ExternalContent: ExternalContent;
  ExternalContentData: ExternalContentData;
  ExternalEventField: ExternalEventField;
  FaqAnswerField: FaqAnswerField;
  FeatureField: FeatureField;
  FeatureInputField: FeatureInputField;
  FeatureNotificationRequestDTO: FeatureNotificationRequestDto;
  FeatureRulesDTO: FeatureRulesDto;
  FeatureToggleInputField: FeatureToggleInputField;
  FieldMappingField: FieldMappingField;
  FieldMappingInput: FieldMappingInput;
  FilterUsersDTO: FilterUsersDto;
  FlagField: FlagField;
  FormFieldValidationError: FormFieldValidationError;
  ForwardApplicantToUniversity: ForwardApplicantToUniversity;
  GenerateAnalyticsDashboardTokenMutation: GenerateAnalyticsDashboardTokenMutation;
  GenerateLiveEventReport: GenerateLiveEventReport;
  GenerateRecommendationsInput: GenerateRecommendationsInput;
  GenericScalar: Scalars['GenericScalar'];
  GetAssistantConversationInput: GetAssistantConversationInput;
  GetChatReportsDTO: GetChatReportsDto;
  GetDownloadableURLMutation: GetDownloadableUrlMutation;
  GetInvitesForEmailDTO: GetInvitesForEmailDto;
  GetMarketplaceApplicantMutation: GetMarketplaceApplicantMutation;
  GetMessageDto: GetMessageDto;
  GetRandomAmbassadorsInput: GetRandomAmbassadorsInput;
  GetRecordingFailureIdsArgs: GetRecordingFailureIdsArgs;
  GetRecordingFailureIdsResponse: GetRecordingFailureIdsResponse;
  GetSignedS3PostFieldsMutation: GetSignedS3PostFieldsMutation;
  GetSignedS3UrlMutation: GetSignedS3UrlMutation;
  GetUserReportsDTO: GetUserReportsDto;
  GifDto: GifDto;
  Giphy: Giphy;
  GraphField: GraphField;
  GraphQLOffsetPaginationInput: GraphQlOffsetPaginationInput;
  GroupField: GroupField;
  HideMentorInMarketplaceMutation: HideMentorInMarketplaceMutation;
  HighSchoolField: HighSchoolField;
  HighSchoolTypeField: HighSchoolTypeField;
  HousingInput: HousingInput;
  HousingObject: HousingObject;
  Image: Image;
  ImageDto: ImageDto;
  InboxItemInterface: ResolversParentTypes['ChatInboxField'] | ResolversParentTypes['UniversityChatInboxField'];
  InboxItemLastMessageField: InboxItemLastMessageField;
  InboxItemUnion: ResolversParentTypes['ChatInboxField'] | ResolversParentTypes['UniversityChatInboxField'];
  InboxPromptAnswer: InboxPromptAnswer;
  InboxPromptAnswerInput: InboxPromptAnswerInput;
  InboxPromptAnswerInputItem: InboxPromptAnswerInputItem;
  InboxPromptInput: InboxPromptInput;
  InboxPromptOption: InboxPromptOption;
  InboxPromptQuestion: InboxPromptQuestion;
  InboxUniversityField: InboxUniversityField;
  InitialiseRecordingArgs: InitialiseRecordingArgs;
  InitialiseRecordingResponse: InitialiseRecordingResponse;
  InputField: InputField;
  Insights: Insights;
  InsightsField: InsightsField;
  Interest: Interest;
  InterestInput: InterestInput;
  InterestObject: InterestObject;
  InterventionMessageField: InterventionMessageField;
  Invite: Invite;
  InviteApplicantsMutation: InviteApplicantsMutation;
  InviteBuddiesMutation: InviteBuddiesMutation;
  InviteFilter: InviteFilter;
  InviteInformation: InviteInformation;
  InviteInformationInput: InviteInformationInput;
  InviteLinkDto: InviteLinkDto;
  InviteLinkInfoDto: InviteLinkInfoDto;
  InviteLinkInput: InviteLinkInput;
  InviteMentorsMutation: InviteMentorsMutation;
  IsoDateTime: Scalars['IsoDateTime'];
  JSONString: Scalars['JSONString'];
  JoinChatGroupMutation: JoinChatGroupMutation;
  KnowledgeBaseDeleteEntryInput: KnowledgeBaseDeleteEntryInput;
  KnowledgeBaseDeleteEntryOutput: KnowledgeBaseDeleteEntryOutput;
  KnowledgeBaseEmbedding: KnowledgeBaseEmbedding;
  KnowledgeBaseSourceQueryInput: KnowledgeBaseSourceQueryInput;
  KnowledgeBaseSourcesByUrl: KnowledgeBaseSourcesByUrl;
  KnowledgeBaseSourcesListInput: KnowledgeBaseSourcesListInput;
  LabelSentenceMutation: LabelSentenceMutation;
  LabellingField: LabellingField;
  LabellingSentenceCandidate: LabellingSentenceCandidate;
  Labels: Labels;
  LanguageField: LanguageField;
  LastMessageField: LastMessageField;
  LastRead: LastRead;
  LeaveChatGroupMutation: LeaveChatGroupMutation;
  LevelSnippetField: LevelSnippetField;
  LikeMessageDto: LikeMessageDto;
  LikeMessageMutation: LikeMessageMutation;
  LimitedInt: Scalars['LimitedInt'];
  LinkOrganisationsMutation: LinkOrganisationsMutation;
  ListAdminRecommendationInput: ListAdminRecommendationInput;
  ListBuddyApplicantConversationsField: ListBuddyApplicantConversationsField;
  ListMentorApplicantConversationsField: ListMentorApplicantConversationsField;
  LiveEventChatGroupField: LiveEventChatGroupField;
  LiveEventChatGroupsInputField: LiveEventChatGroupsInputField;
  LiveEventDetailedReport: LiveEventDetailedReport;
  LiveEventDetailedReportChannelStats: LiveEventDetailedReportChannelStats;
  LiveEventDetailedReportCountryStats: LiveEventDetailedReportCountryStats;
  LiveEventDetailsField: LiveEventDetailsField;
  LiveEventDirectMessaging: LiveEventDirectMessaging;
  LiveEventFeedbackFormMutation: LiveEventFeedbackFormMutation;
  LiveEventField: LiveEventField;
  LiveEventInboxField: Omit<LiveEventInboxField, 'items'> & { items?: Maybe<Array<Maybe<ResolversParentTypes['InboxItemUnion']>>> };
  LiveEventLandingPageField: LiveEventLandingPageField;
  LiveEventProspectFeedbackFormField: LiveEventProspectFeedbackFormField;
  LiveEventsFormFieldValidationError: LiveEventsFormFieldValidationError;
  LiveSession: LiveSession;
  Locale: Locale;
  LocaleField: LocaleField;
  LogoDataField: LogoDataField;
  LogoutMutation: LogoutMutation;
  ManageOrganisationAccessMutation: ManageOrganisationAccessMutation;
  ManuallyClassifyMutation: ManuallyClassifyMutation;
  MarkAssistantConversationAsViewedInput: MarkAssistantConversationAsViewedInput;
  MarkReadMessageDTO: MarkReadMessageDto;
  MarketplaceApplicantCountries: MarketplaceApplicantCountries;
  MarketplaceApplicantData: MarketplaceApplicantData;
  MarketplaceApplicantDegreeCategories: MarketplaceApplicantDegreeCategories;
  MarketplaceApplicantUserData: MarketplaceApplicantUserData;
  MarketplaceField: MarketplaceField;
  MarketplaceFilterInput: MarketplaceFilterInput;
  MarketplaceFilters: MarketplaceFilters;
  MarketplaceInboxField: MarketplaceInboxField;
  MarketplaceMentorList: MarketplaceMentorList;
  MarketplaceSnippetField: MarketplaceSnippetField;
  MatchedUser: MatchedUser;
  MeField: MeField;
  MentorApplicantChatGroupField: MentorApplicantChatGroupField;
  MentorBroadcastMessageMutation: MentorBroadcastMessageMutation;
  MentorChatUserField: MentorChatUserField;
  MentorDegreeField: MentorDegreeField;
  MentorDegreeFieldInput: MentorDegreeFieldInput;
  MentorDegreeHistory: MentorDegreeHistory;
  MentorField: MentorField;
  MentorFieldOrderField: MentorFieldOrderField;
  MentorFieldOrderInput: MentorFieldOrderInput;
  MentorFields: MentorFields;
  MentorFilterField: MentorFilterField;
  MentorFilterInputField: MentorFilterInputField;
  MentorFilterOptionField: MentorFilterOptionField;
  MentorInboxField: MentorInboxField;
  MentorInterestField: MentorInterestField;
  MentorInterventionQueryField: MentorInterventionQueryField;
  MentorIntroMessageField: MentorIntroMessageField;
  MentorInviteCountField: MentorInviteCountField;
  MentorInviteField: MentorInviteField;
  MentorListField: MentorListField;
  MentorListFilterChoice: MentorListFilterChoice;
  MentorListFilterChoiceInput: MentorListFilterChoiceInput;
  MentorModuleField: MentorModuleField;
  MentorPreferencesField: MentorPreferencesField;
  MentorSchoolHistoryField: MentorSchoolHistoryField;
  MentorSnippetField: MentorSnippetField;
  MentorSocietyField: MentorSocietyField;
  MentorcardSnippetField: MentorcardSnippetField;
  MessageDto: MessageDto;
  MessageField: MessageField;
  MessageMetadata: MessageMetadata;
  MessagePublicBotMutation: MessagePublicBotMutation;
  Metadata: Metadata;
  MetricDataPoint: MetricDataPoint;
  Mutation: {};
  NewsFeedGroup: NewsFeedGroup;
  NotificationField: NotificationField;
  ObjectID: Scalars['ObjectID'];
  ObjectId: Scalars['ObjectId'];
  OffsetPagination: OffsetPagination;
  OpenDayField: OpenDayField;
  OpenDaysLoginField: OpenDaysLoginField;
  OpenDaysSignupField: OpenDaysSignupField;
  OpenTextFieldValueField: OpenTextFieldValueField;
  OrderByLiveSessionsInput: OrderByLiveSessionsInput;
  OrganisationBuddyLinkField: OrganisationBuddyLinkField;
  OrganisationField: OrganisationField;
  OriginField: OriginField;
  OutboxChatGroupField: OutboxChatGroupField;
  OutputField: OutputField;
  PageMentorField: PageMentorField;
  PaginatedAdminRecommendation: PaginatedAdminRecommendation;
  PaginatedApplicantInsightInput: PaginatedApplicantInsightInput;
  PaginatedApplicantInvite: PaginatedApplicantInvite;
  PaginatedApplicationInsightModel: PaginatedApplicationInsightModel;
  PaginatedChatMessages: PaginatedChatMessages;
  PaginatedChatRooms: PaginatedChatRooms;
  PaginatedEventPages: PaginatedEventPages;
  PaginatedExternalContents: PaginatedExternalContents;
  PaginatedLiveSessions: PaginatedLiveSessions;
  PaginatedVirtualEvents: PaginatedVirtualEvents;
  PaginationInput: PaginationInput;
  PasswordResetMobileMutation: PasswordResetMobileMutation;
  PasswordResetMobileMutationV2: PasswordResetMobileMutationV2;
  PayloadField: PayloadField;
  PinMessageMutation: PinMessageMutation;
  PinnedMessageField: PinnedMessageField;
  PinnedMessageSenderField: PinnedMessageSenderField;
  Poll: Poll;
  PollDto: PollDto;
  PollOption: PollOption;
  PollOptionDto: PollOptionDto;
  PopCardDegreeSnippetField: PopCardDegreeSnippetField;
  PopCardLevelSnippetField: PopCardLevelSnippetField;
  PopCardWidgetField: PopCardWidgetField;
  PopcardSnippetField: PopcardSnippetField;
  PostFields: PostFields;
  PreferencesInputType: PreferencesInputType;
  PreferencesObjectType: PreferencesObjectType;
  PreferencesOutputType: PreferencesOutputType;
  ProspectStageStatField: ProspectStageStatField;
  ProspectStageSummaryField: ProspectStageSummaryField;
  PublicMentorField: PublicMentorField;
  PublicUserField: PublicUserField;
  QssMetadata: QssMetadata;
  QssQuestion: QssQuestion;
  Query: {};
  QueryRecordingArgs: QueryRecordingArgs;
  QueryRecordingResponse: QueryRecordingResponse;
  QuestionField: QuestionField;
  RandomMentorsListField: RandomMentorsListField;
  RangeDateInput: RangeDateInput;
  Reaction: Reaction;
  ReactionDto: ReactionDto;
  ReadChatMutation: ReadChatMutation;
  Recommendation: Recommendation;
  RecommendationResourceType: RecommendationResourceType;
  RecordUserActivityMutation: RecordUserActivityMutation;
  RecordingConsent: RecordingConsent;
  RecordingDownloadResponse: RecordingDownloadResponse;
  RecordingFailureArgs: RecordingFailureArgs;
  RecordingFailureId: RecordingFailureId;
  RecordingFailureResponse: RecordingFailureResponse;
  RecordingListResponse: RecordingListResponse;
  RecordingModel: RecordingModel;
  RecordingResponse: RecordingResponse;
  RecordingUploadingResponse: RecordingUploadingResponse;
  RegisterToLiveEvent: RegisterToLiveEvent;
  RejectRequestedPublicGroupInput: RejectRequestedPublicGroupInput;
  RemoveApplicantInsightInput: RemoveApplicantInsightInput;
  RemoveBuddyInvitesMutation: RemoveBuddyInvitesMutation;
  RemoveDeviceMutation: RemoveDeviceMutation;
  RemoveMembersFromCommunityInputDTO: RemoveMembersFromCommunityInputDto;
  RemoveMentorInvitesMutation: RemoveMentorInvitesMutation;
  ReplyInputField: ReplyInputField;
  ReplyMessage: ReplyMessage;
  ReplyMessageDto: ReplyMessageDto;
  ReplyOutputField: ReplyOutputField;
  ReportAnalytics: ReportAnalytics;
  RequestAnalyticsExportMutation: RequestAnalyticsExportMutation;
  RequestPasswordResetMutation: RequestPasswordResetMutation;
  RequestPasswordSetMutation: RequestPasswordSetMutation;
  RequestedPublicGroup: RequestedPublicGroup;
  ResearchTimeInput: ResearchTimeInput;
  ResearchTimeLogResult: ResearchTimeLogResult;
  ResearchTimeResult: ResearchTimeResult;
  ResetPasswordMobileMutation: ResetPasswordMobileMutation;
  ResetPasswordMutation: ResetPasswordMutation;
  ResetPasswordMutationV2: ResetPasswordMutationV2;
  ResolveMessageDto: ResolveMessageDto;
  ResolveUniversityChatsMutation: ResolveUniversityChatsMutation;
  RichContentDto: RichContentDto;
  RichContentInputField: RichContentInputField;
  RichContentOutputField: RichContentOutputField;
  RichMessageContent: RichMessageContent;
  RunManageCommandMutation: RunManageCommandMutation;
  SatisfactionSurveyDTO: SatisfactionSurveyDto;
  SatisfactionSurveyEntity: SatisfactionSurveyEntity;
  SaveAppliedUsersInput: SaveAppliedUsersInput;
  SaveAppliedUsersModel: SaveAppliedUsersModel;
  SaveAssistantConversationEmailLeadInput: SaveAssistantConversationEmailLeadInput;
  SaveAssistantConversationFeedbackInput: SaveAssistantConversationFeedbackInput;
  SaveUniversityQuestionAnswerInput: SaveUniversityQuestionAnswerInput;
  SaveVisitedRecommendationInput: SaveVisitedRecommendationInput;
  SearchResult: SearchResult;
  SendAssistantMessageInput: SendAssistantMessageInput;
  SendCommunityVerificationCode: SendCommunityVerificationCode;
  SendFeedMessageMutation: SendFeedMessageMutation;
  SendLiveEventMessageMutation: SendLiveEventMessageMutation;
  SendMessageMutation: SendMessageMutation;
  SendOpenDayProspectReport: SendOpenDayProspectReport;
  SendResetPasswordEmail: SendResetPasswordEmail;
  SendVerificationCode: SendVerificationCode;
  SequenceFilterMentorListField: SequenceFilterMentorListField;
  SessionAnalytics: SessionAnalytics;
  SessionAttendeeAnalytics: SessionAttendeeAnalytics;
  SessionAttendeeData: SessionAttendeeData;
  SessionAttendees: SessionAttendees;
  SessionData: SessionData;
  SetActiveBlogTopicsMutation: SetActiveBlogTopicsMutation;
  SetAllNotificationsReadMutation: SetAllNotificationsReadMutation;
  SetArchivedContentBriefMutation: SetArchivedContentBriefMutation;
  SetArchivedDegreeLevelMutation: SetArchivedDegreeLevelMutation;
  SetArchivedYearOfStudyMutation: SetArchivedYearOfStudyMutation;
  SetBuddiesListFilters: SetBuddiesListFilters;
  SetChatGroupLabelsMutation: SetChatGroupLabelsMutation;
  SetDeviceTokenDTO: SetDeviceTokenDto;
  SetMentorFieldOrderMutation: SetMentorFieldOrderMutation;
  SetMentorListFilterSelection: SetMentorListFilterSelection;
  SetOnlineMutation: SetOnlineMutation;
  SetResolveChatGroupMutation: SetResolveChatGroupMutation;
  SetUserInterestsInput: SetUserInterestsInput;
  SetWillReplyMutation: SetWillReplyMutation;
  SettingsDTO: SettingsDto;
  SettingsObjectType: SettingsObjectType;
  ShowMentorInMarketplaceMutation: ShowMentorInMarketplaceMutation;
  SignUpsCount: SignUpsCount;
  SignedS3POSTFields: SignedS3PostFields;
  SignedS3URLField: SignedS3UrlField;
  SingleChoiceAttributeField: SingleChoiceAttributeField;
  SingleChoiceValueField: SingleChoiceValueField;
  SourceInputField: SourceInputField;
  SourceMetadataField: SourceMetadataField;
  SourceTrackingParamsField: SourceTrackingParamsField;
  StaffBuddycardSnippetField: StaffBuddycardSnippetField;
  StaffChatUserField: StaffChatUserField;
  StaffContactByUniversityIdInput: StaffContactByUniversityIdInput;
  StaffContactInput: StaffContactInput;
  StaffContactOutput: StaffContactOutput;
  StaffField: StaffField;
  StaffFieldsChatUserField: StaffFieldsChatUserField;
  StaffPopcardSnippetField: StaffPopcardSnippetField;
  StaffProfileInformation: StaffProfileInformation;
  StaffUserField: StaffUserField;
  StaffUserFieldsField: StaffUserFieldsField;
  StaffUserListField: StaffUserListField;
  StartRecordingArgs: StartRecordingArgs;
  StateStatField: StateStatField;
  StopRecordingArgs: StopRecordingArgs;
  SubTopicField: SubTopicField;
  SubTopicStatField: SubTopicStatField;
  SubTopicStatisticField: SubTopicStatisticField;
  SuggestionField: SuggestionField;
  SwitchUniversitiesField: SwitchUniversitiesField;
  SwitchUniversityField: SwitchUniversityField;
  SwitchUniversityListField: SwitchUniversityListField;
  Tag: Tag;
  TagField: TagField;
  TextInLocale: TextInLocale;
  TextInLocaleInput: TextInLocaleInput;
  TimeTakenField: TimeTakenField;
  ToggleCrmIntegrationSync: ToggleCrmIntegrationSync;
  ToggleCustomAttributeCrmSyncMutation: ToggleCustomAttributeCrmSyncMutation;
  ToggleDegreesActiveMutation: ToggleDegreesActiveMutation;
  ToggleFeatureAvailabilityMutation: ToggleFeatureAvailabilityMutation;
  TopCountryField: TopCountryField;
  TopDegreeField: TopDegreeField;
  TopDomesticRegionField: TopDomesticRegionField;
  TopRegionField: TopRegionField;
  TopSubTopicField: TopSubTopicField;
  TopTopicField: TopTopicField;
  TopicField: TopicField;
  TopicSnippetField: TopicSnippetField;
  TopicStatisticField: TopicStatisticField;
  TopicsStatField: TopicsStatField;
  TrackLiveEventMutation: TrackLiveEventMutation;
  TrackOpenDayMutation: TrackOpenDayMutation;
  TrendingTopicsField: TrendingTopicsField;
  UniversityAssistantConfiguration: UniversityAssistantConfiguration;
  UniversityAssistantConfigurationEventInput: UniversityAssistantConfigurationEventInput;
  UniversityAssistantConfigurationEventOutput: UniversityAssistantConfigurationEventOutput;
  UniversityAssistantConfigurationEventUrls: UniversityAssistantConfigurationEventUrls;
  UniversityAssistantConfigurationPromptContextInput: UniversityAssistantConfigurationPromptContextInput;
  UniversityAssistantConfigurationPromptContextOutput: UniversityAssistantConfigurationPromptContextOutput;
  UniversityBlockReportField: UniversityBlockReportField;
  UniversityBlockUsersMutation: UniversityBlockUsersMutation;
  UniversityBlogPostsField: UniversityBlogPostsField;
  UniversityChatInboxField: UniversityChatInboxField;
  UniversityConfigField: UniversityConfigField;
  UniversityContent: UniversityContent;
  UniversityContentInput: UniversityContentInput;
  UniversityFeatureField: UniversityFeatureField;
  UniversityField: UniversityField;
  UniversityInboxField: UniversityInboxField;
  UniversityMarketplaceField: UniversityMarketplaceField;
  UniversityMarketplaceInput: UniversityMarketplaceInput;
  UniversityOutput: UniversityOutput;
  UniversityQuestion: UniversityQuestion;
  UniversityQuestionAnswer: UniversityQuestionAnswer;
  UniversityQuestionAnswerInput: UniversityQuestionAnswerInput;
  UniversityQuestionAnswerInputItem: UniversityQuestionAnswerInputItem;
  UniversityQuestionByIdInput: UniversityQuestionByIdInput;
  UniversityQuestionContext: UniversityQuestionContext;
  UniversityQuestionContextInput: UniversityQuestionContextInput;
  UniversityQuestionInput: UniversityQuestionInput;
  UniversityQuestionOption: UniversityQuestionOption;
  UniversityScrapeIndexingOutput: UniversityScrapeIndexingOutput;
  UniversityScrapeIndexingQueryInput: UniversityScrapeIndexingQueryInput;
  UniversityScrapeInput: UniversityScrapeInput;
  UniversityScrapeManualInput: UniversityScrapeManualInput;
  UniversityScrapeOutput: UniversityScrapeOutput;
  UniversityScrapeStopOutput: UniversityScrapeStopOutput;
  UniversityScrapeTask: UniversityScrapeTask;
  UniversitySnippetField: UniversitySnippetField;
  UniversityStopCrawlInput: UniversityStopCrawlInput;
  UniversityUserField: UniversityUserField;
  UniversityUserPreferencesField: UniversityUserPreferencesField;
  UnpinMessageMutation: UnpinMessageMutation;
  UnreadCountField: UnreadCountField;
  UnreadMessageCountField: UnreadMessageCountField;
  UpdateApplicantMutation: UpdateApplicantMutation;
  UpdateApplicantPreferencesMutation: UpdateApplicantPreferencesMutation;
  UpdateCanonicalQuestion: UpdateCanonicalQuestion;
  UpdateChatRoomInput: UpdateChatRoomInput;
  UpdateCommunityApplicantSignupSourceMutation: UpdateCommunityApplicantSignupSourceMutation;
  UpdateDirectMessagingMembersForLiveEventMutation: UpdateDirectMessagingMembersForLiveEventMutation;
  UpdateEventPageInput: UpdateEventPageInput;
  UpdateExternalContentInput: UpdateExternalContentInput;
  UpdateFaqAnswer: UpdateFaqAnswer;
  UpdateHobsonsConnectIntegration: UpdateHobsonsConnectIntegration;
  UpdateIntegrationFieldMapping: UpdateIntegrationFieldMapping;
  UpdateLiveEventFeedSessionDataMutation: UpdateLiveEventFeedSessionDataMutation;
  UpdateLiveSessionAnyUserInput: UpdateLiveSessionAnyUserInput;
  UpdateLiveSessionInput: UpdateLiveSessionInput;
  UpdateManyUserMutation: UpdateManyUserMutation;
  UpdateMarketplaceApplicantMutation: UpdateMarketplaceApplicantMutation;
  UpdateMarketplaceApplicantUniversityLinkMutation: UpdateMarketplaceApplicantUniversityLinkMutation;
  UpdateMarketplaceMutation: UpdateMarketplaceMutation;
  UpdateMentorMutation: UpdateMentorMutation;
  UpdateMentorPreferences: UpdateMentorPreferences;
  UpdateMyUniversityLinkMutation: UpdateMyUniversityLinkMutation;
  UpdatePostStatusMutation: UpdatePostStatusMutation;
  UpdateRecordingLayoutArgs: UpdateRecordingLayoutArgs;
  UpdateResearchTimeMutation: UpdateResearchTimeMutation;
  UpdateStaffContactInput: UpdateStaffContactInput;
  UpdateStaffMutation: UpdateStaffMutation;
  UpdateSubTopicMutation: UpdateSubTopicMutation;
  UpdateTagMutation: UpdateTagMutation;
  UpdateTopicMutation: UpdateTopicMutation;
  UpdateUniversityBlockReportMutation: UpdateUniversityBlockReportMutation;
  UpdateUniversityConfigMutation: UpdateUniversityConfigMutation;
  UpdateUniversityUserMutation: UpdateUniversityUserMutation;
  UpdateUniversityUserPreferencesMutation: UpdateUniversityUserPreferencesMutation;
  UpdateUserMutation: UpdateUserMutation;
  UpdateUserPreferences: UpdateUserPreferences;
  UpdateUserProfile: UpdateUserProfile;
  UpdateVirtualEventInput: UpdateVirtualEventInput;
  UpdatedResults: UpdatedResults;
  Upload: Scalars['Upload'];
  UserCustomAttributesFilterField: UserCustomAttributesFilterField;
  UserDecisionStageInput: UserDecisionStageInput;
  UserDecisionStageOutput: UserDecisionStageOutput;
  UserDegreeField: UserDegreeField;
  UserDirectoryResult: UserDirectoryResult;
  UserField: UserField;
  UserIdsListField: UserIdsListField;
  UserInterest: UserInterest;
  UserListField: UserListField;
  UserLocalePreference: UserLocalePreference;
  UserLocalePreferenceInput: UserLocalePreferenceInput;
  UserNotInterestedStageInput: UserNotInterestedStageInput;
  UserPartialSearchFields: UserPartialSearchFields;
  UserPermissionsListField: UserPermissionsListField;
  UserPreferencesField: UserPreferencesField;
  UserProfile: UserProfile;
  UserRecommendation: UserRecommendation;
  UserReport: UserReport;
  UserReportModeratorInputDTO: UserReportModeratorInputDto;
  UserReportUserInputDTO: UserReportUserInputDto;
  UserTimeSheetExportField: UserTimeSheetExportField;
  UserTimeSpentBreakdown: UserTimeSpentBreakdown;
  UserTimeSpentStatsField: UserTimeSpentStatsField;
  UsersTimeSpentData: UsersTimeSpentData;
  VerifyAccount: VerifyAccount;
  VerifyCodeField: VerifyCodeField;
  VideoContent: VideoContent;
  VideoFile: VideoFile;
  VideoSession: VideoSession;
  VideoSessionField: VideoSessionField;
  VideoSessionToUpdateField: VideoSessionToUpdateField;
  VideoUploadLink: VideoUploadLink;
  VideoUploadLinkInput: VideoUploadLinkInput;
  VideoUploadPresignedFields: VideoUploadPresignedFields;
  VideoUrl: VideoUrl;
  ViewBlogMutation: ViewBlogMutation;
  VirtualEvent: VirtualEvent;
  WidgetConversationDto: WidgetConversationDto;
  WidgetSnippetField: WidgetSnippetField;
  WidgetUrlField: WidgetUrlField;
  WidgetUrlsInput: WidgetUrlsInput;
  WillReplyField: WillReplyField;
  YearOfStudyField: YearOfStudyField;
  pinMessageDTO: PinMessageDto;
  unpinMessageDTO: UnpinMessageDto;
};

export type ActivateApplicantsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivateApplicantsMutation'] = ResolversParentTypes['ActivateApplicantsMutation']> = {
  updateCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivateMentorsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivateMentorsMutation'] = ResolversParentTypes['ActivateMentorsMutation']> = {
  updateCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivityFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivityField'] = ResolversParentTypes['ActivityField']> = {
  activitySource?: Resolver<Maybe<ResolversTypes['ActivitySourceField']>, ParentType, ContextType>;
  app?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ActivitySourceFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActivitySourceField'] = ResolversParentTypes['ActivitySourceField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddAttributeOptionMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddAttributeOptionMutation'] = ResolversParentTypes['AddAttributeOptionMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddBotToChatGroupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddBotToChatGroupMutation'] = ResolversParentTypes['AddBotToChatGroupMutation']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddCustomAttributeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddCustomAttributeMutation'] = ResolversParentTypes['AddCustomAttributeMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddDegreeLevelsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddDegreeLevelsMutation'] = ResolversParentTypes['AddDegreeLevelsMutation']> = {
  degreeLevels?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeLevelField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddDegreesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddDegreesMutation'] = ResolversParentTypes['AddDegreesMutation']> = {
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddHobsonsConnectIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddHobsonsConnectIntegration'] = ResolversParentTypes['AddHobsonsConnectIntegration']> = {
  integration?: Resolver<Maybe<ResolversTypes['CampusManagementConnectIntegrationField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddLocalesToAppResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddLocalesToApp'] = ResolversParentTypes['AddLocalesToApp']> = {
  appLocales?: Resolver<Maybe<Array<Maybe<ResolversTypes['AppLocaleField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddResearchTimeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddResearchTimeMutation'] = ResolversParentTypes['AddResearchTimeMutation']> = {
  result?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddYearOfStudyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddYearOfStudyMutation'] = ResolversParentTypes['AddYearOfStudyMutation']> = {
  yearOfStudy?: Resolver<Maybe<Array<Maybe<ResolversTypes['YearOfStudyField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminDeleteApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminDeleteApplicantMutation'] = ResolversParentTypes['AdminDeleteApplicantMutation']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminRecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminRecommendation'] = ResolversParentTypes['AdminRecommendation']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  cohort?: Resolver<Maybe<ResolversTypes['Cohort']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  forDecisionStage?: Resolver<ResolversTypes['DecisionStageEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  resourceType?: Resolver<ResolversTypes['RecommendationResourceType'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminRecommendationCountPerDecisionStageResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminRecommendationCountPerDecisionStage'] = ResolversParentTypes['AdminRecommendationCountPerDecisionStage']> = {
  forDecisionStage?: Resolver<ResolversTypes['DecisionStageEnum'], ParentType, ContextType>;
  recommendationCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminUpdateMentorMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminUpdateMentorMutation'] = ResolversParentTypes['AdminUpdateMentorMutation']> = {
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdminUserField'] = ResolversParentTypes['AdminUserField']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AgeVerificationInfoFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AgeVerificationInfoField'] = ResolversParentTypes['AgeVerificationInfoField']> = {
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  consentAgeForCountry?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  isMinor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlertFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlertField'] = ResolversParentTypes['AlertField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllPostsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllPostsField'] = ResolversParentTypes['AllPostsField']> = {
  blogPosts?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogPostField']>>>, ParentType, ContextType>;
  blogPostsAuthors?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  blogPostsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blogPostsDegrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  blogPostsTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmbassadorFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmbassadorField'] = ResolversParentTypes['AmbassadorField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reply?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmbassadorProfileInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmbassadorProfileInformation'] = ResolversParentTypes['AmbassadorProfileInformation']> = {
  accountRole?: Resolver<ResolversTypes['Buddies'], ParentType, ContextType>;
  bio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  countryName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  degreeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profilePhoto?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmbassadorRecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmbassadorRecommendation'] = ResolversParentTypes['AmbassadorRecommendation']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ambassadorId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  forDecisionStage?: Resolver<Maybe<ResolversTypes['DecisionStageEnum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  resourceType?: Resolver<ResolversTypes['AmbassadorRecommendationResourceType'], ParentType, ContextType>;
  resourceURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sharedWithUser?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AmbassadorRecommendationResourceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AmbassadorRecommendationResourceType'] = ResolversParentTypes['AmbassadorRecommendationResourceType']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['AmbassadorRecommendationResourceTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Analytics'] = ResolversParentTypes['Analytics']> = {
  attendees?: Resolver<ResolversTypes['Attendees'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  messagesSent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  signups?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  virtualEventId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsBuddyFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsBuddyField'] = ResolversParentTypes['AnalyticsBuddyField']> = {
  buddyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsDashboardAuthFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsDashboardAuthField'] = ResolversParentTypes['AnalyticsDashboardAuthField']> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnalyticsMentorFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnalyticsMentorField'] = ResolversParentTypes['AnalyticsMentorField']> = {
  data?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType>;
  degree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentorId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnyChatUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnyChatUserField'] = ResolversParentTypes['AnyChatUserField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatIntroMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorDegreeField']>>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headlineInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inboxInfoOne?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inboxInfoTwo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isEnterprise?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<AnyChatUserFieldProfilePhotoArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnyUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AnyUserField'] = ResolversParentTypes['AnyUserField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chatToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorDegreeField']>>>, ParentType, ContextType>;
  domesticRegion?: Resolver<Maybe<ResolversTypes['DomesticRegionField']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  prospectWelcomeMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  staffFields?: Resolver<Maybe<ResolversTypes['StaffUserField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppLocaleFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppLocaleField'] = ResolversParentTypes['AppLocaleField']> = {
  locales?: Resolver<Maybe<Array<Maybe<ResolversTypes['Locale']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityLocalePreferenceApplies?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantBroadcastMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantBroadcastMessageMutation'] = ResolversParentTypes['ApplicantBroadcastMessageMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantCollectionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantCollectionField'] = ResolversParentTypes['ApplicantCollectionField']> = {
  fieldName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCustomField?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantConversationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantConversationField'] = ResolversParentTypes['ApplicantConversationField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantCountFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantCountField'] = ResolversParentTypes['ApplicantCountField']> = {
  applicantCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantField'] = ResolversParentTypes['ApplicantField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ageVerificationInfo?: Resolver<Maybe<ResolversTypes['AgeVerificationInfoField']>, ParentType, ContextType>;
  applicantUniversities?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantUniversityLinkField']>>>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['DegreeLevelField']>, ParentType, ContextType>;
  degreesInterested?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasAcceptedMarketing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasAppliedToCurrentUniversity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasReadChatRules?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkWithUniversity?: Resolver<Maybe<ResolversTypes['ApplicantUniversityLinkField']>, ParentType, ContextType, RequireFields<ApplicantFieldLinkWithUniversityArgs, never>>;
  localePreference?: Resolver<Maybe<ResolversTypes['UserLocalePreference']>, ParentType, ContextType>;
  marketplaces?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantMarketplaceObjectField']>>>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['ApplicantPreferencesField']>, ParentType, ContextType>;
  primaryApp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolHistory?: Resolver<Maybe<ResolversTypes['ApplicantSchoolHistory']>, ParentType, ContextType>;
  signUpLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unibuddyServicesConsentAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  universitiesApplied?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantUniversityLinkField']>>>, ParentType, ContextType>;
  universitiesInterested?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantInboxField'] = ResolversParentTypes['ApplicantInboxField']> = {
  botChatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  chatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType>;
  universityClearingChatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantInvite'] = ResolversParentTypes['ApplicantInvite']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantLabelFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantLabelField'] = ResolversParentTypes['ApplicantLabelField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantMarketplaceObjectFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantMarketplaceObjectField'] = ResolversParentTypes['ApplicantMarketplaceObjectField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userData?: Resolver<Maybe<ResolversTypes['MarketplaceApplicantUserData']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantPreferencesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantPreferencesField'] = ResolversParentTypes['ApplicantPreferencesField']> = {
  newMessageNotifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantSchoolHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantSchoolHistory'] = ResolversParentTypes['ApplicantSchoolHistory']> = {
  highSchool?: Resolver<Maybe<ResolversTypes['HighSchoolField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantSignupStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantSignupStats'] = ResolversParentTypes['ApplicantSignupStats']> = {
  xAxis?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  yAxis?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantUniversityLinkFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantUniversityLinkField'] = ResolversParentTypes['ApplicantUniversityLinkField']> = {
  applied?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeValueField']>>>, ParentType, ContextType, RequireFields<ApplicantUniversityLinkFieldCustomAttributesArgs, never>>;
  dateOfEntry?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['DegreeLevelField']>, ParentType, ContextType>;
  degreesInterested?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  delinked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasAcceptedMarketing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setupComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantUserField'] = ResolversParentTypes['ApplicantUserField']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantsAppliedStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantsAppliedStats'] = ResolversParentTypes['ApplicantsAppliedStats']> = {
  applied?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  notApplied?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  notReplied?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicantsMessagedCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicantsMessagedCount'] = ResolversParentTypes['ApplicantsMessagedCount']> = {
  messagedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationField'] = ResolversParentTypes['ApplicationField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationInsightModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['ApplicationInsightModel'] = ResolversParentTypes['ApplicationInsightModel']> = {
  entryDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalApplied?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  university?: Resolver<ResolversTypes['UniversityField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppliedUsersCountModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppliedUsersCountModel'] = ResolversParentTypes['AppliedUsersCountModel']> = {
  appliedUsersCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantConversationFeedbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssistantConversationFeedback'] = ResolversParentTypes['AssistantConversationFeedback']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  rating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantConversationHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssistantConversationHistory'] = ResolversParentTypes['AssistantConversationHistory']> = {
  feedback?: Resolver<Maybe<ResolversTypes['AssistantConversationFeedback']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leadGenerated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messages?: Resolver<Array<ResolversTypes['AssistantConversationMessage']>, ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantConversationLeadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssistantConversationLead'] = ResolversParentTypes['AssistantConversationLead']> = {
  conversationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  marketingConsent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ubConsent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantConversationMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssistantConversationMessage'] = ResolversParentTypes['AssistantConversationMessage']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  feedback?: Resolver<Maybe<ResolversTypes['AssistantMessageFeedback']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['MessageMetadata']>, ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['SenderRole'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssistantMessageFeedbackResolvers<ContextType = any, ParentType extends ResolversParentTypes['AssistantMessageFeedback'] = ResolversParentTypes['AssistantMessageFeedback']> = {
  freeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AssistantMessageFeedbackType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AttachmentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttachmentField'] = ResolversParentTypes['AttachmentField']> = {
  payload?: Resolver<Maybe<ResolversTypes['PayloadField']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeeAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AttendeeAnalytics'] = ResolversParentTypes['AttendeeAnalytics']> = {
  accountRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  virtualEventId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttendeesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attendees'] = ResolversParentTypes['Attendees']> = {
  activeApplicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  applicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthField'] = ResolversParentTypes['AuthField']> = {
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['DeviceField']>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthorSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthorSnippetField'] = ResolversParentTypes['AuthorSnippetField']> = {
  author?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCustomAttributeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCustomAttributeField'] = ResolversParentTypes['BaseCustomAttributeField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSyncedWithCrm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeOptionField']>>>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockUserMutation'] = ResolversParentTypes['BlockUserMutation']> = {
  objectsBlockedIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockUserMutationV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockUserMutationV2'] = ResolversParentTypes['BlockUserMutationV2']> = {
  objectsBlockedIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockerFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlockerField'] = ResolversParentTypes['BlockerField']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogCommentFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlogCommentField'] = ResolversParentTypes['BlogCommentField']> = {
  author?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogPostFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlogPostField'] = ResolversParentTypes['BlogPostField']> = {
  approved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogCommentField']>>>, ParentType, ContextType>;
  coverImages?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<BlogPostFieldCoverImagesArgs, never>>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  flaggedByUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<BlogPostFieldImageArgs, never>>;
  lastUpdatedBy?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  modified?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  numberOfViews?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ogShareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readingTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['BlogStatus']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['BlogTopicField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  videoContent?: Resolver<Maybe<ResolversTypes['VideoContent']>, ParentType, ContextType>;
  wordCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  youtubeVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogTopicFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlogTopicField'] = ResolversParentTypes['BlogTopicField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlogcardSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlogcardSnippetField'] = ResolversParentTypes['BlogcardSnippetField']> = {
  authorSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['AuthorSnippetField']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  topicSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopicSnippetField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BotUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BotUserField'] = ResolversParentTypes['BotUserField']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddiesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddiesField'] = ResolversParentTypes['BuddiesField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  randomizeListSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddiesFilterOptionsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddiesFilterOptionsField'] = ResolversParentTypes['BuddiesFilterOptionsField']> = {
  customAttributesFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributesFilterField']>>>, ParentType, ContextType>;
  departments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddiesListFilterFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddiesListFilterField'] = ResolversParentTypes['BuddiesListFilterField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddyApplicantChatGroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddyApplicantChatGroupField'] = ResolversParentTypes['BuddyApplicantChatGroupField']> = {
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creationSource?: Resolver<Maybe<ResolversTypes['CreationSource']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unanswered?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddyApplicantChatGroupFieldV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddyApplicantChatGroupFieldV2'] = ResolversParentTypes['BuddyApplicantChatGroupFieldV2']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  creationSource?: Resolver<Maybe<ResolversTypes['CreationSource']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastMessageSentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastMessageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  staff?: Resolver<Maybe<ResolversTypes['StaffField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddyApplicantChatInterventionBuddyFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddyApplicantChatInterventionBuddyField'] = ResolversParentTypes['BuddyApplicantChatInterventionBuddyField']> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<BuddyApplicantChatInterventionBuddyFieldProfilePhotoArgs, never>>;
  unansweredCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddyFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddyField'] = ResolversParentTypes['BuddyField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeValueField']>>>, ParentType, ContextType, RequireFields<BuddyFieldCustomAttributesArgs, never>>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<BuddyFieldProfilePhotoArgs, never>>;
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  staffFields?: Resolver<Maybe<ResolversTypes['StaffUserFieldsField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddyInviteFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddyInviteField'] = ResolversParentTypes['BuddyInviteField']> = {
  buddyInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyInviteListField']>>>, ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BuddyInviteListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuddyInviteListField'] = ResolversParentTypes['BuddyInviteListField']> = {
  accepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  notFound?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkApplicantFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkApplicantField'] = ResolversParentTypes['BulkApplicantField']> = {
  acceptMarketing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  applied?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  blockedByUniversity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributesField']>>>, ParentType, ContextType>;
  dateOfEntry?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['DegreeLevelField']>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  degreesInterested?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  domesticRegion?: Resolver<Maybe<ResolversTypes['DomesticRegionField']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labels?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantLabelField']>>>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryApp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolHistory?: Resolver<Maybe<ResolversTypes['ApplicantSchoolHistory']>, ParentType, ContextType>;
  signUpLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signupSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceTrackingParams?: Resolver<Maybe<ResolversTypes['SourceTrackingParamsField']>, ParentType, ContextType>;
  universitiesInterested?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkOptOutMarketplaceUniversityMarketingMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkOptOutMarketplaceUniversityMarketingMutation'] = ResolversParentTypes['BulkOptOutMarketplaceUniversityMarketingMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ButtonFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ButtonField'] = ResolversParentTypes['ButtonField']> = {
  inline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmApplicantFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CRMApplicantField'] = ResolversParentTypes['CRMApplicantField']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CRMField'] = ResolversParentTypes['CRMField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  additionalInfo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crmMetaData?: Resolver<Maybe<ResolversTypes['CRMMetaDataField']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmIntegrationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CRMIntegrationField'] = ResolversParentTypes['CRMIntegrationField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  connection?: Resolver<Maybe<ResolversTypes['CrmConnectionField']>, ParentType, ContextType>;
  crm?: Resolver<Maybe<ResolversTypes['CRMField']>, ParentType, ContextType>;
  crmApplicantRecords?: Resolver<Maybe<Array<Maybe<ResolversTypes['CrmApplicantRecordField']>>>, ParentType, ContextType, RequireFields<CrmIntegrationFieldCrmApplicantRecordsArgs, never>>;
  crmIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldMapping?: Resolver<Maybe<Array<Maybe<ResolversTypes['FieldMappingField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['CrmSyncJobField']>>>, ParentType, ContextType>;
  lastSyncJob?: Resolver<Maybe<ResolversTypes['CrmSyncJobField']>, ParentType, ContextType>;
  syncErrorCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  syncSuccessCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmMetaDataFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CRMMetaDataField'] = ResolversParentTypes['CRMMetaDataField']> = {
  additionalInfoLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  additionalInfoText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoData?: Resolver<Maybe<Array<Maybe<ResolversTypes['LogoDataField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CsvUploadAmbassadorFieldsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CSVUploadAmbassadorFieldsMutation'] = ResolversParentTypes['CSVUploadAmbassadorFieldsMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CsvUploadApplicantsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CSVUploadApplicantsMutation'] = ResolversParentTypes['CSVUploadApplicantsMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampusManagementConnectIntegrationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CampusManagementConnectIntegrationField'] = ResolversParentTypes['CampusManagementConnectIntegrationField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  clientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webServiceUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CanonicalQuestionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CanonicalQuestionField'] = ResolversParentTypes['CanonicalQuestionField']> = {
  answers?: Resolver<Maybe<Array<Maybe<ResolversTypes['FaqAnswerField']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subTopic?: Resolver<Maybe<ResolversTypes['SubTopicField']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarouselSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CarouselSnippetField'] = ResolversParentTypes['CarouselSnippetField']> = {
  defaultSnippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatConversationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatConversation'] = ResolversParentTypes['ChatConversation']> = {
  communityChatDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityChatDynamicLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityChatMembers?: Resolver<Maybe<Array<ResolversTypes['UserField']>>, ParentType, ContextType, RequireFields<ChatConversationCommunityChatMembersArgs, 'offsetPagination'>>;
  communityChatTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDirect?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isNewsFeed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPrivate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  labels?: Resolver<Maybe<Array<ResolversTypes['Labels']>>, ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['ChatMessage']>, ParentType, ContextType>;
  members?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType>;
  mutedBy?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  pinnedMessages?: Resolver<Array<ResolversTypes['ChatMessage']>, ParentType, ContextType>;
  pinnedMessagesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['ConversationTag']>>, ParentType, ContextType>;
  totalMemberCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unreadMessageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatGroupAndMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatGroupAndMessages'] = ResolversParentTypes['ChatGroupAndMessages']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messages?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType>;
  offsetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatGroupAuxiliaryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatGroupAuxiliaryField'] = ResolversParentTypes['ChatGroupAuxiliaryField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  labels?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupLabelField']>>>, ParentType, ContextType>;
  willReply?: Resolver<Maybe<ResolversTypes['WillReplyField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatGroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatGroupField'] = ResolversParentTypes['ChatGroupField']> = {
  applicant?: Resolver<Maybe<ResolversTypes['AnyChatUserField']>, ParentType, ContextType>;
  aux?: Resolver<Maybe<ResolversTypes['ChatGroupAuxiliaryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creationSource?: Resolver<Maybe<ResolversTypes['CreationSource']>, ParentType, ContextType>;
  delinked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['LastMessageField']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnyUserField']>>>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  messages?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType, RequireFields<ChatGroupFieldMessagesArgs, never>>;
  messagesForModeration?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType, RequireFields<ChatGroupFieldMessagesForModerationArgs, never>>;
  opponent?: Resolver<Maybe<ResolversTypes['AnyChatUserField']>, ParentType, ContextType>;
  pinnedMessages?: Resolver<Maybe<Array<Maybe<ResolversTypes['PinnedMessageField']>>>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sendbirdChannelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unansweredByMentor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  unreadMessageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatGroupLabelFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatGroupLabelField'] = ResolversParentTypes['ChatGroupLabelField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatInboxField'] = ResolversParentTypes['ChatInboxField']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['InboxItemLastMessageField']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatUserField']>>>, ParentType, ContextType>;
  unreadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatMessage'] = ResolversParentTypes['ChatMessage']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isSenderBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  likes?: Resolver<Array<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  likesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['Reaction']>>, ParentType, ContextType>;
  resolved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  richContent?: Resolver<Maybe<ResolversTypes['RichMessageContent']>, ParentType, ContextType>;
  sender?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatPermission'] = ResolversParentTypes['ChatPermission']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatReport'] = ResolversParentTypes['ChatReport']> = {
  actionTaken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  community?: Resolver<ResolversTypes['Community'], ParentType, ContextType>;
  conversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType>;
  moderationTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  moderator?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  moderatorComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modificationTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  reportee?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  reporter?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  reporterComment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reporterReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resolved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  university?: Resolver<ResolversTypes['UniversityField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatRoomResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatRoom'] = ResolversParentTypes['ChatRoom']> = {
  chatRoomAnalytics?: Resolver<ResolversTypes['ChatRoomAnalytics'], ParentType, ContextType>;
  conversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatRoomAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatRoomAnalytics'] = ResolversParentTypes['ChatRoomAnalytics']> = {
  chatRoomAttendees?: Resolver<ResolversTypes['ChatRoomAttendees'], ParentType, ContextType>;
  chatRoomId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  chatRoomMessagesSent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  virtualEventId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatRoomAttendeeAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatRoomAttendeeAnalytics'] = ResolversParentTypes['ChatRoomAttendeeAnalytics']> = {
  accountRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  chatRoomId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatRoomAttendeesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatRoomAttendees'] = ResolversParentTypes['ChatRoomAttendees']> = {
  activeApplicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  applicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatSummaryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatSummaryField'] = ResolversParentTypes['ChatSummaryField']> = {
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatSummary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  generatedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  prospectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalMessages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatUserField'] = ResolversParentTypes['ChatUserField']> = {
  __resolveType: TypeResolveFn<'ApplicantUserField' | 'MentorChatUserField' | 'StaffChatUserField', ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type CheckEmailMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckEmailMutation'] = ResolversParentTypes['CheckEmailMutation']> = {
  emailExists?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  passwordSetEmailSent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildOrganisationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChildOrganisationField'] = ResolversParentTypes['ChildOrganisationField']> = {
  hasOrganisationAccess?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassificationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClassificationField'] = ResolversParentTypes['ClassificationField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topSubTopic?: Resolver<Maybe<ResolversTypes['TopSubTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CloneLiveEventAndChannelsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CloneLiveEventAndChannelsMutation'] = ResolversParentTypes['CloneLiveEventAndChannelsMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CohortResolvers<ContextType = any, ParentType extends ResolversParentTypes['Cohort'] = ResolversParentTypes['Cohort']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  areaOfStudies?: Resolver<Maybe<Array<ResolversTypes['DegreeField']>>, ParentType, ContextType>;
  areaOfStudyIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  countries?: Resolver<Maybe<Array<ResolversTypes['CountryField']>>, ParentType, ContextType>;
  countryIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  degreeLevelIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  degreeLevels?: Resolver<Maybe<Array<ResolversTypes['DegreeLevelField']>>, ParentType, ContextType>;
  domesticRegionIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  domesticRegions?: Resolver<Maybe<Array<ResolversTypes['DomesticRegionField']>>, ParentType, ContextType>;
  expectedEntryFrom?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expectedEntryTo?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommentMutation'] = ResolversParentTypes['CommentMutation']> = {
  comment?: Resolver<Maybe<ResolversTypes['BlogCommentField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Community'] = ResolversParentTypes['Community']> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blockedMembers?: Resolver<Maybe<Array<ResolversTypes['UserField']>>, ParentType, ContextType>;
  communityChatGroups?: Resolver<Array<ResolversTypes['CommunityChatGroup']>, ParentType, ContextType>;
  communityMemberCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  institutions?: Resolver<Maybe<Array<ResolversTypes['UniversityField']>>, ParentType, ContextType>;
  inviteLinks?: Resolver<Array<ResolversTypes['InviteLinkDto']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<ResolversTypes['UserField']>>, ParentType, ContextType>;
  newsFeedGroups?: Resolver<Maybe<Array<ResolversTypes['NewsFeedGroup']>>, ParentType, ContextType>;
  requestedPublicGroups?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['SettingsObjectType']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  welcomeText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityChatGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityChatGroup'] = ResolversParentTypes['CommunityChatGroup']> = {
  communityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  conversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dynamicLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isDirect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  labels?: Resolver<Maybe<Array<ResolversTypes['Labels']>>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<ResolversTypes['UserField']>>, ParentType, ContextType>;
  mutedBy?: Resolver<Array<ResolversTypes['ID']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityField'] = ResolversParentTypes['CommunityField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityInviteResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityInviteResponse'] = ResolversParentTypes['CommunityInviteResponse']> = {
  accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  communityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityInvitesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityInvites'] = ResolversParentTypes['CommunityInvites']> = {
  accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invitedEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  usedInviteLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityJoinResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityJoinResponse'] = ResolversParentTypes['CommunityJoinResponse']> = {
  alreadyJoined?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  community?: Resolver<ResolversTypes['Community'], ParentType, ContextType>;
  joined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompletedEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompletedEvent'] = ResolversParentTypes['CompletedEvent']> = {
  activeAttendees?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  attendees?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messagesSent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newSignUps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompletedEventsOverviewResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompletedEventsOverview'] = ResolversParentTypes['CompletedEventsOverview']> = {
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompletedEvent']>>>, ParentType, ContextType>;
  eventsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContentBriefFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContentBriefField'] = ResolversParentTypes['ContentBriefField']> = {
  brief?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isArchived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isValuable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maxDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sourceMetrics?: Resolver<Maybe<Array<Maybe<ResolversTypes['MetricDataPoint']>>>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContextResolvers<ContextType = any, ParentType extends ResolversParentTypes['Context'] = ResolversParentTypes['Context']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContextType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationContextChatGroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationContextChatGroupField'] = ResolversParentTypes['ConversationContextChatGroupField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationContextFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationContextField'] = ResolversParentTypes['ConversationContextField']> = {
  ambassador?: Resolver<Maybe<ResolversTypes['AmbassadorField']>, ParentType, ContextType>;
  applicantMsg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ConversationContextChatGroupField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationInboxResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationInbox'] = ResolversParentTypes['ConversationInbox']> = {
  contexts?: Resolver<Array<ResolversTypes['Context']>, ParentType, ContextType>;
  conversations?: Resolver<Array<ResolversTypes['ChatConversation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConversationTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationTag'] = ResolversParentTypes['ConversationTag']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CookieConsentOutputDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['CookieConsentOutputDTO'] = ResolversParentTypes['CookieConsentOutputDTO']> = {
  preferences?: Resolver<ResolversTypes['PreferencesOutputType'], ParentType, ContextType>;
  uniqueId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountPerEpochResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountPerEpoch'] = ResolversParentTypes['CountPerEpoch']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  epoch?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryAnalyticsValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryAnalyticsValue'] = ResolversParentTypes['CountryAnalyticsValue']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryField'] = ResolversParentTypes['CountryField']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subRegion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountrySnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountrySnippetField'] = ResolversParentTypes['CountrySnippetField']> = {
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryStatFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryStatField'] = ResolversParentTypes['CountryStatField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CourseSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CourseSnippetField'] = ResolversParentTypes['CourseSnippetField']> = {
  degreeSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeSnippetField']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAlertMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAlertMutation'] = ResolversParentTypes['CreateAlertMutation']> = {
  alert?: Resolver<Maybe<ResolversTypes['AlertField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateApplicantMutation'] = ResolversParentTypes['CreateApplicantMutation']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateApplicantMutationV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateApplicantMutationV2'] = ResolversParentTypes['CreateApplicantMutationV2']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateBlogTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateBlogTopicMutation'] = ResolversParentTypes['CreateBlogTopicMutation']> = {
  blogTopic?: Resolver<Maybe<ResolversTypes['BlogTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateBuddyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateBuddyMutation'] = ResolversParentTypes['CreateBuddyMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  existingUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  noInvite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCanonicalQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCanonicalQuestion'] = ResolversParentTypes['CreateCanonicalQuestion']> = {
  question?: Resolver<Maybe<ResolversTypes['CanonicalQuestionField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCommunityApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCommunityApplicantMutation'] = ResolversParentTypes['CreateCommunityApplicantMutation']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCommunityApplicantMutationV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCommunityApplicantMutationV2'] = ResolversParentTypes['CreateCommunityApplicantMutationV2']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateCrmServiceUniversityConfigMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCrmServiceUniversityConfigMutation'] = ResolversParentTypes['CreateCrmServiceUniversityConfigMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityConfiguration?: Resolver<Maybe<ResolversTypes['CrmServiceUniversityConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateExternalEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateExternalEventMutation'] = ResolversParentTypes['CreateExternalEventMutation']> = {
  externalEvent?: Resolver<Maybe<ResolversTypes['ExternalEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFaqAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateFaqAnswer'] = ResolversParentTypes['CreateFaqAnswer']> = {
  faqAnswer?: Resolver<Maybe<ResolversTypes['FaqAnswerField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateFresherMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateFresherMutation'] = ResolversParentTypes['CreateFresherMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fresher?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLiveEventDetailsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateLiveEventDetailsMutation'] = ResolversParentTypes['CreateLiveEventDetailsMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLiveEventFeedMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateLiveEventFeedMutation'] = ResolversParentTypes['CreateLiveEventFeedMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEventFeed?: Resolver<Maybe<ResolversTypes['LiveEventChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLiveEventFeedSessionsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateLiveEventFeedSessionsMutation'] = ResolversParentTypes['CreateLiveEventFeedSessionsMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEventFeed?: Resolver<Maybe<ResolversTypes['LiveEventChatGroupField']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateLiveEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateLiveEventMutation'] = ResolversParentTypes['CreateLiveEventMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormFieldValidationError']>>>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMarketplaceApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMarketplaceApplicantMutation'] = ResolversParentTypes['CreateMarketplaceApplicantMutation']> = {
  authenticatedApplicant?: Resolver<Maybe<ResolversTypes['AuthField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMarketplaceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMarketplaceMutation'] = ResolversParentTypes['CreateMarketplaceMutation']> = {
  marketplace?: Resolver<Maybe<ResolversTypes['MarketplaceField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateMentorMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMentorMutation'] = ResolversParentTypes['CreateMentorMutation']> = {
  existingUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  noInvite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOpenDayMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOpenDayMutation'] = ResolversParentTypes['CreateOpenDayMutation']> = {
  openDay?: Resolver<Maybe<ResolversTypes['OpenDayField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrganisationBuddyLinkMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOrganisationBuddyLinkMutation'] = ResolversParentTypes['CreateOrganisationBuddyLinkMutation']> = {
  organisationBuddyLink?: Resolver<Maybe<ResolversTypes['OrganisationBuddyLinkField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateOrganisationMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateOrganisationMutation'] = ResolversParentTypes['CreateOrganisationMutation']> = {
  organisation?: Resolver<Maybe<ResolversTypes['OrganisationField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreatePostMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreatePostMutation'] = ResolversParentTypes['CreatePostMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  post?: Resolver<Maybe<ResolversTypes['BlogPostField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateSubTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateSubTopicMutation'] = ResolversParentTypes['CreateSubTopicMutation']> = {
  subTopic?: Resolver<Maybe<ResolversTypes['SubTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTagMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTagMutation'] = ResolversParentTypes['CreateTagMutation']> = {
  tag?: Resolver<Maybe<ResolversTypes['TagField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTopicMutation'] = ResolversParentTypes['CreateTopicMutation']> = {
  topic?: Resolver<Maybe<ResolversTypes['TopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUniversityBlockReportMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUniversityBlockReportMutation'] = ResolversParentTypes['CreateUniversityBlockReportMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityBlockReport?: Resolver<Maybe<ResolversTypes['UniversityBlockReportField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUniversityMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUniversityMutation'] = ResolversParentTypes['CreateUniversityMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUniversityUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUniversityUserMutation'] = ResolversParentTypes['CreateUniversityUserMutation']> = {
  universityUser?: Resolver<Maybe<ResolversTypes['UniversityUserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUserMutation'] = ResolversParentTypes['CreateUserMutation']> = {
  user?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreationSourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreationSource'] = ResolversParentTypes['CreationSource']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmApplicantRecordFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmApplicantRecordField'] = ResolversParentTypes['CrmApplicantRecordField']> = {
  applicant?: Resolver<Maybe<ResolversTypes['CRMApplicantField']>, ParentType, ContextType>;
  crmApplicantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSync?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmAttributeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmAttributeField'] = ResolversParentTypes['CrmAttributeField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmConnectionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmConnectionField'] = ResolversParentTypes['CrmConnectionField']> = {
  connectionError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isConnected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmDataFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmDataField'] = ResolversParentTypes['CrmDataField']> = {
  deprecated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  feature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCustomField?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sampleValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmFieldSyncStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmFieldSyncStatus'] = ResolversParentTypes['CrmFieldSyncStatus']> = {
  fieldName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSyncActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmServiceUniversityConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmServiceUniversityConfig'] = ResolversParentTypes['CrmServiceUniversityConfig']> = {
  allFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['CrmDataField']>>>, ParentType, ContextType>;
  connectorEndpoint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  connectorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataFormatConfigs?: Resolver<Maybe<Array<Maybe<ResolversTypes['DataFormatConfig']>>>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['CrmDataField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSyncActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  universityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universitySlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zapierWebhook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CrmSyncJobFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CrmSyncJobField'] = ResolversParentTypes['CrmSyncJobField']> = {
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  synced?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  toSync?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrentDecisionStageOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['CurrentDecisionStageOutput'] = ResolversParentTypes['CurrentDecisionStageOutput']> = {
  decisionStage?: Resolver<Maybe<ResolversTypes['DecisionStageEnum']>, ParentType, ContextType>;
  decisionStageLocation?: Resolver<Maybe<ResolversTypes['DecisionStageLocationEnum']>, ParentType, ContextType>;
  notInterestedReason?: Resolver<Maybe<ResolversTypes['NotInterestedReasonEnum']>, ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomAttributeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomAttributeField'] = ResolversParentTypes['CustomAttributeField']> = {
  __resolveType: TypeResolveFn<'BaseCustomAttributeField' | 'SingleChoiceAttributeField', ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSyncedWithCrm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeOptionField']>>>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type CustomAttributeOptionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomAttributeOptionField'] = ResolversParentTypes['CustomAttributeOptionField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomAttributeValueFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomAttributeValueField'] = ResolversParentTypes['CustomAttributeValueField']> = {
  __resolveType: TypeResolveFn<'DateValueField' | 'OpenTextFieldValueField' | 'SingleChoiceValueField', ParentType, ContextType>;
  attribute?: Resolver<Maybe<ResolversTypes['CustomAttributeField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
};

export type CustomAttributesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomAttributesField'] = ResolversParentTypes['CustomAttributesField']> = {
  attribute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  option?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomAttributesFilterFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomAttributesFilterField'] = ResolversParentTypes['CustomAttributesFilterField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributesFilterOptionField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomAttributesFilterOptionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomAttributesFilterOptionField'] = ResolversParentTypes['CustomAttributesFilterOptionField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataFormatConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataFormatConfig'] = ResolversParentTypes['DataFormatConfig']> = {
  dataFormatConfigName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataFormatConfigValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DateValueFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DateValueField'] = ResolversParentTypes['DateValueField']> = {
  attribute?: Resolver<Maybe<ResolversTypes['CustomAttributeField']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeactivateApplicantsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeactivateApplicantsMutation'] = ResolversParentTypes['DeactivateApplicantsMutation']> = {
  updateCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeactivateMentorsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeactivateMentorsMutation'] = ResolversParentTypes['DeactivateMentorsMutation']> = {
  updateCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefaultQuestionsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DefaultQuestionsField'] = ResolversParentTypes['DefaultQuestionsField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subTopic?: Resolver<Maybe<ResolversTypes['SubTopicField']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeCategoriesAnalyticsValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeCategoriesAnalyticsValue'] = ResolversParentTypes['DegreeCategoriesAnalyticsValue']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeCategoryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeCategoryField'] = ResolversParentTypes['DegreeCategoryField']> = {
  categoryType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hierarchyRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parents?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeCategoryParentField']>>>, ParentType, ContextType>;
  relatedKeywords?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeCategoryParentFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeCategoryParentField'] = ResolversParentTypes['DegreeCategoryParentField']> = {
  categoryType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hierarchyRank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeChatUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeChatUserField'] = ResolversParentTypes['DegreeChatUserField']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeField'] = ResolversParentTypes['DegreeField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeCategoryField']>>>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['DegreeLevelField']>, ParentType, ContextType>;
  degreeType?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeType']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeLevelFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeLevelField'] = ResolversParentTypes['DegreeLevelField']> = {
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  yearOfStudy?: Resolver<Maybe<Array<Maybe<ResolversTypes['YearOfStudyField']>>>, ParentType, ContextType, RequireFields<DegreeLevelFieldYearOfStudyArgs, 'includeArchived'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeSnippetField'] = ResolversParentTypes['DegreeSnippetField']> = {
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeStatFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeStatField'] = ResolversParentTypes['DegreeStatField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  degree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DegreeTypeOrderFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DegreeTypeOrderField'] = ResolversParentTypes['DegreeTypeOrderField']> = {
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['DegreeType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteApplicantMutation'] = ResolversParentTypes['DeleteApplicantMutation']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAttributeOptionsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAttributeOptionsMutation'] = ResolversParentTypes['DeleteAttributeOptionsMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteCanonicalQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCanonicalQuestion'] = ResolversParentTypes['DeleteCanonicalQuestion']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteCustomAttributesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCustomAttributesMutation'] = ResolversParentTypes['DeleteCustomAttributesMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteDegreeLevelMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteDegreeLevelMutation'] = ResolversParentTypes['DeleteDegreeLevelMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteFaqAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteFaqAnswer'] = ResolversParentTypes['DeleteFaqAnswer']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteKnowledgeBaseEntriesOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteKnowledgeBaseEntriesOutput'] = ResolversParentTypes['DeleteKnowledgeBaseEntriesOutput']> = {
  totalCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  urls?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteLiveEventFeedMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteLiveEventFeedMutation'] = ResolversParentTypes['DeleteLiveEventFeedMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEventFeed?: Resolver<Maybe<ResolversTypes['LiveEventChatGroupField']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteLiveEventMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteLiveEventMessageMutation'] = ResolversParentTypes['DeleteLiveEventMessageMutation']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteLiveEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteLiveEventMutation'] = ResolversParentTypes['DeleteLiveEventMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletePostMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletePostMutation'] = ResolversParentTypes['DeletePostMutation']> = {
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteRecordingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteRecordingResponse'] = ResolversParentTypes['DeleteRecordingResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordingId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteResearchTimeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteResearchTimeMutation'] = ResolversParentTypes['DeleteResearchTimeMutation']> = {
  result?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteSubTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteSubTopicMutation'] = ResolversParentTypes['DeleteSubTopicMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteTopicMutation'] = ResolversParentTypes['DeleteTopicMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteUniversityUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteUniversityUserMutation'] = ResolversParentTypes['DeleteUniversityUserMutation']> = {
  universityUser?: Resolver<Maybe<ResolversTypes['UniversityUserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteUserMutation'] = ResolversParentTypes['DeleteUserMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteYearOfStudyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteYearOfStudyMutation'] = ResolversParentTypes['DeleteYearOfStudyMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DepartmentSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DepartmentSnippetField'] = ResolversParentTypes['DepartmentSnippetField']> = {
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DescriptionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DescriptionField'] = ResolversParentTypes['DescriptionField']> = {
  descriptionTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instructionTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceField'] = ResolversParentTypes['DeviceField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceMutation'] = ResolversParentTypes['DeviceMutation']> = {
  device?: Resolver<Maybe<ResolversTypes['DeviceField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceTokenResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeviceToken'] = ResolversParentTypes['DeviceToken']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deviceToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  system?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectMessagingApplicantResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectMessagingApplicant'] = ResolversParentTypes['DirectMessagingApplicant']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectMessagingEnquiryTeamResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectMessagingEnquiryTeam'] = ResolversParentTypes['DirectMessagingEnquiryTeam']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectMessagingMentorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectMessagingMentor'] = ResolversParentTypes['DirectMessagingMentor']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['PublicMentorField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectMessagingStaffMemberResolvers<ContextType = any, ParentType extends ResolversParentTypes['DirectMessagingStaffMember'] = ResolversParentTypes['DirectMessagingStaffMember']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  staffMember?: Resolver<Maybe<ResolversTypes['BuddyField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DisplayResolvers<ContextType = any, ParentType extends ResolversParentTypes['Display'] = ResolversParentTypes['Display']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DomesticRegionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['DomesticRegionField'] = ResolversParentTypes['DomesticRegionField']> = {
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditAlertMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditAlertMutation'] = ResolversParentTypes['EditAlertMutation']> = {
  alert?: Resolver<Maybe<ResolversTypes['AlertField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditAttributeOptionMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditAttributeOptionMutation'] = ResolversParentTypes['EditAttributeOptionMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditBlogTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditBlogTopicMutation'] = ResolversParentTypes['EditBlogTopicMutation']> = {
  blogTopic?: Resolver<Maybe<ResolversTypes['BlogTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditCustomAttributeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditCustomAttributeMutation'] = ResolversParentTypes['EditCustomAttributeMutation']> = {
  customAttribute?: Resolver<Maybe<ResolversTypes['CustomAttributeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditCustomAttributesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditCustomAttributesMutation'] = ResolversParentTypes['EditCustomAttributesMutation']> = {
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeField']>>>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditCustomWelcomeMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditCustomWelcomeMessageMutation'] = ResolversParentTypes['EditCustomWelcomeMessageMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditDegreeLevelMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditDegreeLevelMutation'] = ResolversParentTypes['EditDegreeLevelMutation']> = {
  degreeLevel?: Resolver<Maybe<ResolversTypes['DegreeLevelField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditDegreeNameMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditDegreeNameMutation'] = ResolversParentTypes['EditDegreeNameMutation']> = {
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditDegreesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditDegreesMutation'] = ResolversParentTypes['EditDegreesMutation']> = {
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditExternalEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditExternalEventMutation'] = ResolversParentTypes['EditExternalEventMutation']> = {
  externalEvent?: Resolver<Maybe<ResolversTypes['ExternalEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditLiveEventDetailsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditLiveEventDetailsMutation'] = ResolversParentTypes['EditLiveEventDetailsMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditLiveEventFeedMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditLiveEventFeedMutation'] = ResolversParentTypes['EditLiveEventFeedMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEventFeed?: Resolver<Maybe<ResolversTypes['LiveEventChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditLiveEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditLiveEventMutation'] = ResolversParentTypes['EditLiveEventMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['FormFieldValidationError']>>>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditLiveEventsLandingPageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditLiveEventsLandingPageMutation'] = ResolversParentTypes['EditLiveEventsLandingPageMutation']> = {
  liveEventLandingPage?: Resolver<Maybe<ResolversTypes['LiveEventLandingPageField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditOpenDayMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditOpenDayMutation'] = ResolversParentTypes['EditOpenDayMutation']> = {
  openDay?: Resolver<Maybe<ResolversTypes['OpenDayField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditOrganisationMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditOrganisationMutation'] = ResolversParentTypes['EditOrganisationMutation']> = {
  organisation?: Resolver<Maybe<ResolversTypes['OrganisationField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditPostMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditPostMutation'] = ResolversParentTypes['EditPostMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  post?: Resolver<Maybe<ResolversTypes['BlogPostField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditUniversityMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditUniversityMutation'] = ResolversParentTypes['EditUniversityMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditYearOfStudyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EditYearOfStudyMutation'] = ResolversParentTypes['EditYearOfStudyMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yearOfStudy?: Resolver<Maybe<ResolversTypes['YearOfStudyField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmbeddedEventFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmbeddedEventField'] = ResolversParentTypes['EmbeddedEventField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmbeddedEventSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmbeddedEventSnippetField'] = ResolversParentTypes['EmbeddedEventSnippetField']> = {
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  embeddedEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmbeddedEventField']>>>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnableCustomWelcomeMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnableCustomWelcomeMessageMutation'] = ResolversParentTypes['EnableCustomWelcomeMessageMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnableCustomWelcomeMessageStaffMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnableCustomWelcomeMessageStaffMutation'] = ResolversParentTypes['EnableCustomWelcomeMessageStaffMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnableFeaturesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnableFeaturesMutation'] = ResolversParentTypes['EnableFeaturesMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnrolledInsightResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnrolledInsightResponse'] = ResolversParentTypes['EnrolledInsightResponse']> = {
  community?: Resolver<ResolversTypes['CommunityField'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalApplicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalEnrolled?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  university?: Resolver<ResolversTypes['UniversityField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnterpriseAddOnsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnterpriseAddOns'] = ResolversParentTypes['EnterpriseAddOns']> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EnterpriseMentorFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['EnterpriseMentorField'] = ResolversParentTypes['EnterpriseMentorField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  blogPostCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  linkedToOrganisations?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrganisationField']>>>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourceOrganisation?: Resolver<Maybe<ResolversTypes['OrganisationField']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntriesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntriesField'] = ResolversParentTypes['EntriesField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  entryMonths?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventPageResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventPage'] = ResolversParentTypes['EventPage']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventPagesDisplayedOnResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventPagesDisplayedOn'] = ResolversParentTypes['EventPagesDisplayedOn']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportAnalyticsCsvMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportAnalyticsCsvMutation'] = ResolversParentTypes['ExportAnalyticsCsvMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportTimeSheetDataFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportTimeSheetDataField'] = ResolversParentTypes['ExportTimeSheetDataField']> = {
  usersTimesheetData?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserTimeSheetExportField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportedReportFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExportedReportField'] = ResolversParentTypes['ExportedReportField']> = {
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalContent'] = ResolversParentTypes['ExternalContent']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventPagesDisplayedOn?: Resolver<Array<ResolversTypes['EventPagesDisplayedOn']>, ParentType, ContextType>;
  eventPagesIds?: Resolver<Array<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  externalContentImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  externalContentUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  showOnHomepage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEventFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEventField'] = ResolversParentTypes['ExternalEventField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FaqAnswerFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FaqAnswerField'] = ResolversParentTypes['FaqAnswerField']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mentor?: Resolver<ResolversTypes['MentorField'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureField'] = ResolversParentTypes['FeatureField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  descriptionClickablePart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  descriptionLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureRulesDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['FeatureRulesDTO'] = ResolversParentTypes['FeatureRulesDTO']> = {
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  universities?: Resolver<Maybe<Array<ResolversTypes['UniversityOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldMappingFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldMappingField'] = ResolversParentTypes['FieldMappingField']> = {
  applicationField?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  crmName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FlagFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['FlagField'] = ResolversParentTypes['FlagField']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormFieldValidationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['FormFieldValidationError'] = ResolversParentTypes['FormFieldValidationError']> = {
  code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForwardApplicantToUniversityResolvers<ContextType = any, ParentType extends ResolversParentTypes['ForwardApplicantToUniversity'] = ResolversParentTypes['ForwardApplicantToUniversity']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  messages?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateAnalyticsDashboardTokenMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenerateAnalyticsDashboardTokenMutation'] = ResolversParentTypes['GenerateAnalyticsDashboardTokenMutation']> = {
  analyticsAuth?: Resolver<Maybe<ResolversTypes['AnalyticsDashboardAuthField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateLiveEventReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['GenerateLiveEventReport'] = ResolversParentTypes['GenerateLiveEventReport']> = {
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GenericScalarScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GenericScalar'], any> {
  name: 'GenericScalar';
}

export type GetDownloadableUrlMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetDownloadableURLMutation'] = ResolversParentTypes['GetDownloadableURLMutation']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetInvitesForEmailDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetInvitesForEmailDTO'] = ResolversParentTypes['GetInvitesForEmailDTO']> = {
  community?: Resolver<ResolversTypes['Community'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetMarketplaceApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetMarketplaceApplicantMutation'] = ResolversParentTypes['GetMarketplaceApplicantMutation']> = {
  authenticatedApplicant?: Resolver<Maybe<ResolversTypes['AuthField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetRecordingFailureIdsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetRecordingFailureIdsResponse'] = ResolversParentTypes['GetRecordingFailureIdsResponse']> = {
  failedIds?: Resolver<Array<ResolversTypes['RecordingFailureId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetSignedS3PostFieldsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetSignedS3PostFieldsMutation'] = ResolversParentTypes['GetSignedS3PostFieldsMutation']> = {
  postFieldsObject?: Resolver<Maybe<ResolversTypes['SignedS3POSTFields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetSignedS3UrlMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GetSignedS3UrlMutation'] = ResolversParentTypes['GetSignedS3UrlMutation']> = {
  signedObject?: Resolver<Maybe<ResolversTypes['SignedS3URLField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiphyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Giphy'] = ResolversParentTypes['Giphy']> = {
  aspectRatio?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  height?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GifType'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GraphFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['GraphField'] = ResolversParentTypes['GraphField']> = {
  xAxis?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  yAxis?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  yWeights?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupField'] = ResolversParentTypes['GroupField']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HideMentorInMarketplaceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HideMentorInMarketplaceMutation'] = ResolversParentTypes['HideMentorInMarketplaceMutation']> = {
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HighSchoolFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['HighSchoolField'] = ResolversParentTypes['HighSchoolField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HighSchoolTypeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['HighSchoolTypeField'] = ResolversParentTypes['HighSchoolTypeField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HousingObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['HousingObject'] = ResolversParentTypes['HousingObject']> = {
  category?: Resolver<ResolversTypes['HousingCategory'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InboxItemInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxItemInterface'] = ResolversParentTypes['InboxItemInterface']> = {
  __resolveType: TypeResolveFn<'ChatInboxField' | 'UniversityChatInboxField', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['InboxItemLastMessageField']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatUserField']>>>, ParentType, ContextType>;
  unreadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
};

export type InboxItemLastMessageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxItemLastMessageField'] = ResolversParentTypes['InboxItemLastMessageField']> = {
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isFromUser?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InboxItemUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxItemUnion'] = ResolversParentTypes['InboxItemUnion']> = {
  __resolveType: TypeResolveFn<'ChatInboxField' | 'UniversityChatInboxField', ParentType, ContextType>;
};

export type InboxPromptAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxPromptAnswer'] = ResolversParentTypes['InboxPromptAnswer']> = {
  answeredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InboxPromptOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxPromptOption'] = ResolversParentTypes['InboxPromptOption']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InboxPromptQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxPromptQuestion'] = ResolversParentTypes['InboxPromptQuestion']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  answers?: Resolver<Array<ResolversTypes['InboxPromptAnswer']>, ParentType, ContextType, RequireFields<InboxPromptQuestionAnswersArgs, 'inboxPromptInput'>>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['InboxPromptOption']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InboxUniversityFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['InboxUniversityField'] = ResolversParentTypes['InboxUniversityField']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  squareLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InitialiseRecordingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InitialiseRecordingResponse'] = ResolversParentTypes['InitialiseRecordingResponse']> = {
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  layoutMode?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  layoutStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordingStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InputFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['InputField'] = ResolversParentTypes['InputField']> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  placeholder?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Insights'] = ResolversParentTypes['Insights']> = {
  communityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  totalApplicantsInCommunity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalEnrolled?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InsightsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['InsightsField'] = ResolversParentTypes['InsightsField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topics?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopicStatisticField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InterestResolvers<ContextType = any, ParentType extends ResolversParentTypes['Interest'] = ResolversParentTypes['Interest']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InterestObjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['InterestObject'] = ResolversParentTypes['InterestObject']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InterventionMessageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['InterventionMessageField'] = ResolversParentTypes['InterventionMessageField']> = {
  message?: Resolver<Maybe<ResolversTypes['MessageField']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invite'] = ResolversParentTypes['Invite']> = {
  communityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reminderStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendUnibuddyEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteApplicantsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteApplicantsMutation'] = ResolversParentTypes['InviteApplicantsMutation']> = {
  alreadyExistingInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  failedInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  successfulInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteBuddiesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteBuddiesMutation'] = ResolversParentTypes['InviteBuddiesMutation']> = {
  failedInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  successfulInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyInviteListField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteInformation'] = ResolversParentTypes['InviteInformation']> = {
  community?: Resolver<ResolversTypes['Community'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invite?: Resolver<Maybe<ResolversTypes['Invite']>, ParentType, ContextType>;
  isPartOfCommunity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  university?: Resolver<ResolversTypes['UniversityField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteLinkDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteLinkDto'] = ResolversParentTypes['InviteLinkDto']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  communityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expiringAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  labels?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteLinkInfoDtoResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteLinkInfoDto'] = ResolversParentTypes['InviteLinkInfoDto']> = {
  communityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  communityTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squareLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universitySlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteMentorsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteMentorsMutation'] = ResolversParentTypes['InviteMentorsMutation']> = {
  successfulInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorInviteField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IsoDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['IsoDateTime'], any> {
  name: 'IsoDateTime';
}

export interface JsonStringScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONString'], any> {
  name: 'JSONString';
}

export type JoinChatGroupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['JoinChatGroupMutation'] = ResolversParentTypes['JoinChatGroupMutation']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KnowledgeBaseDeleteEntryOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['KnowledgeBaseDeleteEntryOutput'] = ResolversParentTypes['KnowledgeBaseDeleteEntryOutput']> = {
  ok?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KnowledgeBaseEmbeddingResolvers<ContextType = any, ParentType extends ResolversParentTypes['KnowledgeBaseEmbedding'] = ResolversParentTypes['KnowledgeBaseEmbedding']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  ids?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KnowledgeBaseSourcesByUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['KnowledgeBaseSourcesByUrl'] = ResolversParentTypes['KnowledgeBaseSourcesByUrl']> = {
  ids?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelSentenceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LabelSentenceMutation'] = ResolversParentTypes['LabelSentenceMutation']> = {
  labelSentence?: Resolver<ResolversTypes['LabellingField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabellingFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LabellingField'] = ResolversParentTypes['LabellingField']> = {
  chatGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classifiedSentenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  embeddedSubTopic?: Resolver<Maybe<ResolversTypes['TopSubTopicField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subTopicExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topicExternalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabellingSentenceCandidateResolvers<ContextType = any, ParentType extends ResolversParentTypes['LabellingSentenceCandidate'] = ResolversParentTypes['LabellingSentenceCandidate']> = {
  classifiedSentenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topSubTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopSubTopicField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LabelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Labels'] = ResolversParentTypes['Labels']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LanguageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LanguageField'] = ResolversParentTypes['LanguageField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastMessageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LastMessageField'] = ResolversParentTypes['LastMessageField']> = {
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sender?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastReadResolvers<ContextType = any, ParentType extends ResolversParentTypes['LastRead'] = ResolversParentTypes['LastRead']> = {
  conversationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaveChatGroupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LeaveChatGroupMutation'] = ResolversParentTypes['LeaveChatGroupMutation']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LevelSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LevelSnippetField'] = ResolversParentTypes['LevelSnippetField']> = {
  level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LikeMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LikeMessageMutation'] = ResolversParentTypes['LikeMessageMutation']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LimitedIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LimitedInt'], any> {
  name: 'LimitedInt';
}

export type LinkOrganisationsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LinkOrganisationsMutation'] = ResolversParentTypes['LinkOrganisationsMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListBuddyApplicantConversationsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListBuddyApplicantConversationsField'] = ResolversParentTypes['ListBuddyApplicantConversationsField']> = {
  conversations?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyApplicantChatGroupFieldV2']>>>, ParentType, ContextType>;
  totalConversations?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListMentorApplicantConversationsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListMentorApplicantConversationsField'] = ResolversParentTypes['ListMentorApplicantConversationsField']> = {
  conversations?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorApplicantChatGroupField']>>>, ParentType, ContextType>;
  totalConversations?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventChatGroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventChatGroupField'] = ResolversParentTypes['LiveEventChatGroupField']> = {
  applicantCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  applicants?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantField']>>>, ParentType, ContextType>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasEnquiryTeam?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hostIdsVideoSessions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recordingEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  staff?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserField']>>>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  universityUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityUserField']>>>, ParentType, ContextType>;
  videoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  videoSessions?: Resolver<Maybe<Array<Maybe<ResolversTypes['VideoSessionField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventDetailedReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventDetailedReport'] = ResolversParentTypes['LiveEventDetailedReport']> = {
  applicantCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventDetailedReportCountryStats']>>>, ParentType, ContextType>;
  liveEventId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messagesPerChannel?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventDetailedReportChannelStats']>>>, ParentType, ContextType>;
  prospectInLiveEvent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prospectLoggedIn?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prospectSignUps?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prospectsInLiveEventChatGroups?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prospectsSendDirectMessage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prospectsSendMessage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventDetailedReportChannelStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventDetailedReportChannelStats'] = ResolversParentTypes['LiveEventDetailedReportChannelStats']> = {
  applicants?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  len?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventDetailedReportCountryStatsResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventDetailedReportCountryStats'] = ResolversParentTypes['LiveEventDetailedReportCountryStats']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventDetailsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventDetailsField'] = ResolversParentTypes['LiveEventDetailsField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isUserRegistered?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventDirectMessagingResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventDirectMessaging'] = ResolversParentTypes['LiveEventDirectMessaging']> = {
  applicants?: Resolver<Maybe<Array<Maybe<ResolversTypes['DirectMessagingApplicant']>>>, ParentType, ContextType>;
  enquiryTeam?: Resolver<Maybe<ResolversTypes['DirectMessagingEnquiryTeam']>, ParentType, ContextType>;
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['DirectMessagingMentor']>>>, ParentType, ContextType>;
  staff?: Resolver<Maybe<Array<Maybe<ResolversTypes['DirectMessagingStaffMember']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventFeedbackFormMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventFeedbackFormMutation'] = ResolversParentTypes['LiveEventFeedbackFormMutation']> = {
  feedBackForm?: Resolver<Maybe<ResolversTypes['LiveEventProspectFeedbackFormField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventField'] = ResolversParentTypes['LiveEventField']> = {
  allMentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType>;
  allStaff?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyField']>>>, ParentType, ContextType>;
  applicants?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdByUniversity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  detailedReport?: Resolver<Maybe<ResolversTypes['LiveEventDetailedReport']>, ParentType, ContextType>;
  directMessagingMentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType>;
  directMessagingStaff?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyField']>>>, ParentType, ContextType>;
  directMessagingUniversity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  editable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inbox?: Resolver<Maybe<ResolversTypes['LiveEventInboxField']>, ParentType, ContextType, RequireFields<LiveEventFieldInboxArgs, 'limit'>>;
  liveFeeds?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventChatGroupField']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  report?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setupCompleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventInboxField'] = ResolversParentTypes['LiveEventInboxField']> = {
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['InboxItemUnion']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventLandingPageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventLandingPageField'] = ResolversParentTypes['LiveEventLandingPageField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExternalEventField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventDetailsField']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventProspectFeedbackFormFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventProspectFeedbackFormField'] = ResolversParentTypes['LiveEventProspectFeedbackFormField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  eventExperience?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eventFeedback?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveEventsFormFieldValidationErrorResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveEventsFormFieldValidationError'] = ResolversParentTypes['LiveEventsFormFieldValidationError']> = {
  code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveSession'] = ResolversParentTypes['LiveSession']> = {
  conversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eventPagesDisplayedOn?: Resolver<Array<ResolversTypes['EventPagesDisplayedOn']>, ParentType, ContextType>;
  eventPagesIds?: Resolver<Array<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  isSavedByMe?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  presenters?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType>;
  recordingEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sessionAnalytics?: Resolver<ResolversTypes['SessionAnalytics'], ParentType, ContextType>;
  sessionImageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  showOnHomepage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocaleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Locale'] = ResolversParentTypes['Locale']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocaleFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocaleField'] = ResolversParentTypes['LocaleField']> = {
  androidCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dialectOf?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osxCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osxLocale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  textDirection?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  threeLettersCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  twoLettersCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogoDataFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoDataField'] = ResolversParentTypes['LogoDataField']> = {
  logoText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogoutMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoutMutation'] = ResolversParentTypes['LogoutMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManageOrganisationAccessMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManageOrganisationAccessMutation'] = ResolversParentTypes['ManageOrganisationAccessMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManuallyClassifyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManuallyClassifyMutation'] = ResolversParentTypes['ManuallyClassifyMutation']> = {
  classification?: Resolver<ResolversTypes['ClassificationField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceApplicantCountriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceApplicantCountries'] = ResolversParentTypes['MarketplaceApplicantCountries']> = {
  countries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryAnalyticsValue']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceApplicantDegreeCategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceApplicantDegreeCategories'] = ResolversParentTypes['MarketplaceApplicantDegreeCategories']> = {
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeCategoriesAnalyticsValue']>>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceApplicantUserDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceApplicantUserData'] = ResolversParentTypes['MarketplaceApplicantUserData']> = {
  custom?: Resolver<Maybe<ResolversTypes['GenericScalar']>, ParentType, ContextType>;
  degreeCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketingConsentAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  personalDataCollectionAccepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  yearOfEntry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceField'] = ResolversParentTypes['MarketplaceField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowUniversityProspectDataAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customBuddyWord?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['MarketplaceFilters']>>>, ParentType, ContextType>;
  disableExternalNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  faAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSsoEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  marketplaceMarketingConsentCollectionEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  marketplaceMarketingConsentMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personalDataApiEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unibuddyMarketingConsentEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  universityMarketingConsentEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  webhookUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceFilters'] = ResolversParentTypes['MarketplaceFilters']> = {
  filterSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceInboxField'] = ResolversParentTypes['MarketplaceInboxField']> = {
  chatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceMentorListResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceMentorList'] = ResolversParentTypes['MarketplaceMentorList']> = {
  mentorSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['PublicMentorField']>>>, ParentType, ContextType>;
  totalMentors?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  universitySeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketplaceSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MarketplaceSnippetField'] = ResolversParentTypes['MarketplaceSnippetField']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['MatchedUser'] = ResolversParentTypes['MatchedUser']> = {
  matchingCriteria?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  matchingPercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeField'] = ResolversParentTypes['MeField']> = {
  admin?: Resolver<Maybe<ResolversTypes['AdminUserField']>, ParentType, ContextType>;
  anyUser?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  staff?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  universityUser?: Resolver<Maybe<ResolversTypes['UniversityUserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorApplicantChatGroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorApplicantChatGroupField'] = ResolversParentTypes['MentorApplicantChatGroupField']> = {
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creationSource?: Resolver<Maybe<ResolversTypes['CreationSource']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastMessageSentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastMessageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unansweredByMentor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorBroadcastMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorBroadcastMessageMutation'] = ResolversParentTypes['MentorBroadcastMessageMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorChatUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorChatUserField'] = ResolversParentTypes['MentorChatUserField']> = {
  degree?: Resolver<Maybe<ResolversTypes['DegreeChatUserField']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MentorChatUserFieldProfilePhotoArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorDegreeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorDegreeField'] = ResolversParentTypes['MentorDegreeField']> = {
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  degreeType?: Resolver<Maybe<ResolversTypes['DegreeType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorDegreeHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorDegreeHistory'] = ResolversParentTypes['MentorDegreeHistory']> = {
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorField'] = ResolversParentTypes['MentorField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blogPostCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  cardComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatIntro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeValueField']>>>, ParentType, ContextType, RequireFields<MentorFieldCustomAttributesArgs, never>>;
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  degreeHistory?: Resolver<Maybe<ResolversTypes['MentorDegreeHistory']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorDegreeField']>>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  favouriteModules?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorModuleField']>>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasUnansweredConversation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interests?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorInterestField']>>>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<Maybe<ResolversTypes['LanguageField']>>>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastUpdatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentorFields?: Resolver<Maybe<ResolversTypes['MentorFields']>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pastQualification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['MentorPreferencesField']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MentorFieldProfilePhotoArgs, never>>;
  prospectWelcomeMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolvedUnansweredConversations?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  schoolHistory?: Resolver<Maybe<ResolversTypes['MentorSchoolHistoryField']>, ParentType, ContextType>;
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  showIn?: Resolver<Maybe<Array<Maybe<ResolversTypes['Display']>>>, ParentType, ContextType>;
  societies?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorSocietyField']>>>, ParentType, ContextType>;
  stageOneComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  stageTwoComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unansweredCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  unreadMessageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorFieldOrderFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorFieldOrderField'] = ResolversParentTypes['MentorFieldOrderField']> = {
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorFields'] = ResolversParentTypes['MentorFields']> = {
  primaryFlag?: Resolver<Maybe<ResolversTypes['FlagField']>, ParentType, ContextType>;
  secondaryFlag?: Resolver<Maybe<ResolversTypes['FlagField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorFilterFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorFilterField'] = ResolversParentTypes['MentorFilterField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorFilterOptionField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorFilterOptionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorFilterOptionField'] = ResolversParentTypes['MentorFilterOptionField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorInboxField'] = ResolversParentTypes['MentorInboxField']> = {
  chatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType>;
  chatGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorInterestFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorInterestField'] = ResolversParentTypes['MentorInterestField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorInterventionQueryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorInterventionQueryField'] = ResolversParentTypes['MentorInterventionQueryField']> = {
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorIntroMessageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorIntroMessageField'] = ResolversParentTypes['MentorIntroMessageField']> = {
  introMessage?: Resolver<Maybe<ResolversTypes['MessageField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorInviteCountFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorInviteCountField'] = ResolversParentTypes['MentorInviteCountField']> = {
  mentorInviteCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorInviteFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorInviteField'] = ResolversParentTypes['MentorInviteField']> = {
  accepted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notFound?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorListField'] = ResolversParentTypes['MentorListField']> = {
  countries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryField']>>>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  levels?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['PublicMentorField']>>>, ParentType, ContextType>;
  validCombination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorListFilterChoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorListFilterChoice'] = ResolversParentTypes['MentorListFilterChoice']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorModuleFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorModuleField'] = ResolversParentTypes['MentorModuleField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorPreferencesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorPreferencesField'] = ResolversParentTypes['MentorPreferencesField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newMessageNotifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorSchoolHistoryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorSchoolHistoryField'] = ResolversParentTypes['MentorSchoolHistoryField']> = {
  highSchool?: Resolver<Maybe<ResolversTypes['HighSchoolField']>, ParentType, ContextType>;
  highSchoolType?: Resolver<Maybe<ResolversTypes['HighSchoolTypeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorSnippetField'] = ResolversParentTypes['MentorSnippetField']> = {
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorSocietyFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorSocietyField'] = ResolversParentTypes['MentorSocietyField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MentorcardSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MentorcardSnippetField'] = ResolversParentTypes['MentorcardSnippetField']> = {
  countrySnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountrySnippetField']>>>, ParentType, ContextType>;
  degreeSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeSnippetField']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  mentorSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorSnippetField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageField'] = ResolversParentTypes['MessageField']> = {
  attachment?: Resolver<Maybe<ResolversTypes['AttachmentField']>, ParentType, ContextType>;
  chatGroupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  input?: Resolver<Maybe<ResolversTypes['InputField']>, ParentType, ContextType>;
  instantAction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSenderBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  likes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ownMessage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  read?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  richContent?: Resolver<Maybe<ResolversTypes['RichContentOutputField']>, ParentType, ContextType>;
  sender?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  visible?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageMetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageMetadata'] = ResolversParentTypes['MessageMetadata']> = {
  ambassadorProfile?: Resolver<Maybe<ResolversTypes['AmbassadorProfileInformation']>, ParentType, ContextType>;
  staffProfile?: Resolver<Maybe<ResolversTypes['StaffProfileInformation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagePublicBotMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessagePublicBotMutation'] = ResolversParentTypes['MessagePublicBotMutation']> = {
  botMessages?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetricDataPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['MetricDataPoint'] = ResolversParentTypes['MetricDataPoint']> = {
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  metricName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metricType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  acceptRequestedPublicGroup?: Resolver<ResolversTypes['RequestedPublicGroup'], ParentType, ContextType, RequireFields<MutationAcceptRequestedPublicGroupArgs, 'acceptRequestedPublicGroupInput'>>;
  activateApplicants?: Resolver<Maybe<ResolversTypes['ActivateApplicantsMutation']>, ParentType, ContextType, RequireFields<MutationActivateApplicantsArgs, never>>;
  activateMentors?: Resolver<Maybe<ResolversTypes['ActivateMentorsMutation']>, ParentType, ContextType, RequireFields<MutationActivateMentorsArgs, never>>;
  addAttendees?: Resolver<Maybe<ResolversTypes['AttendeeAnalytics']>, ParentType, ContextType, RequireFields<MutationAddAttendeesArgs, 'attendeeData'>>;
  addBotToChatGroup?: Resolver<Maybe<ResolversTypes['AddBotToChatGroupMutation']>, ParentType, ContextType, RequireFields<MutationAddBotToChatGroupArgs, never>>;
  addChatRoomAttendees?: Resolver<Maybe<ResolversTypes['ChatRoomAttendeeAnalytics']>, ParentType, ContextType, RequireFields<MutationAddChatRoomAttendeesArgs, 'chatRoomAttendeeData'>>;
  addCustomAttribute?: Resolver<Maybe<ResolversTypes['AddCustomAttributeMutation']>, ParentType, ContextType, RequireFields<MutationAddCustomAttributeArgs, never>>;
  addCustomAttributeOption?: Resolver<Maybe<ResolversTypes['AddAttributeOptionMutation']>, ParentType, ContextType, RequireFields<MutationAddCustomAttributeOptionArgs, never>>;
  addDegreeLevels?: Resolver<Maybe<ResolversTypes['AddDegreeLevelsMutation']>, ParentType, ContextType, RequireFields<MutationAddDegreeLevelsArgs, never>>;
  addDegrees?: Resolver<Maybe<ResolversTypes['AddDegreesMutation']>, ParentType, ContextType, RequireFields<MutationAddDegreesArgs, never>>;
  addDevice?: Resolver<Maybe<ResolversTypes['DeviceMutation']>, ParentType, ContextType, RequireFields<MutationAddDeviceArgs, never>>;
  addHobsonsConnectIntegration?: Resolver<Maybe<ResolversTypes['AddHobsonsConnectIntegration']>, ParentType, ContextType, RequireFields<MutationAddHobsonsConnectIntegrationArgs, never>>;
  addLocalesToApp?: Resolver<Maybe<ResolversTypes['AddLocalesToApp']>, ParentType, ContextType, RequireFields<MutationAddLocalesToAppArgs, 'appLocales'>>;
  addMatchingCriteria?: Resolver<ResolversTypes['UpdatedResults'], ParentType, ContextType>;
  addReactionToMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationAddReactionToMessageArgs, 'reactionDto'>>;
  addResearchTime?: Resolver<Maybe<ResolversTypes['AddResearchTimeMutation']>, ParentType, ContextType, RequireFields<MutationAddResearchTimeArgs, 'researchTimeData'>>;
  addSessionAttendees?: Resolver<Maybe<ResolversTypes['SessionAttendeeAnalytics']>, ParentType, ContextType, RequireFields<MutationAddSessionAttendeesArgs, 'sessionAttendeeData'>>;
  addToFeatureExperiment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationAddToFeatureExperimentArgs, 'experimentName' | 'metadata'>>;
  addUserToCommunityConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationAddUserToCommunityConversationArgs, 'conversationId' | 'memberId'>>;
  addVote?: Resolver<ResolversTypes['Poll'], ParentType, ContextType, RequireFields<MutationAddVoteArgs, 'chatMessageId' | 'option'>>;
  addYearOfStudy?: Resolver<Maybe<ResolversTypes['AddYearOfStudyMutation']>, ParentType, ContextType, RequireFields<MutationAddYearOfStudyArgs, 'degreeLevelName' | 'universitySlug' | 'yearOfStudyNames'>>;
  adminDeleteApplicant?: Resolver<Maybe<ResolversTypes['AdminDeleteApplicantMutation']>, ParentType, ContextType, RequireFields<MutationAdminDeleteApplicantArgs, never>>;
  adminDeleteBuddy?: Resolver<Maybe<ResolversTypes['DeleteUserMutation']>, ParentType, ContextType, RequireFields<MutationAdminDeleteBuddyArgs, 'userId'>>;
  adminUpdateMentor?: Resolver<Maybe<ResolversTypes['AdminUpdateMentorMutation']>, ParentType, ContextType, RequireFields<MutationAdminUpdateMentorArgs, never>>;
  applicantBroadcastMessage?: Resolver<Maybe<ResolversTypes['ApplicantBroadcastMessageMutation']>, ParentType, ContextType, RequireFields<MutationApplicantBroadcastMessageArgs, never>>;
  bulkOptOutMarketplaceUniversityMarketing?: Resolver<Maybe<ResolversTypes['BulkOptOutMarketplaceUniversityMarketingMutation']>, ParentType, ContextType, RequireFields<MutationBulkOptOutMarketplaceUniversityMarketingArgs, 'marketplaceSlug' | 'universitySlugs'>>;
  checkEmail?: Resolver<Maybe<ResolversTypes['CheckEmailMutation']>, ParentType, ContextType, RequireFields<MutationCheckEmailArgs, never>>;
  cloneCommunity?: Resolver<ResolversTypes['Community'], ParentType, ContextType, RequireFields<MutationCloneCommunityArgs, 'sourceCommmunityId'>>;
  cloneLiveEventAndChannels?: Resolver<Maybe<ResolversTypes['CloneLiveEventAndChannelsMutation']>, ParentType, ContextType, RequireFields<MutationCloneLiveEventAndChannelsArgs, never>>;
  createAlert?: Resolver<Maybe<ResolversTypes['CreateAlertMutation']>, ParentType, ContextType, RequireFields<MutationCreateAlertArgs, never>>;
  createApplicant?: Resolver<Maybe<ResolversTypes['CreateApplicantMutation']>, ParentType, ContextType, RequireFields<MutationCreateApplicantArgs, never>>;
  createApplicantV2?: Resolver<Maybe<ResolversTypes['CreateApplicantMutationV2']>, ParentType, ContextType, RequireFields<MutationCreateApplicantV2Args, never>>;
  createApplicationInsight?: Resolver<ResolversTypes['ApplicationInsightModel'], ParentType, ContextType, RequireFields<MutationCreateApplicationInsightArgs, 'createApplicantInsightInput'>>;
  createAssistantConversation?: Resolver<ResolversTypes['AssistantConversationHistory'], ParentType, ContextType, RequireFields<MutationCreateAssistantConversationArgs, 'createAssistantConversationInput'>>;
  createAssistantMessageFeedback?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateAssistantMessageFeedbackArgs, 'assistantMessageFeedbackInput'>>;
  createBlogTopic?: Resolver<Maybe<ResolversTypes['CreateBlogTopicMutation']>, ParentType, ContextType, RequireFields<MutationCreateBlogTopicArgs, never>>;
  createBuddy?: Resolver<Maybe<ResolversTypes['CreateBuddyMutation']>, ParentType, ContextType, RequireFields<MutationCreateBuddyArgs, never>>;
  createCanonicalQuestion?: Resolver<Maybe<ResolversTypes['CreateCanonicalQuestion']>, ParentType, ContextType, RequireFields<MutationCreateCanonicalQuestionArgs, 'question' | 'subTopic'>>;
  createChatReport?: Resolver<ResolversTypes['ChatReport'], ParentType, ContextType, RequireFields<MutationCreateChatReportArgs, 'chatReportUserInputDTO'>>;
  createChatRoom?: Resolver<ResolversTypes['ChatRoom'], ParentType, ContextType, RequireFields<MutationCreateChatRoomArgs, 'description' | 'name' | 'virtualEventId'>>;
  createComment?: Resolver<Maybe<ResolversTypes['CommentMutation']>, ParentType, ContextType, RequireFields<MutationCreateCommentArgs, never>>;
  createCommunity?: Resolver<ResolversTypes['Community'], ParentType, ContextType, RequireFields<MutationCreateCommunityArgs, 'CreateCommunityDTO'>>;
  createCommunityApplicant?: Resolver<Maybe<ResolversTypes['CreateCommunityApplicantMutation']>, ParentType, ContextType, RequireFields<MutationCreateCommunityApplicantArgs, never>>;
  createCommunityApplicantV2?: Resolver<Maybe<ResolversTypes['CreateCommunityApplicantMutationV2']>, ParentType, ContextType, RequireFields<MutationCreateCommunityApplicantV2Args, never>>;
  createCommunityChatGroup?: Resolver<ResolversTypes['CommunityChatGroup'], ParentType, ContextType, RequireFields<MutationCreateCommunityChatGroupArgs, 'createCommunityChatGroupInput'>>;
  createCommunityConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationCreateCommunityConversationArgs, 'CreateCommunityConversationDTO'>>;
  createCommunityFromTemplate?: Resolver<ResolversTypes['Community'], ParentType, ContextType, RequireFields<MutationCreateCommunityFromTemplateArgs, 'CreateCommunityDTO' | 'communityUseCase'>>;
  createCrmServiceUniversityConfig?: Resolver<Maybe<ResolversTypes['CreateCrmServiceUniversityConfigMutation']>, ParentType, ContextType, RequireFields<MutationCreateCrmServiceUniversityConfigArgs, never>>;
  createDirectConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationCreateDirectConversationArgs, 'DirectConversationDTO'>>;
  createEventPage?: Resolver<ResolversTypes['EventPage'], ParentType, ContextType, RequireFields<MutationCreateEventPageArgs, 'eventPageData' | 'virtualEventId'>>;
  createExternalContent?: Resolver<ResolversTypes['ExternalContent'], ParentType, ContextType, RequireFields<MutationCreateExternalContentArgs, 'externalContentData' | 'virtualEventId'>>;
  createExternalEvent?: Resolver<Maybe<ResolversTypes['CreateExternalEventMutation']>, ParentType, ContextType, RequireFields<MutationCreateExternalEventArgs, 'description' | 'name'>>;
  createFaqAnswer?: Resolver<Maybe<ResolversTypes['CreateFaqAnswer']>, ParentType, ContextType, RequireFields<MutationCreateFaqAnswerArgs, 'answer' | 'canonicalQuestion'>>;
  createFresher?: Resolver<Maybe<ResolversTypes['CreateFresherMutation']>, ParentType, ContextType, RequireFields<MutationCreateFresherArgs, never>>;
  createInviteLink?: Resolver<ResolversTypes['InviteLinkDto'], ParentType, ContextType, RequireFields<MutationCreateInviteLinkArgs, 'inviteLinkInput'>>;
  createLiveEvent?: Resolver<Maybe<ResolversTypes['CreateLiveEventMutation']>, ParentType, ContextType, RequireFields<MutationCreateLiveEventArgs, never>>;
  createLiveEventDetails?: Resolver<Maybe<ResolversTypes['CreateLiveEventDetailsMutation']>, ParentType, ContextType, RequireFields<MutationCreateLiveEventDetailsArgs, never>>;
  createLiveEventFeed?: Resolver<Maybe<ResolversTypes['CreateLiveEventFeedMutation']>, ParentType, ContextType, RequireFields<MutationCreateLiveEventFeedArgs, never>>;
  createLiveEventFeedSessions?: Resolver<Maybe<ResolversTypes['CreateLiveEventFeedSessionsMutation']>, ParentType, ContextType, RequireFields<MutationCreateLiveEventFeedSessionsArgs, never>>;
  createLiveSession?: Resolver<ResolversTypes['LiveSession'], ParentType, ContextType, RequireFields<MutationCreateLiveSessionArgs, 'sessionData' | 'virtualEventId'>>;
  createMarketplace?: Resolver<Maybe<ResolversTypes['CreateMarketplaceMutation']>, ParentType, ContextType, RequireFields<MutationCreateMarketplaceArgs, 'name' | 'slug' | 'widgetUrl'>>;
  createMarketplaceApplicant?: Resolver<Maybe<ResolversTypes['CreateMarketplaceApplicantMutation']>, ParentType, ContextType, RequireFields<MutationCreateMarketplaceApplicantArgs, 'country' | 'email' | 'firstName' | 'lastName' | 'marketplace' | 'password'>>;
  createMentor?: Resolver<Maybe<ResolversTypes['CreateMentorMutation']>, ParentType, ContextType, RequireFields<MutationCreateMentorArgs, never>>;
  createOpenDay?: Resolver<Maybe<ResolversTypes['CreateOpenDayMutation']>, ParentType, ContextType, RequireFields<MutationCreateOpenDayArgs, 'description' | 'externalEventIds' | 'liveEventIds' | 'title'>>;
  createOrUpdateUniversityAssistantPromptContext?: Resolver<ResolversTypes['UniversityAssistantConfigurationPromptContextOutput'], ParentType, ContextType, RequireFields<MutationCreateOrUpdateUniversityAssistantPromptContextArgs, 'universityAssistantConfigurationPromptContextInput'>>;
  createOrganisation?: Resolver<Maybe<ResolversTypes['CreateOrganisationMutation']>, ParentType, ContextType, RequireFields<MutationCreateOrganisationArgs, 'name' | 'organisationType'>>;
  createOrganisationBuddyLink?: Resolver<Maybe<ResolversTypes['CreateOrganisationBuddyLinkMutation']>, ParentType, ContextType, RequireFields<MutationCreateOrganisationBuddyLinkArgs, 'organisation' | 'status' | 'user'>>;
  createPost?: Resolver<Maybe<ResolversTypes['CreatePostMutation']>, ParentType, ContextType, RequireFields<MutationCreatePostArgs, never>>;
  createStaffContact?: Resolver<ResolversTypes['StaffContactOutput'], ParentType, ContextType, RequireFields<MutationCreateStaffContactArgs, 'staffContactInput'>>;
  createSubTopic?: Resolver<Maybe<ResolversTypes['CreateSubTopicMutation']>, ParentType, ContextType, RequireFields<MutationCreateSubTopicArgs, 'externalId' | 'name' | 'topic'>>;
  createTag?: Resolver<Maybe<ResolversTypes['CreateTagMutation']>, ParentType, ContextType, RequireFields<MutationCreateTagArgs, 'name'>>;
  createTopic?: Resolver<Maybe<ResolversTypes['CreateTopicMutation']>, ParentType, ContextType, RequireFields<MutationCreateTopicArgs, 'externalId' | 'name'>>;
  createUniversity?: Resolver<Maybe<ResolversTypes['CreateUniversityMutation']>, ParentType, ContextType, RequireFields<MutationCreateUniversityArgs, never>>;
  createUniversityAssistantEventLink?: Resolver<ResolversTypes['UniversityAssistantConfigurationEventOutput'], ParentType, ContextType, RequireFields<MutationCreateUniversityAssistantEventLinkArgs, 'universityAssistantConfigurationEventInput'>>;
  createUniversityBlockReport?: Resolver<Maybe<ResolversTypes['CreateUniversityBlockReportMutation']>, ParentType, ContextType, RequireFields<MutationCreateUniversityBlockReportArgs, never>>;
  createUniversityScrape?: Resolver<ResolversTypes['UniversityScrapeOutput'], ParentType, ContextType, RequireFields<MutationCreateUniversityScrapeArgs, 'universityScrapeInput'>>;
  createUniversityScrapeBatch?: Resolver<ResolversTypes['UniversityScrapeOutput'], ParentType, ContextType, RequireFields<MutationCreateUniversityScrapeBatchArgs, 'universityScrapeManualInput'>>;
  createUniversityScrapeOne?: Resolver<ResolversTypes['UniversityScrapeOutput'], ParentType, ContextType, RequireFields<MutationCreateUniversityScrapeOneArgs, 'universityScrapeInput'>>;
  createUniversityUser?: Resolver<Maybe<ResolversTypes['CreateUniversityUserMutation']>, ParentType, ContextType, RequireFields<MutationCreateUniversityUserArgs, never>>;
  createUserMutation?: Resolver<Maybe<ResolversTypes['CreateUserMutation']>, ParentType, ContextType, RequireFields<MutationCreateUserMutationArgs, 'email' | 'firstName'>>;
  createUserReport?: Resolver<ResolversTypes['UserReport'], ParentType, ContextType, RequireFields<MutationCreateUserReportArgs, 'userReportUserInputDTO'>>;
  createVideoUploadLink?: Resolver<ResolversTypes['VideoUploadLink'], ParentType, ContextType, RequireFields<MutationCreateVideoUploadLinkArgs, 'videoUploadLinkInput'>>;
  createVirtualEvent?: Resolver<ResolversTypes['VirtualEvent'], ParentType, ContextType, RequireFields<MutationCreateVirtualEventArgs, 'createVirtualEventInput' | 'universityId'>>;
  createVirtualEventProspectReport?: Resolver<Maybe<ResolversTypes['ReportAnalytics']>, ParentType, ContextType, RequireFields<MutationCreateVirtualEventProspectReportArgs, 'virtualEventId'>>;
  csvUploadAmbassadorFields?: Resolver<Maybe<ResolversTypes['CSVUploadAmbassadorFieldsMutation']>, ParentType, ContextType, RequireFields<MutationCsvUploadAmbassadorFieldsArgs, 'file' | 'uniSlug'>>;
  csvUploadApplicants?: Resolver<Maybe<ResolversTypes['CSVUploadApplicantsMutation']>, ParentType, ContextType, RequireFields<MutationCsvUploadApplicantsArgs, 'file' | 'password' | 'uniSlug'>>;
  deactivateApplicants?: Resolver<Maybe<ResolversTypes['DeactivateApplicantsMutation']>, ParentType, ContextType, RequireFields<MutationDeactivateApplicantsArgs, never>>;
  deactivateMentors?: Resolver<Maybe<ResolversTypes['DeactivateMentorsMutation']>, ParentType, ContextType, RequireFields<MutationDeactivateMentorsArgs, never>>;
  deleteAdminRecommendation?: Resolver<ResolversTypes['AdminRecommendation'], ParentType, ContextType, RequireFields<MutationDeleteAdminRecommendationArgs, 'recommendationId'>>;
  deleteApplicant?: Resolver<Maybe<ResolversTypes['DeleteApplicantMutation']>, ParentType, ContextType>;
  deleteBlogPost?: Resolver<Maybe<ResolversTypes['DeletePostMutation']>, ParentType, ContextType, RequireFields<MutationDeleteBlogPostArgs, never>>;
  deleteCanonicalQuestion?: Resolver<Maybe<ResolversTypes['DeleteCanonicalQuestion']>, ParentType, ContextType, RequireFields<MutationDeleteCanonicalQuestionArgs, 'question'>>;
  deleteChatRoom?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteChatRoomArgs, 'chatRoomId'>>;
  deleteCommunityConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationDeleteCommunityConversationArgs, 'DeleteCommunityConversationDTO'>>;
  deleteConversationMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationDeleteConversationMessageArgs, 'deleteMessageDto'>>;
  deleteCustomAttributeOptions?: Resolver<Maybe<ResolversTypes['DeleteAttributeOptionsMutation']>, ParentType, ContextType, RequireFields<MutationDeleteCustomAttributeOptionsArgs, never>>;
  deleteCustomAttributes?: Resolver<Maybe<ResolversTypes['DeleteCustomAttributesMutation']>, ParentType, ContextType, RequireFields<MutationDeleteCustomAttributesArgs, never>>;
  deleteDegreeLevel?: Resolver<Maybe<ResolversTypes['DeleteDegreeLevelMutation']>, ParentType, ContextType, RequireFields<MutationDeleteDegreeLevelArgs, 'degreeLevelId' | 'universitySlug'>>;
  deleteEventPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteEventPageArgs, 'eventPageId'>>;
  deleteExternalContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteExternalContentArgs, 'externalContentId'>>;
  deleteFaqAnswer?: Resolver<Maybe<ResolversTypes['DeleteFaqAnswer']>, ParentType, ContextType, RequireFields<MutationDeleteFaqAnswerArgs, 'answer'>>;
  deleteLiveEvent?: Resolver<Maybe<ResolversTypes['DeleteLiveEventMutation']>, ParentType, ContextType, RequireFields<MutationDeleteLiveEventArgs, never>>;
  deleteLiveEventFeed?: Resolver<Maybe<ResolversTypes['DeleteLiveEventFeedMutation']>, ParentType, ContextType, RequireFields<MutationDeleteLiveEventFeedArgs, never>>;
  deleteLiveEventMessageMutation?: Resolver<Maybe<ResolversTypes['DeleteLiveEventMessageMutation']>, ParentType, ContextType, RequireFields<MutationDeleteLiveEventMessageMutationArgs, never>>;
  deleteLiveSession?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteLiveSessionArgs, 'liveSessionId'>>;
  deleteRecording?: Resolver<ResolversTypes['DeleteRecordingResponse'], ParentType, ContextType, RequireFields<MutationDeleteRecordingArgs, 'sid' | 'universityId'>>;
  deleteResearchTime?: Resolver<Maybe<ResolversTypes['DeleteResearchTimeMutation']>, ParentType, ContextType, RequireFields<MutationDeleteResearchTimeArgs, 'id'>>;
  deleteSubTopic?: Resolver<Maybe<ResolversTypes['DeleteSubTopicMutation']>, ParentType, ContextType, RequireFields<MutationDeleteSubTopicArgs, 'id'>>;
  deleteTopic?: Resolver<Maybe<ResolversTypes['DeleteTopicMutation']>, ParentType, ContextType, RequireFields<MutationDeleteTopicArgs, 'id'>>;
  deleteUniversityKnowledgeBaseSourceById?: Resolver<ResolversTypes['KnowledgeBaseDeleteEntryOutput'], ParentType, ContextType, RequireFields<MutationDeleteUniversityKnowledgeBaseSourceByIdArgs, 'knowledgeBaseDeleteEntryInput'>>;
  deleteUniversityKnowledgeBaseSourceByPatternMatch?: Resolver<ResolversTypes['DeleteKnowledgeBaseEntriesOutput'], ParentType, ContextType, RequireFields<MutationDeleteUniversityKnowledgeBaseSourceByPatternMatchArgs, 'deleteKnowledgeBaseEntriesByPatternMatchInput'>>;
  deleteUniversityKnowledgeBaseSourceByPatternMismatch?: Resolver<ResolversTypes['DeleteKnowledgeBaseEntriesOutput'], ParentType, ContextType, RequireFields<MutationDeleteUniversityKnowledgeBaseSourceByPatternMismatchArgs, 'deleteKnowledgeBaseEntriesByPatternMismatchInput'>>;
  deleteUniversityKnowledgeBaseSourceByUrlList?: Resolver<ResolversTypes['DeleteKnowledgeBaseEntriesOutput'], ParentType, ContextType, RequireFields<MutationDeleteUniversityKnowledgeBaseSourceByUrlListArgs, 'deleteKnowledgeBaseEntriesByUrlListInput'>>;
  deleteUniversityUser?: Resolver<Maybe<ResolversTypes['DeleteUniversityUserMutation']>, ParentType, ContextType, RequireFields<MutationDeleteUniversityUserArgs, never>>;
  deleteVirtualEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteVirtualEventArgs, 'virtualEventId'>>;
  deleteYearOfStudy?: Resolver<Maybe<ResolversTypes['DeleteYearOfStudyMutation']>, ParentType, ContextType, RequireFields<MutationDeleteYearOfStudyArgs, 'yearOfStudyId'>>;
  editAdminRecommendation?: Resolver<ResolversTypes['AdminRecommendation'], ParentType, ContextType, RequireFields<MutationEditAdminRecommendationArgs, 'adminRecommendationInput' | 'recommendationId'>>;
  editAlert?: Resolver<Maybe<ResolversTypes['EditAlertMutation']>, ParentType, ContextType, RequireFields<MutationEditAlertArgs, never>>;
  editBlogPost?: Resolver<Maybe<ResolversTypes['EditPostMutation']>, ParentType, ContextType, RequireFields<MutationEditBlogPostArgs, never>>;
  editBlogTopic?: Resolver<Maybe<ResolversTypes['EditBlogTopicMutation']>, ParentType, ContextType, RequireFields<MutationEditBlogTopicArgs, never>>;
  editCommunity?: Resolver<ResolversTypes['Community'], ParentType, ContextType, RequireFields<MutationEditCommunityArgs, 'EditCommunityDTO'>>;
  editCommunityConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationEditCommunityConversationArgs, 'EditCommunityConversationDTO'>>;
  editCookieConsentPreferences?: Resolver<ResolversTypes['CookieConsentOutputDTO'], ParentType, ContextType, RequireFields<MutationEditCookieConsentPreferencesArgs, 'CookieConsentInputDTO'>>;
  editCustomAttribute?: Resolver<Maybe<ResolversTypes['EditCustomAttributeMutation']>, ParentType, ContextType, RequireFields<MutationEditCustomAttributeArgs, never>>;
  editCustomAttributeOption?: Resolver<Maybe<ResolversTypes['EditAttributeOptionMutation']>, ParentType, ContextType, RequireFields<MutationEditCustomAttributeOptionArgs, never>>;
  editCustomAttributes?: Resolver<Maybe<ResolversTypes['EditCustomAttributesMutation']>, ParentType, ContextType, RequireFields<MutationEditCustomAttributesArgs, never>>;
  editCustomWelcomeMessage?: Resolver<Maybe<ResolversTypes['EditCustomWelcomeMessageMutation']>, ParentType, ContextType, RequireFields<MutationEditCustomWelcomeMessageArgs, never>>;
  editDegreeLevel?: Resolver<Maybe<ResolversTypes['EditDegreeLevelMutation']>, ParentType, ContextType, RequireFields<MutationEditDegreeLevelArgs, never>>;
  editDegreeName?: Resolver<Maybe<ResolversTypes['EditDegreeNameMutation']>, ParentType, ContextType, RequireFields<MutationEditDegreeNameArgs, never>>;
  editDegrees?: Resolver<Maybe<ResolversTypes['EditDegreesMutation']>, ParentType, ContextType, RequireFields<MutationEditDegreesArgs, never>>;
  editExternalEvent?: Resolver<Maybe<ResolversTypes['EditExternalEventMutation']>, ParentType, ContextType, RequireFields<MutationEditExternalEventArgs, 'description' | 'name'>>;
  editLiveEvent?: Resolver<Maybe<ResolversTypes['EditLiveEventMutation']>, ParentType, ContextType, RequireFields<MutationEditLiveEventArgs, never>>;
  editLiveEventDetails?: Resolver<Maybe<ResolversTypes['EditLiveEventDetailsMutation']>, ParentType, ContextType, RequireFields<MutationEditLiveEventDetailsArgs, never>>;
  editLiveEventFeed?: Resolver<Maybe<ResolversTypes['EditLiveEventFeedMutation']>, ParentType, ContextType, RequireFields<MutationEditLiveEventFeedArgs, never>>;
  editLiveEventsLandingPage?: Resolver<Maybe<ResolversTypes['EditLiveEventsLandingPageMutation']>, ParentType, ContextType, RequireFields<MutationEditLiveEventsLandingPageArgs, 'description' | 'externalEventIds' | 'liveEventIds' | 'title'>>;
  editOpenDay?: Resolver<Maybe<ResolversTypes['EditOpenDayMutation']>, ParentType, ContextType, RequireFields<MutationEditOpenDayArgs, 'description' | 'externalEventIds' | 'liveEventIds' | 'openDayId' | 'title'>>;
  editOrganisation?: Resolver<Maybe<ResolversTypes['EditOrganisationMutation']>, ParentType, ContextType, RequireFields<MutationEditOrganisationArgs, 'organisationId'>>;
  editUniversity?: Resolver<Maybe<ResolversTypes['EditUniversityMutation']>, ParentType, ContextType, RequireFields<MutationEditUniversityArgs, never>>;
  editYearOfStudy?: Resolver<Maybe<ResolversTypes['EditYearOfStudyMutation']>, ParentType, ContextType, RequireFields<MutationEditYearOfStudyArgs, 'degreeLevelName' | 'name' | 'universitySlug' | 'yearOfStudyId'>>;
  emailInvitesDataForCommunity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationEmailInvitesDataForCommunityArgs, 'communityId' | 'filters' | 'retrieveArchived'>>;
  enableCustomWelcomeMessage?: Resolver<Maybe<ResolversTypes['EnableCustomWelcomeMessageMutation']>, ParentType, ContextType, RequireFields<MutationEnableCustomWelcomeMessageArgs, never>>;
  enableCustomWelcomeMessageStaff?: Resolver<Maybe<ResolversTypes['EnableCustomWelcomeMessageStaffMutation']>, ParentType, ContextType, RequireFields<MutationEnableCustomWelcomeMessageStaffArgs, never>>;
  enableFeatures?: Resolver<Maybe<ResolversTypes['EnableFeaturesMutation']>, ParentType, ContextType, RequireFields<MutationEnableFeaturesArgs, never>>;
  exportAnalyticsCsv?: Resolver<Maybe<ResolversTypes['ExportAnalyticsCsvMutation']>, ParentType, ContextType, RequireFields<MutationExportAnalyticsCsvArgs, never>>;
  forwardApplicantToUniversity?: Resolver<Maybe<ResolversTypes['ForwardApplicantToUniversity']>, ParentType, ContextType, RequireFields<MutationForwardApplicantToUniversityArgs, never>>;
  generateAnalyticsDashboardToken?: Resolver<Maybe<ResolversTypes['GenerateAnalyticsDashboardTokenMutation']>, ParentType, ContextType, RequireFields<MutationGenerateAnalyticsDashboardTokenArgs, 'integrationId'>>;
  generateDownloadUrl?: Resolver<Maybe<ResolversTypes['GetDownloadableURLMutation']>, ParentType, ContextType, RequireFields<MutationGenerateDownloadUrlArgs, never>>;
  generateLiveEventReport?: Resolver<Maybe<ResolversTypes['GenerateLiveEventReport']>, ParentType, ContextType, RequireFields<MutationGenerateLiveEventReportArgs, never>>;
  generateRecommendations?: Resolver<Array<ResolversTypes['Recommendation']>, ParentType, ContextType, RequireFields<MutationGenerateRecommendationsArgs, 'generateRecommendationsInput'>>;
  getMarketplaceApplicant?: Resolver<Maybe<ResolversTypes['GetMarketplaceApplicantMutation']>, ParentType, ContextType, RequireFields<MutationGetMarketplaceApplicantArgs, never>>;
  getOrCreateWidgetConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationGetOrCreateWidgetConversationArgs, 'widgetConversationDto'>>;
  getSignedS3PostFields?: Resolver<Maybe<ResolversTypes['GetSignedS3PostFieldsMutation']>, ParentType, ContextType, RequireFields<MutationGetSignedS3PostFieldsArgs, never>>;
  getSignedS3Url?: Resolver<Maybe<ResolversTypes['GetSignedS3UrlMutation']>, ParentType, ContextType, RequireFields<MutationGetSignedS3UrlArgs, never>>;
  hideMentorInMarketplace?: Resolver<Maybe<ResolversTypes['HideMentorInMarketplaceMutation']>, ParentType, ContextType, RequireFields<MutationHideMentorInMarketplaceArgs, 'mentorId'>>;
  initialiseRecording?: Resolver<ResolversTypes['InitialiseRecordingResponse'], ParentType, ContextType, RequireFields<MutationInitialiseRecordingArgs, 'data'>>;
  initiateUserDeletion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inviteApplicants?: Resolver<Maybe<ResolversTypes['InviteApplicantsMutation']>, ParentType, ContextType, RequireFields<MutationInviteApplicantsArgs, never>>;
  inviteBuddies?: Resolver<Maybe<ResolversTypes['InviteBuddiesMutation']>, ParentType, ContextType, RequireFields<MutationInviteBuddiesArgs, never>>;
  inviteEmailsToCommunity?: Resolver<ResolversTypes['CommunityInviteResponse'], ParentType, ContextType, RequireFields<MutationInviteEmailsToCommunityArgs, 'communityId' | 'emails'>>;
  inviteMentors?: Resolver<Maybe<ResolversTypes['InviteMentorsMutation']>, ParentType, ContextType, RequireFields<MutationInviteMentorsArgs, never>>;
  joinChatGroup?: Resolver<Maybe<ResolversTypes['JoinChatGroupMutation']>, ParentType, ContextType, RequireFields<MutationJoinChatGroupArgs, never>>;
  joinCommunity?: Resolver<ResolversTypes['CommunityJoinResponse'], ParentType, ContextType, RequireFields<MutationJoinCommunityArgs, 'communityId'>>;
  joinCommunityByInviteLink?: Resolver<ResolversTypes['CommunityJoinResponse'], ParentType, ContextType, RequireFields<MutationJoinCommunityByInviteLinkArgs, 'communityId'>>;
  joinCommunityConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationJoinCommunityConversationArgs, 'conversationId'>>;
  joinCommunityForAdmins?: Resolver<ResolversTypes['CommunityJoinResponse'], ParentType, ContextType, RequireFields<MutationJoinCommunityForAdminsArgs, 'communityId'>>;
  labelSentence?: Resolver<Maybe<ResolversTypes['LabelSentenceMutation']>, ParentType, ContextType, RequireFields<MutationLabelSentenceArgs, 'chatGroupId' | 'classifiedSentenceId' | 'messageId' | 'subTopicExternalId' | 'text' | 'topicExternalId'>>;
  leaveChatGroup?: Resolver<Maybe<ResolversTypes['LeaveChatGroupMutation']>, ParentType, ContextType, RequireFields<MutationLeaveChatGroupArgs, never>>;
  leaveCommunityChatGroup?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationLeaveCommunityChatGroupArgs, 'conversationId'>>;
  likeConversationMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationLikeConversationMessageArgs, 'likeMessageDto'>>;
  likeMessage?: Resolver<Maybe<ResolversTypes['LikeMessageMutation']>, ParentType, ContextType, RequireFields<MutationLikeMessageArgs, never>>;
  linkOrganisations?: Resolver<Maybe<ResolversTypes['LinkOrganisationsMutation']>, ParentType, ContextType, RequireFields<MutationLinkOrganisationsArgs, 'childOrganisationId' | 'parentOrganisationId'>>;
  liveEventFeedbackForm?: Resolver<Maybe<ResolversTypes['LiveEventFeedbackFormMutation']>, ParentType, ContextType, RequireFields<MutationLiveEventFeedbackFormArgs, never>>;
  logout?: Resolver<Maybe<ResolversTypes['LogoutMutation']>, ParentType, ContextType>;
  manageOrganisationAccess?: Resolver<Maybe<ResolversTypes['ManageOrganisationAccessMutation']>, ParentType, ContextType, RequireFields<MutationManageOrganisationAccessArgs, 'access' | 'organisationId'>>;
  manuallyClassify?: Resolver<Maybe<ResolversTypes['ManuallyClassifyMutation']>, ParentType, ContextType, RequireFields<MutationManuallyClassifyArgs, 'classificationId'>>;
  markAssistantConversationAsViewed?: Resolver<ResolversTypes['AssistantConversationHistory'], ParentType, ContextType, RequireFields<MutationMarkAssistantConversationAsViewedArgs, 'markAssistantConversationAsViewedInput'>>;
  mentorBroadcastMessage?: Resolver<Maybe<ResolversTypes['MentorBroadcastMessageMutation']>, ParentType, ContextType, RequireFields<MutationMentorBroadcastMessageArgs, never>>;
  messagePublicBot?: Resolver<Maybe<ResolversTypes['MessagePublicBotMutation']>, ParentType, ContextType, RequireFields<MutationMessagePublicBotArgs, never>>;
  moderateChatReport?: Resolver<ResolversTypes['ChatReport'], ParentType, ContextType, RequireFields<MutationModerateChatReportArgs, 'chatReportModeratorInputDTO'>>;
  moderateUserReport?: Resolver<ResolversTypes['UserReport'], ParentType, ContextType, RequireFields<MutationModerateUserReportArgs, 'userReportModeratorInputDTO'>>;
  pinMessage?: Resolver<Maybe<ResolversTypes['PinMessageMutation']>, ParentType, ContextType, RequireFields<MutationPinMessageArgs, never>>;
  pinMessageInConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationPinMessageInConversationArgs, 'pinMessageDTO'>>;
  readChat?: Resolver<Maybe<ResolversTypes['ReadChatMutation']>, ParentType, ContextType, RequireFields<MutationReadChatArgs, never>>;
  recordConsent?: Resolver<ResolversTypes['RecordingConsent'], ParentType, ContextType, RequireFields<MutationRecordConsentArgs, 'channelId' | 'time' | 'universityId'>>;
  recordFailure?: Resolver<ResolversTypes['RecordingFailureResponse'], ParentType, ContextType, RequireFields<MutationRecordFailureArgs, 'data'>>;
  recordLastMessageReadByUser?: Resolver<ResolversTypes['LastRead'], ParentType, ContextType, RequireFields<MutationRecordLastMessageReadByUserArgs, 'markReadMessageDto'>>;
  recordUserActivity?: Resolver<Maybe<ResolversTypes['RecordUserActivityMutation']>, ParentType, ContextType, RequireFields<MutationRecordUserActivityArgs, never>>;
  registerToLiveEvent?: Resolver<Maybe<ResolversTypes['RegisterToLiveEvent']>, ParentType, ContextType, RequireFields<MutationRegisterToLiveEventArgs, 'liveEventSlug' | 'universitySlug'>>;
  rejectRequestedPublicGroup?: Resolver<ResolversTypes['RequestedPublicGroup'], ParentType, ContextType, RequireFields<MutationRejectRequestedPublicGroupArgs, 'rejectRequestedPublicGroupInput'>>;
  removeApplicationInsight?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveApplicationInsightArgs, 'removeApplicantInsightInput'>>;
  removeBuddyInvites?: Resolver<Maybe<ResolversTypes['RemoveBuddyInvitesMutation']>, ParentType, ContextType, RequireFields<MutationRemoveBuddyInvitesArgs, never>>;
  removeDevice?: Resolver<Maybe<ResolversTypes['RemoveDeviceMutation']>, ParentType, ContextType, RequireFields<MutationRemoveDeviceArgs, never>>;
  removeMembersFromCommunity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveMembersFromCommunityArgs, 'removeMembersDTO'>>;
  removeMentorInvites?: Resolver<Maybe<ResolversTypes['RemoveMentorInvitesMutation']>, ParentType, ContextType, RequireFields<MutationRemoveMentorInvitesArgs, never>>;
  removeReactionFromMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationRemoveReactionFromMessageArgs, 'reactionDto'>>;
  removeUserFromCommunityChatGroup?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRemoveUserFromCommunityChatGroupArgs, 'conversationId' | 'userId'>>;
  removeVote?: Resolver<ResolversTypes['Poll'], ParentType, ContextType, RequireFields<MutationRemoveVoteArgs, 'chatMessageId' | 'option'>>;
  requestAnalyticsExport?: Resolver<Maybe<ResolversTypes['RequestAnalyticsExportMutation']>, ParentType, ContextType, RequireFields<MutationRequestAnalyticsExportArgs, never>>;
  requestPasswordReset?: Resolver<Maybe<ResolversTypes['RequestPasswordResetMutation']>, ParentType, ContextType, RequireFields<MutationRequestPasswordResetArgs, never>>;
  requestPasswordSet?: Resolver<Maybe<ResolversTypes['RequestPasswordSetMutation']>, ParentType, ContextType, RequireFields<MutationRequestPasswordSetArgs, never>>;
  requestPublicGroup?: Resolver<ResolversTypes['RequestedPublicGroup'], ParentType, ContextType, RequireFields<MutationRequestPublicGroupArgs, 'requestPublicGroupInput'>>;
  requestResetPassword?: Resolver<Maybe<ResolversTypes['ResetPasswordMobileMutation']>, ParentType, ContextType, RequireFields<MutationRequestResetPasswordArgs, never>>;
  requestResetPasswordMobile?: Resolver<Maybe<ResolversTypes['PasswordResetMobileMutation']>, ParentType, ContextType, RequireFields<MutationRequestResetPasswordMobileArgs, never>>;
  requestResetPasswordMobileV2?: Resolver<Maybe<ResolversTypes['PasswordResetMobileMutationV2']>, ParentType, ContextType, RequireFields<MutationRequestResetPasswordMobileV2Args, never>>;
  resetPassword?: Resolver<Maybe<ResolversTypes['ResetPasswordMutation']>, ParentType, ContextType, RequireFields<MutationResetPasswordArgs, never>>;
  resetPasswordEmail?: Resolver<Maybe<ResolversTypes['SendResetPasswordEmail']>, ParentType, ContextType, RequireFields<MutationResetPasswordEmailArgs, never>>;
  resetPasswordV2?: Resolver<Maybe<ResolversTypes['ResetPasswordMutationV2']>, ParentType, ContextType, RequireFields<MutationResetPasswordV2Args, never>>;
  resolveChats?: Resolver<Maybe<ResolversTypes['ResolveUniversityChatsMutation']>, ParentType, ContextType, RequireFields<MutationResolveChatsArgs, never>>;
  resolveConversationMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationResolveConversationMessageArgs, 'resolveMessageDto'>>;
  runManageCommand?: Resolver<Maybe<ResolversTypes['RunManageCommandMutation']>, ParentType, ContextType, RequireFields<MutationRunManageCommandArgs, never>>;
  saveAdminRecommendation?: Resolver<ResolversTypes['AdminRecommendation'], ParentType, ContextType, RequireFields<MutationSaveAdminRecommendationArgs, 'adminRecommendationInput'>>;
  saveAmbassadorRecommendation?: Resolver<ResolversTypes['AmbassadorRecommendation'], ParentType, ContextType, RequireFields<MutationSaveAmbassadorRecommendationArgs, 'ambassadorRecommendationInput'>>;
  saveAppliedUsers?: Resolver<ResolversTypes['SaveAppliedUsersModel'], ParentType, ContextType, RequireFields<MutationSaveAppliedUsersArgs, 'saveAppliedUsersInput'>>;
  saveAssistantConversationEmailLead?: Resolver<ResolversTypes['AssistantConversationLead'], ParentType, ContextType, RequireFields<MutationSaveAssistantConversationEmailLeadArgs, 'saveAssistantConversationEmailLeadInput'>>;
  saveAssistantConversationFeedback?: Resolver<ResolversTypes['AssistantConversationFeedback'], ParentType, ContextType, RequireFields<MutationSaveAssistantConversationFeedbackArgs, 'saveAssistantConversationFeedbackInput'>>;
  saveCookieConsentPreferences?: Resolver<ResolversTypes['CookieConsentOutputDTO'], ParentType, ContextType, RequireFields<MutationSaveCookieConsentPreferencesArgs, 'CookieConsentInputDTO'>>;
  saveInboxPromptAnswer?: Resolver<Array<ResolversTypes['InboxPromptAnswer']>, ParentType, ContextType, RequireFields<MutationSaveInboxPromptAnswerArgs, 'inboxPromptAnswerInput'>>;
  saveInsightsByCommunity?: Resolver<ResolversTypes['Insights'], ParentType, ContextType, RequireFields<MutationSaveInsightsByCommunityArgs, 'communityId' | 'emails'>>;
  saveNotInterestedStage?: Resolver<ResolversTypes['UserDecisionStageOutput'], ParentType, ContextType, RequireFields<MutationSaveNotInterestedStageArgs, 'notInterestedStageInput'>>;
  saveSatisfactionSurvey?: Resolver<ResolversTypes['SatisfactionSurveyEntity'], ParentType, ContextType, RequireFields<MutationSaveSatisfactionSurveyArgs, 'SatisfactionSurveyDTO'>>;
  saveUniversityQuestionAnswer?: Resolver<Array<ResolversTypes['UniversityQuestionAnswer']>, ParentType, ContextType, RequireFields<MutationSaveUniversityQuestionAnswerArgs, 'saveUniversityQuestionAnswerInput'>>;
  saveUserDecisionStage?: Resolver<ResolversTypes['UserDecisionStageOutput'], ParentType, ContextType, RequireFields<MutationSaveUserDecisionStageArgs, 'decisionStageInput'>>;
  saveVisitedRecommendation?: Resolver<ResolversTypes['Recommendation'], ParentType, ContextType, RequireFields<MutationSaveVisitedRecommendationArgs, 'saveVisitedRecommendationInput'>>;
  sendAssistantMessage?: Resolver<ResolversTypes['AssistantConversationMessage'], ParentType, ContextType, RequireFields<MutationSendAssistantMessageArgs, 'sendAssistantMessageInput'>>;
  sendCommunityVerificationCode?: Resolver<Maybe<ResolversTypes['SendCommunityVerificationCode']>, ParentType, ContextType>;
  sendConversationMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationSendConversationMessageArgs, 'messageDto'>>;
  sendFeatureNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendFeatureNotificationArgs, 'FeatureNotificationRequestDTO'>>;
  sendFeedMessage?: Resolver<Maybe<ResolversTypes['SendFeedMessageMutation']>, ParentType, ContextType, RequireFields<MutationSendFeedMessageArgs, never>>;
  sendLiveEventMessage?: Resolver<Maybe<ResolversTypes['SendLiveEventMessageMutation']>, ParentType, ContextType, RequireFields<MutationSendLiveEventMessageArgs, never>>;
  sendMessage?: Resolver<Maybe<ResolversTypes['SendMessageMutation']>, ParentType, ContextType, RequireFields<MutationSendMessageArgs, never>>;
  sendOpenDayProspectReport?: Resolver<Maybe<ResolversTypes['SendOpenDayProspectReport']>, ParentType, ContextType, RequireFields<MutationSendOpenDayProspectReportArgs, 'openDayId'>>;
  sendVerificationCode?: Resolver<Maybe<ResolversTypes['SendVerificationCode']>, ParentType, ContextType, RequireFields<MutationSendVerificationCodeArgs, 'source'>>;
  setActiveBlogTopics?: Resolver<Maybe<ResolversTypes['SetActiveBlogTopicsMutation']>, ParentType, ContextType, RequireFields<MutationSetActiveBlogTopicsArgs, never>>;
  setAllNotificationsRead?: Resolver<Maybe<ResolversTypes['SetAllNotificationsReadMutation']>, ParentType, ContextType>;
  setArchivedContentBrief?: Resolver<Maybe<ResolversTypes['SetArchivedContentBriefMutation']>, ParentType, ContextType, RequireFields<MutationSetArchivedContentBriefArgs, 'archived' | 'contentBriefId' | 'universityId'>>;
  setArchivedDegreeLevel?: Resolver<Maybe<ResolversTypes['SetArchivedDegreeLevelMutation']>, ParentType, ContextType, RequireFields<MutationSetArchivedDegreeLevelArgs, 'archived' | 'degreeLevelId' | 'universitySlug'>>;
  setArchivedYearOfStudy?: Resolver<Maybe<ResolversTypes['SetArchivedYearOfStudyMutation']>, ParentType, ContextType, RequireFields<MutationSetArchivedYearOfStudyArgs, 'archived' | 'universitySlug' | 'yearOfStudyId'>>;
  setBlockUser?: Resolver<Maybe<ResolversTypes['BlockUserMutation']>, ParentType, ContextType, RequireFields<MutationSetBlockUserArgs, never>>;
  setBlockUserV2?: Resolver<Maybe<ResolversTypes['BlockUserMutationV2']>, ParentType, ContextType, RequireFields<MutationSetBlockUserV2Args, never>>;
  setBlogPostStatus?: Resolver<Maybe<ResolversTypes['UpdatePostStatusMutation']>, ParentType, ContextType, RequireFields<MutationSetBlogPostStatusArgs, never>>;
  setBuddiesListFilters?: Resolver<Maybe<ResolversTypes['SetBuddiesListFilters']>, ParentType, ContextType, RequireFields<MutationSetBuddiesListFiltersArgs, never>>;
  setChatGroupLabels?: Resolver<Maybe<ResolversTypes['SetChatGroupLabelsMutation']>, ParentType, ContextType, RequireFields<MutationSetChatGroupLabelsArgs, never>>;
  setDeviceToken?: Resolver<ResolversTypes['DeviceToken'], ParentType, ContextType, RequireFields<MutationSetDeviceTokenArgs, 'SetDeviceTokenDTO'>>;
  setInterestsForUser?: Resolver<Array<ResolversTypes['UserInterest']>, ParentType, ContextType, RequireFields<MutationSetInterestsForUserArgs, 'setUserInterestsInput'>>;
  setMentorFieldOrder?: Resolver<Maybe<ResolversTypes['SetMentorFieldOrderMutation']>, ParentType, ContextType, RequireFields<MutationSetMentorFieldOrderArgs, never>>;
  setMentorListFilterSelection?: Resolver<Maybe<ResolversTypes['SetMentorListFilterSelection']>, ParentType, ContextType, RequireFields<MutationSetMentorListFilterSelectionArgs, never>>;
  setOnline?: Resolver<Maybe<ResolversTypes['SetOnlineMutation']>, ParentType, ContextType, RequireFields<MutationSetOnlineArgs, never>>;
  setResolveChatGroup?: Resolver<Maybe<ResolversTypes['SetResolveChatGroupMutation']>, ParentType, ContextType, RequireFields<MutationSetResolveChatGroupArgs, never>>;
  setUniversityUnblockUsers?: Resolver<Maybe<ResolversTypes['UniversityBlockUsersMutation']>, ParentType, ContextType, RequireFields<MutationSetUniversityUnblockUsersArgs, never>>;
  setUserProfile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType, RequireFields<MutationSetUserProfileArgs, 'data'>>;
  setWillReply?: Resolver<Maybe<ResolversTypes['SetWillReplyMutation']>, ParentType, ContextType, RequireFields<MutationSetWillReplyArgs, never>>;
  showMentorInMarketplace?: Resolver<Maybe<ResolversTypes['ShowMentorInMarketplaceMutation']>, ParentType, ContextType, RequireFields<MutationShowMentorInMarketplaceArgs, 'mentorId'>>;
  startRecordingVideo?: Resolver<Maybe<ResolversTypes['RecordingResponse']>, ParentType, ContextType, RequireFields<MutationStartRecordingVideoArgs, 'data'>>;
  stopRecordingVideo?: Resolver<Maybe<ResolversTypes['RecordingResponse']>, ParentType, ContextType, RequireFields<MutationStopRecordingVideoArgs, 'data'>>;
  stopUniversityCrawl?: Resolver<Maybe<ResolversTypes['UniversityScrapeStopOutput']>, ParentType, ContextType, RequireFields<MutationStopUniversityCrawlArgs, 'universityStopCrawlInput'>>;
  toggleCommunityChatGroupMute?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationToggleCommunityChatGroupMuteArgs, 'conversationId' | 'status'>>;
  toggleCrmIntegrationSync?: Resolver<Maybe<ResolversTypes['ToggleCrmIntegrationSync']>, ParentType, ContextType, RequireFields<MutationToggleCrmIntegrationSyncArgs, never>>;
  toggleCustomAttributeCrmSync?: Resolver<Maybe<ResolversTypes['ToggleCustomAttributeCrmSyncMutation']>, ParentType, ContextType, RequireFields<MutationToggleCustomAttributeCrmSyncArgs, never>>;
  toggleDegreesActive?: Resolver<Maybe<ResolversTypes['ToggleDegreesActiveMutation']>, ParentType, ContextType, RequireFields<MutationToggleDegreesActiveArgs, never>>;
  toggleFeatureAvailability?: Resolver<Maybe<ResolversTypes['ToggleFeatureAvailabilityMutation']>, ParentType, ContextType, RequireFields<MutationToggleFeatureAvailabilityArgs, never>>;
  trackLiveEvent?: Resolver<Maybe<ResolversTypes['TrackLiveEventMutation']>, ParentType, ContextType, RequireFields<MutationTrackLiveEventArgs, never>>;
  trackOpenDay?: Resolver<Maybe<ResolversTypes['TrackOpenDayMutation']>, ParentType, ContextType, RequireFields<MutationTrackOpenDayArgs, 'name' | 'openDayId' | 'product' | 'universitySlug'>>;
  unlikeConversationMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationUnlikeConversationMessageArgs, 'likeMessageDto'>>;
  unpinMessage?: Resolver<Maybe<ResolversTypes['UnpinMessageMutation']>, ParentType, ContextType, RequireFields<MutationUnpinMessageArgs, never>>;
  unpinMessageInConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<MutationUnpinMessageInConversationArgs, 'unpinMessageDTO'>>;
  unresolveConversationMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType, RequireFields<MutationUnresolveConversationMessageArgs, 'resolveMessageDto'>>;
  updateApplicant?: Resolver<Maybe<ResolversTypes['UpdateApplicantMutation']>, ParentType, ContextType, RequireFields<MutationUpdateApplicantArgs, never>>;
  updateApplicantPreferences?: Resolver<Maybe<ResolversTypes['UpdateApplicantPreferencesMutation']>, ParentType, ContextType, RequireFields<MutationUpdateApplicantPreferencesArgs, never>>;
  updateCanonicalQuestion?: Resolver<Maybe<ResolversTypes['UpdateCanonicalQuestion']>, ParentType, ContextType, RequireFields<MutationUpdateCanonicalQuestionArgs, 'questionId'>>;
  updateChatRoom?: Resolver<ResolversTypes['ChatRoom'], ParentType, ContextType, RequireFields<MutationUpdateChatRoomArgs, 'chatRoomId' | 'updateChatRoomInput'>>;
  updateCommunityApplicantSignupsource?: Resolver<Maybe<ResolversTypes['UpdateCommunityApplicantSignupSourceMutation']>, ParentType, ContextType, RequireFields<MutationUpdateCommunityApplicantSignupsourceArgs, never>>;
  updateCrmServiceUniversityConfig?: Resolver<Maybe<ResolversTypes['UpdateUniversityConfigMutation']>, ParentType, ContextType, RequireFields<MutationUpdateCrmServiceUniversityConfigArgs, never>>;
  updateDirectMessagingMembersForLiveEvent?: Resolver<Maybe<ResolversTypes['UpdateDirectMessagingMembersForLiveEventMutation']>, ParentType, ContextType, RequireFields<MutationUpdateDirectMessagingMembersForLiveEventArgs, never>>;
  updateEventPage?: Resolver<ResolversTypes['EventPage'], ParentType, ContextType, RequireFields<MutationUpdateEventPageArgs, 'eventPageId' | 'updateEventPageInput'>>;
  updateExternalContent?: Resolver<ResolversTypes['ExternalContent'], ParentType, ContextType, RequireFields<MutationUpdateExternalContentArgs, 'externalContentId' | 'updateExternalContentInput'>>;
  updateFaqAnswer?: Resolver<Maybe<ResolversTypes['UpdateFaqAnswer']>, ParentType, ContextType, RequireFields<MutationUpdateFaqAnswerArgs, 'answerId'>>;
  updateHobsonsConnectIntegration?: Resolver<Maybe<ResolversTypes['UpdateHobsonsConnectIntegration']>, ParentType, ContextType, RequireFields<MutationUpdateHobsonsConnectIntegrationArgs, never>>;
  updateIntegrationFieldMapping?: Resolver<Maybe<ResolversTypes['UpdateIntegrationFieldMapping']>, ParentType, ContextType, RequireFields<MutationUpdateIntegrationFieldMappingArgs, never>>;
  updateLiveEventFeedSessionData?: Resolver<Maybe<ResolversTypes['UpdateLiveEventFeedSessionDataMutation']>, ParentType, ContextType, RequireFields<MutationUpdateLiveEventFeedSessionDataArgs, never>>;
  updateLiveSession?: Resolver<ResolversTypes['LiveSession'], ParentType, ContextType, RequireFields<MutationUpdateLiveSessionArgs, 'liveSessionId' | 'updateLiveSessionInput'>>;
  updateLiveSessionAnyUser?: Resolver<ResolversTypes['LiveSession'], ParentType, ContextType, RequireFields<MutationUpdateLiveSessionAnyUserArgs, 'liveSessionId' | 'updateLiveSessionInput'>>;
  updateManyUserMutation?: Resolver<Maybe<ResolversTypes['UpdateManyUserMutation']>, ParentType, ContextType, RequireFields<MutationUpdateManyUserMutationArgs, never>>;
  updateMarketplace?: Resolver<Maybe<ResolversTypes['UpdateMarketplaceMutation']>, ParentType, ContextType, RequireFields<MutationUpdateMarketplaceArgs, 'id'>>;
  updateMarketplaceApplicant?: Resolver<Maybe<ResolversTypes['UpdateMarketplaceApplicantMutation']>, ParentType, ContextType, RequireFields<MutationUpdateMarketplaceApplicantArgs, 'marketplace'>>;
  updateMarketplaceApplicantUniversityLink?: Resolver<Maybe<ResolversTypes['UpdateMarketplaceApplicantUniversityLinkMutation']>, ParentType, ContextType, RequireFields<MutationUpdateMarketplaceApplicantUniversityLinkArgs, 'marketplaceSlug' | 'universitySlug'>>;
  updateMentor?: Resolver<Maybe<ResolversTypes['UpdateMentorMutation']>, ParentType, ContextType, RequireFields<MutationUpdateMentorArgs, never>>;
  updateMentorPreferences?: Resolver<Maybe<ResolversTypes['UpdateMentorPreferences']>, ParentType, ContextType, RequireFields<MutationUpdateMentorPreferencesArgs, never>>;
  updateMyUniversityLink?: Resolver<Maybe<ResolversTypes['UpdateMyUniversityLinkMutation']>, ParentType, ContextType, RequireFields<MutationUpdateMyUniversityLinkArgs, never>>;
  updateRecordingLayout?: Resolver<Maybe<ResolversTypes['RecordingResponse']>, ParentType, ContextType, RequireFields<MutationUpdateRecordingLayoutArgs, 'data'>>;
  updateResearchTime?: Resolver<Maybe<ResolversTypes['UpdateResearchTimeMutation']>, ParentType, ContextType, RequireFields<MutationUpdateResearchTimeArgs, 'researchTimeData'>>;
  updateStaff?: Resolver<Maybe<ResolversTypes['UpdateStaffMutation']>, ParentType, ContextType, RequireFields<MutationUpdateStaffArgs, never>>;
  updateStaffContact?: Resolver<ResolversTypes['StaffContactOutput'], ParentType, ContextType, RequireFields<MutationUpdateStaffContactArgs, 'updateStaffContactInput'>>;
  updateSubTopic?: Resolver<Maybe<ResolversTypes['UpdateSubTopicMutation']>, ParentType, ContextType, RequireFields<MutationUpdateSubTopicArgs, 'id'>>;
  updateTag?: Resolver<Maybe<ResolversTypes['UpdateTagMutation']>, ParentType, ContextType, RequireFields<MutationUpdateTagArgs, 'id'>>;
  updateTopic?: Resolver<Maybe<ResolversTypes['UpdateTopicMutation']>, ParentType, ContextType, RequireFields<MutationUpdateTopicArgs, 'id'>>;
  updateUniversityBlockReport?: Resolver<Maybe<ResolversTypes['UpdateUniversityBlockReportMutation']>, ParentType, ContextType, RequireFields<MutationUpdateUniversityBlockReportArgs, never>>;
  updateUniversityUser?: Resolver<Maybe<ResolversTypes['UpdateUniversityUserMutation']>, ParentType, ContextType, RequireFields<MutationUpdateUniversityUserArgs, never>>;
  updateUniversityUserPreferences?: Resolver<Maybe<ResolversTypes['UpdateUniversityUserPreferencesMutation']>, ParentType, ContextType, RequireFields<MutationUpdateUniversityUserPreferencesArgs, never>>;
  updateUserMutation?: Resolver<Maybe<ResolversTypes['UpdateUserMutation']>, ParentType, ContextType, RequireFields<MutationUpdateUserMutationArgs, 'id'>>;
  updateUserPreferencesMutation?: Resolver<Maybe<ResolversTypes['UpdateUserPreferences']>, ParentType, ContextType, RequireFields<MutationUpdateUserPreferencesMutationArgs, never>>;
  updateVirtualEvent?: Resolver<ResolversTypes['VirtualEvent'], ParentType, ContextType, RequireFields<MutationUpdateVirtualEventArgs, 'updateVirtualEventInput' | 'virtualEventId'>>;
  verifyAccount?: Resolver<Maybe<ResolversTypes['VerifyAccount']>, ParentType, ContextType, RequireFields<MutationVerifyAccountArgs, 'code'>>;
  viewBlog?: Resolver<Maybe<ResolversTypes['ViewBlogMutation']>, ParentType, ContextType, RequireFields<MutationViewBlogArgs, 'blogId'>>;
};

export type NewsFeedGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewsFeedGroup'] = ResolversParentTypes['NewsFeedGroup']> = {
  conversationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationField'] = ResolversParentTypes['NotificationField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ObjectIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ObjectID'], any> {
  name: 'ObjectID';
}

export interface ObjectIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ObjectId'], any> {
  name: 'ObjectId';
}

export type OpenDayFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpenDayField'] = ResolversParentTypes['OpenDayField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExternalEventField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isStaffCarouselEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isStudentsCarouselEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  liveEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventDetailsField']>>>, ParentType, ContextType>;
  navigateOpenDays?: Resolver<Maybe<Array<Maybe<ResolversTypes['OpenDayField']>>>, ParentType, ContextType>;
  navigateTags?: Resolver<Maybe<Array<Maybe<ResolversTypes['TagField']>>>, ParentType, ContextType>;
  prospectLogins?: Resolver<Maybe<ResolversTypes['OpenDaysLoginField']>, ParentType, ContextType>;
  prospectSignups?: Resolver<Maybe<ResolversTypes['OpenDaysSignupField']>, ParentType, ContextType>;
  prospectsAttending?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['TagField']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpenDaysLoginFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpenDaysLoginField'] = ResolversParentTypes['OpenDaysLoginField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  openDay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  widget?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpenDaysSignupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpenDaysSignupField'] = ResolversParentTypes['OpenDaysSignupField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  openDay?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  widget?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OpenTextFieldValueFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OpenTextFieldValueField'] = ResolversParentTypes['OpenTextFieldValueField']> = {
  attribute?: Resolver<Maybe<ResolversTypes['CustomAttributeField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationBuddyLinkFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganisationBuddyLinkField'] = ResolversParentTypes['OrganisationBuddyLinkField']> = {
  organisation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganisationField'] = ResolversParentTypes['OrganisationField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  childOrganisations?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChildOrganisationField']>>>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkedInstance?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organisationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parentOrganisation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutboxChatGroupFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OutboxChatGroupField'] = ResolversParentTypes['OutboxChatGroupField']> = {
  aux?: Resolver<Maybe<ResolversTypes['ChatGroupAuxiliaryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['LastMessageField']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnyUserField']>>>, ParentType, ContextType>;
  opponent?: Resolver<Maybe<ResolversTypes['AnyChatUserField']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  sendbirdChannelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unansweredByMentor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unreadMessageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutputFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['OutputField'] = ResolversParentTypes['OutputField']> = {
  returncode?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stderr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stdout?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageMentorFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageMentorField'] = ResolversParentTypes['PageMentorField']> = {
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedAdminRecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedAdminRecommendation'] = ResolversParentTypes['PaginatedAdminRecommendation']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['AdminRecommendation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedApplicantInviteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedApplicantInvite'] = ResolversParentTypes['PaginatedApplicantInvite']> = {
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantInvite']>>>, ParentType, ContextType>;
  totalNum?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedApplicationInsightModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedApplicationInsightModel'] = ResolversParentTypes['PaginatedApplicationInsightModel']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ApplicationInsightModel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedChatMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedChatMessages'] = ResolversParentTypes['PaginatedChatMessages']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  messages?: Resolver<Array<ResolversTypes['ChatMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedChatRoomsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedChatRooms'] = ResolversParentTypes['PaginatedChatRooms']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ChatRoom']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedEventPagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedEventPages'] = ResolversParentTypes['PaginatedEventPages']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['EventPage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedExternalContentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedExternalContents'] = ResolversParentTypes['PaginatedExternalContents']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['ExternalContent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedLiveSessionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedLiveSessions'] = ResolversParentTypes['PaginatedLiveSessions']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['LiveSession']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedVirtualEventsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedVirtualEvents'] = ResolversParentTypes['PaginatedVirtualEvents']> = {
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['VirtualEvent']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PasswordResetMobileMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PasswordResetMobileMutation'] = ResolversParentTypes['PasswordResetMobileMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PasswordResetMobileMutationV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['PasswordResetMobileMutationV2'] = ResolversParentTypes['PasswordResetMobileMutationV2']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PayloadFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PayloadField'] = ResolversParentTypes['PayloadField']> = {
  buttons?: Resolver<Maybe<Array<Maybe<ResolversTypes['ButtonField']>>>, ParentType, ContextType>;
  relativePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  templateType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PinMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PinMessageMutation'] = ResolversParentTypes['PinMessageMutation']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PinnedMessageFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PinnedMessageField'] = ResolversParentTypes['PinnedMessageField']> = {
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sender?: Resolver<Maybe<ResolversTypes['PinnedMessageSenderField']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PinnedMessageSenderFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PinnedMessageSenderField'] = ResolversParentTypes['PinnedMessageSenderField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PollResolvers<ContextType = any, ParentType extends ResolversParentTypes['Poll'] = ResolversParentTypes['Poll']> = {
  allowMultipleAnswers?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['PollOption']>, ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PollOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['PollOption'] = ResolversParentTypes['PollOption']> = {
  option?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  votes?: Resolver<Array<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopCardDegreeSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PopCardDegreeSnippetField'] = ResolversParentTypes['PopCardDegreeSnippetField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  getSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeSnippetField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopCardLevelSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PopCardLevelSnippetField'] = ResolversParentTypes['PopCardLevelSnippetField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  getSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['LevelSnippetField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopCardWidgetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PopCardWidgetField'] = ResolversParentTypes['PopCardWidgetField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PopcardSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PopcardSnippetField'] = ResolversParentTypes['PopcardSnippetField']> = {
  defaultSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['PopCardWidgetField']>>>, ParentType, ContextType>;
  degreeSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['PopCardDegreeSnippetField']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  internationalSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['PopCardWidgetField']>>>, ParentType, ContextType>;
  levelSnippets?: Resolver<Maybe<Array<Maybe<ResolversTypes['PopCardLevelSnippetField']>>>, ParentType, ContextType>;
  nationalSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['PopCardWidgetField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PostFields'] = ResolversParentTypes['PostFields']> = {
  acl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  awsAccessKeyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  policy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  xAmzSecurityToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreferencesObjectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PreferencesObjectType'] = ResolversParentTypes['PreferencesObjectType']> = {
  analyticsCookies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  strictCookies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreferencesOutputTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PreferencesOutputType'] = ResolversParentTypes['PreferencesOutputType']> = {
  analyticsCookies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  strictCookies?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProspectStageStatFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProspectStageStatField'] = ResolversParentTypes['ProspectStageStatField']> = {
  changeComparedToLastPeriod?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProspectStageSummaryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProspectStageSummaryField'] = ResolversParentTypes['ProspectStageSummaryField']> = {
  summary?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProspectStageStatField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicMentorFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicMentorField'] = ResolversParentTypes['PublicMentorField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatIntro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeValueField']>>>, ParentType, ContextType, RequireFields<PublicMentorFieldCustomAttributesArgs, never>>;
  degree?: Resolver<Maybe<ResolversTypes['DegreeField']>, ParentType, ContextType>;
  degreeHistory?: Resolver<Maybe<ResolversTypes['MentorDegreeHistory']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorDegreeField']>>>, ParentType, ContextType>;
  favouriteModules?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorModuleField']>>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interests?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorInterestField']>>>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<Maybe<ResolversTypes['LanguageField']>>>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentorFields?: Resolver<Maybe<ResolversTypes['MentorFields']>, ParentType, ContextType>;
  pastQualification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<PublicMentorFieldProfilePhotoArgs, never>>;
  prospectWelcomeMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolHistory?: Resolver<Maybe<ResolversTypes['MentorSchoolHistoryField']>, ParentType, ContextType>;
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<PublicMentorFieldShareUrlArgs, never>>;
  shortBio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slowSocieties?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorSocietyField']>>>, ParentType, ContextType>;
  societies?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorSocietyField']>>>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublicUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['PublicUserField'] = ResolversParentTypes['PublicUserField']> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  activeUniversities?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType>;
  adminMentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType, RequireFields<QueryAdminMentorArgs, never>>;
  adminRecommendationCountPerDecisionStage?: Resolver<Array<ResolversTypes['AdminRecommendationCountPerDecisionStage']>, ParentType, ContextType, RequireFields<QueryAdminRecommendationCountPerDecisionStageArgs, 'universityId'>>;
  adminSearchMentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType, RequireFields<QueryAdminSearchMentorsArgs, never>>;
  agenda?: Resolver<ResolversTypes['PaginatedLiveSessions'], ParentType, ContextType, RequireFields<QueryAgendaArgs, 'pagination' | 'virtualEventId'>>;
  alert?: Resolver<Maybe<ResolversTypes['AlertField']>, ParentType, ContextType, RequireFields<QueryAlertArgs, never>>;
  allAdminApplicants?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkApplicantField']>>>, ParentType, ContextType, RequireFields<QueryAllAdminApplicantsArgs, 'degreeLevel' | 'email' | 'firstName' | 'itemsPerPage' | 'lastName' | 'order' | 'pageNum' | 'uniId'>>;
  allAppLocales?: Resolver<Maybe<Array<Maybe<ResolversTypes['AppLocaleField']>>>, ParentType, ContextType>;
  allApplicantConversations?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantConversationField']>>>, ParentType, ContextType, RequireFields<QueryAllApplicantConversationsArgs, 'uniId'>>;
  allApplicantCount?: Resolver<Maybe<ResolversTypes['ApplicantCountField']>, ParentType, ContextType, RequireFields<QueryAllApplicantCountArgs, 'degreeLevel' | 'email' | 'firstName' | 'lastName' | 'uniId'>>;
  allApplicantSignUps?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountPerEpoch']>>>, ParentType, ContextType, RequireFields<QueryAllApplicantSignUpsArgs, never>>;
  allApplicants?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkApplicantField']>>>, ParentType, ContextType, RequireFields<QueryAllApplicantsArgs, 'itemsPerPage' | 'order' | 'pageNum'>>;
  allBuddyInvites?: Resolver<Maybe<ResolversTypes['BuddyInviteField']>, ParentType, ContextType, RequireFields<QueryAllBuddyInvitesArgs, 'accepted' | 'itemsPerPage' | 'order' | 'pageNum' | 'roleSelected'>>;
  allChatGroupCount?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountPerEpoch']>>>, ParentType, ContextType, RequireFields<QueryAllChatGroupCountArgs, never>>;
  allChatGroupsByDay?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryAllChatGroupsByDayArgs, never>>;
  allContentBriefs?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContentBriefField']>>>, ParentType, ContextType, RequireFields<QueryAllContentBriefsArgs, never>>;
  allCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryField']>>>, ParentType, ContextType>;
  allCrmIntegrations?: Resolver<Maybe<Array<Maybe<ResolversTypes['CRMIntegrationField']>>>, ParentType, ContextType>;
  allCrms?: Resolver<Maybe<Array<Maybe<ResolversTypes['CRMField']>>>, ParentType, ContextType>;
  allDegreeLevels?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeLevelField']>>>, ParentType, ContextType, RequireFields<QueryAllDegreeLevelsArgs, never>>;
  allDegreeTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeType']>>>, ParentType, ContextType>;
  allDegrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType, RequireFields<QueryAllDegreesArgs, never>>;
  allHighSchoolTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['HighSchoolTypeField']>>>, ParentType, ContextType>;
  allHighSchools?: Resolver<Maybe<Array<Maybe<ResolversTypes['HighSchoolField']>>>, ParentType, ContextType, RequireFields<QueryAllHighSchoolsArgs, never>>;
  allLanguages?: Resolver<Maybe<Array<Maybe<ResolversTypes['LanguageField']>>>, ParentType, ContextType>;
  allLocales?: Resolver<Maybe<Array<Maybe<ResolversTypes['LocaleField']>>>, ParentType, ContextType>;
  allMentorInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorInviteField']>>>, ParentType, ContextType, RequireFields<QueryAllMentorInvitesArgs, 'accepted' | 'itemsPerPage' | 'order' | 'pageNum'>>;
  allMentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorField']>>>, ParentType, ContextType, RequireFields<QueryAllMentorsArgs, 'email' | 'firstName' | 'lastName'>>;
  allMentorsOfEnterprise?: Resolver<Maybe<Array<Maybe<ResolversTypes['EnterpriseMentorField']>>>, ParentType, ContextType, RequireFields<QueryAllMentorsOfEnterpriseArgs, 'email' | 'firstName' | 'lastName' | 'organisationId'>>;
  allMentorsTimeSpent?: Resolver<Maybe<ResolversTypes['UsersTimeSpentData']>, ParentType, ContextType, RequireFields<QueryAllMentorsTimeSpentArgs, 'orderBy'>>;
  allPosts?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogPostField']>>>, ParentType, ContextType, RequireFields<QueryAllPostsArgs, never>>;
  allPostsInfo?: Resolver<Maybe<ResolversTypes['AllPostsField']>, ParentType, ContextType, RequireFields<QueryAllPostsInfoArgs, never>>;
  allStaff?: Resolver<Maybe<ResolversTypes['StaffUserListField']>, ParentType, ContextType, RequireFields<QueryAllStaffArgs, never>>;
  allSubCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopCountryField']>>>, ParentType, ContextType, RequireFields<QueryAllSubCountriesArgs, never>>;
  allSubRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopRegionField']>>>, ParentType, ContextType, RequireFields<QueryAllSubRegionsArgs, never>>;
  allTopCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopCountryField']>>>, ParentType, ContextType, RequireFields<QueryAllTopCountriesArgs, never>>;
  allTopDegrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopDegreeField']>>>, ParentType, ContextType, RequireFields<QueryAllTopDegreesArgs, never>>;
  allTopRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopRegionField']>>>, ParentType, ContextType, RequireFields<QueryAllTopRegionsArgs, never>>;
  allUniversities?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType, RequireFields<QueryAllUniversitiesArgs, never>>;
  allUserIds?: Resolver<Maybe<ResolversTypes['UserIdsListField']>, ParentType, ContextType, RequireFields<QueryAllUserIdsArgs, never>>;
  allUsers?: Resolver<Maybe<ResolversTypes['UserListField']>, ParentType, ContextType, RequireFields<QueryAllUsersArgs, never>>;
  allYearOfStudy?: Resolver<Maybe<Array<Maybe<ResolversTypes['YearOfStudyField']>>>, ParentType, ContextType, RequireFields<QueryAllYearOfStudyArgs, never>>;
  analytics?: Resolver<ResolversTypes['Analytics'], ParentType, ContextType, RequireFields<QueryAnalyticsArgs, 'virtualEventId'>>;
  appLocales?: Resolver<Maybe<ResolversTypes['AppLocaleField']>, ParentType, ContextType, RequireFields<QueryAppLocalesArgs, never>>;
  applicant?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType, RequireFields<QueryApplicantArgs, never>>;
  applicantCollectionFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantCollectionField']>>>, ParentType, ContextType, RequireFields<QueryApplicantCollectionFieldsArgs, never>>;
  applicantCount?: Resolver<Maybe<ResolversTypes['ApplicantCountField']>, ParentType, ContextType, RequireFields<QueryApplicantCountArgs, never>>;
  applicantCountryCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  applicantDegreeCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  applicantDomesticRegionCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  applicantInbox?: Resolver<Maybe<ResolversTypes['ApplicantInboxField']>, ParentType, ContextType, RequireFields<QueryApplicantInboxArgs, 'allowDelinked'>>;
  applicantInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicantInvite']>>>, ParentType, ContextType, RequireFields<QueryApplicantInvitesArgs, 'universityId'>>;
  applicantSignUps?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountPerEpoch']>>>, ParentType, ContextType, RequireFields<QueryApplicantSignUpsArgs, never>>;
  applicantsApplied?: Resolver<Maybe<ResolversTypes['ApplicantsAppliedStats']>, ParentType, ContextType, RequireFields<QueryApplicantsAppliedArgs, 'endDate' | 'startDate'>>;
  applicantsMessaged?: Resolver<Maybe<ResolversTypes['ApplicantsMessagedCount']>, ParentType, ContextType, RequireFields<QueryApplicantsMessagedArgs, 'endDate' | 'startDate'>>;
  applicationInsights?: Resolver<ResolversTypes['PaginatedApplicationInsightModel'], ParentType, ContextType, RequireFields<QueryApplicationInsightsArgs, 'paginatedApplicantInsightInput' | 'pagination'>>;
  appliedUsersCount?: Resolver<ResolversTypes['AppliedUsersCountModel'], ParentType, ContextType, RequireFields<QueryAppliedUsersCountArgs, 'appliedUsersCountInput'>>;
  askQuestion?: Resolver<Maybe<Array<Maybe<ResolversTypes['CanonicalQuestionField']>>>, ParentType, ContextType, RequireFields<QueryAskQuestionArgs, 'question' | 'universitySlug'>>;
  availableDegreeTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeType']>>>, ParentType, ContextType, RequireFields<QueryAvailableDegreeTypesArgs, 'universitySlug'>>;
  blockedUsersByMe?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  blogTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogTopicField']>>>, ParentType, ContextType, RequireFields<QueryBlogTopicsArgs, never>>;
  botUser?: Resolver<Maybe<ResolversTypes['BotUserField']>, ParentType, ContextType, RequireFields<QueryBotUserArgs, never>>;
  buddies?: Resolver<Maybe<ResolversTypes['BuddiesField']>, ParentType, ContextType, RequireFields<QueryBuddiesArgs, 'active' | 'useRandomizeStaffFeature'>>;
  buddiesFilterOptions?: Resolver<Maybe<ResolversTypes['BuddiesFilterOptionsField']>, ParentType, ContextType, RequireFields<QueryBuddiesFilterOptionsArgs, 'active' | 'all'>>;
  buddy?: Resolver<Maybe<ResolversTypes['BuddyField']>, ParentType, ContextType, RequireFields<QueryBuddyArgs, 'id'>>;
  buddyApplicantChatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryBuddyApplicantChatGroupCountArgs, never>>;
  buddyApplicantChatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyApplicantChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryBuddyApplicantChatGroupsArgs, never>>;
  buddyApplicantChatIntervention?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyApplicantChatInterventionBuddyField']>>>, ParentType, ContextType, RequireFields<QueryBuddyApplicantChatInterventionArgs, never>>;
  buddyApplicantChatInterventionTotalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryBuddyApplicantChatInterventionTotalCountArgs, never>>;
  buddyInvite?: Resolver<Maybe<ResolversTypes['BuddyInviteListField']>, ParentType, ContextType, RequireFields<QueryBuddyInviteArgs, never>>;
  buddyResponseTime?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsBuddyField']>>>, ParentType, ContextType, RequireFields<QueryBuddyResponseTimeArgs, never>>;
  canonicalQuestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['CanonicalQuestionField']>>>, ParentType, ContextType, RequireFields<QueryCanonicalQuestionsArgs, never>>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType, RequireFields<QueryChatGroupArgs, 'allowDelinked'>>;
  chatGroupAux?: Resolver<Maybe<ResolversTypes['ChatGroupAuxiliaryField']>, ParentType, ContextType, RequireFields<QueryChatGroupAuxArgs, never>>;
  chatGroupById?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType, RequireFields<QueryChatGroupByIdArgs, 'allowDelinked'>>;
  chatGroupCount?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountPerEpoch']>>>, ParentType, ContextType, RequireFields<QueryChatGroupCountArgs, never>>;
  chatGroupLabellingCandidates?: Resolver<Maybe<Array<Maybe<ResolversTypes['LabellingSentenceCandidate']>>>, ParentType, ContextType, RequireFields<QueryChatGroupLabellingCandidatesArgs, 'chatGroupId'>>;
  chatGroupLabels?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupLabelField']>>>, ParentType, ContextType>;
  chatGroupSuggestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['SuggestionField']>>>, ParentType, ContextType, RequireFields<QueryChatGroupSuggestionsArgs, 'chatGroupId'>>;
  chatGroupsByDay?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryChatGroupsByDayArgs, never>>;
  chatInterventionMentors?: Resolver<Maybe<ResolversTypes['MentorInterventionQueryField']>, ParentType, ContextType, RequireFields<QueryChatInterventionMentorsArgs, 'onlyMentorsWithUnreads'>>;
  chatRoom?: Resolver<Maybe<ResolversTypes['ChatRoom']>, ParentType, ContextType, RequireFields<QueryChatRoomArgs, 'id'>>;
  chatRoomAnalytics?: Resolver<Maybe<ResolversTypes['ChatRoomAnalytics']>, ParentType, ContextType, RequireFields<QueryChatRoomAnalyticsArgs, 'chatRoomId'>>;
  chatRooms?: Resolver<ResolversTypes['PaginatedChatRooms'], ParentType, ContextType, RequireFields<QueryChatRoomsArgs, 'pagination' | 'virtualEventId'>>;
  communityUserDirectory?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType, RequireFields<QueryCommunityUserDirectoryArgs, 'filterUsersDTO' | 'pagination'>>;
  communityUserSearch?: Resolver<ResolversTypes['UserDirectoryResult'], ParentType, ContextType, RequireFields<QueryCommunityUserSearchArgs, 'filterUsersDTO' | 'pagination'>>;
  completedEvents?: Resolver<Maybe<ResolversTypes['CompletedEventsOverview']>, ParentType, ContextType, RequireFields<QueryCompletedEventsArgs, 'endDate' | 'filterByName' | 'filterByType' | 'limit' | 'sortBy' | 'sortOrder' | 'startDate'>>;
  conversationContext?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConversationContextField']>>>, ParentType, ContextType, RequireFields<QueryConversationContextArgs, 'endDate' | 'startDate' | 'subTopic' | 'topic'>>;
  conversationInboxDoNotUse?: Resolver<Array<ResolversTypes['ConversationInbox']>, ParentType, ContextType>;
  conversationStats?: Resolver<Maybe<Array<Maybe<ResolversTypes['TrendingTopicsField']>>>, ParentType, ContextType, RequireFields<QueryConversationStatsArgs, 'endDate' | 'groupBy' | 'startDate'>>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType, RequireFields<QueryCountryArgs, never>>;
  crmApplicationFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['ApplicationField']>>>, ParentType, ContextType>;
  crmAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CrmAttributeField']>>>, ParentType, ContextType, RequireFields<QueryCrmAttributesArgs, never>>;
  crmDateFormats?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  crmIntegration?: Resolver<Maybe<ResolversTypes['CRMIntegrationField']>, ParentType, ContextType, RequireFields<QueryCrmIntegrationArgs, never>>;
  crmServiceUniversityConfig?: Resolver<Maybe<ResolversTypes['CrmServiceUniversityConfig']>, ParentType, ContextType, RequireFields<QueryCrmServiceUniversityConfigArgs, never>>;
  crmSyncJob?: Resolver<Maybe<ResolversTypes['CrmSyncJobField']>, ParentType, ContextType, RequireFields<QueryCrmSyncJobArgs, never>>;
  currentUserDecisionStage?: Resolver<ResolversTypes['CurrentDecisionStageOutput'], ParentType, ContextType, RequireFields<QueryCurrentUserDecisionStageArgs, 'currentUserDecisionStageQueryInput'>>;
  degreeCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeCategoryField']>>>, ParentType, ContextType, RequireFields<QueryDegreeCategoriesArgs, never>>;
  degreeCategoriesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryDegreeCategoriesCountArgs, never>>;
  degreeCategory?: Resolver<Maybe<ResolversTypes['DegreeCategoryField']>, ParentType, ContextType, RequireFields<QueryDegreeCategoryArgs, never>>;
  domesticRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomesticRegionField']>>>, ParentType, ContextType, RequireFields<QueryDomesticRegionsArgs, never>>;
  enrolledInsightsByCommunity?: Resolver<ResolversTypes['EnrolledInsightResponse'], ParentType, ContextType, RequireFields<QueryEnrolledInsightsByCommunityArgs, 'enrolledInsightsInput'>>;
  enterpriseAddOns?: Resolver<Maybe<Array<Maybe<ResolversTypes['EnterpriseAddOns']>>>, ParentType, ContextType>;
  enterpriseDegrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType, RequireFields<QueryEnterpriseDegreesArgs, never>>;
  enterpriseMentorCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryEnterpriseMentorCountArgs, 'degreeLevel' | 'degreeName' | 'email' | 'firstName' | 'lastName' | 'year'>>;
  eventAnalytics?: Resolver<ResolversTypes['Analytics'], ParentType, ContextType, RequireFields<QueryEventAnalyticsArgs, 'virtualEventId'>>;
  eventPage?: Resolver<Maybe<ResolversTypes['EventPage']>, ParentType, ContextType, RequireFields<QueryEventPageArgs, 'id'>>;
  eventPages?: Resolver<ResolversTypes['PaginatedEventPages'], ParentType, ContextType, RequireFields<QueryEventPagesArgs, 'pagination' | 'virtualEventId'>>;
  exampleGraphQLQuery?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  exportTimesheetReport?: Resolver<Maybe<ResolversTypes['ExportTimeSheetDataField']>, ParentType, ContextType, RequireFields<QueryExportTimesheetReportArgs, 'country' | 'degreeLevel' | 'degreeName' | 'email' | 'firstName' | 'lastName' | 'year'>>;
  externalContent?: Resolver<Maybe<ResolversTypes['ExternalContent']>, ParentType, ContextType, RequireFields<QueryExternalContentArgs, 'id'>>;
  externalContents?: Resolver<ResolversTypes['PaginatedExternalContents'], ParentType, ContextType, RequireFields<QueryExternalContentsArgs, 'pagination' | 'virtualEventId'>>;
  externalEvent?: Resolver<Maybe<ResolversTypes['ExternalEventField']>, ParentType, ContextType, RequireFields<QueryExternalEventArgs, never>>;
  externalEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExternalEventField']>>>, ParentType, ContextType, RequireFields<QueryExternalEventsArgs, never>>;
  filterUsersEnterprise?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserCustomAttributesFilterField']>>>, ParentType, ContextType, RequireFields<QueryFilterUsersEnterpriseArgs, never>>;
  getAssistantConversation?: Resolver<Maybe<ResolversTypes['AssistantConversationHistory']>, ParentType, ContextType, RequireFields<QueryGetAssistantConversationArgs, 'getAssistantConversationInput'>>;
  getChatConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<QueryGetChatConversationArgs, 'id'>>;
  getChatConversationFromLegacyId?: Resolver<Maybe<ResolversTypes['ChatConversation']>, ParentType, ContextType, RequireFields<QueryGetChatConversationFromLegacyIdArgs, 'id'>>;
  getChatConversationInbox?: Resolver<Array<ResolversTypes['ChatConversation']>, ParentType, ContextType, RequireFields<QueryGetChatConversationInboxArgs, 'ChatConversationInboxInputDto'>>;
  getChatConversationMessages?: Resolver<ResolversTypes['PaginatedChatMessages'], ParentType, ContextType, RequireFields<QueryGetChatConversationMessagesArgs, 'getMessageDto'>>;
  getChatGroupsForCommunity?: Resolver<Array<ResolversTypes['CommunityChatGroup']>, ParentType, ContextType, RequireFields<QueryGetChatGroupsForCommunityArgs, 'communityId'>>;
  getChatReports?: Resolver<Array<ResolversTypes['ChatReport']>, ParentType, ContextType, RequireFields<QueryGetChatReportsArgs, 'getChatReportsDTO'>>;
  getCommunityChatMembers?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType, RequireFields<QueryGetCommunityChatMembersArgs, 'conversationId' | 'offsetPagination'>>;
  getCommunityConversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType, RequireFields<QueryGetCommunityConversationArgs, 'id'>>;
  getCommunityConversations?: Resolver<Array<ResolversTypes['ChatConversation']>, ParentType, ContextType, RequireFields<QueryGetCommunityConversationsArgs, 'communityId' | 'retrieveArchived'>>;
  getCommunityForUniOrAdmin?: Resolver<ResolversTypes['Community'], ParentType, ContextType, RequireFields<QueryGetCommunityForUniOrAdminArgs, 'communityId' | 'retrieveArchived'>>;
  getCommunityForUser?: Resolver<ResolversTypes['Community'], ParentType, ContextType, RequireFields<QueryGetCommunityForUserArgs, 'communityId'>>;
  getCommunityMembers?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType, RequireFields<QueryGetCommunityMembersArgs, 'communityId'>>;
  getCommunityTemplates?: Resolver<Array<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  getConversationPermission?: Resolver<ResolversTypes['ChatPermission'], ParentType, ContextType, RequireFields<QueryGetConversationPermissionArgs, 'id'>>;
  getExperimentalTodos?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  getIdpToken?: Resolver<Maybe<ResolversTypes['AuthField']>, ParentType, ContextType, RequireFields<QueryGetIdpTokenArgs, never>>;
  getInterests?: Resolver<Array<ResolversTypes['Interest']>, ParentType, ContextType>;
  getInterestsForUser?: Resolver<Array<ResolversTypes['UserInterest']>, ParentType, ContextType>;
  getInviteFromId?: Resolver<ResolversTypes['Invite'], ParentType, ContextType, RequireFields<QueryGetInviteFromIdArgs, 'inviteId'>>;
  getInviteInformation?: Resolver<ResolversTypes['InviteInformation'], ParentType, ContextType, RequireFields<QueryGetInviteInformationArgs, 'InviteInformationInput'>>;
  getInviteLinkInfo?: Resolver<ResolversTypes['InviteLinkInfoDto'], ParentType, ContextType, RequireFields<QueryGetInviteLinkInfoArgs, 'inviteLinkId'>>;
  getInvitesForCommunity?: Resolver<Array<ResolversTypes['CommunityInvites']>, ParentType, ContextType, RequireFields<QueryGetInvitesForCommunityArgs, 'communityId' | 'filters' | 'retrieveArchived'>>;
  getInvitesForEmail?: Resolver<Array<ResolversTypes['GetInvitesForEmailDTO']>, ParentType, ContextType, RequireFields<QueryGetInvitesForEmailArgs, 'email'>>;
  getLabels?: Resolver<Array<ResolversTypes['Labels']>, ParentType, ContextType>;
  getLiveEventsById?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventField']>>>, ParentType, ContextType, RequireFields<QueryGetLiveEventsByIdArgs, 'universityId'>>;
  getMessagesForChatConversation?: Resolver<Array<ResolversTypes['ChatMessage']>, ParentType, ContextType, RequireFields<QueryGetMessagesForChatConversationArgs, 'getMessageDto'>>;
  getOrCreateBotChatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType, RequireFields<QueryGetOrCreateBotChatGroupArgs, never>>;
  getOrCreateChatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType, RequireFields<QueryGetOrCreateChatGroupArgs, never>>;
  getOrCreateCommunitiesForInstitution?: Resolver<Array<ResolversTypes['Community']>, ParentType, ContextType, RequireFields<QueryGetOrCreateCommunitiesForInstitutionArgs, 'universityId'>>;
  getOrCreateUniversityChatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupAndMessages']>, ParentType, ContextType, RequireFields<QueryGetOrCreateUniversityChatGroupArgs, never>>;
  getRandomAmbassadors?: Resolver<Maybe<Array<ResolversTypes['AmbassadorProfileInformation']>>, ParentType, ContextType, RequireFields<QueryGetRandomAmbassadorsArgs, 'getRandomAmbassadorsInput'>>;
  getRecommendedConversations?: Resolver<Array<ResolversTypes['ChatConversation']>, ParentType, ContextType, RequireFields<QueryGetRecommendedConversationsArgs, 'communityId'>>;
  getRecommendedUsers?: Resolver<ResolversTypes['UserRecommendation'], ParentType, ContextType, RequireFields<QueryGetRecommendedUsersArgs, 'communityId'>>;
  getRecording?: Resolver<Maybe<ResolversTypes['RecordingDownloadResponse']>, ParentType, ContextType, RequireFields<QueryGetRecordingArgs, 'path'>>;
  getRecordingFailuresByChannelId?: Resolver<ResolversTypes['GetRecordingFailureIdsResponse'], ParentType, ContextType, RequireFields<QueryGetRecordingFailuresByChannelIdArgs, 'data'>>;
  getRecordingFailuresByEventId?: Resolver<ResolversTypes['GetRecordingFailureIdsResponse'], ParentType, ContextType, RequireFields<QueryGetRecordingFailuresByEventIdArgs, 'data'>>;
  getRecordingList?: Resolver<Maybe<ResolversTypes['RecordingListResponse']>, ParentType, ContextType, RequireFields<QueryGetRecordingListArgs, 'channelId' | 'universityId'>>;
  getRecordingUploadingCount?: Resolver<ResolversTypes['RecordingUploadingResponse'], ParentType, ContextType, RequireFields<QueryGetRecordingUploadingCountArgs, 'channelId' | 'universityId'>>;
  getToken?: Resolver<Maybe<ResolversTypes['AuthField']>, ParentType, ContextType, RequireFields<QueryGetTokenArgs, never>>;
  getTokenForEnterpriseView?: Resolver<Maybe<ResolversTypes['AuthField']>, ParentType, ContextType, RequireFields<QueryGetTokenForEnterpriseViewArgs, never>>;
  getUniversitiesByFeatureFlag?: Resolver<ResolversTypes['FeatureRulesDTO'], ParentType, ContextType, RequireFields<QueryGetUniversitiesByFeatureFlagArgs, 'featureFlag'>>;
  getUniversityAssistantConfig?: Resolver<Maybe<ResolversTypes['UniversityAssistantConfiguration']>, ParentType, ContextType, RequireFields<QueryGetUniversityAssistantConfigArgs, 'universityId'>>;
  getUser?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType, RequireFields<QueryGetUserArgs, never>>;
  getUserByEmail?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType, RequireFields<QueryGetUserByEmailArgs, never>>;
  getUserByIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserField']>>>, ParentType, ContextType, RequireFields<QueryGetUserByIdsArgs, never>>;
  getUserProfile?: Resolver<ResolversTypes['UserProfile'], ParentType, ContextType>;
  getUserReports?: Resolver<Array<ResolversTypes['UserReport']>, ParentType, ContextType, RequireFields<QueryGetUserReportsArgs, 'getUserReportsDTO'>>;
  getUsersCommunityConversations?: Resolver<Array<ResolversTypes['ChatConversation']>, ParentType, ContextType, RequireFields<QueryGetUsersCommunityConversationsArgs, 'communityId'>>;
  getUsersCommunityPrivateConversations?: Resolver<Array<ResolversTypes['ChatConversation']>, ParentType, ContextType, RequireFields<QueryGetUsersCommunityPrivateConversationsArgs, 'communityId'>>;
  getVideoContent?: Resolver<ResolversTypes['VideoContent'], ParentType, ContextType>;
  globalAlerts?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlertField']>>>, ParentType, ContextType, RequireFields<QueryGlobalAlertsArgs, never>>;
  hasContent?: Resolver<ResolversTypes['UniversityContent'], ParentType, ContextType, RequireFields<QueryHasContentArgs, 'universityContentInput'>>;
  hasMore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  helloCommunityService?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryHelloCommunityServiceArgs, 'throwError'>>;
  hobsonsConnectIntegration?: Resolver<Maybe<ResolversTypes['CampusManagementConnectIntegrationField']>, ParentType, ContextType, RequireFields<QueryHobsonsConnectIntegrationArgs, never>>;
  inboxPromptQuestionByQuestionId?: Resolver<ResolversTypes['InboxPromptQuestion'], ParentType, ContextType, RequireFields<QueryInboxPromptQuestionByQuestionIdArgs, 'questionId'>>;
  inboxPromptQuestions?: Resolver<Array<ResolversTypes['InboxPromptQuestion']>, ParentType, ContextType, RequireFields<QueryInboxPromptQuestionsArgs, 'inboxPromptInput'>>;
  interventionMessageList?: Resolver<Maybe<Array<Maybe<ResolversTypes['InterventionMessageField']>>>, ParentType, ContextType, RequireFields<QueryInterventionMessageListArgs, never>>;
  isModerationHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isProspectGuidanceHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isUserInDirectMessages?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<QueryIsUserInDirectMessagesArgs, 'liveEventSlug' | 'universityId' | 'userId'>>;
  isUserProfilingHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVideoContentHealthy?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  latestExportedReport?: Resolver<Maybe<ResolversTypes['ExportedReportField']>, ParentType, ContextType, RequireFields<QueryLatestExportedReportArgs, never>>;
  listAdminRecommendationResourceTypes?: Resolver<Array<ResolversTypes['RecommendationResourceType']>, ParentType, ContextType>;
  listAdminRecommendations?: Resolver<ResolversTypes['PaginatedAdminRecommendation'], ParentType, ContextType, RequireFields<QueryListAdminRecommendationsArgs, 'listAdminRecommendationInput' | 'pagination'>>;
  listArchivedCommunitiesForAnInstitution?: Resolver<Array<ResolversTypes['Community']>, ParentType, ContextType, RequireFields<QueryListArchivedCommunitiesForAnInstitutionArgs, 'institutionId'>>;
  listBuddyApplicantConversations?: Resolver<Maybe<ResolversTypes['ListBuddyApplicantConversationsField']>, ParentType, ContextType, RequireFields<QueryListBuddyApplicantConversationsArgs, 'limit' | 'offset' | 'sort'>>;
  listCommunitiesForAnInstitution?: Resolver<Array<ResolversTypes['Community']>, ParentType, ContextType, RequireFields<QueryListCommunitiesForAnInstitutionArgs, 'institutionId'>>;
  listMentorApplicantConversations?: Resolver<Maybe<ResolversTypes['ListMentorApplicantConversationsField']>, ParentType, ContextType, RequireFields<QueryListMentorApplicantConversationsArgs, 'limit' | 'offset' | 'sort'>>;
  listUniversityKnowledgeBaseSources?: Resolver<Array<ResolversTypes['KnowledgeBaseEmbedding']>, ParentType, ContextType, RequireFields<QueryListUniversityKnowledgeBaseSourcesArgs, 'knowledgeBaseSourceQueryInput'>>;
  listUniversityKnowledgeBaseSourcesByUrlPattern?: Resolver<Array<ResolversTypes['KnowledgeBaseSourcesByUrl']>, ParentType, ContextType, RequireFields<QueryListUniversityKnowledgeBaseSourcesByUrlPatternArgs, 'knowledgeBaseSourcesListInput'>>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType, RequireFields<QueryLiveEventArgs, never>>;
  liveEventLandingPage?: Resolver<Maybe<ResolversTypes['LiveEventLandingPageField']>, ParentType, ContextType, RequireFields<QueryLiveEventLandingPageArgs, never>>;
  liveEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventField']>>>, ParentType, ContextType, RequireFields<QueryLiveEventsArgs, 'viewFilter'>>;
  liveFeed?: Resolver<Maybe<ResolversTypes['LiveEventChatGroupField']>, ParentType, ContextType, RequireFields<QueryLiveFeedArgs, never>>;
  liveSession?: Resolver<Maybe<ResolversTypes['LiveSession']>, ParentType, ContextType, RequireFields<QueryLiveSessionArgs, 'id'>>;
  liveSessions?: Resolver<ResolversTypes['PaginatedLiveSessions'], ParentType, ContextType, RequireFields<QueryLiveSessionsArgs, 'orderBy' | 'pagination' | 'virtualEventId'>>;
  marketplace?: Resolver<Maybe<ResolversTypes['MarketplaceField']>, ParentType, ContextType, RequireFields<QueryMarketplaceArgs, never>>;
  marketplaceApplicantChatGroupsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMarketplaceApplicantChatGroupsCountArgs, never>>;
  marketplaceApplicantCountries?: Resolver<Maybe<ResolversTypes['MarketplaceApplicantCountries']>, ParentType, ContextType, RequireFields<QueryMarketplaceApplicantCountriesArgs, 'marketplaceSlug'>>;
  marketplaceApplicantDegreeCategories?: Resolver<Maybe<ResolversTypes['MarketplaceApplicantDegreeCategories']>, ParentType, ContextType, RequireFields<QueryMarketplaceApplicantDegreeCategoriesArgs, 'marketplaceSlug'>>;
  marketplaceApplicantsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMarketplaceApplicantsCountArgs, never>>;
  marketplaceInbox?: Resolver<Maybe<ResolversTypes['MarketplaceInboxField']>, ParentType, ContextType, RequireFields<QueryMarketplaceInboxArgs, never>>;
  marketplaceMentorCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryField']>>>, ParentType, ContextType, RequireFields<QueryMarketplaceMentorCountriesArgs, never>>;
  marketplaceMentorDegreeCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType, RequireFields<QueryMarketplaceMentorDegreeCategoriesArgs, never>>;
  marketplaceMentorList?: Resolver<Maybe<ResolversTypes['MarketplaceMentorList']>, ParentType, ContextType, RequireFields<QueryMarketplaceMentorListArgs, 'limit'>>;
  marketplaceMentorRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomesticRegionField']>>>, ParentType, ContextType, RequireFields<QueryMarketplaceMentorRegionsArgs, never>>;
  marketplaceMentorUniversities?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType, RequireFields<QueryMarketplaceMentorUniversitiesArgs, never>>;
  marketplaceSnippets?: Resolver<Maybe<ResolversTypes['MarketplaceSnippetField']>, ParentType, ContextType, RequireFields<QueryMarketplaceSnippetsArgs, never>>;
  marketplaceUniversitiesSlugs?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, RequireFields<QueryMarketplaceUniversitiesSlugsArgs, never>>;
  marketplaceUniversityCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryField']>>>, ParentType, ContextType, RequireFields<QueryMarketplaceUniversityCountriesArgs, never>>;
  marketplaceUnreadMessageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMarketplaceUnreadMessageCountArgs, never>>;
  marketplaces?: Resolver<Maybe<Array<Maybe<ResolversTypes['MarketplaceField']>>>, ParentType, ContextType, RequireFields<QueryMarketplacesArgs, never>>;
  marketplacesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMarketplacesCountArgs, never>>;
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  memberOrganisations?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrganisationField']>>>, ParentType, ContextType>;
  mentorApplicantChatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMentorApplicantChatGroupCountArgs, never>>;
  mentorApplicantChatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorApplicantChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryMentorApplicantChatGroupsArgs, never>>;
  mentorCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryMentorCountArgs, 'degreeLevel' | 'degreeName' | 'email' | 'firstName' | 'lastName' | 'onlyMentorsWithUnreads' | 'year'>>;
  mentorInbox?: Resolver<Maybe<ResolversTypes['MentorInboxField']>, ParentType, ContextType, RequireFields<QueryMentorInboxArgs, never>>;
  mentorInterests?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorInterestField']>>>, ParentType, ContextType, RequireFields<QueryMentorInterestsArgs, never>>;
  mentorIntroMessage?: Resolver<Maybe<ResolversTypes['MentorIntroMessageField']>, ParentType, ContextType, RequireFields<QueryMentorIntroMessageArgs, never>>;
  mentorInvite?: Resolver<Maybe<ResolversTypes['MentorInviteField']>, ParentType, ContextType, RequireFields<QueryMentorInviteArgs, never>>;
  mentorInviteCount?: Resolver<Maybe<ResolversTypes['MentorInviteCountField']>, ParentType, ContextType, RequireFields<QueryMentorInviteCountArgs, 'accepted'>>;
  mentorList?: Resolver<Maybe<ResolversTypes['MentorListField']>, ParentType, ContextType, RequireFields<QueryMentorListArgs, 'filterIncompleteProfiles'>>;
  mentorModules?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorModuleField']>>>, ParentType, ContextType, RequireFields<QueryMentorModulesArgs, never>>;
  mentorPosts?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogPostField']>>>, ParentType, ContextType, RequireFields<QueryMentorPostsArgs, never>>;
  mentorResponseTime?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsMentorField']>>>, ParentType, ContextType, RequireFields<QueryMentorResponseTimeArgs, never>>;
  mentorSocieties?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorSocietyField']>>>, ParentType, ContextType, RequireFields<QueryMentorSocietiesArgs, never>>;
  mentorTimeSpent?: Resolver<Maybe<Array<Maybe<ResolversTypes['AnalyticsMentorField']>>>, ParentType, ContextType, RequireFields<QueryMentorTimeSpentArgs, never>>;
  messages?: Resolver<Maybe<ResolversTypes['ChatGroupAndMessages']>, ParentType, ContextType, RequireFields<QueryMessagesArgs, 'allowDelinked'>>;
  myAgenda?: Resolver<ResolversTypes['PaginatedLiveSessions'], ParentType, ContextType, RequireFields<QueryMyAgendaArgs, 'pagination' | 'virtualEventId'>>;
  myBlogTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogTopicField']>>>, ParentType, ContextType>;
  myLiveEventDirectMessaging?: Resolver<Maybe<ResolversTypes['LiveEventDirectMessaging']>, ParentType, ContextType, RequireFields<QueryMyLiveEventDirectMessagingArgs, never>>;
  myLiveEventFeeds?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryMyLiveEventFeedsArgs, never>>;
  myRegisteredEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventDetailsField']>>>, ParentType, ContextType>;
  myUniversityDegrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType, RequireFields<QueryMyUniversityDegreesArgs, never>>;
  myUniversityLink?: Resolver<Maybe<ResolversTypes['ApplicantUniversityLinkField']>, ParentType, ContextType, RequireFields<QueryMyUniversityLinkArgs, never>>;
  offsetId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  openDay?: Resolver<Maybe<ResolversTypes['OpenDayField']>, ParentType, ContextType, RequireFields<QueryOpenDayArgs, never>>;
  openDays?: Resolver<Maybe<Array<Maybe<ResolversTypes['OpenDayField']>>>, ParentType, ContextType, RequireFields<QueryOpenDaysArgs, never>>;
  openDaysWithViewFilter?: Resolver<Maybe<Array<Maybe<ResolversTypes['OpenDayField']>>>, ParentType, ContextType, RequireFields<QueryOpenDaysWithViewFilterArgs, 'viewFilter'>>;
  organisation?: Resolver<Maybe<ResolversTypes['OrganisationField']>, ParentType, ContextType, RequireFields<QueryOrganisationArgs, never>>;
  organisations?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrganisationField']>>>, ParentType, ContextType, RequireFields<QueryOrganisationsArgs, never>>;
  outbox?: Resolver<Maybe<Array<Maybe<ResolversTypes['OutboxChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryOutboxArgs, 'itemsPerPage' | 'pageNum'>>;
  outboxCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pageMentors?: Resolver<Maybe<ResolversTypes['PageMentorField']>, ParentType, ContextType, RequireFields<QueryPageMentorsArgs, 'email' | 'firstName' | 'lastName'>>;
  paginatedApplicantInvites?: Resolver<Maybe<ResolversTypes['PaginatedApplicantInvite']>, ParentType, ContextType, RequireFields<QueryPaginatedApplicantInvitesArgs, 'universityId'>>;
  pingUserDirectory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  popularSession?: Resolver<Maybe<ResolversTypes['SessionAnalytics']>, ParentType, ContextType, RequireFields<QueryPopularSessionArgs, 'virtualEventId'>>;
  post?: Resolver<Maybe<ResolversTypes['BlogPostField']>, ParentType, ContextType, RequireFields<QueryPostArgs, never>>;
  prospectChatSummary?: Resolver<Maybe<ResolversTypes['ChatSummaryField']>, ParentType, ContextType, RequireFields<QueryProspectChatSummaryArgs, never>>;
  prospectChatSummaryV2?: Resolver<Maybe<ResolversTypes['ChatSummaryField']>, ParentType, ContextType, RequireFields<QueryProspectChatSummaryV2Args, 'prospectId'>>;
  prospectStageStats?: Resolver<Maybe<ResolversTypes['ProspectStageSummaryField']>, ParentType, ContextType, RequireFields<QueryProspectStageStatsArgs, 'endDate' | 'startDate'>>;
  publicLiveEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventField']>>>, ParentType, ContextType, RequireFields<QueryPublicLiveEventsArgs, never>>;
  publicMentor?: Resolver<Maybe<ResolversTypes['PublicMentorField']>, ParentType, ContextType, RequireFields<QueryPublicMentorArgs, never>>;
  publicMentorIntroMessage?: Resolver<Maybe<ResolversTypes['MentorIntroMessageField']>, ParentType, ContextType, RequireFields<QueryPublicMentorIntroMessageArgs, never>>;
  publicStaffCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryPublicStaffCountArgs, never>>;
  publicUser?: Resolver<Maybe<ResolversTypes['PublicUserField']>, ParentType, ContextType, RequireFields<QueryPublicUserArgs, never>>;
  qssQuestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuestionField']>>>, ParentType, ContextType>;
  queryRecording?: Resolver<ResolversTypes['QueryRecordingResponse'], ParentType, ContextType, RequireFields<QueryQueryRecordingArgs, 'data'>>;
  queryUniversityIndexing?: Resolver<Maybe<ResolversTypes['UniversityScrapeIndexingOutput']>, ParentType, ContextType, RequireFields<QueryQueryUniversityIndexingArgs, 'UniversityScrapeIndexingQueryInput'>>;
  randomBuddy?: Resolver<Maybe<ResolversTypes['BuddyField']>, ParentType, ContextType, RequireFields<QueryRandomBuddyArgs, never>>;
  randomDefaultQuestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DefaultQuestionsField']>>>, ParentType, ContextType, RequireFields<QueryRandomDefaultQuestionsArgs, 'limit'>>;
  randomMentor?: Resolver<Maybe<ResolversTypes['PublicMentorField']>, ParentType, ContextType, RequireFields<QueryRandomMentorArgs, 'universitySlug'>>;
  randomMentorsList?: Resolver<Maybe<ResolversTypes['RandomMentorsListField']>, ParentType, ContextType, RequireFields<QueryRandomMentorsListArgs, 'limit' | 'universitySlug'>>;
  requestedPublicGroups?: Resolver<Array<ResolversTypes['RequestedPublicGroup']>, ParentType, ContextType, RequireFields<QueryRequestedPublicGroupsArgs, 'communityId'>>;
  researchTime?: Resolver<Maybe<ResolversTypes['ResearchTimeResult']>, ParentType, ContextType, RequireFields<QueryResearchTimeArgs, never>>;
  researchTimeLog?: Resolver<Maybe<Array<Maybe<ResolversTypes['ResearchTimeLogResult']>>>, ParentType, ContextType, RequireFields<QueryResearchTimeLogArgs, never>>;
  searchUniversityKnowledgeBase?: Resolver<Array<ResolversTypes['SearchResult']>, ParentType, ContextType, RequireFields<QuerySearchUniversityKnowledgeBaseArgs, 'limit' | 'searchTerm' | 'universityId'>>;
  sequenceFilterMentorList?: Resolver<Maybe<ResolversTypes['SequenceFilterMentorListField']>, ParentType, ContextType, RequireFields<QuerySequenceFilterMentorListArgs, never>>;
  serverTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signupsOverTime?: Resolver<Maybe<ResolversTypes['ApplicantSignupStats']>, ParentType, ContextType, RequireFields<QuerySignupsOverTimeArgs, 'endDate' | 'startDate'>>;
  singleInstanceOrganisations?: Resolver<Maybe<Array<Maybe<ResolversTypes['OrganisationField']>>>, ParentType, ContextType, RequireFields<QuerySingleInstanceOrganisationsArgs, 'active'>>;
  staffContactByUniversityId?: Resolver<Maybe<ResolversTypes['StaffContactOutput']>, ParentType, ContextType, RequireFields<QueryStaffContactByUniversityIdArgs, 'staffContactByUniversityIdInput'>>;
  subTopic?: Resolver<Maybe<ResolversTypes['SubTopicField']>, ParentType, ContextType>;
  subTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubTopicField']>>>, ParentType, ContextType, RequireFields<QuerySubTopicsArgs, never>>;
  switchUniversities?: Resolver<Maybe<ResolversTypes['SwitchUniversitiesField']>, ParentType, ContextType>;
  tag?: Resolver<Maybe<ResolversTypes['TagField']>, ParentType, ContextType, RequireFields<QueryTagArgs, 'id'>>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['TagField']>>>, ParentType, ContextType, RequireFields<QueryTagsArgs, never>>;
  timeTaken?: Resolver<Maybe<ResolversTypes['TimeTakenField']>, ParentType, ContextType, RequireFields<QueryTimeTakenArgs, never>>;
  topCountries?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopCountryField']>>>, ParentType, ContextType, RequireFields<QueryTopCountriesArgs, never>>;
  topDegrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopDegreeField']>>>, ParentType, ContextType, RequireFields<QueryTopDegreesArgs, never>>;
  topDomesticRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopDomesticRegionField']>>>, ParentType, ContextType, RequireFields<QueryTopDomesticRegionsArgs, never>>;
  topSignupsByCountry?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryStatField']>>>, ParentType, ContextType, RequireFields<QueryTopSignupsByCountryArgs, 'endDate' | 'startDate' | 'topX'>>;
  topSignupsByDegree?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeStatField']>>>, ParentType, ContextType, RequireFields<QueryTopSignupsByDegreeArgs, 'endDate' | 'startDate' | 'topX'>>;
  topSignupsByState?: Resolver<Maybe<Array<Maybe<ResolversTypes['StateStatField']>>>, ParentType, ContextType, RequireFields<QueryTopSignupsByStateArgs, 'endDate' | 'startDate' | 'topX'>>;
  topic?: Resolver<Maybe<ResolversTypes['TopicField']>, ParentType, ContextType, RequireFields<QueryTopicArgs, never>>;
  topicStatistics?: Resolver<Maybe<Array<Maybe<ResolversTypes['InsightsField']>>>, ParentType, ContextType, RequireFields<QueryTopicStatisticsArgs, 'groupBy'>>;
  topics?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopicField']>>>, ParentType, ContextType>;
  totalSignups?: Resolver<Maybe<ResolversTypes['SignUpsCount']>, ParentType, ContextType, RequireFields<QueryTotalSignupsArgs, 'endDate' | 'startDate'>>;
  triggerDegreeExport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTriggerDegreeExportArgs, never>>;
  triggerMentorExport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTriggerMentorExportArgs, 'department' | 'email' | 'firstName' | 'lastName' | 'onlyMentorsWithUnreads' | 'staffRole'>>;
  triggerProspectExportEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTriggerProspectExportEmailArgs, 'includeSmudged' | 'order'>>;
  triggerReportExport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTriggerReportExportArgs, never>>;
  triggerTopicStatisticsExport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryTriggerTopicStatisticsExportArgs, never>>;
  uniApplicantSignUps?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniApplicantSignUpsArgs, never>>;
  uniApplicantSignUpsBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniApplicantSignUpsBenchmarkArgs, never>>;
  uniChatGroups?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniChatGroupsArgs, never>>;
  uniChatGroupsBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniChatGroupsBenchmarkArgs, never>>;
  uniConversionRate?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniConversionRateArgs, never>>;
  uniConversionRateBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniConversionRateBenchmarkArgs, never>>;
  uniMessages?: Resolver<Maybe<ResolversTypes['ChatGroupAndMessages']>, ParentType, ContextType, RequireFields<QueryUniMessagesArgs, never>>;
  uniMessagesPerChatGroup?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniMessagesPerChatGroupArgs, never>>;
  uniResponseTime?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniResponseTimeArgs, never>>;
  uniResponseTimeBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniResponseTimeBenchmarkArgs, never>>;
  uniSentences?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniSentencesArgs, never>>;
  uniSentencesBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniSentencesBenchmarkArgs, never>>;
  uniSentencesPerChatGroup?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniSentencesPerChatGroupArgs, never>>;
  uniSentencesPerChatGroupBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniSentencesPerChatGroupBenchmarkArgs, never>>;
  uniTimeSpent?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniTimeSpentArgs, never>>;
  uniTimeSpentBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniTimeSpentBenchmarkArgs, never>>;
  uniUniqueVisits?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniUniqueVisitsArgs, never>>;
  uniUniqueVisitsBenchmark?: Resolver<Maybe<ResolversTypes['GraphField']>, ParentType, ContextType, RequireFields<QueryUniUniqueVisitsBenchmarkArgs, never>>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType, RequireFields<QueryUniversityArgs, never>>;
  universityAdmins?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityUserField']>>>, ParentType, ContextType>;
  universityAlerts?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlertField']>>>, ParentType, ContextType, RequireFields<QueryUniversityAlertsArgs, never>>;
  universityBlockReports?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityBlockReportField']>>>, ParentType, ContextType, RequireFields<QueryUniversityBlockReportsArgs, 'itemsPerPage' | 'order' | 'pageNum'>>;
  universityBlockReportsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  universityBlogPosts?: Resolver<Maybe<ResolversTypes['UniversityBlogPostsField']>, ParentType, ContextType, RequireFields<QueryUniversityBlogPostsArgs, never>>;
  universityBlogPostsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<QueryUniversityBlogPostsCountArgs, never>>;
  universityBlogTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogTopicField']>>>, ParentType, ContextType>;
  universityInbox?: Resolver<Maybe<ResolversTypes['UniversityInboxField']>, ParentType, ContextType, RequireFields<QueryUniversityInboxArgs, 'limit' | 'offset'>>;
  universityMentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType, RequireFields<QueryUniversityMentorArgs, 'id'>>;
  universityQuestionById?: Resolver<ResolversTypes['UniversityQuestion'], ParentType, ContextType, RequireFields<QueryUniversityQuestionByIdArgs, 'universityQuestionByIdInput'>>;
  universityQuestions?: Resolver<Array<ResolversTypes['UniversityQuestion']>, ParentType, ContextType, RequireFields<QueryUniversityQuestionsArgs, 'universityQuestionInput'>>;
  universitySnippets?: Resolver<Maybe<ResolversTypes['UniversitySnippetField']>, ParentType, ContextType, RequireFields<QueryUniversitySnippetsArgs, never>>;
  universityUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityUserField']>>>, ParentType, ContextType, RequireFields<QueryUniversityUsersArgs, never>>;
  unreadCount?: Resolver<Maybe<ResolversTypes['UnreadCountField']>, ParentType, ContextType>;
  upcomingExternalEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExternalEventField']>>>, ParentType, ContextType, RequireFields<QueryUpcomingExternalEventsArgs, never>>;
  upcomingLiveEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventField']>>>, ParentType, ContextType, RequireFields<QueryUpcomingLiveEventsArgs, never>>;
  userChatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType, RequireFields<QueryUserChatGroupsArgs, never>>;
  userPartialSearch?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserCustomAttributesFilterField']>>>, ParentType, ContextType, RequireFields<QueryUserPartialSearchArgs, never>>;
  userPartialSearchMultipleFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserPartialSearchFields']>>>, ParentType, ContextType, RequireFields<QueryUserPartialSearchMultipleFieldsArgs, never>>;
  userPermissions?: Resolver<Maybe<ResolversTypes['UserPermissionsListField']>, ParentType, ContextType>;
  userTimeSpentBreakdown?: Resolver<Maybe<ResolversTypes['UserTimeSpentBreakdown']>, ParentType, ContextType, RequireFields<QueryUserTimeSpentBreakdownArgs, never>>;
  userUnreadMessageCount?: Resolver<Maybe<ResolversTypes['UnreadMessageCountField']>, ParentType, ContextType>;
  verifyCode?: Resolver<Maybe<ResolversTypes['VerifyCodeField']>, ParentType, ContextType, RequireFields<QueryVerifyCodeArgs, never>>;
  virtualEvent?: Resolver<Maybe<ResolversTypes['VirtualEvent']>, ParentType, ContextType, RequireFields<QueryVirtualEventArgs, 'id'>>;
  virtualEventAttendees?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType, RequireFields<QueryVirtualEventAttendeesArgs, 'virtualEventId'>>;
  virtualEventProspectReport?: Resolver<Maybe<ResolversTypes['ReportAnalytics']>, ParentType, ContextType, RequireFields<QueryVirtualEventProspectReportArgs, 'virtualEventId'>>;
  virtualEvents?: Resolver<ResolversTypes['PaginatedVirtualEvents'], ParentType, ContextType, RequireFields<QueryVirtualEventsArgs, 'pagination' | 'universityId'>>;
  virtualEventsByUniversityIds?: Resolver<ResolversTypes['PaginatedVirtualEvents'], ParentType, ContextType, RequireFields<QueryVirtualEventsByUniversityIdsArgs, 'pagination' | 'universityIds'>>;
};

export type QueryRecordingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['QueryRecordingResponse'] = ResolversParentTypes['QueryRecordingResponse']> = {
  recording?: Resolver<Maybe<ResolversTypes['RecordingModel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionField'] = ResolversParentTypes['QuestionField']> = {
  questionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RandomMentorsListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['RandomMentorsListField'] = ResolversParentTypes['RandomMentorsListField']> = {
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['PublicMentorField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reaction'] = ResolversParentTypes['Reaction']> = {
  reaction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactionUnicode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReadChatMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReadChatMutation'] = ResolversParentTypes['ReadChatMutation']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Recommendation'] = ResolversParentTypes['Recommendation']> = {
  batchId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  contentId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['RecommendationSource'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['RecommendationResourceTypeEnum'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecommendationResourceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecommendationResourceType'] = ResolversParentTypes['RecommendationResourceType']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['RecommendationResourceTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordUserActivityMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordUserActivityMutation'] = ResolversParentTypes['RecordUserActivityMutation']> = {
  newActivity?: Resolver<Maybe<ResolversTypes['ActivityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingConsentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingConsent'] = ResolversParentTypes['RecordingConsent']> = {
  channelId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingDownloadResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingDownloadResponse'] = ResolversParentTypes['RecordingDownloadResponse']> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingFailureIdResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingFailureId'] = ResolversParentTypes['RecordingFailureId']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingFailureResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingFailureResponse'] = ResolversParentTypes['RecordingFailureResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingListResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingListResponse'] = ResolversParentTypes['RecordingListResponse']> = {
  files?: Resolver<Array<ResolversTypes['VideoFile']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingModel'] = ResolversParentTypes['RecordingModel']> = {
  channelId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  layoutMode?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  layoutStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordingStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingResponse'] = ResolversParentTypes['RecordingResponse']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecordingUploadingResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RecordingUploadingResponse'] = ResolversParentTypes['RecordingUploadingResponse']> = {
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegisterToLiveEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisterToLiveEvent'] = ResolversParentTypes['RegisterToLiveEvent']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveBuddyInvitesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemoveBuddyInvitesMutation'] = ResolversParentTypes['RemoveBuddyInvitesMutation']> = {
  successfulInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddyInviteListField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveDeviceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemoveDeviceMutation'] = ResolversParentTypes['RemoveDeviceMutation']> = {
  result?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveMentorInvitesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RemoveMentorInvitesMutation'] = ResolversParentTypes['RemoveMentorInvitesMutation']> = {
  mentorInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorInviteField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReplyMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReplyMessage'] = ResolversParentTypes['ReplyMessage']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReplyOutputFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReplyOutputField'] = ResolversParentTypes['ReplyOutputField']> = {
  isSenderBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalMessageText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  senderAccountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  senderFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  senderId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportAnalytics'] = ResolversParentTypes['ReportAnalytics']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  reportLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reportStatus?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  virtualEventId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestAnalyticsExportMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestAnalyticsExportMutation'] = ResolversParentTypes['RequestAnalyticsExportMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestPasswordResetMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestPasswordResetMutation'] = ResolversParentTypes['RequestPasswordResetMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestPasswordSetMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestPasswordSetMutation'] = ResolversParentTypes['RequestPasswordSetMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestedPublicGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestedPublicGroup'] = ResolversParentTypes['RequestedPublicGroup']> = {
  community?: Resolver<ResolversTypes['Community'], ParentType, ContextType>;
  conversation?: Resolver<ResolversTypes['ChatConversation'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  requestedBy?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  resolvedBy?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['PublicGroupStatus'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResearchTimeLogResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResearchTimeLogResult'] = ResolversParentTypes['ResearchTimeLogResult']> = {
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeSpentSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResearchTimeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResearchTimeResult'] = ResolversParentTypes['ResearchTimeResult']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeSpentSeconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetPasswordMobileMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetPasswordMobileMutation'] = ResolversParentTypes['ResetPasswordMobileMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetPasswordMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetPasswordMutation'] = ResolversParentTypes['ResetPasswordMutation']> = {
  redirectUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetPasswordMutationV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['ResetPasswordMutationV2'] = ResolversParentTypes['ResetPasswordMutationV2']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  redirectUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResolveUniversityChatsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResolveUniversityChatsMutation'] = ResolversParentTypes['ResolveUniversityChatsMutation']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RichContentOutputFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['RichContentOutputField'] = ResolversParentTypes['RichContentOutputField']> = {
  reply?: Resolver<Maybe<ResolversTypes['ReplyOutputField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RichMessageContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['RichMessageContent'] = ResolversParentTypes['RichMessageContent']> = {
  attachments?: Resolver<Maybe<Array<ResolversTypes['Attachment']>>, ParentType, ContextType>;
  giphy?: Resolver<Maybe<ResolversTypes['Giphy']>, ParentType, ContextType>;
  images?: Resolver<Maybe<Array<ResolversTypes['Image']>>, ParentType, ContextType>;
  poll?: Resolver<Maybe<ResolversTypes['Poll']>, ParentType, ContextType>;
  reply?: Resolver<Maybe<ResolversTypes['ChatMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RunManageCommandMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['RunManageCommandMutation'] = ResolversParentTypes['RunManageCommandMutation']> = {
  output?: Resolver<Maybe<ResolversTypes['OutputField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SatisfactionSurveyEntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['SatisfactionSurveyEntity'] = ResolversParentTypes['SatisfactionSurveyEntity']> = {
  communityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universitySlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveAppliedUsersModelResolvers<ContextType = any, ParentType extends ResolversParentTypes['SaveAppliedUsersModel'] = ResolversParentTypes['SaveAppliedUsersModel']> = {
  saved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendCommunityVerificationCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendCommunityVerificationCode'] = ResolversParentTypes['SendCommunityVerificationCode']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendFeedMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendFeedMessageMutation'] = ResolversParentTypes['SendFeedMessageMutation']> = {
  message?: Resolver<Maybe<ResolversTypes['MessageField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendLiveEventMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendLiveEventMessageMutation'] = ResolversParentTypes['SendLiveEventMessageMutation']> = {
  botMessages?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['MessageField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendMessageMutation'] = ResolversParentTypes['SendMessageMutation']> = {
  botMessages?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageField']>>>, ParentType, ContextType>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['MessageField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendOpenDayProspectReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendOpenDayProspectReport'] = ResolversParentTypes['SendOpenDayProspectReport']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendResetPasswordEmailResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendResetPasswordEmail'] = ResolversParentTypes['SendResetPasswordEmail']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendVerificationCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendVerificationCode'] = ResolversParentTypes['SendVerificationCode']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SequenceFilterMentorListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SequenceFilterMentorListField'] = ResolversParentTypes['SequenceFilterMentorListField']> = {
  countries?: Resolver<Maybe<Array<Maybe<ResolversTypes['CountryField']>>>, ParentType, ContextType>;
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorFilterField']>>>, ParentType, ContextType>;
  levels?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  mentorSeed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  mentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['PublicMentorField']>>>, ParentType, ContextType>;
  validCombination?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionAnalytics'] = ResolversParentTypes['SessionAnalytics']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  liveSessionId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  session?: Resolver<Maybe<ResolversTypes['LiveSession']>, ParentType, ContextType>;
  sessionAttendees?: Resolver<ResolversTypes['SessionAttendees'], ParentType, ContextType>;
  sessionMessagesSent?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  virtualEventId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionAttendeeAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionAttendeeAnalytics'] = ResolversParentTypes['SessionAttendeeAnalytics']> = {
  accountRole?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  liveSessionId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SessionAttendeesResolvers<ContextType = any, ParentType extends ResolversParentTypes['SessionAttendees'] = ResolversParentTypes['SessionAttendees']> = {
  activeApplicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  applicants?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetActiveBlogTopicsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetActiveBlogTopicsMutation'] = ResolversParentTypes['SetActiveBlogTopicsMutation']> = {
  blogTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogTopicField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetAllNotificationsReadMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetAllNotificationsReadMutation'] = ResolversParentTypes['SetAllNotificationsReadMutation']> = {
  notifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['NotificationField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetArchivedContentBriefMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetArchivedContentBriefMutation'] = ResolversParentTypes['SetArchivedContentBriefMutation']> = {
  contentBrief?: Resolver<Maybe<ResolversTypes['ContentBriefField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetArchivedDegreeLevelMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetArchivedDegreeLevelMutation'] = ResolversParentTypes['SetArchivedDegreeLevelMutation']> = {
  degreeLevel?: Resolver<Maybe<ResolversTypes['DegreeLevelField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetArchivedYearOfStudyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetArchivedYearOfStudyMutation'] = ResolversParentTypes['SetArchivedYearOfStudyMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yearOfStudy?: Resolver<Maybe<ResolversTypes['YearOfStudyField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetBuddiesListFiltersResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetBuddiesListFilters'] = ResolversParentTypes['SetBuddiesListFilters']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetChatGroupLabelsMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetChatGroupLabelsMutation'] = ResolversParentTypes['SetChatGroupLabelsMutation']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetMentorFieldOrderMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetMentorFieldOrderMutation'] = ResolversParentTypes['SetMentorFieldOrderMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetMentorListFilterSelectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetMentorListFilterSelection'] = ResolversParentTypes['SetMentorListFilterSelection']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetOnlineMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetOnlineMutation'] = ResolversParentTypes['SetOnlineMutation']> = {
  online?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetResolveChatGroupMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetResolveChatGroupMutation'] = ResolversParentTypes['SetResolveChatGroupMutation']> = {
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetWillReplyMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SetWillReplyMutation'] = ResolversParentTypes['SetWillReplyMutation']> = {
  chatGroupAux?: Resolver<Maybe<ResolversTypes['ChatGroupAuxiliaryField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingsObjectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SettingsObjectType'] = ResolversParentTypes['SettingsObjectType']> = {
  allowDirectChat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  allowGifs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowImages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  allowNewsFeed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  allowPrivateGroups?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShowMentorInMarketplaceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShowMentorInMarketplaceMutation'] = ResolversParentTypes['ShowMentorInMarketplaceMutation']> = {
  mentor?: Resolver<Maybe<ResolversTypes['MentorField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignUpsCountResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignUpsCount'] = ResolversParentTypes['SignUpsCount']> = {
  totalSignups?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignedS3PostFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignedS3POSTFields'] = ResolversParentTypes['SignedS3POSTFields']> = {
  fields?: Resolver<Maybe<ResolversTypes['PostFields']>, ParentType, ContextType>;
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignedS3UrlFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignedS3URLField'] = ResolversParentTypes['SignedS3URLField']> = {
  mediaUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  objectName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SingleChoiceAttributeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SingleChoiceAttributeField'] = ResolversParentTypes['SingleChoiceAttributeField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isSyncedWithCrm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeOptionField']>>>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SingleChoiceValueFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SingleChoiceValueField'] = ResolversParentTypes['SingleChoiceValueField']> = {
  attribute?: Resolver<Maybe<ResolversTypes['CustomAttributeField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  option?: Resolver<Maybe<ResolversTypes['CustomAttributeOptionField']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SourceTrackingParamsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceTrackingParamsField'] = ResolversParentTypes['SourceTrackingParamsField']> = {
  ubCampaign?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ubContent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ubMedium?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ubSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffBuddycardSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffBuddycardSnippetField'] = ResolversParentTypes['StaffBuddycardSnippetField']> = {
  departmentSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['DepartmentSnippetField']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffChatUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffChatUserField'] = ResolversParentTypes['StaffChatUserField']> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<StaffChatUserFieldProfilePhotoArgs, never>>;
  staffFields?: Resolver<Maybe<ResolversTypes['StaffFieldsChatUserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffContactOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffContactOutput'] = ResolversParentTypes['StaffContactOutput']> = {
  contact?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffField'] = ResolversParentTypes['StaffField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<StaffFieldProfilePhotoArgs, never>>;
  staffFields?: Resolver<Maybe<ResolversTypes['StaffUserFieldsField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffFieldsChatUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffFieldsChatUserField'] = ResolversParentTypes['StaffFieldsChatUserField']> = {
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffPopcardSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffPopcardSnippetField'] = ResolversParentTypes['StaffPopcardSnippetField']> = {
  departmentSnippet?: Resolver<Maybe<Array<Maybe<ResolversTypes['DepartmentSnippetField']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffProfileInformationResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffProfileInformation'] = ResolversParentTypes['StaffProfileInformation']> = {
  accountRole?: Resolver<ResolversTypes['Buddies'], ParentType, ContextType>;
  bio?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  department?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profilePhoto?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  profileUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universityId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffUserField'] = ResolversParentTypes['StaffUserField']> = {
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffUserFieldsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffUserFieldsField'] = ResolversParentTypes['StaffUserFieldsField']> = {
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StaffUserListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StaffUserListField'] = ResolversParentTypes['StaffUserListField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['StaffField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateStatFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['StateStatField'] = ResolversParentTypes['StateStatField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubTopicFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubTopicField'] = ResolversParentTypes['SubTopicField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['TopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubTopicStatFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubTopicStatField'] = ResolversParentTypes['SubTopicStatField']> = {
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubTopicStatisticFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SubTopicStatisticField'] = ResolversParentTypes['SubTopicStatisticField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuggestionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuggestionField'] = ResolversParentTypes['SuggestionField']> = {
  canonicalQuestions?: Resolver<Maybe<Array<Maybe<ResolversTypes['CanonicalQuestionField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sentence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topSubTopic?: Resolver<Maybe<ResolversTypes['TopSubTopicField']>, ParentType, ContextType>;
  topSubTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopSubTopicField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwitchUniversitiesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwitchUniversitiesField'] = ResolversParentTypes['SwitchUniversitiesField']> = {
  universities?: Resolver<Maybe<Array<Maybe<ResolversTypes['SwitchUniversityListField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwitchUniversityFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwitchUniversityField'] = ResolversParentTypes['SwitchUniversityField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squareLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whiteLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SwitchUniversityListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['SwitchUniversityListField'] = ResolversParentTypes['SwitchUniversityListField']> = {
  university?: Resolver<Maybe<ResolversTypes['SwitchUniversityField']>, ParentType, ContextType>;
  unreadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TagField'] = ResolversParentTypes['TagField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextInLocaleResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextInLocale'] = ResolversParentTypes['TextInLocale']> = {
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeTakenFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeTakenField'] = ResolversParentTypes['TimeTakenField']> = {
  totalTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleCrmIntegrationSyncResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToggleCrmIntegrationSync'] = ResolversParentTypes['ToggleCrmIntegrationSync']> = {
  integration?: Resolver<Maybe<ResolversTypes['CRMIntegrationField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleCustomAttributeCrmSyncMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToggleCustomAttributeCrmSyncMutation'] = ResolversParentTypes['ToggleCustomAttributeCrmSyncMutation']> = {
  customAttributeNewSyncStatus?: Resolver<Maybe<ResolversTypes['CrmFieldSyncStatus']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleDegreesActiveMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToggleDegreesActiveMutation'] = ResolversParentTypes['ToggleDegreesActiveMutation']> = {
  degrees?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToggleFeatureAvailabilityMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToggleFeatureAvailabilityMutation'] = ResolversParentTypes['ToggleFeatureAvailabilityMutation']> = {
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopCountryFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopCountryField'] = ResolversParentTypes['TopCountryField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopDegreeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopDegreeField'] = ResolversParentTypes['TopDegreeField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  degree?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopDomesticRegionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopDomesticRegionField'] = ResolversParentTypes['TopDomesticRegionField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  domesticRegion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopRegionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopRegionField'] = ResolversParentTypes['TopRegionField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopSubTopicFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopSubTopicField'] = ResolversParentTypes['TopSubTopicField']> = {
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  probability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['TopTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopTopicFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopTopicField'] = ResolversParentTypes['TopTopicField']> = {
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  probability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopicField'] = ResolversParentTypes['TopicField']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubTopicField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopicSnippetField'] = ResolversParentTypes['TopicSnippetField']> = {
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topic?: Resolver<Maybe<ResolversTypes['BlogTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicStatisticFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopicStatisticField'] = ResolversParentTypes['TopicStatisticField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubTopicStatisticField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopicsStatFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopicsStatField'] = ResolversParentTypes['TopicsStatField']> = {
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  subTopics?: Resolver<Maybe<Array<Maybe<ResolversTypes['SubTopicStatField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrackLiveEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrackLiveEventMutation'] = ResolversParentTypes['TrackLiveEventMutation']> = {
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrackOpenDayMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrackOpenDayMutation'] = ResolversParentTypes['TrackOpenDayMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrendingTopicsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrendingTopicsField'] = ResolversParentTypes['TrendingTopicsField']> = {
  aggregatedCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topics?: Resolver<Maybe<Array<Maybe<ResolversTypes['TopicsStatField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityAssistantConfigurationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityAssistantConfiguration'] = ResolversParentTypes['UniversityAssistantConfiguration']> = {
  eventUrls?: Resolver<Maybe<Array<ResolversTypes['UniversityAssistantConfigurationEventUrls']>>, ParentType, ContextType>;
  promptContext?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityAssistantConfigurationEventOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityAssistantConfigurationEventOutput'] = ResolversParentTypes['UniversityAssistantConfigurationEventOutput']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityAssistantConfigurationEventUrlsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityAssistantConfigurationEventUrls'] = ResolversParentTypes['UniversityAssistantConfigurationEventUrls']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityAssistantConfigurationPromptContextOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityAssistantConfigurationPromptContextOutput'] = ResolversParentTypes['UniversityAssistantConfigurationPromptContextOutput']> = {
  promptContext?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityBlockReportFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityBlockReportField'] = ResolversParentTypes['UniversityBlockReportField']> = {
  blocked?: Resolver<Maybe<ResolversTypes['ApplicantField']>, ParentType, ContextType>;
  blocker?: Resolver<Maybe<ResolversTypes['AnyUserField']>, ParentType, ContextType>;
  chatGroup?: Resolver<Maybe<ResolversTypes['ChatGroupField']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  conversation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  triggeredFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityBlockUsersMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityBlockUsersMutation'] = ResolversParentTypes['UniversityBlockUsersMutation']> = {
  count?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityBlogPostsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityBlogPostsField'] = ResolversParentTypes['UniversityBlogPostsField']> = {
  blogPostCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  blogPosts?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlogPostField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityChatInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityChatInboxField'] = ResolversParentTypes['UniversityChatInboxField']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['InboxItemLastMessageField']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatUserField']>>>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['InboxUniversityField']>, ParentType, ContextType>;
  unreadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityConfigFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityConfigField'] = ResolversParentTypes['UniversityConfigField']> = {
  features?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityFeatureField']>>>, ParentType, ContextType>;
  featuresWithUniversityControl?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityFeatureField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityContent'] = ResolversParentTypes['UniversityContent']> = {
  hasContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityFeatureFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityFeatureField'] = ResolversParentTypes['UniversityFeatureField']> = {
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enabledByUnibuddy?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  enabledByUniversity?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  feature?: Resolver<Maybe<ResolversTypes['FeatureField']>, ParentType, ContextType>;
  isReadOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  universityCanToggleFeature?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityField'] = ResolversParentTypes['UniversityField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowWelcomeMessageCustomization?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowWelcomeMessageCustomizationStaff?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  buddiesListFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['BuddiesListFilterField']>>>, ParentType, ContextType>;
  chatboxUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  churned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  colour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  config?: Resolver<Maybe<ResolversTypes['UniversityConfigField']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  customAttribute?: Resolver<Maybe<ResolversTypes['CustomAttributeField']>, ParentType, ContextType, RequireFields<UniversityFieldCustomAttributeArgs, never>>;
  customAttributeOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeOptionField']>>>, ParentType, ContextType, RequireFields<UniversityFieldCustomAttributeOptionsArgs, never>>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeField']>>>, ParentType, ContextType, RequireFields<UniversityFieldCustomAttributesArgs, never>>;
  customChatBubbleTextInLocales?: Resolver<Maybe<Array<Maybe<ResolversTypes['TextInLocale']>>>, ParentType, ContextType>;
  customMarketingConsentText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customMarketingConsentTextInLocales?: Resolver<Maybe<Array<Maybe<ResolversTypes['TextInLocale']>>>, ParentType, ContextType>;
  customPreSignupText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customPreSignupTextInLocales?: Resolver<Maybe<Array<Maybe<ResolversTypes['TextInLocale']>>>, ParentType, ContextType>;
  defaultProspectWelcomeMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultProspectWelcomeMessageStaff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degreeTypeOrder?: Resolver<Maybe<Array<Maybe<ResolversTypes['DegreeTypeOrderField']>>>, ParentType, ContextType>;
  domesticRegions?: Resolver<Maybe<Array<Maybe<ResolversTypes['DomesticRegionField']>>>, ParentType, ContextType>;
  entries?: Resolver<Maybe<ResolversTypes['EntriesField']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isEnterprise?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFeatureEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<UniversityFieldIsFeatureEnabledArgs, never>>;
  isFeatureReadOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<UniversityFieldIsFeatureReadOnlyArgs, never>>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isReadyToLaunch?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSyncedWithCrm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  launchDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  localePreference?: Resolver<Maybe<ResolversTypes['Locale']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketplaces?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityMarketplaceField']>>>, ParentType, ContextType>;
  mentorFieldOrder?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorFieldOrderField']>>>, ParentType, ContextType>;
  mentorListFilters?: Resolver<Maybe<Array<Maybe<ResolversTypes['MentorListFilterChoice']>>>, ParentType, ContextType>;
  mentorMessagesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  onlineMentorAvatars?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  privacyPolicyUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  segment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  squareLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whiteLogo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  widgetUrls?: Resolver<Maybe<Array<Maybe<ResolversTypes['WidgetUrlField']>>>, ParentType, ContextType, RequireFields<UniversityFieldWidgetUrlsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityInboxFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityInboxField'] = ResolversParentTypes['UniversityInboxField']> = {
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatGroups?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChatGroupField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityMarketplaceFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityMarketplaceField'] = ResolversParentTypes['UniversityMarketplaceField']> = {
  allowProspectDataAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketingOptInWording?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tncWording?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityMarketplaceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityOutput'] = ResolversParentTypes['UniversityOutput']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  isFeatureEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityQuestion'] = ResolversParentTypes['UniversityQuestion']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  answers?: Resolver<Array<ResolversTypes['UniversityQuestionAnswer']>, ParentType, ContextType, RequireFields<UniversityQuestionAnswersArgs, 'universityQuestionAnswerInput'>>;
  contexts?: Resolver<Array<ResolversTypes['UniversityQuestionContext']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  isMultipleAnswersAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  options?: Resolver<Maybe<Array<ResolversTypes['UniversityQuestionOption']>>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['UniversityQuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityQuestionAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityQuestionAnswer'] = ResolversParentTypes['UniversityQuestionAnswer']> = {
  answeredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  context?: Resolver<Maybe<ResolversTypes['UniversityQuestionContext']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityQuestionContextResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityQuestionContext'] = ResolversParentTypes['UniversityQuestionContext']> = {
  id?: Resolver<Maybe<ResolversTypes['ObjectId']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityQuestionOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityQuestionOption'] = ResolversParentTypes['UniversityQuestionOption']> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityScrapeIndexingOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityScrapeIndexingOutput'] = ResolversParentTypes['UniversityScrapeIndexingOutput']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  jobStatus?: Resolver<ResolversTypes['ScraperJobStatus'], ParentType, ContextType>;
  taskCompletedCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taskInFlightCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  taskQueuedCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tasks?: Resolver<Array<ResolversTypes['UniversityScrapeTask']>, ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityScrapeOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityScrapeOutput'] = ResolversParentTypes['UniversityScrapeOutput']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobStatus?: Resolver<ResolversTypes['ScraperJobStatus'], ParentType, ContextType>;
  rootUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  started?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityScrapeStopOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityScrapeStopOutput'] = ResolversParentTypes['UniversityScrapeStopOutput']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  jobStatus?: Resolver<ResolversTypes['ScraperJobStatus'], ParentType, ContextType>;
  rootUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityScrapeTaskResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityScrapeTask'] = ResolversParentTypes['UniversityScrapeTask']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  depth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  error?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  httpStatus?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  jobId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestDurationMs?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  rootUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taskStatus?: Resolver<ResolversTypes['ScraperTaskStatus'], ParentType, ContextType>;
  universityId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversitySnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversitySnippetField'] = ResolversParentTypes['UniversitySnippetField']> = {
  blogcardSnippets?: Resolver<Maybe<ResolversTypes['BlogcardSnippetField']>, ParentType, ContextType>;
  carouselWidgetSnippets?: Resolver<Maybe<ResolversTypes['CarouselSnippetField']>, ParentType, ContextType>;
  courseWidgetSnippets?: Resolver<Maybe<ResolversTypes['CourseSnippetField']>, ParentType, ContextType>;
  embeddedEventSnippet?: Resolver<Maybe<ResolversTypes['EmbeddedEventSnippetField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentorcardExternalSnippets?: Resolver<Maybe<ResolversTypes['MentorcardSnippetField']>, ParentType, ContextType>;
  mentorcardInternalSnippets?: Resolver<Maybe<ResolversTypes['MentorcardSnippetField']>, ParentType, ContextType>;
  popcardSnippets?: Resolver<Maybe<ResolversTypes['PopcardSnippetField']>, ParentType, ContextType>;
  staffBuddycardExternalSnippet?: Resolver<Maybe<ResolversTypes['StaffBuddycardSnippetField']>, ParentType, ContextType>;
  staffBuddycardInternalSnippet?: Resolver<Maybe<ResolversTypes['StaffBuddycardSnippetField']>, ParentType, ContextType>;
  staffPopcardSnippet?: Resolver<Maybe<ResolversTypes['StaffPopcardSnippetField']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  widgetSnippet?: Resolver<Maybe<ResolversTypes['WidgetSnippetField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityUserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityUserField'] = ResolversParentTypes['UniversityUserField']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['UniversityUserPreferencesField']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UniversityUserPreferencesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UniversityUserPreferencesField'] = ResolversParentTypes['UniversityUserPreferencesField']> = {
  newMessageNotifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnpinMessageMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnpinMessageMutation'] = ResolversParentTypes['UnpinMessageMutation']> = {
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadCountFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnreadCountField'] = ResolversParentTypes['UnreadCountField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadMessageCountFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UnreadMessageCountField'] = ResolversParentTypes['UnreadMessageCountField']> = {
  unreadCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateApplicantMutation'] = ResolversParentTypes['UpdateApplicantMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateApplicantPreferencesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateApplicantPreferencesMutation'] = ResolversParentTypes['UpdateApplicantPreferencesMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCanonicalQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCanonicalQuestion'] = ResolversParentTypes['UpdateCanonicalQuestion']> = {
  canonicalQuestion?: Resolver<Maybe<ResolversTypes['CanonicalQuestionField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateCommunityApplicantSignupSourceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCommunityApplicantSignupSourceMutation'] = ResolversParentTypes['UpdateCommunityApplicantSignupSourceMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateDirectMessagingMembersForLiveEventMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateDirectMessagingMembersForLiveEventMutation'] = ResolversParentTypes['UpdateDirectMessagingMembersForLiveEventMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEvent?: Resolver<Maybe<ResolversTypes['LiveEventField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateFaqAnswerResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateFaqAnswer'] = ResolversParentTypes['UpdateFaqAnswer']> = {
  faqAnswer?: Resolver<Maybe<ResolversTypes['FaqAnswerField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateHobsonsConnectIntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateHobsonsConnectIntegration'] = ResolversParentTypes['UpdateHobsonsConnectIntegration']> = {
  integration?: Resolver<Maybe<ResolversTypes['CampusManagementConnectIntegrationField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateIntegrationFieldMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateIntegrationFieldMapping'] = ResolversParentTypes['UpdateIntegrationFieldMapping']> = {
  integration?: Resolver<Maybe<ResolversTypes['CRMIntegrationField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateLiveEventFeedSessionDataMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateLiveEventFeedSessionDataMutation'] = ResolversParentTypes['UpdateLiveEventFeedSessionDataMutation']> = {
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['LiveEventsFormFieldValidationError']>>>, ParentType, ContextType>;
  liveEventFeed?: Resolver<Maybe<ResolversTypes['LiveEventChatGroupField']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateManyUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateManyUserMutation'] = ResolversParentTypes['UpdateManyUserMutation']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMarketplaceApplicantMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMarketplaceApplicantMutation'] = ResolversParentTypes['UpdateMarketplaceApplicantMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMarketplaceApplicantUniversityLinkMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMarketplaceApplicantUniversityLinkMutation'] = ResolversParentTypes['UpdateMarketplaceApplicantUniversityLinkMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMarketplaceMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMarketplaceMutation'] = ResolversParentTypes['UpdateMarketplaceMutation']> = {
  marketplace?: Resolver<Maybe<ResolversTypes['MarketplaceField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMentorMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMentorMutation'] = ResolversParentTypes['UpdateMentorMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMentorPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMentorPreferences'] = ResolversParentTypes['UpdateMentorPreferences']> = {
  preferences?: Resolver<Maybe<ResolversTypes['MentorPreferencesField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateMyUniversityLinkMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMyUniversityLinkMutation'] = ResolversParentTypes['UpdateMyUniversityLinkMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatePostStatusMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatePostStatusMutation'] = ResolversParentTypes['UpdatePostStatusMutation']> = {
  blogPost?: Resolver<Maybe<ResolversTypes['BlogPostField']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateResearchTimeMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateResearchTimeMutation'] = ResolversParentTypes['UpdateResearchTimeMutation']> = {
  result?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateStaffMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateStaffMutation'] = ResolversParentTypes['UpdateStaffMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateSubTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateSubTopicMutation'] = ResolversParentTypes['UpdateSubTopicMutation']> = {
  subTopic?: Resolver<Maybe<ResolversTypes['SubTopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTagMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTagMutation'] = ResolversParentTypes['UpdateTagMutation']> = {
  tag?: Resolver<Maybe<ResolversTypes['TagField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateTopicMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTopicMutation'] = ResolversParentTypes['UpdateTopicMutation']> = {
  topic?: Resolver<Maybe<ResolversTypes['TopicField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUniversityBlockReportMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUniversityBlockReportMutation'] = ResolversParentTypes['UpdateUniversityBlockReportMutation']> = {
  universityBlockReport?: Resolver<Maybe<ResolversTypes['UniversityBlockReportField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUniversityConfigMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUniversityConfigMutation'] = ResolversParentTypes['UpdateUniversityConfigMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  universityConfiguration?: Resolver<Maybe<ResolversTypes['CrmServiceUniversityConfig']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUniversityUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUniversityUserMutation'] = ResolversParentTypes['UpdateUniversityUserMutation']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUniversityUserPreferencesMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUniversityUserPreferencesMutation'] = ResolversParentTypes['UpdateUniversityUserPreferencesMutation']> = {
  me?: Resolver<Maybe<ResolversTypes['MeField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserMutation'] = ResolversParentTypes['UpdateUserMutation']> = {
  user?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUserPreferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserPreferences'] = ResolversParentTypes['UpdateUserPreferences']> = {
  preferences?: Resolver<Maybe<ResolversTypes['UserPreferencesField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdatedResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdatedResults'] = ResolversParentTypes['UpdatedResults']> = {
  acknowledged?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  matchedCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  modifiedCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  upsertedCount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  upsertedId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserCustomAttributesFilterFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCustomAttributesFilterField'] = ResolversParentTypes['UserCustomAttributesFilterField']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDecisionStageOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDecisionStageOutput'] = ResolversParentTypes['UserDecisionStageOutput']> = {
  decisionStage?: Resolver<ResolversTypes['DecisionStageEnum'], ParentType, ContextType>;
  decisionStageLocation?: Resolver<ResolversTypes['DecisionStageLocationEnum'], ParentType, ContextType>;
  notInterestedReason?: Resolver<Maybe<ResolversTypes['NotInterestedReasonEnum']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDegreeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDegreeField'] = ResolversParentTypes['UserDegreeField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDirectoryResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserDirectoryResult'] = ResolversParentTypes['UserDirectoryResult']> = {
  result?: Resolver<Array<ResolversTypes['UserField']>, ParentType, ContextType>;
  totalUsers?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserField'] = ResolversParentTypes['UserField']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedByMentors?: Resolver<Maybe<Array<Maybe<ResolversTypes['BlockerField']>>>, ParentType, ContextType>;
  blockedByUniversities?: Resolver<Maybe<Array<Maybe<ResolversTypes['UniversityField']>>>, ParentType, ContextType>;
  chatGroupCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatIntro?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chatToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communities?: Resolver<Maybe<Array<ResolversTypes['Community']>>, ParentType, ContextType>;
  communityProfile?: Resolver<Maybe<ResolversTypes['UserProfile']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryField']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customAttributes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CustomAttributeValueField']>>>, ParentType, ContextType, RequireFields<UserFieldCustomAttributesArgs, never>>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupField']>>>, ParentType, ContextType>;
  hasUnansweredConversation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  interests?: Resolver<Array<ResolversTypes['Interest']>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<Maybe<ResolversTypes['LanguageField']>>>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastSeen?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  messageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferences?: Resolver<Maybe<ResolversTypes['UserPreferencesField']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<UserFieldProfilePhotoArgs, never>>;
  prospectWelcomeMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolvedUnansweredConversations?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  shareUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smudged?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  staffFields?: Resolver<Maybe<ResolversTypes['StaffUserFieldsField']>, ParentType, ContextType>;
  stageOneComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  stageTwoComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  unansweredCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['UniversityField']>, ParentType, ContextType>;
  unreadMessageCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserIdsListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserIdsListField'] = ResolversParentTypes['UserIdsListField']> = {
  ids?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInterestResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInterest'] = ResolversParentTypes['UserInterest']> = {
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserListField'] = ResolversParentTypes['UserListField']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserLocalePreferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserLocalePreference'] = ResolversParentTypes['UserLocalePreference']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPartialSearchFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPartialSearchFields'] = ResolversParentTypes['UserPartialSearchFields']> = {
  accountRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  active?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  concatenatedFields?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPermissionsListFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPermissionsListField'] = ResolversParentTypes['UserPermissionsListField']> = {
  permissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferencesFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreferencesField'] = ResolversParentTypes['UserPreferencesField']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newMessageNotifications?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserProfile'] = ResolversParentTypes['UserProfile']> = {
  areaOfStudy?: Resolver<Maybe<Array<ResolversTypes['DegreeField']>>, ParentType, ContextType>;
  bio?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  communities?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  housing?: Resolver<Maybe<ResolversTypes['HousingObject']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  interests?: Resolver<Maybe<Array<ResolversTypes['InterestObject']>>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pronouns?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserRecommendationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserRecommendation'] = ResolversParentTypes['UserRecommendation']> = {
  currentUser?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  matches?: Resolver<Array<ResolversTypes['MatchedUser']>, ParentType, ContextType>;
  totalMatches?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserReport'] = ResolversParentTypes['UserReport']> = {
  actionTaken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  community?: Resolver<Maybe<ResolversTypes['Community']>, ParentType, ContextType>;
  creationTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  moderationTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  moderator?: Resolver<Maybe<ResolversTypes['UserField']>, ParentType, ContextType>;
  moderatorComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modificationTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  reportee?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  reporter?: Resolver<ResolversTypes['UserField'], ParentType, ContextType>;
  reporterComment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reporterReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resolved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  university?: Resolver<ResolversTypes['UniversityField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTimeSheetExportFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTimeSheetExportField'] = ResolversParentTypes['UserTimeSheetExportField']> = {
  blogs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conversations?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degreeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  research?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalTimeSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTimeSpentBreakdownResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTimeSpentBreakdown'] = ResolversParentTypes['UserTimeSpentBreakdown']> = {
  blogs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conversations?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  liveEvents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  research?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalTimeSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTimeSpentStatsFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTimeSpentStatsField'] = ResolversParentTypes['UserTimeSpentStatsField']> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  blogs?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  conversations?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  countryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  degree?: Resolver<Maybe<ResolversTypes['UserDegreeField']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fromTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  liveEvents?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  profilePhotoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  research?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  toTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalTimeSpent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersTimeSpentDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersTimeSpentData'] = ResolversParentTypes['UsersTimeSpentData']> = {
  userCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  userIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  userTimeStats?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserTimeSpentStatsField']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyAccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyAccount'] = ResolversParentTypes['VerifyAccount']> = {
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyCodeFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyCodeField'] = ResolversParentTypes['VerifyCodeField']> = {
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoContent'] = ResolversParentTypes['VideoContent']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['VideoStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['VideoTypeEnum'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['VideoUrl'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoFileResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoFile'] = ResolversParentTypes['VideoFile']> = {
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fileIndex?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastModified?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recordingNumber?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  splitNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoSessionFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoSessionField'] = ResolversParentTypes['VideoSessionField']> = {
  coverS3Url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoUploadLinkResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoUploadLink'] = ResolversParentTypes['VideoUploadLink']> = {
  fields?: Resolver<Array<ResolversTypes['VideoUploadPresignedFields']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  presignedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoUploadPresignedFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoUploadPresignedFields'] = ResolversParentTypes['VideoUploadPresignedFields']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoUrlResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoUrl'] = ResolversParentTypes['VideoUrl']> = {
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewBlogMutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ViewBlogMutation'] = ResolversParentTypes['ViewBlogMutation']> = {
  post?: Resolver<Maybe<ResolversTypes['BlogPostField']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirtualEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['VirtualEvent'] = ResolversParentTypes['VirtualEvent']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ObjectId'], ParentType, ContextType>;
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  university?: Resolver<ResolversTypes['UniversityField'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WidgetSnippetFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['WidgetSnippetField'] = ResolversParentTypes['WidgetSnippetField']> = {
  description?: Resolver<Maybe<ResolversTypes['DescriptionField']>, ParentType, ContextType>;
  embeddedEventSnippet?: Resolver<Maybe<ResolversTypes['EmbeddedEventSnippetField']>, ParentType, ContextType>;
  snippet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WidgetUrlFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['WidgetUrlField'] = ResolversParentTypes['WidgetUrlField']> = {
  locale?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WillReplyFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['WillReplyField'] = ResolversParentTypes['WillReplyField']> = {
  created?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dismissed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mobileAdded?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YearOfStudyFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['YearOfStudyField'] = ResolversParentTypes['YearOfStudyField']> = {
  archived?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  degreeLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  university?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ActivateApplicantsMutation?: ActivateApplicantsMutationResolvers<ContextType>;
  ActivateMentorsMutation?: ActivateMentorsMutationResolvers<ContextType>;
  ActivityField?: ActivityFieldResolvers<ContextType>;
  ActivitySourceField?: ActivitySourceFieldResolvers<ContextType>;
  AddAttributeOptionMutation?: AddAttributeOptionMutationResolvers<ContextType>;
  AddBotToChatGroupMutation?: AddBotToChatGroupMutationResolvers<ContextType>;
  AddCustomAttributeMutation?: AddCustomAttributeMutationResolvers<ContextType>;
  AddDegreeLevelsMutation?: AddDegreeLevelsMutationResolvers<ContextType>;
  AddDegreesMutation?: AddDegreesMutationResolvers<ContextType>;
  AddHobsonsConnectIntegration?: AddHobsonsConnectIntegrationResolvers<ContextType>;
  AddLocalesToApp?: AddLocalesToAppResolvers<ContextType>;
  AddResearchTimeMutation?: AddResearchTimeMutationResolvers<ContextType>;
  AddYearOfStudyMutation?: AddYearOfStudyMutationResolvers<ContextType>;
  AdminDeleteApplicantMutation?: AdminDeleteApplicantMutationResolvers<ContextType>;
  AdminRecommendation?: AdminRecommendationResolvers<ContextType>;
  AdminRecommendationCountPerDecisionStage?: AdminRecommendationCountPerDecisionStageResolvers<ContextType>;
  AdminUpdateMentorMutation?: AdminUpdateMentorMutationResolvers<ContextType>;
  AdminUserField?: AdminUserFieldResolvers<ContextType>;
  AgeVerificationInfoField?: AgeVerificationInfoFieldResolvers<ContextType>;
  AlertField?: AlertFieldResolvers<ContextType>;
  AllPostsField?: AllPostsFieldResolvers<ContextType>;
  AmbassadorField?: AmbassadorFieldResolvers<ContextType>;
  AmbassadorProfileInformation?: AmbassadorProfileInformationResolvers<ContextType>;
  AmbassadorRecommendation?: AmbassadorRecommendationResolvers<ContextType>;
  AmbassadorRecommendationResourceType?: AmbassadorRecommendationResourceTypeResolvers<ContextType>;
  Analytics?: AnalyticsResolvers<ContextType>;
  AnalyticsBuddyField?: AnalyticsBuddyFieldResolvers<ContextType>;
  AnalyticsDashboardAuthField?: AnalyticsDashboardAuthFieldResolvers<ContextType>;
  AnalyticsMentorField?: AnalyticsMentorFieldResolvers<ContextType>;
  AnyChatUserField?: AnyChatUserFieldResolvers<ContextType>;
  AnyUserField?: AnyUserFieldResolvers<ContextType>;
  AppLocaleField?: AppLocaleFieldResolvers<ContextType>;
  ApplicantBroadcastMessageMutation?: ApplicantBroadcastMessageMutationResolvers<ContextType>;
  ApplicantCollectionField?: ApplicantCollectionFieldResolvers<ContextType>;
  ApplicantConversationField?: ApplicantConversationFieldResolvers<ContextType>;
  ApplicantCountField?: ApplicantCountFieldResolvers<ContextType>;
  ApplicantField?: ApplicantFieldResolvers<ContextType>;
  ApplicantInboxField?: ApplicantInboxFieldResolvers<ContextType>;
  ApplicantInvite?: ApplicantInviteResolvers<ContextType>;
  ApplicantLabelField?: ApplicantLabelFieldResolvers<ContextType>;
  ApplicantMarketplaceObjectField?: ApplicantMarketplaceObjectFieldResolvers<ContextType>;
  ApplicantPreferencesField?: ApplicantPreferencesFieldResolvers<ContextType>;
  ApplicantSchoolHistory?: ApplicantSchoolHistoryResolvers<ContextType>;
  ApplicantSignupStats?: ApplicantSignupStatsResolvers<ContextType>;
  ApplicantUniversityLinkField?: ApplicantUniversityLinkFieldResolvers<ContextType>;
  ApplicantUserField?: ApplicantUserFieldResolvers<ContextType>;
  ApplicantsAppliedStats?: ApplicantsAppliedStatsResolvers<ContextType>;
  ApplicantsMessagedCount?: ApplicantsMessagedCountResolvers<ContextType>;
  ApplicationField?: ApplicationFieldResolvers<ContextType>;
  ApplicationInsightModel?: ApplicationInsightModelResolvers<ContextType>;
  AppliedUsersCountModel?: AppliedUsersCountModelResolvers<ContextType>;
  AssistantConversationFeedback?: AssistantConversationFeedbackResolvers<ContextType>;
  AssistantConversationHistory?: AssistantConversationHistoryResolvers<ContextType>;
  AssistantConversationLead?: AssistantConversationLeadResolvers<ContextType>;
  AssistantConversationMessage?: AssistantConversationMessageResolvers<ContextType>;
  AssistantMessageFeedback?: AssistantMessageFeedbackResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  AttachmentField?: AttachmentFieldResolvers<ContextType>;
  AttendeeAnalytics?: AttendeeAnalyticsResolvers<ContextType>;
  Attendees?: AttendeesResolvers<ContextType>;
  AuthField?: AuthFieldResolvers<ContextType>;
  AuthorSnippetField?: AuthorSnippetFieldResolvers<ContextType>;
  BaseCustomAttributeField?: BaseCustomAttributeFieldResolvers<ContextType>;
  BlockUserMutation?: BlockUserMutationResolvers<ContextType>;
  BlockUserMutationV2?: BlockUserMutationV2Resolvers<ContextType>;
  BlockerField?: BlockerFieldResolvers<ContextType>;
  BlogCommentField?: BlogCommentFieldResolvers<ContextType>;
  BlogPostField?: BlogPostFieldResolvers<ContextType>;
  BlogTopicField?: BlogTopicFieldResolvers<ContextType>;
  BlogcardSnippetField?: BlogcardSnippetFieldResolvers<ContextType>;
  BotUserField?: BotUserFieldResolvers<ContextType>;
  BuddiesField?: BuddiesFieldResolvers<ContextType>;
  BuddiesFilterOptionsField?: BuddiesFilterOptionsFieldResolvers<ContextType>;
  BuddiesListFilterField?: BuddiesListFilterFieldResolvers<ContextType>;
  BuddyApplicantChatGroupField?: BuddyApplicantChatGroupFieldResolvers<ContextType>;
  BuddyApplicantChatGroupFieldV2?: BuddyApplicantChatGroupFieldV2Resolvers<ContextType>;
  BuddyApplicantChatInterventionBuddyField?: BuddyApplicantChatInterventionBuddyFieldResolvers<ContextType>;
  BuddyField?: BuddyFieldResolvers<ContextType>;
  BuddyInviteField?: BuddyInviteFieldResolvers<ContextType>;
  BuddyInviteListField?: BuddyInviteListFieldResolvers<ContextType>;
  BulkApplicantField?: BulkApplicantFieldResolvers<ContextType>;
  BulkOptOutMarketplaceUniversityMarketingMutation?: BulkOptOutMarketplaceUniversityMarketingMutationResolvers<ContextType>;
  ButtonField?: ButtonFieldResolvers<ContextType>;
  CRMApplicantField?: CrmApplicantFieldResolvers<ContextType>;
  CRMField?: CrmFieldResolvers<ContextType>;
  CRMIntegrationField?: CrmIntegrationFieldResolvers<ContextType>;
  CRMMetaDataField?: CrmMetaDataFieldResolvers<ContextType>;
  CSVUploadAmbassadorFieldsMutation?: CsvUploadAmbassadorFieldsMutationResolvers<ContextType>;
  CSVUploadApplicantsMutation?: CsvUploadApplicantsMutationResolvers<ContextType>;
  CampusManagementConnectIntegrationField?: CampusManagementConnectIntegrationFieldResolvers<ContextType>;
  CanonicalQuestionField?: CanonicalQuestionFieldResolvers<ContextType>;
  CarouselSnippetField?: CarouselSnippetFieldResolvers<ContextType>;
  ChatConversation?: ChatConversationResolvers<ContextType>;
  ChatGroupAndMessages?: ChatGroupAndMessagesResolvers<ContextType>;
  ChatGroupAuxiliaryField?: ChatGroupAuxiliaryFieldResolvers<ContextType>;
  ChatGroupField?: ChatGroupFieldResolvers<ContextType>;
  ChatGroupLabelField?: ChatGroupLabelFieldResolvers<ContextType>;
  ChatInboxField?: ChatInboxFieldResolvers<ContextType>;
  ChatMessage?: ChatMessageResolvers<ContextType>;
  ChatPermission?: ChatPermissionResolvers<ContextType>;
  ChatReport?: ChatReportResolvers<ContextType>;
  ChatRoom?: ChatRoomResolvers<ContextType>;
  ChatRoomAnalytics?: ChatRoomAnalyticsResolvers<ContextType>;
  ChatRoomAttendeeAnalytics?: ChatRoomAttendeeAnalyticsResolvers<ContextType>;
  ChatRoomAttendees?: ChatRoomAttendeesResolvers<ContextType>;
  ChatSummaryField?: ChatSummaryFieldResolvers<ContextType>;
  ChatUserField?: ChatUserFieldResolvers<ContextType>;
  CheckEmailMutation?: CheckEmailMutationResolvers<ContextType>;
  ChildOrganisationField?: ChildOrganisationFieldResolvers<ContextType>;
  ClassificationField?: ClassificationFieldResolvers<ContextType>;
  CloneLiveEventAndChannelsMutation?: CloneLiveEventAndChannelsMutationResolvers<ContextType>;
  Cohort?: CohortResolvers<ContextType>;
  CommentMutation?: CommentMutationResolvers<ContextType>;
  Community?: CommunityResolvers<ContextType>;
  CommunityChatGroup?: CommunityChatGroupResolvers<ContextType>;
  CommunityField?: CommunityFieldResolvers<ContextType>;
  CommunityInviteResponse?: CommunityInviteResponseResolvers<ContextType>;
  CommunityInvites?: CommunityInvitesResolvers<ContextType>;
  CommunityJoinResponse?: CommunityJoinResponseResolvers<ContextType>;
  CompletedEvent?: CompletedEventResolvers<ContextType>;
  CompletedEventsOverview?: CompletedEventsOverviewResolvers<ContextType>;
  ContentBriefField?: ContentBriefFieldResolvers<ContextType>;
  Context?: ContextResolvers<ContextType>;
  ConversationContextChatGroupField?: ConversationContextChatGroupFieldResolvers<ContextType>;
  ConversationContextField?: ConversationContextFieldResolvers<ContextType>;
  ConversationInbox?: ConversationInboxResolvers<ContextType>;
  ConversationTag?: ConversationTagResolvers<ContextType>;
  CookieConsentOutputDTO?: CookieConsentOutputDtoResolvers<ContextType>;
  CountPerEpoch?: CountPerEpochResolvers<ContextType>;
  CountryAnalyticsValue?: CountryAnalyticsValueResolvers<ContextType>;
  CountryField?: CountryFieldResolvers<ContextType>;
  CountrySnippetField?: CountrySnippetFieldResolvers<ContextType>;
  CountryStatField?: CountryStatFieldResolvers<ContextType>;
  CourseSnippetField?: CourseSnippetFieldResolvers<ContextType>;
  CreateAlertMutation?: CreateAlertMutationResolvers<ContextType>;
  CreateApplicantMutation?: CreateApplicantMutationResolvers<ContextType>;
  CreateApplicantMutationV2?: CreateApplicantMutationV2Resolvers<ContextType>;
  CreateBlogTopicMutation?: CreateBlogTopicMutationResolvers<ContextType>;
  CreateBuddyMutation?: CreateBuddyMutationResolvers<ContextType>;
  CreateCanonicalQuestion?: CreateCanonicalQuestionResolvers<ContextType>;
  CreateCommunityApplicantMutation?: CreateCommunityApplicantMutationResolvers<ContextType>;
  CreateCommunityApplicantMutationV2?: CreateCommunityApplicantMutationV2Resolvers<ContextType>;
  CreateCrmServiceUniversityConfigMutation?: CreateCrmServiceUniversityConfigMutationResolvers<ContextType>;
  CreateExternalEventMutation?: CreateExternalEventMutationResolvers<ContextType>;
  CreateFaqAnswer?: CreateFaqAnswerResolvers<ContextType>;
  CreateFresherMutation?: CreateFresherMutationResolvers<ContextType>;
  CreateLiveEventDetailsMutation?: CreateLiveEventDetailsMutationResolvers<ContextType>;
  CreateLiveEventFeedMutation?: CreateLiveEventFeedMutationResolvers<ContextType>;
  CreateLiveEventFeedSessionsMutation?: CreateLiveEventFeedSessionsMutationResolvers<ContextType>;
  CreateLiveEventMutation?: CreateLiveEventMutationResolvers<ContextType>;
  CreateMarketplaceApplicantMutation?: CreateMarketplaceApplicantMutationResolvers<ContextType>;
  CreateMarketplaceMutation?: CreateMarketplaceMutationResolvers<ContextType>;
  CreateMentorMutation?: CreateMentorMutationResolvers<ContextType>;
  CreateOpenDayMutation?: CreateOpenDayMutationResolvers<ContextType>;
  CreateOrganisationBuddyLinkMutation?: CreateOrganisationBuddyLinkMutationResolvers<ContextType>;
  CreateOrganisationMutation?: CreateOrganisationMutationResolvers<ContextType>;
  CreatePostMutation?: CreatePostMutationResolvers<ContextType>;
  CreateSubTopicMutation?: CreateSubTopicMutationResolvers<ContextType>;
  CreateTagMutation?: CreateTagMutationResolvers<ContextType>;
  CreateTopicMutation?: CreateTopicMutationResolvers<ContextType>;
  CreateUniversityBlockReportMutation?: CreateUniversityBlockReportMutationResolvers<ContextType>;
  CreateUniversityMutation?: CreateUniversityMutationResolvers<ContextType>;
  CreateUniversityUserMutation?: CreateUniversityUserMutationResolvers<ContextType>;
  CreateUserMutation?: CreateUserMutationResolvers<ContextType>;
  CreationSource?: CreationSourceResolvers<ContextType>;
  CrmApplicantRecordField?: CrmApplicantRecordFieldResolvers<ContextType>;
  CrmAttributeField?: CrmAttributeFieldResolvers<ContextType>;
  CrmConnectionField?: CrmConnectionFieldResolvers<ContextType>;
  CrmDataField?: CrmDataFieldResolvers<ContextType>;
  CrmFieldSyncStatus?: CrmFieldSyncStatusResolvers<ContextType>;
  CrmServiceUniversityConfig?: CrmServiceUniversityConfigResolvers<ContextType>;
  CrmSyncJobField?: CrmSyncJobFieldResolvers<ContextType>;
  CurrentDecisionStageOutput?: CurrentDecisionStageOutputResolvers<ContextType>;
  CustomAttributeField?: CustomAttributeFieldResolvers<ContextType>;
  CustomAttributeOptionField?: CustomAttributeOptionFieldResolvers<ContextType>;
  CustomAttributeValueField?: CustomAttributeValueFieldResolvers<ContextType>;
  CustomAttributesField?: CustomAttributesFieldResolvers<ContextType>;
  CustomAttributesFilterField?: CustomAttributesFilterFieldResolvers<ContextType>;
  CustomAttributesFilterOptionField?: CustomAttributesFilterOptionFieldResolvers<ContextType>;
  DataFormatConfig?: DataFormatConfigResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DateValueField?: DateValueFieldResolvers<ContextType>;
  DeactivateApplicantsMutation?: DeactivateApplicantsMutationResolvers<ContextType>;
  DeactivateMentorsMutation?: DeactivateMentorsMutationResolvers<ContextType>;
  DefaultQuestionsField?: DefaultQuestionsFieldResolvers<ContextType>;
  DegreeCategoriesAnalyticsValue?: DegreeCategoriesAnalyticsValueResolvers<ContextType>;
  DegreeCategoryField?: DegreeCategoryFieldResolvers<ContextType>;
  DegreeCategoryParentField?: DegreeCategoryParentFieldResolvers<ContextType>;
  DegreeChatUserField?: DegreeChatUserFieldResolvers<ContextType>;
  DegreeField?: DegreeFieldResolvers<ContextType>;
  DegreeLevelField?: DegreeLevelFieldResolvers<ContextType>;
  DegreeSnippetField?: DegreeSnippetFieldResolvers<ContextType>;
  DegreeStatField?: DegreeStatFieldResolvers<ContextType>;
  DegreeTypeOrderField?: DegreeTypeOrderFieldResolvers<ContextType>;
  DeleteApplicantMutation?: DeleteApplicantMutationResolvers<ContextType>;
  DeleteAttributeOptionsMutation?: DeleteAttributeOptionsMutationResolvers<ContextType>;
  DeleteCanonicalQuestion?: DeleteCanonicalQuestionResolvers<ContextType>;
  DeleteCustomAttributesMutation?: DeleteCustomAttributesMutationResolvers<ContextType>;
  DeleteDegreeLevelMutation?: DeleteDegreeLevelMutationResolvers<ContextType>;
  DeleteFaqAnswer?: DeleteFaqAnswerResolvers<ContextType>;
  DeleteKnowledgeBaseEntriesOutput?: DeleteKnowledgeBaseEntriesOutputResolvers<ContextType>;
  DeleteLiveEventFeedMutation?: DeleteLiveEventFeedMutationResolvers<ContextType>;
  DeleteLiveEventMessageMutation?: DeleteLiveEventMessageMutationResolvers<ContextType>;
  DeleteLiveEventMutation?: DeleteLiveEventMutationResolvers<ContextType>;
  DeletePostMutation?: DeletePostMutationResolvers<ContextType>;
  DeleteRecordingResponse?: DeleteRecordingResponseResolvers<ContextType>;
  DeleteResearchTimeMutation?: DeleteResearchTimeMutationResolvers<ContextType>;
  DeleteSubTopicMutation?: DeleteSubTopicMutationResolvers<ContextType>;
  DeleteTopicMutation?: DeleteTopicMutationResolvers<ContextType>;
  DeleteUniversityUserMutation?: DeleteUniversityUserMutationResolvers<ContextType>;
  DeleteUserMutation?: DeleteUserMutationResolvers<ContextType>;
  DeleteYearOfStudyMutation?: DeleteYearOfStudyMutationResolvers<ContextType>;
  DepartmentSnippetField?: DepartmentSnippetFieldResolvers<ContextType>;
  DescriptionField?: DescriptionFieldResolvers<ContextType>;
  DeviceField?: DeviceFieldResolvers<ContextType>;
  DeviceMutation?: DeviceMutationResolvers<ContextType>;
  DeviceToken?: DeviceTokenResolvers<ContextType>;
  DirectMessagingApplicant?: DirectMessagingApplicantResolvers<ContextType>;
  DirectMessagingEnquiryTeam?: DirectMessagingEnquiryTeamResolvers<ContextType>;
  DirectMessagingMentor?: DirectMessagingMentorResolvers<ContextType>;
  DirectMessagingStaffMember?: DirectMessagingStaffMemberResolvers<ContextType>;
  Display?: DisplayResolvers<ContextType>;
  DomesticRegionField?: DomesticRegionFieldResolvers<ContextType>;
  EditAlertMutation?: EditAlertMutationResolvers<ContextType>;
  EditAttributeOptionMutation?: EditAttributeOptionMutationResolvers<ContextType>;
  EditBlogTopicMutation?: EditBlogTopicMutationResolvers<ContextType>;
  EditCustomAttributeMutation?: EditCustomAttributeMutationResolvers<ContextType>;
  EditCustomAttributesMutation?: EditCustomAttributesMutationResolvers<ContextType>;
  EditCustomWelcomeMessageMutation?: EditCustomWelcomeMessageMutationResolvers<ContextType>;
  EditDegreeLevelMutation?: EditDegreeLevelMutationResolvers<ContextType>;
  EditDegreeNameMutation?: EditDegreeNameMutationResolvers<ContextType>;
  EditDegreesMutation?: EditDegreesMutationResolvers<ContextType>;
  EditExternalEventMutation?: EditExternalEventMutationResolvers<ContextType>;
  EditLiveEventDetailsMutation?: EditLiveEventDetailsMutationResolvers<ContextType>;
  EditLiveEventFeedMutation?: EditLiveEventFeedMutationResolvers<ContextType>;
  EditLiveEventMutation?: EditLiveEventMutationResolvers<ContextType>;
  EditLiveEventsLandingPageMutation?: EditLiveEventsLandingPageMutationResolvers<ContextType>;
  EditOpenDayMutation?: EditOpenDayMutationResolvers<ContextType>;
  EditOrganisationMutation?: EditOrganisationMutationResolvers<ContextType>;
  EditPostMutation?: EditPostMutationResolvers<ContextType>;
  EditUniversityMutation?: EditUniversityMutationResolvers<ContextType>;
  EditYearOfStudyMutation?: EditYearOfStudyMutationResolvers<ContextType>;
  EmbeddedEventField?: EmbeddedEventFieldResolvers<ContextType>;
  EmbeddedEventSnippetField?: EmbeddedEventSnippetFieldResolvers<ContextType>;
  EnableCustomWelcomeMessageMutation?: EnableCustomWelcomeMessageMutationResolvers<ContextType>;
  EnableCustomWelcomeMessageStaffMutation?: EnableCustomWelcomeMessageStaffMutationResolvers<ContextType>;
  EnableFeaturesMutation?: EnableFeaturesMutationResolvers<ContextType>;
  EnrolledInsightResponse?: EnrolledInsightResponseResolvers<ContextType>;
  EnterpriseAddOns?: EnterpriseAddOnsResolvers<ContextType>;
  EnterpriseMentorField?: EnterpriseMentorFieldResolvers<ContextType>;
  EntriesField?: EntriesFieldResolvers<ContextType>;
  EventPage?: EventPageResolvers<ContextType>;
  EventPagesDisplayedOn?: EventPagesDisplayedOnResolvers<ContextType>;
  ExportAnalyticsCsvMutation?: ExportAnalyticsCsvMutationResolvers<ContextType>;
  ExportTimeSheetDataField?: ExportTimeSheetDataFieldResolvers<ContextType>;
  ExportedReportField?: ExportedReportFieldResolvers<ContextType>;
  ExternalContent?: ExternalContentResolvers<ContextType>;
  ExternalEventField?: ExternalEventFieldResolvers<ContextType>;
  FaqAnswerField?: FaqAnswerFieldResolvers<ContextType>;
  FeatureField?: FeatureFieldResolvers<ContextType>;
  FeatureRulesDTO?: FeatureRulesDtoResolvers<ContextType>;
  FieldMappingField?: FieldMappingFieldResolvers<ContextType>;
  FlagField?: FlagFieldResolvers<ContextType>;
  FormFieldValidationError?: FormFieldValidationErrorResolvers<ContextType>;
  ForwardApplicantToUniversity?: ForwardApplicantToUniversityResolvers<ContextType>;
  GenerateAnalyticsDashboardTokenMutation?: GenerateAnalyticsDashboardTokenMutationResolvers<ContextType>;
  GenerateLiveEventReport?: GenerateLiveEventReportResolvers<ContextType>;
  GenericScalar?: GraphQLScalarType;
  GetDownloadableURLMutation?: GetDownloadableUrlMutationResolvers<ContextType>;
  GetInvitesForEmailDTO?: GetInvitesForEmailDtoResolvers<ContextType>;
  GetMarketplaceApplicantMutation?: GetMarketplaceApplicantMutationResolvers<ContextType>;
  GetRecordingFailureIdsResponse?: GetRecordingFailureIdsResponseResolvers<ContextType>;
  GetSignedS3PostFieldsMutation?: GetSignedS3PostFieldsMutationResolvers<ContextType>;
  GetSignedS3UrlMutation?: GetSignedS3UrlMutationResolvers<ContextType>;
  Giphy?: GiphyResolvers<ContextType>;
  GraphField?: GraphFieldResolvers<ContextType>;
  GroupField?: GroupFieldResolvers<ContextType>;
  HideMentorInMarketplaceMutation?: HideMentorInMarketplaceMutationResolvers<ContextType>;
  HighSchoolField?: HighSchoolFieldResolvers<ContextType>;
  HighSchoolTypeField?: HighSchoolTypeFieldResolvers<ContextType>;
  HousingObject?: HousingObjectResolvers<ContextType>;
  Image?: ImageResolvers<ContextType>;
  InboxItemInterface?: InboxItemInterfaceResolvers<ContextType>;
  InboxItemLastMessageField?: InboxItemLastMessageFieldResolvers<ContextType>;
  InboxItemUnion?: InboxItemUnionResolvers<ContextType>;
  InboxPromptAnswer?: InboxPromptAnswerResolvers<ContextType>;
  InboxPromptOption?: InboxPromptOptionResolvers<ContextType>;
  InboxPromptQuestion?: InboxPromptQuestionResolvers<ContextType>;
  InboxUniversityField?: InboxUniversityFieldResolvers<ContextType>;
  InitialiseRecordingResponse?: InitialiseRecordingResponseResolvers<ContextType>;
  InputField?: InputFieldResolvers<ContextType>;
  Insights?: InsightsResolvers<ContextType>;
  InsightsField?: InsightsFieldResolvers<ContextType>;
  Interest?: InterestResolvers<ContextType>;
  InterestObject?: InterestObjectResolvers<ContextType>;
  InterventionMessageField?: InterventionMessageFieldResolvers<ContextType>;
  Invite?: InviteResolvers<ContextType>;
  InviteApplicantsMutation?: InviteApplicantsMutationResolvers<ContextType>;
  InviteBuddiesMutation?: InviteBuddiesMutationResolvers<ContextType>;
  InviteInformation?: InviteInformationResolvers<ContextType>;
  InviteLinkDto?: InviteLinkDtoResolvers<ContextType>;
  InviteLinkInfoDto?: InviteLinkInfoDtoResolvers<ContextType>;
  InviteMentorsMutation?: InviteMentorsMutationResolvers<ContextType>;
  IsoDateTime?: GraphQLScalarType;
  JSONString?: GraphQLScalarType;
  JoinChatGroupMutation?: JoinChatGroupMutationResolvers<ContextType>;
  KnowledgeBaseDeleteEntryOutput?: KnowledgeBaseDeleteEntryOutputResolvers<ContextType>;
  KnowledgeBaseEmbedding?: KnowledgeBaseEmbeddingResolvers<ContextType>;
  KnowledgeBaseSourcesByUrl?: KnowledgeBaseSourcesByUrlResolvers<ContextType>;
  LabelSentenceMutation?: LabelSentenceMutationResolvers<ContextType>;
  LabellingField?: LabellingFieldResolvers<ContextType>;
  LabellingSentenceCandidate?: LabellingSentenceCandidateResolvers<ContextType>;
  Labels?: LabelsResolvers<ContextType>;
  LanguageField?: LanguageFieldResolvers<ContextType>;
  LastMessageField?: LastMessageFieldResolvers<ContextType>;
  LastRead?: LastReadResolvers<ContextType>;
  LeaveChatGroupMutation?: LeaveChatGroupMutationResolvers<ContextType>;
  LevelSnippetField?: LevelSnippetFieldResolvers<ContextType>;
  LikeMessageMutation?: LikeMessageMutationResolvers<ContextType>;
  LimitedInt?: GraphQLScalarType;
  LinkOrganisationsMutation?: LinkOrganisationsMutationResolvers<ContextType>;
  ListBuddyApplicantConversationsField?: ListBuddyApplicantConversationsFieldResolvers<ContextType>;
  ListMentorApplicantConversationsField?: ListMentorApplicantConversationsFieldResolvers<ContextType>;
  LiveEventChatGroupField?: LiveEventChatGroupFieldResolvers<ContextType>;
  LiveEventDetailedReport?: LiveEventDetailedReportResolvers<ContextType>;
  LiveEventDetailedReportChannelStats?: LiveEventDetailedReportChannelStatsResolvers<ContextType>;
  LiveEventDetailedReportCountryStats?: LiveEventDetailedReportCountryStatsResolvers<ContextType>;
  LiveEventDetailsField?: LiveEventDetailsFieldResolvers<ContextType>;
  LiveEventDirectMessaging?: LiveEventDirectMessagingResolvers<ContextType>;
  LiveEventFeedbackFormMutation?: LiveEventFeedbackFormMutationResolvers<ContextType>;
  LiveEventField?: LiveEventFieldResolvers<ContextType>;
  LiveEventInboxField?: LiveEventInboxFieldResolvers<ContextType>;
  LiveEventLandingPageField?: LiveEventLandingPageFieldResolvers<ContextType>;
  LiveEventProspectFeedbackFormField?: LiveEventProspectFeedbackFormFieldResolvers<ContextType>;
  LiveEventsFormFieldValidationError?: LiveEventsFormFieldValidationErrorResolvers<ContextType>;
  LiveSession?: LiveSessionResolvers<ContextType>;
  Locale?: LocaleResolvers<ContextType>;
  LocaleField?: LocaleFieldResolvers<ContextType>;
  LogoDataField?: LogoDataFieldResolvers<ContextType>;
  LogoutMutation?: LogoutMutationResolvers<ContextType>;
  ManageOrganisationAccessMutation?: ManageOrganisationAccessMutationResolvers<ContextType>;
  ManuallyClassifyMutation?: ManuallyClassifyMutationResolvers<ContextType>;
  MarketplaceApplicantCountries?: MarketplaceApplicantCountriesResolvers<ContextType>;
  MarketplaceApplicantDegreeCategories?: MarketplaceApplicantDegreeCategoriesResolvers<ContextType>;
  MarketplaceApplicantUserData?: MarketplaceApplicantUserDataResolvers<ContextType>;
  MarketplaceField?: MarketplaceFieldResolvers<ContextType>;
  MarketplaceFilters?: MarketplaceFiltersResolvers<ContextType>;
  MarketplaceInboxField?: MarketplaceInboxFieldResolvers<ContextType>;
  MarketplaceMentorList?: MarketplaceMentorListResolvers<ContextType>;
  MarketplaceSnippetField?: MarketplaceSnippetFieldResolvers<ContextType>;
  MatchedUser?: MatchedUserResolvers<ContextType>;
  MeField?: MeFieldResolvers<ContextType>;
  MentorApplicantChatGroupField?: MentorApplicantChatGroupFieldResolvers<ContextType>;
  MentorBroadcastMessageMutation?: MentorBroadcastMessageMutationResolvers<ContextType>;
  MentorChatUserField?: MentorChatUserFieldResolvers<ContextType>;
  MentorDegreeField?: MentorDegreeFieldResolvers<ContextType>;
  MentorDegreeHistory?: MentorDegreeHistoryResolvers<ContextType>;
  MentorField?: MentorFieldResolvers<ContextType>;
  MentorFieldOrderField?: MentorFieldOrderFieldResolvers<ContextType>;
  MentorFields?: MentorFieldsResolvers<ContextType>;
  MentorFilterField?: MentorFilterFieldResolvers<ContextType>;
  MentorFilterOptionField?: MentorFilterOptionFieldResolvers<ContextType>;
  MentorInboxField?: MentorInboxFieldResolvers<ContextType>;
  MentorInterestField?: MentorInterestFieldResolvers<ContextType>;
  MentorInterventionQueryField?: MentorInterventionQueryFieldResolvers<ContextType>;
  MentorIntroMessageField?: MentorIntroMessageFieldResolvers<ContextType>;
  MentorInviteCountField?: MentorInviteCountFieldResolvers<ContextType>;
  MentorInviteField?: MentorInviteFieldResolvers<ContextType>;
  MentorListField?: MentorListFieldResolvers<ContextType>;
  MentorListFilterChoice?: MentorListFilterChoiceResolvers<ContextType>;
  MentorModuleField?: MentorModuleFieldResolvers<ContextType>;
  MentorPreferencesField?: MentorPreferencesFieldResolvers<ContextType>;
  MentorSchoolHistoryField?: MentorSchoolHistoryFieldResolvers<ContextType>;
  MentorSnippetField?: MentorSnippetFieldResolvers<ContextType>;
  MentorSocietyField?: MentorSocietyFieldResolvers<ContextType>;
  MentorcardSnippetField?: MentorcardSnippetFieldResolvers<ContextType>;
  MessageField?: MessageFieldResolvers<ContextType>;
  MessageMetadata?: MessageMetadataResolvers<ContextType>;
  MessagePublicBotMutation?: MessagePublicBotMutationResolvers<ContextType>;
  MetricDataPoint?: MetricDataPointResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NewsFeedGroup?: NewsFeedGroupResolvers<ContextType>;
  NotificationField?: NotificationFieldResolvers<ContextType>;
  ObjectID?: GraphQLScalarType;
  ObjectId?: GraphQLScalarType;
  OpenDayField?: OpenDayFieldResolvers<ContextType>;
  OpenDaysLoginField?: OpenDaysLoginFieldResolvers<ContextType>;
  OpenDaysSignupField?: OpenDaysSignupFieldResolvers<ContextType>;
  OpenTextFieldValueField?: OpenTextFieldValueFieldResolvers<ContextType>;
  OrganisationBuddyLinkField?: OrganisationBuddyLinkFieldResolvers<ContextType>;
  OrganisationField?: OrganisationFieldResolvers<ContextType>;
  OutboxChatGroupField?: OutboxChatGroupFieldResolvers<ContextType>;
  OutputField?: OutputFieldResolvers<ContextType>;
  PageMentorField?: PageMentorFieldResolvers<ContextType>;
  PaginatedAdminRecommendation?: PaginatedAdminRecommendationResolvers<ContextType>;
  PaginatedApplicantInvite?: PaginatedApplicantInviteResolvers<ContextType>;
  PaginatedApplicationInsightModel?: PaginatedApplicationInsightModelResolvers<ContextType>;
  PaginatedChatMessages?: PaginatedChatMessagesResolvers<ContextType>;
  PaginatedChatRooms?: PaginatedChatRoomsResolvers<ContextType>;
  PaginatedEventPages?: PaginatedEventPagesResolvers<ContextType>;
  PaginatedExternalContents?: PaginatedExternalContentsResolvers<ContextType>;
  PaginatedLiveSessions?: PaginatedLiveSessionsResolvers<ContextType>;
  PaginatedVirtualEvents?: PaginatedVirtualEventsResolvers<ContextType>;
  PasswordResetMobileMutation?: PasswordResetMobileMutationResolvers<ContextType>;
  PasswordResetMobileMutationV2?: PasswordResetMobileMutationV2Resolvers<ContextType>;
  PayloadField?: PayloadFieldResolvers<ContextType>;
  PinMessageMutation?: PinMessageMutationResolvers<ContextType>;
  PinnedMessageField?: PinnedMessageFieldResolvers<ContextType>;
  PinnedMessageSenderField?: PinnedMessageSenderFieldResolvers<ContextType>;
  Poll?: PollResolvers<ContextType>;
  PollOption?: PollOptionResolvers<ContextType>;
  PopCardDegreeSnippetField?: PopCardDegreeSnippetFieldResolvers<ContextType>;
  PopCardLevelSnippetField?: PopCardLevelSnippetFieldResolvers<ContextType>;
  PopCardWidgetField?: PopCardWidgetFieldResolvers<ContextType>;
  PopcardSnippetField?: PopcardSnippetFieldResolvers<ContextType>;
  PostFields?: PostFieldsResolvers<ContextType>;
  PreferencesObjectType?: PreferencesObjectTypeResolvers<ContextType>;
  PreferencesOutputType?: PreferencesOutputTypeResolvers<ContextType>;
  ProspectStageStatField?: ProspectStageStatFieldResolvers<ContextType>;
  ProspectStageSummaryField?: ProspectStageSummaryFieldResolvers<ContextType>;
  PublicMentorField?: PublicMentorFieldResolvers<ContextType>;
  PublicUserField?: PublicUserFieldResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QueryRecordingResponse?: QueryRecordingResponseResolvers<ContextType>;
  QuestionField?: QuestionFieldResolvers<ContextType>;
  RandomMentorsListField?: RandomMentorsListFieldResolvers<ContextType>;
  Reaction?: ReactionResolvers<ContextType>;
  ReadChatMutation?: ReadChatMutationResolvers<ContextType>;
  Recommendation?: RecommendationResolvers<ContextType>;
  RecommendationResourceType?: RecommendationResourceTypeResolvers<ContextType>;
  RecordUserActivityMutation?: RecordUserActivityMutationResolvers<ContextType>;
  RecordingConsent?: RecordingConsentResolvers<ContextType>;
  RecordingDownloadResponse?: RecordingDownloadResponseResolvers<ContextType>;
  RecordingFailureId?: RecordingFailureIdResolvers<ContextType>;
  RecordingFailureResponse?: RecordingFailureResponseResolvers<ContextType>;
  RecordingListResponse?: RecordingListResponseResolvers<ContextType>;
  RecordingModel?: RecordingModelResolvers<ContextType>;
  RecordingResponse?: RecordingResponseResolvers<ContextType>;
  RecordingUploadingResponse?: RecordingUploadingResponseResolvers<ContextType>;
  RegisterToLiveEvent?: RegisterToLiveEventResolvers<ContextType>;
  RemoveBuddyInvitesMutation?: RemoveBuddyInvitesMutationResolvers<ContextType>;
  RemoveDeviceMutation?: RemoveDeviceMutationResolvers<ContextType>;
  RemoveMentorInvitesMutation?: RemoveMentorInvitesMutationResolvers<ContextType>;
  ReplyMessage?: ReplyMessageResolvers<ContextType>;
  ReplyOutputField?: ReplyOutputFieldResolvers<ContextType>;
  ReportAnalytics?: ReportAnalyticsResolvers<ContextType>;
  RequestAnalyticsExportMutation?: RequestAnalyticsExportMutationResolvers<ContextType>;
  RequestPasswordResetMutation?: RequestPasswordResetMutationResolvers<ContextType>;
  RequestPasswordSetMutation?: RequestPasswordSetMutationResolvers<ContextType>;
  RequestedPublicGroup?: RequestedPublicGroupResolvers<ContextType>;
  ResearchTimeLogResult?: ResearchTimeLogResultResolvers<ContextType>;
  ResearchTimeResult?: ResearchTimeResultResolvers<ContextType>;
  ResetPasswordMobileMutation?: ResetPasswordMobileMutationResolvers<ContextType>;
  ResetPasswordMutation?: ResetPasswordMutationResolvers<ContextType>;
  ResetPasswordMutationV2?: ResetPasswordMutationV2Resolvers<ContextType>;
  ResolveUniversityChatsMutation?: ResolveUniversityChatsMutationResolvers<ContextType>;
  RichContentOutputField?: RichContentOutputFieldResolvers<ContextType>;
  RichMessageContent?: RichMessageContentResolvers<ContextType>;
  RunManageCommandMutation?: RunManageCommandMutationResolvers<ContextType>;
  SatisfactionSurveyEntity?: SatisfactionSurveyEntityResolvers<ContextType>;
  SaveAppliedUsersModel?: SaveAppliedUsersModelResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  SendCommunityVerificationCode?: SendCommunityVerificationCodeResolvers<ContextType>;
  SendFeedMessageMutation?: SendFeedMessageMutationResolvers<ContextType>;
  SendLiveEventMessageMutation?: SendLiveEventMessageMutationResolvers<ContextType>;
  SendMessageMutation?: SendMessageMutationResolvers<ContextType>;
  SendOpenDayProspectReport?: SendOpenDayProspectReportResolvers<ContextType>;
  SendResetPasswordEmail?: SendResetPasswordEmailResolvers<ContextType>;
  SendVerificationCode?: SendVerificationCodeResolvers<ContextType>;
  SequenceFilterMentorListField?: SequenceFilterMentorListFieldResolvers<ContextType>;
  SessionAnalytics?: SessionAnalyticsResolvers<ContextType>;
  SessionAttendeeAnalytics?: SessionAttendeeAnalyticsResolvers<ContextType>;
  SessionAttendees?: SessionAttendeesResolvers<ContextType>;
  SetActiveBlogTopicsMutation?: SetActiveBlogTopicsMutationResolvers<ContextType>;
  SetAllNotificationsReadMutation?: SetAllNotificationsReadMutationResolvers<ContextType>;
  SetArchivedContentBriefMutation?: SetArchivedContentBriefMutationResolvers<ContextType>;
  SetArchivedDegreeLevelMutation?: SetArchivedDegreeLevelMutationResolvers<ContextType>;
  SetArchivedYearOfStudyMutation?: SetArchivedYearOfStudyMutationResolvers<ContextType>;
  SetBuddiesListFilters?: SetBuddiesListFiltersResolvers<ContextType>;
  SetChatGroupLabelsMutation?: SetChatGroupLabelsMutationResolvers<ContextType>;
  SetMentorFieldOrderMutation?: SetMentorFieldOrderMutationResolvers<ContextType>;
  SetMentorListFilterSelection?: SetMentorListFilterSelectionResolvers<ContextType>;
  SetOnlineMutation?: SetOnlineMutationResolvers<ContextType>;
  SetResolveChatGroupMutation?: SetResolveChatGroupMutationResolvers<ContextType>;
  SetWillReplyMutation?: SetWillReplyMutationResolvers<ContextType>;
  SettingsObjectType?: SettingsObjectTypeResolvers<ContextType>;
  ShowMentorInMarketplaceMutation?: ShowMentorInMarketplaceMutationResolvers<ContextType>;
  SignUpsCount?: SignUpsCountResolvers<ContextType>;
  SignedS3POSTFields?: SignedS3PostFieldsResolvers<ContextType>;
  SignedS3URLField?: SignedS3UrlFieldResolvers<ContextType>;
  SingleChoiceAttributeField?: SingleChoiceAttributeFieldResolvers<ContextType>;
  SingleChoiceValueField?: SingleChoiceValueFieldResolvers<ContextType>;
  SourceTrackingParamsField?: SourceTrackingParamsFieldResolvers<ContextType>;
  StaffBuddycardSnippetField?: StaffBuddycardSnippetFieldResolvers<ContextType>;
  StaffChatUserField?: StaffChatUserFieldResolvers<ContextType>;
  StaffContactOutput?: StaffContactOutputResolvers<ContextType>;
  StaffField?: StaffFieldResolvers<ContextType>;
  StaffFieldsChatUserField?: StaffFieldsChatUserFieldResolvers<ContextType>;
  StaffPopcardSnippetField?: StaffPopcardSnippetFieldResolvers<ContextType>;
  StaffProfileInformation?: StaffProfileInformationResolvers<ContextType>;
  StaffUserField?: StaffUserFieldResolvers<ContextType>;
  StaffUserFieldsField?: StaffUserFieldsFieldResolvers<ContextType>;
  StaffUserListField?: StaffUserListFieldResolvers<ContextType>;
  StateStatField?: StateStatFieldResolvers<ContextType>;
  SubTopicField?: SubTopicFieldResolvers<ContextType>;
  SubTopicStatField?: SubTopicStatFieldResolvers<ContextType>;
  SubTopicStatisticField?: SubTopicStatisticFieldResolvers<ContextType>;
  SuggestionField?: SuggestionFieldResolvers<ContextType>;
  SwitchUniversitiesField?: SwitchUniversitiesFieldResolvers<ContextType>;
  SwitchUniversityField?: SwitchUniversityFieldResolvers<ContextType>;
  SwitchUniversityListField?: SwitchUniversityListFieldResolvers<ContextType>;
  TagField?: TagFieldResolvers<ContextType>;
  TextInLocale?: TextInLocaleResolvers<ContextType>;
  TimeTakenField?: TimeTakenFieldResolvers<ContextType>;
  ToggleCrmIntegrationSync?: ToggleCrmIntegrationSyncResolvers<ContextType>;
  ToggleCustomAttributeCrmSyncMutation?: ToggleCustomAttributeCrmSyncMutationResolvers<ContextType>;
  ToggleDegreesActiveMutation?: ToggleDegreesActiveMutationResolvers<ContextType>;
  ToggleFeatureAvailabilityMutation?: ToggleFeatureAvailabilityMutationResolvers<ContextType>;
  TopCountryField?: TopCountryFieldResolvers<ContextType>;
  TopDegreeField?: TopDegreeFieldResolvers<ContextType>;
  TopDomesticRegionField?: TopDomesticRegionFieldResolvers<ContextType>;
  TopRegionField?: TopRegionFieldResolvers<ContextType>;
  TopSubTopicField?: TopSubTopicFieldResolvers<ContextType>;
  TopTopicField?: TopTopicFieldResolvers<ContextType>;
  TopicField?: TopicFieldResolvers<ContextType>;
  TopicSnippetField?: TopicSnippetFieldResolvers<ContextType>;
  TopicStatisticField?: TopicStatisticFieldResolvers<ContextType>;
  TopicsStatField?: TopicsStatFieldResolvers<ContextType>;
  TrackLiveEventMutation?: TrackLiveEventMutationResolvers<ContextType>;
  TrackOpenDayMutation?: TrackOpenDayMutationResolvers<ContextType>;
  TrendingTopicsField?: TrendingTopicsFieldResolvers<ContextType>;
  UniversityAssistantConfiguration?: UniversityAssistantConfigurationResolvers<ContextType>;
  UniversityAssistantConfigurationEventOutput?: UniversityAssistantConfigurationEventOutputResolvers<ContextType>;
  UniversityAssistantConfigurationEventUrls?: UniversityAssistantConfigurationEventUrlsResolvers<ContextType>;
  UniversityAssistantConfigurationPromptContextOutput?: UniversityAssistantConfigurationPromptContextOutputResolvers<ContextType>;
  UniversityBlockReportField?: UniversityBlockReportFieldResolvers<ContextType>;
  UniversityBlockUsersMutation?: UniversityBlockUsersMutationResolvers<ContextType>;
  UniversityBlogPostsField?: UniversityBlogPostsFieldResolvers<ContextType>;
  UniversityChatInboxField?: UniversityChatInboxFieldResolvers<ContextType>;
  UniversityConfigField?: UniversityConfigFieldResolvers<ContextType>;
  UniversityContent?: UniversityContentResolvers<ContextType>;
  UniversityFeatureField?: UniversityFeatureFieldResolvers<ContextType>;
  UniversityField?: UniversityFieldResolvers<ContextType>;
  UniversityInboxField?: UniversityInboxFieldResolvers<ContextType>;
  UniversityMarketplaceField?: UniversityMarketplaceFieldResolvers<ContextType>;
  UniversityOutput?: UniversityOutputResolvers<ContextType>;
  UniversityQuestion?: UniversityQuestionResolvers<ContextType>;
  UniversityQuestionAnswer?: UniversityQuestionAnswerResolvers<ContextType>;
  UniversityQuestionContext?: UniversityQuestionContextResolvers<ContextType>;
  UniversityQuestionOption?: UniversityQuestionOptionResolvers<ContextType>;
  UniversityScrapeIndexingOutput?: UniversityScrapeIndexingOutputResolvers<ContextType>;
  UniversityScrapeOutput?: UniversityScrapeOutputResolvers<ContextType>;
  UniversityScrapeStopOutput?: UniversityScrapeStopOutputResolvers<ContextType>;
  UniversityScrapeTask?: UniversityScrapeTaskResolvers<ContextType>;
  UniversitySnippetField?: UniversitySnippetFieldResolvers<ContextType>;
  UniversityUserField?: UniversityUserFieldResolvers<ContextType>;
  UniversityUserPreferencesField?: UniversityUserPreferencesFieldResolvers<ContextType>;
  UnpinMessageMutation?: UnpinMessageMutationResolvers<ContextType>;
  UnreadCountField?: UnreadCountFieldResolvers<ContextType>;
  UnreadMessageCountField?: UnreadMessageCountFieldResolvers<ContextType>;
  UpdateApplicantMutation?: UpdateApplicantMutationResolvers<ContextType>;
  UpdateApplicantPreferencesMutation?: UpdateApplicantPreferencesMutationResolvers<ContextType>;
  UpdateCanonicalQuestion?: UpdateCanonicalQuestionResolvers<ContextType>;
  UpdateCommunityApplicantSignupSourceMutation?: UpdateCommunityApplicantSignupSourceMutationResolvers<ContextType>;
  UpdateDirectMessagingMembersForLiveEventMutation?: UpdateDirectMessagingMembersForLiveEventMutationResolvers<ContextType>;
  UpdateFaqAnswer?: UpdateFaqAnswerResolvers<ContextType>;
  UpdateHobsonsConnectIntegration?: UpdateHobsonsConnectIntegrationResolvers<ContextType>;
  UpdateIntegrationFieldMapping?: UpdateIntegrationFieldMappingResolvers<ContextType>;
  UpdateLiveEventFeedSessionDataMutation?: UpdateLiveEventFeedSessionDataMutationResolvers<ContextType>;
  UpdateManyUserMutation?: UpdateManyUserMutationResolvers<ContextType>;
  UpdateMarketplaceApplicantMutation?: UpdateMarketplaceApplicantMutationResolvers<ContextType>;
  UpdateMarketplaceApplicantUniversityLinkMutation?: UpdateMarketplaceApplicantUniversityLinkMutationResolvers<ContextType>;
  UpdateMarketplaceMutation?: UpdateMarketplaceMutationResolvers<ContextType>;
  UpdateMentorMutation?: UpdateMentorMutationResolvers<ContextType>;
  UpdateMentorPreferences?: UpdateMentorPreferencesResolvers<ContextType>;
  UpdateMyUniversityLinkMutation?: UpdateMyUniversityLinkMutationResolvers<ContextType>;
  UpdatePostStatusMutation?: UpdatePostStatusMutationResolvers<ContextType>;
  UpdateResearchTimeMutation?: UpdateResearchTimeMutationResolvers<ContextType>;
  UpdateStaffMutation?: UpdateStaffMutationResolvers<ContextType>;
  UpdateSubTopicMutation?: UpdateSubTopicMutationResolvers<ContextType>;
  UpdateTagMutation?: UpdateTagMutationResolvers<ContextType>;
  UpdateTopicMutation?: UpdateTopicMutationResolvers<ContextType>;
  UpdateUniversityBlockReportMutation?: UpdateUniversityBlockReportMutationResolvers<ContextType>;
  UpdateUniversityConfigMutation?: UpdateUniversityConfigMutationResolvers<ContextType>;
  UpdateUniversityUserMutation?: UpdateUniversityUserMutationResolvers<ContextType>;
  UpdateUniversityUserPreferencesMutation?: UpdateUniversityUserPreferencesMutationResolvers<ContextType>;
  UpdateUserMutation?: UpdateUserMutationResolvers<ContextType>;
  UpdateUserPreferences?: UpdateUserPreferencesResolvers<ContextType>;
  UpdatedResults?: UpdatedResultsResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UserCustomAttributesFilterField?: UserCustomAttributesFilterFieldResolvers<ContextType>;
  UserDecisionStageOutput?: UserDecisionStageOutputResolvers<ContextType>;
  UserDegreeField?: UserDegreeFieldResolvers<ContextType>;
  UserDirectoryResult?: UserDirectoryResultResolvers<ContextType>;
  UserField?: UserFieldResolvers<ContextType>;
  UserIdsListField?: UserIdsListFieldResolvers<ContextType>;
  UserInterest?: UserInterestResolvers<ContextType>;
  UserListField?: UserListFieldResolvers<ContextType>;
  UserLocalePreference?: UserLocalePreferenceResolvers<ContextType>;
  UserPartialSearchFields?: UserPartialSearchFieldsResolvers<ContextType>;
  UserPermissionsListField?: UserPermissionsListFieldResolvers<ContextType>;
  UserPreferencesField?: UserPreferencesFieldResolvers<ContextType>;
  UserProfile?: UserProfileResolvers<ContextType>;
  UserRecommendation?: UserRecommendationResolvers<ContextType>;
  UserReport?: UserReportResolvers<ContextType>;
  UserTimeSheetExportField?: UserTimeSheetExportFieldResolvers<ContextType>;
  UserTimeSpentBreakdown?: UserTimeSpentBreakdownResolvers<ContextType>;
  UserTimeSpentStatsField?: UserTimeSpentStatsFieldResolvers<ContextType>;
  UsersTimeSpentData?: UsersTimeSpentDataResolvers<ContextType>;
  VerifyAccount?: VerifyAccountResolvers<ContextType>;
  VerifyCodeField?: VerifyCodeFieldResolvers<ContextType>;
  VideoContent?: VideoContentResolvers<ContextType>;
  VideoFile?: VideoFileResolvers<ContextType>;
  VideoSessionField?: VideoSessionFieldResolvers<ContextType>;
  VideoUploadLink?: VideoUploadLinkResolvers<ContextType>;
  VideoUploadPresignedFields?: VideoUploadPresignedFieldsResolvers<ContextType>;
  VideoUrl?: VideoUrlResolvers<ContextType>;
  ViewBlogMutation?: ViewBlogMutationResolvers<ContextType>;
  VirtualEvent?: VirtualEventResolvers<ContextType>;
  WidgetSnippetField?: WidgetSnippetFieldResolvers<ContextType>;
  WidgetUrlField?: WidgetUrlFieldResolvers<ContextType>;
  WillReplyField?: WillReplyFieldResolvers<ContextType>;
  YearOfStudyField?: YearOfStudyFieldResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

export type GetEmailAddressFromInviteIdQueryVariables = Exact<{
  inviteId: Scalars['String'];
}>;


export type GetEmailAddressFromInviteIdQuery = (
  { __typename?: 'Query' }
  & { getInviteFromId: (
    { __typename?: 'Invite' }
    & Pick<Invite, 'valid' | 'id' | 'invitedEmail' | 'communityId'>
  ) }
);

export type GetInviteInformationQueryVariables = Exact<{
  inviteInformationInput: InviteInformationInput;
}>;


export type GetInviteInformationQuery = (
  { __typename?: 'Query' }
  & { getInviteInformation: (
    { __typename?: 'InviteInformation' }
    & Pick<InviteInformation, 'id'>
    & { community: (
      { __typename?: 'Community' }
      & Pick<Community, 'description' | 'title' | 'id'>
    ), university: (
      { __typename?: 'UniversityField' }
      & Pick<UniversityField, 'name' | 'slug' | 'id' | 'squareLogo'>
    ) }
  ) }
);

export type AdminLobbyQueryVariables = Exact<{
  institutionId: Scalars['String'];
}>;


export type AdminLobbyQuery = (
  { __typename?: 'Query' }
  & { listCommunitiesForAnInstitution: Array<(
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'title' | 'communityMemberCount'>
    & { members?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
    )>>, institutions?: Maybe<Array<(
      { __typename?: 'UniversityField' }
      & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl' | 'isFeatureEnabled'>
    )>> }
    & CommunitySettingsFragment
    & NewsFeedGroupsFragment
  )> }
);

export type CommunityLobbyQueryVariables = Exact<{
  userId: Scalars['String'];
  email: Scalars['String'];
}>;


export type CommunityLobbyQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id'>
    & { communities?: Maybe<Array<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'title' | 'communityMemberCount'>
      & { institutions?: Maybe<Array<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl' | 'isFeatureEnabled'>
      )>> }
      & CommunitySettingsFragment
      & NewsFeedGroupsFragment
    )>> }
  )>, getInvitesForEmail: Array<(
    { __typename?: 'GetInvitesForEmailDTO' }
    & Pick<GetInvitesForEmailDto, 'id'>
    & { community: (
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'title' | 'communityMemberCount'>
      & { institutions?: Maybe<Array<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl' | 'isFeatureEnabled'>
      )>> }
      & CommunitySettingsFragment
      & NewsFeedGroupsFragment
    ) }
  )> }
);

export type JoinCommunityMutationVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type JoinCommunityMutation = (
  { __typename?: 'Mutation' }
  & { joinCommunity: (
    { __typename?: 'CommunityJoinResponse' }
    & Pick<CommunityJoinResponse, 'joined'>
    & { community: (
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'title' | 'welcomeText'>
      & { institutions?: Maybe<Array<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'slug' | 'name' | 'logo' | 'squareLogo' | 'colour' | 'privacyPolicyUrl'>
      )>> }
      & CommunitySettingsFragment
      & NewsFeedGroupsFragment
    ) }
  ) }
);

export type JoinCommunityForAdminsMutationVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type JoinCommunityForAdminsMutation = (
  { __typename?: 'Mutation' }
  & { joinCommunityForAdmins: (
    { __typename?: 'CommunityJoinResponse' }
    & Pick<CommunityJoinResponse, 'joined'>
    & { community: (
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'title' | 'welcomeText'>
      & { institutions?: Maybe<Array<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'slug' | 'name' | 'logo' | 'squareLogo' | 'colour' | 'privacyPolicyUrl'>
      )>> }
      & CommunitySettingsFragment
      & NewsFeedGroupsFragment
    ) }
  ) }
);

export type UpdateApplicantSignupSourceMutationVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type UpdateApplicantSignupSourceMutation = (
  { __typename?: 'Mutation' }
  & { updateCommunityApplicantSignupsource?: Maybe<(
    { __typename?: 'UpdateCommunityApplicantSignupSourceMutation' }
    & { me?: Maybe<(
      { __typename?: 'MeField' }
      & { anyUser?: Maybe<(
        { __typename?: 'AnyUserField' }
        & Pick<AnyUserField, 'id'>
      )> }
    )> }
  )> }
);

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'userId' | 'profilePhoto' | 'bio'>
    & { interests?: Maybe<Array<(
      { __typename?: 'InterestObject' }
      & Pick<InterestObject, 'id' | 'type'>
    )>>, areaOfStudy?: Maybe<Array<(
      { __typename?: 'DegreeField' }
      & DegreeFragment
    )>> }
  ) }
);

export type SetCommunityDeviceTokenMutationVariables = Exact<{
  active: Scalars['Boolean'];
  deviceToken: Scalars['String'];
  userId: Scalars['String'];
  system: Scalars['String'];
}>;


export type SetCommunityDeviceTokenMutation = (
  { __typename?: 'Mutation' }
  & { setDeviceToken: (
    { __typename?: 'DeviceToken' }
    & Pick<DeviceToken, 'active'>
  ) }
);

export type MeQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQueryQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'MeField' }
    & { anyUser?: Maybe<(
      { __typename?: 'AnyUserField' }
      & Pick<AnyUserField, 'verified' | 'firstName' | 'lastName' | 'profilePhoto' | 'email' | 'id' | 'accountRole'>
      & { country?: Maybe<(
        { __typename?: 'CountryField' }
        & Pick<CountryField, 'name' | 'id' | 'code'>
      )>, university?: Maybe<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'slug' | 'name'>
      )> }
    )> }
  )> }
);

export type RegisterCommunityApplicantMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country?: Maybe<Scalars['String']>;
}>;


export type RegisterCommunityApplicantMutation = (
  { __typename?: 'Mutation' }
  & { createCommunityApplicantV2?: Maybe<(
    { __typename?: 'CreateCommunityApplicantMutationV2' }
    & Pick<CreateCommunityApplicantMutationV2, 'error'>
    & { applicant?: Maybe<(
      { __typename?: 'ApplicantField' }
      & Pick<ApplicantField, 'id'>
    )> }
  )> }
);

export type RequestCommunityVerificationCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestCommunityVerificationCodeMutation = (
  { __typename?: 'Mutation' }
  & { sendCommunityVerificationCode?: Maybe<(
    { __typename?: 'SendCommunityVerificationCode' }
    & Pick<SendCommunityVerificationCode, 'success'>
  )> }
);

export type VerifyCommunityEmailMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type VerifyCommunityEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyAccount?: Maybe<(
    { __typename?: 'VerifyAccount' }
    & Pick<VerifyAccount, 'success' | 'error'>
  )> }
);

export type CreateUserReportMutationVariables = Exact<{
  userReportUserInputDTO: UserReportUserInputDto;
}>;


export type CreateUserReportMutation = (
  { __typename?: 'Mutation' }
  & { createUserReport: (
    { __typename?: 'UserReport' }
    & Pick<UserReport, 'id'>
  ) }
);

export type ToggleMuteConversationMutationVariables = Exact<{
  conversationId: Scalars['String'];
  status: Scalars['Boolean'];
}>;


export type ToggleMuteConversationMutation = (
  { __typename?: 'Mutation' }
  & { toggleCommunityChatGroupMute: (
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id'>
    & CommunityFieldsFragment
    & GroupTypesFragment
    & AllMembersFragment
  ) }
);

export type AddUserToGroupMutationVariables = Exact<{
  memberId: Scalars['String'];
  conversationId: Scalars['String'];
}>;


export type AddUserToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUserToCommunityConversation: (
    { __typename?: 'ChatConversation' }
    & { allMembers?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
    )>> }
    & InboxConversationFragment
  ) }
);

export type GetCommunityConversationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCommunityConversationQuery = (
  { __typename?: 'Query' }
  & { getChatConversation: (
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id'>
    & CommunityFieldsFragment
    & GroupTypesFragment
  ) }
);

export type CommunityFieldsFragment = (
  { __typename?: 'ChatConversation' }
  & Pick<ChatConversation, 'communityChatTitle' | 'communityChatDescription' | 'communityId' | 'communityChatDynamicLink' | 'mutedBy' | 'totalMemberCount'>
  & { communityChatMembers?: Maybe<Array<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'profilePhoto' | 'firstName' | 'accountRole'>
    & { communityProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'profilePhoto'>
    )> }
  )>> }
);

export type AllMembersFragment = (
  { __typename?: 'ChatConversation' }
  & { allMembers?: Maybe<Array<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id'>
  )>> }
);

export type GroupTypesFragment = (
  { __typename?: 'ChatConversation' }
  & Pick<ChatConversation, 'isPrivate' | 'isNewsFeed' | 'isDirect'>
);

export type GetFullConversationMembersListQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFullConversationMembersListQuery = (
  { __typename?: 'Query' }
  & { getChatConversation: (
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id' | 'totalMemberCount'>
  ) }
);

export type GetCommunityChatMembersQueryVariables = Exact<{
  conversationId: Scalars['String'];
  offsetPagination: OffsetPagination;
  idIn?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetCommunityChatMembersQuery = (
  { __typename?: 'Query' }
  & { getCommunityChatMembers: Array<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'lastName' | 'accountRole' | 'profilePhoto'>
    & CountryFragment
    & CommunityProfileFragment
  )>, getUserByIds?: Maybe<Array<Maybe<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'lastName' | 'accountRole'>
    & CountryFragment
    & CommunityProfileFragment
  )>>> }
);

export type GetUserByUserIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserByUserIdQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'accountRole' | 'profilePhoto'>
    & { communityProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'profilePhoto' | 'bio' | 'pronouns'>
      & { areaOfStudy?: Maybe<Array<(
        { __typename?: 'DegreeField' }
        & Pick<DegreeField, 'name'>
      )>> }
      & HousingFragment
    )> }
    & CountryFragment
    & InterestsFragment
  )> }
);

export type CountryFragment = (
  { __typename?: 'UserField' }
  & { country?: Maybe<(
    { __typename?: 'CountryField' }
    & Pick<CountryField, 'id' | 'name' | 'code'>
  )> }
);

export type CommunityProfileFragment = (
  { __typename?: 'UserField' }
  & { communityProfile?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'profilePhoto' | 'bio'>
    & { areaOfStudy?: Maybe<Array<(
      { __typename?: 'DegreeField' }
      & Pick<DegreeField, 'name'>
    )>>, interests?: Maybe<Array<(
      { __typename?: 'InterestObject' }
      & Pick<InterestObject, 'type'>
    )>> }
  )> }
);

export type InterestsFragment = (
  { __typename?: 'UserField' }
  & { interests: Array<(
    { __typename?: 'Interest' }
    & Pick<Interest, 'id' | 'name'>
  )> }
);

export type HousingFragment = (
  { __typename?: 'UserProfile' }
  & { housing?: Maybe<(
    { __typename?: 'HousingObject' }
    & Pick<HousingObject, 'category' | 'name'>
  )> }
);

export type CommunityInboxPrivateQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type CommunityInboxPrivateQuery = (
  { __typename?: 'Query' }
  & { getUsersCommunityPrivateConversations: Array<(
    { __typename?: 'ChatConversation' }
    & { allMembers?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
    )>> }
    & InboxConversationFragment
  )> }
);

export type AddVoteMutationVariables = Exact<{
  option: Scalars['String'];
  chatMessageId: Scalars['ObjectId'];
}>;


export type AddVoteMutation = (
  { __typename?: 'Mutation' }
  & { addVote: (
    { __typename?: 'Poll' }
    & CommunityPollRichContentFragment
  ) }
);

export type RemoveVoteMutationVariables = Exact<{
  option: Scalars['String'];
  chatMessageId: Scalars['ObjectId'];
}>;


export type RemoveVoteMutation = (
  { __typename?: 'Mutation' }
  & { removeVote: (
    { __typename?: 'Poll' }
    & CommunityPollRichContentFragment
  ) }
);

export type AddReactionToMessageMutationVariables = Exact<{
  reactionDto: ReactionDto;
}>;


export type AddReactionToMessageMutation = (
  { __typename?: 'Mutation' }
  & { addReactionToMessage: (
    { __typename?: 'ChatMessage' }
    & FullCommunityChatMessageFragment
  ) }
);

export type RemoveReactionFromMessageMutationVariables = Exact<{
  reactionDto: ReactionDto;
}>;


export type RemoveReactionFromMessageMutation = (
  { __typename?: 'Mutation' }
  & { removeReactionFromMessage: (
    { __typename?: 'ChatMessage' }
    & FullCommunityChatMessageFragment
  ) }
);

export type BlockCommunityUserMutationVariables = Exact<{
  userId: Scalars['String'];
  conversationId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type BlockCommunityUserMutation = (
  { __typename?: 'Mutation' }
  & { createUniversityBlockReport?: Maybe<(
    { __typename?: 'CreateUniversityBlockReportMutation' }
    & Pick<CreateUniversityBlockReportMutation, 'error'>
    & { universityBlockReport?: Maybe<(
      { __typename?: 'UniversityBlockReportField' }
      & Pick<UniversityBlockReportField, 'id'>
    )> }
  )> }
);

export type SetBlockUserV2MutationVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  setBlocked?: Maybe<Scalars['Boolean']>;
}>;


export type SetBlockUserV2Mutation = (
  { __typename?: 'Mutation' }
  & { setBlockUserV2?: Maybe<(
    { __typename?: 'BlockUserMutationV2' }
    & Pick<BlockUserMutationV2, 'objectsBlockedIds'>
  )> }
);

export type BlockedUsersListQueryVariables = Exact<{ [key: string]: never; }>;


export type BlockedUsersListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'blockedUsersByMe'>
);

export type CreateDirectConversationMutationVariables = Exact<{
  communityId: Scalars['String'];
  userToConverseWith: Scalars['String'];
  source?: Maybe<Scalars['String']>;
}>;


export type CreateDirectConversationMutation = (
  { __typename?: 'Mutation' }
  & { createDirectConversation: (
    { __typename?: 'ChatConversation' }
    & InboxConversationFragment
  ) }
);

export type DeleteConversationMessageMutationVariables = Exact<{
  deleteMessageDto: DeleteMessageDto;
}>;


export type DeleteConversationMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteConversationMessage: (
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id' | 'created' | 'text' | 'deleted'>
  ) }
);

export type CommunityLikeMessageMutationVariables = Exact<{
  conversationId: Scalars['ObjectId'];
  messageId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
}>;


export type CommunityLikeMessageMutation = (
  { __typename?: 'Mutation' }
  & { likeConversationMessage: (
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id' | 'likesCount' | 'likes'>
  ) }
);

export type CommunityChatMessagesQueryVariables = Exact<{
  getMessageDto: GetMessageDto;
}>;


export type CommunityChatMessagesQuery = (
  { __typename?: 'Query' }
  & { getChatConversationMessages: (
    { __typename?: 'PaginatedChatMessages' }
    & Pick<PaginatedChatMessages, 'hasMore'>
    & { messages: Array<(
      { __typename?: 'ChatMessage' }
      & FullCommunityChatMessageFragment
    )> }
  ) }
);

export type FullCommunityChatMessageFragment = (
  { __typename?: 'ChatMessage' }
  & { richContent?: Maybe<(
    { __typename?: 'RichMessageContent' }
    & { giphy?: Maybe<(
      { __typename?: 'Giphy' }
      & CommunityGiphyRichContentFragment
    )>, reply?: Maybe<(
      { __typename?: 'ChatMessage' }
      & { richContent?: Maybe<(
        { __typename?: 'RichMessageContent' }
        & { giphy?: Maybe<(
          { __typename?: 'Giphy' }
          & CommunityGiphyRichContentFragment
        )>, images?: Maybe<Array<(
          { __typename?: 'Image' }
          & CommunityImagesRichContentFragment
        )>>, attachments?: Maybe<Array<(
          { __typename?: 'Attachment' }
          & CommunityAttachmentRichContentFragment
        )>> }
      )> }
      & CommunityReplyRichContentFragment
    )>, images?: Maybe<Array<(
      { __typename?: 'Image' }
      & CommunityImagesRichContentFragment
    )>>, attachments?: Maybe<Array<(
      { __typename?: 'Attachment' }
      & CommunityAttachmentRichContentFragment
    )>>, poll?: Maybe<(
      { __typename?: 'Poll' }
      & CommunityPollRichContentFragment
    )> }
  )> }
  & CommunityChatMessageFragment
);

export type CommunityChatMessageFragment = (
  { __typename?: 'ChatMessage' }
  & Pick<ChatMessage, 'id' | 'text' | 'deleted' | 'created' | 'likes' | 'likesCount'>
  & { sender: (
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'profilePhoto' | 'accountRole'>
    & { country?: Maybe<(
      { __typename?: 'CountryField' }
      & Pick<CountryField, 'id' | 'name' | 'code'>
    )>, communityProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'profilePhoto'>
    )> }
  ) }
  & ReactionsFragment
);

export type CommunityReplyRichContentFragment = (
  { __typename?: 'ChatMessage' }
  & Pick<ChatMessage, 'text' | 'deleted' | 'created' | 'likes' | 'likesCount'>
  & { sender: (
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'profilePhoto' | 'accountRole'>
    & { communityProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'profilePhoto'>
    )> }
  ) }
);

export type CommunityGiphyRichContentFragment = (
  { __typename?: 'Giphy' }
  & Pick<Giphy, 'id' | 'type' | 'width' | 'height' | 'aspectRatio'>
);

export type CommunityImagesRichContentFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'url'>
);

export type ReactionsFragment = (
  { __typename?: 'ChatMessage' }
  & { reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & Pick<Reaction, 'reaction' | 'reactionUnicode' | 'userIds'>
  )>> }
);

export type CommunityAttachmentRichContentFragment = (
  { __typename?: 'Attachment' }
  & Pick<Attachment, 'link' | 'type' | 'size' | 'fileName'>
);

export type CommunityPollRichContentFragment = (
  { __typename?: 'Poll' }
  & Pick<Poll, 'allowMultipleAnswers' | 'question'>
  & { options: Array<(
    { __typename?: 'PollOption' }
    & Pick<PollOption, 'option' | 'votes'>
  )> }
);

export type CommunityPinMessageMutationVariables = Exact<{
  pinMessageDTO: PinMessageDto;
}>;


export type CommunityPinMessageMutation = (
  { __typename?: 'Mutation' }
  & { pinMessageInConversation: (
    { __typename?: 'ChatConversation' }
    & { pinnedMessages: Array<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id'>
    )> }
  ) }
);

export type CommunityPinnedMessagesQueryVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type CommunityPinnedMessagesQuery = (
  { __typename?: 'Query' }
  & { getChatConversation: (
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id' | 'communityChatTitle'>
    & { pinnedMessages: Array<(
      { __typename?: 'ChatMessage' }
      & FullCommunityChatMessageFragment
    )> }
  ) }
);

export type CommunityPinnedMessageIdFragment = (
  { __typename?: 'ChatConversation' }
  & { pinnedMessages: Array<(
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id'>
  )> }
);

export type CommunityConversationReadMutationVariables = Exact<{
  conversationId: Scalars['ID'];
  messageId: Scalars['ObjectId'];
}>;


export type CommunityConversationReadMutation = (
  { __typename?: 'Mutation' }
  & { recordLastMessageReadByUser: (
    { __typename?: 'LastRead' }
    & Pick<LastRead, 'userId' | 'messageId' | 'conversationId'>
  ) }
);

export type CreateChatReportMutationVariables = Exact<{
  chatReportUserInputDTO: ChatReportUserInputDto;
}>;


export type CreateChatReportMutation = (
  { __typename?: 'Mutation' }
  & { createChatReport: (
    { __typename?: 'ChatReport' }
    & Pick<ChatReport, 'id'>
  ) }
);

export type SendCommunityChatMessageMutationVariables = Exact<{
  messageDto: MessageDto;
}>;


export type SendCommunityChatMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendConversationMessage: (
    { __typename?: 'ChatMessage' }
    & { richContent?: Maybe<(
      { __typename?: 'RichMessageContent' }
      & { giphy?: Maybe<(
        { __typename?: 'Giphy' }
        & CommunityGiphyRichContentFragment
      )>, reply?: Maybe<(
        { __typename?: 'ChatMessage' }
        & { richContent?: Maybe<(
          { __typename?: 'RichMessageContent' }
          & { giphy?: Maybe<(
            { __typename?: 'Giphy' }
            & CommunityGiphyRichContentFragment
          )>, images?: Maybe<Array<(
            { __typename?: 'Image' }
            & CommunityImagesRichContentFragment
          )>> }
        )> }
        & CommunityReplyRichContentFragment
      )>, images?: Maybe<Array<(
        { __typename?: 'Image' }
        & CommunityImagesRichContentFragment
      )>>, attachments?: Maybe<Array<(
        { __typename?: 'Attachment' }
        & CommunityAttachmentRichContentFragment
      )>>, poll?: Maybe<(
        { __typename?: 'Poll' }
        & CommunityPollRichContentFragment
      )> }
    )> }
    & CommunityChatMessageFragment
  ) }
);

export type CommunityUnlikeMessageMutationVariables = Exact<{
  conversationId: Scalars['ObjectId'];
  messageId: Scalars['ObjectId'];
  userId: Scalars['ObjectId'];
}>;


export type CommunityUnlikeMessageMutation = (
  { __typename?: 'Mutation' }
  & { unlikeConversationMessage: (
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id' | 'likesCount' | 'likes'>
  ) }
);

export type CommunityUnpinMessageMutationVariables = Exact<{
  unpinMessageDTO: UnpinMessageDto;
}>;


export type CommunityUnpinMessageMutation = (
  { __typename?: 'Mutation' }
  & { unpinMessageInConversation: (
    { __typename?: 'ChatConversation' }
    & { pinnedMessages: Array<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id'>
    )> }
  ) }
);

export type GetCommunitiesQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type GetCommunitiesQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id'>
    & { communities?: Maybe<Array<(
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'title'>
      & { members?: Maybe<Array<(
        { __typename?: 'UserField' }
        & Pick<UserField, 'id'>
      )>>, institutions?: Maybe<Array<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl' | 'isFeatureEnabled'>
      )>> }
      & CommunitySettingsFragment
      & NewsFeedGroupsFragment
    )>> }
  )> }
);

export type NewsFeedGroupsFragment = (
  { __typename?: 'Community' }
  & { newsFeedGroups?: Maybe<Array<(
    { __typename?: 'NewsFeedGroup' }
    & Pick<NewsFeedGroup, 'conversationId'>
  )>> }
);

export type CommunitySettingsFragment = (
  { __typename?: 'Community' }
  & { settings?: Maybe<(
    { __typename?: 'SettingsObjectType' }
    & Pick<SettingsObjectType, 'allowGifs' | 'allowPrivateGroups' | 'allowImages' | 'allowDirectChat' | 'allowNewsFeed'>
  )> }
);

export type GetUniversityFeaturesQueryQueryVariables = Exact<{
  universitySlug?: Maybe<Scalars['String']>;
}>;


export type GetUniversityFeaturesQueryQuery = (
  { __typename?: 'Query' }
  & { university?: Maybe<(
    { __typename?: 'UniversityField' }
    & Pick<UniversityField, 'id'>
    & { config?: Maybe<(
      { __typename?: 'UniversityConfigField' }
      & { features?: Maybe<Array<Maybe<(
        { __typename?: 'UniversityFeatureField' }
        & Pick<UniversityFeatureField, 'enabledByUnibuddy' | 'enabledByUniversity' | 'universityCanToggleFeature'>
        & { feature?: Maybe<(
          { __typename?: 'FeatureField' }
          & Pick<FeatureField, 'name'>
        )> }
      )>>> }
    )> }
  )> }
);

export type BlogPostsQueryQueryVariables = Exact<{
  topics?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  universityId?: Maybe<Scalars['String']>;
  universitySlug?: Maybe<Scalars['String']>;
  degrees?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  authorId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['LimitedInt']>;
  offset?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  randomise?: Maybe<Scalars['Boolean']>;
}>;


export type BlogPostsQueryQuery = (
  { __typename?: 'Query' }
  & { allPostsInfo?: Maybe<(
    { __typename?: 'AllPostsField' }
    & { blogPosts?: Maybe<Array<Maybe<(
      { __typename?: 'BlogPostField' }
      & BlogPostPreviewFragmentFragment
    )>>> }
  )> }
);

export type BlogPostPreviewFragmentFragment = (
  { __typename?: 'BlogPostField' }
  & Pick<BlogPostField, 'id' | 'body' | 'title' | 'coverImages' | 'youtubeVideo'>
  & { topic?: Maybe<(
    { __typename?: 'BlogTopicField' }
    & Pick<BlogTopicField, 'name'>
  )>, videoContent?: Maybe<(
    { __typename?: 'VideoContent' }
    & Pick<VideoContent, 'id' | 'status' | 'type'>
    & { url: (
      { __typename?: 'VideoUrl' }
      & Pick<VideoUrl, 'url'>
    ) }
  )>, author?: Maybe<(
    { __typename?: 'MentorField' }
    & Pick<MentorField, 'id' | 'firstName' | 'lastName' | 'profilePhoto'>
    & { degree?: Maybe<(
      { __typename?: 'DegreeField' }
      & Pick<DegreeField, 'id' | 'name'>
    )>, country?: Maybe<(
      { __typename?: 'CountryField' }
      & Pick<CountryField, 'id' | 'code' | 'name'>
    )> }
  )> }
);

export type CommunityCreatePrivateGroupMutationVariables = Exact<{
  createCommunityConversationDTO: CreateCommunityConversationDto;
}>;


export type CommunityCreatePrivateGroupMutation = (
  { __typename?: 'Mutation' }
  & { createCommunityConversation: (
    { __typename?: 'ChatConversation' }
    & InboxConversationFragment
  ) }
);

export type LeaveCommunityChatGroupMutationVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type LeaveCommunityChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { leaveCommunityChatGroup: (
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id'>
  ) }
);

export type CommunityInboxQueryVariables = Exact<{
  communityId: Scalars['String'];
  offsetPagination?: Maybe<OffsetPagination>;
}>;


export type CommunityInboxQuery = (
  { __typename?: 'Query' }
  & { getUsersCommunityConversations: Array<(
    { __typename?: 'ChatConversation' }
    & InboxConversationFragment
  )> }
);

export type InboxConversationFragment = (
  { __typename?: 'ChatConversation' }
  & Pick<ChatConversation, 'id' | 'communityChatTitle' | 'communityChatDescription' | 'communityChatDynamicLink' | 'unreadMessageCount' | 'mutedBy'>
  & { lastMessage?: Maybe<(
    { __typename?: 'ChatMessage' }
    & Pick<ChatMessage, 'id' | 'created' | 'text'>
    & { sender: (
      { __typename?: 'UserField' }
      & Pick<UserField, 'id' | 'firstName'>
    ) }
  )>, communityChatMembers?: Maybe<Array<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'accountRole' | 'profilePhoto'>
    & { communityProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'profilePhoto'>
    )> }
  )>> }
  & GroupTypesFragment
);

export type GetInviteLinkInfoQueryVariables = Exact<{
  inviteLinkId: Scalars['String'];
}>;


export type GetInviteLinkInfoQuery = (
  { __typename?: 'Query' }
  & { getInviteLinkInfo: (
    { __typename?: 'InviteLinkInfoDto' }
    & Pick<InviteLinkInfoDto, 'id' | 'valid' | 'communityId' | 'communityTitle' | 'universityName' | 'squareLogo' | 'universitySlug' | 'privacyPolicyUrl'>
  ) }
);

export type JoinCommunityByInviteLinkMutationVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type JoinCommunityByInviteLinkMutation = (
  { __typename?: 'Mutation' }
  & { joinCommunityByInviteLink: (
    { __typename?: 'CommunityJoinResponse' }
    & Pick<CommunityJoinResponse, 'alreadyJoined' | 'joined'>
    & { community: (
      { __typename?: 'Community' }
      & Pick<Community, 'id' | 'title'>
      & { members?: Maybe<Array<(
        { __typename?: 'UserField' }
        & Pick<UserField, 'id'>
      )>>, institutions?: Maybe<Array<(
        { __typename?: 'UniversityField' }
        & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl'>
      )>> }
      & CommunitySettingsFragment
      & NewsFeedGroupsFragment
    ) }
  ) }
);

export type GetJoinGroupDataQueryVariables = Exact<{
  communityId: Scalars['String'];
  conversationId: Scalars['String'];
}>;


export type GetJoinGroupDataQuery = (
  { __typename?: 'Query' }
  & { getCommunityForUser: (
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'title' | 'description'>
    & { institutions?: Maybe<Array<(
      { __typename?: 'UniversityField' }
      & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl'>
    )>> }
    & CommunitySettingsFragment
    & NewsFeedGroupsFragment
  ), getCommunityConversation: (
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id' | 'communityChatTitle' | 'communityChatDescription' | 'communityChatDynamicLink'>
    & GroupTypesFragment
  ) }
);

export type GetAreaOfStudyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAreaOfStudyQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'UserProfile' }
    & { areaOfStudy?: Maybe<Array<(
      { __typename?: 'DegreeField' }
      & DegreeFragment
    )>> }
  ) }
);

export type GetCommunityUserSearchQueryVariables = Exact<{
  pagination: OffsetPagination;
  filterUsers: FilterUsersDto;
}>;


export type GetCommunityUserSearchQuery = (
  { __typename?: 'Query' }
  & { communityUserSearch: (
    { __typename?: 'UserDirectoryResult' }
    & Pick<UserDirectoryResult, 'totalUsers'>
    & { result: Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id' | 'firstName' | 'lastName' | 'accountRole' | 'profilePhoto'>
      & CountryFragment
      & CommunityProfileFragment
    )> }
  ) }
);

export type GetRecommendedUsersQueryVariables = Exact<{
  communityId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  matchingCriterion?: Maybe<MatchingCriteriaEnum>;
}>;


export type GetRecommendedUsersQuery = (
  { __typename?: 'Query' }
  & { getRecommendedUsers: (
    { __typename?: 'UserRecommendation' }
    & RecommendedUsersFragment
  ) }
);

export type RecommendedUsersFragment = (
  { __typename?: 'UserRecommendation' }
  & Pick<UserRecommendation, 'totalMatches' | 'type'>
  & { currentUser: (
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'accountRole' | 'firstName' | 'lastName'>
    & { university?: Maybe<(
      { __typename?: 'UniversityField' }
      & Pick<UniversityField, 'name'>
    )>, communityProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'profilePhoto'>
    )> }
  ), matches: Array<(
    { __typename?: 'MatchedUser' }
    & Pick<MatchedUser, 'matchingCriteria' | 'matchingPercentage'>
    & { user: (
      { __typename?: 'UserField' }
      & Pick<UserField, 'id' | 'firstName' | 'profilePhoto' | 'accountRole' | 'lastName'>
      & CountryFragment
      & CommunityProfileFragment
    ) }
  )> }
);

export type GetRecommendedUsersByNationalityCriterionQueryVariables = Exact<{
  communityId: Scalars['String'];
  limit: Scalars['Int'];
  nationalityCriterion: MatchingCriteriaEnum;
}>;


export type GetRecommendedUsersByNationalityCriterionQuery = (
  { __typename?: 'Query' }
  & { nationalityRecommendedUsers: (
    { __typename?: 'UserRecommendation' }
    & RecommendedUsersFragment
  ) }
);

export type GetRecommendedUsersByCriterionQueryVariables = Exact<{
  communityId: Scalars['String'];
  limit: Scalars['Int'];
  matchingCriterion?: Maybe<MatchingCriteriaEnum>;
}>;


export type GetRecommendedUsersByCriterionQuery = (
  { __typename?: 'Query' }
  & { recommendedUsers: (
    { __typename?: 'UserRecommendation' }
    & RecommendedUsersFragment
  ) }
);

export type GetRecommendedGroupsQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type GetRecommendedGroupsQuery = (
  { __typename?: 'Query' }
  & { getRecommendedConversations: Array<(
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'id'>
    & { title: ChatConversation['communityChatTitle'], description: ChatConversation['communityChatDescription'] }
    & { members?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
    )>>, membersWithProfilePhoto?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
      & { communityProfile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'profilePhoto'>
      )> }
    )>> }
  )> }
);

export type GetTopicsAndUserInterestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTopicsAndUserInterestsQuery = (
  { __typename?: 'Query' }
  & { topics?: Maybe<Array<Maybe<(
    { __typename?: 'TopicField' }
    & Pick<TopicField, 'id' | 'name' | 'description'>
  )>>>, getUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'userId'>
    & { interests?: Maybe<Array<(
      { __typename?: 'InterestObject' }
      & Pick<InterestObject, 'id' | 'type'>
    )>> }
  ) }
);

export type SetUserProfileMutationVariables = Exact<{
  data: UpdateUserProfile;
}>;


export type SetUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { setUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'userId' | 'profilePhoto' | 'bio' | 'pronouns'>
    & { interests?: Maybe<Array<(
      { __typename?: 'InterestObject' }
      & Pick<InterestObject, 'id' | 'type'>
    )>>, areaOfStudy?: Maybe<Array<(
      { __typename?: 'DegreeField' }
      & Pick<DegreeField, 'id' | 'name'>
    )>> }
    & HousingFragment
  ) }
);

export type AddToFeatureExperimentMutationVariables = Exact<{
  metadata: Scalars['String'];
  experimentName: Scalars['String'];
}>;


export type AddToFeatureExperimentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addToFeatureExperiment'>
);

export type RequestPublicGroupMutationVariables = Exact<{
  requestPublicGroupInput: CreateRequestedPublicGroupInput;
}>;


export type RequestPublicGroupMutation = (
  { __typename?: 'Mutation' }
  & { requestPublicGroup: (
    { __typename?: 'RequestedPublicGroup' }
    & Pick<RequestedPublicGroup, 'id'>
  ) }
);

export type AllCommunityChatGroupsQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type AllCommunityChatGroupsQuery = (
  { __typename?: 'Query' }
  & { getChatGroupsForCommunity: Array<(
    { __typename?: 'CommunityChatGroup' }
    & Pick<CommunityChatGroup, 'id' | 'title' | 'description'>
    & { conversation: (
      { __typename?: 'ChatConversation' }
      & Pick<ChatConversation, 'id'>
    ), members?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id' | 'profilePhoto'>
    )>> }
  )> }
);

export type GetCommunityConversationsGroupsQueryVariables = Exact<{
  communityId: Scalars['String'];
  offsetPagination: OffsetPagination;
}>;


export type GetCommunityConversationsGroupsQuery = (
  { __typename?: 'Query' }
  & { getCommunityConversations: Array<(
    { __typename?: 'ChatConversation' }
    & Pick<ChatConversation, 'totalMemberCount' | 'id' | 'deleted' | 'communityId' | 'communityChatTitle' | 'communityChatDescription'>
    & { communityChatMembers?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
      & { communityProfile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'profilePhoto'>
      )> }
    )>>, labels?: Maybe<Array<(
      { __typename?: 'Labels' }
      & Pick<Labels, 'id' | 'name'>
    )>> }
  )> }
);

export type JoinCommunityConversationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type JoinCommunityConversationMutation = (
  { __typename?: 'Mutation' }
  & { joinCommunityConversation: (
    { __typename?: 'ChatConversation' }
    & InboxConversationFragment
  ) }
);

export type GetAllDegreesQueryVariables = Exact<{
  universitySlug?: Maybe<Scalars['String']>;
}>;


export type GetAllDegreesQuery = (
  { __typename?: 'Query' }
  & { allDegrees?: Maybe<Array<Maybe<(
    { __typename?: 'DegreeField' }
    & DegreeFragment
  )>>>, getUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'>
    & { areaOfStudy?: Maybe<Array<(
      { __typename?: 'DegreeField' }
      & DegreeFragment
    )>> }
  ) }
);

export type DegreeFragment = (
  { __typename?: 'DegreeField' }
  & Pick<DegreeField, 'id' | 'name' | 'degreeType' | 'active'>
);

export type GetExperimentalTodosQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExperimentalTodosQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getExperimentalTodos'>
);

export type GetCommunityForUserQueryVariables = Exact<{
  communityId: Scalars['String'];
}>;


export type GetCommunityForUserQuery = (
  { __typename?: 'Query' }
  & { getCommunityForUser: (
    { __typename?: 'Community' }
    & Pick<Community, 'id' | 'title' | 'description'>
    & { members?: Maybe<Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id'>
    )>>, institutions?: Maybe<Array<(
      { __typename?: 'UniversityField' }
      & Pick<UniversityField, 'id' | 'logo' | 'slug' | 'name' | 'squareLogo' | 'colour' | 'privacyPolicyUrl'>
    )>> }
  ) }
);

export type GetLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLabelsQuery = (
  { __typename?: 'Query' }
  & { getLabels: Array<(
    { __typename?: 'Labels' }
    & Pick<Labels, 'id' | 'name'>
  )> }
);

export type UniversityQuestionsQueryVariables = Exact<{
  confidenceRatingQuestionInput: UniversityQuestionByIdInput;
  feelConfidentQuestionInput: UniversityQuestionByIdInput;
}>;


export type UniversityQuestionsQuery = (
  { __typename?: 'Query' }
  & { question1: (
    { __typename?: 'UniversityQuestion' }
    & Pick<UniversityQuestion, 'id' | 'active' | 'text'>
    & { options?: Maybe<Array<(
      { __typename?: 'UniversityQuestionOption' }
      & Pick<UniversityQuestionOption, 'text' | 'id'>
    )>> }
  ), question2: (
    { __typename?: 'UniversityQuestion' }
    & Pick<UniversityQuestion, 'id' | 'active' | 'text'>
    & { options?: Maybe<Array<(
      { __typename?: 'UniversityQuestionOption' }
      & Pick<UniversityQuestionOption, 'text' | 'id'>
    )>> }
  ) }
);

export type SaveUniversityQuestionAnswerMutationVariables = Exact<{
  saveUniversityQuestionAnswerInput: SaveUniversityQuestionAnswerInput;
}>;


export type SaveUniversityQuestionAnswerMutation = (
  { __typename?: 'Mutation' }
  & { saveUniversityQuestionAnswer: Array<(
    { __typename?: 'UniversityQuestionAnswer' }
    & Pick<UniversityQuestionAnswer, 'id'>
  )> }
);

export type EnrolledUniversityQuestionsQueryVariables = Exact<{
  haveEnrolledQuestionInput: UniversityQuestionByIdInput;
  enrolledCommunityInfluenceRatingQuestionInput: UniversityQuestionByIdInput;
  enrolledMoreInfo: UniversityQuestionByIdInput;
  enroledWhatInfluencedDecision: UniversityQuestionByIdInput;
}>;


export type EnrolledUniversityQuestionsQuery = (
  { __typename?: 'Query' }
  & { question1: (
    { __typename?: 'UniversityQuestion' }
    & Pick<UniversityQuestion, 'id' | 'active' | 'text'>
    & { options?: Maybe<Array<(
      { __typename?: 'UniversityQuestionOption' }
      & Pick<UniversityQuestionOption, 'text' | 'id'>
    )>> }
  ), question2: (
    { __typename?: 'UniversityQuestion' }
    & Pick<UniversityQuestion, 'id' | 'active' | 'text'>
    & { options?: Maybe<Array<(
      { __typename?: 'UniversityQuestionOption' }
      & Pick<UniversityQuestionOption, 'text' | 'id'>
    )>> }
  ), question3: (
    { __typename?: 'UniversityQuestion' }
    & Pick<UniversityQuestion, 'id' | 'active' | 'text'>
    & { options?: Maybe<Array<(
      { __typename?: 'UniversityQuestionOption' }
      & Pick<UniversityQuestionOption, 'text' | 'id'>
    )>> }
  ), question4: (
    { __typename?: 'UniversityQuestion' }
    & Pick<UniversityQuestion, 'id' | 'active' | 'text'>
    & { options?: Maybe<Array<(
      { __typename?: 'UniversityQuestionOption' }
      & Pick<UniversityQuestionOption, 'text' | 'id'>
    )>> }
  ) }
);

export type SendCommunityFeedbackMutationVariables = Exact<{
  communityId: Scalars['String'];
  universitySlug: Scalars['String'];
  question: Scalars['String'];
  rating: Scalars['Float'];
  feedback?: Maybe<Scalars['String']>;
}>;


export type SendCommunityFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { saveSatisfactionSurvey: (
    { __typename?: 'SatisfactionSurveyEntity' }
    & Pick<SatisfactionSurveyEntity, 'userId' | 'communityId' | 'universitySlug'>
  ) }
);

export type InboxPromptQuestionsQueryVariables = Exact<{
  inboxPromptInput: InboxPromptInput;
}>;


export type InboxPromptQuestionsQuery = (
  { __typename?: 'Query' }
  & { inboxPromptQuestions: Array<(
    { __typename?: 'InboxPromptQuestion' }
    & Pick<InboxPromptQuestion, 'id' | 'tags'>
    & { options: Array<(
      { __typename?: 'InboxPromptOption' }
      & Pick<InboxPromptOption, 'id' | 'text' | 'active'>
    )> }
  )> }
);

export type SaveInboxPromptAnswerMutationVariables = Exact<{
  inboxPromptAnswerInput: InboxPromptAnswerInput;
}>;


export type SaveInboxPromptAnswerMutation = (
  { __typename?: 'Mutation' }
  & { saveInboxPromptAnswer: Array<(
    { __typename?: 'InboxPromptAnswer' }
    & Pick<InboxPromptAnswer, 'id'>
  )> }
);

export type GetUserCreatedByUserIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserCreatedByUserIdQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'UserField' }
    & Pick<UserField, 'id' | 'firstName' | 'created'>
    & CountryFragment
  )> }
);

export type SaveNotInterestedStageMutationVariables = Exact<{
  notInterestedStageInput: UserNotInterestedStageInput;
}>;


export type SaveNotInterestedStageMutation = (
  { __typename?: 'Mutation' }
  & { saveNotInterestedStage: (
    { __typename?: 'UserDecisionStageOutput' }
    & Pick<UserDecisionStageOutput, 'decisionStage' | 'decisionStageLocation' | 'notInterestedReason' | 'userId'>
  ) }
);

export type SaveUserDecisionStageMutationVariables = Exact<{
  decisionStageInput: UserDecisionStageInput;
}>;


export type SaveUserDecisionStageMutation = (
  { __typename?: 'Mutation' }
  & { saveUserDecisionStage: (
    { __typename?: 'UserDecisionStageOutput' }
    & Pick<UserDecisionStageOutput, 'decisionStage' | 'decisionStageLocation' | 'notInterestedReason' | 'userId'>
  ) }
);

export type GetConversationPermissionQueryVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type GetConversationPermissionQuery = (
  { __typename?: 'Query' }
  & { getConversationPermission: (
    { __typename?: 'ChatPermission' }
    & Pick<ChatPermission, 'id' | 'isMember'>
  ) }
);

export type GetInterestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInterestsQuery = (
  { __typename?: 'Query' }
  & { getInterests: Array<(
    { __typename?: 'Interest' }
    & Pick<Interest, 'id' | 'name'>
  )> }
);

export type GetCommunityUserDirectoryQueryVariables = Exact<{
  pagination: OffsetPagination;
  filterUsers: FilterUsersDto;
}>;


export type GetCommunityUserDirectoryQuery = (
  { __typename?: 'Query' }
  & { communityUserSearch: (
    { __typename?: 'UserDirectoryResult' }
    & Pick<UserDirectoryResult, 'totalUsers'>
    & { result: Array<(
      { __typename?: 'UserField' }
      & Pick<UserField, 'id' | 'firstName' | 'lastName' | 'accountRole' | 'profilePhoto'>
      & CountryFragment
      & CommunityProfileFragment
    )> }
  ) }
);

export type SetInterestsForUserMutationVariables = Exact<{
  setUserInterestsInput: SetUserInterestsInput;
}>;


export type SetInterestsForUserMutation = (
  { __typename?: 'Mutation' }
  & { setInterestsForUser: Array<(
    { __typename?: 'UserInterest' }
    & Pick<UserInterest, 'id' | 'name'>
  )> }
);

export type GetAllCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCountriesQuery = (
  { __typename?: 'Query' }
  & { allCountries?: Maybe<Array<Maybe<(
    { __typename?: 'CountryField' }
    & Pick<CountryField, 'code' | 'id' | 'name'>
  )>>> }
);

export type UpdateApplicantCountryMutationVariables = Exact<{
  country?: Maybe<Scalars['String']>;
}>;


export type UpdateApplicantCountryMutation = (
  { __typename?: 'Mutation' }
  & { updateApplicant?: Maybe<(
    { __typename?: 'UpdateApplicantMutation' }
    & { me?: Maybe<(
      { __typename?: 'MeField' }
      & { applicant?: Maybe<(
        { __typename?: 'ApplicantField' }
        & Pick<ApplicantField, 'id'>
      )>, anyUser?: Maybe<(
        { __typename?: 'AnyUserField' }
        & { country?: Maybe<(
          { __typename?: 'CountryField' }
          & Pick<CountryField, 'name' | 'code' | 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type GetAccountInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountInformationQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'userId' | 'profilePhoto' | 'bio' | 'pronouns'>
    & { interests?: Maybe<Array<(
      { __typename?: 'InterestObject' }
      & Pick<InterestObject, 'id' | 'type'>
    )>>, areaOfStudy?: Maybe<Array<(
      { __typename?: 'DegreeField' }
      & DegreeFragment
    )>>, housing?: Maybe<(
      { __typename?: 'HousingObject' }
      & Pick<HousingObject, 'category' | 'name'>
    )> }
  ), getInterests: Array<(
    { __typename?: 'Interest' }
    & Pick<Interest, 'id' | 'name'>
  )>, getInterestsForUser: Array<(
    { __typename?: 'UserInterest' }
    & Pick<UserInterest, 'id' | 'name'>
  )> }
);

export type InitiateUserDeletionMutationVariables = Exact<{ [key: string]: never; }>;


export type InitiateUserDeletionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'initiateUserDeletion'>
);

export type ChangeCommunityPasswordMutationVariables = Exact<{
  password?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
}>;


export type ChangeCommunityPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordV2?: Maybe<(
    { __typename?: 'ResetPasswordMutationV2' }
    & Pick<ResetPasswordMutationV2, 'success' | 'redirectUrl' | 'error'>
  )> }
);

export type CommunityRequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
  redirectUrl: Scalars['String'];
}>;


export type CommunityRequestPasswordResetMutation = (
  { __typename?: 'Mutation' }
  & { requestPasswordReset?: Maybe<(
    { __typename?: 'RequestPasswordResetMutation' }
    & Pick<RequestPasswordResetMutation, 'success'>
  )> }
);

export type SaveCookieConsentPreferencesMutationVariables = Exact<{
  input: CookieConsentInputDto;
}>;


export type SaveCookieConsentPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { saveCookieConsentPreferences: (
    { __typename?: 'CookieConsentOutputDTO' }
    & Pick<CookieConsentOutputDto, 'uniqueId'>
    & { preferences: (
      { __typename?: 'PreferencesOutputType' }
      & Pick<PreferencesOutputType, 'strictCookies' | 'analyticsCookies'>
    ) }
  ) }
);

export type EditCookieConsentPreferencesMutationVariables = Exact<{
  input: CookieConsentInputDto;
}>;


export type EditCookieConsentPreferencesMutation = (
  { __typename?: 'Mutation' }
  & { editCookieConsentPreferences: (
    { __typename?: 'CookieConsentOutputDTO' }
    & Pick<CookieConsentOutputDto, 'uniqueId'>
    & { preferences: (
      { __typename?: 'PreferencesOutputType' }
      & Pick<PreferencesOutputType, 'strictCookies' | 'analyticsCookies'>
    ) }
  ) }
);

export const CommunityFieldsFragmentDoc = gql`
    fragment CommunityFields on ChatConversation {
  communityChatTitle
  communityChatDescription
  communityId
  communityChatDynamicLink
  mutedBy
  totalMemberCount
  communityChatMembers(offsetPagination: {limit: 15}) {
    id
    profilePhoto
    firstName
    accountRole
    communityProfile {
      id
      profilePhoto
    }
  }
}
    `;
export const AllMembersFragmentDoc = gql`
    fragment AllMembers on ChatConversation {
  allMembers: communityChatMembers(offsetPagination: {limit: 999}) {
    id
  }
}
    `;
export const InterestsFragmentDoc = gql`
    fragment Interests on UserField {
  interests {
    id
    name
  }
}
    `;
export const HousingFragmentDoc = gql`
    fragment Housing on UserProfile {
  housing {
    category
    name
  }
}
    `;
export const ReactionsFragmentDoc = gql`
    fragment Reactions on ChatMessage {
  reactions {
    reaction
    reactionUnicode
    userIds
  }
}
    `;
export const CommunityChatMessageFragmentDoc = gql`
    fragment CommunityChatMessage on ChatMessage {
  id
  text
  deleted
  created
  likes
  likesCount
  sender {
    id
    firstName
    profilePhoto
    accountRole
    country {
      id
      name
      code
    }
    communityProfile {
      id
      profilePhoto
    }
  }
  ...Reactions
}
    ${ReactionsFragmentDoc}`;
export const CommunityGiphyRichContentFragmentDoc = gql`
    fragment CommunityGiphyRichContent on Giphy {
  id
  type
  width
  height
  aspectRatio
}
    `;
export const CommunityReplyRichContentFragmentDoc = gql`
    fragment CommunityReplyRichContent on ChatMessage {
  text
  deleted
  created
  likes
  likesCount
  sender {
    id
    firstName
    profilePhoto
    accountRole
    communityProfile {
      id
      profilePhoto
    }
  }
}
    `;
export const CommunityImagesRichContentFragmentDoc = gql`
    fragment CommunityImagesRichContent on Image {
  url
}
    `;
export const CommunityAttachmentRichContentFragmentDoc = gql`
    fragment CommunityAttachmentRichContent on Attachment {
  link
  type
  size
  fileName
}
    `;
export const CommunityPollRichContentFragmentDoc = gql`
    fragment CommunityPollRichContent on Poll {
  allowMultipleAnswers
  question
  options {
    option
    votes
  }
}
    `;
export const FullCommunityChatMessageFragmentDoc = gql`
    fragment FullCommunityChatMessage on ChatMessage {
  ...CommunityChatMessage
  richContent {
    giphy {
      ...CommunityGiphyRichContent
    }
    reply {
      ...CommunityReplyRichContent
      richContent {
        giphy {
          ...CommunityGiphyRichContent
        }
        images {
          ...CommunityImagesRichContent
        }
        attachments {
          ...CommunityAttachmentRichContent
        }
      }
    }
    images {
      ...CommunityImagesRichContent
    }
    attachments {
      ...CommunityAttachmentRichContent
    }
    poll {
      ...CommunityPollRichContent
    }
  }
}
    ${CommunityChatMessageFragmentDoc}
${CommunityGiphyRichContentFragmentDoc}
${CommunityReplyRichContentFragmentDoc}
${CommunityImagesRichContentFragmentDoc}
${CommunityAttachmentRichContentFragmentDoc}
${CommunityPollRichContentFragmentDoc}`;
export const CommunityPinnedMessageIdFragmentDoc = gql`
    fragment CommunityPinnedMessageId on ChatConversation {
  pinnedMessages {
    id
  }
}
    `;
export const NewsFeedGroupsFragmentDoc = gql`
    fragment NewsFeedGroups on Community {
  newsFeedGroups {
    conversationId
  }
}
    `;
export const CommunitySettingsFragmentDoc = gql`
    fragment CommunitySettings on Community {
  settings {
    allowGifs
    allowPrivateGroups
    allowImages
    allowDirectChat
    allowNewsFeed
  }
}
    `;
export const BlogPostPreviewFragmentFragmentDoc = gql`
    fragment BlogPostPreviewFragment on BlogPostField {
  id
  body
  title
  coverImages(raw: true)
  youtubeVideo
  topic {
    name
  }
  videoContent {
    id
    status
    type
    url {
      url
    }
  }
  author {
    id
    firstName
    lastName
    profilePhoto
    degree {
      id
      name
    }
    country {
      id
      code
      name
    }
  }
}
    `;
export const GroupTypesFragmentDoc = gql`
    fragment GroupTypes on ChatConversation {
  isPrivate
  isNewsFeed
  isDirect
}
    `;
export const InboxConversationFragmentDoc = gql`
    fragment InboxConversation on ChatConversation {
  id
  communityChatTitle
  communityChatDescription
  communityChatDynamicLink
  unreadMessageCount
  mutedBy
  ...GroupTypes
  lastMessage {
    id
    sender {
      id
      firstName
    }
    created
    text
  }
  communityChatMembers(offsetPagination: {offset: 0, limit: 2}) {
    id
    firstName
    accountRole
    profilePhoto
    communityProfile {
      id
      profilePhoto
    }
  }
}
    ${GroupTypesFragmentDoc}`;
export const CountryFragmentDoc = gql`
    fragment Country on UserField {
  country {
    id
    name
    code
  }
}
    `;
export const CommunityProfileFragmentDoc = gql`
    fragment CommunityProfile on UserField {
  communityProfile {
    id
    profilePhoto
    bio
    areaOfStudy {
      name
    }
    interests {
      type
    }
  }
}
    `;
export const RecommendedUsersFragmentDoc = gql`
    fragment RecommendedUsers on UserRecommendation {
  currentUser {
    id
    accountRole
    firstName
    lastName
    university {
      name
    }
    communityProfile {
      id
      profilePhoto
    }
  }
  matches {
    matchingCriteria
    matchingPercentage
    user {
      id
      firstName
      profilePhoto
      accountRole
      lastName
      ...Country
      ...CommunityProfile
    }
  }
  totalMatches
  type
}
    ${CountryFragmentDoc}
${CommunityProfileFragmentDoc}`;
export const DegreeFragmentDoc = gql`
    fragment Degree on DegreeField {
  id
  name
  degreeType
  active
}
    `;
export const GetEmailAddressFromInviteIdDocument = gql`
    query GetEmailAddressFromInviteId($inviteId: String!) {
  getInviteFromId(inviteId: $inviteId) {
    valid
    id
    invitedEmail
    communityId
  }
}
    `;

/**
 * __useGetEmailAddressFromInviteIdQuery__
 *
 * To run a query within a React component, call `useGetEmailAddressFromInviteIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailAddressFromInviteIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailAddressFromInviteIdQuery({
 *   variables: {
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useGetEmailAddressFromInviteIdQuery(baseOptions: Apollo.QueryHookOptions<GetEmailAddressFromInviteIdQuery, GetEmailAddressFromInviteIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmailAddressFromInviteIdQuery, GetEmailAddressFromInviteIdQueryVariables>(GetEmailAddressFromInviteIdDocument, options);
      }
export function useGetEmailAddressFromInviteIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmailAddressFromInviteIdQuery, GetEmailAddressFromInviteIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmailAddressFromInviteIdQuery, GetEmailAddressFromInviteIdQueryVariables>(GetEmailAddressFromInviteIdDocument, options);
        }
export type GetEmailAddressFromInviteIdQueryHookResult = ReturnType<typeof useGetEmailAddressFromInviteIdQuery>;
export type GetEmailAddressFromInviteIdLazyQueryHookResult = ReturnType<typeof useGetEmailAddressFromInviteIdLazyQuery>;
export type GetEmailAddressFromInviteIdQueryResult = Apollo.QueryResult<GetEmailAddressFromInviteIdQuery, GetEmailAddressFromInviteIdQueryVariables>;
export const GetInviteInformationDocument = gql`
    query GetInviteInformation($inviteInformationInput: InviteInformationInput!) {
  getInviteInformation(InviteInformationInput: $inviteInformationInput) {
    community {
      description
      title
      id
    }
    id
    university {
      name
      slug
      id
      squareLogo
    }
  }
}
    `;

/**
 * __useGetInviteInformationQuery__
 *
 * To run a query within a React component, call `useGetInviteInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteInformationQuery({
 *   variables: {
 *      inviteInformationInput: // value for 'inviteInformationInput'
 *   },
 * });
 */
export function useGetInviteInformationQuery(baseOptions: Apollo.QueryHookOptions<GetInviteInformationQuery, GetInviteInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviteInformationQuery, GetInviteInformationQueryVariables>(GetInviteInformationDocument, options);
      }
export function useGetInviteInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviteInformationQuery, GetInviteInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviteInformationQuery, GetInviteInformationQueryVariables>(GetInviteInformationDocument, options);
        }
export type GetInviteInformationQueryHookResult = ReturnType<typeof useGetInviteInformationQuery>;
export type GetInviteInformationLazyQueryHookResult = ReturnType<typeof useGetInviteInformationLazyQuery>;
export type GetInviteInformationQueryResult = Apollo.QueryResult<GetInviteInformationQuery, GetInviteInformationQueryVariables>;
export const AdminLobbyDocument = gql`
    query AdminLobby($institutionId: String!) {
  listCommunitiesForAnInstitution(institutionId: $institutionId) {
    id
    title
    members {
      id
    }
    communityMemberCount
    institutions {
      id
      logo
      slug
      name
      squareLogo
      colour
      privacyPolicyUrl
      isFeatureEnabled(feature: "community")
    }
    ...CommunitySettings
    ...NewsFeedGroups
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}`;

/**
 * __useAdminLobbyQuery__
 *
 * To run a query within a React component, call `useAdminLobbyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLobbyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLobbyQuery({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useAdminLobbyQuery(baseOptions: Apollo.QueryHookOptions<AdminLobbyQuery, AdminLobbyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminLobbyQuery, AdminLobbyQueryVariables>(AdminLobbyDocument, options);
      }
export function useAdminLobbyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminLobbyQuery, AdminLobbyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminLobbyQuery, AdminLobbyQueryVariables>(AdminLobbyDocument, options);
        }
export type AdminLobbyQueryHookResult = ReturnType<typeof useAdminLobbyQuery>;
export type AdminLobbyLazyQueryHookResult = ReturnType<typeof useAdminLobbyLazyQuery>;
export type AdminLobbyQueryResult = Apollo.QueryResult<AdminLobbyQuery, AdminLobbyQueryVariables>;
export const CommunityLobbyDocument = gql`
    query CommunityLobby($userId: String!, $email: String!) {
  getUser(id: $userId) {
    id
    communities {
      id
      title
      communityMemberCount
      institutions {
        id
        logo
        slug
        name
        squareLogo
        colour
        privacyPolicyUrl
        isFeatureEnabled(feature: "community")
      }
      ...CommunitySettings
      ...NewsFeedGroups
    }
  }
  getInvitesForEmail(email: $email) {
    id
    community {
      id
      title
      communityMemberCount
      institutions {
        id
        logo
        slug
        name
        squareLogo
        colour
        privacyPolicyUrl
        isFeatureEnabled(feature: "community")
      }
      ...CommunitySettings
      ...NewsFeedGroups
    }
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}`;

/**
 * __useCommunityLobbyQuery__
 *
 * To run a query within a React component, call `useCommunityLobbyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityLobbyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityLobbyQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCommunityLobbyQuery(baseOptions: Apollo.QueryHookOptions<CommunityLobbyQuery, CommunityLobbyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityLobbyQuery, CommunityLobbyQueryVariables>(CommunityLobbyDocument, options);
      }
export function useCommunityLobbyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityLobbyQuery, CommunityLobbyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityLobbyQuery, CommunityLobbyQueryVariables>(CommunityLobbyDocument, options);
        }
export type CommunityLobbyQueryHookResult = ReturnType<typeof useCommunityLobbyQuery>;
export type CommunityLobbyLazyQueryHookResult = ReturnType<typeof useCommunityLobbyLazyQuery>;
export type CommunityLobbyQueryResult = Apollo.QueryResult<CommunityLobbyQuery, CommunityLobbyQueryVariables>;
export const JoinCommunityDocument = gql`
    mutation JoinCommunity($communityId: String!) {
  joinCommunity(communityId: $communityId) {
    joined
    community {
      id
      institutions {
        id
        slug
        name
        logo
        squareLogo
        colour
        privacyPolicyUrl
      }
      title
      welcomeText
      ...CommunitySettings
      ...NewsFeedGroups
    }
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}`;
export type JoinCommunityMutationFn = Apollo.MutationFunction<JoinCommunityMutation, JoinCommunityMutationVariables>;

/**
 * __useJoinCommunityMutation__
 *
 * To run a mutation, you first call `useJoinCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCommunityMutation, { data, loading, error }] = useJoinCommunityMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useJoinCommunityMutation(baseOptions?: Apollo.MutationHookOptions<JoinCommunityMutation, JoinCommunityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinCommunityMutation, JoinCommunityMutationVariables>(JoinCommunityDocument, options);
      }
export type JoinCommunityMutationHookResult = ReturnType<typeof useJoinCommunityMutation>;
export type JoinCommunityMutationResult = Apollo.MutationResult<JoinCommunityMutation>;
export type JoinCommunityMutationOptions = Apollo.BaseMutationOptions<JoinCommunityMutation, JoinCommunityMutationVariables>;
export const JoinCommunityForAdminsDocument = gql`
    mutation JoinCommunityForAdmins($communityId: String!) {
  joinCommunityForAdmins(communityId: $communityId) {
    joined
    community {
      id
      institutions {
        id
        slug
        name
        logo
        squareLogo
        colour
        privacyPolicyUrl
      }
      title
      welcomeText
      ...CommunitySettings
      ...NewsFeedGroups
    }
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}`;
export type JoinCommunityForAdminsMutationFn = Apollo.MutationFunction<JoinCommunityForAdminsMutation, JoinCommunityForAdminsMutationVariables>;

/**
 * __useJoinCommunityForAdminsMutation__
 *
 * To run a mutation, you first call `useJoinCommunityForAdminsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCommunityForAdminsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCommunityForAdminsMutation, { data, loading, error }] = useJoinCommunityForAdminsMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useJoinCommunityForAdminsMutation(baseOptions?: Apollo.MutationHookOptions<JoinCommunityForAdminsMutation, JoinCommunityForAdminsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinCommunityForAdminsMutation, JoinCommunityForAdminsMutationVariables>(JoinCommunityForAdminsDocument, options);
      }
export type JoinCommunityForAdminsMutationHookResult = ReturnType<typeof useJoinCommunityForAdminsMutation>;
export type JoinCommunityForAdminsMutationResult = Apollo.MutationResult<JoinCommunityForAdminsMutation>;
export type JoinCommunityForAdminsMutationOptions = Apollo.BaseMutationOptions<JoinCommunityForAdminsMutation, JoinCommunityForAdminsMutationVariables>;
export const UpdateApplicantSignupSourceDocument = gql`
    mutation UpdateApplicantSignupSource($communityId: String!) {
  updateCommunityApplicantSignupsource(communityId: $communityId) {
    me {
      anyUser {
        id
      }
    }
  }
}
    `;
export type UpdateApplicantSignupSourceMutationFn = Apollo.MutationFunction<UpdateApplicantSignupSourceMutation, UpdateApplicantSignupSourceMutationVariables>;

/**
 * __useUpdateApplicantSignupSourceMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantSignupSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantSignupSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantSignupSourceMutation, { data, loading, error }] = useUpdateApplicantSignupSourceMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUpdateApplicantSignupSourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantSignupSourceMutation, UpdateApplicantSignupSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicantSignupSourceMutation, UpdateApplicantSignupSourceMutationVariables>(UpdateApplicantSignupSourceDocument, options);
      }
export type UpdateApplicantSignupSourceMutationHookResult = ReturnType<typeof useUpdateApplicantSignupSourceMutation>;
export type UpdateApplicantSignupSourceMutationResult = Apollo.MutationResult<UpdateApplicantSignupSourceMutation>;
export type UpdateApplicantSignupSourceMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantSignupSourceMutation, UpdateApplicantSignupSourceMutationVariables>;
export const GetUserProfileDocument = gql`
    query GetUserProfile {
  getUserProfile {
    id
    userId
    interests {
      id
      type
    }
    profilePhoto
    areaOfStudy {
      ...Degree
    }
    bio
  }
}
    ${DegreeFragmentDoc}`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const SetCommunityDeviceTokenDocument = gql`
    mutation SetCommunityDeviceToken($active: Boolean!, $deviceToken: String!, $userId: String!, $system: String!) {
  setDeviceToken(
    SetDeviceTokenDTO: {active: $active, deviceToken: $deviceToken, system: $system, userId: $userId}
  ) {
    active
  }
}
    `;
export type SetCommunityDeviceTokenMutationFn = Apollo.MutationFunction<SetCommunityDeviceTokenMutation, SetCommunityDeviceTokenMutationVariables>;

/**
 * __useSetCommunityDeviceTokenMutation__
 *
 * To run a mutation, you first call `useSetCommunityDeviceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCommunityDeviceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCommunityDeviceTokenMutation, { data, loading, error }] = useSetCommunityDeviceTokenMutation({
 *   variables: {
 *      active: // value for 'active'
 *      deviceToken: // value for 'deviceToken'
 *      userId: // value for 'userId'
 *      system: // value for 'system'
 *   },
 * });
 */
export function useSetCommunityDeviceTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetCommunityDeviceTokenMutation, SetCommunityDeviceTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCommunityDeviceTokenMutation, SetCommunityDeviceTokenMutationVariables>(SetCommunityDeviceTokenDocument, options);
      }
export type SetCommunityDeviceTokenMutationHookResult = ReturnType<typeof useSetCommunityDeviceTokenMutation>;
export type SetCommunityDeviceTokenMutationResult = Apollo.MutationResult<SetCommunityDeviceTokenMutation>;
export type SetCommunityDeviceTokenMutationOptions = Apollo.BaseMutationOptions<SetCommunityDeviceTokenMutation, SetCommunityDeviceTokenMutationVariables>;
export const MeQueryDocument = gql`
    query MeQuery {
  me {
    anyUser {
      verified
      firstName
      lastName
      profilePhoto
      email
      id
      accountRole
      country {
        name
        id
        code
      }
      university {
        id
        slug
        name
      }
    }
  }
}
    `;

/**
 * __useMeQueryQuery__
 *
 * To run a query within a React component, call `useMeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQueryQuery(baseOptions?: Apollo.QueryHookOptions<MeQueryQuery, MeQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQueryQuery, MeQueryQueryVariables>(MeQueryDocument, options);
      }
export function useMeQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQueryQuery, MeQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQueryQuery, MeQueryQueryVariables>(MeQueryDocument, options);
        }
export type MeQueryQueryHookResult = ReturnType<typeof useMeQueryQuery>;
export type MeQueryLazyQueryHookResult = ReturnType<typeof useMeQueryLazyQuery>;
export type MeQueryQueryResult = Apollo.QueryResult<MeQueryQuery, MeQueryQueryVariables>;
export const RegisterCommunityApplicantDocument = gql`
    mutation RegisterCommunityApplicant($email: String!, $password: String!, $firstName: String!, $lastName: String!, $country: String) {
  createCommunityApplicantV2(
    email: $email
    password: $password
    firstName: $firstName
    lastName: $lastName
    country: $country
  ) {
    applicant {
      id
    }
    error
  }
}
    `;
export type RegisterCommunityApplicantMutationFn = Apollo.MutationFunction<RegisterCommunityApplicantMutation, RegisterCommunityApplicantMutationVariables>;

/**
 * __useRegisterCommunityApplicantMutation__
 *
 * To run a mutation, you first call `useRegisterCommunityApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCommunityApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCommunityApplicantMutation, { data, loading, error }] = useRegisterCommunityApplicantMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useRegisterCommunityApplicantMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCommunityApplicantMutation, RegisterCommunityApplicantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCommunityApplicantMutation, RegisterCommunityApplicantMutationVariables>(RegisterCommunityApplicantDocument, options);
      }
export type RegisterCommunityApplicantMutationHookResult = ReturnType<typeof useRegisterCommunityApplicantMutation>;
export type RegisterCommunityApplicantMutationResult = Apollo.MutationResult<RegisterCommunityApplicantMutation>;
export type RegisterCommunityApplicantMutationOptions = Apollo.BaseMutationOptions<RegisterCommunityApplicantMutation, RegisterCommunityApplicantMutationVariables>;
export const RequestCommunityVerificationCodeDocument = gql`
    mutation RequestCommunityVerificationCode {
  sendCommunityVerificationCode {
    success
  }
}
    `;
export type RequestCommunityVerificationCodeMutationFn = Apollo.MutationFunction<RequestCommunityVerificationCodeMutation, RequestCommunityVerificationCodeMutationVariables>;

/**
 * __useRequestCommunityVerificationCodeMutation__
 *
 * To run a mutation, you first call `useRequestCommunityVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCommunityVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCommunityVerificationCodeMutation, { data, loading, error }] = useRequestCommunityVerificationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestCommunityVerificationCodeMutation(baseOptions?: Apollo.MutationHookOptions<RequestCommunityVerificationCodeMutation, RequestCommunityVerificationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestCommunityVerificationCodeMutation, RequestCommunityVerificationCodeMutationVariables>(RequestCommunityVerificationCodeDocument, options);
      }
export type RequestCommunityVerificationCodeMutationHookResult = ReturnType<typeof useRequestCommunityVerificationCodeMutation>;
export type RequestCommunityVerificationCodeMutationResult = Apollo.MutationResult<RequestCommunityVerificationCodeMutation>;
export type RequestCommunityVerificationCodeMutationOptions = Apollo.BaseMutationOptions<RequestCommunityVerificationCodeMutation, RequestCommunityVerificationCodeMutationVariables>;
export const VerifyCommunityEmailDocument = gql`
    mutation VerifyCommunityEmail($code: String!) {
  verifyAccount(code: $code) {
    success
    error
  }
}
    `;
export type VerifyCommunityEmailMutationFn = Apollo.MutationFunction<VerifyCommunityEmailMutation, VerifyCommunityEmailMutationVariables>;

/**
 * __useVerifyCommunityEmailMutation__
 *
 * To run a mutation, you first call `useVerifyCommunityEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyCommunityEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyCommunityEmailMutation, { data, loading, error }] = useVerifyCommunityEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyCommunityEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyCommunityEmailMutation, VerifyCommunityEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyCommunityEmailMutation, VerifyCommunityEmailMutationVariables>(VerifyCommunityEmailDocument, options);
      }
export type VerifyCommunityEmailMutationHookResult = ReturnType<typeof useVerifyCommunityEmailMutation>;
export type VerifyCommunityEmailMutationResult = Apollo.MutationResult<VerifyCommunityEmailMutation>;
export type VerifyCommunityEmailMutationOptions = Apollo.BaseMutationOptions<VerifyCommunityEmailMutation, VerifyCommunityEmailMutationVariables>;
export const CreateUserReportDocument = gql`
    mutation CreateUserReport($userReportUserInputDTO: UserReportUserInputDTO!) {
  createUserReport(userReportUserInputDTO: $userReportUserInputDTO) {
    id
  }
}
    `;
export type CreateUserReportMutationFn = Apollo.MutationFunction<CreateUserReportMutation, CreateUserReportMutationVariables>;

/**
 * __useCreateUserReportMutation__
 *
 * To run a mutation, you first call `useCreateUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserReportMutation, { data, loading, error }] = useCreateUserReportMutation({
 *   variables: {
 *      userReportUserInputDTO: // value for 'userReportUserInputDTO'
 *   },
 * });
 */
export function useCreateUserReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserReportMutation, CreateUserReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserReportMutation, CreateUserReportMutationVariables>(CreateUserReportDocument, options);
      }
export type CreateUserReportMutationHookResult = ReturnType<typeof useCreateUserReportMutation>;
export type CreateUserReportMutationResult = Apollo.MutationResult<CreateUserReportMutation>;
export type CreateUserReportMutationOptions = Apollo.BaseMutationOptions<CreateUserReportMutation, CreateUserReportMutationVariables>;
export const ToggleMuteConversationDocument = gql`
    mutation ToggleMuteConversation($conversationId: String!, $status: Boolean!) {
  toggleCommunityChatGroupMute(conversationId: $conversationId, status: $status) {
    id
    ...CommunityFields
    ...GroupTypes
    ...AllMembers
  }
}
    ${CommunityFieldsFragmentDoc}
${GroupTypesFragmentDoc}
${AllMembersFragmentDoc}`;
export type ToggleMuteConversationMutationFn = Apollo.MutationFunction<ToggleMuteConversationMutation, ToggleMuteConversationMutationVariables>;

/**
 * __useToggleMuteConversationMutation__
 *
 * To run a mutation, you first call `useToggleMuteConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMuteConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMuteConversationMutation, { data, loading, error }] = useToggleMuteConversationMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useToggleMuteConversationMutation(baseOptions?: Apollo.MutationHookOptions<ToggleMuteConversationMutation, ToggleMuteConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleMuteConversationMutation, ToggleMuteConversationMutationVariables>(ToggleMuteConversationDocument, options);
      }
export type ToggleMuteConversationMutationHookResult = ReturnType<typeof useToggleMuteConversationMutation>;
export type ToggleMuteConversationMutationResult = Apollo.MutationResult<ToggleMuteConversationMutation>;
export type ToggleMuteConversationMutationOptions = Apollo.BaseMutationOptions<ToggleMuteConversationMutation, ToggleMuteConversationMutationVariables>;
export const AddUserToGroupDocument = gql`
    mutation AddUserToGroup($memberId: String!, $conversationId: String!) {
  addUserToCommunityConversation(
    memberId: $memberId
    conversationId: $conversationId
  ) {
    ...InboxConversation
    allMembers: communityChatMembers(offsetPagination: {limit: 999}) {
      id
    }
  }
}
    ${InboxConversationFragmentDoc}`;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<AddUserToGroupMutation, AddUserToGroupMutationVariables>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useAddUserToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument, options);
      }
export type AddUserToGroupMutationHookResult = ReturnType<typeof useAddUserToGroupMutation>;
export type AddUserToGroupMutationResult = Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const GetCommunityConversationDocument = gql`
    query GetCommunityConversation($id: String!) {
  getChatConversation(id: $id) {
    id
    ...CommunityFields
    ...GroupTypes
  }
}
    ${CommunityFieldsFragmentDoc}
${GroupTypesFragmentDoc}`;

/**
 * __useGetCommunityConversationQuery__
 *
 * To run a query within a React component, call `useGetCommunityConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommunityConversationQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityConversationQuery, GetCommunityConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityConversationQuery, GetCommunityConversationQueryVariables>(GetCommunityConversationDocument, options);
      }
export function useGetCommunityConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityConversationQuery, GetCommunityConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityConversationQuery, GetCommunityConversationQueryVariables>(GetCommunityConversationDocument, options);
        }
export type GetCommunityConversationQueryHookResult = ReturnType<typeof useGetCommunityConversationQuery>;
export type GetCommunityConversationLazyQueryHookResult = ReturnType<typeof useGetCommunityConversationLazyQuery>;
export type GetCommunityConversationQueryResult = Apollo.QueryResult<GetCommunityConversationQuery, GetCommunityConversationQueryVariables>;
export const GetFullConversationMembersListDocument = gql`
    query GetFullConversationMembersList($id: String!) {
  getChatConversation(id: $id) {
    id
    totalMemberCount
  }
}
    `;

/**
 * __useGetFullConversationMembersListQuery__
 *
 * To run a query within a React component, call `useGetFullConversationMembersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullConversationMembersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullConversationMembersListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFullConversationMembersListQuery(baseOptions: Apollo.QueryHookOptions<GetFullConversationMembersListQuery, GetFullConversationMembersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullConversationMembersListQuery, GetFullConversationMembersListQueryVariables>(GetFullConversationMembersListDocument, options);
      }
export function useGetFullConversationMembersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullConversationMembersListQuery, GetFullConversationMembersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullConversationMembersListQuery, GetFullConversationMembersListQueryVariables>(GetFullConversationMembersListDocument, options);
        }
export type GetFullConversationMembersListQueryHookResult = ReturnType<typeof useGetFullConversationMembersListQuery>;
export type GetFullConversationMembersListLazyQueryHookResult = ReturnType<typeof useGetFullConversationMembersListLazyQuery>;
export type GetFullConversationMembersListQueryResult = Apollo.QueryResult<GetFullConversationMembersListQuery, GetFullConversationMembersListQueryVariables>;
export const GetCommunityChatMembersDocument = gql`
    query GetCommunityChatMembers($conversationId: String!, $offsetPagination: OffsetPagination!, $idIn: [String]) {
  getCommunityChatMembers(
    conversationId: $conversationId
    offsetPagination: $offsetPagination
  ) {
    id
    firstName
    lastName
    accountRole
    profilePhoto
    ...Country
    ...CommunityProfile
  }
  getUserByIds(idIn: $idIn) {
    id
    firstName
    lastName
    accountRole
    ...Country
    ...CommunityProfile
  }
}
    ${CountryFragmentDoc}
${CommunityProfileFragmentDoc}`;

/**
 * __useGetCommunityChatMembersQuery__
 *
 * To run a query within a React component, call `useGetCommunityChatMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityChatMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityChatMembersQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      offsetPagination: // value for 'offsetPagination'
 *      idIn: // value for 'idIn'
 *   },
 * });
 */
export function useGetCommunityChatMembersQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityChatMembersQuery, GetCommunityChatMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityChatMembersQuery, GetCommunityChatMembersQueryVariables>(GetCommunityChatMembersDocument, options);
      }
export function useGetCommunityChatMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityChatMembersQuery, GetCommunityChatMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityChatMembersQuery, GetCommunityChatMembersQueryVariables>(GetCommunityChatMembersDocument, options);
        }
export type GetCommunityChatMembersQueryHookResult = ReturnType<typeof useGetCommunityChatMembersQuery>;
export type GetCommunityChatMembersLazyQueryHookResult = ReturnType<typeof useGetCommunityChatMembersLazyQuery>;
export type GetCommunityChatMembersQueryResult = Apollo.QueryResult<GetCommunityChatMembersQuery, GetCommunityChatMembersQueryVariables>;
export const GetUserByUserIdDocument = gql`
    query GetUserByUserId($id: String!) {
  getUser(id: $id) {
    id
    firstName
    accountRole
    ...Country
    ...Interests
    profilePhoto
    communityProfile {
      id
      profilePhoto
      bio
      areaOfStudy {
        name
      }
      pronouns
      ...Housing
    }
  }
}
    ${CountryFragmentDoc}
${InterestsFragmentDoc}
${HousingFragmentDoc}`;

/**
 * __useGetUserByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByUserIdQuery, GetUserByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByUserIdQuery, GetUserByUserIdQueryVariables>(GetUserByUserIdDocument, options);
      }
export function useGetUserByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByUserIdQuery, GetUserByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByUserIdQuery, GetUserByUserIdQueryVariables>(GetUserByUserIdDocument, options);
        }
export type GetUserByUserIdQueryHookResult = ReturnType<typeof useGetUserByUserIdQuery>;
export type GetUserByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserByUserIdLazyQuery>;
export type GetUserByUserIdQueryResult = Apollo.QueryResult<GetUserByUserIdQuery, GetUserByUserIdQueryVariables>;
export const CommunityInboxPrivateDocument = gql`
    query CommunityInboxPrivate($communityId: String!) {
  getUsersCommunityPrivateConversations(communityId: $communityId) {
    ...InboxConversation
    allMembers: communityChatMembers(offsetPagination: {limit: 999}) {
      id
    }
  }
}
    ${InboxConversationFragmentDoc}`;

/**
 * __useCommunityInboxPrivateQuery__
 *
 * To run a query within a React component, call `useCommunityInboxPrivateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityInboxPrivateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityInboxPrivateQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useCommunityInboxPrivateQuery(baseOptions: Apollo.QueryHookOptions<CommunityInboxPrivateQuery, CommunityInboxPrivateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityInboxPrivateQuery, CommunityInboxPrivateQueryVariables>(CommunityInboxPrivateDocument, options);
      }
export function useCommunityInboxPrivateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityInboxPrivateQuery, CommunityInboxPrivateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityInboxPrivateQuery, CommunityInboxPrivateQueryVariables>(CommunityInboxPrivateDocument, options);
        }
export type CommunityInboxPrivateQueryHookResult = ReturnType<typeof useCommunityInboxPrivateQuery>;
export type CommunityInboxPrivateLazyQueryHookResult = ReturnType<typeof useCommunityInboxPrivateLazyQuery>;
export type CommunityInboxPrivateQueryResult = Apollo.QueryResult<CommunityInboxPrivateQuery, CommunityInboxPrivateQueryVariables>;
export const AddVoteDocument = gql`
    mutation AddVote($option: String!, $chatMessageId: ObjectId!) {
  addVote(option: $option, chatMessageId: $chatMessageId) {
    ...CommunityPollRichContent
  }
}
    ${CommunityPollRichContentFragmentDoc}`;
export type AddVoteMutationFn = Apollo.MutationFunction<AddVoteMutation, AddVoteMutationVariables>;

/**
 * __useAddVoteMutation__
 *
 * To run a mutation, you first call `useAddVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVoteMutation, { data, loading, error }] = useAddVoteMutation({
 *   variables: {
 *      option: // value for 'option'
 *      chatMessageId: // value for 'chatMessageId'
 *   },
 * });
 */
export function useAddVoteMutation(baseOptions?: Apollo.MutationHookOptions<AddVoteMutation, AddVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVoteMutation, AddVoteMutationVariables>(AddVoteDocument, options);
      }
export type AddVoteMutationHookResult = ReturnType<typeof useAddVoteMutation>;
export type AddVoteMutationResult = Apollo.MutationResult<AddVoteMutation>;
export type AddVoteMutationOptions = Apollo.BaseMutationOptions<AddVoteMutation, AddVoteMutationVariables>;
export const RemoveVoteDocument = gql`
    mutation RemoveVote($option: String!, $chatMessageId: ObjectId!) {
  removeVote(option: $option, chatMessageId: $chatMessageId) {
    ...CommunityPollRichContent
  }
}
    ${CommunityPollRichContentFragmentDoc}`;
export type RemoveVoteMutationFn = Apollo.MutationFunction<RemoveVoteMutation, RemoveVoteMutationVariables>;

/**
 * __useRemoveVoteMutation__
 *
 * To run a mutation, you first call `useRemoveVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVoteMutation, { data, loading, error }] = useRemoveVoteMutation({
 *   variables: {
 *      option: // value for 'option'
 *      chatMessageId: // value for 'chatMessageId'
 *   },
 * });
 */
export function useRemoveVoteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVoteMutation, RemoveVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVoteMutation, RemoveVoteMutationVariables>(RemoveVoteDocument, options);
      }
export type RemoveVoteMutationHookResult = ReturnType<typeof useRemoveVoteMutation>;
export type RemoveVoteMutationResult = Apollo.MutationResult<RemoveVoteMutation>;
export type RemoveVoteMutationOptions = Apollo.BaseMutationOptions<RemoveVoteMutation, RemoveVoteMutationVariables>;
export const AddReactionToMessageDocument = gql`
    mutation AddReactionToMessage($reactionDto: ReactionDto!) {
  addReactionToMessage(reactionDto: $reactionDto) {
    ...FullCommunityChatMessage
  }
}
    ${FullCommunityChatMessageFragmentDoc}`;
export type AddReactionToMessageMutationFn = Apollo.MutationFunction<AddReactionToMessageMutation, AddReactionToMessageMutationVariables>;

/**
 * __useAddReactionToMessageMutation__
 *
 * To run a mutation, you first call `useAddReactionToMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionToMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionToMessageMutation, { data, loading, error }] = useAddReactionToMessageMutation({
 *   variables: {
 *      reactionDto: // value for 'reactionDto'
 *   },
 * });
 */
export function useAddReactionToMessageMutation(baseOptions?: Apollo.MutationHookOptions<AddReactionToMessageMutation, AddReactionToMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReactionToMessageMutation, AddReactionToMessageMutationVariables>(AddReactionToMessageDocument, options);
      }
export type AddReactionToMessageMutationHookResult = ReturnType<typeof useAddReactionToMessageMutation>;
export type AddReactionToMessageMutationResult = Apollo.MutationResult<AddReactionToMessageMutation>;
export type AddReactionToMessageMutationOptions = Apollo.BaseMutationOptions<AddReactionToMessageMutation, AddReactionToMessageMutationVariables>;
export const RemoveReactionFromMessageDocument = gql`
    mutation RemoveReactionFromMessage($reactionDto: ReactionDto!) {
  removeReactionFromMessage(reactionDto: $reactionDto) {
    ...FullCommunityChatMessage
  }
}
    ${FullCommunityChatMessageFragmentDoc}`;
export type RemoveReactionFromMessageMutationFn = Apollo.MutationFunction<RemoveReactionFromMessageMutation, RemoveReactionFromMessageMutationVariables>;

/**
 * __useRemoveReactionFromMessageMutation__
 *
 * To run a mutation, you first call `useRemoveReactionFromMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionFromMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionFromMessageMutation, { data, loading, error }] = useRemoveReactionFromMessageMutation({
 *   variables: {
 *      reactionDto: // value for 'reactionDto'
 *   },
 * });
 */
export function useRemoveReactionFromMessageMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReactionFromMessageMutation, RemoveReactionFromMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReactionFromMessageMutation, RemoveReactionFromMessageMutationVariables>(RemoveReactionFromMessageDocument, options);
      }
export type RemoveReactionFromMessageMutationHookResult = ReturnType<typeof useRemoveReactionFromMessageMutation>;
export type RemoveReactionFromMessageMutationResult = Apollo.MutationResult<RemoveReactionFromMessageMutation>;
export type RemoveReactionFromMessageMutationOptions = Apollo.BaseMutationOptions<RemoveReactionFromMessageMutation, RemoveReactionFromMessageMutationVariables>;
export const BlockCommunityUserDocument = gql`
    mutation BlockCommunityUser($userId: String!, $conversationId: String!, $comment: String) {
  createUniversityBlockReport(
    blocked: $userId
    conversation: $conversationId
    reason: "Spam"
    comment: $comment
    triggeredFrom: "community"
  ) {
    universityBlockReport {
      id
    }
    error
  }
}
    `;
export type BlockCommunityUserMutationFn = Apollo.MutationFunction<BlockCommunityUserMutation, BlockCommunityUserMutationVariables>;

/**
 * __useBlockCommunityUserMutation__
 *
 * To run a mutation, you first call `useBlockCommunityUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockCommunityUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockCommunityUserMutation, { data, loading, error }] = useBlockCommunityUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      conversationId: // value for 'conversationId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useBlockCommunityUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockCommunityUserMutation, BlockCommunityUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockCommunityUserMutation, BlockCommunityUserMutationVariables>(BlockCommunityUserDocument, options);
      }
export type BlockCommunityUserMutationHookResult = ReturnType<typeof useBlockCommunityUserMutation>;
export type BlockCommunityUserMutationResult = Apollo.MutationResult<BlockCommunityUserMutation>;
export type BlockCommunityUserMutationOptions = Apollo.BaseMutationOptions<BlockCommunityUserMutation, BlockCommunityUserMutationVariables>;
export const SetBlockUserV2Document = gql`
    mutation SetBlockUserV2($userId: String, $setBlocked: Boolean) {
  setBlockUserV2(userId: $userId, setBlocked: $setBlocked) {
    objectsBlockedIds
  }
}
    `;
export type SetBlockUserV2MutationFn = Apollo.MutationFunction<SetBlockUserV2Mutation, SetBlockUserV2MutationVariables>;

/**
 * __useSetBlockUserV2Mutation__
 *
 * To run a mutation, you first call `useSetBlockUserV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBlockUserV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBlockUserV2Mutation, { data, loading, error }] = useSetBlockUserV2Mutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      setBlocked: // value for 'setBlocked'
 *   },
 * });
 */
export function useSetBlockUserV2Mutation(baseOptions?: Apollo.MutationHookOptions<SetBlockUserV2Mutation, SetBlockUserV2MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetBlockUserV2Mutation, SetBlockUserV2MutationVariables>(SetBlockUserV2Document, options);
      }
export type SetBlockUserV2MutationHookResult = ReturnType<typeof useSetBlockUserV2Mutation>;
export type SetBlockUserV2MutationResult = Apollo.MutationResult<SetBlockUserV2Mutation>;
export type SetBlockUserV2MutationOptions = Apollo.BaseMutationOptions<SetBlockUserV2Mutation, SetBlockUserV2MutationVariables>;
export const BlockedUsersListDocument = gql`
    query BlockedUsersList {
  blockedUsersByMe
}
    `;

/**
 * __useBlockedUsersListQuery__
 *
 * To run a query within a React component, call `useBlockedUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockedUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockedUsersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockedUsersListQuery(baseOptions?: Apollo.QueryHookOptions<BlockedUsersListQuery, BlockedUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockedUsersListQuery, BlockedUsersListQueryVariables>(BlockedUsersListDocument, options);
      }
export function useBlockedUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockedUsersListQuery, BlockedUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockedUsersListQuery, BlockedUsersListQueryVariables>(BlockedUsersListDocument, options);
        }
export type BlockedUsersListQueryHookResult = ReturnType<typeof useBlockedUsersListQuery>;
export type BlockedUsersListLazyQueryHookResult = ReturnType<typeof useBlockedUsersListLazyQuery>;
export type BlockedUsersListQueryResult = Apollo.QueryResult<BlockedUsersListQuery, BlockedUsersListQueryVariables>;
export const CreateDirectConversationDocument = gql`
    mutation CreateDirectConversation($communityId: String!, $userToConverseWith: String!, $source: String) {
  createDirectConversation(
    DirectConversationDTO: {communityId: $communityId, userToConverseWith: $userToConverseWith, source: $source}
  ) {
    ...InboxConversation
  }
}
    ${InboxConversationFragmentDoc}`;
export type CreateDirectConversationMutationFn = Apollo.MutationFunction<CreateDirectConversationMutation, CreateDirectConversationMutationVariables>;

/**
 * __useCreateDirectConversationMutation__
 *
 * To run a mutation, you first call `useCreateDirectConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectConversationMutation, { data, loading, error }] = useCreateDirectConversationMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      userToConverseWith: // value for 'userToConverseWith'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useCreateDirectConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDirectConversationMutation, CreateDirectConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDirectConversationMutation, CreateDirectConversationMutationVariables>(CreateDirectConversationDocument, options);
      }
export type CreateDirectConversationMutationHookResult = ReturnType<typeof useCreateDirectConversationMutation>;
export type CreateDirectConversationMutationResult = Apollo.MutationResult<CreateDirectConversationMutation>;
export type CreateDirectConversationMutationOptions = Apollo.BaseMutationOptions<CreateDirectConversationMutation, CreateDirectConversationMutationVariables>;
export const DeleteConversationMessageDocument = gql`
    mutation DeleteConversationMessage($deleteMessageDto: DeleteMessageDto!) {
  deleteConversationMessage(deleteMessageDto: $deleteMessageDto) {
    id
    created
    text
    deleted
  }
}
    `;
export type DeleteConversationMessageMutationFn = Apollo.MutationFunction<DeleteConversationMessageMutation, DeleteConversationMessageMutationVariables>;

/**
 * __useDeleteConversationMessageMutation__
 *
 * To run a mutation, you first call `useDeleteConversationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversationMessageMutation, { data, loading, error }] = useDeleteConversationMessageMutation({
 *   variables: {
 *      deleteMessageDto: // value for 'deleteMessageDto'
 *   },
 * });
 */
export function useDeleteConversationMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConversationMessageMutation, DeleteConversationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConversationMessageMutation, DeleteConversationMessageMutationVariables>(DeleteConversationMessageDocument, options);
      }
export type DeleteConversationMessageMutationHookResult = ReturnType<typeof useDeleteConversationMessageMutation>;
export type DeleteConversationMessageMutationResult = Apollo.MutationResult<DeleteConversationMessageMutation>;
export type DeleteConversationMessageMutationOptions = Apollo.BaseMutationOptions<DeleteConversationMessageMutation, DeleteConversationMessageMutationVariables>;
export const CommunityLikeMessageDocument = gql`
    mutation CommunityLikeMessage($conversationId: ObjectId!, $messageId: ObjectId!, $userId: ObjectId!) {
  likeConversationMessage(
    likeMessageDto: {conversationId: $conversationId, messageId: $messageId, userId: $userId}
  ) {
    id
    likesCount
    likes
  }
}
    `;
export type CommunityLikeMessageMutationFn = Apollo.MutationFunction<CommunityLikeMessageMutation, CommunityLikeMessageMutationVariables>;

/**
 * __useCommunityLikeMessageMutation__
 *
 * To run a mutation, you first call `useCommunityLikeMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityLikeMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityLikeMessageMutation, { data, loading, error }] = useCommunityLikeMessageMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCommunityLikeMessageMutation(baseOptions?: Apollo.MutationHookOptions<CommunityLikeMessageMutation, CommunityLikeMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityLikeMessageMutation, CommunityLikeMessageMutationVariables>(CommunityLikeMessageDocument, options);
      }
export type CommunityLikeMessageMutationHookResult = ReturnType<typeof useCommunityLikeMessageMutation>;
export type CommunityLikeMessageMutationResult = Apollo.MutationResult<CommunityLikeMessageMutation>;
export type CommunityLikeMessageMutationOptions = Apollo.BaseMutationOptions<CommunityLikeMessageMutation, CommunityLikeMessageMutationVariables>;
export const CommunityChatMessagesDocument = gql`
    query CommunityChatMessages($getMessageDto: GetMessageDto!) {
  getChatConversationMessages(getMessageDto: $getMessageDto) @persist {
    hasMore
    messages {
      ...FullCommunityChatMessage
    }
  }
}
    ${FullCommunityChatMessageFragmentDoc}`;

/**
 * __useCommunityChatMessagesQuery__
 *
 * To run a query within a React component, call `useCommunityChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityChatMessagesQuery({
 *   variables: {
 *      getMessageDto: // value for 'getMessageDto'
 *   },
 * });
 */
export function useCommunityChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<CommunityChatMessagesQuery, CommunityChatMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityChatMessagesQuery, CommunityChatMessagesQueryVariables>(CommunityChatMessagesDocument, options);
      }
export function useCommunityChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityChatMessagesQuery, CommunityChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityChatMessagesQuery, CommunityChatMessagesQueryVariables>(CommunityChatMessagesDocument, options);
        }
export type CommunityChatMessagesQueryHookResult = ReturnType<typeof useCommunityChatMessagesQuery>;
export type CommunityChatMessagesLazyQueryHookResult = ReturnType<typeof useCommunityChatMessagesLazyQuery>;
export type CommunityChatMessagesQueryResult = Apollo.QueryResult<CommunityChatMessagesQuery, CommunityChatMessagesQueryVariables>;
export const CommunityPinMessageDocument = gql`
    mutation CommunityPinMessage($pinMessageDTO: pinMessageDTO!) {
  pinMessageInConversation(pinMessageDTO: $pinMessageDTO) {
    pinnedMessages {
      id
    }
  }
}
    `;
export type CommunityPinMessageMutationFn = Apollo.MutationFunction<CommunityPinMessageMutation, CommunityPinMessageMutationVariables>;

/**
 * __useCommunityPinMessageMutation__
 *
 * To run a mutation, you first call `useCommunityPinMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityPinMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityPinMessageMutation, { data, loading, error }] = useCommunityPinMessageMutation({
 *   variables: {
 *      pinMessageDTO: // value for 'pinMessageDTO'
 *   },
 * });
 */
export function useCommunityPinMessageMutation(baseOptions?: Apollo.MutationHookOptions<CommunityPinMessageMutation, CommunityPinMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityPinMessageMutation, CommunityPinMessageMutationVariables>(CommunityPinMessageDocument, options);
      }
export type CommunityPinMessageMutationHookResult = ReturnType<typeof useCommunityPinMessageMutation>;
export type CommunityPinMessageMutationResult = Apollo.MutationResult<CommunityPinMessageMutation>;
export type CommunityPinMessageMutationOptions = Apollo.BaseMutationOptions<CommunityPinMessageMutation, CommunityPinMessageMutationVariables>;
export const CommunityPinnedMessagesDocument = gql`
    query CommunityPinnedMessages($conversationId: String!) {
  getChatConversation(id: $conversationId) {
    id
    communityChatTitle
    pinnedMessages {
      ...FullCommunityChatMessage
    }
  }
}
    ${FullCommunityChatMessageFragmentDoc}`;

/**
 * __useCommunityPinnedMessagesQuery__
 *
 * To run a query within a React component, call `useCommunityPinnedMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPinnedMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPinnedMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useCommunityPinnedMessagesQuery(baseOptions: Apollo.QueryHookOptions<CommunityPinnedMessagesQuery, CommunityPinnedMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityPinnedMessagesQuery, CommunityPinnedMessagesQueryVariables>(CommunityPinnedMessagesDocument, options);
      }
export function useCommunityPinnedMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityPinnedMessagesQuery, CommunityPinnedMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityPinnedMessagesQuery, CommunityPinnedMessagesQueryVariables>(CommunityPinnedMessagesDocument, options);
        }
export type CommunityPinnedMessagesQueryHookResult = ReturnType<typeof useCommunityPinnedMessagesQuery>;
export type CommunityPinnedMessagesLazyQueryHookResult = ReturnType<typeof useCommunityPinnedMessagesLazyQuery>;
export type CommunityPinnedMessagesQueryResult = Apollo.QueryResult<CommunityPinnedMessagesQuery, CommunityPinnedMessagesQueryVariables>;
export const CommunityConversationReadDocument = gql`
    mutation CommunityConversationRead($conversationId: ID!, $messageId: ObjectId!) {
  recordLastMessageReadByUser(
    markReadMessageDto: {conversationId: $conversationId, messageId: $messageId}
  ) {
    userId
    messageId
    conversationId
  }
}
    `;
export type CommunityConversationReadMutationFn = Apollo.MutationFunction<CommunityConversationReadMutation, CommunityConversationReadMutationVariables>;

/**
 * __useCommunityConversationReadMutation__
 *
 * To run a mutation, you first call `useCommunityConversationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityConversationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityConversationReadMutation, { data, loading, error }] = useCommunityConversationReadMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useCommunityConversationReadMutation(baseOptions?: Apollo.MutationHookOptions<CommunityConversationReadMutation, CommunityConversationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityConversationReadMutation, CommunityConversationReadMutationVariables>(CommunityConversationReadDocument, options);
      }
export type CommunityConversationReadMutationHookResult = ReturnType<typeof useCommunityConversationReadMutation>;
export type CommunityConversationReadMutationResult = Apollo.MutationResult<CommunityConversationReadMutation>;
export type CommunityConversationReadMutationOptions = Apollo.BaseMutationOptions<CommunityConversationReadMutation, CommunityConversationReadMutationVariables>;
export const CreateChatReportDocument = gql`
    mutation CreateChatReport($chatReportUserInputDTO: ChatReportUserInputDTO!) {
  createChatReport(chatReportUserInputDTO: $chatReportUserInputDTO) {
    id
  }
}
    `;
export type CreateChatReportMutationFn = Apollo.MutationFunction<CreateChatReportMutation, CreateChatReportMutationVariables>;

/**
 * __useCreateChatReportMutation__
 *
 * To run a mutation, you first call `useCreateChatReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatReportMutation, { data, loading, error }] = useCreateChatReportMutation({
 *   variables: {
 *      chatReportUserInputDTO: // value for 'chatReportUserInputDTO'
 *   },
 * });
 */
export function useCreateChatReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatReportMutation, CreateChatReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatReportMutation, CreateChatReportMutationVariables>(CreateChatReportDocument, options);
      }
export type CreateChatReportMutationHookResult = ReturnType<typeof useCreateChatReportMutation>;
export type CreateChatReportMutationResult = Apollo.MutationResult<CreateChatReportMutation>;
export type CreateChatReportMutationOptions = Apollo.BaseMutationOptions<CreateChatReportMutation, CreateChatReportMutationVariables>;
export const SendCommunityChatMessageDocument = gql`
    mutation SendCommunityChatMessage($messageDto: MessageDto!) {
  sendConversationMessage(messageDto: $messageDto) {
    ...CommunityChatMessage
    richContent {
      giphy {
        ...CommunityGiphyRichContent
      }
      reply {
        ...CommunityReplyRichContent
        richContent {
          giphy {
            ...CommunityGiphyRichContent
          }
          images {
            ...CommunityImagesRichContent
          }
        }
      }
      images {
        ...CommunityImagesRichContent
      }
      attachments {
        ...CommunityAttachmentRichContent
      }
      poll {
        ...CommunityPollRichContent
      }
    }
  }
}
    ${CommunityChatMessageFragmentDoc}
${CommunityGiphyRichContentFragmentDoc}
${CommunityReplyRichContentFragmentDoc}
${CommunityImagesRichContentFragmentDoc}
${CommunityAttachmentRichContentFragmentDoc}
${CommunityPollRichContentFragmentDoc}`;
export type SendCommunityChatMessageMutationFn = Apollo.MutationFunction<SendCommunityChatMessageMutation, SendCommunityChatMessageMutationVariables>;

/**
 * __useSendCommunityChatMessageMutation__
 *
 * To run a mutation, you first call `useSendCommunityChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCommunityChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCommunityChatMessageMutation, { data, loading, error }] = useSendCommunityChatMessageMutation({
 *   variables: {
 *      messageDto: // value for 'messageDto'
 *   },
 * });
 */
export function useSendCommunityChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendCommunityChatMessageMutation, SendCommunityChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCommunityChatMessageMutation, SendCommunityChatMessageMutationVariables>(SendCommunityChatMessageDocument, options);
      }
export type SendCommunityChatMessageMutationHookResult = ReturnType<typeof useSendCommunityChatMessageMutation>;
export type SendCommunityChatMessageMutationResult = Apollo.MutationResult<SendCommunityChatMessageMutation>;
export type SendCommunityChatMessageMutationOptions = Apollo.BaseMutationOptions<SendCommunityChatMessageMutation, SendCommunityChatMessageMutationVariables>;
export const CommunityUnlikeMessageDocument = gql`
    mutation CommunityUnlikeMessage($conversationId: ObjectId!, $messageId: ObjectId!, $userId: ObjectId!) {
  unlikeConversationMessage(
    likeMessageDto: {conversationId: $conversationId, messageId: $messageId, userId: $userId}
  ) {
    id
    likesCount
    likes
  }
}
    `;
export type CommunityUnlikeMessageMutationFn = Apollo.MutationFunction<CommunityUnlikeMessageMutation, CommunityUnlikeMessageMutationVariables>;

/**
 * __useCommunityUnlikeMessageMutation__
 *
 * To run a mutation, you first call `useCommunityUnlikeMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityUnlikeMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityUnlikeMessageMutation, { data, loading, error }] = useCommunityUnlikeMessageMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCommunityUnlikeMessageMutation(baseOptions?: Apollo.MutationHookOptions<CommunityUnlikeMessageMutation, CommunityUnlikeMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityUnlikeMessageMutation, CommunityUnlikeMessageMutationVariables>(CommunityUnlikeMessageDocument, options);
      }
export type CommunityUnlikeMessageMutationHookResult = ReturnType<typeof useCommunityUnlikeMessageMutation>;
export type CommunityUnlikeMessageMutationResult = Apollo.MutationResult<CommunityUnlikeMessageMutation>;
export type CommunityUnlikeMessageMutationOptions = Apollo.BaseMutationOptions<CommunityUnlikeMessageMutation, CommunityUnlikeMessageMutationVariables>;
export const CommunityUnpinMessageDocument = gql`
    mutation CommunityUnpinMessage($unpinMessageDTO: unpinMessageDTO!) {
  unpinMessageInConversation(unpinMessageDTO: $unpinMessageDTO) {
    pinnedMessages {
      id
    }
  }
}
    `;
export type CommunityUnpinMessageMutationFn = Apollo.MutationFunction<CommunityUnpinMessageMutation, CommunityUnpinMessageMutationVariables>;

/**
 * __useCommunityUnpinMessageMutation__
 *
 * To run a mutation, you first call `useCommunityUnpinMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityUnpinMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityUnpinMessageMutation, { data, loading, error }] = useCommunityUnpinMessageMutation({
 *   variables: {
 *      unpinMessageDTO: // value for 'unpinMessageDTO'
 *   },
 * });
 */
export function useCommunityUnpinMessageMutation(baseOptions?: Apollo.MutationHookOptions<CommunityUnpinMessageMutation, CommunityUnpinMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityUnpinMessageMutation, CommunityUnpinMessageMutationVariables>(CommunityUnpinMessageDocument, options);
      }
export type CommunityUnpinMessageMutationHookResult = ReturnType<typeof useCommunityUnpinMessageMutation>;
export type CommunityUnpinMessageMutationResult = Apollo.MutationResult<CommunityUnpinMessageMutation>;
export type CommunityUnpinMessageMutationOptions = Apollo.BaseMutationOptions<CommunityUnpinMessageMutation, CommunityUnpinMessageMutationVariables>;
export const GetCommunitiesDocument = gql`
    query GetCommunities($userId: String!) {
  getUser(id: $userId) {
    id
    communities {
      id
      title
      members {
        id
      }
      institutions {
        id
        logo
        slug
        name
        squareLogo
        colour
        privacyPolicyUrl
        isFeatureEnabled(feature: "community")
      }
      ...CommunitySettings
      ...NewsFeedGroups
    }
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}`;

/**
 * __useGetCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetCommunitiesQuery(baseOptions: Apollo.QueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(GetCommunitiesDocument, options);
      }
export function useGetCommunitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunitiesQuery, GetCommunitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunitiesQuery, GetCommunitiesQueryVariables>(GetCommunitiesDocument, options);
        }
export type GetCommunitiesQueryHookResult = ReturnType<typeof useGetCommunitiesQuery>;
export type GetCommunitiesLazyQueryHookResult = ReturnType<typeof useGetCommunitiesLazyQuery>;
export type GetCommunitiesQueryResult = Apollo.QueryResult<GetCommunitiesQuery, GetCommunitiesQueryVariables>;
export const GetUniversityFeaturesQueryDocument = gql`
    query GetUniversityFeaturesQuery($universitySlug: String) {
  university(slug: $universitySlug) {
    id
    config {
      features {
        enabledByUnibuddy
        enabledByUniversity
        universityCanToggleFeature
        feature {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetUniversityFeaturesQueryQuery__
 *
 * To run a query within a React component, call `useGetUniversityFeaturesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUniversityFeaturesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUniversityFeaturesQueryQuery({
 *   variables: {
 *      universitySlug: // value for 'universitySlug'
 *   },
 * });
 */
export function useGetUniversityFeaturesQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetUniversityFeaturesQueryQuery, GetUniversityFeaturesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUniversityFeaturesQueryQuery, GetUniversityFeaturesQueryQueryVariables>(GetUniversityFeaturesQueryDocument, options);
      }
export function useGetUniversityFeaturesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUniversityFeaturesQueryQuery, GetUniversityFeaturesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUniversityFeaturesQueryQuery, GetUniversityFeaturesQueryQueryVariables>(GetUniversityFeaturesQueryDocument, options);
        }
export type GetUniversityFeaturesQueryQueryHookResult = ReturnType<typeof useGetUniversityFeaturesQueryQuery>;
export type GetUniversityFeaturesQueryLazyQueryHookResult = ReturnType<typeof useGetUniversityFeaturesQueryLazyQuery>;
export type GetUniversityFeaturesQueryQueryResult = Apollo.QueryResult<GetUniversityFeaturesQueryQuery, GetUniversityFeaturesQueryQueryVariables>;
export const BlogPostsQueryDocument = gql`
    query BlogPostsQuery($topics: [String], $universityId: String, $universitySlug: String, $degrees: [String], $authorId: String, $limit: LimitedInt, $offset: Int, $title: String, $order: String, $randomise: Boolean) {
  allPostsInfo(
    topics: $topics
    universityId: $universityId
    universitySlug: $universitySlug
    degrees: $degrees
    authorId: $authorId
    safeLimit: $limit
    offset: $offset
    title: $title
    order: $order
    randomise: $randomise
  ) {
    blogPosts {
      ...BlogPostPreviewFragment
    }
  }
}
    ${BlogPostPreviewFragmentFragmentDoc}`;

/**
 * __useBlogPostsQueryQuery__
 *
 * To run a query within a React component, call `useBlogPostsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostsQueryQuery({
 *   variables: {
 *      topics: // value for 'topics'
 *      universityId: // value for 'universityId'
 *      universitySlug: // value for 'universitySlug'
 *      degrees: // value for 'degrees'
 *      authorId: // value for 'authorId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      title: // value for 'title'
 *      order: // value for 'order'
 *      randomise: // value for 'randomise'
 *   },
 * });
 */
export function useBlogPostsQueryQuery(baseOptions?: Apollo.QueryHookOptions<BlogPostsQueryQuery, BlogPostsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogPostsQueryQuery, BlogPostsQueryQueryVariables>(BlogPostsQueryDocument, options);
      }
export function useBlogPostsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogPostsQueryQuery, BlogPostsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogPostsQueryQuery, BlogPostsQueryQueryVariables>(BlogPostsQueryDocument, options);
        }
export type BlogPostsQueryQueryHookResult = ReturnType<typeof useBlogPostsQueryQuery>;
export type BlogPostsQueryLazyQueryHookResult = ReturnType<typeof useBlogPostsQueryLazyQuery>;
export type BlogPostsQueryQueryResult = Apollo.QueryResult<BlogPostsQueryQuery, BlogPostsQueryQueryVariables>;
export const CommunityCreatePrivateGroupDocument = gql`
    mutation CommunityCreatePrivateGroup($createCommunityConversationDTO: CreateCommunityConversationDTO!) {
  createCommunityConversation(
    CreateCommunityConversationDTO: $createCommunityConversationDTO
  ) {
    ...InboxConversation
  }
}
    ${InboxConversationFragmentDoc}`;
export type CommunityCreatePrivateGroupMutationFn = Apollo.MutationFunction<CommunityCreatePrivateGroupMutation, CommunityCreatePrivateGroupMutationVariables>;

/**
 * __useCommunityCreatePrivateGroupMutation__
 *
 * To run a mutation, you first call `useCommunityCreatePrivateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityCreatePrivateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityCreatePrivateGroupMutation, { data, loading, error }] = useCommunityCreatePrivateGroupMutation({
 *   variables: {
 *      createCommunityConversationDTO: // value for 'createCommunityConversationDTO'
 *   },
 * });
 */
export function useCommunityCreatePrivateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CommunityCreatePrivateGroupMutation, CommunityCreatePrivateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityCreatePrivateGroupMutation, CommunityCreatePrivateGroupMutationVariables>(CommunityCreatePrivateGroupDocument, options);
      }
export type CommunityCreatePrivateGroupMutationHookResult = ReturnType<typeof useCommunityCreatePrivateGroupMutation>;
export type CommunityCreatePrivateGroupMutationResult = Apollo.MutationResult<CommunityCreatePrivateGroupMutation>;
export type CommunityCreatePrivateGroupMutationOptions = Apollo.BaseMutationOptions<CommunityCreatePrivateGroupMutation, CommunityCreatePrivateGroupMutationVariables>;
export const LeaveCommunityChatGroupDocument = gql`
    mutation LeaveCommunityChatGroup($conversationId: String!) {
  leaveCommunityChatGroup(conversationId: $conversationId) {
    id
  }
}
    `;
export type LeaveCommunityChatGroupMutationFn = Apollo.MutationFunction<LeaveCommunityChatGroupMutation, LeaveCommunityChatGroupMutationVariables>;

/**
 * __useLeaveCommunityChatGroupMutation__
 *
 * To run a mutation, you first call `useLeaveCommunityChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCommunityChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCommunityChatGroupMutation, { data, loading, error }] = useLeaveCommunityChatGroupMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useLeaveCommunityChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<LeaveCommunityChatGroupMutation, LeaveCommunityChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveCommunityChatGroupMutation, LeaveCommunityChatGroupMutationVariables>(LeaveCommunityChatGroupDocument, options);
      }
export type LeaveCommunityChatGroupMutationHookResult = ReturnType<typeof useLeaveCommunityChatGroupMutation>;
export type LeaveCommunityChatGroupMutationResult = Apollo.MutationResult<LeaveCommunityChatGroupMutation>;
export type LeaveCommunityChatGroupMutationOptions = Apollo.BaseMutationOptions<LeaveCommunityChatGroupMutation, LeaveCommunityChatGroupMutationVariables>;
export const CommunityInboxDocument = gql`
    query CommunityInbox($communityId: String!, $offsetPagination: OffsetPagination) {
  getUsersCommunityConversations(
    communityId: $communityId
    offsetPagination: $offsetPagination
  ) @persist {
    ...InboxConversation
  }
}
    ${InboxConversationFragmentDoc}`;

/**
 * __useCommunityInboxQuery__
 *
 * To run a query within a React component, call `useCommunityInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityInboxQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      offsetPagination: // value for 'offsetPagination'
 *   },
 * });
 */
export function useCommunityInboxQuery(baseOptions: Apollo.QueryHookOptions<CommunityInboxQuery, CommunityInboxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityInboxQuery, CommunityInboxQueryVariables>(CommunityInboxDocument, options);
      }
export function useCommunityInboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityInboxQuery, CommunityInboxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityInboxQuery, CommunityInboxQueryVariables>(CommunityInboxDocument, options);
        }
export type CommunityInboxQueryHookResult = ReturnType<typeof useCommunityInboxQuery>;
export type CommunityInboxLazyQueryHookResult = ReturnType<typeof useCommunityInboxLazyQuery>;
export type CommunityInboxQueryResult = Apollo.QueryResult<CommunityInboxQuery, CommunityInboxQueryVariables>;
export const GetInviteLinkInfoDocument = gql`
    query GetInviteLinkInfo($inviteLinkId: String!) {
  getInviteLinkInfo(inviteLinkId: $inviteLinkId) {
    id
    valid
    communityId
    communityTitle
    universityName
    squareLogo
    universitySlug
    privacyPolicyUrl
  }
}
    `;

/**
 * __useGetInviteLinkInfoQuery__
 *
 * To run a query within a React component, call `useGetInviteLinkInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInviteLinkInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInviteLinkInfoQuery({
 *   variables: {
 *      inviteLinkId: // value for 'inviteLinkId'
 *   },
 * });
 */
export function useGetInviteLinkInfoQuery(baseOptions: Apollo.QueryHookOptions<GetInviteLinkInfoQuery, GetInviteLinkInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviteLinkInfoQuery, GetInviteLinkInfoQueryVariables>(GetInviteLinkInfoDocument, options);
      }
export function useGetInviteLinkInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviteLinkInfoQuery, GetInviteLinkInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviteLinkInfoQuery, GetInviteLinkInfoQueryVariables>(GetInviteLinkInfoDocument, options);
        }
export type GetInviteLinkInfoQueryHookResult = ReturnType<typeof useGetInviteLinkInfoQuery>;
export type GetInviteLinkInfoLazyQueryHookResult = ReturnType<typeof useGetInviteLinkInfoLazyQuery>;
export type GetInviteLinkInfoQueryResult = Apollo.QueryResult<GetInviteLinkInfoQuery, GetInviteLinkInfoQueryVariables>;
export const JoinCommunityByInviteLinkDocument = gql`
    mutation JoinCommunityByInviteLink($communityId: String!) {
  joinCommunityByInviteLink(communityId: $communityId) {
    alreadyJoined
    joined
    community {
      id
      title
      members {
        id
      }
      institutions {
        id
        logo
        slug
        name
        squareLogo
        colour
        privacyPolicyUrl
      }
      ...CommunitySettings
      ...NewsFeedGroups
    }
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}`;
export type JoinCommunityByInviteLinkMutationFn = Apollo.MutationFunction<JoinCommunityByInviteLinkMutation, JoinCommunityByInviteLinkMutationVariables>;

/**
 * __useJoinCommunityByInviteLinkMutation__
 *
 * To run a mutation, you first call `useJoinCommunityByInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCommunityByInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCommunityByInviteLinkMutation, { data, loading, error }] = useJoinCommunityByInviteLinkMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useJoinCommunityByInviteLinkMutation(baseOptions?: Apollo.MutationHookOptions<JoinCommunityByInviteLinkMutation, JoinCommunityByInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinCommunityByInviteLinkMutation, JoinCommunityByInviteLinkMutationVariables>(JoinCommunityByInviteLinkDocument, options);
      }
export type JoinCommunityByInviteLinkMutationHookResult = ReturnType<typeof useJoinCommunityByInviteLinkMutation>;
export type JoinCommunityByInviteLinkMutationResult = Apollo.MutationResult<JoinCommunityByInviteLinkMutation>;
export type JoinCommunityByInviteLinkMutationOptions = Apollo.BaseMutationOptions<JoinCommunityByInviteLinkMutation, JoinCommunityByInviteLinkMutationVariables>;
export const GetJoinGroupDataDocument = gql`
    query GetJoinGroupData($communityId: String!, $conversationId: String!) {
  getCommunityForUser(communityId: $communityId) {
    id
    title
    description
    ...CommunitySettings
    ...NewsFeedGroups
    institutions {
      id
      logo
      slug
      name
      squareLogo
      colour
      privacyPolicyUrl
    }
  }
  getCommunityConversation(id: $conversationId) {
    id
    communityChatTitle
    communityChatDescription
    communityChatDynamicLink
    ...GroupTypes
  }
}
    ${CommunitySettingsFragmentDoc}
${NewsFeedGroupsFragmentDoc}
${GroupTypesFragmentDoc}`;

/**
 * __useGetJoinGroupDataQuery__
 *
 * To run a query within a React component, call `useGetJoinGroupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJoinGroupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJoinGroupDataQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetJoinGroupDataQuery(baseOptions: Apollo.QueryHookOptions<GetJoinGroupDataQuery, GetJoinGroupDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJoinGroupDataQuery, GetJoinGroupDataQueryVariables>(GetJoinGroupDataDocument, options);
      }
export function useGetJoinGroupDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJoinGroupDataQuery, GetJoinGroupDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJoinGroupDataQuery, GetJoinGroupDataQueryVariables>(GetJoinGroupDataDocument, options);
        }
export type GetJoinGroupDataQueryHookResult = ReturnType<typeof useGetJoinGroupDataQuery>;
export type GetJoinGroupDataLazyQueryHookResult = ReturnType<typeof useGetJoinGroupDataLazyQuery>;
export type GetJoinGroupDataQueryResult = Apollo.QueryResult<GetJoinGroupDataQuery, GetJoinGroupDataQueryVariables>;
export const GetAreaOfStudyDocument = gql`
    query GetAreaOfStudy {
  getUserProfile {
    areaOfStudy {
      ...Degree
    }
  }
}
    ${DegreeFragmentDoc}`;

/**
 * __useGetAreaOfStudyQuery__
 *
 * To run a query within a React component, call `useGetAreaOfStudyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaOfStudyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaOfStudyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAreaOfStudyQuery(baseOptions?: Apollo.QueryHookOptions<GetAreaOfStudyQuery, GetAreaOfStudyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAreaOfStudyQuery, GetAreaOfStudyQueryVariables>(GetAreaOfStudyDocument, options);
      }
export function useGetAreaOfStudyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAreaOfStudyQuery, GetAreaOfStudyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAreaOfStudyQuery, GetAreaOfStudyQueryVariables>(GetAreaOfStudyDocument, options);
        }
export type GetAreaOfStudyQueryHookResult = ReturnType<typeof useGetAreaOfStudyQuery>;
export type GetAreaOfStudyLazyQueryHookResult = ReturnType<typeof useGetAreaOfStudyLazyQuery>;
export type GetAreaOfStudyQueryResult = Apollo.QueryResult<GetAreaOfStudyQuery, GetAreaOfStudyQueryVariables>;
export const GetCommunityUserSearchDocument = gql`
    query GetCommunityUserSearch($pagination: OffsetPagination!, $filterUsers: FilterUsersDTO!) {
  communityUserSearch(pagination: $pagination, filterUsersDTO: $filterUsers) {
    result {
      id
      firstName
      lastName
      accountRole
      profilePhoto
      ...Country
      ...CommunityProfile
    }
    totalUsers
  }
}
    ${CountryFragmentDoc}
${CommunityProfileFragmentDoc}`;

/**
 * __useGetCommunityUserSearchQuery__
 *
 * To run a query within a React component, call `useGetCommunityUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityUserSearchQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filterUsers: // value for 'filterUsers'
 *   },
 * });
 */
export function useGetCommunityUserSearchQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityUserSearchQuery, GetCommunityUserSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityUserSearchQuery, GetCommunityUserSearchQueryVariables>(GetCommunityUserSearchDocument, options);
      }
export function useGetCommunityUserSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityUserSearchQuery, GetCommunityUserSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityUserSearchQuery, GetCommunityUserSearchQueryVariables>(GetCommunityUserSearchDocument, options);
        }
export type GetCommunityUserSearchQueryHookResult = ReturnType<typeof useGetCommunityUserSearchQuery>;
export type GetCommunityUserSearchLazyQueryHookResult = ReturnType<typeof useGetCommunityUserSearchLazyQuery>;
export type GetCommunityUserSearchQueryResult = Apollo.QueryResult<GetCommunityUserSearchQuery, GetCommunityUserSearchQueryVariables>;
export const GetRecommendedUsersDocument = gql`
    query getRecommendedUsers($communityId: String!, $limit: Int, $matchingCriterion: MatchingCriteriaEnum) {
  getRecommendedUsers(
    communityId: $communityId
    limit: $limit
    matchingCriterion: $matchingCriterion
  ) {
    ...RecommendedUsers
  }
}
    ${RecommendedUsersFragmentDoc}`;

/**
 * __useGetRecommendedUsersQuery__
 *
 * To run a query within a React component, call `useGetRecommendedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedUsersQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      limit: // value for 'limit'
 *      matchingCriterion: // value for 'matchingCriterion'
 *   },
 * });
 */
export function useGetRecommendedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedUsersQuery, GetRecommendedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedUsersQuery, GetRecommendedUsersQueryVariables>(GetRecommendedUsersDocument, options);
      }
export function useGetRecommendedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedUsersQuery, GetRecommendedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedUsersQuery, GetRecommendedUsersQueryVariables>(GetRecommendedUsersDocument, options);
        }
export type GetRecommendedUsersQueryHookResult = ReturnType<typeof useGetRecommendedUsersQuery>;
export type GetRecommendedUsersLazyQueryHookResult = ReturnType<typeof useGetRecommendedUsersLazyQuery>;
export type GetRecommendedUsersQueryResult = Apollo.QueryResult<GetRecommendedUsersQuery, GetRecommendedUsersQueryVariables>;
export const GetRecommendedUsersByNationalityCriterionDocument = gql`
    query getRecommendedUsersByNationalityCriterion($communityId: String!, $limit: Int!, $nationalityCriterion: MatchingCriteriaEnum!) {
  nationalityRecommendedUsers: getRecommendedUsers(
    communityId: $communityId
    limit: $limit
    matchingCriterion: $nationalityCriterion
  ) {
    ...RecommendedUsers
  }
}
    ${RecommendedUsersFragmentDoc}`;

/**
 * __useGetRecommendedUsersByNationalityCriterionQuery__
 *
 * To run a query within a React component, call `useGetRecommendedUsersByNationalityCriterionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedUsersByNationalityCriterionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedUsersByNationalityCriterionQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      limit: // value for 'limit'
 *      nationalityCriterion: // value for 'nationalityCriterion'
 *   },
 * });
 */
export function useGetRecommendedUsersByNationalityCriterionQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedUsersByNationalityCriterionQuery, GetRecommendedUsersByNationalityCriterionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedUsersByNationalityCriterionQuery, GetRecommendedUsersByNationalityCriterionQueryVariables>(GetRecommendedUsersByNationalityCriterionDocument, options);
      }
export function useGetRecommendedUsersByNationalityCriterionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedUsersByNationalityCriterionQuery, GetRecommendedUsersByNationalityCriterionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedUsersByNationalityCriterionQuery, GetRecommendedUsersByNationalityCriterionQueryVariables>(GetRecommendedUsersByNationalityCriterionDocument, options);
        }
export type GetRecommendedUsersByNationalityCriterionQueryHookResult = ReturnType<typeof useGetRecommendedUsersByNationalityCriterionQuery>;
export type GetRecommendedUsersByNationalityCriterionLazyQueryHookResult = ReturnType<typeof useGetRecommendedUsersByNationalityCriterionLazyQuery>;
export type GetRecommendedUsersByNationalityCriterionQueryResult = Apollo.QueryResult<GetRecommendedUsersByNationalityCriterionQuery, GetRecommendedUsersByNationalityCriterionQueryVariables>;
export const GetRecommendedUsersByCriterionDocument = gql`
    query getRecommendedUsersByCriterion($communityId: String!, $limit: Int!, $matchingCriterion: MatchingCriteriaEnum) {
  recommendedUsers: getRecommendedUsers(
    communityId: $communityId
    limit: $limit
    matchingCriterion: $matchingCriterion
  ) {
    ...RecommendedUsers
  }
}
    ${RecommendedUsersFragmentDoc}`;

/**
 * __useGetRecommendedUsersByCriterionQuery__
 *
 * To run a query within a React component, call `useGetRecommendedUsersByCriterionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedUsersByCriterionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedUsersByCriterionQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      limit: // value for 'limit'
 *      matchingCriterion: // value for 'matchingCriterion'
 *   },
 * });
 */
export function useGetRecommendedUsersByCriterionQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedUsersByCriterionQuery, GetRecommendedUsersByCriterionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedUsersByCriterionQuery, GetRecommendedUsersByCriterionQueryVariables>(GetRecommendedUsersByCriterionDocument, options);
      }
export function useGetRecommendedUsersByCriterionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedUsersByCriterionQuery, GetRecommendedUsersByCriterionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedUsersByCriterionQuery, GetRecommendedUsersByCriterionQueryVariables>(GetRecommendedUsersByCriterionDocument, options);
        }
export type GetRecommendedUsersByCriterionQueryHookResult = ReturnType<typeof useGetRecommendedUsersByCriterionQuery>;
export type GetRecommendedUsersByCriterionLazyQueryHookResult = ReturnType<typeof useGetRecommendedUsersByCriterionLazyQuery>;
export type GetRecommendedUsersByCriterionQueryResult = Apollo.QueryResult<GetRecommendedUsersByCriterionQuery, GetRecommendedUsersByCriterionQueryVariables>;
export const GetRecommendedGroupsDocument = gql`
    query GetRecommendedGroups($communityId: String!) {
  getRecommendedConversations(communityId: $communityId) {
    id
    title: communityChatTitle
    description: communityChatDescription
    members: communityChatMembers(offsetPagination: {offset: 0, limit: 999}) {
      id
    }
    membersWithProfilePhoto: communityChatMembers(
      offsetPagination: {offset: 0, limit: 15}
    ) {
      id
      communityProfile {
        id
        profilePhoto
      }
    }
  }
}
    `;

/**
 * __useGetRecommendedGroupsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedGroupsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetRecommendedGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendedGroupsQuery, GetRecommendedGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendedGroupsQuery, GetRecommendedGroupsQueryVariables>(GetRecommendedGroupsDocument, options);
      }
export function useGetRecommendedGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendedGroupsQuery, GetRecommendedGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendedGroupsQuery, GetRecommendedGroupsQueryVariables>(GetRecommendedGroupsDocument, options);
        }
export type GetRecommendedGroupsQueryHookResult = ReturnType<typeof useGetRecommendedGroupsQuery>;
export type GetRecommendedGroupsLazyQueryHookResult = ReturnType<typeof useGetRecommendedGroupsLazyQuery>;
export type GetRecommendedGroupsQueryResult = Apollo.QueryResult<GetRecommendedGroupsQuery, GetRecommendedGroupsQueryVariables>;
export const GetTopicsAndUserInterestsDocument = gql`
    query GetTopicsAndUserInterests {
  topics {
    id
    name
    description
  }
  getUserProfile {
    id
    userId
    interests {
      id
      type
    }
  }
}
    `;

/**
 * __useGetTopicsAndUserInterestsQuery__
 *
 * To run a query within a React component, call `useGetTopicsAndUserInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsAndUserInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsAndUserInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicsAndUserInterestsQuery(baseOptions?: Apollo.QueryHookOptions<GetTopicsAndUserInterestsQuery, GetTopicsAndUserInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicsAndUserInterestsQuery, GetTopicsAndUserInterestsQueryVariables>(GetTopicsAndUserInterestsDocument, options);
      }
export function useGetTopicsAndUserInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicsAndUserInterestsQuery, GetTopicsAndUserInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicsAndUserInterestsQuery, GetTopicsAndUserInterestsQueryVariables>(GetTopicsAndUserInterestsDocument, options);
        }
export type GetTopicsAndUserInterestsQueryHookResult = ReturnType<typeof useGetTopicsAndUserInterestsQuery>;
export type GetTopicsAndUserInterestsLazyQueryHookResult = ReturnType<typeof useGetTopicsAndUserInterestsLazyQuery>;
export type GetTopicsAndUserInterestsQueryResult = Apollo.QueryResult<GetTopicsAndUserInterestsQuery, GetTopicsAndUserInterestsQueryVariables>;
export const SetUserProfileDocument = gql`
    mutation SetUserProfile($data: UpdateUserProfile!) {
  setUserProfile(data: $data) {
    id
    userId
    interests {
      id
      type
    }
    profilePhoto
    areaOfStudy {
      id
      name
    }
    bio
    pronouns
    ...Housing
  }
}
    ${HousingFragmentDoc}`;
export type SetUserProfileMutationFn = Apollo.MutationFunction<SetUserProfileMutation, SetUserProfileMutationVariables>;

/**
 * __useSetUserProfileMutation__
 *
 * To run a mutation, you first call `useSetUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserProfileMutation, { data, loading, error }] = useSetUserProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<SetUserProfileMutation, SetUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserProfileMutation, SetUserProfileMutationVariables>(SetUserProfileDocument, options);
      }
export type SetUserProfileMutationHookResult = ReturnType<typeof useSetUserProfileMutation>;
export type SetUserProfileMutationResult = Apollo.MutationResult<SetUserProfileMutation>;
export type SetUserProfileMutationOptions = Apollo.BaseMutationOptions<SetUserProfileMutation, SetUserProfileMutationVariables>;
export const AddToFeatureExperimentDocument = gql`
    mutation AddToFeatureExperiment($metadata: String!, $experimentName: String!) {
  addToFeatureExperiment(metadata: $metadata, experimentName: $experimentName)
}
    `;
export type AddToFeatureExperimentMutationFn = Apollo.MutationFunction<AddToFeatureExperimentMutation, AddToFeatureExperimentMutationVariables>;

/**
 * __useAddToFeatureExperimentMutation__
 *
 * To run a mutation, you first call `useAddToFeatureExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToFeatureExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToFeatureExperimentMutation, { data, loading, error }] = useAddToFeatureExperimentMutation({
 *   variables: {
 *      metadata: // value for 'metadata'
 *      experimentName: // value for 'experimentName'
 *   },
 * });
 */
export function useAddToFeatureExperimentMutation(baseOptions?: Apollo.MutationHookOptions<AddToFeatureExperimentMutation, AddToFeatureExperimentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToFeatureExperimentMutation, AddToFeatureExperimentMutationVariables>(AddToFeatureExperimentDocument, options);
      }
export type AddToFeatureExperimentMutationHookResult = ReturnType<typeof useAddToFeatureExperimentMutation>;
export type AddToFeatureExperimentMutationResult = Apollo.MutationResult<AddToFeatureExperimentMutation>;
export type AddToFeatureExperimentMutationOptions = Apollo.BaseMutationOptions<AddToFeatureExperimentMutation, AddToFeatureExperimentMutationVariables>;
export const RequestPublicGroupDocument = gql`
    mutation RequestPublicGroup($requestPublicGroupInput: CreateRequestedPublicGroupInput!) {
  requestPublicGroup(requestPublicGroupInput: $requestPublicGroupInput) {
    id
  }
}
    `;
export type RequestPublicGroupMutationFn = Apollo.MutationFunction<RequestPublicGroupMutation, RequestPublicGroupMutationVariables>;

/**
 * __useRequestPublicGroupMutation__
 *
 * To run a mutation, you first call `useRequestPublicGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPublicGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPublicGroupMutation, { data, loading, error }] = useRequestPublicGroupMutation({
 *   variables: {
 *      requestPublicGroupInput: // value for 'requestPublicGroupInput'
 *   },
 * });
 */
export function useRequestPublicGroupMutation(baseOptions?: Apollo.MutationHookOptions<RequestPublicGroupMutation, RequestPublicGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPublicGroupMutation, RequestPublicGroupMutationVariables>(RequestPublicGroupDocument, options);
      }
export type RequestPublicGroupMutationHookResult = ReturnType<typeof useRequestPublicGroupMutation>;
export type RequestPublicGroupMutationResult = Apollo.MutationResult<RequestPublicGroupMutation>;
export type RequestPublicGroupMutationOptions = Apollo.BaseMutationOptions<RequestPublicGroupMutation, RequestPublicGroupMutationVariables>;
export const AllCommunityChatGroupsDocument = gql`
    query AllCommunityChatGroups($communityId: String!) {
  getChatGroupsForCommunity(communityId: $communityId) {
    id
    title
    description
    conversation {
      id
    }
    members {
      id
      profilePhoto
    }
  }
}
    `;

/**
 * __useAllCommunityChatGroupsQuery__
 *
 * To run a query within a React component, call `useAllCommunityChatGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCommunityChatGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCommunityChatGroupsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useAllCommunityChatGroupsQuery(baseOptions: Apollo.QueryHookOptions<AllCommunityChatGroupsQuery, AllCommunityChatGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCommunityChatGroupsQuery, AllCommunityChatGroupsQueryVariables>(AllCommunityChatGroupsDocument, options);
      }
export function useAllCommunityChatGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCommunityChatGroupsQuery, AllCommunityChatGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCommunityChatGroupsQuery, AllCommunityChatGroupsQueryVariables>(AllCommunityChatGroupsDocument, options);
        }
export type AllCommunityChatGroupsQueryHookResult = ReturnType<typeof useAllCommunityChatGroupsQuery>;
export type AllCommunityChatGroupsLazyQueryHookResult = ReturnType<typeof useAllCommunityChatGroupsLazyQuery>;
export type AllCommunityChatGroupsQueryResult = Apollo.QueryResult<AllCommunityChatGroupsQuery, AllCommunityChatGroupsQueryVariables>;
export const GetCommunityConversationsGroupsDocument = gql`
    query GetCommunityConversationsGroups($communityId: String!, $offsetPagination: OffsetPagination!) {
  getCommunityConversations(communityId: $communityId) {
    communityChatMembers(offsetPagination: $offsetPagination) {
      id
      communityProfile {
        profilePhoto
      }
    }
    totalMemberCount
    id
    deleted
    communityId
    communityChatTitle
    communityChatDescription
    labels {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCommunityConversationsGroupsQuery__
 *
 * To run a query within a React component, call `useGetCommunityConversationsGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityConversationsGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityConversationsGroupsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      offsetPagination: // value for 'offsetPagination'
 *   },
 * });
 */
export function useGetCommunityConversationsGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityConversationsGroupsQuery, GetCommunityConversationsGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityConversationsGroupsQuery, GetCommunityConversationsGroupsQueryVariables>(GetCommunityConversationsGroupsDocument, options);
      }
export function useGetCommunityConversationsGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityConversationsGroupsQuery, GetCommunityConversationsGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityConversationsGroupsQuery, GetCommunityConversationsGroupsQueryVariables>(GetCommunityConversationsGroupsDocument, options);
        }
export type GetCommunityConversationsGroupsQueryHookResult = ReturnType<typeof useGetCommunityConversationsGroupsQuery>;
export type GetCommunityConversationsGroupsLazyQueryHookResult = ReturnType<typeof useGetCommunityConversationsGroupsLazyQuery>;
export type GetCommunityConversationsGroupsQueryResult = Apollo.QueryResult<GetCommunityConversationsGroupsQuery, GetCommunityConversationsGroupsQueryVariables>;
export const JoinCommunityConversationDocument = gql`
    mutation JoinCommunityConversation($id: String!) {
  joinCommunityConversation(conversationId: $id) {
    ...InboxConversation
  }
}
    ${InboxConversationFragmentDoc}`;
export type JoinCommunityConversationMutationFn = Apollo.MutationFunction<JoinCommunityConversationMutation, JoinCommunityConversationMutationVariables>;

/**
 * __useJoinCommunityConversationMutation__
 *
 * To run a mutation, you first call `useJoinCommunityConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinCommunityConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinCommunityConversationMutation, { data, loading, error }] = useJoinCommunityConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJoinCommunityConversationMutation(baseOptions?: Apollo.MutationHookOptions<JoinCommunityConversationMutation, JoinCommunityConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinCommunityConversationMutation, JoinCommunityConversationMutationVariables>(JoinCommunityConversationDocument, options);
      }
export type JoinCommunityConversationMutationHookResult = ReturnType<typeof useJoinCommunityConversationMutation>;
export type JoinCommunityConversationMutationResult = Apollo.MutationResult<JoinCommunityConversationMutation>;
export type JoinCommunityConversationMutationOptions = Apollo.BaseMutationOptions<JoinCommunityConversationMutation, JoinCommunityConversationMutationVariables>;
export const GetAllDegreesDocument = gql`
    query GetAllDegrees($universitySlug: String) {
  allDegrees(universitySlug: $universitySlug) {
    ...Degree
  }
  getUserProfile {
    id
    areaOfStudy {
      ...Degree
    }
  }
}
    ${DegreeFragmentDoc}`;

/**
 * __useGetAllDegreesQuery__
 *
 * To run a query within a React component, call `useGetAllDegreesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDegreesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDegreesQuery({
 *   variables: {
 *      universitySlug: // value for 'universitySlug'
 *   },
 * });
 */
export function useGetAllDegreesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDegreesQuery, GetAllDegreesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDegreesQuery, GetAllDegreesQueryVariables>(GetAllDegreesDocument, options);
      }
export function useGetAllDegreesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDegreesQuery, GetAllDegreesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDegreesQuery, GetAllDegreesQueryVariables>(GetAllDegreesDocument, options);
        }
export type GetAllDegreesQueryHookResult = ReturnType<typeof useGetAllDegreesQuery>;
export type GetAllDegreesLazyQueryHookResult = ReturnType<typeof useGetAllDegreesLazyQuery>;
export type GetAllDegreesQueryResult = Apollo.QueryResult<GetAllDegreesQuery, GetAllDegreesQueryVariables>;
export const GetExperimentalTodosDocument = gql`
    query GetExperimentalTodos {
  getExperimentalTodos
}
    `;

/**
 * __useGetExperimentalTodosQuery__
 *
 * To run a query within a React component, call `useGetExperimentalTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExperimentalTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExperimentalTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExperimentalTodosQuery(baseOptions?: Apollo.QueryHookOptions<GetExperimentalTodosQuery, GetExperimentalTodosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExperimentalTodosQuery, GetExperimentalTodosQueryVariables>(GetExperimentalTodosDocument, options);
      }
export function useGetExperimentalTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExperimentalTodosQuery, GetExperimentalTodosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExperimentalTodosQuery, GetExperimentalTodosQueryVariables>(GetExperimentalTodosDocument, options);
        }
export type GetExperimentalTodosQueryHookResult = ReturnType<typeof useGetExperimentalTodosQuery>;
export type GetExperimentalTodosLazyQueryHookResult = ReturnType<typeof useGetExperimentalTodosLazyQuery>;
export type GetExperimentalTodosQueryResult = Apollo.QueryResult<GetExperimentalTodosQuery, GetExperimentalTodosQueryVariables>;
export const GetCommunityForUserDocument = gql`
    query GetCommunityForUser($communityId: String!) {
  getCommunityForUser(communityId: $communityId) {
    id
    title
    description
    members {
      id
    }
    institutions {
      id
      logo
      slug
      name
      squareLogo
      colour
      privacyPolicyUrl
    }
  }
}
    `;

/**
 * __useGetCommunityForUserQuery__
 *
 * To run a query within a React component, call `useGetCommunityForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityForUserQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityForUserQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityForUserQuery, GetCommunityForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityForUserQuery, GetCommunityForUserQueryVariables>(GetCommunityForUserDocument, options);
      }
export function useGetCommunityForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityForUserQuery, GetCommunityForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityForUserQuery, GetCommunityForUserQueryVariables>(GetCommunityForUserDocument, options);
        }
export type GetCommunityForUserQueryHookResult = ReturnType<typeof useGetCommunityForUserQuery>;
export type GetCommunityForUserLazyQueryHookResult = ReturnType<typeof useGetCommunityForUserLazyQuery>;
export type GetCommunityForUserQueryResult = Apollo.QueryResult<GetCommunityForUserQuery, GetCommunityForUserQueryVariables>;
export const GetLabelsDocument = gql`
    query GetLabels {
  getLabels {
    id
    name
  }
}
    `;

/**
 * __useGetLabelsQuery__
 *
 * To run a query within a React component, call `useGetLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLabelsQuery(baseOptions?: Apollo.QueryHookOptions<GetLabelsQuery, GetLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelsQuery, GetLabelsQueryVariables>(GetLabelsDocument, options);
      }
export function useGetLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsQuery, GetLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelsQuery, GetLabelsQueryVariables>(GetLabelsDocument, options);
        }
export type GetLabelsQueryHookResult = ReturnType<typeof useGetLabelsQuery>;
export type GetLabelsLazyQueryHookResult = ReturnType<typeof useGetLabelsLazyQuery>;
export type GetLabelsQueryResult = Apollo.QueryResult<GetLabelsQuery, GetLabelsQueryVariables>;
export const UniversityQuestionsDocument = gql`
    query UniversityQuestions($confidenceRatingQuestionInput: UniversityQuestionByIdInput!, $feelConfidentQuestionInput: UniversityQuestionByIdInput!) {
  question1: universityQuestionById(
    universityQuestionByIdInput: $confidenceRatingQuestionInput
  ) {
    id
    active
    text
    options {
      text
      id
    }
  }
  question2: universityQuestionById(
    universityQuestionByIdInput: $feelConfidentQuestionInput
  ) {
    id
    active
    text
    options {
      text
      id
    }
  }
}
    `;

/**
 * __useUniversityQuestionsQuery__
 *
 * To run a query within a React component, call `useUniversityQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUniversityQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUniversityQuestionsQuery({
 *   variables: {
 *      confidenceRatingQuestionInput: // value for 'confidenceRatingQuestionInput'
 *      feelConfidentQuestionInput: // value for 'feelConfidentQuestionInput'
 *   },
 * });
 */
export function useUniversityQuestionsQuery(baseOptions: Apollo.QueryHookOptions<UniversityQuestionsQuery, UniversityQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UniversityQuestionsQuery, UniversityQuestionsQueryVariables>(UniversityQuestionsDocument, options);
      }
export function useUniversityQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UniversityQuestionsQuery, UniversityQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UniversityQuestionsQuery, UniversityQuestionsQueryVariables>(UniversityQuestionsDocument, options);
        }
export type UniversityQuestionsQueryHookResult = ReturnType<typeof useUniversityQuestionsQuery>;
export type UniversityQuestionsLazyQueryHookResult = ReturnType<typeof useUniversityQuestionsLazyQuery>;
export type UniversityQuestionsQueryResult = Apollo.QueryResult<UniversityQuestionsQuery, UniversityQuestionsQueryVariables>;
export const SaveUniversityQuestionAnswerDocument = gql`
    mutation SaveUniversityQuestionAnswer($saveUniversityQuestionAnswerInput: SaveUniversityQuestionAnswerInput!) {
  saveUniversityQuestionAnswer(
    saveUniversityQuestionAnswerInput: $saveUniversityQuestionAnswerInput
  ) {
    id
  }
}
    `;
export type SaveUniversityQuestionAnswerMutationFn = Apollo.MutationFunction<SaveUniversityQuestionAnswerMutation, SaveUniversityQuestionAnswerMutationVariables>;

/**
 * __useSaveUniversityQuestionAnswerMutation__
 *
 * To run a mutation, you first call `useSaveUniversityQuestionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUniversityQuestionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUniversityQuestionAnswerMutation, { data, loading, error }] = useSaveUniversityQuestionAnswerMutation({
 *   variables: {
 *      saveUniversityQuestionAnswerInput: // value for 'saveUniversityQuestionAnswerInput'
 *   },
 * });
 */
export function useSaveUniversityQuestionAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SaveUniversityQuestionAnswerMutation, SaveUniversityQuestionAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUniversityQuestionAnswerMutation, SaveUniversityQuestionAnswerMutationVariables>(SaveUniversityQuestionAnswerDocument, options);
      }
export type SaveUniversityQuestionAnswerMutationHookResult = ReturnType<typeof useSaveUniversityQuestionAnswerMutation>;
export type SaveUniversityQuestionAnswerMutationResult = Apollo.MutationResult<SaveUniversityQuestionAnswerMutation>;
export type SaveUniversityQuestionAnswerMutationOptions = Apollo.BaseMutationOptions<SaveUniversityQuestionAnswerMutation, SaveUniversityQuestionAnswerMutationVariables>;
export const EnrolledUniversityQuestionsDocument = gql`
    query EnrolledUniversityQuestions($haveEnrolledQuestionInput: UniversityQuestionByIdInput!, $enrolledCommunityInfluenceRatingQuestionInput: UniversityQuestionByIdInput!, $enrolledMoreInfo: UniversityQuestionByIdInput!, $enroledWhatInfluencedDecision: UniversityQuestionByIdInput!) {
  question1: universityQuestionById(
    universityQuestionByIdInput: $haveEnrolledQuestionInput
  ) {
    id
    active
    text
    options {
      text
      id
    }
  }
  question2: universityQuestionById(
    universityQuestionByIdInput: $enrolledCommunityInfluenceRatingQuestionInput
  ) {
    id
    active
    text
    options {
      text
      id
    }
  }
  question3: universityQuestionById(
    universityQuestionByIdInput: $enrolledMoreInfo
  ) {
    id
    active
    text
    options {
      text
      id
    }
  }
  question4: universityQuestionById(
    universityQuestionByIdInput: $enroledWhatInfluencedDecision
  ) {
    id
    active
    text
    options {
      text
      id
    }
  }
}
    `;

/**
 * __useEnrolledUniversityQuestionsQuery__
 *
 * To run a query within a React component, call `useEnrolledUniversityQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrolledUniversityQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrolledUniversityQuestionsQuery({
 *   variables: {
 *      haveEnrolledQuestionInput: // value for 'haveEnrolledQuestionInput'
 *      enrolledCommunityInfluenceRatingQuestionInput: // value for 'enrolledCommunityInfluenceRatingQuestionInput'
 *      enrolledMoreInfo: // value for 'enrolledMoreInfo'
 *      enroledWhatInfluencedDecision: // value for 'enroledWhatInfluencedDecision'
 *   },
 * });
 */
export function useEnrolledUniversityQuestionsQuery(baseOptions: Apollo.QueryHookOptions<EnrolledUniversityQuestionsQuery, EnrolledUniversityQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrolledUniversityQuestionsQuery, EnrolledUniversityQuestionsQueryVariables>(EnrolledUniversityQuestionsDocument, options);
      }
export function useEnrolledUniversityQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrolledUniversityQuestionsQuery, EnrolledUniversityQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrolledUniversityQuestionsQuery, EnrolledUniversityQuestionsQueryVariables>(EnrolledUniversityQuestionsDocument, options);
        }
export type EnrolledUniversityQuestionsQueryHookResult = ReturnType<typeof useEnrolledUniversityQuestionsQuery>;
export type EnrolledUniversityQuestionsLazyQueryHookResult = ReturnType<typeof useEnrolledUniversityQuestionsLazyQuery>;
export type EnrolledUniversityQuestionsQueryResult = Apollo.QueryResult<EnrolledUniversityQuestionsQuery, EnrolledUniversityQuestionsQueryVariables>;
export const SendCommunityFeedbackDocument = gql`
    mutation SendCommunityFeedback($communityId: String!, $universitySlug: String!, $question: String!, $rating: Float!, $feedback: String) {
  saveSatisfactionSurvey(
    SatisfactionSurveyDTO: {communityId: $communityId, universitySlug: $universitySlug, question: $question, rating: $rating, feedback: $feedback}
  ) {
    userId
    communityId
    universitySlug
  }
}
    `;
export type SendCommunityFeedbackMutationFn = Apollo.MutationFunction<SendCommunityFeedbackMutation, SendCommunityFeedbackMutationVariables>;

/**
 * __useSendCommunityFeedbackMutation__
 *
 * To run a mutation, you first call `useSendCommunityFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCommunityFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCommunityFeedbackMutation, { data, loading, error }] = useSendCommunityFeedbackMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      universitySlug: // value for 'universitySlug'
 *      question: // value for 'question'
 *      rating: // value for 'rating'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSendCommunityFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<SendCommunityFeedbackMutation, SendCommunityFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCommunityFeedbackMutation, SendCommunityFeedbackMutationVariables>(SendCommunityFeedbackDocument, options);
      }
export type SendCommunityFeedbackMutationHookResult = ReturnType<typeof useSendCommunityFeedbackMutation>;
export type SendCommunityFeedbackMutationResult = Apollo.MutationResult<SendCommunityFeedbackMutation>;
export type SendCommunityFeedbackMutationOptions = Apollo.BaseMutationOptions<SendCommunityFeedbackMutation, SendCommunityFeedbackMutationVariables>;
export const InboxPromptQuestionsDocument = gql`
    query InboxPromptQuestions($inboxPromptInput: InboxPromptInput!) {
  inboxPromptQuestions(inboxPromptInput: $inboxPromptInput) {
    id
    options {
      id
      text
      active
    }
    tags
  }
}
    `;

/**
 * __useInboxPromptQuestionsQuery__
 *
 * To run a query within a React component, call `useInboxPromptQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxPromptQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxPromptQuestionsQuery({
 *   variables: {
 *      inboxPromptInput: // value for 'inboxPromptInput'
 *   },
 * });
 */
export function useInboxPromptQuestionsQuery(baseOptions: Apollo.QueryHookOptions<InboxPromptQuestionsQuery, InboxPromptQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboxPromptQuestionsQuery, InboxPromptQuestionsQueryVariables>(InboxPromptQuestionsDocument, options);
      }
export function useInboxPromptQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboxPromptQuestionsQuery, InboxPromptQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboxPromptQuestionsQuery, InboxPromptQuestionsQueryVariables>(InboxPromptQuestionsDocument, options);
        }
export type InboxPromptQuestionsQueryHookResult = ReturnType<typeof useInboxPromptQuestionsQuery>;
export type InboxPromptQuestionsLazyQueryHookResult = ReturnType<typeof useInboxPromptQuestionsLazyQuery>;
export type InboxPromptQuestionsQueryResult = Apollo.QueryResult<InboxPromptQuestionsQuery, InboxPromptQuestionsQueryVariables>;
export const SaveInboxPromptAnswerDocument = gql`
    mutation SaveInboxPromptAnswer($inboxPromptAnswerInput: InboxPromptAnswerInput!) {
  saveInboxPromptAnswer(inboxPromptAnswerInput: $inboxPromptAnswerInput) {
    id
  }
}
    `;
export type SaveInboxPromptAnswerMutationFn = Apollo.MutationFunction<SaveInboxPromptAnswerMutation, SaveInboxPromptAnswerMutationVariables>;

/**
 * __useSaveInboxPromptAnswerMutation__
 *
 * To run a mutation, you first call `useSaveInboxPromptAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInboxPromptAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInboxPromptAnswerMutation, { data, loading, error }] = useSaveInboxPromptAnswerMutation({
 *   variables: {
 *      inboxPromptAnswerInput: // value for 'inboxPromptAnswerInput'
 *   },
 * });
 */
export function useSaveInboxPromptAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SaveInboxPromptAnswerMutation, SaveInboxPromptAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInboxPromptAnswerMutation, SaveInboxPromptAnswerMutationVariables>(SaveInboxPromptAnswerDocument, options);
      }
export type SaveInboxPromptAnswerMutationHookResult = ReturnType<typeof useSaveInboxPromptAnswerMutation>;
export type SaveInboxPromptAnswerMutationResult = Apollo.MutationResult<SaveInboxPromptAnswerMutation>;
export type SaveInboxPromptAnswerMutationOptions = Apollo.BaseMutationOptions<SaveInboxPromptAnswerMutation, SaveInboxPromptAnswerMutationVariables>;
export const GetUserCreatedByUserIdDocument = gql`
    query GetUserCreatedByUserId($id: String!) {
  getUser(id: $id) {
    id
    firstName
    created
    ...Country
  }
}
    ${CountryFragmentDoc}`;

/**
 * __useGetUserCreatedByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUserCreatedByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCreatedByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCreatedByUserIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserCreatedByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserCreatedByUserIdQuery, GetUserCreatedByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCreatedByUserIdQuery, GetUserCreatedByUserIdQueryVariables>(GetUserCreatedByUserIdDocument, options);
      }
export function useGetUserCreatedByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCreatedByUserIdQuery, GetUserCreatedByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCreatedByUserIdQuery, GetUserCreatedByUserIdQueryVariables>(GetUserCreatedByUserIdDocument, options);
        }
export type GetUserCreatedByUserIdQueryHookResult = ReturnType<typeof useGetUserCreatedByUserIdQuery>;
export type GetUserCreatedByUserIdLazyQueryHookResult = ReturnType<typeof useGetUserCreatedByUserIdLazyQuery>;
export type GetUserCreatedByUserIdQueryResult = Apollo.QueryResult<GetUserCreatedByUserIdQuery, GetUserCreatedByUserIdQueryVariables>;
export const SaveNotInterestedStageDocument = gql`
    mutation saveNotInterestedStage($notInterestedStageInput: UserNotInterestedStageInput!) {
  saveNotInterestedStage(notInterestedStageInput: $notInterestedStageInput) {
    decisionStage
    decisionStageLocation
    notInterestedReason
    userId
  }
}
    `;
export type SaveNotInterestedStageMutationFn = Apollo.MutationFunction<SaveNotInterestedStageMutation, SaveNotInterestedStageMutationVariables>;

/**
 * __useSaveNotInterestedStageMutation__
 *
 * To run a mutation, you first call `useSaveNotInterestedStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotInterestedStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotInterestedStageMutation, { data, loading, error }] = useSaveNotInterestedStageMutation({
 *   variables: {
 *      notInterestedStageInput: // value for 'notInterestedStageInput'
 *   },
 * });
 */
export function useSaveNotInterestedStageMutation(baseOptions?: Apollo.MutationHookOptions<SaveNotInterestedStageMutation, SaveNotInterestedStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveNotInterestedStageMutation, SaveNotInterestedStageMutationVariables>(SaveNotInterestedStageDocument, options);
      }
export type SaveNotInterestedStageMutationHookResult = ReturnType<typeof useSaveNotInterestedStageMutation>;
export type SaveNotInterestedStageMutationResult = Apollo.MutationResult<SaveNotInterestedStageMutation>;
export type SaveNotInterestedStageMutationOptions = Apollo.BaseMutationOptions<SaveNotInterestedStageMutation, SaveNotInterestedStageMutationVariables>;
export const SaveUserDecisionStageDocument = gql`
    mutation saveUserDecisionStage($decisionStageInput: UserDecisionStageInput!) {
  saveUserDecisionStage(decisionStageInput: $decisionStageInput) {
    decisionStage
    decisionStageLocation
    notInterestedReason
    userId
  }
}
    `;
export type SaveUserDecisionStageMutationFn = Apollo.MutationFunction<SaveUserDecisionStageMutation, SaveUserDecisionStageMutationVariables>;

/**
 * __useSaveUserDecisionStageMutation__
 *
 * To run a mutation, you first call `useSaveUserDecisionStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserDecisionStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserDecisionStageMutation, { data, loading, error }] = useSaveUserDecisionStageMutation({
 *   variables: {
 *      decisionStageInput: // value for 'decisionStageInput'
 *   },
 * });
 */
export function useSaveUserDecisionStageMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserDecisionStageMutation, SaveUserDecisionStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserDecisionStageMutation, SaveUserDecisionStageMutationVariables>(SaveUserDecisionStageDocument, options);
      }
export type SaveUserDecisionStageMutationHookResult = ReturnType<typeof useSaveUserDecisionStageMutation>;
export type SaveUserDecisionStageMutationResult = Apollo.MutationResult<SaveUserDecisionStageMutation>;
export type SaveUserDecisionStageMutationOptions = Apollo.BaseMutationOptions<SaveUserDecisionStageMutation, SaveUserDecisionStageMutationVariables>;
export const GetConversationPermissionDocument = gql`
    query GetConversationPermission($conversationId: String!) {
  getConversationPermission(id: $conversationId) {
    id
    isMember
  }
}
    `;

/**
 * __useGetConversationPermissionQuery__
 *
 * To run a query within a React component, call `useGetConversationPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConversationPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConversationPermissionQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useGetConversationPermissionQuery(baseOptions: Apollo.QueryHookOptions<GetConversationPermissionQuery, GetConversationPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConversationPermissionQuery, GetConversationPermissionQueryVariables>(GetConversationPermissionDocument, options);
      }
export function useGetConversationPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConversationPermissionQuery, GetConversationPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConversationPermissionQuery, GetConversationPermissionQueryVariables>(GetConversationPermissionDocument, options);
        }
export type GetConversationPermissionQueryHookResult = ReturnType<typeof useGetConversationPermissionQuery>;
export type GetConversationPermissionLazyQueryHookResult = ReturnType<typeof useGetConversationPermissionLazyQuery>;
export type GetConversationPermissionQueryResult = Apollo.QueryResult<GetConversationPermissionQuery, GetConversationPermissionQueryVariables>;
export const GetInterestsDocument = gql`
    query GetInterests {
  getInterests {
    id
    name
  }
}
    `;

/**
 * __useGetInterestsQuery__
 *
 * To run a query within a React component, call `useGetInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInterestsQuery(baseOptions?: Apollo.QueryHookOptions<GetInterestsQuery, GetInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterestsQuery, GetInterestsQueryVariables>(GetInterestsDocument, options);
      }
export function useGetInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInterestsQuery, GetInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterestsQuery, GetInterestsQueryVariables>(GetInterestsDocument, options);
        }
export type GetInterestsQueryHookResult = ReturnType<typeof useGetInterestsQuery>;
export type GetInterestsLazyQueryHookResult = ReturnType<typeof useGetInterestsLazyQuery>;
export type GetInterestsQueryResult = Apollo.QueryResult<GetInterestsQuery, GetInterestsQueryVariables>;
export const GetCommunityUserDirectoryDocument = gql`
    query GetCommunityUserDirectory($pagination: OffsetPagination!, $filterUsers: FilterUsersDTO!) {
  communityUserSearch(pagination: $pagination, filterUsersDTO: $filterUsers) {
    result {
      id
      firstName
      lastName
      accountRole
      profilePhoto
      ...Country
      ...CommunityProfile
    }
    totalUsers
  }
}
    ${CountryFragmentDoc}
${CommunityProfileFragmentDoc}`;

/**
 * __useGetCommunityUserDirectoryQuery__
 *
 * To run a query within a React component, call `useGetCommunityUserDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityUserDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityUserDirectoryQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filterUsers: // value for 'filterUsers'
 *   },
 * });
 */
export function useGetCommunityUserDirectoryQuery(baseOptions: Apollo.QueryHookOptions<GetCommunityUserDirectoryQuery, GetCommunityUserDirectoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommunityUserDirectoryQuery, GetCommunityUserDirectoryQueryVariables>(GetCommunityUserDirectoryDocument, options);
      }
export function useGetCommunityUserDirectoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityUserDirectoryQuery, GetCommunityUserDirectoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommunityUserDirectoryQuery, GetCommunityUserDirectoryQueryVariables>(GetCommunityUserDirectoryDocument, options);
        }
export type GetCommunityUserDirectoryQueryHookResult = ReturnType<typeof useGetCommunityUserDirectoryQuery>;
export type GetCommunityUserDirectoryLazyQueryHookResult = ReturnType<typeof useGetCommunityUserDirectoryLazyQuery>;
export type GetCommunityUserDirectoryQueryResult = Apollo.QueryResult<GetCommunityUserDirectoryQuery, GetCommunityUserDirectoryQueryVariables>;
export const SetInterestsForUserDocument = gql`
    mutation SetInterestsForUser($setUserInterestsInput: SetUserInterestsInput!) {
  setInterestsForUser(setUserInterestsInput: $setUserInterestsInput) {
    id
    name
  }
}
    `;
export type SetInterestsForUserMutationFn = Apollo.MutationFunction<SetInterestsForUserMutation, SetInterestsForUserMutationVariables>;

/**
 * __useSetInterestsForUserMutation__
 *
 * To run a mutation, you first call `useSetInterestsForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInterestsForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInterestsForUserMutation, { data, loading, error }] = useSetInterestsForUserMutation({
 *   variables: {
 *      setUserInterestsInput: // value for 'setUserInterestsInput'
 *   },
 * });
 */
export function useSetInterestsForUserMutation(baseOptions?: Apollo.MutationHookOptions<SetInterestsForUserMutation, SetInterestsForUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInterestsForUserMutation, SetInterestsForUserMutationVariables>(SetInterestsForUserDocument, options);
      }
export type SetInterestsForUserMutationHookResult = ReturnType<typeof useSetInterestsForUserMutation>;
export type SetInterestsForUserMutationResult = Apollo.MutationResult<SetInterestsForUserMutation>;
export type SetInterestsForUserMutationOptions = Apollo.BaseMutationOptions<SetInterestsForUserMutation, SetInterestsForUserMutationVariables>;
export const GetAllCountriesDocument = gql`
    query getAllCountries {
  allCountries {
    code
    id
    name
  }
}
    `;

/**
 * __useGetAllCountriesQuery__
 *
 * To run a query within a React component, call `useGetAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCountriesQuery, GetAllCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument, options);
      }
export function useGetAllCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCountriesQuery, GetAllCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCountriesQuery, GetAllCountriesQueryVariables>(GetAllCountriesDocument, options);
        }
export type GetAllCountriesQueryHookResult = ReturnType<typeof useGetAllCountriesQuery>;
export type GetAllCountriesLazyQueryHookResult = ReturnType<typeof useGetAllCountriesLazyQuery>;
export type GetAllCountriesQueryResult = Apollo.QueryResult<GetAllCountriesQuery, GetAllCountriesQueryVariables>;
export const UpdateApplicantCountryDocument = gql`
    mutation UpdateApplicantCountry($country: String) {
  updateApplicant(country: $country) {
    me {
      applicant {
        id
      }
      anyUser {
        country {
          name
          code
          id
        }
      }
    }
  }
}
    `;
export type UpdateApplicantCountryMutationFn = Apollo.MutationFunction<UpdateApplicantCountryMutation, UpdateApplicantCountryMutationVariables>;

/**
 * __useUpdateApplicantCountryMutation__
 *
 * To run a mutation, you first call `useUpdateApplicantCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicantCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicantCountryMutation, { data, loading, error }] = useUpdateApplicantCountryMutation({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useUpdateApplicantCountryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicantCountryMutation, UpdateApplicantCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicantCountryMutation, UpdateApplicantCountryMutationVariables>(UpdateApplicantCountryDocument, options);
      }
export type UpdateApplicantCountryMutationHookResult = ReturnType<typeof useUpdateApplicantCountryMutation>;
export type UpdateApplicantCountryMutationResult = Apollo.MutationResult<UpdateApplicantCountryMutation>;
export type UpdateApplicantCountryMutationOptions = Apollo.BaseMutationOptions<UpdateApplicantCountryMutation, UpdateApplicantCountryMutationVariables>;
export const GetAccountInformationDocument = gql`
    query GetAccountInformation {
  getUserProfile {
    id
    userId
    interests {
      id
      type
    }
    profilePhoto
    areaOfStudy {
      ...Degree
    }
    bio
    pronouns
    housing {
      category
      name
    }
  }
  getInterests {
    id
    name
  }
  getInterestsForUser {
    id
    name
  }
}
    ${DegreeFragmentDoc}`;

/**
 * __useGetAccountInformationQuery__
 *
 * To run a query within a React component, call `useGetAccountInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountInformationQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(GetAccountInformationDocument, options);
      }
export function useGetAccountInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountInformationQuery, GetAccountInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountInformationQuery, GetAccountInformationQueryVariables>(GetAccountInformationDocument, options);
        }
export type GetAccountInformationQueryHookResult = ReturnType<typeof useGetAccountInformationQuery>;
export type GetAccountInformationLazyQueryHookResult = ReturnType<typeof useGetAccountInformationLazyQuery>;
export type GetAccountInformationQueryResult = Apollo.QueryResult<GetAccountInformationQuery, GetAccountInformationQueryVariables>;
export const InitiateUserDeletionDocument = gql`
    mutation InitiateUserDeletion {
  initiateUserDeletion
}
    `;
export type InitiateUserDeletionMutationFn = Apollo.MutationFunction<InitiateUserDeletionMutation, InitiateUserDeletionMutationVariables>;

/**
 * __useInitiateUserDeletionMutation__
 *
 * To run a mutation, you first call `useInitiateUserDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateUserDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateUserDeletionMutation, { data, loading, error }] = useInitiateUserDeletionMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitiateUserDeletionMutation(baseOptions?: Apollo.MutationHookOptions<InitiateUserDeletionMutation, InitiateUserDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateUserDeletionMutation, InitiateUserDeletionMutationVariables>(InitiateUserDeletionDocument, options);
      }
export type InitiateUserDeletionMutationHookResult = ReturnType<typeof useInitiateUserDeletionMutation>;
export type InitiateUserDeletionMutationResult = Apollo.MutationResult<InitiateUserDeletionMutation>;
export type InitiateUserDeletionMutationOptions = Apollo.BaseMutationOptions<InitiateUserDeletionMutation, InitiateUserDeletionMutationVariables>;
export const ChangeCommunityPasswordDocument = gql`
    mutation ChangeCommunityPassword($password: String, $token: String, $userId: String) {
  resetPasswordV2(userId: $userId, password: $password, token: $token) {
    success
    redirectUrl
    error
  }
}
    `;
export type ChangeCommunityPasswordMutationFn = Apollo.MutationFunction<ChangeCommunityPasswordMutation, ChangeCommunityPasswordMutationVariables>;

/**
 * __useChangeCommunityPasswordMutation__
 *
 * To run a mutation, you first call `useChangeCommunityPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCommunityPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCommunityPasswordMutation, { data, loading, error }] = useChangeCommunityPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useChangeCommunityPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCommunityPasswordMutation, ChangeCommunityPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCommunityPasswordMutation, ChangeCommunityPasswordMutationVariables>(ChangeCommunityPasswordDocument, options);
      }
export type ChangeCommunityPasswordMutationHookResult = ReturnType<typeof useChangeCommunityPasswordMutation>;
export type ChangeCommunityPasswordMutationResult = Apollo.MutationResult<ChangeCommunityPasswordMutation>;
export type ChangeCommunityPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeCommunityPasswordMutation, ChangeCommunityPasswordMutationVariables>;
export const CommunityRequestPasswordResetDocument = gql`
    mutation CommunityRequestPasswordReset($email: String!, $redirectUrl: String!) {
  requestPasswordReset(
    email: $email
    redirectUrl: $redirectUrl
    rootUrl: COMMUNITY_URL
  ) {
    success
  }
}
    `;
export type CommunityRequestPasswordResetMutationFn = Apollo.MutationFunction<CommunityRequestPasswordResetMutation, CommunityRequestPasswordResetMutationVariables>;

/**
 * __useCommunityRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useCommunityRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunityRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communityRequestPasswordResetMutation, { data, loading, error }] = useCommunityRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useCommunityRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<CommunityRequestPasswordResetMutation, CommunityRequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommunityRequestPasswordResetMutation, CommunityRequestPasswordResetMutationVariables>(CommunityRequestPasswordResetDocument, options);
      }
export type CommunityRequestPasswordResetMutationHookResult = ReturnType<typeof useCommunityRequestPasswordResetMutation>;
export type CommunityRequestPasswordResetMutationResult = Apollo.MutationResult<CommunityRequestPasswordResetMutation>;
export type CommunityRequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<CommunityRequestPasswordResetMutation, CommunityRequestPasswordResetMutationVariables>;
export const SaveCookieConsentPreferencesDocument = gql`
    mutation SaveCookieConsentPreferences($input: CookieConsentInputDTO!) {
  saveCookieConsentPreferences(CookieConsentInputDTO: $input) {
    uniqueId
    preferences {
      strictCookies
      analyticsCookies
    }
  }
}
    `;
export type SaveCookieConsentPreferencesMutationFn = Apollo.MutationFunction<SaveCookieConsentPreferencesMutation, SaveCookieConsentPreferencesMutationVariables>;

/**
 * __useSaveCookieConsentPreferencesMutation__
 *
 * To run a mutation, you first call `useSaveCookieConsentPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCookieConsentPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCookieConsentPreferencesMutation, { data, loading, error }] = useSaveCookieConsentPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCookieConsentPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SaveCookieConsentPreferencesMutation, SaveCookieConsentPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveCookieConsentPreferencesMutation, SaveCookieConsentPreferencesMutationVariables>(SaveCookieConsentPreferencesDocument, options);
      }
export type SaveCookieConsentPreferencesMutationHookResult = ReturnType<typeof useSaveCookieConsentPreferencesMutation>;
export type SaveCookieConsentPreferencesMutationResult = Apollo.MutationResult<SaveCookieConsentPreferencesMutation>;
export type SaveCookieConsentPreferencesMutationOptions = Apollo.BaseMutationOptions<SaveCookieConsentPreferencesMutation, SaveCookieConsentPreferencesMutationVariables>;
export const EditCookieConsentPreferencesDocument = gql`
    mutation EditCookieConsentPreferences($input: CookieConsentInputDTO!) {
  editCookieConsentPreferences(CookieConsentInputDTO: $input) {
    uniqueId
    preferences {
      strictCookies
      analyticsCookies
    }
  }
}
    `;
export type EditCookieConsentPreferencesMutationFn = Apollo.MutationFunction<EditCookieConsentPreferencesMutation, EditCookieConsentPreferencesMutationVariables>;

/**
 * __useEditCookieConsentPreferencesMutation__
 *
 * To run a mutation, you first call `useEditCookieConsentPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCookieConsentPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCookieConsentPreferencesMutation, { data, loading, error }] = useEditCookieConsentPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCookieConsentPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<EditCookieConsentPreferencesMutation, EditCookieConsentPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCookieConsentPreferencesMutation, EditCookieConsentPreferencesMutationVariables>(EditCookieConsentPreferencesDocument, options);
      }
export type EditCookieConsentPreferencesMutationHookResult = ReturnType<typeof useEditCookieConsentPreferencesMutation>;
export type EditCookieConsentPreferencesMutationResult = Apollo.MutationResult<EditCookieConsentPreferencesMutation>;
export type EditCookieConsentPreferencesMutationOptions = Apollo.BaseMutationOptions<EditCookieConsentPreferencesMutation, EditCookieConsentPreferencesMutationVariables>;