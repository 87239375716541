// keeping it here as reference of possible values
export enum SignUpSources {
    UB_EMAIL = "ubEmailv2",
    INVITE = "invite",
}

export enum SignUpTrackingPageSources {
    LANDING_V2 = "landingV2",
    INVITE_LINK = "inviteLink",
}
