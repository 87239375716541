import React, {FC, PropsWithChildren} from "react";
import {BrowserRouter} from "react-router-dom";
import {CommunityThemeProvider} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import * as Sentry from "@sentry/react";
import {ErrorBoundary} from "@unibuddy/error-reporting";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {Text} from "@unibuddy/patron";
import {ErrorReportingProvider, createSentryAdaptor} from "@unibuddy/error-reporting";
import {AnalyticsValue} from "@unibuddy/tracking";
import AuthProvider from "ubcommunity-shared/src/Auth/AuthProvider/AuthProvider";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import ApiProvider from "ubcommunity-shared/src/ApiProvider/ApiProvider";
import {getEnvName} from "ubcommunity-shared/src/getEnvName";
import {DialogProvider} from "ubcommunity-shared/src/General/DialogProvider/DialogProvider";
import {SimpleFallback} from "ubcommunity-shared/src/General/Errors/SimpleFallback";
import {PeopleFiltersProvider} from "ubcommunity-shared/src/People/PeopleFiltersProvider";
import "./App.css";
import AppRoutes from "./AppRoutes";
import {CommunityAnalyticsProvider} from "./CommunityAnalyticsProvider";
import {CommunityProvider} from "ubcommunity-shared/src/Community/CommunityProvider/CommunityProvider";
import {ignoreErrors} from "ubcommunity-shared/src/constants";
import {HotJar} from "./General/HotJar";

Sentry.init({
    dsn: "https://9c52fb9a8e524799b6968a365c76a7b3@o338736.ingest.sentry.io/5830410",
    environment: getEnvName(),
    release: `community_${process.env.CIRCLE_SHA1}`,
    ignoreErrors,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.035,
    tracesSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
});

const ErrorReportingWithUser: FC<PropsWithChildren> = ({children}) => {
    const {user} = useAuth();
    const sentryAdaptor = createSentryAdaptor({Sentry, userId: user?.id ?? null});

    return <ErrorReportingProvider adaptor={sentryAdaptor}>{children}</ErrorReportingProvider>;
};

function App() {
    return (
        <AuthProvider>
            <ErrorReportingWithUser>
                <ErrorBoundary
                    fallback={
                        <SimpleFallback>
                            <Text size="medium" align="center">
                                We could not recover from this error. Try reloading this page.
                            </Text>
                        </SimpleFallback>
                    }
                >
                    <CommunityThemeProvider>
                        <ApiProvider>
                            <CommunityProvider>
                                {/* required only by PeoplePage, placed here to maintain sync with mobile structure */}
                                {/* and to persist state across mounts */}
                                <PeopleFiltersProvider>
                                    <BrowserRouter>
                                        <SafeAreaProvider>
                                            <CommunityAnalyticsProvider>
                                                <AnalyticsValue name="product" value="Community" />
                                                <HotJar />
                                                <DialogProvider>
                                                    <AppRoutes />
                                                </DialogProvider>
                                            </CommunityAnalyticsProvider>
                                        </SafeAreaProvider>
                                    </BrowserRouter>
                                </PeopleFiltersProvider>
                            </CommunityProvider>
                        </ApiProvider>
                    </CommunityThemeProvider>
                </ErrorBoundary>
            </ErrorReportingWithUser>
        </AuthProvider>
    );
}

export default App;
