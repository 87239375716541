import React, {useEffect, useState} from "react";
import {Text, useLocalStorage, View} from "@unibuddy/patron";
import {useHistory} from "react-router-dom";
import {useErrorReporting} from "@unibuddy/error-reporting";
import {useAnalytics} from "@unibuddy/tracking";

import {LoadingCover} from "ubcommunity-shared/src/General/LoadingCover/LoadingCover";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import {SignUpPrompt} from "ubcommunity-shared/src/Community/SignUpPrompt";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import {UserAccontRoles} from "ubcommunity-shared/src/constants/accountRoles";
import {useQueryParams} from "ubcommunity-shared/src/Hooks/useQueryParams";
import {
    useGetInviteLinkInfoQuery,
    useGetCommunitiesLazyQuery,
    useJoinCommunityByInviteLinkMutation,
    useUpdateApplicantSignupSourceMutation,
    Community,
} from "../../types";
import {useCommunity} from "../CommunityProvider/CommunityProvider";
import {TermsPrompt} from "./components/TermsPrompt";

import {useAddToFeatureExperimentMutation} from "ubcommunity-shared/src/types";
import {SignUpSources, SignUpTrackingPageSources} from "ubcommunity-shared/src/constants";

interface Props {
    inviteId: string;
}

export const InviteContainer = ({inviteId}: Props) => {
    const {isLoggedIn, user, isVerified} = useAuth();
    const {push} = useHistory();
    const {select} = useCommunity();
    const {isDesktop} = useIsDesktop();
    const [showTermsDialog, setShowTermsDialog] = useState(false);
    const [communityInviteId, setCommunityInviteId] = useLocalStorage("community-invite-id.v1");

    const {getParams} = useQueryParams();
    const source = getParams("source");

    const {data, loading, error} = useGetInviteLinkInfoQuery({
        variables: {
            inviteLinkId: inviteId,
        },
    });

    const [updateApplicantSignupSourceMutation] = useUpdateApplicantSignupSourceMutation();
    const {reportError} = useErrorReporting();
    const {trackEvent} = useAnalytics();

    const linkInfo = data?.getInviteLinkInfo;

    const [getUser, {loading: userDataLoading}] = useGetCommunitiesLazyQuery({
        variables: {
            userId: user ? user.id : "",
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            handleAuthSubmit(data);
        },
        onError: (error) => {
            console.log(error);
            reportError(error);
        },
    });

    const [addToFeatureExperimentMutation] = useAddToFeatureExperimentMutation();

    useEffect(() => {
        if (inviteId && !communityInviteId) {
            setCommunityInviteId(inviteId);
        }
    }, [inviteId, communityInviteId, setCommunityInviteId]);

    useEffect(() => {
        if (source === "sms" && linkInfo?.communityId) {
            trackEvent("Invite via link: Landing page loaded", {source: source || "unknown"});

            addToFeatureExperimentMutation({
                variables: {
                    experimentName: "sms-invite-experiment",
                    metadata: JSON.stringify({
                        communityId: linkInfo?.communityId,
                        universityName: linkInfo?.universityName,
                        universitySlug: linkInfo?.universitySlug,
                    }),
                },
            });
        }
    }, [linkInfo, source, addToFeatureExperimentMutation, trackEvent]);

    useEffect(() => {
        if (!linkInfo) return;
        if (isLoggedIn() && !isVerified()) {
            push(`/u/${linkInfo.universitySlug}`);
            return;
        }
        if (communityInviteId && communityInviteId !== inviteId) {
            setCommunityInviteId(null);
            getUser();
        }
    }, [
        communityInviteId,
        setCommunityInviteId,
        getUser,
        isLoggedIn,
        isVerified,
        push,
        linkInfo,
        trackEvent,
        source,
        inviteId,
    ]);

    const [joinCommunity] = useJoinCommunityByInviteLinkMutation();

    const onJoin = async () => {
        const result = await joinCommunity({
            variables: {communityId: linkInfo.communityId},
        });
        if (user.accountRole === UserAccontRoles.APPLICANT) {
            await updateApplicantSignupSourceMutation({
                variables: {communityId: linkInfo.communityId},
            });
        }
        select(result.data.joinCommunityByInviteLink.community as Community);
        push(`/u/${linkInfo.universitySlug}`);
        trackEvent("Invite via link: User joined community", {
            inviteId,
            communityId: linkInfo.communityId,
        });
    };

    const handleAuthSubmit = (data) => {
        const communities = data.getUser.communities;
        const joinedCommunity = communities.find(
            (community: Community) => linkInfo.communityId === community.id,
        );

        if (joinedCommunity) {
            select(joinedCommunity);
            push(`/u/${linkInfo.universitySlug}`);
            trackEvent("Invite via link: User entered community", {
                inviteId,
                communityId: linkInfo.communityId,
                source: source || "unknown",
            });
        } else {
            setShowTermsDialog(true);
            trackEvent("Invite via link: User showed t&c", {
                inviteId,
                communityId: linkInfo.communityId,
                source: source || "unknown",
            });
        }
    };

    const handleSubmit = () => {
        push(`/u/${linkInfo.universitySlug}/auth/signup-step-1?source=inviteLink`);
        setCommunityInviteId(inviteId);
        trackEvent("Invite via link: Anonymous user interacted", {
            inviteId,
            communityId: linkInfo.communityId,
            source: source || "unknown",
        });
    };

    if (loading) return <LoadingCover loading backgroundColor="body" />;

    if (error) {
        console.log(error);
        reportError(error);

        return (
            <View maxW="320px">
                <Text align="center">
                    An error occurred. Try reloading the page. If the problem persists please
                    contact support.
                </Text>
            </View>
        );
    }

    return (
        <View
            backgroundColor="white"
            shadow="medium"
            style={{zIndex: 1}}
            padding={isDesktop ? "small" : "none"}
        >
            {showTermsDialog ? (
                <TermsPrompt onJoin={onJoin} linkInfo={linkInfo} />
            ) : (
                <SignUpPrompt
                    getUser={getUser}
                    linkInfo={linkInfo}
                    userDataLoading={userDataLoading}
                    handleSubmit={handleSubmit}
                    isSubmitDisabled={!linkInfo.valid}
                    pageSource={SignUpTrackingPageSources.INVITE_LINK}
                    loginPath={`${Routes.LOGIN}?source=${SignUpSources.INVITE}`}
                />
            )}
        </View>
    );
};
