import {useCallback, useEffect, useRef, useState} from "react";
import {Platform} from "react-native";
import {ApolloClient, NormalizedCacheObject, ApolloLink} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {CachePersistor} from "apollo3-cache-persist";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {getAuthorizationHeaderFor} from "ubcommunity-shared/src/Auth/utils";
import {apolloCache} from "./cache";
import {storage} from "./storage";
import {cleanTypeName, errorMiddleware, httpLink} from "./utils";
import {persistenceMapper, createPersistLink} from "./persistence";

export const useApiClient = () => {
    const {authState, setAuth} = useAuth();
    const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>();
    /**
     * This clears cache from the persistor layer (not from apollo client)
     */
    const clearCache = useCallback(() => {
        if (!persistor) {
            return;
        }
        persistor.purge();
    }, [persistor]);

    // We need a ref here because we are utilizing a javascript closure in the `setContext` callback and if we try and
    // access authState directly in the callback it will be out of sync. This is a classic stale closure issue
    // that react hooks dependency array is designed to fix. Unfortunately, we can't use a deps array since useState
    // init function only runs once, kinda like a constructor in a class component. To fix the stale closure issue
    // we use a ref inside the callback instead of the state, and then make sure to update the ref in the render
    // so it is always in sync. This is a bit hacky but it works. The reason it works is because the ref never changes,
    // unlike the state. If we destructured the ref like so `const {current: authState} = authRef` then it would not work
    // since `current` changes. We have to keep in mind that only the first version of the useState init function is always used.
    // Subsequent versions of that function are discarded by react. This is why we also need to use a closure variable from the
    // first render, and that variable is our ref. Previously we used a useLayoutEffect and set the client state but this also had a
    // bug where the client state was out of sync with authState because useEffect + setState is async which means that for at least
    // one render cycle the authState was truthy and the client had no accessToken in the header.
    // This is why we are using useRef instead of useState.
    const authRef = useRef(authState);
    authRef.current = authState;

    const [client] = useState(() => {
        const authLink = setContext((_, {headers}) => {
            const tokenScheme = authRef.current?.tokenScheme;
            const accessToken = authRef.current?.accessToken;
            if (accessToken) {
                return {
                    headers: {
                        ...headers,
                        authorization: getAuthorizationHeaderFor(tokenScheme, accessToken),
                    },
                };
            }

            return {
                headers,
            };
        });

        const persistLink = createPersistLink();

        return new ApolloClient({
            link: ApolloLink.from([
                cleanTypeName,
                errorMiddleware(setAuth),
                authLink,
                persistLink.concat(httpLink),
            ]),
            cache: apolloCache,
            ...Platform.select({
                ios: {
                    name: "community-ios",
                },
                android: {
                    name: "community-android",
                },
                default: {
                    name: "community-web",
                },
            }),
        });
    });

    useEffect(() => {
        async function init() {
            try {
                const newPersistor = new CachePersistor({
                    cache: apolloCache,
                    storage,
                    debug: process.env.NODE_ENV === "development",
                    trigger: "write",
                    persistenceMapper,
                });
                await newPersistor.restore();
                setPersistor(newPersistor);
            } catch (error) {
                console.error("Error restoring Apollo cache", error);
            }
        }

        init();
    }, [setAuth]);

    return {
        client,
        clearCache,
    };
};
