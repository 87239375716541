import React from "react";
import {TouchableOpacity} from "react-native";

import {
    Box,
    Column,
    Columns,
    Text,
    usePatronTheme,
    ResponsiveProp,
    LayoutSpaceProp,
    View,
} from "@unibuddy/patron";

import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";
import ArrowBackIcon from "ubcommunity-shared/src/Icons/ArrowBackIcon";

export const BackButton = ({
    text = "Back",
    paddingTop = "xsmall",
    paddingBottom = "medium",
    borderWidth = 1,
    addonRight = null,
    callback,
}: {
    text?: string;
    paddingTop?: ResponsiveProp<LayoutSpaceProp>;
    paddingBottom?: ResponsiveProp<LayoutSpaceProp>;
    borderWidth?: number;
    addonRight?: React.ReactNode;
    callback?: () => void;
}) => {
    const {darkModeEnabled} = useCommunityTheme();
    const theme = usePatronTheme();

    return (
        <Box
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingX="xxsmall"
            justifyContent="space-between"
            alignItems="center"
            flexShrink={0}
            borderBottomWidth={darkModeEnabled ? 0 : borderWidth}
            borderColor="grey100"
        >
            <TouchableOpacity
                onPress={callback}
                accessibilityRole="button"
                accessibilityLabel="Navigate back"
            >
                <Columns verticalAlign="center">
                    <Column width="content">
                        <View w={42} h={42} justifyContent="center" alignItems="center">
                            <ArrowBackIcon
                                size={24}
                                fill={!darkModeEnabled ? null : theme?.colors?.white}
                            />
                        </View>
                    </Column>
                    <Column width="content">
                        <Box paddingLeft="xsmall">
                            <Text size="large">{text}</Text>
                        </Box>
                    </Column>
                </Columns>
            </TouchableOpacity>
            {addonRight}
        </Box>
    );
};
