import React from "react";

import {Confirm as TermsDialog} from "ubcommunity-shared/src/Auth/Lobby/GeneralLobby";

import {InviteLinkInfoDto} from "../../../../types";

interface TermsPromptProps {
    onJoin: () => void;
    linkInfo: InviteLinkInfoDto;
}

export const TermsPrompt = ({onJoin, linkInfo}: TermsPromptProps) => {
    return (
        <TermsDialog
            onConfirm={onJoin}
            community={{
                title: linkInfo.communityTitle,
                institutions: [
                    {
                        name: linkInfo.universityName,
                        squareLogo: linkInfo.squareLogo,
                        privacyPolicyUrl: linkInfo.privacyPolicyUrl,
                    },
                ],
            }}
        />
    );
};
