import React, {lazy, Suspense} from "react";
import {useRouteMatch} from "react-router";
import RelativeRouter from "ubcommunity-shared/src/Navigation/RelativeRouter/RelativeRouter";
import useAuth from "ubcommunity-shared/src/Auth/hooks/useAuth";
import {EmailInviteProvider} from "ubcommunity-shared/src/Auth/EmailInviteProvider/EmailInviteProvider";

const AuthRoutes = lazy(() => import("../Auth/AuthRoutes/AuthRoutes"));
const CommunityRoutes = lazy(() => import("./CommunityRoutes"));

const UniversityRoutes: React.FC = () => {
    const match = useRouteMatch();
    const {isLoggedIn, isVerified, loading} = useAuth();
    if (loading) return null;

    if (!isLoggedIn() || !isVerified()) {
        return (
            <Suspense fallback={null}>
                <RelativeRouter path={match.path} url={match.url}>
                    <EmailInviteProvider>
                        <AuthRoutes />
                    </EmailInviteProvider>
                </RelativeRouter>
            </Suspense>
        );
    }

    return (
        <Suspense fallback={null}>
            <RelativeRouter path={match.path} url={match.url}>
                <CommunityRoutes />
            </RelativeRouter>
        </Suspense>
    );
};

export default UniversityRoutes;
