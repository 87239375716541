import React from "react";
import {Box, View} from "@unibuddy/patron";
import {InviteContainer} from "ubcommunity-shared/src/Community/Invite/InviteContainer";
import {ChildrenProps} from "./InvitePage";

export const MobileInvitePage = ({inviteId}: ChildrenProps) => {
    return (
        <View flex="1" maxH="100%" overflow="hidden">
            <Box display="flex" flex="1" alignItems="center" justifyContent="center" minH={0}>
                <InviteContainer inviteId={inviteId} />
            </Box>
        </View>
    );
};
