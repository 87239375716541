/**
 * These are errors we don't want or need to send to Sentry
 * When using strings, partial matches will be filtered out,
 * so if you need to filter by exact match, use regex patterns instead
 * https://docs.sentry.io/platforms/javascript/configuration/filtering/#using--1
 */

export const ignoreErrors = [
    "403 Forbidden", // when someone tries to create an account with an existing email address
    "User did not grant camera permission",
    "User cancelled image selection",
    "LaunchDarklyTimeoutError",
    "Code is invalid for that email", //email verification code
    "Code already used", //email verification code
    "Wrong Username or Password",
];
