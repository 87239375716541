import React, {PropsWithChildren} from "react";

import {Box, Inline, Text} from "@unibuddy/patron";

import PersonIcon from "ubcommunity-shared/src/Icons/PersonIcon";
import {useCommunityTheme} from "ubcommunity-shared/src/Theme/CommunityThemeProvider";

const colors = {
    default: {
        bgColor: "#f1f1f1",
        textColor: "#404446",
    },
    primary: {
        bgColor: "#CFC8FF",
        textColor: "#5140C9",
    },
};

const darkModeColors = {
    default: {
        bgColor: "#AEA1FF",
        textColor: "#5342C9",
    },
    primary: {
        bgColor: "#6852FB",
        textColor: "#fff",
    },
};

export type MemberCountColor = "primary" | "default";

export function MemberCount({
    memberCount,
    color = "default",
    children = null,
}: PropsWithChildren<{
    memberCount: number;
    color?: MemberCountColor;
}>) {
    const {darkModeEnabled} = useCommunityTheme();
    const colorMap = darkModeEnabled ? darkModeColors : colors;
    return (
        <Box
            bgColor={colorMap[color].bgColor}
            color={colorMap[color].textColor}
            padding="xsmall"
            paddingRight="small"
            paddingY="xxsmall"
            borderRadius="xsmall"
            data-testid="member-count"
        >
            <Inline space="xxsmall" verticalAlign="center" wrap="nowrap">
                <PersonIcon aria-hidden color={colorMap[color].textColor} size={16} />
                <Text color={colorMap[color].textColor} size="small">
                    {memberCount}
                </Text>
                {children ? (
                    <Text size="small" color={colorMap[color].textColor}>
                        {" "}
                        {children}
                    </Text>
                ) : null}
            </Inline>
        </Box>
    );
}
