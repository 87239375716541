export const SIGNUP_PROMPT_STRINGS = {
    WELCOME_TO: "Welcome to",
    JOIN_THE_COMMUNITY:
        "Join Community to start chatting with other students and connecting with your classmates!",
    NOT_LOGGED_IN:
        "If this is your first time using Community, you’ll need to create an account on the next page.",
    BUTTON_TEXT_LETS_GO: "Let's go!",
    BUTTON_TEXT_CREATE_ACCOUNT: "Create an account",
    BUTTON_TEXT_INVALID_LINK: "This Link is Invalid",
    GOT_ACCOUNT_ALREADY: "Got an account?",
    BUTTON_TEXT_LOGIN: "Login",
};

export const SIGNUP_PROMPT_ERROR_MESSAGE_STRINGS = {
    ERROR_MESSAGE:
        "An error occurred. Try reloading the page. If the problem persists please contact support.",
};
