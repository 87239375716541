import {InMemoryCache, FieldPolicy} from "@apollo/client";

export const getUsersCommunityConversationsTypePolicy: FieldPolicy = {
    keyArgs: ["communityId"],
    merge(existing, incoming, {args: {offsetPagination}}) {
        let offset = 0;
        if (offsetPagination) {
            offset = offsetPagination.offset;
        }
        const merged = existing ? existing.slice(0) : [];
        for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
        }
        return merged;
    },
};

export const communityUserSearchTypePolicy: FieldPolicy = {
    keyArgs: ["filterUsers"],
    merge(existing, incoming, {args: {pagination}}) {
        let offset = 0;
        if (pagination) {
            offset = pagination.offset;
        }
        if (!incoming.result) {
            return existing || {result: [], totalUsers: 0};
        }
        const merged = existing?.result ? existing.result.slice(0) : [];
        for (let i = 0; i < incoming.result.length; ++i) {
            merged[offset + i] = incoming.result[i];
        }
        return {...incoming, result: merged};
    },
};

export const apolloCache = new InMemoryCache({
    // This doesn’t work correctly with the manual updates to the cache as we are doing it
    // currently inside useChat. We will need to change those to use `cache.modify()`.
    // typePolicies: {
    //     Query: {
    //         fields: {
    //             getChatConversationMessages: {
    //                 // By excluding pagination args in cache key creation we ensure
    //                 // a single cache key per conversationId
    //                 keyArgs: ({getMessageDto: {conversationId}}) => {
    //                     return JSON.stringify({getMessageDto: {conversationId}});
    //                 },
    //                 merge(existing, incoming, {variables}) {
    //                     // console.log(variables);
    //                     // if (!variables.offsetId) return incoming;
    //                     if (!existing) return incoming;
    //                     return {
    //                         ...incoming,
    //                         messages: [...incoming.messages, ...existing.messages],
    //                     };
    //                 },
    //             },
    //         },
    //     },
    // },
    typePolicies: {
        Query: {
            fields: {
                getCommunityChatMembers: {
                    keyArgs: ["conversationId"],
                    merge(existing, incoming) {
                        return existing ? [...existing, ...incoming] : incoming;
                    },
                },
                communityUserSearch: communityUserSearchTypePolicy,
                getUsersCommunityConversations: getUsersCommunityConversationsTypePolicy,
            },
        },
    },
});
