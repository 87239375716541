import React, {createContext, ReactNode, useMemo} from "react";

export const RelativeRouterContext = createContext({path: "", url: ""});

/**
 * Creates a relative routing context where all RelativeRouterLinks and RelativeRouterRoutes children within its context will
 * get the path prop prepended to its urls. It can be used to easily add routes and links withing a complex url
 * route and where we do not have access to the original url. For example if we have a route as follows:
 *
 * ```jsx
 * <Route path="/embed/:universitySlug/colour/:colourCode" render={() => {
 *   // here we render Widget
 * }}/>
 * ```
 *
 * Then anywhere within the Widget whenever we use a Link or a Route, we have to prepend the existing url first
 * in order to get a url like this /embed/:universitySlug/colour/:colourCode/inbox/chat`. This can quickly
 * become tedious, as we have to pass in the original url throughout the app. RelativeRouter simplifies this
 * by doing this:
 *
 * ```jsx
 * <Route path="/embed/:universitySlug/colour/:colourCode" render={() => {
 *   <RelativeRouter path="/embed/:universitySlug/colour/:colourCode">
 *     // here we render Widget and somewhere down the component tree...
 *     <RelativeLink to="/test">Test</RelativeLink>
 *   </RelativeRouter>
 * }}/>
 * ```
 *
 * In the above example the resulting Link will have the to prop set as:
 * `/embed/:universitySlug/colour/:colourCode/test`
 */

type RelativeRouterProps = {children: ReactNode; path: string; url: string};

const RelativeRouter = ({children, path, url}: RelativeRouterProps) => {
    const value = useMemo(
        () => ({
            path: path === "/" ? "" : path,
            url: url === "/" ? "" : url,
        }),
        [path, url],
    );

    return (
        <RelativeRouterContext.Provider value={value}>{children}</RelativeRouterContext.Provider>
    );
};

export default RelativeRouter;
