import React from "react";
import {useParams} from "react-router-dom";
import {useIsDesktop} from "ubcommunity-shared/src/General/useIsDesktop/useIsDesktop";
import RelativeRoute from "ubcommunity-shared/src/Navigation/RelativeRoute/RelativeRoute";
import RelativeSwitch from "ubcommunity-shared/src/Navigation/RelativeSwitch/RelativeSwitch";
import {DesktopInvitePage} from "./DesktopInvitePage";
import {MobileInvitePage} from "./MobileInvitePage";

export interface ChildrenProps {
    inviteId: string;
}

export const InvitePage = () => {
    const {isDesktop} = useIsDesktop();
    const {inviteId} = useParams<{inviteId: string}>();

    return (
        <RelativeSwitch>
            <RelativeRoute path="/">
                {isDesktop ? (
                    <DesktopInvitePage inviteId={inviteId} />
                ) : (
                    <MobileInvitePage inviteId={inviteId} />
                )}
            </RelativeRoute>
        </RelativeSwitch>
    );
};
