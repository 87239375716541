export const universityBaseRoute = "/u/:universitySlug";

export enum Routes {
    // start auth routes
    SIGNUP_STEP_1 = "/auth/signup-step-1",
    SSO_LOGIN = "/auth/sso",
    SSO_SUCCESS = "/auth/sso/success",
    LOGIN = "/auth/login",
    REQUEST_RESET = "/auth/request-reset",
    CHANGE_PASSWORD = "/auth/reset/:userType/:userId/token/:token",
    LINK_UNIVERSITY = "/auth/link",
    BASE_AUTH = "/auth",
    VERIFY_EMAIL = "verifyEmail",
    LANDING = "/landing",

    // start in-app routes
    ROOT = "/",
    CHAT = "/chat",
    CHAT_HOME = "/chat",
    CHAT_GROUP = "/chat/:id",
    CREATE_POLL = "/chat/:id/poll/create",
    CREATE_GROUP = "/chat/group/create",
    SETTINGS = "/settings",
    EXPLORE = "/explore",
    PEOPLE = "/people",
    NEWS = "/news",
    TODO = "/to-do",
    NEWS_CREATE_POLL = "/news/poll/create",
    JOIN_GROUP = "/joingroup/:conversationId/:communityId",
    CHAT_PREVIEW = "/chatpreview/:id",
    MATCHES = "/matches",
    BANKS_LIST = "/banksList",
    // end in-app routes
    CONTENT_VIEW = "/blog",
    // start generic routes
    BASE_INVITE = "/invite",
    // end generic routes

    // start settings routes
    ACCOUNT_INFORMATION = "/settings/account",
    // end settings routes
}
