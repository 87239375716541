export const chatTheme = {
    Button: {
        colors: {
            chat: {
                // color: "#3e3e48",
                // bgColor: "#c2d1d9",
                // borderColor: "transparent",
                // hoverBgColor: "#c2d1d9",
                // outlineColor: "#3e3e48",
                // clearColor: "#3e3e48",
                // clearHoverBgColor: "#c2d1d9",
                color: "#6852FB",
                bgColor: "#CFC8FF",
                borderColor: "transparent",
                hoverBgColor: "#b6adf2",
                outlineColor: "#5140C9",
                clearColor: "#9b570b",
                clearHoverBgColor: "#ffe5c8",
            },
            activeChat: {
                color: "#fff",
                bgColor: "#6852FB",
                borderColor: "transparent",
                hoverBgColor: "#5140C9",
                outlineColor: "#000",
                clearColor: "#9b570b",
                clearHoverBgColor: "#ffe5c8",
            },
        },
    },
    chat: {
        contextMenu: {
            backgroundColor: "#fff",
            backdropColor: "#a1aeb7",
            underlayColor: "#f1f1f1",
            reactionBackgroundColor: "#F3F5F5",
            reactionBorderColor: "#5140C9",
        },
        message: {
            backgroundColor: "#CFC8FF",
            altBackgroundColor: "#fff",
            linkColor: "#5140C9",
            altLinkColor: "#5140C9",
            textColor: "#424b5a",
            altTextColor: "#424b5a",
        },
        input: {
            color: "#3e3e48",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.08)",
            paddingTop: "9px",
            placeholderColor: "3e3e48",
        },
        footer: {
            backgroundColor: "#f2f5f7",
        },
        quote: {
            backgroundColor: "#bfb3fb",
            altBackgroundColor: "#f1f1f1",
            highlightColor: "#5140C9",
            altBorderColor: "#FFE5C8",
            authorColor: "#3e3e48",
            altAuthorColor: "#5140C9",
            bodyColor: "#3e3e48",
            altBodyColor: "#3e3e48",
        },
        scroll: {
            backgroundColor: "#f2f5f7",
            gradientBgColorTop: "transparent",
            gradientBgColorBottom: "transparent",
        },
        reaction: {
            backgroundColor: "#fafafa",
            pinnedBackgroundColor: "#fafafa",
            border: "1px solid #fafafa",
            reactedBackgroundColor: "#fafafa",
            reactedBorderColor: "#5140C9",
        },
    },
};
