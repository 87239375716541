import {dependencies as PackageDeps} from "../../package.json";

export const dependencies = {
    react: {
        [PackageDeps.react]: {
            // eslint-disable-next-line
            get: () => Promise.resolve().then(() => () => require("react")),
        },
    },
    "react-dom": {
        [PackageDeps["react-dom"]]: {
            // eslint-disable-next-line
            get: () => Promise.resolve().then(() => () => require("react-dom")),
        },
    },
    "react-router-dom": {
        [PackageDeps["react-router-dom"]]: {
            // eslint-disable-next-line
            get: () => Promise.resolve().then(() => () => require("react-router-dom")),
        },
    },
    "react-router": {
        [PackageDeps["react-router"]]: {
            // eslint-disable-next-line
            get: () => Promise.resolve().then(() => () => require("react-router")),
        },
    },
    "styled-components": {
        [PackageDeps["styled-components"]]: {
            // eslint-disable-next-line
            get: () => Promise.resolve().then(() => () => require("styled-components")),
        },
    },
    "@unibuddy/patron": {
        [PackageDeps["@unibuddy/patron"]]: {
            // eslint-disable-next-line
            get: () => Promise.resolve().then(() => () => require("@unibuddy/patron")),
        },
    },
    "ub-feature-flagging-react": {
        [PackageDeps["ub-feature-flagging-react"]]: {
            get: () =>
                Promise.resolve().then(
                    () => () =>
                        // eslint-disable-next-line
                        require("ub-feature-flagging-react"),
                ),
        },
    },
};
