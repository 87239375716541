import React, {createContext, useCallback, useMemo, useState} from "react";
import {Dialog, Heading, Text, Box, Stack} from "@unibuddy/patron";

export const DialogContext = createContext<{
    openPrompt(options: Options): void;
    dismissPrompt(): void;
}>({
    openPrompt: () => {},
    dismissPrompt: () => {},
});

interface Options {
    title: string;
    body: any;
    message?: string;
}

export const DialogProvider = ({children}) => {
    const [prompt, setPrompt] = useState<null | Options>(null);
    const openPrompt = useCallback((options: Options) => {
        setPrompt(options);
    }, []);
    const dismissPrompt = useCallback(() => {
        setPrompt(null);
    }, []);
    const value = useMemo(() => ({openPrompt, dismissPrompt}), [dismissPrompt, openPrompt]);

    return (
        <DialogContext.Provider value={value}>
            {prompt ? (
                <Dialog
                    isOpen={!!prompt}
                    CloseButton={null}
                    verticalAlign="center"
                    size="xxsmall"
                    onDismiss={() => {
                        dismissPrompt();
                    }}
                >
                    <Stack space="small">
                        <Box paddingY="medium">
                            <Heading align="center" size="xsmall" level="2">
                                {prompt.title}
                            </Heading>
                        </Box>
                        {prompt.message ? (
                            <Box paddingX="medium">
                                <Text>{prompt.message}</Text>
                            </Box>
                        ) : null}
                        <Box overflow="hidden" brb="small">
                            {prompt.body}
                        </Box>
                    </Stack>
                </Dialog>
            ) : null}
            {children}
        </DialogContext.Provider>
    );
};
