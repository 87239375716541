import React from "react";
import {Platform} from "react-native";
import {Box, Button, Heading, Stack, Text, View, usePatronTheme} from "@unibuddy/patron";
import {SUPPORT_EMAIL} from "ubcommunity-shared/src/constants/constants";
import {Routes} from "ubcommunity-shared/src/constants/routes";
import RelativeButtonLink from "ubcommunity-shared/src/Navigation/RelativeButtonLink/RelativeButtonLink";
import {boldFontStyles} from "ubcommunity-shared/src/Styles/fontStyles";
import {Link} from "ubcommunity-shared/src/General/Link/Link";
import {useLogout} from "../hooks/useLogout";

export enum LobbyEmptyStateStrings {
    title = "It looks like there's no community invitation associated with this email address",
    body_1 = "Please make sure you are using the same email address you used in your university application, or contact ",
    help = "support@unibuddy.com",
    body_2 = " for assistance.",
    buttonText = "Back to Sign in",
}

export const LobbyEmptyState = () => {
    const theme = usePatronTheme();
    const {logout} = useLogout();

    return (
        <>
            <Box w="100%" bgColor="primaryColor" h={3} marginTop="xlarge" />
            <View padding="large" flex={1}>
                <Box paddingBottom="medium">
                    <Heading align="center" level="1" size="xsmall">
                        {LobbyEmptyStateStrings.title}
                    </Heading>
                </Box>
                <Stack align="center" space="medium">
                    <Text size="medium" align="center">
                        {LobbyEmptyStateStrings.body_1}
                        <Link
                            external
                            href={`mailto:${SUPPORT_EMAIL}`}
                            style={{
                                textDecorationLine: "underline",
                                textDecorationColor: theme?.colors?.textLinkColor,
                                color: theme?.colors?.textLinkColor,
                            }}
                            size="medium"
                        >
                            {LobbyEmptyStateStrings.help}
                        </Link>
                        {LobbyEmptyStateStrings.body_2}
                    </Text>
                </Stack>
            </View>
            <Box padding="medium" paddingTop="none">
                {Platform.select({
                    web: (
                        <RelativeButtonLink
                            to={Routes.BASE_AUTH}
                            onClick={logout}
                            block
                            color="primary"
                        >
                            <Text
                                size="medium"
                                // @ts-ignore
                                style={{...boldFontStyles}}
                                color="white"
                            >
                                Back to Sign in
                            </Text>
                        </RelativeButtonLink>
                    ),
                    native: (
                        <Button onClick={logout} block color="primary">
                            <Text
                                size="medium"
                                // @ts-ignore
                                style={{...boldFontStyles}}
                                color="white"
                            >
                                {LobbyEmptyStateStrings.buttonText}
                            </Text>
                        </Button>
                    ),
                })}
            </Box>
        </>
    );
};
