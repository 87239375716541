const API_URL = process.env.FEDERATION_API_URL;
const PUSHER_APP_CLUSTER = process.env.PUSHER_APP_CLUSTER;
const PUSHER_APP_KEY = process.env.PUSHER_APP_KEY;
const PIKACHU_URL = process.env.PIKACHU_URL;
const ASSETS_URL = process.env.ASSETS_URL;
const ASSETS_URL_V2 = process.env.ASSETS_URL_V2;
const MIXPANEL_API_TOKEN = process.env.MIXPANEL_API_TOKEN;
const ENV_NAME = process.env.ENV_NAME;
const LAUNCH_DARKLY_CLIENT_ID = process.env.LAUNCH_DARKLY_CLIENT_ID;
const COMMUNITY_APP_URL = "";
const CODEPUSH_IOS_QA_STAGING_KEY = undefined;
const CODEPUSH_IOS_QA_PRODUCTION_KEY = undefined;
const CODEPUSH_IOS_MASTER_STAGING_KEY = undefined;
const CODEPUSH_IOS_MASTER_PRODUCTION_KEY = undefined;
const CODEPUSH_ANDROID_QA_STAGING_KEY = undefined;
const CODEPUSH_ANDROID_QA_PRODUCTION_KEY = undefined;
const CODEPUSH_ANDROID_MASTER_STAGING_KEY = undefined;
const CODEPUSH_ANDROID_MASTER_PRODUCTION_KEY = undefined;
const SUPPORT_EMAIL = "support@unibuddy.com";
const CONTENT_FEDERATED_MODULES_APP_URL = process.env.CONTENT_FEDERATED_MODULES_APP_URL;

const AUTH_FLOW_TIMEOUT = Number(process.env.AUTH_FLOW_TIMEOUT ?? 2500);
const AUTH_FLOW_UNKNOWN = "UNKNOWN";
const AUTH_FLOW_SSO = "SSO";
const AUTH_FLOW_NON_SSO = "NON_SSO";
const GATEWAY_URL = process.env.GATEWAY_URL;
const ACVL_APPLICATION_ID = process.env.ACVL_APPLICATION_ID;

// we cannot directly export the variables because the plugin is weird
export {
    API_URL,
    PUSHER_APP_CLUSTER,
    PUSHER_APP_KEY,
    PIKACHU_URL,
    ASSETS_URL,
    ASSETS_URL_V2,
    MIXPANEL_API_TOKEN,
    ENV_NAME,
    LAUNCH_DARKLY_CLIENT_ID,
    COMMUNITY_APP_URL,
    CODEPUSH_IOS_QA_STAGING_KEY,
    CODEPUSH_IOS_QA_PRODUCTION_KEY,
    CODEPUSH_IOS_MASTER_STAGING_KEY,
    CODEPUSH_IOS_MASTER_PRODUCTION_KEY,
    CODEPUSH_ANDROID_QA_STAGING_KEY,
    CODEPUSH_ANDROID_QA_PRODUCTION_KEY,
    CODEPUSH_ANDROID_MASTER_STAGING_KEY,
    CODEPUSH_ANDROID_MASTER_PRODUCTION_KEY,
    CONTENT_FEDERATED_MODULES_APP_URL,
    SUPPORT_EMAIL,
    AUTH_FLOW_TIMEOUT,
    AUTH_FLOW_UNKNOWN,
    AUTH_FLOW_SSO,
    AUTH_FLOW_NON_SSO,
    GATEWAY_URL,
    ACVL_APPLICATION_ID,
};
