import React from "react";
import styled from "styled-components";

const A = styled.a`
    color: ${({theme, color}) => (color ? color : theme?.colors?.textLinkColor)};
    text-decoration: none;

    &:hover {
        opacity: 0.8;
        text-decoration: underline;
    }
`;

export function Link({children, ...props}) {
    return <A {...props}>{children}</A>;
}
